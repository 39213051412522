import { forwardRef, Fragment, useCallback } from "react";

import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "../../../../../redux/store";
import usePixelPerfect from "../../../../../hooks/old/usePixelPerfect";
import { setTransferAttachedFilesModalState } from "../../../../../redux/slices/inventory/transfer/transferAttachedFilesModalStatesSlice";
import DefaultModalLayout from "../../../../../layouts/collections/modals/DefaultModalLayout";
import CustomText from "../../../texts/CustomText";
import TransferAttachNewFileForm from "../../../../inventory/transfers/TransferAttachNewFileForm";


export default forwardRef(function CustomAttachNewTransferFileModal() {
    const dispatch = useAppDispatch();
    const { PP } = usePixelPerfect();
    const { t } = useTranslation();

    const resetForm = useCallback(async () => {
        dispatch(
            setTransferAttachedFilesModalState({
                id: "attachFile",
                value: false,
            })
        );
    }, [dispatch]);

    const handleClickCloseModal = (event: React.MouseEvent<SVGSVGElement>) => {
        resetForm();
    };

    const handleBackClick = useCallback(async () => {
        dispatch(
            setTransferAttachedFilesModalState({
                id: "attachFile",
                value: false,
            })
        );
        dispatch(
            setTransferAttachedFilesModalState({
                id: "selectTypeFile",
                value: true,
            })
        );
    }, [dispatch]);

    return (
        <DefaultModalLayout handleClose={handleClickCloseModal}>
            <Fragment>
                <CustomText
                    showBackArrowButton
                    noSpacing
                    bigSize
                    premediumWeight
                    color="#1C1C1C"
                    style={{ marginBottom: PP(24) }}
                    onClick={handleBackClick}
                >
                    {t("Adjuntar documento")}
                </CustomText>
                <Divider sx={{ margin: "0px" }} />
                <TransferAttachNewFileForm />
            </Fragment>
        </DefaultModalLayout>
    );
});
