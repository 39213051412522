import { createAsyncThunk } from "@reduxjs/toolkit";

import { getRedux } from "../../../../utils/old/api";

export const fetchOneTransformationInfo = createAsyncThunk(
  "oneTransformationInfo/fetchGet",
  async (payload: { transformationId: string }, thunkAPI) => {
    try {
      const response = await getRedux(`/transform/${payload.transformationId}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
