import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import {
  reset as resetAddMaterial,
  setFormAddMaterialState,
} from "../../redux/slices/collections/addMaterialCollectionSlice";
import { setCollectionMaterialsModalState } from "../../redux/slices/collections/collectionsMaterialsModalsStates";
import materialsListSlice, {
  reset as resetMaterialsList,
} from "../../redux/slices/collections/materialsListSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { fetchAddMaterialCollection } from "../../redux/thunks/collections/addMaterialCollectionThunk";
import { fetchCollectionDetails } from "../../redux/thunks/collections/collectionDetailsThunk";
import {
  materialId as materialIdValidation,
  quantity as quantityValidation,
  validateYupSchema,
} from "../../utils/forms/validationSchema";
import CustomButton from "../custom/buttons/default/CustomButton";
import CustomMaterialPickerInput from "../custom/forms/collections/CustomMaterialPickerInput";
import CustomInput from "../custom/forms/default/CustomInput";
import CustomText from "../custom/texts/CustomText";
import { Box } from "@mui/material";
import { fetchMaterialsList } from "../../redux/thunks/collections/materialsListThunk";
import CustomAlert from "../custom/alerts/CustomAlert";
import {
  setMessage,
  setOpenSnackbar,
  setType,
} from "../../redux/slices/globalSnackbar/globalSnackbarSlice";

export default function AddMaterialCollectionForm() {
  const { id } = useParams();
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { category, materialId, quantity, price, collectionId } =
    useAppSelector((state) => state.addMaterialCollection.form);

  const { collectionDetails } = useAppSelector(
    (state) => state.collectionDetails
  );

  const { page, selected, totalMaterialsList, materialsList } = useAppSelector(
    (state) => state.materialsList
  );

  const { materialsPricesList } = useAppSelector(
    (state) => state.collectionMaterialsPricesList
  );

  const [totalToPay, setTotalToPay] = useState(0);

  const [disabledPrice, setDisabledPrice] = useState(false);

  const [disabledPriceInput, setDisabledPriceInput] = useState(false);

  const getMaterialsList = async () => {
    if (totalMaterialsList > materialsList.length) {
      dispatch(fetchMaterialsList({ page: page, category: category }));
    }
  };

  useEffect(() => {
    getMaterialsList();
  }, [category, dispatch]);

  const resetForm = useCallback(async () => {
    dispatch(resetAddMaterial());
    dispatch(resetMaterialsList());
    dispatch(
      setCollectionMaterialsModalState({ id: "addMaterial", value: false })
    );
  }, [dispatch]);

  const handleClickCloseModal = () => {
    resetForm();
  };

  const handleChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    formikAddMaterialCollection.setFieldValue("quantity", value);
    dispatch(setFormAddMaterialState({ id: "quantity", value: value }));
  };

  const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabledPrice) {
      // Evitar cambiar el precio si disabledPrice es true
      const { value } = event.target;
      formikAddMaterialCollection.setFieldValue("price", value);
      dispatch(setFormAddMaterialState({ id: "price", value: value }));
    }
  };

  const initialFormikValues = {
    priceType: "country",
    materialId: materialId,
    quantity: 0,
    price: "0",
    collectionId: collectionId,
  };

  const validationSchema = validateYupSchema({
    materialId: materialIdValidation,
    quantity: quantityValidation,
  });

  const handleSubmit = async (data: {
    priceType: string;
    materialId: number;
    quantity: number;
    price: string;
    collectionId: number;
  }) => {
    const payload = {
      materials: [
        {
          priceType: data.priceType,
          materialId: data.materialId,
          quantity: Number(data.quantity),
          price: Number(data.price),
          collectionId: Number(id),
        },
      ],
    };

    let repeatProduct = false;

    collectionDetails.items.map((material) => {
      if (material.materialId === formikAddMaterialCollection.values.materialId)
        repeatProduct = true;
    });

    if (repeatProduct) {
      dispatch(setOpenSnackbar(true));
      dispatch(setMessage("Se ha producido un error: producto repetido"));
      dispatch(setType("failedSnackbar"));
      if (id !== undefined) dispatch(fetchCollectionDetails({ id: id }));
    } else {
      dispatch(fetchAddMaterialCollection(payload));
      if (id !== undefined) dispatch(fetchCollectionDetails({ id: id }));
    }
    handleClickCloseModal();
  };

  const formikAddMaterialCollection = useFormik({
    initialValues: initialFormikValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: false,
  });

  useEffect(() => {
    const newTotalToPay =
      Number(formikAddMaterialCollection.values.price) *
      Number(formikAddMaterialCollection.values.quantity);
    setTotalToPay(newTotalToPay);
  }, [
    formikAddMaterialCollection.values.price,
    formikAddMaterialCollection.values.quantity,
  ]);

  useEffect(() => {
    if (selected && materialsPricesList.length > 0) {
      let material = materialsPricesList.filter((elem) => {
        return elem.materialId === selected.id;
      })[0];

      if (!material)
        material = {
          ...selected,
          ownerType: "country",
          price: 0,
          materialId: selected.id,
          countryId: 1,
          ownerId: 1,
        };

      formikAddMaterialCollection.setFieldValue("price", material.price);
      formikAddMaterialCollection.setFieldValue(
        "priceType",
        material.ownerType
      );
      if (collectionDetails.payment_method === "bank_transfer") {
        setDisabledPriceInput(true);
      } else if (collectionDetails.payment_method === "cash") {
        if (material.ownerType !== "country") {
          setDisabledPriceInput(true);
          setDisabledPrice(true);
        } else {
          setDisabledPriceInput(false);
          setDisabledPrice(false);
        }
      } else if (collectionDetails.payment_method === "mixed") {
        if (material.ownerType !== "country") {
          setDisabledPriceInput(true);
          setDisabledPrice(true);
        } else {
          if (material.materialId <= 5) {
            setDisabledPriceInput(false);
            setDisabledPrice(false);
          } else {
            setDisabledPriceInput(true);
            setDisabledPrice(false);
          }
        }
      }
    }
  }, [
    formikAddMaterialCollection.values.materialId,
    disabledPrice,
    selected,
    disabledPriceInput,
  ]);

  return (
    <form noValidate onSubmit={formikAddMaterialCollection.handleSubmit}>
      <CustomInput
        id="category"
        label={t(
          "collectionInformation.customAddMaterialModal.AddMaterialCollectionForm.Categoría"
        )}
        placeholder="Categoría de material"
        value={t(
          `collectionInformation.customSelectCategoryModal.categories.${category}`
        )}
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        inputHeight={PP(40)}
        inputBorderRadius={PP(8)}
        backgroundColor="#F6F6F6"
        extraStyle={{ marginTop: PP(24) }}
        disabled
      />
      <CustomMaterialPickerInput
        width={432}
        label={t(
          "collectionInformation.customAddMaterialModal.AddMaterialCollectionForm.Material"
        )}
        required
        id="materialId"
        error={t(formikAddMaterialCollection.errors.materialId)}
        disabledPrice={disabledPrice}
      />
      {disabledPrice && (
        <CustomAlert
          alertText={t(
            "collectionInformation.customAddMaterialModal.AddMaterialCollectionForm.Este material tiene un precio unitario personalizado"
          )}
        />
      )}

      <CustomInput
        id="quantity"
        label={t(
          "collectionInformation.customAddMaterialModal.AddMaterialCollectionForm.Cantidad"
        )}
        placeholder="00"
        formik={formikAddMaterialCollection}
        value={formikAddMaterialCollection.values.quantity}
        onChange={handleChangeQuantity}
        labelStyles={{
          fontSize: PP(14),
          fontWeight: 700,
        }}
        inputHeight={PP(40)}
        inputBorderRadius={PP(4)}
        endAdornmentValue={selected?.measure}
        extraStyle={{ marginBottom: PP(16) }}
      />
      {/* Alerta informativa Para ACU */}
      {formikAddMaterialCollection.values.materialId === 1 && (
        <CustomAlert
          alertText={t(
            "collectionInformation.customAddMaterialModal.AddMaterialCollectionForm.Ingresar peso NETO del material"
          )}
        />
      )}

      <CustomInput
        id="price"
        label={t(
          "collectionInformation.customAddMaterialModal.AddMaterialCollectionForm.Precio unitario"
        )}
        placeholder="0.00"
        formik={formikAddMaterialCollection}
        value={formikAddMaterialCollection.values.price}
        disabled={disabledPriceInput}
        onChange={handleChangePrice}
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        extraStyle={{ marginBottom: PP(16) }}
        inputHeight={PP(24)}
        inputBorderRadius={PP(8)}
        startAdornmentValue={
          <CustomText noSpacing color="#969696">
            $
          </CustomText>
        }
      />

      <Box display="flex" flexDirection="row">
        <CustomText noSpacing color="#4D4D4D">
          {t(
            "collectionInformation.customAddMaterialModal.AddMaterialCollectionForm.Total a pagar"
          )}
          :
        </CustomText>
        <CustomText noSpacing mediumWeight color="#4D4D4D">
          &nbsp;&nbsp;$ {totalToPay.toLocaleString("es-MX")}
        </CustomText>
      </Box>

      <CustomButton
        width={432}
        height={44}
        noSpacing
        style={{ padding: "0px", marginTop: PP(24) }}
        type="submit"
        label={t(
          "collectionInformation.customAddMaterialModal.AddMaterialCollectionForm.Agregar"
        )}
        formik={formikAddMaterialCollection}
      />
      <CustomButton
        noSpacing
        width={432}
        height={44}
        cancelButton
        onClick={handleClickCloseModal}
        formik={formikAddMaterialCollection}
      />
    </form>
  );
}
