import { Box, IconButton } from "@mui/material";

import "./collectionFileFullScreen.css";
import CustomText from "../custom/texts/CustomText";
import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import { ReactComponent as DownloadIcon } from "../../assets/icons/modals/download.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/modals/x.svg";

interface CollectionPdfFullScreenProps {
  fileURL: string;
  fileName: string;
  setFullSreen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CollectionPdfFullScreen(
  props: CollectionPdfFullScreenProps
) {
  const { PP } = usePixelPerfect();

  const handleDownloadPdf = () => {
    // Crea un enlace temporal
    const link = document.createElement("a");
    link.href = props.fileURL; // Establece la URL de la imagen como el enlace de descarga
    link.download = props.fileName; // Establece el nombre de archivo para la descarga

    // Simula un clic en el enlace para iniciar la descarga
    link.click();

    // Limpia el enlace temporal
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="image-preview-modal">
        <div className="image-preview-header">
          <Box display="flex" flexDirection="row" alignItems="center">
            <CustomText> {props.fileName}</CustomText>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <IconButton onClick={handleDownloadPdf}>
              <DownloadIcon
                style={{
                  width: PP(24),
                  height: PP(24),
                  marginRight: PP(16),
                }}
              />
            </IconButton>
            <IconButton onClick={() => props.setFullSreen(false)}>
              <CloseIcon style={{ width: PP(24), height: PP(24) }} />
            </IconButton>
          </Box>
        </div>
        <iframe
          src={`https://docs.google.com/viewer?url=${encodeURIComponent(
            props.fileURL
          )}&embedded=true&download=no&rm=demo&viewMode=fullScreen&disableZoom=true`}
          width="100%"
          height="600px"
        ></iframe>
      </div>
    </>
  );
}
