import {
    styled as styledMui,
    TableCell,
    TableRow,
} from "@mui/material";
// import { useTranslation } from "react-i18next";

import { CustomTableRowType } from "../../../../../../interfaces/styles/customTableRowInterface";
import { useAppDispatch } from "../../../../../../redux/store";

interface TransformationTableRowProps {
    element: {
        id: number;
        transformId: number;
        materialId: number;
        quantityMaterial: number;
        type: string;
        createdAt: string;
        updatedAt: string;
        material: {
            id: number;
            name: string;
            measure: string;
            category: string;
            description: string;
            nameTranslations: {
                translations: {
                    es: string;
                    en: string;
                    it: string;
                };
            };
        };
    };
    myKey: number;
}

const CustomTableRow = styledMui(TableRow)<CustomTableRowType>(({ theme }) => ({
    "&:hover": {
        backgroundColor: "#E8E8E8",
    },
}));

export default function CustomTransformationsDetailsMaterialsTableRow(
    props: TransformationTableRowProps
) {
    // const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { element, myKey } = props;
    const selectedLanguage = localStorage.getItem("language");

    return (
        <>
            <CustomTableRow key={myKey} variant="default">
                {element && (
                    <>
                        <TableCell sx={{ color: "#1C1C1C", cursor: "default" }}>
                            {/* {element.material.nameTranslations?.translations !== null
                                ? //@ts-ignore
                                element.material.nameTranslations.translations[
                                selectedLanguage ? selectedLanguage : "es"
                                ]
                                : */element.material.name}
                        </TableCell>
                        <TableCell sx={{ color: "#1C1C1C", cursor: "default" }}>
                            {element.material.name === "uco"
                                ? `${element.quantityMaterial.toFixed(2)} ${element.material.measure
                                } (Neto)`
                                : `${element.quantityMaterial.toFixed(2)} ${element.material.measure}`}
                        </TableCell>
                    </>
                )}
            </CustomTableRow>
        </>
    );
}
