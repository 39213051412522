import { createAsyncThunk } from "@reduxjs/toolkit";

import { getRedux } from "../../../../utils/old/api";

export const fetchTransferList = createAsyncThunk(
  "transferList/fetchGet",
  async (payload: { page?: number; filter?: string }, thunkAPI) => {
    const page = payload.page ? payload.page : 0;
    try {
      const response = await getRedux(
        `/transfer?$limit=50&$skip=${page * 50}&$sort[startDate]=-1&${
          payload.filter ? payload.filter : ``
        }`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
