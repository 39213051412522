import { useCallback } from "react";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SelectChangeEvent } from "@mui/material";
import { useFormik } from "formik";

import usePixelPerfect from "../../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { reset as resetEditTransferMaterial } from "../../../redux/slices/inventory/transfer/editMaterialTransferSlice";
import { setTransferMaterialsModalState } from "../../../redux/slices/inventory/transfer/transferMaterialsModalsStatesSlice";
import { quantity, validateYupSchema } from "../../../utils/forms/validationSchema";
import { fetchEditMaterialTransfer } from "../../../redux/thunks/inventory/transfer/editMaterialTransferThunk";
import { fetchTransferDetails } from "../../../redux/thunks/inventory/transfer/transferDetailsThunk";
import CustomInput from "../../custom/forms/default/CustomInput";
import { firstLetterCapitalizeFormat } from "../../../utils/formats/firstLetterCapitalizeFormat";
import CustomText from "../../custom/texts/CustomText";
import CustomButton from "../../custom/buttons/default/CustomButton";
import CustomAlert from "../../custom/alerts/CustomAlert";
import { setMessage, setOpenSnackbar, setType } from "../../../redux/slices/globalSnackbar/globalSnackbarSlice";


export default function EditMaterialTransferForm() {
    const { id } = useParams();
    const { PP } = usePixelPerfect();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { selectedMaterialToEdit } = useAppSelector(
        (state) => state.editMaterialTransfer
    );

    const { transferDetails } = useAppSelector(
        (state) => state.transferDetails
    );

    const resetForm = useCallback(async () => {
        dispatch(resetEditTransferMaterial());
        dispatch(
            setTransferMaterialsModalState({ id: "editQuantity", value: false })
        );
    }, [dispatch]);

    const handleClickCloseModal = () => {
        resetForm();
    };


    const initialFormikValues = {
        materialId: selectedMaterialToEdit.materialId,
        quantityDestination: selectedMaterialToEdit.quantityDestination,
        quantityOrigin: selectedMaterialToEdit.quantityOrigin,
    };

    const validationSchema = validateYupSchema({

    });

    const handleSubmit = async (data: {
        materialId: number;
        quantityDestination: number;
        quantityOrigin: number;
    }) => {
        let payload = {}
        if (transferDetails.status === "pending") {
            payload = {
                materialId: selectedMaterialToEdit.materialId,
                quantityOrigin: Number(data.quantityOrigin),
            };
        } else {
            payload = {
                materialId: selectedMaterialToEdit.materialId,
                quantityDestination: Number(data.quantityDestination),
            };
        }

        dispatch(
            fetchEditMaterialTransfer({
                data: payload,
                transferMaterialId: selectedMaterialToEdit.id,
            })
        );
        if (id !== undefined) dispatch(fetchTransferDetails({ id: id }));
        handleClickCloseModal();
        dispatch(setType("sucessSnackbar"));
        dispatch(setMessage(t("snackbars.Material editado con éxito")));
        dispatch(setOpenSnackbar(true));
    };

    const formikEditMaterialTransfer = useFormik({
        initialValues: initialFormikValues,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
        validateOnChange: false,
    });

    const formikEditMaterialTransferChange = useCallback(
        (
            event:
                | React.ChangeEvent<HTMLInputElement>
                | SelectChangeEvent<number | string>
        ) => {
            formikEditMaterialTransfer.setFieldValue(
                event.target.name,
                event.target.value
            );
        },
        [formikEditMaterialTransfer]
    );

    return (
        <form noValidate onSubmit={formikEditMaterialTransfer.handleSubmit}>
            <CustomInput
                id="category"
                label={t("Categoría")}
                placeholder="00"
                value={t(selectedMaterialToEdit.material.category)}
                labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
                inputHeight={PP(40)}
                inputBorderRadius={PP(8)}
                backgroundColor="#F6F6F6"
                extraStyle={{ marginBottom: PP(16), marginTop: PP(24) }}
                disabled
            />
            <CustomInput
                id="materialId"
                label={t("Material")}
                placeholder="00"
                value={firstLetterCapitalizeFormat(
                    selectedMaterialToEdit.material.name
                )}
                labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
                inputHeight={PP(40)}
                inputBorderRadius={PP(8)}
                backgroundColor="#F6F6F6"
                extraStyle={{ marginBottom: PP(16) }}
                disabled
            />
            {transferDetails.status === "pending" &&
                < CustomInput
                    id="quantityOrigin"
                    label={t("Cantidad origen")}
                    placeholder="00"
                    formik={formikEditMaterialTransfer}
                    value={formikEditMaterialTransfer.values.quantityOrigin}
                    onChange={formikEditMaterialTransferChange}
                    labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
                    extraStyle={{ marginBottom: PP(16) }}
                    inputHeight={PP(40)}
                    inputBorderRadius={PP(8)}
                    endAdornmentValue={
                        <CustomText noSpacing color="#969696">
                            {selectedMaterialToEdit.material.measure}
                        </CustomText>
                    }
                />
            }
            {transferDetails.status === "received" &&
                <CustomInput
                    id="quantityDestination"
                    label={t("Cantidad destino")}
                    placeholder="00"
                    formik={formikEditMaterialTransfer}
                    value={formikEditMaterialTransfer.values.quantityDestination}
                    onChange={formikEditMaterialTransferChange}
                    labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
                    extraStyle={{ marginBottom: PP(16) }}
                    inputHeight={PP(40)}
                    inputBorderRadius={PP(8)}
                    endAdornmentValue={
                        <CustomText noSpacing color="#969696">
                            {selectedMaterialToEdit.material.measure}
                        </CustomText>
                    }
                />
            }
            {/* Alerta informativa Para ACU */}
            {selectedMaterialToEdit.material.id === 1 && (
                <CustomAlert alertText={t("Ingresar peso NETO del material")} />
            )}

            <CustomButton
                width={432}
                height={44}
                noSpacing
                style={{ padding: "0px", marginTop: PP(24) }}
                type="submit"
                label={t("Guardar")}
                formik={formikEditMaterialTransfer}
            />
            <CustomButton
                noSpacing
                width={432}
                height={44}
                cancelButton
                onClick={handleClickCloseModal}
                formik={formikEditMaterialTransfer}
            />
        </form>
    );
}
