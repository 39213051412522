import posthog from "posthog-js";
import React, { useContext } from "react";
import { Edit as EditIcon } from "@mui/icons-material";
import { useFormik } from "formik";
import {
  Divider,
  IconButton,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Toolbar,
  styled,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";

import { useEffect, useState } from "react";
import CustomHeaderTitle from "../../../components/custom/headers/default/CustomHeaderTitle";
import CustomCompleteTable from "../../../components/custom/tables/default/CustomCompleteTable";
import usePixelPerfect from "../../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  fetchWarehousesList,
  fetchWarehouseQuantityList,
  updateWarehouseQuantity,
} from "../../../redux/thunks/warehouse/warehousesListThunk";
import { verificationTableHeadCells } from "../../../utils/constants/inventory/inventoryAdjustmentTableHeadCells";

import { reset as resetWarehouseListSlice } from "../../../redux/slices/warehouse/warehouseListSlice";
import { AuthContext } from "../../../contexts/old/JWTContext";

const CustomTableRow = styled(TableRow)<any>(({ }) => ({}));
const TextField = styled(MuiTextField)(spacing);

function VerificationList() {
  const { user }: any = useContext(AuthContext);

  const { verificationList } = useAppSelector(
    (state) => state.verificationList
  );
  const [selectedWarehouse, setSelectedWarehouse] = useState<any>(0);
  const [selectedDate, setSelectedDate] = useState<any>(
    new Date().toISOString().split("T")[0]
  );
  const dispatch = useAppDispatch();
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const {
    warehousesList,
    inventoryQuantities,
    status,
    quantityStatus,
    loadingQuantities,
  } = useAppSelector((state) => state.warehouseList);

  const [modalEdit, setModalEdit] = useState(false);
  const [open, setOpen] = useState(false);

  const editPrice = (price: any) => {
    setSelectedPrice(price);
    setModalEdit(true);
  };

  useEffect(() => {
    getWarehouseList();

    return () => {
      dispatch(resetWarehouseListSlice());
    };
  }, []);

  useEffect(() => {
    dispatch(fetchWarehouseQuantityList({ warehouseId: selectedWarehouse }));
  }, [selectedWarehouse]);

  const getWarehouseList = async () => {
    if (warehousesList.length === 0) {
      dispatch(fetchWarehousesList({ page: 0 }));
    }
  };

  const [selectedPrice, setSelectedPrice] = useState<{ quantity: number, id: number, warehouseId?: number }>({ quantity: 0, id: 0 });

  const initialValues = {
    quantity: selectedPrice.quantity,
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: async (data) => {
      await dispatch(
        updateWarehouseQuantity({
          id: selectedPrice.id,
          data: { quantity: parseFloat(data.quantity.toString()) },
        })
      );
      posthog.capture("update_inventory_quantities", {
        data: {
          userId: user.id,
          materialId: selectedPrice.id,
          quantity: selectedPrice.quantity,
          newQuantity: parseFloat(data.quantity.toString()),
          warehouseId: selectedPrice?.warehouseId || "N/A",
        },
      });
    },
  });

  useEffect(() => {
    if (quantityStatus == "succeeded") {
      setModalEdit(false);
      setSelectedPrice({ quantity: 0, id: 0 });
      dispatch(
        fetchWarehouseQuantityList({ warehouseId: selectedWarehouse || 0 })
      );
    }
  }, [quantityStatus]);

  const handleClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Toolbar
        style={{
          padding: "0px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <CustomHeaderTitle
          title={t("adjustment_title")}
          style={{ fontSize: PP(36), fontWeight: 500 }}
        />
        <div>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedWarehouse}
            label="Age"
            onChange={(event) => {
              setSelectedWarehouse(event.target.value);
            }}
          >
            <MenuItem key={0} value={0} disabled>
              {t("Bodega")}
            </MenuItem>
            {warehousesList.map((element: any, key) => {
              return (
                <MenuItem key={key} value={element.id}>
                  {element.name}
                </MenuItem>
              );
            })}
          </Select>
        </div>
      </Toolbar>
      <Divider />

      {loadingQuantities ? (
        <h1>Cargando</h1>
      ) : (
        <CustomCompleteTable
          status={status}
          tableHeadCells={verificationTableHeadCells} //Encabezados de la tabla
          totalRows={50} // total de filas de la tabla = total de datos respuesta backend
          rowsPerPage={50} //Filas por pagina de tabla
          page={0} //página actual
          handleChangePage={() => { }} // Manejando cambio de página para consumo Api
          setPage={() => { }} //Cambio de página actual
          children={inventoryQuantities?.map((element: any, key) => {
            return (
              <CustomTableRow
                key={key}
                variant="default"
                style={{ cursor: "default" }}
              >
                {element && (
                  <>
                    <TableCell style={{ color: "#212121", cursor: "default" }}>
                      {element.material.name}
                    </TableCell>
                    <TableCell style={{ color: "#212121", cursor: "default" }}>
                      {element.warehouse.name}
                    </TableCell>
                    <TableCell style={{ color: "#212121", cursor: "default" }}>
                      {element.quantity} {element.material.measure}
                    </TableCell>
                    <TableCell align="right" style={{ cursor: "default" }}>
                      <IconButton
                        aria-label="details"
                        size="large"
                        onClick={(e) => editPrice(element)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </>
                )}
              </CustomTableRow>
            );
          })}
        />
      )}

      <Dialog
        open={modalEdit}
        onClose={() => setModalEdit(false)}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle id="form-dialog-title">
            {t("editQuantityModal.Editar un precio")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t(
                "editQuantityModal.Este será el nuevo precio por defecto para todos los negocios y hogares"
              )}
            </DialogContentText>
            <TextField
              name="quantity"
              label={t("editQuantityModal.precio")}
              type="decimal"
              variant="outlined"
              value={formik.values.quantity}
              onChange={formik.handleChange}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModalEdit(false)} color="primary">
              {t("editPriceModal.cancelar")}
            </Button>
            <Button type="submit" color="primary">
              {t("editPriceModal.actualizar")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={t("editPriceModal.Precio actualizado")}
        action={
          <React.Fragment>
            {/* <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton> */}
          </React.Fragment>
        }
      />
    </>
  );
}

export default VerificationList;
