import { useCallback, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import CustomButton from "../custom/buttons/default/CustomButton";
import CustomText from "../custom/texts/CustomText";
import { setCollectionAttachedFilesModalState } from "../../redux/slices/collections/collectionAttachedFilesModalStatesSlice";
import { fetchDeleteCollectionAttachFile } from "../../redux/thunks/collections/deleteCollectionAttachFileThunk";
import {
  setMessage,
  setOpenSnackbar,
  setType,
} from "../../redux/slices/globalSnackbar/globalSnackbarSlice";

export default function DeleteSelectedAttachFileForm() {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { fileSelectedToDelete, status } = useAppSelector(
    (state) => state.deleteCollectionAttachFile
  );

  const resetForm = useCallback(async () => {
    dispatch(
      setCollectionAttachedFilesModalState({
        id: "deleteAttachFile",
        value: false,
      })
    );
  }, [dispatch]);

  const handleClickCloseModal = () => {
    resetForm();
  };

  const handleSubmit = async () => {
    dispatch(
      fetchDeleteCollectionAttachFile({ imageId: fileSelectedToDelete.id })
    );
    handleClickCloseModal();
  };

  useEffect(() => {
    if (status === 200) {
      dispatch(setOpenSnackbar(true));
      dispatch(setMessage(t("snackbars.Cambios guardados exitosamente")));
      dispatch(setType("sucessSnackbar"));
    }
  }, [status]);

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Box
        sx={[
          {
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            backgroundColor: "#FFFFFF",
            width: PP(432),
            height: PP(40),
            marginTop: PP(24),
          },
        ]}
      >
        <CustomText noSpacing color="#585757" width="100%">
          {t(
            "collectionAttachedFiles.customDeleteAttachFileModal.deleteSelectedAttachFileForm.¿Estás seguro que deseas eliminar este documento?"
          )}
        </CustomText>
      </Box>

      <CustomButton
        width={432}
        height={44}
        noSpacing
        style={{ padding: "0px", marginTop: PP(24) }}
        deleteButton
        type="submit"
        label={t(
          "collectionAttachedFiles.customDeleteAttachFileModal.deleteSelectedAttachFileForm.Sí, deseo eliminarlo"
        )}
        onClick={handleSubmit}
      />
      <CustomButton
        noSpacing
        width={432}
        height={44}
        cancelButton
        onClick={handleClickCloseModal}
      />
    </form>
  );
}
