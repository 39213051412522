import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Card as MuiCard,
  CardContent,
  Typography,
  Avatar as MuiAvatar,
  Dialog,
  DialogTitle,
  DialogContent,
  Alert,
  DialogActions,
  FormControl,
  Paper,
  FormControlLabel,
  Checkbox,
  Snackbar,
  FormLabel,
  RadioGroup,
  Radio,
  FormGroup,
  IconButton,
  TextField as MuiTextField,
  Grid as MuiGrid,
  Divider as MuiDivider,
  Switch,
} from "@mui/material";
import { Edit as EditIcon, Close as CloseIcon } from "@mui/icons-material";
import MailIcon from "@mui/icons-material/Mail";
import styled from "@emotion/styled";
import { Box, spacing } from "@mui/system";

import useAuth from "../../../../../hooks/old/useAuth";
import {
  fetchCreateNotification,
  fetchPerson,
  fetchUpdatePerson,
  pushPersonPermission,
  setCheckPermissions,
  setShowSnack,
} from "../../../../../redux/old/slices/individualsSlicer";
import { useTranslation } from "react-i18next";

const TextField = styled(MuiTextField)(spacing);

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const Centered = styled.div`
  text-align: center;
`;

const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;

const BubbleWrapper = styled.div`
  .ql-editor {
    padding: 0;
    &.ql-blank:before {
      left: 0;
    }
  }
  .ql-tooltip {
    z-index: 9999;
  }
`;

const Grid = styled(MuiGrid)(spacing);

const Divider = styled(MuiDivider)(spacing);

export default function BasicPersonDataCard() {
  const { id } = useParams(); //id del perfil de la persona que se inspecciona
  const { user } = useAuth(); //Información del usuario que está logueado en el managment web
  const { t } = useTranslation();

  const [editModal, setEditModal] = useState(false);
  const [resetWarningModal, setResetWarningModal] = useState(false);
  const [contactUserModal, setContactUserModal] = useState(false);
  const [value, setValue] = useState(""); //Para manejar msj predeterminados - contactar usuario seleccionado
  const [state, setState] = useState({
    email: false,
    sms: false,
    app: true,
  });

  //Reduxtoolkit-
  const loading = useSelector((state) => state.person.showLoading);
  const person = useSelector((state) => state.person.person);
  const checkPermissions = useSelector(
    (state) => state.person.checkPermissions
  );
  const permissions = useSelector((state) => state.person.permissions);

  const [snackTxt, setSnackTxt] = useState("");
  const [showSnack, setShowSnack] = useState(false);

  const dispatch = useDispatch();

  //--------------------Actualización de Datos Básicos de la persona-------------------//

  const initialValuesPerson = {
    email: person.email,
    name: person.name,
    last_name: person.last_name,
    document_number: person.document_number,
    phone: person.phone,
    countryId: 1,
    permissions: permissions,
  };

  function validationSchema() {
    return {
      email: Yup.string()
        .email(t("editInfoUserModal.validations.Correo no válido"))
        .required(t("editInfoUserModal.validations.Correo requerido")),
      name: Yup.string()
        .min(3, t("editInfoUserModal.validations.Mín 3 caracteres"))
        .required(t("editInfoUserModal.validations.Nombre requerido")),
      last_name: Yup.string()
        .min(3, t("editInfoUserModal.validations.Mín 3 caracteres"))
        .required(t("editInfoUserModal.validations.Apellido requerido")),
      document_number: Yup.number().required(
        t("editInfoUserModal.validations.Número de documento requerido")
      ),
      phone: Yup.number().required(
        t("editInfoUserModal.validations.Teléfono requerido")
      ),
    };
  }

  const updatePerson = async (data) => {
    dispatch(fetchUpdatePerson({ data: data, id: id })); //Reduxtoolkit Patch
    setEditModal(false);
    setResetWarningModal(false);
    dispatch(fetchPerson(id)); //Reduxtoolkit Get
  };

  const formikPerson = useFormik({
    initialValues: initialValuesPerson,
    enableReinitialize: true,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) => {
      if (user.permissions.includes("admin")) {
        //Si el usuario logueado posee permiso de "admin"...
        data.permissions = person.permissions;
        updatePerson(data); //Actualizando la información de la persona con los nuevos datos
        setSnackTxt(t("Usuario actualizado"));
        setShowSnack(true);
      }
    },
  });

  const handleChangePermissions = (event) => {
    dispatch(
      setCheckPermissions({
        key: event.target.name,
        value: event.target.checked,
      })
    );
  };

  // Manejando Reestablecimiento de contraseña de personas ("Con permiso Migrated")

  const initialValuesResetPasswordPerson = {
    permissions: permissions,
  };

  const formikResetPasswordPerson = useFormik({
    initialValues: initialValuesResetPasswordPerson,
    enableReinitialize: true,
    onSubmit: async (data) => {
      if (permissions.includes("migrated")) {
        updatePerson(data);
      } else {
        dispatch(pushPersonPermission("migrated")); // se le pushea al array permissions el migrated
        data.permissions = permissions; //se le asigna a data.permissions (valor que se envía del form) el nuevo array  permissions actualizado con migrated
        updatePerson(data); //se actualiza los datos de la persona
      }
    },
  });

  //--------------Manjenado el envío de mensajes, correos, notificaciones personalizadas hacia la persona:
  const handleChangeContactMessage = (event) => {
    setValue(event.target.value);
  };

  const handleChangeCheckBoxContactUserModal = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const { email, sms, app } = state;

  const initialValuesNotification = {
    email: email,
    sms: sms,
    app: app,
  };

  const formikNotification = useFormik({
    initialValues: initialValuesNotification,
    enableReinitialize: true,
    onSubmit: async (data) => {
      createNotification(data);
    },
  });

  const createNotification = async (data) => {
    data.user_id = id;
    dispatch(fetchCreateNotification(data));
    setContactUserModal(false);
  };

  return (
    <React.Fragment>
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {t("Persona")}
          </Typography>

          <Spacer mb={4} />

          <Centered>
            {person.name && <Avatar>{person.name[0].toUpperCase()}</Avatar>}
            <Typography variant="body2" component="div" gutterBottom>
              <Box fontWeight="fontWeightMedium">
                {person.name + " " + person.last_name}{" "}
              </Box>
              <Box fontWeight="fontWeightRegular">{person.email}</Box>
              <Box fontWeight="fontWeightRegular">{person.phone}</Box>
              <Box fontWeight="fontWeightRegular">${person.wallet}</Box>
            </Typography>
            <Box mb={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => setEditModal(true)}
              >
                <EditIcon />
                {t("Editar")}
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => setContactUserModal(true)}
              >
                <MailIcon />
                {t("Contactar")}
              </Button>
            </Box>
          </Centered>
        </CardContent>
      </Card>

      {/* -----------------------------------------MODALES ------------------------------------------------------------- */}

      {/* 1) Modal Editar Persona */}

      <Dialog
        open={editModal}
        onClose={() => setEditModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={formikPerson.handleSubmit}>
          <DialogTitle id="form-dialog-title">
            {" "}
            {t("editInfoUserModal.Actualizar usuario")}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={6}>
              <Grid item md={6}>
                <TextField
                  name="email"
                  label={t("editInfoUserModal.Correo")}
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formikPerson.values.email}
                  onChange={formikPerson.handleChange}
                />
                {formikPerson.errors.email && (
                  <Alert mt={2} mb={3} severity="error">
                    {formikPerson.errors.email}
                  </Alert>
                )}
              </Grid>
              <Grid item md={6}>
                <TextField
                  name="document_number"
                  label={t("editInfoUserModal.Número de documento")}
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formikPerson.values.document_number}
                  onChange={formikPerson.handleChange}
                />
                {formikPerson.errors.document_number && (
                  <Alert mt={2} mb={3} severity="error">
                    {formikPerson.errors.document_number}
                  </Alert>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item md={6}>
                <TextField
                  name="name"
                  label={t("editInfoUserModal.Nombre")}
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formikPerson.values.name}
                  onChange={formikPerson.handleChange}
                />
                {formikPerson.errors.name && (
                  <Alert mt={2} mb={3} severity="error">
                    {formikPerson.errors.name}
                  </Alert>
                )}
              </Grid>
              <Grid item md={6}>
                <TextField
                  name="last_name"
                  label={t("editInfoUserModal.Apellido")}
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formikPerson.values.last_name}
                  onChange={formikPerson.handleChange}
                />
                {formikPerson.errors.last_name && (
                  <Alert mt={2} mb={3} severity="error">
                    {formikPerson.errors.last_name}
                  </Alert>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item md={6}>
                <TextField
                  name="phone"
                  label={t("editInfoUserModal.Teléfono")}
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formikPerson.values.phone}
                  onChange={formikPerson.handleChange}
                />
                {formikPerson.errors.phone && (
                  <Alert mt={2} mb={3} severity="error">
                    {formikPerson.errors.phone}
                  </Alert>
                )}
              </Grid>
            </Grid>
            <Divider />
            {user &&
              user.permissions.includes("admin") && ( //Controlando el renderizado de los permisos sólo para usuario logueado con permiso admin
                <Card mb={6}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {t("editInfoUserModal.Permisos")}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {t(
                        "editInfoUserModal.Activa o desactiva los permisos que tiene el usuario"
                      )}
                    </Typography>
                    <Paper mt={3}>
                      <FormControl component="fieldset" variant="standard">
                        <FormLabel component="legend">
                          {t("editInfoUserModal.Asignar Permisos")}
                        </FormLabel>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={checkPermissions.collector_fog} //Controlarlo con el localStorage
                                onChange={handleChangePermissions}
                                name="collector_fog"
                              />
                            }
                            label={t("editInfoUserModal.Recolector Aceite")}
                          />
                          <FormControlLabel
                            control={
                              <Switch
                                checked={checkPermissions.collector_ssr}
                                onChange={handleChangePermissions}
                                name="collector_ssr"
                              />
                            }
                            label={t(
                              "editInfoUserModal.Recolector Aprovechables"
                            )}
                          />
                          <FormControlLabel
                            control={
                              <Switch
                                checked={checkPermissions.commercial}
                                onChange={handleChangePermissions}
                                name="commercial"
                              />
                            }
                            label={t("editInfoUserModal.Comercial")}
                          />
                        </FormGroup>
                      </FormControl>
                    </Paper>
                  </CardContent>
                </Card>
              )}

            <Divider />
            {user &&
              user.permissions.includes("admin") && ( //Controlando el renderizado de los permisos sólo para usuario logueado con permiso admin
                <Card mb={6}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {t("editInfoUserModal.Reestablecer contraseña")}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {t(
                        "editInfoUserModal.El usuario tendrá que reestablecer su contraseña para poder usar la App"
                      )}
                    </Typography>
                    <Button
                      onClick={() => setResetWarningModal(true)}
                      color="primary"
                    >
                      {t("editInfoUserModal.Reestablecer")}
                    </Button>
                  </CardContent>
                </Card>
              )}
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setEditModal(false)} color="primary">
              {t("editInfoUserModal.Cancelar")}
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {t("editInfoUserModal.Actualizar")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        open={resetWarningModal}
        onClose={() => setResetWarningModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={formikResetPasswordPerson.handleSubmit}>
          <DialogContent>
            <Typography variant="body2" gutterBottom>
              {t(
                "editInfoUserModal.Al dar click en Confirmar, recuerda que el usuario tendrá que reestablecer su contraseña para poder usar la App"
              )}
            </Typography>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setResetWarningModal(false)} color="primary">
              {t("editInfoUserModal.Cancelar")}
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {t("editInfoUserModal.Confirmar")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* 2) Modal Contactar Persona*/}
      <Dialog
        open={contactUserModal}
        onClose={() => setContactUserModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={formikNotification.handleSubmit}>
          {" "}
          {/*Formulario para Envío de Notificaciones-MODAL*/}
          <DialogTitle id="form-dialog-title">
            {t("contactUserModal.Contactar usuario")}
          </DialogTitle>
          <DialogContent>
            <Typography variant="body2" gutterBottom>
              {t(
                "contactUserModal.Escribe el mensaje que deseas enviar al usuario"
              )}
              :
            </Typography>
            <Box mt={3} mb={6} border={1} borderColor="#EFEBEB" padding="1em">
              <BubbleWrapper>
                <ReactQuill
                  theme="bubble"
                  value={value}
                  onChange={setValue}
                  placeholder={t("contactUserModal.Escribe aquí")}
                />
              </BubbleWrapper>
            </Box>

            <Box mt={3} mb={6}>
              <Paper mt={3}>
                <FormControl component="fieldset" variant="standard">
                  <FormLabel component="legend">
                    {t("contactUserModal.Mensajes predeterminados")}:
                  </FormLabel>
                  <RadioGroup
                    aria-label="default-messages"
                    name="default-messages"
                    value={value}
                    onChange={handleChangeContactMessage}
                  >
                    <FormControlLabel
                      value="Hola Mutante! es un gusto saludarte..."
                      control={<Radio />}
                      label={t(
                        "contactUserModal.Hola Mutante! es un gusto saludarte"
                      )}
                    />

                    <FormControlLabel
                      value="Buenas tardes Mutante, te informamos que..."
                      control={<Radio />}
                      label={t(
                        "contactUserModal.Buenas tardes Mutante, te informamos que"
                      )}
                    />
                    <FormControlLabel
                      value="Buenos días Mutante, te informamos que..."
                      control={<Radio />}
                      label={t(
                        "contactUserModal.Buenos días Mutante, te informamos que"
                      )}
                    />
                  </RadioGroup>
                </FormControl>
              </Paper>
            </Box>
            <Box mt={3} mb={6}>
              <Paper mt={6}>
                <FormControl component="fieldset" variant="standard">
                  <FormLabel component="legend">
                    {t("contactUserModal.Enviar mensaje mediante:")}
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sms}
                          onChange={handleChangeCheckBoxContactUserModal}
                          name="sms"
                        />
                      }
                      label={t("contactUserModal.Mensaje de texto")}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={email}
                          onChange={handleChangeCheckBoxContactUserModal}
                          name="email"
                        />
                      }
                      label={t("contactUserModal.Correo electrónico")}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={app}
                          onChange={handleChangeCheckBoxContactUserModal}
                          name="app"
                        />
                      }
                      label={t("contactUserModal.Notificación de App")}
                    />
                  </FormGroup>
                </FormControl>
              </Paper>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setContactUserModal(false)} color="primary">
              {t("contactUserModal.Cancelar")}
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {t("contactUserModal.Enviar")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* --------------------------------------------------------------- */}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showSnack}
        autoHideDuration={6000}
        onClose={() => {
          setShowSnack(false);
        }}
        message={snackTxt}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                setShowSnack(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
