import { createAsyncThunk } from "@reduxjs/toolkit";

import { getRedux } from "../../../utils/old/api";

export const fetchCollectionMaterialPrices = createAsyncThunk(
  "collectionMaterialPricesList/fetch",
  async (payload: { ownerType: string; ownerId: number }, thunkAPI) => {
    const url: string = `/material-price?${payload.ownerType}_id=${payload.ownerId}`;

    try {
      const response = await getRedux(url);
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
