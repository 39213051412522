import { forwardRef, useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { Box, Divider } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import CustomButton from "../../buttons/default/CustomButton";
import CustomText from "../../texts/CustomText";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import CustomSearchFilters from "../../headers/default/CustomSearchFilters";
import { ReactComponent as BlueCheckIcon } from "../../../../assets/icons/modals/blueCheck.svg";
import CollectionsWarehouseFilterModalLayout from "../../../../layouts/collections/modals/CollectionsWarehouseFilterModalLayout";
import { fetchCitiesList } from "../../../../redux/thunks/cities/citiesListThunk";
import {
  resetWarehouseFilter,
  setCitySelected,
} from "../../../../redux/slices/collections/collectionsListWarehouseFilterSlice";
import { reset as resetCitiesList } from "../../../../redux/slices/cities/citiesListSlice";
import {
  setCollectionsWarehouseFilterModalState,
  reset as resetWarehouseFilterModalStates,
} from "../../../../redux/slices/collections/collectionWarehouseFilterModalStatesSlice";
import { CityType } from "../../../../interfaces/data/cityInterface";
import {
  setCityCollectionsListFilterQuery,
  setSelectedCityFilter,
} from "../../../../redux/slices/collections/collectionsListOtherFiltersBySlice";

export default forwardRef(function SelectCityWarehouseFilterModal() {
  const { PP } = usePixelPerfect();
  const { citiesList, totalRows, page } = useAppSelector(
    (state) => state.citiesList
  );
  const { citySelected } = useAppSelector(
    (state) => state.collectionsListWarehouseFilter
  );

  const [selectedCity, setSelectedCity] = useState(citySelected);
  const [cityFilterQuery, setCityFilterQuery] = useState("");

  const [loadMore, setLoadMore] = useState(false);

  const handleLoadMore = () => {
    setLoadMore(true);
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    setLoadMore(true);
  }, [dispatch]);

  const getCitiesList = useCallback(async () => {
    if (loadMore) {
      setLoadMore(false);
      await dispatch(
        fetchCitiesList({
          page: page,
        })
      );
    }
  }, [page, loadMore, dispatch]);

  useEffect(() => {
    getCitiesList();
  }, [getCitiesList]);

  const handleCloseClickModal = (event: React.MouseEvent<SVGSVGElement>) => {
    dispatch(resetWarehouseFilter());
    dispatch(resetCitiesList());
    dispatch(resetWarehouseFilterModalStates());
  };

  const handleBackModal = () => {
    dispatch(
      setCollectionsWarehouseFilterModalState({
        id: "selectCity",
        value: false,
      })
    );
  };

  const handleSaveCity = useCallback(() => {
    dispatch(setCitySelected(selectedCity));
    //para filter control slice
    dispatch(setSelectedCityFilter(selectedCity));
    dispatch(setCityCollectionsListFilterQuery(cityFilterQuery));
    handleBackModal();
  }, [selectedCity, dispatch]);

  const handleSetSelected = (selectedCity: CityType) => {
    setSelectedCity(selectedCity);
    setCityFilterQuery(`cityId=${selectedCity?.id}&`);
  };

  useEffect(() => {}, [selectedCity, dispatch]);

  return (
    <CollectionsWarehouseFilterModalLayout handleClose={handleCloseClickModal}>
      <CustomText
        showBackArrowButton
        bigSize
        premediumWeight
        color="#1C1C1C"
        onClick={handleBackModal}
        style={{ marginBottom: PP(24) }}
      >
        Seleccionar ciudad
      </CustomText>
      <Divider style={{ marginBottom: PP(24) }} />
      <CustomSearchFilters
        about="onlySearch"
        placeholder={"Buscar por nombre"}
        width={432}
        height={40}
        bradius={PP(4)}
        widthsearchicon={16}
        heightsearchicon={16}
      />
      <Box
        sx={[
          {
            borderRadius: PP(8),
            minWidth: PP(367),
            height: PP(160),
            marginBottom: PP(24),
            marginTop: PP(8),
          },
        ]}
      >
        <Box>
          <InfiniteScroll
            height={PP(160)}
            dataLength={citiesList.length}
            next={handleLoadMore}
            hasMore={totalRows >= 10 * page}
            loader={<CustomText color="#969696">cargando</CustomText>}
          >
            {citiesList.map((city, key) => (
              <Box
                onClick={() => handleSetSelected(city)}
                key={key}
                sx={[
                  {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    padding: PP(16),
                    color: "white",
                    height: PP(40),
                    "&:hover": {
                      backgroundColor: "#ECF1FF",
                      cursor: "pointer",
                      borderRadius: PP(4),
                    },
                  },
                  selectedCity !== null &&
                    selectedCity.id === city.id && {
                      backgroundColor: "#ECF1FF",
                      borderRadius: PP(4),
                    },
                ]}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box>
                    <CustomText
                      noSpacing
                      color={
                        selectedCity !== null && selectedCity.id === city.id
                          ? "#4463B8"
                          : "#1C1C1C"
                      }
                    >
                      {city?.name}
                    </CustomText>
                  </Box>
                  {selectedCity !== null && selectedCity.id === city.id && (
                    <span>
                      <BlueCheckIcon />
                    </span>
                  )}
                </Box>
              </Box>
            ))}
          </InfiniteScroll>
        </Box>
      </Box>

      <CustomButton
        width={432}
        height={44}
        label="Seleccionar"
        onClick={handleSaveCity}
      />
      <CustomButton
        noSpacing
        width={432}
        height={44}
        cancelButton
        onClick={handleBackModal}
      />
    </CollectionsWarehouseFilterModalLayout>
  );
});
