import { createAsyncThunk } from "@reduxjs/toolkit";

import { destroyRedux } from "../../../utils/old/api";

export const fetchDeleteMaterialTransaction = createAsyncThunk(
  "deleteMaterialTransaction/fetchDelete",
  async (
    payload: { materialId: number; transactionId: string | undefined },
    thunkAPI
  ) => {
    try {
      const response = await destroyRedux(
        `/transaction/material/${payload.transactionId}?materialId=${payload.materialId}`
      );
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    } catch (error) {
      const errors = thunkAPI.rejectWithValue(error);
      // console.log(errors);
      return errors;
    }
  }
);
