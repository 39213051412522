import { createSlice } from "@reduxjs/toolkit";

import { fetchCreateTransformation } from "../../../thunks/inventory/transformations/createTransformationThunk";

const initialState = {
  loading: false,
  status: -1,
  statusText: "",
  transformId: -1,
};

export const createTransformationSlice = createSlice({
  name: "createTransformationReducer",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = -1;
      state.statusText = "";
    },
    resetCreateTransformation: () => initialState,
  },
  extraReducers: (builder) => {
    // Crear nueva transformación
    builder.addCase(fetchCreateTransformation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCreateTransformation.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.statusText = action.payload.statusText;
      state.loading = false;
      state.transformId = action.payload.data.id;
    });
    builder.addCase(fetchCreateTransformation.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { resetStatus, resetCreateTransformation } =
  createTransformationSlice.actions;

export default createTransformationSlice.reducer;
