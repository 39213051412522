import { LinearProgress, Paper, Table, TableContainer } from "@mui/material";

import { tableHeadCellType } from "../../../../interfaces/data/tableHeadCellInterface";
import CustomTableBody from "./CustomTableBody";
import CustomTableHead from "./CustomTableHead";
import CustomTablePagination from "./CustomTablePagination";

type CompleteTableProps = {
  status: "idle" | "loading" | "succeeded" | "failed";
  rowsPerPageOptions?: number[];
  totalRows: number;
  rowsPerPage: number;
  page: number;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  tableHeadCells: tableHeadCellType[];
  setPage: (page: number) => void;
  children: React.ReactNode;
};

export default function CustomCompleteTable(props: CompleteTableProps) {
  const {
    status,
    tableHeadCells,
    totalRows,
    rowsPerPage,
    page,
    handleChangePage,
    setPage,
    children,
  } = props;

  return (
    <Paper style={{ width: "100%" }}>
      {status === "loading" && <LinearProgress />}
      <TableContainer style={{ width: "100%" }}>
        <Table
          style={{ width: "100%" }}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          <CustomTableHead tableHeadCells={tableHeadCells} />
          <CustomTableBody tableRow={children} />
        </Table>
      </TableContainer>
      <CustomTablePagination
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        setPage={setPage}
        onPageChange={(event, newPage) => handleChangePage(event, newPage)}
      />
    </Paper>
  );
}
