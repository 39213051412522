import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Box, Divider, Paper } from "@mui/material";

import { ReactComponent as EditIcon } from "../../assets/icons/buttons/edit.svg";
import CustomText from "../custom/texts/CustomText";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import { fetchCollectorsList } from "../../redux/thunks/collectors/collectorsListThunk";
import { fetchWarehouseDetails } from "../../redux/thunks/warehouse/warehouseDetailsThunk";
import { firstLetterCapitalizeFormat } from "../../utils/formats/firstLetterCapitalizeFormat";
import { setCollectionEditStateModalState } from "../../redux/slices/collections/collectionDetailsInfoModalStatesSlice";
import { fetchCollectionDetails } from "../../redux/thunks/collections/collectionDetailsThunk";
import CustomCollectorPickerInput from "../custom/forms/collections/CustomCollectorPickerInput";
import { useTranslation } from "react-i18next";

export default function CollectionAssignmentDetail() {
  const { id } = useParams();
  const { PP } = usePixelPerfect();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { collectionDetails } = useAppSelector(
    (state) => state.collectionDetails
  );
  const { warehouseDetails } = useAppSelector(
    (state) => state.warehouseDetails
  );

  const getCollectorsList = async () => {
    await dispatch(fetchCollectorsList());
  };

  const getWarehouseDetails = async (): Promise<void> => {
    if (collectionDetails && collectionDetails.warehouseId) {
      dispatch(fetchWarehouseDetails({ id: collectionDetails.warehouseId }));
    }
  };

  const getCollectionDetails = useCallback(async () => {
    if (id !== undefined) await dispatch(fetchCollectionDetails({ id: id }));
  }, [collectionDetails]);

  useEffect(() => {
    getCollectorsList();
  }, [dispatch]);

  useEffect(() => {
    if (collectionDetails && collectionDetails.warehouseId) {
      getWarehouseDetails();
    }
  }, [collectionDetails, dispatch]);

  useEffect(() => {
    if (id !== undefined) {
      getCollectionDetails();
    }
  }, [dispatch, id]);



  return (
    <Box marginLeft={PP(14.71)} width="30%" marginTop={PP(20)}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="flex-end"
        justifyContent="space-between"
        width="100%"
        height={PP(72)}
        marginBottom={PP(16)}
      >
        <CustomText color="#7D7D7D" noSpacing>
          {t("collectionAssignmentDetail.Detalle de asignación")}
        </CustomText>
        {collectionDetails.collectorId !== null &&
          (collectionDetails.state === "pending" ||
            collectionDetails.state === "collected" ||
            (collectionDetails.state === "assigned" && (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: `${0} ${PP(16)}`,
                }}
                onClick={() => {
                  dispatch(
                    setCollectionEditStateModalState({
                      id: "editAssignmentDetail",
                      value: true,
                    })
                  );
                }}
              >
                <EditIcon
                  style={{
                    width: PP(24),
                    height: PP(24),
                    cursor: "pointer",
                    marginRight: PP(8),
                  }}
                />
                <CustomText
                  mediumWeight
                  color="#969696"
                  noSpacing
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: PP(24),
                    cursor: "pointer",
                  }}
                >
                  {t("collectionAssignmentDetail.Editar")}
                </CustomText>
              </Box>
            )))}
      </Box>
      <Paper
        style={{
          border: `${PP(1)} solid #E8E8E8`,
          padding: `${PP(24)} ${PP(24)}`,
          width: "100%",
          marginBottom: PP(16),
          borderRadius: PP(8),
          height: "auto",
        }}
      >
        <CustomText
          mediumSize
          premediumWeight
          color="#7D7D7D"
          noSpacing
          style={{ marginBottom: PP(8) }}
        >
          {t("collectionAssignmentDetail.Bodega asignada")}
        </CustomText>
        <CustomText color="#4D4D4D" noSpacing>
          {firstLetterCapitalizeFormat(warehouseDetails?._source.name)}
        </CustomText>
        <Divider style={{ marginTop: PP(16), marginBottom: PP(16) }} />
        <CustomText
          mediumSize
          premediumWeight
          color="#7D7D7D"
          noSpacing
          style={{ marginBottom: PP(8) }}
        >
          {t("collectionAssignmentDetail.Recolector asignado")}
        </CustomText>

        <CustomCollectorPickerInput required id="collectorId" />

      </Paper>
    </Box>
  );
}
