import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { Box, Chip, Divider } from "@mui/material";

import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import CustomText from "../custom/texts/CustomText";
import { ReactComponent as DollarIcon } from "../../assets/icons/chips/dollarSign.svg";
import { ReactComponent as CreditCardIcon } from "../../assets/icons/chips/creditCard.svg";
import { useAppDispatch, useAppSelector } from "../../redux/store";

export default function CollectionMoneyInformation() {
  const { PP } = usePixelPerfect();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { collectionDetails, status } = useAppSelector(
    (state) => state.collectionDetails
  );

  const idsCashMaterials = [1, 2, 3, 4, 5]; // IDs de los materiales que en caso de ser pago mixto, se pagan en efectivo
  // Filtrar los elementos que cumplan los IDs deseados

  let cashTotal = 0;

  let bankTransferTotal = 0;

  if (collectionDetails.payment_method === "mixed") {
    // Cálculos para mixed
    const cashCollectionMaterials = collectionDetails.items.filter((item) =>
      idsCashMaterials.includes(item.materialId)
    );

    const bankTransferCollectionMaterials = collectionDetails.items.filter(
      (item) => !idsCashMaterials.includes(item.materialId)
    );

    cashTotal = cashCollectionMaterials.reduce(
      (acumulador, item) => acumulador + item.total,
      0
    );
    bankTransferTotal = bankTransferCollectionMaterials.reduce(
      (acumulador, item) => acumulador + item.total,
      0
    );
  } else if (collectionDetails.payment_method === "cash") {
    // Cálculos para cash
    cashTotal = collectionDetails.items.reduce(
      (acumulador, item) => acumulador + item.total,
      0
    );
  } else if (collectionDetails.payment_method === "bank_transfer") {
    // Cálculos para bank_transfer
    bankTransferTotal = collectionDetails.items.reduce(
      (acumulador, item) => acumulador + item.total,
      0
    );
  }

  useEffect(() => { }, [collectionDetails.items, dispatch]);

  return (
    <Box width="48.5%">
      <Box>
        <Box
          width="100%"
          height="100%"
          sx={{
            border: `${PP(1)} solid #E8E8E8`,
            borderRadius: PP(8),
            marginLeft: PP(16),
            padding: PP(24),
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginBottom: PP(8),
            }}
          >
            <CustomText noSpacing color="#969696" mediumSize premediumWeight>
              {t("collectionRequestInformation.collectionMoneyInformation.Total Pagado")}
            </CustomText>
            <CustomText noSpacing color="#969696" mediumSize premediumWeight>
              {t("collectionRequestInformation.collectionMoneyInformation.Método de pago")}
            </CustomText>
          </Box>
          {collectionDetails.payment_method === "cash" ? (
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <CustomText color="#1C1C1C">
                $ {cashTotal.toLocaleString("es-MX")}
              </CustomText>
              <Chip
                style={{
                  borderRadius: PP(100),
                  borderWidth: PP(1),
                  width: "auto",
                  height: "auto",
                }}
                // @ts-ignore
                variant={"collected"}
                icon={
                  <DollarIcon
                    style={{
                      width: PP(18),
                      height: PP(18),
                    }}
                  />
                }
                label={
                  <CustomText
                    noSpacing
                    mediumWeight
                    style={{ fontSize: PP(14), margin: `${PP(5)} 0px` }}
                  >
                    {t("collectionRequestInformation.collectionMoneyInformation.Efectivo")}
                  </CustomText>
                }
              />
            </Box>
          ) : collectionDetails.payment_method === "bank_transfer" ? (
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <CustomText color="#1C1C1C">
                $ {bankTransferTotal.toLocaleString("es-MX")}
              </CustomText>
              <Chip
                style={{
                  borderRadius: PP(100),
                  borderWidth: PP(1),
                  width: "auto",
                  height: "auto",
                }}
                // @ts-ignore
                variant={"collected"}
                icon={
                  <CreditCardIcon style={{ width: PP(18), height: PP(18) }} />
                }
                label={
                  <CustomText
                    noSpacing
                    mediumWeight
                    style={{ fontSize: PP(14), margin: `${PP(5)} 0px` }}
                  >
                    {t("collectionRequestInformation.collectionMoneyInformation.Transferencia")}
                  </CustomText>
                }
              />
            </Box>
          ) : (
            <>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <CustomText color="#1C1C1C">
                  $ {cashTotal.toLocaleString("es-MX")}
                </CustomText>
                <Chip
                  style={{
                    borderRadius: PP(100),
                    borderWidth: PP(1),
                    width: "auto",
                    height: "auto",
                  }}
                  // @ts-ignore
                  variant={"collected"}
                  icon={
                    <DollarIcon
                      style={{
                        width: PP(18),
                        height: PP(18),
                      }}
                    />
                  }
                  label={
                    <CustomText
                      noSpacing
                      mediumWeight
                      style={{ fontSize: PP(14), margin: `${PP(5)} 0px` }}
                    >
                      {t("collectionRequestInformation.collectionMoneyInformation.Efectivo")}
                    </CustomText>
                  }
                />
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <CustomText color="#1C1C1C">
                  $ {bankTransferTotal.toLocaleString("es-MX")}
                </CustomText>
                <Chip
                  style={{
                    borderRadius: PP(100),
                    borderWidth: PP(1),
                    width: "auto",
                    height: "auto",
                  }}
                  // @ts-ignore
                  variant={"collected"}
                  icon={
                    <CreditCardIcon style={{ width: PP(18), height: PP(18) }} />
                  }
                  label={
                    <CustomText
                      noSpacing
                      mediumWeight
                      style={{ fontSize: PP(14), margin: `${PP(5)} 0px` }}
                    >
                      {t("collectionRequestInformation.collectionMoneyInformation.Transferencia")}
                    </CustomText>
                  }
                />
              </Box>
            </>
          )}

          <Box style={{ padding: `0px ${PP(1)} ` }}>
            <Divider />
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {" "}
            <CustomText
              noSpacing
              premediumWeight
              color="#1C1C1C"
              style={{ fontSize: PP(20) }}
            >
              $ {(bankTransferTotal + cashTotal).toLocaleString("es-MX")}
            </CustomText>
            <CustomText
              noSpacing
              premediumWeight
              color="#969696"
              style={{
                height: PP(32),
                display: "flex",
                alignItems: "center",
              }}
            >
              &nbsp;COP
            </CustomText>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
