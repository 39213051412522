export const customMuiPickersToolbar = {
  styleOverrides: {
    toolbar: {
      "& h4": {
        fontSize: "2.125rem",
        fontWeight: 400,
      },
    },
  },
};
