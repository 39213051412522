import { createSlice } from "@reduxjs/toolkit";
import { set } from "date-fns";




interface MaterialToRemove {
    materialId: number,
    quantityMaterial: number,
    name: string,
    materialNameTranslations: string,
    materialMeasure: string,
    type: string,
}

interface InitialState {
    selectedToDeleteMaterialName: string;
    selectedToDeleteTransformationMaterialId: number;
    selectedToDeleteMaterial: null | MaterialToRemove;
    loading: boolean;
    status: number;
    statusText: string;
}

const initialState: InitialState = {
    selectedToDeleteMaterial: null,
    selectedToDeleteMaterialName: "",
    selectedToDeleteTransformationMaterialId: -1,
    loading: false,
    status: -1,
    statusText: "",
};

export const deleteMaterialTransformationSlice = createSlice({
    name: "deleteMaterialTransformationReducer",
    initialState,
    reducers: {
        resetStatus: (state) => {
            state.status = -1;
            state.statusText = "";
        },
        setSelectedToDeleteTransformationMaterialId: (state, action) => {
            state.selectedToDeleteTransformationMaterialId = action.payload;
        },
        setSelectedToDeleteMaterialName: (state, action) => {
            state.selectedToDeleteMaterialName = action.payload;
        },
        setSelectedToDeleteMaterial: (state, action) => {
            state.selectedToDeleteMaterial = action.payload;
        },
        resetDeleteMaterialTransformation: () => initialState,
    },
});

export const {
    resetStatus,
    setSelectedToDeleteMaterial,
    setSelectedToDeleteMaterialName,
    resetDeleteMaterialTransformation,
    setSelectedToDeleteTransformationMaterialId,
} = deleteMaterialTransformationSlice.actions;

export default deleteMaterialTransformationSlice.reducer;
