import { createSlice } from "@reduxjs/toolkit";
import { fetchAddMaterialTransfer } from "../../../thunks/inventory/transfer/addMaterialTransferThunk";

const initialState = {
  loading: false,
  status: -1,
  statusText: "",
  form: {
    category: "",
    materialId: -1,
    quantityOrigin: 0,
    quantityDestination: 0,
    transferId: -1,
  },
};

export const addMaterialTransferSlice = createSlice({
  name: "addMaterialTransferReducer",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = -1;
      state.statusText = "";
    },
    setFormAddTransferMaterial: (state, action) => {
      const { id, value } = action.payload;
      state.form = {
        ...state.form,
        [id]: value,
      };
    },

    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Añadir nuevo material a la transferencia
    builder.addCase(fetchAddMaterialTransfer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAddMaterialTransfer.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.statusText = action.payload.statusText;
      state.loading = false;
    });
    builder.addCase(fetchAddMaterialTransfer.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { resetStatus, reset, setFormAddTransferMaterial } =
  addMaterialTransferSlice.actions;

export default addMaterialTransferSlice.reducer;
