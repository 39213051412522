import { forwardRef, useCallback } from "react";

import { Divider } from "@mui/material";

import CustomText from "../../texts/CustomText";
import useAppDispatch from "../../../../hooks/old/useAppDispatch";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import MaterialsModalLayout from "../../../../layouts/MaterialsModalLayout";
import { useTranslation } from "react-i18next";
import { resetDeleteMaterialTransfer } from "../../../../redux/slices/inventory/transfer/deleteMaterialTransferSlice";
import { setTransferMaterialsModalState } from "../../../../redux/slices/inventory/transfer/transferMaterialsModalsStatesSlice";
import DeleteMaterialTransactionForm from "../../../transactions/DeleteMaterialTransactionForm";

export default forwardRef(function CustomDeleteTransactionMaterialModal() {
  const dispatch = useAppDispatch();
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();

  const resetForm = useCallback(async () => {
    dispatch(resetDeleteMaterialTransfer());
    dispatch(
      setTransferMaterialsModalState({ id: "deleteMaterial", value: false })
    );
  }, [dispatch]);

  const handleClickCloseModal = (event: React.MouseEvent<SVGSVGElement>) => {
    resetForm();
  };

  return (
    <MaterialsModalLayout handleClose={handleClickCloseModal}>
      <CustomText
        noSpacing
        bigSize
        premediumWeight
        color="#1C1C1C"
        style={{ marginBottom: PP(24) }}
        onClick={resetForm}
      >
        {t("Eliminar material")}
      </CustomText>
      <Divider sx={{ marginBottom: "24px" }} />
      <DeleteMaterialTransactionForm />
    </MaterialsModalLayout>
  );
});
