import { forwardRef, Fragment, useCallback } from "react";

import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "../../../../../redux/store";
import usePixelPerfect from "../../../../../hooks/old/usePixelPerfect";
import MaterialsModalLayout from "../../../../../layouts/MaterialsModalLayout";
import CustomText from "../../../texts/CustomText";
import { setTransformationMaterialsModalStates } from "../../../../../redux/slices/inventory/transformation/transformationMaterialsModalsStatesSlice";
import { resetEditTransformationMaterial } from "../../../../../redux/slices/inventory/transformation/editMaterialTransformationSlice";
import EditMaterialTransformationForm from "../../../../inventory/transformations/EditMaterialTransformationForm";


export default forwardRef(function CustomEditTransformationMaterialModal() {
    const dispatch = useAppDispatch();
    const { PP } = usePixelPerfect();
    const { t } = useTranslation();

    const resetForm = useCallback(async () => {
        dispatch(resetEditTransformationMaterial());
        dispatch(
            setTransformationMaterialsModalStates({ id: "editQuantity", value: false })
        );
    }, [dispatch]);

    const handleClickCloseModal = (event: React.MouseEvent<SVGSVGElement>) => {
        resetForm();
    };

    return (
        <MaterialsModalLayout handleClose={handleClickCloseModal}>
            <Fragment>
                <CustomText
                    showBackArrowButton
                    noSpacing
                    bigSize
                    premediumWeight
                    color="#1C1C1C"
                    style={{ marginBottom: PP(24) }}
                    onClick={resetForm}
                >
                    {t("Editar información material")}
                </CustomText>
                <Divider sx={{ margin: "0px" }} />
                <EditMaterialTransformationForm />
            </Fragment>
        </MaterialsModalLayout>
    );
});
