import { useCallback } from "react";

import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { Box, Divider } from "@mui/material";

import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import CustomButton from "../custom/buttons/default/CustomButton";
import CustomCheckCollectionsStatesFilter from "../custom/forms/collections/filters/CustomCheckCollectionsStatesFilter";
import CustomSelectRangeDate from "../custom/forms/collections/filters/CustomSelectRangeDate";
import CustomSelectCollectorInput from "../custom/forms/collections/filters/CustomSelectCollectorInput";
// import CustomSelectTypeOfCollection from "../custom/forms/collections/filters/CustomSelectTypeOfCollection";
// import CustomSelectPaymentMethod from "../custom/forms/collections/filters/CustomSelectPaymentMethod";
import { setCollectionsFilterByModalState } from "../../redux/slices/collections/collectionsFilterByModalStatesSlice";
import { setCollectionsFilter } from "../../redux/slices/collections/collectionListSlice";
import {
  setPaymentMethodCollectionsListFilterChip,
  setRangeDateCollectionsListFilterChip,
  setSelectedCityFilterChip,
  setSelectedCollectorFilterChip,
  setStatesCollectionsFilterChip,
  setSubmit,
  setTypeOfMaterialCollectionsListFilterChip,
} from "../../redux/slices/collections/collectionsListOtherFiltersBySlice";
// import CustomSelectCityInput from "../custom/forms/collections/filters/CustomSelectCityInput";
import "../../styles/scrollbar/scrollBar.css";

export default function SelectOthersCollectionsFiltersForm() {
  const { PP2 } = usePixelPerfect();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  dispatch(setSubmit(false));


  const { citySelected, warehouseSelected, cityFilter, warehouseFilter } =
    useAppSelector((state) => state.collectionsListWarehouseFilter);

  const {
    statesCollectionsListFilterQuery,
    rangeDateCollectionsListFilterQuery,
    collectorCollectionsListFilterQuery,
    typeOfMaterialCollectionsListFilterQuery,
    paymentMethodCollectionsListFilterQuery,
    selectedStatesFilterArray,
    rangeDateCollectionsListFilter,
    selectedCollectorFilter,
    selectedCityFilter,
    typeOfMaterialCollectionsListFilter,
    paymentMethodCollectionsListFilter,
  } = useAppSelector((state) => state.collectionsListOtherFilters);

  const resetForm = useCallback(() => {
    dispatch(
      setCollectionsFilterByModalState({
        id: "openFilterByModal",
        value: false,
      })
    );
  }, [dispatch]);

  const handleClickCloseModal = () => {
    resetForm();
  };

  const initialFormikValues = {
    cityId: citySelected?.id,
    warehouseId: warehouseSelected?.id,
  };

  const handleSubmit = useCallback(() => {
    const updatedCollectionsFiltersQuery =
      cityFilter +
      warehouseFilter +
      statesCollectionsListFilterQuery +
      rangeDateCollectionsListFilterQuery +
      collectorCollectionsListFilterQuery +
      typeOfMaterialCollectionsListFilterQuery +
      paymentMethodCollectionsListFilterQuery;

    dispatch(setCollectionsFilter(updatedCollectionsFiltersQuery));
    dispatch(setStatesCollectionsFilterChip(selectedStatesFilterArray));
    dispatch(
      setRangeDateCollectionsListFilterChip(rangeDateCollectionsListFilter)
    );
    dispatch(setSelectedCollectorFilterChip(selectedCollectorFilter));
    dispatch(setSelectedCityFilterChip(selectedCityFilter));
    dispatch(
      setTypeOfMaterialCollectionsListFilterChip(
        typeOfMaterialCollectionsListFilter
      )
    );
    dispatch(
      setPaymentMethodCollectionsListFilterChip(
        paymentMethodCollectionsListFilter
      )
    );
    dispatch(setSubmit(true));
    handleClickCloseModal();
  }, [
    cityFilter,
    warehouseFilter,
    statesCollectionsListFilterQuery,
    rangeDateCollectionsListFilterQuery,
    collectorCollectionsListFilterQuery,
    typeOfMaterialCollectionsListFilterQuery,
    paymentMethodCollectionsListFilterQuery,
  ]);

  const formikCollectionsFilters = useFormik({
    initialValues: initialFormikValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validateOnChange: false,
  });

  return (
    <form noValidate onSubmit={formikCollectionsFilters.handleSubmit}>
      <Box style={{ overflow: "auto", height: PP2(450) }}>
        <CustomCheckCollectionsStatesFilter width={425} />
        <Divider />
        <CustomSelectCollectorInput label={t("collectionsFilterByModal.selectOthersCollectionsFiltersForm.Recolector")} width={425} />
        <Divider />
        <CustomSelectRangeDate label={t("collectionsFilterByModal.selectOthersCollectionsFiltersForm.Fecha de recolección")} width={425} />
        <Divider />
        {/* <CustomSelectCityInput
          label="Ciudad"
          required
          id="cityId"
          width={425}
        />
        <Divider /> */}
        {/* <CustomSelectTypeOfCollection label="Tipo de recolección" width={425} /> */}
        {/* <Divider />
        <CustomSelectPaymentMethod label="Método de pago" width={425} /> */}
      </Box>
      <CustomButton
        // width={425}
        // height={44}
        noSpacing
        style={{ padding: "0px", marginTop: PP2(24) }}
        type="submit"
        label={t("collectionsFilterByModal.selectOthersCollectionsFiltersForm.Aplicar")}
        formik={formikCollectionsFilters}
      />
      <CustomButton
        noSpacing
        // width={425}
        // height={44}
        cancelButton
        onClick={handleClickCloseModal}
        formik={formikCollectionsFilters}
      />
    </form>
  );
}
