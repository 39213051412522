import { forwardRef, useCallback } from "react";

import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "../../../../../redux/store";
import usePixelPerfect from "../../../../../hooks/old/usePixelPerfect";
import { setTransformationMaterialsModalStates } from "../../../../../redux/slices/inventory/transformation/transformationMaterialsModalsStatesSlice";
import MaterialsModalLayout from "../../../../../layouts/MaterialsModalLayout";
import CustomText from "../../../texts/CustomText";
import { resetDeleteMaterialTransformation } from "../../../../../redux/slices/inventory/transformation/deleteMaterialTransformationSlice";
import DeleteMaterialTransformationForm from "../../../../inventory/transformations/DeleteMaterialTransformationForm";



export default forwardRef(function CustomDeleteTransformationMaterialModal() {
    const dispatch = useAppDispatch();
    const { PP } = usePixelPerfect();
    const { t } = useTranslation();

    const resetForm = useCallback(async () => {
        dispatch(resetDeleteMaterialTransformation());
        dispatch(
            setTransformationMaterialsModalStates({ id: "deleteMaterial", value: false })
        );
    }, [dispatch]);

    const handleClickCloseModal = (event: React.MouseEvent<SVGSVGElement>) => {
        resetForm();
    };

    return (
        <MaterialsModalLayout handleClose={handleClickCloseModal}>
            <CustomText
                noSpacing
                bigSize
                premediumWeight
                color="#1C1C1C"
                style={{ marginBottom: PP(24) }}
                onClick={resetForm}
            >
                {t("Eliminar material")}
            </CustomText>
            <Divider sx={{ marginBottom: "24px" }} />
            <DeleteMaterialTransformationForm />
        </MaterialsModalLayout>
    );
});
