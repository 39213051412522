import { forwardRef, useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { Box, Divider } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import CustomButton from "../../buttons/default/CustomButton";
import CustomText from "../../texts/CustomText";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import CustomSearchFilters from "../../headers/default/CustomSearchFilters";
import { ReactComponent as BlueCheckIcon } from "../../../../assets/icons/modals/blueCheck.svg";
import CollectionsWarehouseFilterModalLayout from "../../../../layouts/collections/modals/CollectionsWarehouseFilterModalLayout";
import { fetchWarehousesList } from "../../../../redux/thunks/warehouse/warehousesListThunk";
import { reset as resetCitiesList } from "../../../../redux/slices/cities/citiesListSlice";
import {
  reset as resetCollectionWarehouseFilterModalStates,
  setCollectionsWarehouseFilterModalState,
} from "../../../../redux/slices/collections/collectionWarehouseFilterModalStatesSlice";
import {
  setWarehouseFilter,
  setWarehouseSelected,
} from "../../../../redux/slices/collections/collectionsListWarehouseFilterSlice";
import { WarehouseType } from "../../../../interfaces/data/warehouseInterface";
import { useTranslation } from "react-i18next";

export default forwardRef(function SelectWarehouseOfListModal() {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const { page, totalWarehousesList, warehousesList } = useAppSelector(
    (state) => state.warehouseList
  );

  const { warehouseSelected, citySelected, warehouseFilter } = useAppSelector(
    (state) => state.collectionsListWarehouseFilter
  );

  const [selectedWarehouse, setSelectedWarehouse] = useState(warehouseSelected);
  const [warehouseFilterQuery, setWarehouseFilterQuery] = useState("");

  const [loadMore, setLoadMore] = useState(false);

  const handleLoadMore = () => {
    setLoadMore(true);
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    setLoadMore(true);
  }, [dispatch]);

  const getWarehousesList = useCallback(() => {
    if (loadMore) {
      setLoadMore(false);
      dispatch(
        fetchWarehousesList({
          page: page,
        })
      );
    }
  }, [page, loadMore, dispatch]);

  useEffect(() => {
    getWarehousesList();
  }, [getWarehousesList, citySelected]);

  const handleCloseClickModal = (event: React.MouseEvent<SVGSVGElement>) => {
    dispatch(resetCitiesList());
    dispatch(resetCollectionWarehouseFilterModalStates());
  };

  const handleBackModal = () => {
    dispatch(
      setCollectionsWarehouseFilterModalState({
        id: "selectWarehouse",
        value: false,
      })
    );
    dispatch(
      setCollectionsWarehouseFilterModalState({
        id: "openWarehouseFilterModal",
        value: true,
      })
    );
  };

  const handleSaveWarehouse = () => {
    dispatch(setWarehouseSelected(selectedWarehouse));
    dispatch(setWarehouseFilter(warehouseFilterQuery));
    handleBackModal();
  };

  const handleSetSelected = (selectedWarehouse: WarehouseType) => {
    setSelectedWarehouse(selectedWarehouse);
    setWarehouseFilterQuery(`warehouseId=${selectedWarehouse.id}&`);
  };

  useEffect(() => {}, [warehouseSelected, dispatch]);  

  return (
    <CollectionsWarehouseFilterModalLayout handleClose={handleCloseClickModal}>
      <CustomText
        showBackArrowButton
        bigSize
        premediumWeight
        color="#1C1C1C"
        onClick={handleBackModal}
        style={{ marginBottom: PP(24) }}
      >
        {t(
          "collectionWarehouseFilterModal.selectWarehouseOfListModal.Seleccionar bodega"
        )}
      </CustomText>
      <Divider style={{ marginBottom: PP(24) }} />
      <CustomSearchFilters
        about="onlySearch"
        placeholder={"Buscar por nombre"}
        width={432}
        height={40}
        bradius={PP(4)}
        widthsearchicon={16}
        heightsearchicon={16}
      />
      <Box
        sx={[
          {
            borderRadius: PP(8),
            minWidth: PP(367),
            height: PP(160),
            marginBottom: PP(24),
            marginTop: PP(8),
          },
        ]}
      >
        <Box>
          <InfiniteScroll
            height={PP(160)}
            dataLength={warehousesList.length}
            next={handleLoadMore}
            hasMore={totalWarehousesList >= 10 * page}
            loader={
              <CustomText color="#969696">
                {t(
                  "collectionWarehouseFilterModal.selectWarehouseOfListModal.cargando"
                )}
              </CustomText>
            }
          >
            {warehousesList.map((warehouse, key) => (
              <Box
                onClick={() => handleSetSelected(warehouse)}
                key={key}
                sx={[
                  {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    padding: PP(16),
                    color: "white",
                    height: PP(40),
                    "&:hover": {
                      backgroundColor: "#ECF1FF",
                      cursor: "pointer",
                      borderRadius: PP(4),
                    },
                  },
                  selectedWarehouse !== null &&
                    selectedWarehouse.id === warehouse.id && {
                      backgroundColor: "#ECF1FF",
                      borderRadius: PP(4),
                    },
                ]}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box>
                    <CustomText
                      noSpacing
                      color={
                        selectedWarehouse !== null &&
                        selectedWarehouse.id === warehouse.id
                          ? "#4463B8"
                          : "#1C1C1C"
                      }
                    >
                      {warehouse.name}
                    </CustomText>
                  </Box>
                  {selectedWarehouse !== null &&
                    selectedWarehouse.id === warehouse.id && (
                      <span>
                        <BlueCheckIcon />
                      </span>
                    )}
                </Box>
              </Box>
            ))}
          </InfiniteScroll>
        </Box>
      </Box>

      <CustomButton
        width={432}
        height={44}
        label={t(
          "collectionWarehouseFilterModal.selectWarehouseOfListModal.Seleccionar"
        )}
        onClick={handleSaveWarehouse}
      />
      <CustomButton
        noSpacing
        width={432}
        height={44}
        cancelButton
        onClick={handleBackModal}
      />
    </CollectionsWarehouseFilterModalLayout>
  );
});
