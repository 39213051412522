import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { RemoveRedEye as RemoveRedEyeIcon } from "@mui/icons-material";
import styled from "@emotion/styled";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const headCells = [
  { id: "point", alignment: "left", label: "Punto", disablePadding: true },
  {
    id: "ownerName",
    alignment: "center",
    label: "Cliente",
    disablePadding: true,
  },
  {
    id: "addressDescription",
    alignment: "center",
    label: "Dirección",
    disablePadding: true,
  },
  {
    id: "ownerPhone",
    alignment: "center",
    label: "Teléfono",
    disablePadding: true,
  },
  {
    id: "observation",
    alignment: "center",
    label: "Observación",
    disablePadding: true,
  },
  {
    id: "action",
    alignment: "center",
    label: "Acción",
    disablePadding: true,
  },
];

export default function PointsListSelectedCollector() {
  const { pointsCollectorModal } = useSelector(
    (state) => state.googleMapsModal
  );

  return (
    <TableWrapper>
      <Table style={{ marginTop: "2em" }}>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.alignment}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {pointsCollectorModal &&
            pointsCollectorModal.map((point, index) => {
              return (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {point.ownerName}
                  </TableCell>
                  <TableCell align="center">
                    {point.addressDescription && point.addressComplement
                      ? point.addressDescription +
                        ", " +
                        point.addressComplement
                      : point.addressDescription}
                  </TableCell>
                  <TableCell align="center">{point.ownerPhone}</TableCell>
                  <TableCell align="center">
                    {point.observation !== null ? point.observation : "No"}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      aria-label="details"
                      component={NavLink}
                      to={`/collections/detail/${point.collectionId}`}
                      size="large"
                      target="_blank"
                    >
                      <RemoveRedEyeIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableWrapper>
  );
}
