import { createSlice } from "@reduxjs/toolkit";

import { TransformationType } from "../../../../interfaces/data/transformationInterface";
import { fetchTransformationList } from "../../../thunks/inventory/transformations/transformationListThunk";

const initialState: {
  status: "idle" | "loading" | "succeeded" | "failed";
  transformationList: TransformationType[];
  totalRows: number;
  filter: string;
} = {
  status: "idle",
  transformationList: [],
  totalRows: 0,
  filter: "",
};

export const transformationListSlice = createSlice({
  name: "transformationList",
  initialState,
  reducers: {
    reset: () => initialState,
    setTransformationFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    //Get lista transformaciones de inventario en general con o sin filtros
    builder.addCase(fetchTransformationList.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchTransformationList.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.transformationList = action.payload.data;
      state.totalRows = action.payload.total;
    });
    builder.addCase(fetchTransformationList.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export const { reset, setTransformationFilter } =
  transformationListSlice.actions;

export default transformationListSlice.reducer;
