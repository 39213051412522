import { forwardRef, Fragment, useCallback } from "react";

import { Divider } from "@mui/material";

import { useAppSelector } from "../../../../redux/store";
import CustomText from "../../texts/CustomText";
import useAppDispatch from "../../../../hooks/old/useAppDispatch";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import { reset as resetAddMaterial } from "../../../../redux/slices/collections/addMaterialCollectionSlice";
import { setCollectionMaterialsModalState } from "../../../../redux/slices/collections/collectionsMaterialsModalsStates";
import AddMaterialCollectionForm from "../../../collections/AddMaterialCollectionForm";
import { reset as resetMaterialsList } from "../../../../redux/slices/collections/materialsListSlice";
import { useTranslation } from "react-i18next";
import MaterialsModalLayout from "../../../../layouts/MaterialsModalLayout";

export default forwardRef(function CustomAddMaterialModal() {
  const { status } = useAppSelector((state) => state.newCollectionUser);
  const dispatch = useAppDispatch();
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();

  const resetForm = useCallback(async () => {
    dispatch(resetAddMaterial());
    dispatch(resetMaterialsList());
    dispatch(
      setCollectionMaterialsModalState({ id: "addMaterial", value: false })
    );
  }, [dispatch]);

  const handleClickCloseModal = (event: React.MouseEvent<SVGSVGElement>) => {
    resetForm();
  };

  const handleBackClick = useCallback(async () => {
    dispatch(resetAddMaterial());
    dispatch(resetMaterialsList());
    dispatch(
      setCollectionMaterialsModalState({ id: "addMaterial", value: false })
    );
    dispatch(
      setCollectionMaterialsModalState({ id: "selectCategory", value: true })
    );
  }, [dispatch]);

  return (
    <MaterialsModalLayout handleClose={handleClickCloseModal}>
      {status === -1 && (
        <Fragment>
          <CustomText
            showBackArrowButton
            noSpacing
            bigSize
            premediumWeight
            color="#1C1C1C"
            style={{ marginBottom: PP(24) }}
            onClick={handleBackClick}
          >
            {t("collectionInformation.customAddMaterialModal.Agregar material")}
          </CustomText>
          <Divider sx={{ margin: "0px" }} />
          <AddMaterialCollectionForm />
        </Fragment>
      )}
    </MaterialsModalLayout>
  );
});
