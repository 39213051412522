import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Box, Button, Divider, Grid } from "@mui/material";

import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import CustomText from "../custom/texts/CustomText";
import { ReactComponent as CrossAddIcon } from "../../assets/icons/cards/crossAdd.svg";
import { ReactComponent as PaperClipIcon } from "../../assets/icons/cards/paperclip.svg";
import { ReactComponent as BlackTrashIcon } from "../../assets/icons/buttons/blackTrash.svg";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { setCollectionAttachedFilesModalState } from "../../redux/slices/collections/collectionAttachedFilesModalStatesSlice";
import { createCollectionDateFormat } from "../../utils/formats/createCollectionDateFormat";
import { collectionFilesType } from "../../interfaces/data/collectionFilesInterface";
import { setFileSelectedToDelete } from "../../redux/slices/collections/deleteCollectionAttachFileSlice";
import CollectionFileFullScreen from "./CollectionFileFullScreen";
import CollectionUnregisteredInformation from "./CollectionUnregisteredInformation";

export default function CollectionAttachedFiles() {
  const { PP, PP2 } = usePixelPerfect();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { collectionDetails } = useAppSelector(
    (state) => state.collectionDetails
  );

  const columnWidth = "100%";
  const columnSpacing = "5%";

  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [fullSreen, setFullSreen] = useState(false);
  const [selectedFileIndex, setSelectedFileIndex] = useState(-1);

  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(-1);
  };

  const handleOpenSelectTypeFileModal = () => {
    dispatch(
      setCollectionAttachedFilesModalState({
        id: "selectTypeFile",
        value: true,
      })
    );
  };

  const handleOpenFullScreen = (index: number) => {
    setSelectedFileIndex(index);
    setFullSreen(true);
  };

  const handleOpenDeleteAttachFileModal = (file: collectionFilesType) => {
    dispatch(
      setCollectionAttachedFilesModalState({
        id: "deleteAttachFile",
        value: true,
      })
    );
    dispatch(setFileSelectedToDelete(file));
  };

  useEffect(() => { }, [setCollectionAttachedFilesModalState]);

  return (
    <Box marginTop={PP(24)}>
      <Box
        style={{
          display: "flex",
          justifyContent: "space between",
          alignItems: "center",
        }}
      >
        <CustomText
          noSpacing
          premediumWeight
          color="#1C1C1C"
          style={{ fontSize: PP(20) }}
        >
          {t("collectionAttachedFiles.Adjuntos")}
        </CustomText>
      </Box>
      {collectionDetails.state !== "pending" &&
        collectionDetails.state !== "assigned" ? (
        <>
          <Divider style={{ margin: `${PP(16)} 0px` }} />
          <Grid container spacing={columnSpacing}>
            {collectionDetails?.files?.map((file, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  xl={2}
                  key={index}
                  sx={{ height: PP(300), width: PP(300) }}
                >
                  <CustomText
                    mediumSize
                    premediumWeight
                    color="#7D7D7D"
                    style={{ border: "none" }}
                  >
                    {t(`collectionAttachedFiles.filesTypes.${file.type}`)}
                  </CustomText>
                  {/* <CustomText
                    preSmallSize
                    color="#7D7D7D"
                    style={{ border: "none" }}
                  >
                    {(new Date(file.createdAt)).toLocaleDateString() + " " + (new Date(file.createdAt)).toLocaleTimeString()}
                  </CustomText> */}
                  {fullSreen && index === selectedFileIndex && (
                    <CollectionFileFullScreen
                      fileURL={file.url}
                      fileName={file.name}
                      fileType={file.type_file}
                      setFullSreen={setFullSreen}
                    />
                  )}

                  <Box
                    sx={{
                      width: columnWidth,
                      height: "70%",
                      backgroundColor: "#FAFAFA",
                      border: `${PP2(1)} solid #DEDEDE`,
                      borderRadius: PP2(8),
                      position: "relative",
                    }}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    {file.type_file === "pdf" ? (
                      <iframe
                        src={`https://docs.google.com/viewer?url=${encodeURIComponent(
                          file.url
                        )}&embedded=true&download=no&rm=demo&viewMode=fullScreen&disableZoom=true`}
                        width="100%"
                        height="100%"
                      ></iframe>
                    ) : (
                      <img
                        src={file.url}
                        alt={file.name}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          border: "none",
                          borderRadius: PP2(8),
                        }}
                      />
                    )}
                    {collectionDetails.state === "collected" && (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          position: "absolute",
                          top: "0%",
                          left: "100%",
                          transform: "translate(-50%, -50%)",
                          zIndex: 2,
                          border: "none",
                          backgroundColor: "transparent",
                          fontSize: PP(16),
                          fontWeight: 700,
                        }}
                        onClick={() => handleOpenDeleteAttachFileModal(file)}
                      >
                        <Box
                          style={{
                            width: PP(40),
                            height: PP(40),
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: PP2(95),
                            border: `${PP2(1)} solid #DEDEDE`,
                            backgroundColor: "#FFFFFF",
                            boxShadow:
                              "0px 5px 3px -2px rgba(0, 0, 0, 0.02), 0px 3px 1px -2px rgba(0, 0, 0, 0.06)",
                          }}
                        >
                          <BlackTrashIcon />
                        </Box>
                      </Button>
                    )}
                    {hoveredIndex === index && (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          zIndex: 2,
                          border: "none",
                          backgroundColor: "transparent",
                          fontSize: PP(16),
                          fontWeight: 700,
                          cursor: "pointer",
                        }}
                        onClick={() => handleOpenFullScreen(index)}
                      >
                        <Box
                          style={{
                            width: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {t("collectionAttachedFiles.Abrir")}
                        </Box>
                      </Button>
                    )}
                    {hoveredIndex === index && (
                      <div
                        style={{
                          content: "''",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          background:
                            "linear-gradient(0deg, rgba(1, 2, 3, 0.5), rgba(1, 2, 3, 0.5))",
                          opacity: 0.7,
                          zIndex: 1,
                          borderRadius: PP2(8),
                        }}
                      ></div>
                    )}
                  </Box>
                  <CustomText preSmallSize color="#7D7D7D">
                    {t("collectionAttachedFiles.Adjunto en")} {createCollectionDateFormat(file.createdAt, t)}
                  </CustomText>
                </Grid>
              );
            })}
            {collectionDetails.state === "collected" && (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={2}
                marginTop={PP(30)}
                sx={{ height: PP(300), width: PP(300) }}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    width: columnWidth,
                    height: "100%",
                    backgroundColor: "rgba(85, 124, 230, 0.10)",
                    border: `${PP2(1)} dashed #557CE6`,
                    borderRadius: PP2(8),
                    padding: PP2(20),
                    cursor: "pointer",
                  }}
                  onClick={handleOpenSelectTypeFileModal}
                >
                  <CrossAddIcon
                    style={{
                      width: PP(24),
                      height: PP(24),
                      marginRight: PP(8),
                    }}
                  />
                  <CustomText noSpacing mediumWeight color="#557CE6">
                    {t("collectionAttachedFiles.Adjuntar nuevo")}
                  </CustomText>
                </Box>
              </Grid>
            )}
          </Grid>
        </>
      ) : (
        <Box width="100%" marginTop={PP2(16)}>
          <CollectionUnregisteredInformation
            informativeIcon={<PaperClipIcon width={PP2(62)} height={PP2(62)} />}
            text={t("collectionAttachedFiles.Todavía no se adjuntan evidencias a esta recolección")}
          />
        </Box>
      )}
    </Box>
  );
}
