import { forwardRef } from "react";

import { Divider } from "@mui/material";

import DefaultModalLayout from "../../../../layouts/collections/modals/DefaultModalLayout";
import CustomText from "../../texts/CustomText";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import CreateTransactionForm from "../../../transactions/CreateTransactionForm";
import { useTranslation } from "react-i18next";

interface CustomCreateTransactionModalProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getTransactions: () => Promise<void>;
}

export default forwardRef(function CustomCreateTransactionModal(
  props: CustomCreateTransactionModalProps
) {
  const { setOpen, getTransactions } = props;
  const { PP2 } = usePixelPerfect();
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DefaultModalLayout handleClose={handleClose}>
      <CustomText
        noSpacing
        bigSize
        premediumWeight
        color="#1C1C1C"
        style={{ marginBottom: PP2(24) }}
        // onClick={resetForm}
      >
        {t("Crear venta")}
      </CustomText>
      <Divider sx={{ margin: "0px" }} />
      <CreateTransactionForm
        setOpen={setOpen}
        getTransactions={getTransactions}
      />
    </DefaultModalLayout>
  );
});
