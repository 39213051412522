import React from "react";
import { Helmet } from "react-helmet-async";

import styled from "@emotion/styled";
import { Card, CardMedia, Paper, Typography } from "@mui/material";

import LoginLogo from "../../../../vendor/old/loginLogo.png";
import ResetPasswordComponent from "../../../../components/old/auth/old/ResetPassword";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function ResetPassword() {
  return (
    <React.Fragment>
      <Card>
        <CardMedia
          component="img"
          sx={{ marginBottom: "20px" }}
          image={LoginLogo}
          alt="LoginLogo"
        />
      </Card>
      <Wrapper>
        <Helmet title="Reset Password" />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Reset Password
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Enter your email to reset your password
        </Typography>

        <ResetPasswordComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default ResetPassword;
