import { tableHeadCellType } from "../../../interfaces/data/tableHeadCellInterface";

export const allCollectionsTableHeadCells: tableHeadCellType[] = [
  {
    id: "consecutive",
    alignment: "left",
    label: "Cons.",
    disablePadding: false,
  },
  {
    id: "date",
    alignment: "left",
    label: "Fecha",
    disablePadding: false,
  },
  {
    id: "owner",
    alignment: "left",
    label: "Generador",
    disablePadding: false,
  },
  {
    id: "city",
    alignment: "left",
    label: "Ciudad",
    disablePadding: false,
  },
  {
    id: "addressDescription",
    alignment: "left",
    label: "Punto de recolección",
    disablePadding: false,
  },
  {
    id: "state",
    alignment: "left",
    label: "Estado",
    disablePadding: false,
  },
  {
    id: "action",
    alignment: "right",
    label: "Acciones",
    disablePadding: false,
  },
];
