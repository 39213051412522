import posthog from "posthog-js";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { getRedux, postRedux } from "../../../utils/old/api";

export const fetchVerificationList = createAsyncThunk(
  "inventoryVerificationList/fetch",
  async (payload: { date: Date; warehouseId: Number }, thunkAPI) => {
    try {
      const response = await getRedux(
        `/inventory-verification?date=${payload.date}&warehouseId=${payload.warehouseId}`
      );
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchVerificationDetails = createAsyncThunk(
  "inventoryVerificationDetails/fetch",
  async (
    payload: { date: String | null; collectorId: String | null },
    thunkAPI
  ) => {
    try {
      const response = await getRedux(
        `/inventory-verification/0?date=${payload.date}&collectorId=${payload.collectorId}`
      );
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createInventoryVerification = createAsyncThunk(
  "inventoryVerificationDetails/create",
  async (
    payload: { collectorId: Number | String; date: String; userVerifier: any },
    thunkAPI
  ) => {
    try {
      const response = await postRedux(`/inventory-verification`, payload);
      console.log(response);
      await posthog.capture("inventory_verification_created", {
        data: {
          date: payload.date,
          userVerifier: {
            id: payload.userVerifier.id,
            email: payload.userVerifier.email,
          },
          collectorId: payload.collectorId,
        },
      });
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    } catch (error) {
      console.log(error);

      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createInventoryVerificationItem = createAsyncThunk(
  "inventoryVerificationDetailsItem/create",
  async (
    payload: {
      materialId: Number | String;
      quantity: Number;
      verificationId: Number | null;
    },
    thunkAPI
  ) => {
    try {
      const response = await postRedux(`/inventory-verification-item`, payload);
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
