import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { spacing } from "@mui/system";
import { green, grey, orange, red, teal, yellow } from "@mui/material/colors";
import { es } from "date-fns/locale";
import {
  Alert,
  Button,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import {
  RemoveRedEye as RemoveRedEyeIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { get } from "../../../../../utils/old/api";
import {
  fetchCreatePersonCollection,
  fetchPersonAddressList,
  fetchPersonCollections,
  setPageCollections,
  setShowSnack,
} from "../../../../../redux/old/slices/individualsSlicer";
import { collectionDateFormat } from "../../../../../utils/old/collectionDateFormat";
import {
  collectionHours,
  collectionHoursIntialTime,
} from "../../../../../utils/old/constants";
import { formatHour } from "../../../../../utils/old/collectionHoursFormat";
import { format } from "date-fns";

const Card = styled(MuiCard)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.pending && orange[700]};
  background: ${(props) => props.assigned && yellow[500]};
  background: ${(props) => props.collected && green[400]};
  background: ${(props) => props.finished && teal[400]};
  background: ${(props) => props.canceled && grey[400]};
  background: ${(props) => props.failed && red[400]};

  color: ${(props) => props.pending && props.theme.palette.common.white};
`;

export default function CollectionsCard() {
  const { id } = useParams();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [person, setPerson] = useState({});
  const [personPermissions, setPersonPermissions] = useState({});
  const [collectionModal, setCollectionModal] = useState(false);
  const [collectionHoursFinalTime, setCollectionHoursFinalTime] = useState([]);
  const [date, setDate] = useState(new Date());

  //ReduxTookit
  //const loading = useSelector((state) => state.business.showLoading);
  const collections = useSelector((state) => state.person.collections);

  const totalRowsCollections = useSelector(
    (state) => state.person.totalRowsCollections
  );
  const totalAddressesList = useSelector(
    (state) => state.person.totalAddressesList
  );
  const snackTxt = useSelector((state) => state.person.snackTxt);
  const showSnack = useSelector((state) => state.person.showSnack);
  const pageCollections = useSelector((state) => state.person.pageCollections);

  const dispatch = useDispatch();

  useEffect(() => {
    getPerson();
  }, [collectionModal]);

  const getPerson = async (cPage = 0) => {
    setLoading(true);
    get(
      `/user/${id}`,
      (response) => {
        setPerson(response.data);
        setPersonPermissions({
          collector_fog: response.data.permissions.includes("collector_fog"),
          collector_ssr: response.data.permissions.includes("collector_ssr"),
          commercial: response.data.permissions.includes("commercial"),
        });
        setLoading(false);
        dispatch(fetchPersonCollections({ cPage: cPage, id: id })); //ReduxTookit //Recolecciones
        dispatch(setPageCollections(cPage)); //ReduxToolkit reducer
        dispatch(fetchPersonAddressList(id));
      },
      (error) => {
        alert("Error obteniendo usuario");
        setLoading(false);
      }
    );
  };

  const handleChangePageCollections = async (event, newPage) => {
    dispatch(fetchPersonCollections({ cPage: newPage, id: id }));
    dispatch(setPageCollections(newPage));
  };

  const handleChangeRowsPerCollectionPage = async () => {
    dispatch(fetchPersonCollections({ cPage: 0, id: id }));
  };

  //Manejando creación de nuevas recolecciones:

  const initialValuesCollection = {
    address_id: "",
    observation: "",
    initialTime: "",
    finalTime: "",
  };

  function validationSchema() {
    return {
      address_id: Yup.number().required("Dirección requerida"),
      initialTime: Yup.string().required("Hora inicial requerida"),
      finalTime: Yup.string().required("Hora final requerida"),
    };
  }

  const formikCollection = useFormik({
    initialValues: initialValuesCollection,
    enableReinitialize: true,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) => {
      createCollection(data);
    },
  });

  const createCollection = async (data) => {
    data.date = collectionDateFormat(date);
    data.ownerId = id;
    data.ownerType = "user";
    data.admin = true;
    data.source = "management-web";
    dispatch(fetchCreatePersonCollection(data));
    setCollectionModal(false);
    dispatch(fetchPersonCollections({ cPage: 0, id: id })); //ReduxTookit //Precios personalizados
  };

  const newFinalTimeArray = (initialTime) => {
    let indexOfInitialTime = -1;

    collectionHours.map((hour, index) =>
      hour.value === initialTime
        ? (indexOfInitialTime = index)
        : indexOfInitialTime
    );
    setCollectionHoursFinalTime(collectionHours.slice(indexOfInitialTime + 1));
  };

  return (
    <React.Fragment>
      <Card mb={6}>
        <CardContent>
          <Grid justifyContent="space-between" container spacing={10}>
            <Grid item>
              <Typography variant="h6" gutterBottom>
                {t("Recolecciones Únicas")}
              </Typography>
            </Grid>
          </Grid>

          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("tables.tableHeadCells.Dirección")}</TableCell>
                  <TableCell>
                    {t("tables.tableHeadCells.Complemento")}
                  </TableCell>
                  <TableCell>{t("tables.tableHeadCells.Fecha")}</TableCell>
                  <TableCell>{t("tables.tableHeadCells.Estado")}</TableCell>
                  <TableCell>{t("tables.tableHeadCells.Acción")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {collections.map((row, index) => {
                  return (
                    <TableRow key={index} hover>
                      <TableCell>{row.addressDescription}</TableCell>
                      <TableCell>{row.addressName}</TableCell>
                      <TableCell>{row.date}</TableCell>
                      <TableCell>
                        {row.state === "pending" && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label={t("chips.states.Pendiente")}
                            pending={+true}
                          />
                        )}
                        {row.state === "assigned" && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label={t("chips.states.Asignada")}
                            assigned={+true}
                          />
                        )}
                        {row.state === "collected" && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label={t("chips.states.Recolectada")}
                            collected={+true}
                          />
                        )}
                        {row.state === "failed" && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label={t("chips.states.Fallida")}
                            failed={+true}
                          />
                        )}
                        {row.state === "finished" && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label={t("chips.states.Terminada")}
                            finished={+true}
                          />
                        )}
                        {row.state === "canceled" && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label={t("chips.states.Cancelada")}
                            canceled={+true}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="details"
                          component={NavLink}
                          to={`/collections/detail/${row.id}`}
                          size="large"
                        >
                          <RemoveRedEyeIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[20]}
              component="div"
              count={totalRowsCollections}
              rowsPerPage={20}
              page={pageCollections}
              onPageChange={handleChangePageCollections}
              onRowsPerPageChange={handleChangeRowsPerCollectionPage}
            />
          </TableWrapper>
          {collections.length === 0 && (
            <Box display="flex" justifyContent="center">
              <Typography style={{ fontSize: "16px", color: "gray" }}>
                {t("No se han creado recolecciones para este usuario")}
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>

      {/* --------------------------------Modal Crear Recolección---------------------------------------------- */}

      <Dialog
        open={collectionModal}
        onClose={() => setCollectionModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={formikCollection.handleSubmit}>
          <DialogTitle id="form-dialog-title">
            Crear una recolección
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Se le asignará a la persona actual
            </DialogContentText>
            <br />
            <Grid container spacing={6}>
              <Grid item md={12}>
                <Typography style={{ fontSize: "11px" }} color="grey">
                  Dirección
                </Typography>
                <Select
                  name="address_id"
                  label="Dirección"
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formikCollection.values.address_id}
                  onChange={formikCollection.handleChange}
                >
                  {totalAddressesList.map((element, index) => (
                    <MenuItem key={element.id} value={element.id}>
                      {" "}
                      {`${element.description} ${element.city.name}`}
                    </MenuItem>
                  ))}
                </Select>
                {formikCollection.errors.address_id && (
                  <Alert mt={2} mb={3} severity="error">
                    {formikCollection.errors.address_id}
                  </Alert>
                )}
              </Grid>
              <Grid item md={12}>
                <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                  <DatePicker
                    name="date"
                    label="Fecha"
                    my={2}
                    value={date}
                    onChange={(newValue) => {
                      setDate(format(new Date(newValue), "yyyy-MM-dd"));
                    }}
                    renderInput={(params) => (
                      <TextField {...params} style={{ width: "42.5em" }} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={6}>
                <Typography style={{ fontSize: "11px" }} color="grey">
                  Hora inicio
                </Typography>
                {formikCollection.values.initialTime === "" && (
                  <Select
                    name="initialTime"
                    label="Hora inicio"
                    fullWidth
                    variant="outlined"
                    value={formikCollection.values.initialTime}
                    onChange={(e) => {
                      newFinalTimeArray(e.target.value);
                      formikCollection.setFieldValue(
                        "initialTime",
                        e.target.value
                      );
                    }}
                  >
                    {collectionHoursIntialTime.map((element, index) => (
                      <MenuItem key={index} value={element.value}>
                        {element.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                {formikCollection.values.initialTime !== "" && (
                  <Typography>
                    {formatHour(formikCollection.values.initialTime)}
                  </Typography>
                )}
                {formikCollection.errors.initialTime && (
                  <Alert mt={2} mb={3} severity="error">
                    {formikCollection.errors.initialTime}
                  </Alert>
                )}
              </Grid>
              <Grid item md={6}>
                <Typography style={{ fontSize: "11px" }} color="grey">
                  Hora fin
                </Typography>
                <Select
                  name="finalTime"
                  label="Hora fin"
                  fullWidth
                  variant="outlined"
                  value={formikCollection.values.finalTime}
                  onChange={formikCollection.handleChange}
                >
                  {collectionHoursFinalTime.map((element, index) => (
                    <MenuItem key={index} value={element.value}>
                      {element.label}
                    </MenuItem>
                  ))}
                </Select>
                {formikCollection.errors.finalTime && (
                  <Alert mt={2} mb={3} severity="error">
                    {formikCollection.errors.finalTime}
                  </Alert>
                )}
              </Grid>{" "}
              <Grid item md={12}>
                <TextField
                  name="observation"
                  label="Observaciones"
                  id="observation"
                  multiline={true}
                  maxRows={6}
                  variant="outlined"
                  my={2}
                  style={{ width: "42.5em" }}
                  value={formikCollection.values.observation}
                  onChange={formikCollection.handleChange}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCollectionModal(false)} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              Crear
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/*--------------- Snackbar de Confirmación --------------*/}

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showSnack}
        autoHideDuration={6000}
        onClose={() => {
          dispatch(setShowSnack(false));
        }}
        message={snackTxt}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                dispatch(setShowSnack(false));
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
