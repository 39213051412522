import { createSlice } from "@reduxjs/toolkit";

import { fetchUpdateUserInformation } from "../../thunks/users/updateUserInformationThunk";

const initialState = {
  loading: false,
  status: -1,
  statusText: "",
};

export const updateUserInformationSlice = createSlice({
  name: "updateUserInformationReducer",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = -1;
      state.statusText = "";
    },

    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Actualizar información de un usuario
    builder.addCase(fetchUpdateUserInformation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUpdateUserInformation.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.statusText = action.payload.statusText;
      state.loading = false;
    });
    builder.addCase(fetchUpdateUserInformation.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { resetStatus, reset } = updateUserInformationSlice.actions;

export default updateUserInformationSlice.reducer;
