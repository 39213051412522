import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Badge, Grid, Avatar, Typography, Box } from "@mui/material";

import useAuth from "../../../../hooks/old/useAuth";
import { permisos } from "../../../../utils/old/permisos";

const Footer = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const FooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

const FooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const SidebarFooter = ({ ...rest }) => {
  const { user } = useAuth();
  const { t } = useTranslation();

  return (
    <Footer {...rest}>
      <Grid container spacing={2}>
        <Grid item>
          <FooterBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
          >
            {user ? (
              <Avatar alt={user.name} src={user.name} />
            ) : (
              <Avatar alt="Muta" src="Muta" />
            )}
          </FooterBadge>
        </Grid>
        <Grid item>
          {user && (
            <Box>
              <FooterText variant="body2">{user.name}</FooterText>
              {user.permissions.includes("admin") && (
                <FooterSubText variant="caption">
                  {t(`permissions.${permisos["admin"]}`)}
                </FooterSubText>
              )}
              {!user.permissions.includes("admin") && (
                <FooterSubText variant="caption">
                  {t(`permissions.${permisos[user.permissions[0]]}`)}
                </FooterSubText>
              )}
            </Box>
          )}

          {/* Default data */}
          {!user && (
            <Box>
              <FooterText variant="body2">Colaborador Muta</FooterText>
              <FooterSubText variant="caption">Permiso</FooterSubText>
            </Box>
          )}
        </Grid>
      </Grid>
    </Footer>
  );
};

export default SidebarFooter;
