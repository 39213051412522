import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import { ReactComponent as BlueCheckIcon } from "../../../assets/icons/modals/blueCheck.svg";
import usePixelPerfect from "../../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { fetchWarehousesList } from "../../../redux/thunks/warehouse/warehousesListThunk";
import CustomSelect from "../../custom/forms/default/CustomSelect";
import DateFilter from "../../custom/forms/sales/DateFilter";
import { reset } from "../../../redux/slices/warehouse/warehouseListSlice";

interface TransformationsFiltersProps {
    setFilter: (filter: string) => void;
    setPage: (page: number) => void;
}

export default function TransformationsFilters(props: TransformationsFiltersProps) {
    const { PP } = usePixelPerfect();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [warehouse, setWarehouse] = useState<string | number>("");

    const [date, setDate] = useState<string | Date>("");

    const { warehousesList } = useAppSelector(
        (state) => state.warehouseList
    );
    const getWarehousesList = useCallback(() => {
        dispatch(
            fetchWarehousesList({
                page: 0,
            })
        );
    }, []);

    useEffect(() => {
        dispatch(reset());
        getWarehousesList();
    }, [getWarehousesList]);


    useEffect(() => {
        let filter = "";

        if (date !== "") {
            filter += `&date=${date}`;
        }

        if (warehouse !== "") {
            filter += `&warehouseId=${warehouse}`;
        }

        if (filter !== "") {
            props.setFilter(filter)
            props.setPage(0)
        }
    }, [warehouse, date]);

    return (
        <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            height={PP(40)}
        >
            <Box>
                <CustomSelect
                    id="warehouse"
                    value={warehouse}
                    onChange={(event) => {
                        setWarehouse(event.target.value);
                    }}
                    items={warehousesList.map((warehouse) => {
                        return { value: warehouse.id, label: warehouse.name }
                    })}
                    extraStyle={{
                        height: PP(40),
                    }}
                    placeholder={t("Selecciona una bodega")}
                    startSelectedIcon={<BlueCheckIcon />}
                />
            </Box>
            <Box height={PP(42)}>
                <DateFilter date={date} setDate={setDate} />
            </Box>
        </Box>
    );
}