import { Box, Divider, IconButton } from "@mui/material";

import { ReactComponent as ArrowLeft } from "../../assets/icons/layouts/arrow-left.svg";
import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import CustomText from "../../components/custom/texts/CustomText";
import useAppDispatch from "../../hooks/old/useAppDispatch";
import { reset as resetCollectionsList } from "../../redux/slices/collections/collectionListSlice";
import { useTranslation } from "react-i18next";

interface ExportsDetailsLayoutProps {
  children?: React.ReactNode;
}
export default function TransactionsDetailsLayout(
  props: ExportsDetailsLayoutProps
) {
  const { PP } = usePixelPerfect();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const goBack = () => {
    window.history.back();
    dispatch(resetCollectionsList());
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: PP(8),
        }}
      >
        <IconButton
          aria-label="comeBackToBusinessList"
          onClick={goBack}
          style={{ padding: "0px" }}
        >
          <ArrowLeft style={{ width: PP(22), height: PP(22) }} />
        </IconButton>
        <CustomText color="#969696">&nbsp; {t("Volver")}</CustomText>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: PP(14),
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <CustomText
            premediumWeight
            noSpacing
            bigSize
            color="#1C1C1C"
            style={{
              lineHeight: PP(32),
              marginRight: PP(8.23),
            }}
          >
            {t("Detalles de venta")}
          </CustomText>
        </Box>
      </Box>
      <Divider style={{ marginBottom: PP(24) }} />
      <>{props.children}</>
    </Box>
  );
}
