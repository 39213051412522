import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import styled from "@emotion/styled";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField as MuiTextField,
  Snackbar,
  Alert,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";

import { license } from "../../../../../utils/old/constants";
import UsersTable from "../../../../../components/old/users/old/individuals/UsersTable";
import PageHeader from "../../../../../components/old/generalOld/PageHeader";
import {
  fetchCreatePerson,
  fetchPersonList,
  setShowSnack,
  setUserErrorExistsSnack,
} from "../../../../../redux/old/slices/individualsSlicer";

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

const headCells = [
  { id: "name", alignment: "left", label: "Nombre" },
  { id: "email", alignment: "left", label: "Email" },
  { id: "phone", alignment: "left", label: "Telefono" },
  { id: "actions", alignment: "right", label: "Acciones" },
];

export default function UserList() {
  const [createModal, setCreateModal] = useState(false);
  const [page, setPage] = useState(0);
  const { t } = useTranslation();

  //Reduxtoolkit
  const loading = useSelector((state) => state.person.loading);
  const snackTxt = useSelector((state) => state.person.snackTxt);
  const showSnack = useSelector((state) => state.person.showSnack);
  const errorMessage = useSelector((state) => state.person.errorMessage);
  const userErrorExistsSnack = useSelector(
    (state) => state.person.userErrorExistsSnack
  );
  const dispatch = useDispatch();

  const emptyRows = 5;

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async (cPage = 0) => {
    dispatch(fetchPersonList(cPage)); //ReduxToolkit
    setPage(cPage);
  };

  const initialValues = {
    email: "",
    name: "",
    last_name: "",
    document_type: "person_id",
    document_number: "",
    phone: "",
    countryId: 1,
  };

  function validationSchema() {
    return {
      email: Yup.string()
        .email("Correo no válido")
        .required("Correo requerido")
        .max(255, `máx 255 caracteres`),
      name: Yup.string()
        .min(3, `Mín 3 caracteres`)
        .required("Nombre requerido"),
      last_name: Yup.string()
        .min(3, `Mín 3 caracteres`)
        .required("Apellido requerido"),
      document_number: Yup.number().required("Cédula requerida"),
      phone: Yup.number().required("Teléfono requerido"),
    };
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) => {
      createUser(data);
    },
  });

  const createUser = async (data) => {
    let r = (Math.random() + 1).toString(36).substring(2);
    data.password = r;
    data.licenseId = license;
    data.admin = true;
    dispatch(fetchCreatePerson(data)); //Redux Toolkit Post
    setCreateModal(false);
    getUsers(0);
  };

  return (
    <React.Fragment>
      <PageHeader title={t("Personas")}>
        <Grid item>
          {/* <div>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => setCreateModal(true)}
            >
              <AddIcon />
              Nuevo
            </Button>
          </div> */}
        </Grid>
      </PageHeader>

      <Divider my={6} />
      <UsersTable headCells={headCells} />

      {/* -------------------------------------------Modal y SnackBar----------------------------------------------------- */}

      <Dialog
        open={createModal}
        onClose={() => setCreateModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle id="form-dialog-title">Crear una persona</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Cada persona tendrá una contraseña aleatoria que podrá ser
              cambiada al momento de iniciar sesión
            </DialogContentText>
            <Grid container spacing={6}>
              <Grid item md={6}>
                <TextField
                  name="email"
                  label="Correo"
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                {formik.errors.email && (
                  <Alert mt={2} mb={3} severity="error">
                    {formik.errors.email}
                  </Alert>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item md={6}>
                <TextField
                  name="name"
                  label="Nombre"
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                {formik.errors.name && (
                  <Alert mt={2} mb={3} severity="error">
                    {formik.errors.name}
                  </Alert>
                )}
              </Grid>
              <Grid item md={6}>
                <TextField
                  name="last_name"
                  label="Apellido"
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                />
                {formik.errors.last_name && (
                  <Alert mt={2} mb={3} severity="error">
                    {formik.errors.last_name}
                  </Alert>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item md={6}>
                <TextField
                  name="phone"
                  label="Teléfono"
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                />
                {formik.errors.phone && (
                  <Alert mt={2} mb={3} severity="error">
                    {formik.errors.phone}
                  </Alert>
                )}
              </Grid>
              <Grid item md={6}>
                <TextField
                  name="document_number"
                  label="Cédula"
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formik.values.document_number}
                  onChange={formik.handleChange}
                />
                {formik.errors.document_number && (
                  <Alert mt={2} mb={3} severity="error">
                    {formik.errors.document_number}
                  </Alert>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCreateModal(false)} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              Crear
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showSnack}
        autoHideDuration={6000}
        onClose={() => {
          dispatch(setShowSnack(false));
        }}
        message={snackTxt}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                dispatch(setShowSnack(false));
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={userErrorExistsSnack}
        autoHideDuration={10000}
        onClose={() => {
          dispatch(setUserErrorExistsSnack(false));
        }}
        ContentProps={{
          sx: {
            background: "#DD1E1E",
          },
        }}
        message={snackTxt}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                dispatch(setUserErrorExistsSnack(false));
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
