import { forwardRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { Box, Divider } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import CustomButton from "../../buttons/default/CustomButton";
import CustomText from "../../texts/CustomText";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import { ReactComponent as RedCheckIcon } from "../../../../assets/icons/modals/redCheck.svg";
import DetailsCollectionModalLayout from "../../../../layouts/collections/modals/DetailsCollectionModalLayout";
import { setCollectionEditStateModalState } from "../../../../redux/slices/collections/collectionDetailsInfoModalStatesSlice";
import { fetchUpdateCollectionInfo } from "../../../../redux/thunks/collections/updateCollectionInfoThunk";
import { useParams } from "react-router-dom";
import { fetchCollectionDetails } from "../../../../redux/thunks/collections/collectionDetailsThunk";
import { collectionsFailedReasonsOptions } from "../../../../utils/constants/collections/collectionsFailedReasonsOptions";
import { useTranslation } from "react-i18next";

export default forwardRef(function CustomSelectCollectionFailedReasonModal() {
  const { id } = useParams();
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();

  const { collectionDetails } = useAppSelector(
    (state) => state.collectionDetails
  );

  const [selectedFailedReason, setSelectedFailedReason] = useState(
    collectionDetails.collectorObservation
  );

  const [loadMore, setLoadMore] = useState(false);

  const handleLoadMore = () => {
    setLoadMore(true);
  };

  const dispatch = useAppDispatch();

  const handleCloseClickModal = (event: React.MouseEvent<SVGSVGElement>) => {
    dispatch(
      setCollectionEditStateModalState({
        id: "failedReasons",
        value: false,
      })
    );
  };

  const handleBackModal = () => {
    dispatch(
      setCollectionEditStateModalState({
        id: "failedReasons",
        value: false,
      })
    );
  };

  const updateNewCollectionState = async () => {
    if (id !== undefined) {
      await dispatch(
        fetchUpdateCollectionInfo({
          data: { state: "failed", collectorObservation: selectedFailedReason },
          collectionId: id,
        })
      );
    }
    if (id !== undefined) await dispatch(fetchCollectionDetails({ id: id }));
    handleBackModal();
    //IF (ESTADO DE ACTUALIZACIÓN ESTA OK...) ELSE...(ERROR)
    // dispatch(setOpenSnackbar(true));
    // dispatch(setMessage("Cambios guardados exitosamente"));
    // dispatch(setType("sucessSnackbar"));
  };

  const handleSaveNewState = async () => {
    selectedFailedReason !== null
      ? updateNewCollectionState()
      : alert(t("alerts.Selecciona razón de fallo"));
  };

  const handleSetSelected = (selectedFailedReason: string) => {
    setSelectedFailedReason(selectedFailedReason);
  };

  return (
    <DetailsCollectionModalLayout handleClose={handleCloseClickModal}>
      <CustomText
        showBackArrowButton
        bigSize
        premediumWeight
        color="#1C1C1C"
        onClick={handleBackModal}
        style={{ marginBottom: PP(24) }}
      >
        {t(
          "collectionDetailsLayout.customSelectCollectionFailedReasonModal.Razón de estado fallida"
        )}
      </CustomText>
      <Divider style={{ marginBottom: PP(24) }} />
      <Box
        sx={[
          {
            borderRadius: PP(8),
            minWidth: PP(432),
            height: PP(240),
            marginBottom: PP(24),
            marginTop: PP(8),
          },
        ]}
      >
        <Box>
          <InfiniteScroll
            height={PP(240)}
            dataLength={collectionsFailedReasonsOptions.length}
            next={handleLoadMore}
            hasMore={collectionsFailedReasonsOptions.length <= 5}
            loader={
              <CustomText color="#969696">
                {t(
                  "collectionDetailsLayout.customSelectCollectionFailedReasonModal.cargando"
                )}
              </CustomText>
            }
          >
            {collectionsFailedReasonsOptions.map((reason, key) => (
              <Box
                onClick={() => handleSetSelected(reason)}
                key={key}
                sx={[
                  {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    padding: PP(16),
                    color: "white",
                    height: PP(40),
                    "&:hover": {
                      backgroundColor: "rgba(229, 26, 66, 0.10)",
                      cursor: "pointer",
                      borderRadius: PP(4),
                    },
                  },
                  selectedFailedReason !== null &&
                    selectedFailedReason === reason && {
                      backgroundColor: "rgba(229, 26, 66, 0.10)",
                      borderRadius: PP(4),
                    },
                ]}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box>
                    <CustomText
                      noSpacing
                      color={
                        selectedFailedReason !== null &&
                        selectedFailedReason === reason
                          ? "#E51A42"
                          : "#1C1C1C"
                      }
                    >
                      {t(
                        `collectionDetailsLayout.customSelectCollectionFailedReasonModal.failedReasonsOptions.${reason}`
                      )}
                    </CustomText>
                  </Box>
                  {selectedFailedReason !== null &&
                    selectedFailedReason === reason && (
                      <span>
                        <RedCheckIcon />
                      </span>
                    )}
                </Box>
              </Box>
            ))}
          </InfiniteScroll>
        </Box>
      </Box>

      <CustomButton
        deleteButton //  el botón no es para eliminar pero posee el mismo estilo
        width={432}
        height={44}
        label={t(
          "collectionDetailsLayout.customSelectCollectionFailedReasonModal.Guardar"
        )}
        onClick={handleSaveNewState}
      />
      <CustomButton
        noSpacing
        width={432}
        height={44}
        cancelButton
        onClick={handleBackModal}
      />
    </DetailsCollectionModalLayout>
  );
});
