import { useCallback } from "react";

import { useFormik } from "formik";
import usePixelPerfect from "../../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { useTranslation } from "react-i18next";
import {
  setMessage,
  setOpenSnackbar,
  setType,
} from "../../../redux/slices/globalSnackbar/globalSnackbarSlice";
import {
  addressYup,
  contactEmailYup,
  contactNameYup,
  contactPhoneYup,
  documentNumberYup,
  legalNameYup,
  nameYup,
  validateYupSchema,
} from "../../../utils/forms/validationSchema";
import { SelectChangeEvent } from "@mui/material";
import { setFormCreateTransaction } from "../../../redux/slices/transactions/createTransactionSlice";
import CustomInput from "../forms/default/CustomInput";
import CustomButton from "../buttons/default/CustomButton";
import { fetchCreateClient } from "../../../redux/thunks/clients/createClientThunk";

// import SelectTransactionUploadDate from "../custom/forms/sales/SelectTransactionUploadDate";

interface CreateClientFormProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getClientList: () => Promise<void>;
}

export default function CreateClientForm(props: CreateClientFormProps) {
  const { PP } = usePixelPerfect();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { setOpen, getClientList } = props;

  const {
    name,
    legalName,
    documentNumber,
    address,
    contactName,
    contactEmail,
    contactPhone,
  } = useAppSelector((state) => state.createClient.form);

  const handleClickCloseModal = () => {
    setOpen(false);
  };

  const initialFormikValues = {
    name: name,
    legalName: legalName,
    documentNumber: documentNumber,
    address: address,
    contactName: contactName,
    contactEmail: contactEmail,
    contactPhone: contactPhone,
  };

  const handleSubmit = async (values: typeof initialFormikValues) => {
    try {
      await dispatch(fetchCreateClient({ data: values })).unwrap();
      dispatch(setOpenSnackbar(true));
      dispatch(setMessage(t("Cliente creado")));
      dispatch(setType("sucessSnackbar"));
      await getClientList();
      handleClickCloseModal();
    } catch (error) {
      console.error("Failed to create client:", error);
      dispatch(setMessage(t("Error al crear cliente")));
      dispatch(setType("errorSnackbar"));
    }
  };


  const validationSchema = validateYupSchema({
    name: nameYup,
    legalName: legalNameYup,
    documentNumber: documentNumberYup,
    address: addressYup,
    contactName: contactNameYup,
    contactEmail: contactEmailYup,
    contactPhone: contactPhoneYup,
  });

  const formikCreateClient = useFormik({
    initialValues: initialFormikValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: false,
  });

  const formikCreateClientChange = useCallback(
    (
      event:
        | React.ChangeEvent<HTMLInputElement>
        | SelectChangeEvent<number | string>
    ) => {
      formikCreateClient.setFieldValue(event.target.name, event.target.value);
      dispatch(
        setFormCreateTransaction({
          id: event.target.name,
          value: event.target.value,
        })
      );
    },
    [formikCreateClient]
  );

  return (
    <form noValidate onSubmit={formikCreateClient.handleSubmit}>
      <CustomInput
        id="name"
        label={t("Nombre del cliente")}
        placeholder=""
        value={formikCreateClient.values.name}
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        inputHeight={PP(40)}
        extraStyle={{ marginTop: PP(24) }}
        onChange={formikCreateClientChange}
        formik={formikCreateClient}
        type="string"
        required
      />
      <CustomInput
        id="legalName"
        label={t("Razón social")}
        placeholder=""
        value={formikCreateClient.values.legalName}
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        inputHeight={PP(40)}
        extraStyle={{ marginTop: PP(24) }}
        onChange={formikCreateClientChange}
        formik={formikCreateClient}
        required
      />

      <CustomInput
        id="documentNumber"
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        extraStyle={{ marginTop: PP(24) }}
        label={t("Nit")}
        placeholder=""
        value={formikCreateClient.values.documentNumber}
        onChange={formikCreateClientChange}
        formik={formikCreateClient}
        required
      />
      <CustomInput
        id="address"
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        extraStyle={{ marginTop: PP(24) }}
        label={t("Dirección")}
        placeholder=""
        value={formikCreateClient.values.address}
        onChange={formikCreateClientChange}
        formik={formikCreateClient}
        required
      />
      <CustomInput
        id="contactName"
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        extraStyle={{ marginTop: PP(24) }}
        label={t("Nombre contacto")}
        placeholder=""
        value={formikCreateClient.values.contactName}
        onChange={formikCreateClientChange}
        formik={formikCreateClient}
        required
      />
      <CustomInput
        id="contactEmail"
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        extraStyle={{ marginTop: PP(24) }}
        label={t("Correo")}
        placeholder=""
        value={formikCreateClient.values.contactEmail}
        onChange={formikCreateClientChange}
        formik={formikCreateClient}
        required
      />
      <CustomInput
        id="contactPhone"
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        extraStyle={{ marginTop: PP(24) }}
        label={t("Teléfono")}
        placeholder=""
        value={formikCreateClient.values.contactPhone}
        onChange={formikCreateClientChange}
        formik={formikCreateClient}
        required
      />
      <CustomButton
        width={432}
        height={44}
        noSpacing
        style={{ padding: "0px", marginTop: PP(24) }}
        type="submit"
        label={t("Crear")}
        formik={formikCreateClient}
      />
      <CustomButton
        noSpacing
        width={432}
        height={44}
        cancelButton
        onClick={handleClickCloseModal}
        formik={formikCreateClient}
      />
    </form>
  );
}
