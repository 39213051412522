import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Alert,
  Button,
  Card as MuiCard,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid as MuiGrid,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField as MuiTextField,
  Typography,
  Box,
} from "@mui/material";
import {
  Add as AddIcon,
  Close as CloseIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";

import { get } from "../../../../../utils/old/api";
import {
  fetchCreatePersonAddress,
  fetchDeleteAddress,
  fetchPersonAddress,
  fetchUpdateAddress,
  setPageAddresses,
} from "../../../../../redux/old/slices/individualsSlicer";
import { fetchCities } from "../../../../../redux/old/slices/citiesSlicer";
import AddressMap from "../../../googlemaps/old/AddressMap";
import getLatLng from "../../../../../utils/old/axiosGoogleMaps";
import { useTranslation } from "react-i18next";

const Grid = styled(MuiGrid)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const TextField = styled(MuiTextField)(spacing);

const Card = styled(MuiCard)(spacing);

const headCells = [
  {
    id: "description",
    alignment: "left",
    label: "Descripción",
    disablePadding: true,
  },
  {
    id: "complement",
    alignment: "left",
    label: "Complemento",
    disablePadding: true,
  },
  { id: "name", alignment: "center", label: "Ciudad", disablePadding: true },
  {
    id: "actions",
    alignment: "center",
    label: "Acciones",
    disablePadding: true,
  },
];

export default function AddressCard() {
  const { id } = useParams();

  const [person, setPerson] = useState({});
  const [personPermissions, setPersonPermissions] = useState({});
  const [addressModal, setAddressModal] = useState(false);
  const [snackTxt, setSnackTxt] = useState("hola");
  const [showSnack, setShowSnack] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [currentDelay, setCurrentDelay] = useState(null);
  const { t } = useTranslation();

  let idCitySelected = 1;
  let citySelected;
  let descriptionGoogleMaps = "";

  const loading = useSelector((state) => state.person.showLoading);
  //const person = useSelector((state) => state.person.person);
  const addresses = useSelector((state) => state.person.addresses);
  const allCities = useSelector((state) => state.cities.allCities);
  const totalRowsAddresses = useSelector(
    (state) => state.person.totalRowsAddresses
  );
  const pageAddresses = useSelector((state) => state.person.pageAddresses);

  const dispatch = useDispatch();

  useEffect(() => {
    getPerson();
    getCities();
  }, [addressModal, showSnack]);

  useEffect(() => {
    if (selectedAddress.location) {
      if (
        selectedAddress.location.coordinates[0] !== 0 ||
        selectedAddress.location.coordinates[1] !== 0
      ) {
        setLat(selectedAddress.location.coordinates[1]);
        setLng(selectedAddress.location.coordinates[0]);
      }
    }
  }, [selectedAddress]);

  const getPerson = async (cPage = 0) => {
    get(
      `/user/${id}`,
      (response) => {
        setPerson(response.data);
        setPersonPermissions({
          collector_fog: response.data.permissions.includes("collector_fog"),
          collector_ssr: response.data.permissions.includes("collector_ssr"),
          commercial: response.data.permissions.includes("commercial"),
        });
        dispatch(fetchPersonAddress({ cPage: cPage, id: id })); //ReduxToolkit Get
        dispatch(setPageAddresses(cPage)); //ReduxToolkit reducer
      },
      (error) => {
        alert("Error obteniendo usuario"); /*setLoading(false)*/
      }
    );
  };

  const getCities = async () => {
    dispatch(fetchCities(0)); //ReduxTookit
  };

  const handleChangePageAddresses = async (event, newPage) => {
    dispatch(fetchPersonAddress({ cPage: newPage, id: id })); //ReduxTookit Get
    dispatch(setPageAddresses(newPage));
  };

  const handleChangeRowsPerAddress = async () => {
    dispatch(fetchPersonAddress({ cPage: 0, id: id }));
  };

  //Controlando marcador de mapa
  const onChangeMarker = async (marker) => {
    setLat(marker.lat);
    setLng(marker.lng);
  };

  //Manejando CREACIÓN de direcciones con validaciones

  const initialValuesAddress = {
    description: "", //Como ejemplo de cómo debe escribirse la dirección
    complement: "",
    cityId: 0,
  };

  function validationSchema() {
    return {
      description: Yup.string().required(
        t("newAddressUserModal.validations.Descripción requerida")
      ),
      cityId: Yup.number()
        .positive(
          t("newAddressUserModal.validations.Debes seleccionar una ciudad")
        )
        .required(t("newAddressUserModal.validations.Ciudad requerida")),
    };
  }

  const formikAddress = useFormik({
    initialValues: initialValuesAddress,
    enableReinitialize: true,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) => {
      createAddress(data);
    },
  });

  const createAddress = async (data) => {
    data.location = {
      type: "Point",
      coordinates: [lng, lat],
    };

    data.ownerId = id;
    data.ownerType = "user";
    data.admin = true;
    dispatch(fetchCreatePersonAddress(data)); //ReduxToolkit Post
    setAddressModal(false);
    setSnackTxt("Dirección creada");
    setShowSnack(true);
    dispatch(fetchPersonAddress({ cPage: 0, id: id })); //ReduxToolkit Get
  };

  //Manejando EDICIÓN de direcciones

  const editAddress = (address) => {
    setSelectedAddress(address);
    setModalEdit(true);
  };

  const initialValuesEditAddress = {
    description: selectedAddress.description,
    complement: selectedAddress.complement,
    cityId: selectedAddress.cityId,
  };

  function validationSchema() {
    return {
      description: Yup.string().required(
        t("newAddressUserModal.validations.Descripción requerida")
      ),
      cityId: Yup.number()
        .positive(
          t("newAddressUserModal.validations.Debes seleccionar una ciudad")
        )
        .required(t("newAddressUserModal.validations.Ciudad requerida")),
    };
  }

  const formikEditAddress = useFormik({
    initialValues: initialValuesEditAddress,
    enableReinitialize: true,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) => {
      updateAddress(data);
    },
  });

  const updateAddress = async (data) => {
    let selectedAddressId = selectedAddress.id;
    data.location = {
      type: "Point",
      coordinates: [lng, lat],
    };
    data.user_id = id;
    dispatch(
      fetchUpdateAddress({ data: data, selectedAddressId: selectedAddressId })
    ); //ReduxToolkit Patch
    setModalEdit(false);
    setSnackTxt(t("Dirección actualizada"));
    setShowSnack(true);
    dispatch(fetchPersonAddress({ cPage: 0, id: id })); //Reduxtoolkit Get
  };

  //Manejando ELIMINAR dirección

  const deleteAddress = (address) => {
    dispatch(fetchDeleteAddress(address)); //ReduxTookit Destroy
    setSnackTxt(t("Dirección eliminada"));
    setShowSnack(true);
    dispatch(fetchPersonAddress({ cPage: 0, id: id })); //Reduxtoolkit Get
  };

  const onChangedDescription = async (description) => {
    clearTimeout(currentDelay);
    setCurrentDelay(
      setTimeout(async () => {
        var cId = formikEditAddress.values.cityId
          ? formikEditAddress.values.cityId
          : formikAddress.values.cityId;
        citySelected = allCities.filter((city) => city.id === cId);
        descriptionGoogleMaps =
          description + "," + citySelected[0].name + "," + "Colombia";
        getLatLng(descriptionGoogleMaps, citySelected[0].id).then(
          (response) => {
            const { lat, lng } = response.data.results[0].geometry.location;
            setLat(lat);
            setLng(lng);
          }
        );
      }, 1000)
    );
  };

  return (
    <React.Fragment>
      <Card mb={6}>
        <CardContent>
          <Grid justifyContent="space-between" container spacing={10}>
            <Grid item>
              <Typography variant="h6" gutterBottom>
                {t("Direcciones")}
              </Typography>
            </Grid>
            <Grid item>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    setSelectedAddress({});
                    setLat(0);
                    setLng(0);
                    setAddressModal(true);
                  }}
                >
                  <AddIcon />
                  {t("newAddressUserModal.Nueva")}
                </Button>
              </div>
            </Grid>
          </Grid>

          <TableWrapper>
            <Table style={{ marginTop: "2em" }}>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.alignment}
                      padding={headCell.disablePadding ? "none" : "normal"}
                    >
                      {t(`tables.tableHeadCells.${headCell.label}`)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {addresses.map((row, index) => {
                  return (
                    <TableRow hover key={index}>
                      <TableCell align="left">{row.description}</TableCell>
                      <TableCell align="left">{row.complement}</TableCell>
                      <TableCell align="center">{row.city.name}</TableCell>
                      <TableCell align="right">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <IconButton
                            aria-label="edit"
                            size="large"
                            onClick={(e) => editAddress(row)}
                          >
                            <EditIcon />
                          </IconButton>

                          <IconButton
                            aria-label="delete"
                            size="large"
                            onClick={(e) => deleteAddress(row)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[20]}
              component="div"
              count={totalRowsAddresses}
              rowsPerPage={20}
              page={pageAddresses}
              onPageChange={handleChangePageAddresses}
              onRowsPerPageChange={handleChangeRowsPerAddress}
            />
          </TableWrapper>
          {addresses.length === 0 && (
            <Box display="flex" justifyContent="center">
              <Typography style={{ fontSize: "16px", color: "gray" }}>
                {t("No se han creado direcciones para este usuario")}
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>

      {/*----------------------------- MODAL EDITAR DIRECCIÓN-------------------------------------- */}
      <Dialog
        open={modalEdit}
        onClose={() => setModalEdit(false)}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={formikEditAddress.handleSubmit}>
          <DialogTitle id="form-dialog-title">
            {t("editAddressUserModal.Editar la dirección")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("editAddressUserModal.Se editará la dirección del usuario")}
            </DialogContentText>
            <Grid container spacing={6}>
              <Grid item md={12}>
                <Select
                  name="cityId"
                  label={t("editAddressUserModal.Ciudad")}
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formikEditAddress.values.cityId}
                  onChange={formikEditAddress.handleChange}
                >
                  <MenuItem value={0} key={0} disabled={true}>
                    {t("editAddressUserModal.Ciudad")}
                  </MenuItem>
                  {allCities &&
                    allCities.map((city, index) => (
                      <MenuItem key={city.id} value={city.id}>
                        {city.name}
                      </MenuItem>
                    ))}
                </Select>

                <div style={{ display: "none" }}>
                  {(idCitySelected = formikEditAddress.values.cityId)}
                </div>

                {formikEditAddress.errors.cityId && (
                  <Alert mt={2} mb={3} severity="error">
                    {formikEditAddress.errors.cityId}
                  </Alert>
                )}
              </Grid>
              <Grid item md={6}>
                <TextField
                  name="description"
                  label={t("editAddressUserModal.Descripción")}
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formikEditAddress.values.description}
                  onChange={(e) => {
                    onChangedDescription(e.target.value);
                    formikEditAddress.setFieldValue(
                      "description",
                      e.target.value
                    );
                  }}
                />

                {formikEditAddress.errors.description && (
                  <Alert mt={2} mb={3} severity="error">
                    {formikEditAddress.errors.description}
                  </Alert>
                )}
              </Grid>
              <Grid item md={6}>
                <TextField
                  name="complement"
                  label={t("editAddressUserModal.Complemento")}
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formikEditAddress.values.complement}
                  onChange={formikEditAddress.handleChange}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <AddressMap
            latSelectedAddres={lat}
            lngSelectedAddres={lng}
            onChangeMarker={onChangeMarker}
            idCitySelected={idCitySelected}
          />

          <DialogActions>
            <Button onClick={() => setModalEdit(false)} color="primary">
              {t("editAddressUserModal.Cancelar")}
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {t("editAddressUserModal.Actualizar")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* ----------------Modal Crear dirección-------------------- */}

      <Dialog
        open={addressModal}
        onClose={() => setAddressModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={formikAddress.handleSubmit}>
          <DialogTitle id="form-dialog-title">
            {t("newAddressUserModal.Crear una dirección")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("newAddressUserModal.Se le asignará al usuario actual")}
            </DialogContentText>
            <Grid container spacing={6}>
              <Grid item md={12}>
                <Select
                  name="cityId"
                  label={t("newAddressUserModal.Ciudad")}
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formikAddress.values.cityId}
                  onChange={formikAddress.handleChange}
                >
                  <MenuItem value={0} key={0} disabled={true}>
                    {t("newAddressUserModal.Ciudad")}
                  </MenuItem>
                  {allCities &&
                    allCities.map((city, index) => (
                      <MenuItem key={city.id} value={city.id}>
                        {city.name}
                      </MenuItem>
                    ))}
                </Select>

                <div style={{ display: "none" }}>
                  {(idCitySelected = formikAddress.values.cityId)}
                </div>

                {formikAddress.errors.cityId && (
                  <Alert mt={2} mb={3} severity="error">
                    {formikAddress.errors.cityId}
                  </Alert>
                )}
              </Grid>
              {formikAddress.values.cityId != 0 && (
                <Grid item md={6}>
                  <TextField
                    name="description"
                    label={t("newAddressUserModal.Descripción")}
                    fullWidth
                    variant="outlined"
                    my={2}
                    value={formikAddress.values.description}
                    onChange={(e) => {
                      onChangedDescription(e.target.value);
                      formikAddress.setFieldValue(
                        "description",
                        e.target.value
                      );
                    }}
                  />

                  {formikAddress.errors.description && (
                    <Alert mt={2} mb={3} severity="error">
                      {formikAddress.errors.description}
                    </Alert>
                  )}
                </Grid>
              )}
              {formikAddress.values.cityId != 0 && (
                <Grid item md={6}>
                  <TextField
                    name="complement"
                    label={t("newAddressUserModal.Complemento")}
                    fullWidth
                    variant="outlined"
                    my={2}
                    value={formikAddress.values.complement}
                    onChange={formikAddress.handleChange}
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>

          {formikAddress.values.cityId != 0 && (
            <AddressMap
              latSelectedAddres={lat}
              lngSelectedAddres={lng}
              onChangeMarker={onChangeMarker}
              idCitySelected={idCitySelected}
            />
          )}

          <DialogActions>
            <Button onClick={() => setAddressModal(false)} color="primary">
              {t("newAddressUserModal.Cancelar")}
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {t("newAddressUserModal.Crear")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* ----------------Snackbar Confirmación-------------------- */}

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showSnack}
        autoHideDuration={6000}
        onClose={() => {
          setShowSnack(false);
        }}
        message={snackTxt}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                setShowSnack(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
