import { createSlice } from "@reduxjs/toolkit";
import { fetchEditClient } from "../../thunks/clients/editClientThunk";

const initialState = {
  loading: false,
  status: -1,
  statusText: "",
};

export const editClientSlice = createSlice({
  name: "editClientSliceReducer",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = -1;
      state.statusText = "";
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Editar un cliente de las transacciones
    builder.addCase(fetchEditClient.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchEditClient.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.statusText = action.payload.statusText;
      state.loading = false;
    });
    builder.addCase(fetchEditClient.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { resetStatus, reset } = editClientSlice.actions;

export default editClientSlice.reducer;
