import { createSlice } from "@reduxjs/toolkit";

import { materialsListSliceInterface } from "../../../interfaces/redux/materialsListSliceInterface";
import { fetchMaterialsList } from "../../thunks/collections/materialsListThunk";

const initialState: materialsListSliceInterface = {
  loading: false,
  status: -1,
  materialsList: [],
  page: 0,
  totalMaterialsList: 0,
  selected: null,
  selectedCategory: null, //PROVISIONAL
};

export const materialsListSlice = createSlice({
  name: "materialsListReducer",
  initialState,
  reducers: {
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    resetPage: (state) => {
      state.page = 0;
    },
    resetMaterialsList: (state) => {
      state.materialsList = [];
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Get Materials List
    builder.addCase(fetchMaterialsList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchMaterialsList.fulfilled, (state, action) => {
      if (action.payload.data.data.length > 0 && state.selected === null) {
        state.selected = action.payload.data.data[0];
      }
      if (action.payload.data.data && action.payload.data.data.length > 0) {
        state.materialsList = state.materialsList.concat(
          action.payload.data.data
        );
      }
      state.totalMaterialsList = action.payload.data.total;
      state.status = action.payload.status;
      state.loading = false;
      state.page = state.page + 1;
    });
    builder.addCase(fetchMaterialsList.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const {
  setSelected,
  setSelectedCategory,
  resetPage,
  resetMaterialsList,
  reset,
} = materialsListSlice.actions;

export default materialsListSlice.reducer;
