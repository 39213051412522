import { forwardRef, useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { Box, Divider } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import CustomButton from "../../buttons/default/CustomButton";
import CustomText from "../../texts/CustomText";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import CustomSearchFilters from "../../headers/default/CustomSearchFilters";
import { ReactComponent as BlueCheckIcon } from "../../../../assets/icons/modals/blueCheck.svg";
import CollectionsWarehouseFilterModalLayout from "../../../../layouts/collections/modals/CollectionsWarehouseFilterModalLayout";
import { setCollectionsFilterByModalState } from "../../../../redux/slices/collections/collectionsFilterByModalStatesSlice";
import { fetchCollectorsList } from "../../../../redux/thunks/collectors/collectorsListThunk";
import { resetCollectorsList } from "../../../../redux/slices/collectors/collectorsListSlice";
import { CollectorType } from "../../../../interfaces/data/collectorInterface";
import {
  setCollectorCollectionsListFilterQuery,
  setSelectedCollectorFilter,
} from "../../../../redux/slices/collections/collectionsListOtherFiltersBySlice";
import { useTranslation } from "react-i18next";

export default forwardRef(function SelectCollectorOfListModal() {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();

  const { collectorsList } = useAppSelector((state) => state.collectorsList);
  const { selectedCollectorFilter } = useAppSelector(
    (state) => state.collectionsListOtherFilters
  );

  const [selectedCollector, setSelectedCollector] = useState(
    selectedCollectorFilter
      ? selectedCollectorFilter
      : {
          id: -1,
          name: "",
          phone: "",
        }
  );

  const [loadMore, setLoadMore] = useState(false);

  const handleLoadMore = () => {
    setLoadMore(true);
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    setLoadMore(true);
  }, [dispatch]);

  const getCollectorsList = useCallback(() => {
    if (loadMore) {
      setLoadMore(false);
      dispatch(fetchCollectorsList());
    }
  }, [loadMore, dispatch]);

  useEffect(() => {
    getCollectorsList();
  }, [getCollectorsList]);

  const handleCloseClickModal = (event: React.MouseEvent<SVGSVGElement>) => {
    dispatch(resetCollectorsList());
    dispatch(
      setCollectionsFilterByModalState({
        id: "selectCollectorFilterModal",
        value: false,
      })
    );
  };

  const handleBackModal = () => {
    dispatch(
      setCollectionsFilterByModalState({
        id: "selectCollectorFilterModal",
        value: false,
      })
    );
  };

  const handleSaveCollector = () => {
    if (selectedCollector.id !== -1) {
      dispatch(setSelectedCollectorFilter(selectedCollector));
      dispatch(
        setCollectorCollectionsListFilterQuery(
          `collectorId=${selectedCollector.id}&`
        )
      );
      handleBackModal();
    }
  };

  const handleSetSelected = (selectedCollector: CollectorType) => {
    setSelectedCollector(selectedCollector);
  };

  return (
    <CollectionsWarehouseFilterModalLayout handleClose={handleCloseClickModal}>
      <CustomText
        showBackArrowButton
        bigSize
        premediumWeight
        color="#1C1C1C"
        onClick={handleBackModal}
        style={{ marginBottom: PP(24) }}
      >
        {t(
          "collectionsFilterByModal.selectOthersCollectionsFiltersForm.selectCollectorOfListModal.Seleccionar recolector"
        )}
      </CustomText>
      <Divider style={{ marginBottom: PP(24) }} />
      <CustomSearchFilters
        about="onlySearch"
        placeholder={"Buscar por nombre o correo"}
        width={432}
        height={40}
        bradius={PP(4)}
        widthsearchicon={16}
        heightsearchicon={16}
      />
      <Box
        sx={[
          {
            borderRadius: PP(8),
            minWidth: PP(367),
            height: PP(160),
            marginBottom: PP(24),
            marginTop: PP(8),
          },
        ]}
      >
        <Box>
          <InfiniteScroll
            height={PP(160)}
            dataLength={collectorsList.length}
            next={handleLoadMore}
            hasMore={collectorsList.length >= 40}
            loader={
              <CustomText color="#969696">
                {t(
                  "collectionsFilterByModal.selectOthersCollectionsFiltersForm.selectCollectorOfListModal.cargando"
                )}
              </CustomText>
            }
          >
            {collectorsList.map((collector, key) => (
              <Box
                onClick={() => handleSetSelected(collector)}
                key={key}
                sx={[
                  {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    padding: PP(16),
                    color: "white",
                    height: PP(40),
                    "&:hover": {
                      backgroundColor: "#ECF1FF",
                      cursor: "pointer",
                      borderRadius: PP(4),
                    },
                  },
                  selectedCollector.id !== -1 &&
                    selectedCollector.id === collector.id && {
                      backgroundColor: "#ECF1FF",
                      borderRadius: PP(4),
                    },
                ]}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box>
                    <CustomText
                      noSpacing
                      color={
                        selectedCollector.id !== -1 &&
                        selectedCollector.id === collector.id
                          ? "#4463B8"
                          : "#1C1C1C"
                      }
                    >
                      {collector.name}
                    </CustomText>
                  </Box>
                  {selectedCollector.id !== -1 &&
                    selectedCollector.id === collector.id && (
                      <span>
                        <BlueCheckIcon />
                      </span>
                    )}
                </Box>
              </Box>
            ))}
          </InfiniteScroll>
        </Box>
      </Box>

      <CustomButton
        width={432}
        height={44}
        label={t(
          "collectionsFilterByModal.selectOthersCollectionsFiltersForm.selectCollectorOfListModal.Seleccionar"
        )}
        onClick={handleSaveCollector}
      />
      <CustomButton
        noSpacing
        width={432}
        height={44}
        cancelButton
        onClick={handleBackModal}
      />
    </CollectionsWarehouseFilterModalLayout>
  );
});
