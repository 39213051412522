import { createSlice } from "@reduxjs/toolkit";

import { GlobalSnackbarSliceType } from "../../../interfaces/redux/globalSnackbarSliceInterface";

const initialState: GlobalSnackbarSliceType = {
  openSnackBar: false,
  message: "",
  type: "sucessSnackbar",
};

export const globalSnackbarSlice = createSlice({
  name: "globalSnackbarReducer",
  initialState,
  reducers: {
    setOpenSnackbar: (state, action) => {
      state.openSnackBar = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    reset: () => initialState,
  },
});

export const { setOpenSnackbar, setMessage, setType } =
  globalSnackbarSlice.actions;

export default globalSnackbarSlice.reducer;
