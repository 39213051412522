import { createRef, useEffect, useState } from "react";

import { Add as AddIcon } from "@mui/icons-material";
import { Button, Divider, Modal, Toolbar } from "@mui/material";
import { useTranslation } from "react-i18next";

import CustomHeaderTitle from "../../components/custom/headers/default/CustomHeaderTitle";
import CustomCompleteTable from "../../components/custom/tables/default/CustomCompleteTable";
import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import { transactionsListTableHeadCells } from "../../utils/constants/transactions/transactionsListTableHeadCells";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { fetchTransactionsList } from "../../redux/thunks/transactions/transactionsListThunk";
import CustomTransactionsListTableRow from "../../components/custom/tables/sales/CustomTransactionsListTableRow";
import { TransactionType } from "../../interfaces/data/transactionInterface";
import CustomCreateTransactionModal from "../../components/custom/modals/sales/CustomCreateTransactionModal";
import TransactionsFilters from "../../components/transactions/TransactionsFilters";

export default function Transactions() {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const ref = createRef();

  const { transactionsList, status, totalRows } = useAppSelector(
    (state) => state.transactionsList
  );

  const [createTransactionModal, setCreateTransactionModal] = useState(false);
  const [page, setPage] = useState<number>(0);

  const handleChangePage = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (event !== null) {
      setPage(newPage);
    }
  };

  const getTransactions = async () => {
    await dispatch(fetchTransactionsList({ page: page }));
  };

  useEffect(() => {
    getTransactions();
  }, [page, dispatch]);

  return (
    <>
      <Toolbar
        style={{
          padding: "0px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <CustomHeaderTitle
          title={t("Ventas")}
          style={{ fontSize: PP(36), fontWeight: 500 }}
        />
        <TransactionsFilters />

        <Button
          variant="contained"
          color="primary"
          onClick={(e) => setCreateTransactionModal(true)}
        >
          <AddIcon />
          {t("Crear venta")}
        </Button>
      </Toolbar>
      <Divider />

      <CustomCompleteTable
        status={status}
        tableHeadCells={transactionsListTableHeadCells} //Encabezados de la tabla
        totalRows={totalRows} // total de filas de la tabla = total de datos respuesta backend
        rowsPerPage={50} //Filas por pagina de tabla
        page={page} //página actual
        handleChangePage={handleChangePage} // Manejando cambio de página para consumo Api
        setPage={setPage} //Cambio de página actual
        children={transactionsList?.map((element: TransactionType, key) => {
          return (
            <CustomTransactionsListTableRow
              element={element}
              key={key}
              myKey={key}
            />
          );
        })}
      />
      <Modal
        open={createTransactionModal}
        children={
          <CustomCreateTransactionModal
            ref={ref}
            setOpen={setCreateTransactionModal}
            getTransactions={getTransactions}
          />
        }
      />
    </>
  );
}
