import axios from "./axios";

const accessToken = window.localStorage.getItem("accessToken");
axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;


//Redux Toolkit
export function getRedux(url) {
    return axios.get(url);
}
 
export async function postRedux(url, data) {
    return axios.post(url, data);
}

export async function patchRedux(url, data) {
    return axios.patch(url, data);
}

export async function destroyRedux(url) {
    return axios.delete(url);
}


//Forma Convencional
export async function get(url, onSuccess, onError) {
    try {
        onSuccess(await axios.get(url));
    } catch (error) {
        onError(error);
    }
}

export async function post(url, data, onSuccess, onError) {
    try {
        onSuccess(await axios.post(url, data));
    } catch (error) {
        onError(error);
    }
}

export async function patch(url, data, onSuccess, onError) {
    try {
        onSuccess(await axios.patch(url, data));
    } catch (error) {
        onError(error);
    }
}

export async function destroy(url, onSuccess, onError) {
    try {
        onSuccess(await axios.delete(url));
    } catch (error) {
        onError(error);
    }
}