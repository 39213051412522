import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FormikErrors,
  FormikTouched,
  FormikValues,
} from "formik";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import CustomText from "../../texts/CustomText";

interface CustomInputProps {
  id: string;
  formik?: {
    handleBlur: (e: React.FocusEvent<any>) => void;
    handleChange: (e: React.ChangeEvent<any>) => void;
    touched: FormikTouched<FormikValues>;
    errors: FormikErrors<FormikValues>;
    values: FormikValues;
  };
  extraStyle?: React.CSSProperties;
  labelStyles?: React.CSSProperties;
  multiline?: boolean;
  rows?: number;
  placeholder?: string;
  label?: string;
  required?: boolean;
  paddingTopLabel?: string;
  labelColor?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  value?: string | number;
  paddingTopInput?: string;
  inputHeight?: string;
  inputBorderRadius?: string;
  backgroundColor?: string;
  disabled?: boolean;
  widthProp?: number | string;
  heightProp?: number | string;
  paddingInput?: number | string;
  startAdornmentValue?: string | number | ReactNode | undefined;
  endAdornmentValue?: string | number | ReactNode | undefined;
}

export default function CustomInput(props: CustomInputProps) {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (inputValue.length <= 100) {
      const validInputValue = validateInput(inputValue);
      if (props.onChange) {
        event.target.value = validInputValue;
        props.onChange(event);
      } else if (props.formik && props.formik.handleChange) {
        event.target.value = validInputValue;
        props.formik.handleChange(event);
      }
    }
  };

  const validateInput = (inputValue: string): string => {
    const sanitizedValue = inputValue.replace(/,/g, "").replace(/\.{2,}/g, ".");
    return sanitizedValue;
  };

  const getEndAdornment = () => {
    if (props.type === "password") {
      return (
        <InputAdornment position="end">
          <IconButton
            onClick={handleTogglePasswordVisibility}
            onMouseDown={(event) => event.preventDefault()} // Evita el comportamiento predeterminado de cambiar el tipo de entrada
          >
            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </InputAdornment>
      );
    } else {
      return (
        <InputAdornment position="end">
          {props.endAdornmentValue}
        </InputAdornment>
      );
    }
  };

  return (
    <FormControl
      sx={{
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "#E8E8E8",
          },
        },
        "& .MuiOutlinedInput-root:hover": {
          "& > fieldset": {
            borderColor: "#E8E8E8",
          },
        },
        ...(props.formik &&
          props.formik.touched[props.id] &&
          props.formik.errors[props.id] && {
          fieldset: {
            border: `${PP(1)} solid #E8E8E8`,
          },
        }),
        fieldset: {
          border: props.disabled ? "none" : `${PP(1)} solid #E8E8E8`,
        },
        ...props.extraStyle,
        height: PP(props.heightProp),
        width: props.widthProp ? PP(props.widthProp) : "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: props.label ? PP(8) : 0,
        }}
      >
        <CustomText
          smallSize
          noSpacing
          color="#969696"
          premediumWeight
          style={props.labelStyles}
        >
          {props.label}
        </CustomText>
      </Box>

      <TextField
        {...(props.multiline && { multiline: props.multiline })}
        {...(props.rows && { rows: props.rows })}
        placeholder={props.placeholder}
        id={`${props.id}-input`}
        InputLabelProps={{
          style: {
            color: "black",
          },
        }}
        autoComplete="off"
        InputProps={{
          style: {
            marginLeft: "0px",
            fontFamily: "'Inter', sans-serif",
            borderRadius: props.inputBorderRadius
              ? props.inputBorderRadius
              : PP(4),
            border:
              props.formik &&
              props.formik.errors[props.id] &&
              `${PP(1)} solid #E73B85`,
          },
          startAdornment: (
            <InputAdornment position="start">
              {props.startAdornmentValue}
            </InputAdornment>
          ),
          endAdornment: getEndAdornment(),
        }}
        type={props.type === "password" && !showPassword ? "password" : "text"}
        name={props.id}
        value={props.formik ? props.formik.values[props.id] : props.value}
        fullWidth
        onBlur={props.formik && props.formik.handleBlur}
        onChange={handleInputChange}
        sx={[
          {
            "& .MuiInputBase-root": {
              backgroundColor: props.backgroundColor
                ? props.backgroundColor
                : "#FFFFFF",
              fontSize: PP(16),
              color: "#4D4D4D",
              minHeight: PP(40),
              height: props.inputHeight,
            },
            "& .MuiInputBase-input": {
              fontFamily: "'Inter', sans-serif",
              padding: props.paddingInput ? props.paddingInput : 0,
            },
          },
          { paddingTop: props.paddingTopInput },
          { height: PP(props.heightProp) },
          props.type === "number" && {
            "& .MuiOutlinedInput-input": {
              "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                WebkitAppearance: "none",
              },
            },
            "& .MuiInputBase-input": {
              "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                WebkitAppearance: "none",
              },
            },
          },
        ]}
        autoFocus={false}
        disabled={props.disabled}
      />
      {props.formik && props.formik.touched[props.id] && (
        <span
          style={{
            color: "#E73B85",
            fontSize: PP(12),
          }}
        >
          {t(props.formik.errors[props.id])}
        </span>
      )}
    </FormControl>
  );
}