import { createSlice } from "@reduxjs/toolkit";

import { CreateUserCustomPriceSliceType } from "../../../interfaces/redux/createUserCustomPriceSliceInterface";
import { fetchcreateUserCustomPrice } from "../../thunks/users/createUserCustomPriceThunk";

const initialState: CreateUserCustomPriceSliceType = {
  loading: false,
  status: -1,
  statusText: "",
  form: {
    category: "",
    materialId: -1,
    price: 0,
  },
};

export const createUserCustomPriceSlice = createSlice({
  name: "createUserCustomPriceReducer",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = -1;
      state.statusText = "";
    },
    setFormCreateCustomPriceState: (state, action) => {
      const { id, value } = action.payload;
      state.form = {
        ...state.form,
        [id]: value,
      };
    },

    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Añadir nuevo precio personalizado de material en usuario específico
    builder.addCase(fetchcreateUserCustomPrice.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchcreateUserCustomPrice.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.statusText = action.payload.statusText;
      state.loading = false;
    });
    builder.addCase(fetchcreateUserCustomPrice.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { resetStatus, reset, setFormCreateCustomPriceState } =
  createUserCustomPriceSlice.actions;

export default createUserCustomPriceSlice.reducer;
