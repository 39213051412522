import { useEffect, useState } from "react";

import { Box } from "@mui/material";
import moment from "moment";

import usePixelPerfect from "../../../../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import CustomText from "../../../texts/CustomText";
import SelectCustomDateRangeFilter from "./SelectCustomDateRangeFilter";
import {
  setRangeDateCollectionsListFilter,
  setRangeDateCollectionsListFilterQuery,
} from "../../../../../redux/slices/collections/collectionsListOtherFiltersBySlice";
import { ReactComponent as BlueCheckIcon } from "../../../../../assets/icons/modals/blueCheck.svg";
import { useTranslation } from "react-i18next";

interface CustomSelectRangeDateProps {
  label?: string;
  width?: number;
}

const subtractDays = (days: number): string => {
  return moment().subtract(days, "days").format("YYYY-MM-DD");
};

const dateFilterOptions: {
  value: string;
  label: string;
}[] = [
    {
      value: `date[$gte]=${subtractDays(30)}&`,
      label: "Últimos 30 días",
    },
    {
      value: `date[$gte]=${subtractDays(15)}&`,
      label: "Últimos 15 días",
    },
    {
      value: `date[$gte]=${subtractDays(7)}&`,
      label: "Últimos 7 días",
    },
    {
      value: `date[$eq]=${subtractDays(1)}&`,
      label: "Ayer",
    },
  ];

export default function CustomSelectRangeDate(
  props: CustomSelectRangeDateProps
) {
  const { PP2 } = usePixelPerfect();
  const { t } = useTranslation();

  const {
    rangeDateCollectionsListFilter,
    rangeDateCollectionsListFilterQuery,
    isSwitchOnSlice
  } = useAppSelector((state) => state.collectionsListOtherFilters);

  const [selected, setSelected] = useState(rangeDateCollectionsListFilter);

  const dispatch = useAppDispatch();

  const handleSetSelected = (selectedDateFilter: {
    value: string;
    label: string;
  }) => {
    setSelected(selectedDateFilter);
    dispatch(setRangeDateCollectionsListFilter(selectedDateFilter));
    dispatch(setRangeDateCollectionsListFilterQuery(selectedDateFilter.value));
  };

  useEffect(() => {
    if (rangeDateCollectionsListFilterQuery === "") {
      dispatch(setRangeDateCollectionsListFilter({ value: "", label: "" }));
      setSelected({ value: "", label: "" });
    }
  }, [rangeDateCollectionsListFilterQuery]);

  return (
    <Box
      sx={{
        marginBottom: PP2(24),
        width: PP2(props.width),
      }}
    >
      <CustomText
        smallSize
        noSpacing
        color="#969696"
        mediumWeight
        style={{ marginBottom: PP2(24), marginTop: PP2(24), fontSize: "14px" }}
      >
        {props.label}
      </CustomText>
      {!isSwitchOnSlice &&
        dateFilterOptions.map((dateOption, key) => {
          return (
            <Box
              key={key}
              onClick={() => handleSetSelected(dateOption)}
              sx={[
                {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: "#FFFFFF",
                  width: PP2(props.width),
                  padding: `${PP2(8)} ${PP2(12)}`,
                  height: PP2(43),
                  transition: "background-color 0.3s ease",
                  borderRadius: PP2(8),
                },
                {
                  ":hover": {
                    backgroundColor: "rgba(85, 124, 230, 0.10)",
                    cursor: "pointer",
                  },
                },
                selected !== null &&
                selected === dateOption && {
                  backgroundColor: "rgba(85, 124, 230, 0.10)",
                },
              ]}
            >
              <Box width={PP2(408)} height={PP2(27)} style={{ padding: PP2(4) }}>
                <CustomText
                  noSpacing
                  color={
                    selected !== null && selected === dateOption
                      ? "#557CE6"
                      : "#4D4D4D"
                  }
                >
                  {t(`collectionsFilterByModal.selectOthersCollectionsFiltersForm.customSelectRangeDateFilter.dateFilterOptions.${dateOption.label}`)}
                </CustomText>
              </Box>
              {selected !== null && selected === dateOption && (
                <span>
                  <BlueCheckIcon />
                </span>
              )}
            </Box>
          );
        })}
      <SelectCustomDateRangeFilter />
    </Box>
  );
}
