import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CollectionMaterialsModalsSliceType } from "../../../interfaces/redux/collectionMaterialsModalsSliceInterface";

type CollectionMaterialsModalKeys = any;

interface SetCollectionsModalStatePayload {
  id: CollectionMaterialsModalKeys;
  value: boolean;
}
const initialState: CollectionMaterialsModalsSliceType = {
  selectCategory: false,
  addMaterial: false,
  selectMaterial: false,
  deleteMaterial: false,
  editQuantity: false,
};

export const collectionMaterialsModalsStateSlice = createSlice({
  name: "collectionMaterialsModalsStateReducer",
  initialState,
  reducers: {
    setCollectionMaterialsModalState: (
      state: any,
      action: PayloadAction<SetCollectionsModalStatePayload>
    ) => {
      // @ts-ignore
      state[action.payload.id] = action.payload.value;
    },
    reset: () => initialState,
  },
});

export const { setCollectionMaterialsModalState, reset } =
  collectionMaterialsModalsStateSlice.actions;

export default collectionMaterialsModalsStateSlice.reducer;
