import { createSlice } from "@reduxjs/toolkit";

import { CommercialsListType } from "../../../interfaces/redux/commercialsListSliceInterface";
import { fetchCommercialsList } from "../../thunks/commercials/commercialsListThunk";

const initialState: CommercialsListType = {
  status: "idle",
  commercialsList: [],
  openSelectCommercialModal: false,
  selectedCommercial: { id: -1, email: "", name: "" },
};

export const commercialsListSlice = createSlice({
  name: "commercialsList",
  initialState,
  reducers: {
    setOpenSelectCommercialModal: (state, action) => {
      state.openSelectCommercialModal = action.payload;
    },
    setSelectedCommercial: (state, action) => {
      state.selectedCommercial = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    //Get lista de comerciales
    builder.addCase(fetchCommercialsList.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchCommercialsList.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.commercialsList = action.payload;
    });
    builder.addCase(fetchCommercialsList.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export const { setOpenSelectCommercialModal, setSelectedCommercial, reset } =
  commercialsListSlice.actions;

export default commercialsListSlice.reducer;
