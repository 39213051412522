import { createRef, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Box, Modal } from "@mui/material";

import TransferDetailsLayout from "../../../layouts/transfers/TransferDetailsLayout";
import { fetchTransferDetails } from "../../../redux/thunks/inventory/transfer/transferDetailsThunk";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import CustomEditTransferStatusModal from "../../../components/custom/modals/inventory/transfer/CustomEditTransferStatusModal";
import EditTransferForm from "../../../components/inventory/transfers/EditTransferForm";
import TransferMaterialInfo from "../../../components/inventory/transfers/TransferMaterialInfo";
import TransferAttachments from "../../../components/inventory/transfers/TransferAttachments";
import CustomSelectCategory from "../../../components/custom/modals/inventory/transfer/CustomSelectCategory";
import CustomAddMaterialModal from "../../../components/custom/modals/inventory/transfer/CustomAddMaterialModal";
import CustomSelectMaterialTransferModal from "../../../components/custom/modals/inventory/transfer/CustomSelectMaterialTransferModal";
import CustomEditTransferMaterialModal from "../../../components/custom/modals/inventory/transfer/CustomEditTransferMaterialModal";
import CustomTransferSelectTypeFileModal from "../../../components/custom/modals/inventory/transfer/CustomTransferSelectTypeFileModal";
import CustomAttachNewTransferFileModal from "../../../components/custom/modals/inventory/transfer/CustomAttachNewTransferFileModal";
import CustomDeleteTransferAttachFileModal from "../../../components/custom/modals/inventory/transfer/CustomDeleteTransferAttachFileModal";
import { resetTransferDetails } from "../../../redux/slices/inventory/transfer/transferDetailsSlice";
import CustomDeleteTransferMaterialModal from "../../../components/custom/modals/inventory/CustomDeleteTransferMaterialModal";

export default function TransferDetails() {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const ref = createRef();

    const { editTransferStatus } = useAppSelector((state) => state.transferDetailsModalStatus);
    const {
        selectCategory, addMaterial,
        selectMaterial, editQuantity,
        deleteMaterial
    } = useAppSelector((state) => state.transferMaterialsModalsStates);

    const { selectTypeFile, attachFile, deleteAttachFile } = useAppSelector((state) => state.transferAttachedFilesModalStates);


    const getTransferDetails = useCallback(async () => {
        if (id) {
            await dispatch(fetchTransferDetails({ id }));
        }
    }, [dispatch, id]);

    useEffect(() => {
        getTransferDetails();

        return () => {
            dispatch(resetTransferDetails())
        }
    }, [dispatch, id]);

    return (
        <TransferDetailsLayout>
            <Box>
                <EditTransferForm />
                <TransferMaterialInfo />
                <TransferAttachments />
            </Box>

            {/* Estados */}
            <Modal
                open={editTransferStatus}
                children={<CustomEditTransferStatusModal ref={ref} />}
            />
            {/* Materiales */}
            <Modal
                open={selectCategory}
                children={<CustomSelectCategory ref={ref} />}
            />
            <Modal
                open={addMaterial}
                children={<CustomAddMaterialModal ref={ref} />}
            />
            <Modal
                open={selectMaterial}
                children={<CustomSelectMaterialTransferModal ref={ref} />}
            />
            <Modal
                open={deleteMaterial}
                children={<CustomDeleteTransferMaterialModal ref={ref} />}
            />
            <Modal
                open={editQuantity}
                children={<CustomEditTransferMaterialModal ref={ref} />}
            />

            {/* Archivos adjuntos*/}

            <Modal
                open={selectTypeFile}
                children={<CustomTransferSelectTypeFileModal ref={ref} />}
            />
            <Modal
                open={attachFile}
                children={<CustomAttachNewTransferFileModal ref={ref} />}
            />
            <Modal
                open={deleteAttachFile}
                children={<CustomDeleteTransferAttachFileModal ref={ref} />}
            />
        </TransferDetailsLayout>
    )
}
