import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  materialId as materialIdValidation,
  quantity as quantityValidation,
  validateYupSchema,
} from "../../utils/forms/validationSchema";
import CustomButton from "../custom/buttons/default/CustomButton";
import CustomInput from "../custom/forms/default/CustomInput";
import CustomText from "../custom/texts/CustomText";
import { Box } from "@mui/material";
import { fetchMaterialsList } from "../../redux/thunks/collections/materialsListThunk";
import CustomAlert from "../custom/alerts/CustomAlert";
import {
  setMessage,
  setOpenSnackbar,
  setType,
} from "../../redux/slices/globalSnackbar/globalSnackbarSlice";
import { reset as resetAddMaterial } from "../../redux/slices/transactions/addMaterialTransactionSlice";
import { resetMaterialsList } from "../../redux/slices/collections/materialsListSlice";
import CustomExportMaterialPickerInput from "../custom/forms/sales/CustomExportMaterialPickerInput";
import { setTransactionMaterialsModalState } from "../../redux/slices/transactions/transactionMaterialsModalsStatesSlice";
import { setFormAddTransactionMaterialState } from "../../redux/slices/transactions/addMaterialTransactionSlice";
import { fetchTransactionDetails } from "../../redux/thunks/transactions/transactionsDetailsThunk";
import { fetchAddMaterialTransaction } from "../../redux/thunks/transactions/addMaterialTransactionThunk";
import { materialInfoType } from "../../interfaces/redux/materialInfoInterface";

export default function AddMaterialTransactionForm() {
  const { id } = useParams();
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { category, materialId, quantity, price } = useAppSelector(
    (state) => state.addMaterialTransaction.form
  );

  const { transactionDetails } = useAppSelector(
    (state) => state.transactionDetails
  );
  const { page, selected, totalMaterialsList, materialsList } = useAppSelector(
    (state) => state.materialsList
  );

  const { materialsPricesList } = useAppSelector(
    (state) => state.collectionMaterialsPricesList
  );

  const [totalToPay, setTotalToPay] = useState(0);

  const [disabledPriceInput, setDisabledPriceInput] = useState(false);

  const getMaterialsList = async () => {
    if (totalMaterialsList > materialsList.length) {
      dispatch(fetchMaterialsList({ page: page, category: category }));
    }
  };

  useEffect(() => {
    getMaterialsList();
  }, [category, dispatch]);

  const resetForm = useCallback(async () => {
    dispatch(resetAddMaterial());
    dispatch(resetMaterialsList());
    dispatch(
      setTransactionMaterialsModalState({ id: "addMaterial", value: false })
    );
  }, [dispatch]);

  const handleClickCloseModal = () => {
    resetForm();
  };

  const handleChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    formikAddMaterialTransaction.setFieldValue("quantity", value);
    dispatch(
      setFormAddTransactionMaterialState({ id: "quantity", value: value })
    );
  };

  const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    formikAddMaterialTransaction.setFieldValue("price", value);
    dispatch(setFormAddTransactionMaterialState({ id: "price", value: value }));
  };

  const handleChangeDiscount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    formikAddMaterialTransaction.setFieldValue("discount", value);
    dispatch(
      setFormAddTransactionMaterialState({ id: "discount", value: value })
    );
  };

  const initialFormikValues = {
    idTransaction: id,
    materialId: materialId,
    quantity: 0,
    price: "0",
    discount: 0,
  };

  const validationSchema = validateYupSchema({
    materialId: materialIdValidation,
    quantity: quantityValidation,
  });

  const handleSubmit = async (data: {
    idTransaction: string | undefined;
    materialId: number;
    quantity: number;
    price: string;
    discount: number;
  }) => {
    const payload = {
      idTransaction: data.idTransaction,
      materials: [
        {
          materialId: data.materialId,
          quantity: Number(data.quantity),
          price: Number(data.price),
          discount: Number(data.discount),
        },
      ],
    };

    let repeatProduct = false;

    transactionDetails.materials.map((material) => {
      if (
        material.materialId === formikAddMaterialTransaction.values.materialId
      )
        repeatProduct = true;
    });

    if (repeatProduct) {
      dispatch(setOpenSnackbar(true));
      dispatch(setMessage(t("Se ha producido un error: material repetido")));
      dispatch(setType("failedSnackbar"));
      if (id !== undefined) dispatch(fetchTransactionDetails({ id: id }));
    } else {
      dispatch(fetchAddMaterialTransaction(payload));
      if (id !== undefined) dispatch(fetchTransactionDetails({ id: id }));
      dispatch(setOpenSnackbar(true));
      dispatch(setMessage(t("Material añadido")));
      dispatch(setType("sucessSnackbar"));
    }
    handleClickCloseModal();
  };

  const formikAddMaterialTransaction = useFormik({
    initialValues: initialFormikValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: false,
  });

  useEffect(() => {
    const newTotalToPay =
      Number(formikAddMaterialTransaction.values.price) *
        Number(formikAddMaterialTransaction.values.quantity) -
      Number(formikAddMaterialTransaction.values.discount);
    setTotalToPay(newTotalToPay);
  }, [
    formikAddMaterialTransaction.values.price,
    formikAddMaterialTransaction.values.quantity,
    formikAddMaterialTransaction.values.discount,
  ]);

  useEffect(() => {
    if (selected && materialsPricesList.length > 0) {
      let material = materialsPricesList.filter((elem) => {
        return elem.materialId === selected.id;
      })[0];

      if (!material)
        material = {
          ...selected,
          ownerType: "country",
          price: 0,
          materialId: selected.id,
          countryId: 1,
          ownerId: 1,
        };

      formikAddMaterialTransaction.setFieldValue("price", material.price);
    }
  }, [
    formikAddMaterialTransaction.values.materialId,
    selected,
    disabledPriceInput,
    formikAddMaterialTransaction.isSubmitting,
  ]);

  return (
    <form noValidate onSubmit={formikAddMaterialTransaction.handleSubmit}>
      <CustomInput
        id="category"
        label={t("Categoría")}
        placeholder={t("Categoría de material")}
        value={t(category)}
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        inputHeight={PP(40)}
        inputBorderRadius={PP(8)}
        backgroundColor="#F6F6F6"
        extraStyle={{ marginTop: PP(24) }}
        disabled
      />
      <CustomExportMaterialPickerInput
        width={432}
        label={t("Material")}
        required
        id="materialId"
        error={t(formikAddMaterialTransaction.errors.materialId)}
      />

      <CustomInput
        id="quantity"
        label={t("Cantidad")}
        placeholder="00"
        formik={formikAddMaterialTransaction}
        value={formikAddMaterialTransaction.values.quantity}
        onChange={handleChangeQuantity}
        labelStyles={{
          fontSize: PP(14),
          fontWeight: 700,
        }}
        inputHeight={PP(40)}
        inputBorderRadius={PP(4)}
        endAdornmentValue={selected?.measure}
        extraStyle={{ marginBottom: PP(16) }}
      />
      {/* Alerta informativa Para ACU */}
      {formikAddMaterialTransaction.values.materialId === 1 && (
        <CustomAlert alertText={t("Ingresar peso NETO del material")} />
      )}

      <CustomInput
        id="price"
        label={t("Precio unitario")}
        placeholder="0.00"
        formik={formikAddMaterialTransaction}
        value={formikAddMaterialTransaction.values.price}
        disabled={disabledPriceInput}
        onChange={handleChangePrice}
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        extraStyle={{ marginBottom: PP(16) }}
        inputHeight={PP(24)}
        inputBorderRadius={PP(8)}
        endAdornmentValue={
          <CustomText noSpacing color="#969696">
            {transactionDetails.currency}
          </CustomText>
        }
      />

      <CustomInput
        id="discount"
        label={t("Descuento")}
        placeholder="0.00"
        formik={formikAddMaterialTransaction}
        value={formikAddMaterialTransaction.values.discount}
        disabled={disabledPriceInput}
        onChange={handleChangeDiscount}
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        extraStyle={{ marginBottom: PP(16) }}
        inputHeight={PP(24)}
        inputBorderRadius={PP(8)}
        endAdornmentValue={
          <CustomText noSpacing color="#969696">
            {transactionDetails.currency}
          </CustomText>
        }
      />

      <Box display="flex" flexDirection="row">
        <CustomText noSpacing color="#4D4D4D">
          {t("Total a pagar")}:
        </CustomText>
        <CustomText noSpacing mediumWeight color="#4D4D4D">
          &nbsp;&nbsp;$ {totalToPay.toLocaleString("es-MX")}
        </CustomText>
      </Box>

      <CustomButton
        width={432}
        height={44}
        noSpacing
        style={{ padding: "0px", marginTop: PP(24) }}
        type="submit"
        label={t("Agregar")}
        formik={formikAddMaterialTransaction}
      />
      <CustomButton
        noSpacing
        width={432}
        height={44}
        cancelButton
        onClick={handleClickCloseModal}
        formik={formikAddMaterialTransaction}
      />
    </form>
  );
}
