import { createSlice } from "@reduxjs/toolkit";

import { NewCollectionUserSliceType } from "../../../interfaces/redux/newCollectionUserSliceInterface";
import { fetchCreateNewCollectionUser } from "../../thunks/users/newCollectionUserThunk";

const initialState: NewCollectionUserSliceType = {
  loading: false,
  status: -1,
  statusText: "",
  form: {
    address_id: -1,
    date: "",
    selectedHour: {
      label: "Indiferente",
      value: { initialTime: "08:00:00", finalTime: "18:00:00" },
    },
    observation: "",
  },
};

export const newCollectionSlice = createSlice({
  name: "newCollectionReducer",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = -1;
      state.statusText = "";
    },
    setFormState: (state, action) => {
      const { id, value } = action.payload;
      state.form = {
        ...state.form,
        [id]: value,
      };
    },

    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Crear nueva recolección
    builder.addCase(fetchCreateNewCollectionUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCreateNewCollectionUser.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.statusText = action.payload.statusText;
      state.loading = false;
    });
    builder.addCase(fetchCreateNewCollectionUser.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { resetStatus, reset, setFormState } = newCollectionSlice.actions;

export default newCollectionSlice.reducer;
