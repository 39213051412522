import { useState } from "react";

import { Box } from "@mui/material";

import CustomText from "../../texts/CustomText";
import { ReactComponent as BlueXIcon } from "../../../../assets/icons/chips/blueX.svg";

import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import { setCollectionsFilter } from "../../../../redux/slices/collections/collectionListSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  setCollectorCollectionsListFilterQuery,
  setPaymentMethodCollectionsListFilterQuery,
  setRangeDateCollectionsListFilterQuery,
  setStatesCollectionsFilterQuery,
  setTypeOfMaterialCollectionsListFilterQuery,
} from "../../../../redux/slices/collections/collectionsListOtherFiltersBySlice";
import { useTranslation } from "react-i18next";

interface CustomAppliedFilterChipProps {
  chipLabel: string;
  filterName: FilterType;
}

const customLabelFilter = {
  state: "Estado",
  date: "Fecha",
  collectorId: "Recolector",
  have: "Tipo de recolección",
  payment_method: "Método de pago",
};

export type FilterType =
  | "state"
  | "date"
  | "collectorId"
  | "have"
  | "payment_method"
  | "cityId";

export default function CustomAppliedFilterChip(
  props: CustomAppliedFilterChipProps
) {
  const { chipLabel, filterName } = props;
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { cityFilter, warehouseFilter } = useAppSelector(
    (state) => state.collectionsListWarehouseFilter
  );

  const {
    statesCollectionsListFilterQuery,
    rangeDateCollectionsListFilterQuery,
    collectorCollectionsListFilterQuery,
    typeOfMaterialCollectionsListFilterQuery,
    paymentMethodCollectionsListFilterQuery,
  } = useAppSelector((state) => state.collectionsListOtherFilters);

  const [filters, setFilters] = useState<Record<string, string>>({
    state: statesCollectionsListFilterQuery,
    date: rangeDateCollectionsListFilterQuery,
    collectorId: collectorCollectionsListFilterQuery,
    have: typeOfMaterialCollectionsListFilterQuery,
    payment_method: paymentMethodCollectionsListFilterQuery,
  });

  const handleChangesFilters = async (filterNameToRemove: FilterType) => {
    // Copia del objeto de filtros actual
    const updatedFilters = { ...filters };

    // Establece la propiedad a un valor vacío en lugar de eliminarla
    updatedFilters[filterNameToRemove] = "";

    filterNameToRemove === "state"
      ? dispatch(setStatesCollectionsFilterQuery(""))
      : filterNameToRemove === "date"
        ? dispatch(setRangeDateCollectionsListFilterQuery(""))
        : filterNameToRemove === "collectorId"
          ? dispatch(setCollectorCollectionsListFilterQuery(""))
          : filterNameToRemove === "have"
            ? dispatch(setTypeOfMaterialCollectionsListFilterQuery(""))
            : dispatch(setPaymentMethodCollectionsListFilterQuery(""));

    // Estado con los filtros actualizados
    console.log("nuevo: ", updatedFilters);

    setFilters(updatedFilters);

    // Nueva consulta de filtro basada en los filtros restantes
    const updatedCollectionsFiltersQuery =
      Object.values(updatedFilters).join("");

    // Actualización de la consulta de filtro en Redux
    await dispatch(
      setCollectionsFilter(updatedCollectionsFiltersQuery + warehouseFilter)
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      height={PP(28)}
      padding={`${PP(4)} ${PP(8)}`}
      marginRight={PP(8)}
      style={{
        backgroundColor: "rgba(85, 124, 230, 0.15)",
        borderRadius: PP(4),
      }}
      alignItems="center"
    >
      <Box display="flex">
        <CustomText
          noSpacing
          color="#557CE6"
          mediumSize
          style={{ marginRight: PP(2) }}
        >
          {
            // @ts-ignore
            t(`collectionsFilterByModal.selectOthersCollectionsFiltersForm.customLabelFilter.${customLabelFilter[filterName]}`)
          }
        </CustomText>
        <CustomText noSpacing color="#557CE6" mediumSize mediumWeight>
          {chipLabel}
        </CustomText>
      </Box>

      <Box
        style={{
          marginRight: PP(8),
          marginLeft: PP(8),
        }}
      >
        <CustomText noSpacing color="#557CE6" mediumSize>
          |
        </CustomText>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        style={{ cursor: "pointer" }}
        onClick={() => {
          handleChangesFilters(filterName);
        }}
      >
        <BlueXIcon style={{ width: PP(16), height: PP(16) }} />
      </Box>
    </Box>
  );
}
