import { createAsyncThunk } from "@reduxjs/toolkit";

import { postRedux } from "../../../utils/old/api";

export const fetchCreateNewCollectionUser = createAsyncThunk(
  "createNewCollection/fetchPost",
  async (payload: { data: {} }, thunkAPI) => {
    try {
      const response = await postRedux("collection", payload.data);
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
