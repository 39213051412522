import { FormEvent, useCallback, useEffect } from "react";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import usePixelPerfect from "../../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { setTransferMaterialsModalState } from "../../../redux/slices/inventory/transfer/transferMaterialsModalsStatesSlice";
import { fetchTransferDetails } from "../../../redux/thunks/inventory/transfer/transferDetailsThunk";
import { setMessage, setOpenSnackbar, setType } from "../../../redux/slices/globalSnackbar/globalSnackbarSlice";
import CustomText from "../../custom/texts/CustomText";
import CustomButton from "../../custom/buttons/default/CustomButton";
import { firstLetterCapitalizeFormat } from "../../../utils/formats/firstLetterCapitalizeFormat";
import { fetchDeleteMaterialTransfer } from "../../../redux/thunks/inventory/transfer/deleteMaterialTransferThunk";
import { resetDeleteMaterialTransfer } from "../../../redux/slices/inventory/transfer/deleteMaterialTransferSlice";



export default function DeleteMaterialTransferForm() {
    const { id } = useParams();
    const { PP } = usePixelPerfect();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { selectedToDeleteMaterialName, selectedToDeleteTransferMaterialId } =
        useAppSelector((state) => state.deleteMaterialTransfer);

    const resetForm = useCallback(async () => {
        dispatch(resetDeleteMaterialTransfer());
        dispatch(
            setTransferMaterialsModalState({ id: "deleteMaterial", value: false })
        );
    }, [dispatch]);

    const handleClickCloseModal = () => {
        resetForm();
    };

    const getTransactionDetails = async () => {
        if (id !== undefined) await dispatch(fetchTransferDetails({ id: id }));
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        await dispatch(
            fetchDeleteMaterialTransfer({
                transferMaterialId: selectedToDeleteTransferMaterialId.toString(),
            })
        );
        getTransactionDetails();
        dispatch(setOpenSnackbar(true));
        dispatch(setMessage(t("Material eliminado con éxito")));
        dispatch(setType("sucessSnackbar"));
        handleClickCloseModal();
    };

    useEffect(() => {
        getTransactionDetails();
    }, [dispatch, id]);

    return (
        <form noValidate onSubmit={handleSubmit}>
            <Box
                sx={[
                    {
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        backgroundColor: "#FFFFFF",
                        width: PP(432),
                        height: PP(40),
                    },
                ]}
            >
                <CustomText noSpacing color="#585757" width="100%">
                    ¿{t("Estás seguro que deseas eliminar")}
                    <span style={{ color: "#FF3364" }}>
                        {" "}
                        {firstLetterCapitalizeFormat(selectedToDeleteMaterialName)}
                    </span>
                    ?
                </CustomText>
            </Box>

            <CustomButton
                width={432}
                height={44}
                noSpacing
                style={{ padding: "0px", marginTop: PP(24) }}
                deleteButton
                type="submit"
                label={t("Sí, deseo eliminarlo")}
            />
            <CustomButton
                noSpacing
                width={432}
                height={44}
                cancelButton
                onClick={handleClickCloseModal}
            />
        </form>
    );
}
