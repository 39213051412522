import { forwardRef, Fragment, useCallback } from "react";

import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../redux/store";
import CustomText from "../../texts/CustomText";
import useAppDispatch from "../../../../hooks/old/useAppDispatch";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import CollectionsWarehouseFilterModalLayout from "../../../../layouts/collections/modals/CollectionsWarehouseFilterModalLayout";
import SelectWarehouseFilterForm from "../../../collections/SelectWarehouseFilterForm";
import { reset as resetWareHouseFilterModalStates } from "../../../../redux/slices/collections/collectionWarehouseFilterModalStatesSlice";
import { resetWarehouseFilter } from "../../../../redux/slices/collections/collectionsListWarehouseFilterSlice";

export default forwardRef(function CollectionWarehouseFilterModal() {
  const { status } = useAppSelector((state) => state.newCollectionUser);
  const dispatch = useAppDispatch();
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();

  const resetForm = useCallback(async () => {
    dispatch(resetWarehouseFilter());
    dispatch(resetWareHouseFilterModalStates());
  }, [dispatch]);

  const handleClickCloseModal = (event: React.MouseEvent<SVGSVGElement>) => {
    resetForm();
  };

  return (
    <CollectionsWarehouseFilterModalLayout handleClose={handleClickCloseModal}>
      {status === -1 && (
        <Fragment>
          <CustomText
            showBackArrowButton
            noSpacing
            bigSize
            premediumWeight
            color="#1C1C1C"
            style={{ marginBottom: PP(24) }}
            onClick={resetForm}
          >
            {t("collectionWarehouseFilterModal.Seleccionar bodega")}
          </CustomText>
          <Divider sx={{ margin: "0px" }} />
          <SelectWarehouseFilterForm />
        </Fragment>
      )}
    </CollectionsWarehouseFilterModalLayout>
  );
});
