import * as Yup from "yup";

const createBusinessSchema = (t: any) =>
  Yup.object().shape({
    name: Yup.string()
      .min(3, t("createNewBusinessModal.validations.Mín 3 caracteres"))
      .required(t("createNewBusinessModal.validations.Nombre requerido")),
    email: Yup.string()
      .email(t("createNewBusinessModal.validations.Correo no válido"))

      .max(255, t("createNewBusinessModal.validations.máx 255 caracteres")),

    legal_name: Yup.string().min(
      3,
      t("createNewBusinessModal.validations.Mín 3 caracteres")
    ),
    phone: Yup.string(),

    // regime: Yup.string().required(
    //   t("createNewBusinessModal.validations.Régimen requerido")
    // ),

    segment: Yup.string(),

    payment_method: Yup.string().required(
      t("createNewBusinessModal.validations.Método de pago requerido")
    ),

    // operator: Yup.string().required(
    //   t("createNewBusinessModal.validations.Operador requerido")
    // ),

    document_type: Yup.string(),

    // document_number: Yup.string()
    //   .required(
    //     t("createNewBusinessModal.validations.Número de documento requerido")
    //   )
    //   .matches(
    //     /^[0-9]+$/,
    //     t(
    //       "createNewBusinessModal.validations.El número de documento debe contener justamente sólo números, sin espacios ni guion"
    //     )
    //   )
    //   .matches(
    //     /^[^\s-]+$/,
    //     t(
    //       "createNewBusinessModal.validations.El número de documento no puede contener espacios ni guiones"
    //     )
    //   ),

    internal_code: Yup.string().matches(
      /^[a-zA-Z0-9]+$/,
      t(
        "createNewBusinessModal.validations.Ingresa una combinación válida de números y letras"
      )
    ),
  });

export default createBusinessSchema;
