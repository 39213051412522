import { createAsyncThunk } from "@reduxjs/toolkit";

import { getRedux } from "../../../utils/old/api";

export const fetchUserAddressesList = createAsyncThunk(
  "userAddressesList/fetch",
  async (
    payload: { page: number; ownerType: string; id: string | undefined },
    thunkAPI
  ) => {
    try {
      const response = await getRedux(
        `/address?$skip=${payload.page * 10}&$limit=10&ownerType=${
          payload.ownerType
        }&ownerId=${payload.id}&admin=true&$sort[createdAt]=-1`
      );
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
