import { createSlice } from "@reduxjs/toolkit";

import { fetchTransactionsList } from "../../thunks/transactions/transactionsListThunk";
import { TransactionsListSliceType } from "../../../interfaces/redux/transactionsListSliceInterface";

const initialState: TransactionsListSliceType = {
  status: "idle",
  transactionsList: [],
  totalRows: 0,
  filter: "",
};

export const transactionsListSlice = createSlice({
  name: "transactionsList",
  initialState,
  reducers: {
    reset: () => initialState,
    setTransactionsFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    //Get lista transacciones en general con o sin filtros
    builder.addCase(fetchTransactionsList.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchTransactionsList.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.transactionsList = action.payload.data;
      state.totalRows = action.payload.total;
    });
    builder.addCase(fetchTransactionsList.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export const { reset, setTransactionsFilter } = transactionsListSlice.actions;

export default transactionsListSlice.reducer;
