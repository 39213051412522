import { createAsyncThunk } from "@reduxjs/toolkit";

import { patchRedux } from "../../../utils/old/api";

export const fetchEditClient = createAsyncThunk(
  "editClient/fetchPatch",
  async (
    payload: {
      data: {
        name: string;
        legalName: string;
        documentNumber: string;
        address: string;
        contactName: string;
        contactEmail: string;
        contactPhone: number;
      };
      clientId: number;
    },
    thunkAPI
  ) => {
    try {
      const response = await patchRedux(
        `/client/${payload.clientId}`,
        payload.data
      );
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    } catch (error) {
      const errors = thunkAPI.rejectWithValue(error);
      //   console.log(errors);
      return errors;
    }
  }
);
