import { createSlice } from "@reduxjs/toolkit";

import { warehousesListSliceType } from "../../../interfaces/redux/warehousesListSliceInterface";
import { fetchWarehouseQuantityList, fetchWarehousesList, updateWarehouseQuantity } from "../../thunks/warehouse/warehousesListThunk";

const initialState: warehousesListSliceType = {
  loading: false,
  status: "idle",
  warehousesList: [],
  page: 0,
  totalWarehousesList: 0,
  inventoryQuantities: [],
  quantityStatus: "idle",
  loadingQuantities: false
};

export const warehouseListSlice = createSlice({
  name: "warehouseListReducer",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Get Lista de bodegas
    builder.addCase(fetchWarehousesList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchWarehousesList.fulfilled, (state, action) => {

      for (let i = 0; i < action.payload.data.length; i++) {
        let temp = action.payload.data[i];
        temp = {
          id: temp._id,
          name: temp._source.name,
          enabled: temp._source.enabled,
          cityId: temp._source.cityId
        }
        action.payload.data[i] = temp;
      }
      console.log(action.payload.data);
      
      if (action.payload.data && action.payload.data.length > 0) {
        state.warehousesList = state.warehousesList.concat(
          action.payload.data
        );
      }
      state.totalWarehousesList = action.payload.data.length;
      state.status = "succeeded";
      state.loading = false;
      state.page = state.page + 1;
    });
    builder.addCase(fetchWarehousesList.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fetchWarehouseQuantityList.fulfilled, (state, action) => {
      state.loadingQuantities = false;
      state.inventoryQuantities = action.payload.data;
      
    });
    builder.addCase(fetchWarehouseQuantityList.pending, (state, action) => {
      state.loadingQuantities = true;
    });
    builder.addCase(updateWarehouseQuantity.pending, (state, action) => {
      state.quantityStatus = "loading";
    });
    builder.addCase(updateWarehouseQuantity.fulfilled, (state, action) => {
      state.quantityStatus = "succeeded";
    });
  },
});

export const { reset } = warehouseListSlice.actions;

export default warehouseListSlice.reducer;
