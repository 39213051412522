import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import "moment/locale/es";
import moment from "moment-timezone";

import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Card as MuiCard,
  Chip as MuiChip,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  CardContent,
  Select,
  MenuItem,
  TextField,
  Alert,
  TablePagination,
  Box,
} from "@mui/material";
import {
  RemoveRedEye as RemoveRedEyeIcon,
  Close as CloseIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { green, orange, red } from "@mui/material/colors";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";

import { BankList } from "../../../../../utils/old/bankList";
import {
  fetchBankAccountsList,
  fetchCreateWithdrawal,
  fetchUpdateWithdrawal,
  fetchWithdrawals,
  setPageWithdrawals,
} from "../../../../../redux/old/slices/businessSlicer";
import { documentTypeOptionsObject } from "../../../../../utils/old/constants";
import { useTranslation } from "react-i18next";
import { dateFormat } from "../../../../../utils/formats/dateFormat";

const Paper = styled(MuiPaper)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};
  background: ${(props) => props.pending && orange[700]};
  background: ${(props) => props.approved && green[500]};
  background: ${(props) => props.rejected && red[400]};
  color: ${(props) => props.pending && props.theme.palette.common.white};
`;

const headCells = [
  { id: "bank", alignment: "center", label: "Banco", disablePadding: true },
  { id: "account", alignment: "center", label: "Cuenta", disablePadding: true },
  {
    id: "accountType",
    alignment: "center",
    label: "Tipo",
    disablePadding: true,
  },
  { id: "amount", alignment: "center", label: "Monto", disablePadding: true },
  {
    id: "date",
    alignment: "center",
    label: "Fecha de solicitud",
    disablePadding: true,
  },
  { id: "state", alignment: "center", label: "Estado", disablePadding: true },
  {
    id: "actions",
    alignment: "center",
    label: "Acciones",
    disablePadding: true,
  },
];

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {t(`tables.tableHeadCells.${headCell.label}`)}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

function EnhancedTable({ id }) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("customer");
  const [selected, setSelected] = useState([]);
  const [modalEdit, setModalEdit] = useState(false);
  const [selectedWithdrawal, setSelectedWithdrawal] = useState({
    owner: { name: "" },
  });
  const [openModal, setOpenModal] = useState(false);
  const [snackTxt, setSnackTxt] = useState("hola");
  const [showSnack, setShowSnack] = useState(false);
  const { t } = useTranslation();

  const isSelected = (id) => selected.indexOf(id) !== -1;

  //Reduxtoolkit
  const rows = useSelector((state) => state.business.rows);
  const pageWithdrawals = useSelector(
    (state) => state.business.pageWithdrawals
  );
  const totalRowsWithdrawals = useSelector(
    (state) => state.business.totalRowsWithdrawals
  );
  const { successWithdrawal, totalBankAccounts } = useSelector(
    (state) => state.business
  );

  const rowsManageWithdrawal = [
    {
      label: t("manageWithdrawalModal.Tipo de cuenta"),
      value:
        selectedWithdrawal.accountType === "savings"
          ? t("CUENTA DE AHORROS")
          : t("CUENTA CORRIENTE"),
    },
    {
      label: t("manageWithdrawalModal.Nombre comercial"),
      value: selectedWithdrawal.owner.name,
    },
    {
      label: t("manageWithdrawalModal.Tipo de documento"),
      value: documentTypeOptionsObject[selectedWithdrawal.owner.document_type],
    },
    {
      label: t("manageWithdrawalModal.Número de documento"),
      value: selectedWithdrawal.owner.document_number,
    },
    {
      label: t("manageWithdrawalModal.Correo"),
      value: selectedWithdrawal.owner.email,
    },
    {
      label: t("manageWithdrawalModal.Celular"),
      value: selectedWithdrawal.owner.phone,
    },
    {
      label: t("manageWithdrawalModal.Banco"),
      value: BankList[selectedWithdrawal.bank],
    },
    {
      label: t("manageWithdrawalModal.Monto"),
      value: selectedWithdrawal.amount,
    },
  ];

  const dispatch = useDispatch();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  useEffect(() => {
    getWithdrawals();
  }, [openModal]);

  const getWithdrawals = async (cPage = 0) => {
    dispatch(fetchWithdrawals({ cPage: cPage, id: id })); //Redux Get
    dispatch(setPageWithdrawals(cPage)); //ReduxToolkit reducer
    dispatch(fetchBankAccountsList(id));
  };

  const handleChangePageWithdrawals = async (event, newPage) => {
    dispatch(fetchWithdrawals({ cPage: newPage, id: id }));
    dispatch(setPageWithdrawals(newPage));
  };

  const handleChangeRowsPerWithdrawalsPage = async () => {
    dispatch(fetchWithdrawals({ cPage: 0, id: id }));
  };

  const updateWithdrawal = async (state) => {
    dispatch(
      fetchUpdateWithdrawal({
        state: state,
        selectedWithdrawal: selectedWithdrawal,
      })
    );
    getWithdrawals();
    setSnackTxt(t("Retiro actualizado"));
    setShowSnack(true);
    setModalEdit(false);
    getWithdrawals();
  };

  //Manejando creación de nuevas solicitudes de retiro:

  function validationSchema() {
    return {
      bank_account: Yup.string().required(
        t("newWithdrawalModal.validations.Cuenta bancaria requerida")
      ),
      amount: Yup.number()
        .required(t("newWithdrawalModal.validations.Cantidad es requerida"))
        .min(
          50000,
          t(
            "newWithdrawalModal.validations.La cantidad no puede ser menor a 50.000"
          )
        ),
    };
  }

  const initialValuesCollection = {
    bank_account: "",
    amount: 0,
  };

  const formikWithdrawal = useFormik({
    initialValues: initialValuesCollection,
    enableReinitialize: true,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) => {
      createWithdrawal(data);
    },
  });

  const createWithdrawal = async (data) => {
    dispatch(fetchCreateWithdrawal(data));
    setOpenModal(false);
    if (successWithdrawal) {
      setSnackTxt(t("Solicitud de retiro creada"));
      setShowSnack(successWithdrawal);
    }
    getWithdrawals();
  };

  return (
    <div>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h6" gutterBottom>
            {t("Retiros")}
          </Typography>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => setOpenModal(true)}
            >
              <AddIcon />
              {t("newWithdrawalModal.Nuevo")}
            </Button>
          </div>
        </Grid>
      </Grid>

      <Paper>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={`${row.id}-${index}`}
                    selected={isItemSelected}
                  >
                    <TableCell align="left">{BankList[row.bank]}</TableCell>
                    <TableCell align="left">{row.number}</TableCell>
                    {row.accountType === "checking" && (
                      <TableCell>
                        {t("newWithdrawalModal.CUENTA CORRIENTE")}
                      </TableCell>
                    )}

                    {row.accountType === "savings" && (
                      <TableCell>
                        {t("newWithdrawalModal.CUENTA DE AHORROS")}
                      </TableCell>
                    )}
                    <TableCell align="right">${row.amount}</TableCell>
                    <TableCell align="right">
                      {dateFormat(row.createdAt, t)}
                    </TableCell>
                    <TableCell align="right">
                      {row.state === "pending" && (
                        <Chip
                          size="small"
                          mr={1}
                          mb={1}
                          label={t("newWithdrawalModal.Pendiente")}
                          pending={+true}
                        />
                      )}
                      {row.state === "approved" && (
                        <Chip
                          size="small"
                          mr={1}
                          mb={1}
                          label={t("newWithdrawalModal.Aprobado")}
                          approved={+true}
                        />
                      )}
                      {row.state === "rejected" && (
                        <Chip
                          size="small"
                          mr={1}
                          mb={1}
                          label={t("newWithdrawalModal.Rechazado")}
                          rejected={+true}
                        />
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        aria-label="details"
                        size="large"
                        onClick={(e) => {
                          setSelectedWithdrawal(row);
                          setModalEdit(true);
                        }}
                      >
                        <RemoveRedEyeIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[20]}
            component="div"
            count={totalRowsWithdrawals}
            rowsPerPage={20}
            page={pageWithdrawals}
            onPageChange={handleChangePageWithdrawals}
            onRowsPerPageChange={handleChangeRowsPerWithdrawalsPage}
          />
        </TableContainer>
        {rows.length === 0 && (
          <Box display="flex" justifyContent="center">
            <Typography style={{ fontSize: "16px", color: "gray" }}>
              {t("No tiene retiros")}
            </Typography>
          </Box>
        )}
      </Paper>

      {/*----------------------------- MODAL ADMINISTRACIÓN DE RETIRO-------------------------------------- */}
      <Dialog
        fullWidth
        open={modalEdit}
        onClose={() => setModalEdit(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t("manageWithdrawalModal.Administrar retiro")}
        </DialogTitle>
        <DialogContent>
          <Table>
            {rowsManageWithdrawal.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.label}</TableCell>
                <TableCell>{row.value}</TableCell>
              </TableRow>
            ))}
          </Table>
          <DialogContentText>
            {t(
              "manageWithdrawalModal.Mutante, recuerda que si apruebas el retiro, la billetera de {negocio} cambiará automáticamente.",
              {
                ownerName: selectedWithdrawal.owner.name,
              }
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => updateWithdrawal({ state: "rejected" })}
            color="primary"
          >
            {t("manageWithdrawalModal.Rechazar")}
          </Button>
          <Button
            onClick={() => updateWithdrawal({ state: "approved" })}
            color="primary"
          >
            {t("manageWithdrawalModal.Aprobar")}
          </Button>
        </DialogActions>
      </Dialog>

      {/*----------------------------- MODAL SOLICITUD DE RETIRO-------------------------------------- */}

      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={formikWithdrawal.handleSubmit}>
          <DialogTitle id="form-dialog-title">
            {t("newWithdrawalModal.Solicitar un retiro")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t(
                "newWithdrawalModal.Se le asignará al negocio actual. Recuerda que el negocio debe tener mínimo $50.000COP disponibles en su billetera para realizar esta solicitud"
              )}
              .
            </DialogContentText>
            <Grid container spacing={6}>
              <Grid item md={6} my={2}>
                <Select
                  name="bank_account"
                  label="Cuenta Bancaria"
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formikWithdrawal.values.bank_account}
                  onChange={formikWithdrawal.handleChange}
                >
                  {totalBankAccounts.map((element, index) => (
                    <MenuItem key={element._id} value={element._id}>
                      {BankList[element.bank] + " " + element.number}{" "}
                    </MenuItem>
                  ))}
                </Select>
                {formikWithdrawal.errors.bank_account && (
                  <Alert mt={2} mb={3} severity="error">
                    {formikWithdrawal.errors.bank_account}
                  </Alert>
                )}
              </Grid>
              <Grid item md={6} my={2}>
                <TextField
                  name="amount"
                  label={t("newWithdrawalModal.Cantidad")}
                  type="decimal"
                  variant="outlined"
                  value={formikWithdrawal.values.amount}
                  onChange={formikWithdrawal.handleChange}
                  fullWidth
                  my={2}
                />
                {formikWithdrawal.errors.amount && (
                  <Alert mt={2} mb={3} severity="error">
                    {formikWithdrawal.errors.amount}
                  </Alert>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenModal(false)} color="primary">
              {t("newWithdrawalModal.Cancelar")}
            </Button>
            <Button type="submit" color="primary">
              {t("newWithdrawalModal.Solicitar")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/*--------------- Snackbar de Confirmación --------------*/}

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showSnack}
        autoHideDuration={6000}
        onClose={() => {
          setShowSnack(false);
        }}
        message={t(snackTxt)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                setShowSnack(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}

function WithdrawalsCard() {
  const { id } = useParams();

  return (
    <React.Fragment>
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <EnhancedTable id={id} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default WithdrawalsCard;
