import { forwardRef } from "react";

import { Box, Divider } from "@mui/material";

import CustomButton from "../../buttons/default/CustomButton";
import CustomText from "../../texts/CustomText";

import DetailsCollectionModalLayout from "../../../../layouts/collections/modals/DetailsCollectionModalLayout";
import { setCollectionEditStateModalState } from "../../../../redux/slices/collections/collectionDetailsInfoModalStatesSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { firstLetterCapitalizeFormat } from "../../../../utils/formats/firstLetterCapitalizeFormat";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import { collectionsStates } from "../../../../utils/constants/collections/collectionsStates";
import { useTranslation } from "react-i18next";

export default forwardRef(function CustomCollectionWithMaterialsModal() {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { selectedState } = useAppSelector(
    (state) => state.updateCollectionState
  );

  const handleCloseClickModal = () => {
    dispatch(
      setCollectionEditStateModalState({
        id: "collectionWithMaterials",
        value: false,
      })
    );
  };
  return (
    <DetailsCollectionModalLayout handleClose={handleCloseClickModal}>
      <CustomText
        bigSize
        premediumWeight
        color="#1C1C1C"
        style={{ marginBottom: PP(24) }}
      >
        {t(
          "collectionDetailsLayout.customCollectionWithMaterialsModal.Recolección con materiales"
        )}
      </CustomText>
      <Divider style={{ marginBottom: PP(24) }} />
      <Box
        sx={[
          {
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            backgroundColor: "#FFFFFF",
            width: PP(432),
            height: "auto",
          },
        ]}
      >
        <CustomText noSpacing color="#585757" width="100%">
          {t(
            "collectionDetailsLayout.customCollectionWithMaterialsModal.El estado de la recolección no puede cambiarse a"
          )}{" "}
          <span style={{ color: "#557CE6" }}>
            {t(
              `collectionDetailsLayout.states.${firstLetterCapitalizeFormat(
                collectionsStates[selectedState]
              )}`
            )}
          </span>{" "}
          {t(
            "collectionDetailsLayout.customCollectionWithMaterialsModal.mientras tenga materiales registrados"
          )}
          .
        </CustomText>
      </Box>

      <CustomButton
        width={432}
        height={44}
        noSpacing
        style={{ padding: "0px", marginTop: PP(24) }}
        label={t(
          "collectionDetailsLayout.customCollectionWithMaterialsModal.Entendido"
        )}
        onClick={handleCloseClickModal}
      />
    </DetailsCollectionModalLayout>
  );
});
