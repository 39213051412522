import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedMaterialToEdit: {
    materialId: -1,
    quantityMaterial: -1,
    type: "",
    name: "",
    category: "",
    materialNameTranslations: {
      translations: {
        es: "",
        en: "",
        it: "",
      },
    },
    materialMeasure: "",
  },
  loading: false,
  status: -1,
  statusText: "",
  form: {
    quantityMaterial: 0,
  },
};

export const editMaterialTransformationSlice = createSlice({
  name: "editMaterialTransformationReducer",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = -1;
      state.statusText = "";
    },
    setFormEditTransformatioMaterial: (state, action) => {
      const { id, value } = action.payload;
      state.form = {
        ...state.form,
        [id]: value,
      };
    },
    setSelectedMaterialToEdit: (state, action) => {
      state.selectedMaterialToEdit = action.payload;
    },

    resetEditTransformationMaterial: () => initialState,
  },
});

export const {
  resetStatus,
  resetEditTransformationMaterial,
  setFormEditTransformatioMaterial,
  setSelectedMaterialToEdit,
} = editMaterialTransformationSlice.actions;

export default editMaterialTransformationSlice.reducer;
