import { createAsyncThunk } from "@reduxjs/toolkit";

import { getRedux } from "../../../utils/old/api";

export const fetchTransactionDetails = createAsyncThunk(
  "transactionDetails/fetchGet",
  async (payload: { id: string }, thunkAPI) => {
    try {
      const response = await getRedux(`/transaction/${payload.id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
