import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import { spacing } from "@mui/system";
import {
  Alert,
  Button,
  Card as MuiCard,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import {
  Add as AddIcon,
  // Edit as EditIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import styled from "@emotion/styled";

import { fetchCities } from "../../../../../redux/old/slices/citiesSlicer";
import {
  collectionHours,
  collectionHoursIntialTime,
  daysForCollect,
  recurrentDaysForCollect,
} from "../../../../../utils/old/constants";
import {
  formatCollectionHour,
  formatHour,
} from "../../../../../utils/old/collectionHoursFormat";
import {
  fetchCreateRecurrentCollection,
  fetchDeleteRecurrentCollection,
  fetchUpdateRecurrentCollection,
  fetchUserRecurrentCollections,
  setPageRecurrentCollections,
  setShowSnack,
} from "../../../../../redux/old/slices/individualsSlicer";
import { useTranslation } from "react-i18next";

const Card = styled(MuiCard)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

export default function RecurrentCollectionCard() {
  const { id } = useParams();
  const { t } = useTranslation();

  const [collectionModal, setCollectionModal] = useState(false);
  const [editCollectionModal, setEditCollectionModal] = useState(false);
  const [selectedRecurrentCollection, setSelectedRecurrentCollection] =
    useState({});
  const [collectionHoursFinalTime, setCollectionHoursFinalTime] = useState([]);

  //ReduxTookit
  const loading = useSelector((state) => state.person.showLoading);
  const recurrentCollections = useSelector(
    (state) => state.person.recurrentCollections
  );
  const totalRowsRecurrentCollections = useSelector(
    (state) => state.person.totalRowsRecurrentCollections
  );
  const pageCollections = useSelector(
    (state) => state.person.pageRecurrentCollections
  );
  const totalAddressesList = useSelector(
    (state) => state.person.totalAddressesList
  );
  const snackTxt = useSelector((state) => state.person.snackTxt);
  const showSnack = useSelector((state) => state.person.showSnack);

  const dispatch = useDispatch();

  const recurrentCollectionDaysFormat = (daysArray) => {
    let newArrayTranslated = [];

    daysArray.map((day) =>
      newArrayTranslated.push(t(`${recurrentDaysForCollect[day]}`))
    );

    let StringDays = newArrayTranslated.toString();

    return StringDays;
  };

  useEffect(() => {
    getUser();
    getCities();
  }, [collectionModal]);

  const getUser = async (cPage = 0) => {
    dispatch(fetchUserRecurrentCollections({ cPage: cPage, id: id })); //ReduxToolkit
    dispatch(setPageRecurrentCollections(cPage)); //ReduxToolkit reducer
  };

  const handleChangePageCollections = async (event, newPage) => {
    dispatch(fetchUserRecurrentCollections({ cPage: newPage, id: id }));
    dispatch(setPageRecurrentCollections(newPage));
  };

  const handleChangeRowsPerCollectionPage = async () => {
    dispatch(fetchUserRecurrentCollections({ cPage: 0, id: id }));
  };

  const getCities = async () => {
    dispatch(fetchCities()); //ReduxToolkit
  };

  const newFinalTimeArray = (initialTime) => {
    let indexOfInitialTime = -1;

    collectionHours.map((hour, index) =>
      hour.value === initialTime
        ? (indexOfInitialTime = index)
        : indexOfInitialTime
    );
    setCollectionHoursFinalTime(collectionHours.slice(indexOfInitialTime + 1));
  };

  //Manejando CREAR nuevas recolecciones:

  const initialValuesRecurrentCollection = {
    address_id: "",
    initialTime: "",
    finalTime: "",
    days: [],
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    observation: "",
  };

  function validationSchema() {
    return {
      address_id: Yup.number().required(
        t("newRecurrentCollectionModal.validations.Dirección requerida")
      ),
      initialTime: Yup.string().required(
        t("newRecurrentCollectionModal.validations.Hora inicial requerida")
      ),
      finalTime: Yup.string().required(
        t("newRecurrentCollectionModal.validations.Hora final requerida")
      ),
    };
  }

  const formikCollection = useFormik({
    initialValues: initialValuesRecurrentCollection,
    enableReinitialize: true,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) => {
      createCollection(data);
    },
  });

  const createCollection = async (data) => {
    data.user_id = id;
    data.admin = true;
    daysForCollect.map((day) => {
      if (data[day.value] === true) data.days.push(day.value);
    });

    let fetchData = {
      address_id: data.address_id,
      observation: data.observation,
      days: data.days,
      initialTime: data.initialTime,
      finalTime: data.finalTime,
      user_id: data.user_id,
    };

    if (fetchData.days.length !== 0) {
      dispatch(fetchCreateRecurrentCollection(fetchData));
      setCollectionModal(false);
      dispatch(fetchUserRecurrentCollections({ cPage: 0, id: id }));
    } else {
      alert(
        t(
          "newRecurrentCollectionModal.Mutante, debes seleccionar al menos 1 día"
        )
      );
    }
  };

  //Manejando formulario para EDITAR las recolecciones recurrentes existentes:

  // const editRecurrentCollection = (recurrentCollection) => {
  //   setSelectedRecurrentCollection(recurrentCollection);
  //   setEditCollectionModal(true);
  // };

  //Manejando ELIMINAR la recolección recurrente seleccionada:

  const deleteRecurrentCollection = (recurrentCollection) => {
    dispatch(fetchDeleteRecurrentCollection(recurrentCollection)); //ReduxToolkit Destroy
    dispatch(setShowSnack(true));
    dispatch(fetchUserRecurrentCollections({ cPage: 0, id: id })); //Reduxtoolkit Get
  };

  const initialValuesEditRecurrentCollection = {
    address_id: selectedRecurrentCollection.address_id,
    initialTime: selectedRecurrentCollection.initialTime,
    finalTime: selectedRecurrentCollection.finalTime,
    days: selectedRecurrentCollection.days,
    // monday: selectedRecurrentCollection.days.includes("monday"),
    // tuesday: selectedRecurrentCollection.days.includes("tuesday"),
    // wednesday: selectedRecurrentCollection.days.includes("wednesday"),
    // thursday: selectedRecurrentCollection.days.includes("thursday"),
    // friday: selectedRecurrentCollection.days.includes("friday"),
    // saturday: selectedRecurrentCollection.days.includes("saturday"),
    observation: "",
  };

  function validationSchema() {
    return {
      address_id: Yup.number().required(
        t("newRecurrentCollectionModal.validations.Dirección requerida")
      ),
      initialTime: Yup.string().required(
        t("newRecurrentCollectionModal.validations.Hora inicial requerida")
      ),
      finalTime: Yup.string().required(
        t("newRecurrentCollectionModal.validations.Hora final requerida")
      ),
    };
  }

  const formikEditCollection = useFormik({
    initialValues: initialValuesEditRecurrentCollection,
    enableReinitialize: true,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) => {
      editCollection(data);
    },
  });

  const editCollection = async (data) => {
    let selectedRecurrentCollectionId = selectedRecurrentCollection.id;
    data.user_id = id;
    data.admin = true;
    daysForCollect.map((day) => {
      if (data[day.value] === true) data.days.push(day.value);
    });

    let fetchData = {
      address_id: data.address_id,
      observation: data.observation,
      days: data.days,
      initialTime: data.initialTime,
      finalTime: data.finalTime,
      user_id: data.user_id,
    };

    if (fetchData.days.length !== 0) {
      dispatch(
        fetchUpdateRecurrentCollection({
          data: fetchData,
          selectedRecurrentCollectionId: selectedRecurrentCollectionId,
        })
      );
      setEditCollectionModal(false);
      dispatch(fetchUserRecurrentCollections({ cPage: 0, id: id }));
    } else {
      alert(
        t(
          "newRecurrentCollectionModal.Mutante, debes seleccionar al menos 1 día"
        )
      );
    }
  };
  return (
    <React.Fragment>
      <Card mb={6}>
        <CardContent>
          <Grid justifyContent="space-between" container spacing={10}>
            <Grid item>
              <Typography variant="h6" gutterBottom>
                {t("Programación de Recolecciones Recurrentes")}
              </Typography>
            </Grid>
          </Grid>

          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("tables.tableHeadCells.Dirección")}</TableCell>
                  <TableCell>{t("tables.tableHeadCells.Días")}</TableCell>
                  <TableCell>{t("tables.tableHeadCells.Horario")}</TableCell>
                  <TableCell>
                    {t("tables.tableHeadCells.Observaciones")}
                  </TableCell>
                  <TableCell>{t("tables.tableHeadCells.Acciones")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recurrentCollections.map((row, index) => {
                  return (
                    <TableRow hover key={index}>
                      <TableCell>{row.addressDescription}</TableCell>
                      <TableCell>
                        {recurrentCollectionDaysFormat(row.days)}
                      </TableCell>
                      <TableCell>
                        {`${formatCollectionHour(
                          row.initialTime,
                          row.finalTime
                        )}`}
                      </TableCell>

                      <TableCell>
                        {row.observation === "" && "-"}
                        {row.observation !== "" && row.observation}
                      </TableCell>

                      <TableCell>
                        <div style={{ display: "flex" }}>
                          {/* <IconButton
                            aria-label="edit"
                            size="large"
                            onClick={(e) => editRecurrentCollection(row)}
                          >
                            <EditIcon />
                          </IconButton> */}

                          <IconButton
                            aria-label="delete"
                            size="large"
                            onClick={(e) => deleteRecurrentCollection(row)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[20]}
              component="div"
              count={totalRowsRecurrentCollections}
              rowsPerPage={20}
              page={pageCollections}
              onPageChange={handleChangePageCollections}
              onRowsPerPageChange={handleChangeRowsPerCollectionPage}
            />
          </TableWrapper>
          {recurrentCollections.length === 0 && (
            <Box display="flex" justifyContent="center">
              <Typography style={{ fontSize: "16px", color: "gray" }}>
                {t(
                  "No se han creado recolecciones recurrentes para este usuario"
                )}
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>

      {/* --------------------------------Modal Crear Recolección---------------------------------------------- */}

      <Dialog
        open={collectionModal}
        onClose={() => setCollectionModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={formikCollection.handleSubmit}>
          <DialogTitle id="form-dialog-title">
            Programar recolección recurrente
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Se le asignará a la persona actual
            </DialogContentText>
            <br />
            <Grid container spacing={6}>
              <Grid item md={12}>
                <Typography style={{ fontSize: "11px" }} color="grey">
                  Dirección
                </Typography>
                <Select
                  name="address_id"
                  label="Dirección"
                  fullWidth
                  variant="outlined"
                  value={formikCollection.values.address_id}
                  onChange={formikCollection.handleChange}
                >
                  {totalAddressesList.map((element, index) => (
                    <MenuItem
                      key={element.id}
                      value={element.id}
                    >{`${element.description} ${element.city.name}`}</MenuItem>
                  ))}
                </Select>
                {formikCollection.errors.address_id && (
                  <Alert mt={2} mb={3} severity="error">
                    {formikCollection.errors.address_id}
                  </Alert>
                )}
              </Grid>
              <Grid item md={6}>
                <Typography style={{ fontSize: "11px" }} color="grey">
                  Hora inicio
                </Typography>
                {formikCollection.values.initialTime === "" && (
                  <Select
                    name="initialTime"
                    label="Hora inicio"
                    fullWidth
                    variant="outlined"
                    value={formikCollection.values.initialTime}
                    onChange={(e) => {
                      newFinalTimeArray(e.target.value);
                      formikCollection.setFieldValue(
                        "initialTime",
                        e.target.value
                      );
                    }}
                  >
                    {collectionHoursIntialTime.map((element, index) => (
                      <MenuItem key={index} value={element.value}>
                        {element.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                {formikCollection.values.initialTime !== "" && (
                  <Typography>
                    {formatHour(formikCollection.values.initialTime)}
                  </Typography>
                )}
                {formikCollection.errors.initialTime && (
                  <Alert mt={2} mb={3} severity="error">
                    {formikCollection.errors.initialTime}
                  </Alert>
                )}
              </Grid>
              <Grid item md={6}>
                <Typography style={{ fontSize: "11px" }} color="grey">
                  Hora fin
                </Typography>
                <Select
                  name="finalTime"
                  label="Hora fin"
                  fullWidth
                  variant="outlined"
                  value={formikCollection.values.finalTime}
                  onChange={formikCollection.handleChange}
                >
                  {collectionHoursFinalTime.map((element, index) => (
                    <MenuItem key={index} value={element.value}>
                      {element.label}
                    </MenuItem>
                  ))}
                </Select>
                {formikCollection.errors.finalTime && (
                  <Alert mt={2} mb={3} severity="error">
                    {formikCollection.errors.finalTime}
                  </Alert>
                )}
              </Grid>
              <Grid item md={12}>
                <Typography style={{ fontSize: "11px" }} color="grey">
                  Días
                </Typography>
                <FormControl component="fieldset" variant="standard">
                  <FormGroup>
                    {daysForCollect.map((day, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            name={day.value}
                            checked={formikCollection.values.name}
                            onChange={formikCollection.handleChange}
                          />
                        }
                        label={day.label}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>

              <Grid item md={12}>
                <TextField
                  name="observation"
                  label="Observaciones"
                  id="observation"
                  multiline={true}
                  maxRows={6}
                  variant="outlined"
                  my={2}
                  style={{ width: "42.5em" }}
                  value={formikCollection.values.observation}
                  onChange={formikCollection.handleChange}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCollectionModal(false)} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              Programar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* --------------------------------Modal Editar Recolección---------------------------------------------- */}

      <Dialog
        open={editCollectionModal}
        onClose={() => setEditCollectionModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={formikEditCollection.handleSubmit}>
          <DialogTitle id="form-dialog-title">
            Editar programación de recolección recurrente
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Se actualizará la recolección recurrente seleccionada, con los
              cambios que realices
            </DialogContentText>
            <br />
            <Grid container spacing={6}>
              <Grid item md={12}>
                <Typography>Dirección</Typography>
                <Select
                  name="address_id"
                  label="Dirección"
                  fullWidth
                  variant="outlined"
                  value={formikEditCollection.values.address_id}
                  onChange={formikEditCollection.handleChange}
                >
                  {totalAddressesList.map((element, index) => (
                    <MenuItem
                      key={element.id}
                      value={element.id}
                    >{`${element.description} ${element.city.name}`}</MenuItem>
                  ))}
                </Select>
                {formikEditCollection.errors.address_id && (
                  <Alert mt={2} mb={3} severity="error">
                    {formikEditCollection.errors.address_id}
                  </Alert>
                )}
              </Grid>
              <Grid item md={6}>
                <Typography>Hora inicio</Typography>
                <Select
                  name="initialTime"
                  label="Hora inicio"
                  fullWidth
                  variant="outlined"
                  value={formikEditCollection.values.initialTime}
                  onChange={formikEditCollection.handleChange}
                >
                  {collectionHours.map((element, index) => (
                    <MenuItem key={index} value={element.value}>
                      {element.label}
                    </MenuItem>
                  ))}
                </Select>
                {formikEditCollection.errors.initialTime && (
                  <Alert mt={2} mb={3} severity="error">
                    {formikEditCollection.errors.initialTime}
                  </Alert>
                )}
              </Grid>
              <Grid item md={6}>
                <Typography>Hora fin</Typography>
                <Select
                  name="finalTime"
                  label="Hora fin"
                  fullWidth
                  variant="outlined"
                  value={formikEditCollection.values.finalTime}
                  onChange={formikEditCollection.handleChange}
                >
                  {collectionHours.map((element, index) => (
                    <MenuItem key={index} value={element.value}>
                      {element.label}
                    </MenuItem>
                  ))}
                </Select>
                {formikEditCollection.errors.finalTime && (
                  <Alert mt={2} mb={3} severity="error">
                    {formikEditCollection.errors.finalTime}
                  </Alert>
                )}
              </Grid>
              <Grid item md={12}>
                <Typography>Días</Typography>
                <FormControl component="fieldset" variant="standard">
                  <FormGroup>
                    {daysForCollect.map((day, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            name={day.value}
                            checked={formikEditCollection.values.name}
                            onChange={formikEditCollection.handleChange}
                          />
                        }
                        label={day.label}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>

              <Grid item md={12}>
                <TextField
                  name="observation"
                  label="Observaciones"
                  id="observation"
                  multiline={true}
                  maxRows={6}
                  variant="outlined"
                  my={2}
                  style={{ width: "42.5em" }}
                  value={formikEditCollection.values.observation}
                  onChange={formikEditCollection.handleChange}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setEditCollectionModal(false)}
              color="primary"
            >
              Cancelar
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              Programar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/*--------------- Snackbar de Confirmación --------------*/}

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showSnack}
        autoHideDuration={6000}
        onClose={() => {
          dispatch(setShowSnack(false));
        }}
        message={snackTxt}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                dispatch(setShowSnack(false));
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
