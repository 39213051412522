export const customMuiOutlinedInput = {
  styleOverrides: {
    root: {
      color: "#969696",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
    },
  },
};
