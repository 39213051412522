import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import CustomButton from "../custom/buttons/default/CustomButton";
import CustomInput from "../custom/forms/default/CustomInput";
import {
  setMessage,
  setOpenSnackbar,
  setType,
} from "../../redux/slices/globalSnackbar/globalSnackbarSlice";
import { setTransactionAttachedFilesModalState } from "../../redux/slices/transactions/transactionAttachedFilesModalStatesSlice";
import { setFormAttachNewTransactionFileState } from "../../redux/slices/transactions/transactionAttachNewFileModalSlice";
import TransactionSelectedAttachedFile from "./TransactionSelectedAttachedFile";
import { fetchTransactionAttachNewFile } from "../../redux/thunks/transactions/transactionAttachNewFileModalThunk";
import { fetchTransactionDetails } from "../../redux/thunks/transactions/transactionsDetailsThunk";

export default function TransactionAttachNewFileForm() {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { transactionDetails } = useAppSelector(
    (state) => state.transactionDetails
  );

  const { fileType } = useAppSelector(
    (state) => state.transactionAttachNewFileModal.form
  );

  const { status } = useAppSelector(
    (state) => state.transactionAttachNewFileModal
  );

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const resetForm = useCallback(async () => {
    dispatch(
      setTransactionAttachedFilesModalState({
        id: "attachFile",
        value: false,
      })
    );
  }, [dispatch]);

  const handleClickCloseModal = () => {
    resetForm();
  };

  const initialFormikValues = {
    Imagen1: null, // Inicialmente, no hay archivo seleccionado
    ownerType: "transaction",
    ownerId: transactionDetails._id,
    type: fileType.type,
    description: "",
  };

  const handleSubmit = async () => {
    if (selectedFile) {
      await dispatch(
        fetchTransactionAttachNewFile({
          data: selectedFile,
          ownerId: transactionDetails._id,
          type: fileType.type,
        })
      );
      await dispatch(fetchTransactionDetails({ id: transactionDetails._id }));
      handleClickCloseModal();
    }
  };

  const formikTransactionAttachNewFile = useFormik({
    initialValues: initialFormikValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validateOnChange: false,
  });

  // Actualiza selectedFile cuando el usuario selecciona un archivo
  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      setSelectedFile(selectedFile);
      dispatch(
        setFormAttachNewTransactionFileState({
          id: "selectedDocument",
          value: selectedFile.name,
        })
      );
    }
  };

  useEffect(() => {
    if (status === 201) {
      dispatch(setOpenSnackbar(true));
      dispatch(setMessage(t("snackbars.Cambios guardados exitosamente")));
      dispatch(setType("sucessSnackbar"));
    }
    if (status === 403) {
      dispatch(setOpenSnackbar(true));
      dispatch(
        setMessage(t("snackbars.No tienes permiso para adjuntar archivos"))
      );
      dispatch(setType("failedSnackbar"));
    }
  }, [status]);

  return (
    <form noValidate onSubmit={formikTransactionAttachNewFile.handleSubmit}>
      <CustomInput
        id="type"
        label={t("Tipo de documento")}
        placeholder={t(
          "transactionAttachedFiles.customAttachNewTransactionFileModal.Tipo de documento"
        )}
        value={t(`transactionAttachedFiles.filesTypes.${fileType.label}`)}
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        inputHeight={PP(40)}
        inputBorderRadius={PP(8)}
        backgroundColor="#F6F6F6"
        extraStyle={{ marginTop: PP(24) }}
        disabled
      />

      <TransactionSelectedAttachedFile
        selectedFile={selectedFile}
        handleSelectFile={(e) => handleSelectFile(e)}
      />

      <CustomButton
        width={432}
        height={44}
        noSpacing
        style={{ padding: "0px", marginTop: PP(24) }}
        type="submit"
        label={t("Guardar")}
        formik={formikTransactionAttachNewFile}
        disabled={selectedFile ? false : true} // Habilita el botón si hay un archivo seleccionado
      />
      <CustomButton
        noSpacing
        width={432}
        height={44}
        cancelButton
        onClick={handleClickCloseModal}
        formik={formikTransactionAttachNewFile}
      />
    </form>
  );
}
