import { useEffect, useState } from "react";

import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import { useAppDispatch } from "../../redux/store";

import CustomSelect from "../custom/forms/default/CustomSelect";
import { Box } from "@mui/material";

import { ReactComponent as BlueCheckIcon } from "../../assets/icons/modals/blueCheck.svg";

import DateFilter from "../custom/forms/sales/DateFilter";
import { fetchTransactionsList } from "../../redux/thunks/transactions/transactionsListThunk";
import { useTranslation } from "react-i18next";

export default function TransactionsFilters() {
  const { PP } = usePixelPerfect();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [transactionState, setTransactionState] = useState<string | number>("");

  const [date, setDate] = useState<string | Date>("");

  useEffect(() => {
    let filter = "";

    if (date !== "") {
      filter += `&extra.loadDate=${date}`;
    }

    if (transactionState !== "") {
      filter += `&state[$in][]=${transactionState}`;
    }

    if (filter !== "") {
      dispatch(fetchTransactionsList({ filter }));
    }
  }, [transactionState, date]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      height={PP(40)}
    >
      <Box>
        <CustomSelect
          id="transactionState"
          value={transactionState}
          onChange={(event) => {
            setTransactionState(event.target.value);
          }}
          items={[
            { value: "open", label: t("Abierta") },
            { value: "close", label: t("Cerrada") },
          ]}
          extraStyle={{
            height: PP(40),
          }}
          placeholder={t("Selecciona un estado")}
          startSelectedIcon={<BlueCheckIcon />}
        />
      </Box>
      <Box height={PP(42)}>
        <DateFilter date={date} setDate={setDate} />
      </Box>
    </Box>
  );
}
