import { useTranslation } from "react-i18next";
import { Box, Divider } from "@mui/material";

import usePixelPerfect from "../../../hooks/old/usePixelPerfect";
import CustomText from "../../custom/texts/CustomText";
import CustomCompleteTableNoPagination from "../../custom/tables/default/CustomCompleteTableNoPagination";
import { transformationsMaterialsTableHeadCells } from "../../../utils/constants/inventory/transformationsMaterialsTableHeadCells";
import CustomTransformationsDetailsMaterialsTableRow from "../../custom/tables/inventary/transfer/transformations/CustomTransformationsDetailsMaterialsTableRow";

interface TransformationDetailsInputMaterialProps {
    transformMaterials: {
        id: number;
        transformId: number;
        materialId: number;
        quantityMaterial: number;
        type: string;
        createdAt: string;
        updatedAt: string;
        material: {
            id: number;
            name: string;
            measure: string;
            category: string;
            description: string;
            nameTranslations: {
                translations: {
                    es: string;
                    en: string;
                    it: string;
                };
            };
        };
    }[];
}

export default function TransformationDetailsInputMaterial(props: TransformationDetailsInputMaterialProps) {
    const { PP } = usePixelPerfect();
    const { t } = useTranslation();

    return (
        <Box marginTop={PP(24)}>
            <Box
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <CustomText
                    noSpacing
                    premediumWeight
                    color="#1C1C1C"
                    style={{ fontSize: PP(20) }}
                >
                    {t("Material transformado")}
                </CustomText>
            </Box>

            <Divider style={{ marginTop: PP(16), marginBottom: PP(16) }} />
            <Box display="flex">
                <Box flex={1}>
                    <CustomCompleteTableNoPagination
                        status={""}
                        tableHeadCells={transformationsMaterialsTableHeadCells} //Encabezados de la tabla
                        children={
                            <CustomTransformationsDetailsMaterialsTableRow
                                element={props.transformMaterials[0]}
                                key={0}
                                myKey={0}
                            />
                        }
                    />
                </Box>
            </Box>
        </Box >
    );
}
