import { forwardRef, Fragment, useCallback } from "react";

import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import usePixelPerfect from "../../../../../hooks/old/usePixelPerfect";
import { reset as resetMaterialsList } from "../../../../../redux/slices/collections/materialsListSlice";
import MaterialsModalLayout from "../../../../../layouts/MaterialsModalLayout";
import CustomText from "../../../texts/CustomText";
import { reset as resetAddMaterial } from "../../../../../redux/slices/inventory/transformation/addMaterialTransformationSlice";
import { setTransformationMaterialsModalStates } from "../../../../../redux/slices/inventory/transformation/transformationMaterialsModalsStatesSlice";
import AddMaterialTransformationForm from "../../../../inventory/transformations/AddMaterialTransformationForm";


export default forwardRef(function CustomAddMaterialModal() {
    const { status } = useAppSelector((state) => state.newCollectionUser);
    const dispatch = useAppDispatch();
    const { PP } = usePixelPerfect();
    const { t } = useTranslation();

    const resetForm = useCallback(async () => {
        dispatch(resetAddMaterial());
        dispatch(resetMaterialsList());
        dispatch(
            setTransformationMaterialsModalStates({ id: "addMaterial", value: false })
        );
    }, [dispatch]);

    const handleClickCloseModal = (event: React.MouseEvent<SVGSVGElement>) => {
        resetForm();
    };

    const handleBackClick = useCallback(async () => {
        dispatch(resetAddMaterial());
        dispatch(resetMaterialsList());
        dispatch(
            setTransformationMaterialsModalStates({ id: "addMaterial", value: false })
        );
        dispatch(
            setTransformationMaterialsModalStates({ id: "selectCategory", value: true })
        );
    }, [dispatch]);

    return (
        <MaterialsModalLayout handleClose={handleClickCloseModal}>
            {status === -1 && (
                <Fragment>
                    <CustomText
                        showBackArrowButton
                        noSpacing
                        bigSize
                        premediumWeight
                        color="#1C1C1C"
                        style={{ marginBottom: PP(24) }}
                        onClick={handleBackClick}
                    >
                        {t("Agregar material")}
                    </CustomText>
                    <Divider sx={{ margin: "0px" }} />
                    <AddMaterialTransformationForm />
                </Fragment>
            )}
        </MaterialsModalLayout>
    );
});