import { createTheme } from "@mui/material";

import { esES } from "@mui/material/locale";
import PPD from "../../utils/modals/ppd";

const theme = createTheme(
  {
    components: {
      // Name of the component
      MuiInputBase: {
        styleOverrides: {
          // Name of the slot

          root: {
            backgroundColor: "#FFFFFF",
            border: `${PPD(1)} solid  #E8E8E8`,
            fontFamily: "'Inter', sans-serif",
            fontSize: PPD(16),
            color: "#4D4D4D",
            ".MuiInputBase-input": {
              padding: `${PPD(8)} ${PPD(12)}`,
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          // Name of the slot
          root: {
            width: `${PPD(347)}`,
            height: `${PPD(290)}`,
            borderRadius: `${PPD(8)}`,
            ".MuiCalendarOrClockPicker-root > div": {
              height: `${PPD(290)}`,
              width: `${PPD(347)}`,
              backgroundColor: "#ffffff",
              borderRadius: `${PPD(8)}`,
            },
          },
        },
      },
      MuiCalendarPicker: {
        styleOverrides: {
          // Name of the slot
          root: {
            width: `${PPD(320)}`,
            height: `${PPD(280)}`,
            // Some CSS
            ".MuiPickersCalendarHeader-root": {
              padding: `${PPD(10)}`,
              ".MuiPickersCalendarHeader-label": {
                fontFamily: "'Inter', sans-serif",
                fontWeight: "600",
                fontSize: `${PPD(16)}`,
                textTransform: "capitalize",
                color: "#4D4D4D",
              },
            },
            ".MuiPickersFadeTransitionGroup-root": {
              ".MuiYearPicker-root": {
                ".PrivatePickersYear-yearButton": {
                  fontFamily: "'Inter', sans-serif",
                  color: "#4D4D4D",
                  fontSize: `${PPD(12)}`,
                  "&:hover": {
                    backgroundColor: "#557CE6",
                    color: "#171717",
                  },
                },
                ".Mui-selected": {
                  backgroundColor: "#557CE6",
                  color: "#171717",
                  fontWeight: "500",
                },
                ".Mui-disabled": {
                  color: "#666666",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#666666",
                    cursor: "default",
                  },
                },
              },

              ".MuiDayPicker-header": {
                height: `${PPD(40)}`,
                margin: 0,
                padding: 0,
                ".MuiTypography-root": {
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: "600",
                  fontSize: `${PPD(12)}`,
                  width: `${PPD(40)}`,
                  height: `${PPD(20)}`,
                  color: "#969696",
                  textTransform: "upperCase",
                },
              }, //here
              ".PrivatePickersSlideTransition-root": {
                ".MuiDayPicker-monthContainer": {
                  ".MuiDayPicker-weekContainer": {
                    ".MuiPickersDay-root": {
                      width: `${PPD(40)}`,
                      height: `${PPD(40)}`,
                    },
                    ".MuiButtonBase-root": {
                      borderRadius: `${PPD(8)}`,
                      fontFamily: "'Inter', sans-serif",
                      fontSize: `${PPD(16)}`,
                      color: "#4D4D4D",
                      backgroundColor: "transparent",
                      width: `${PPD(40)}`,
                      height: `${PPD(40)}`,
                      marginTop: 0,
                      marginBottom: 0,
                      "&:hover": {
                        backgroundColor: "#557CE6",
                        color: "#ffffff",
                      },
                    },
                    ".MuiPickersDay-today": {
                      border: "none",
                    },
                    ".Mui-disabled": {
                      color: "#CBCBCB",
                    },
                    ".Mui-selected": {
                      backgroundColor: "#557CE6",
                      color: "#ffffff",
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  esES
);

export default theme;
