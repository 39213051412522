import { SxProps, Typography } from "@mui/material";

interface LabelProps {
  children: React.ReactNode | string;
  onClick?: () => void;
  className?: string;
  sx?: SxProps;
}

const CustomLabel: React.FC<LabelProps> = ({
  sx,
  children,
  className,
  onClick,
}) => {
  return (
    <>
      <Typography
        sx={sx}
        className={className}
        onClick={onClick}
        style={onClick && { cursor: "pointer" }}
      >
        {children}
      </Typography>
    </>
  );
};

export default CustomLabel;
