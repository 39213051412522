import { createSlice } from "@reduxjs/toolkit";
import { fetchDeleteTransferAttachFile } from "../../../thunks/inventory/transfer/deleteTransferAttachFileThunk";

const initialState = {
  fileSelectedToDelete: {
    id: -1,
    name: "",
    ownerType: "",
    ownerId: -1,
    type: "",
    description: "",
    updatedAt: "",
    createdAt: "",
    url: "",
  },
  loading: false,
  status: -1,
  statusText: "",
};

export const deleteTransferAttachFileSlice = createSlice({
  name: "deleteTransferAttachFileReducer",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = -1;
      state.statusText = "";
    },
    setFileSelectedToDelete: (state, action) => {
      state.fileSelectedToDelete = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Eliminar archivo adjunto seleccionado, de la transferencia de inventario
    builder.addCase(fetchDeleteTransferAttachFile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchDeleteTransferAttachFile.fulfilled,
      (state, action) => {
        state.status = action.payload.status;
        state.statusText = action.payload.statusText;
        state.loading = false;
      }
    );
    builder.addCase(fetchDeleteTransferAttachFile.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { resetStatus, setFileSelectedToDelete, reset } =
  deleteTransferAttachFileSlice.actions;

export default deleteTransferAttachFileSlice.reducer;
