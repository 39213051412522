import { createSlice } from "@reduxjs/toolkit";

import { fetchCreateTransaction } from "../../thunks/transactions/createTransactionThunk";
import { createClientSliceType } from "../../../interfaces/redux/createClientSliceInterface";
import { fetchCreateClient } from "../../thunks/clients/createClientThunk";

const initialState: createClientSliceType = {
  loading: false,
  status: -1,
  form: {
    name: "",
    legalName: "",
    documentNumber: "",
    address: "",
    contactName: "",
    contactEmail: "",
    contactPhone: 0,
  },
};

export const createClientSlice = createSlice({
  name: "createClientSliceReducer",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = -1;
    },
    setFormCreateClient: (state, action) => {
      const { id, value } = action.payload;
      state.form = {
        ...state.form,
        [id]: value,
      };
    },

    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Crear nuevo cliente
    builder.addCase(fetchCreateClient.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCreateClient.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.loading = false;
    });
    builder.addCase(fetchCreateClient.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { resetStatus, reset, setFormCreateClient } =
  createClientSlice.actions;

export default createClientSlice.reducer;
