export const typesCollectionFiles: { type: string; label: string }[] = [
  {
    type: "acu_certificate",
    label: "Certificado de recolección y disposición de ACU",
  },
  { type: "charge_account", label: "Cuenta de cobro" },
  { type: "evidence", label: "Evidencia fotográfica" },
  { type: "remission", label: "Remisión de aprovechables" },
  { type: "collection_voucher", label: "Comprobante de recolección" },
  { type: "sample_taking", label: "Toma de muestra" },
];

export const typesCollectionFilesObject: { [key: string]: string } = {
  acu_certificate: "Disposición de ACU",
  charge_account: "Cuenta de cobro",
  evidence: "Evidencia fotográfica",
  remission: "Remisión de aprovechables",
  sample_taking: "Toma de muestra",
};
