//BanksAccountsCard-Personas

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Alert,
  Button,
  Card as MuiCard,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";

import { BankList, BankListArray } from "../../../../../utils/old/bankList";
import {
  fetchBankAccounts,
  fetchCreateNewBankAccount,
  fetchDeleteBankAccount,
  fetchPerson,
  setPageBankAccounts,
  setShowSnack,
} from "../../../../../redux/old/slices/individualsSlicer";
import { AccountsTypes } from "../../../../../utils/old/constants";
import { useTranslation } from "react-i18next";

const Spacer = styled.div(spacing);

const Card = styled(MuiCard)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

export default function BanksAccountsCard() {
  const { id } = useParams();
  const { t } = useTranslation();

  const [newBankAccountModal, setNewBankAccountModal] = useState(false);
  const [byeBankAccount, setByeBankAccount] = useState(false);

  //ReduxToolkit
  const loading = useSelector((state) => state.person.showLoading);
  const person = useSelector((state) => state.person.person);
  const bankAccounts = useSelector((state) => state.person.bankAccounts);
  const snackTxt = useSelector((state) => state.person.snackTxt);
  const showSnack = useSelector((state) => person.showSnack);
  const totalRowsBanksAccounts = useSelector(
    (state) => state.person.totalRowsBanksAccounts
  );
  const pageBankAccounts = useSelector(
    (state) => state.person.pageBankAccounts
  );
  const dispatch = useDispatch();

  useEffect(() => {
    getPerson();
  }, [newBankAccountModal, byeBankAccount]);

  const getPerson = async (cPage = 0) => {
    dispatch(fetchPerson(id)); //ReduxToolkit Get
    dispatch(fetchBankAccounts({ cPage: cPage, id: id })); //ReduxTookit Get//Cuentas Bancarias del Persona
    dispatch(setPageBankAccounts(cPage)); //ReduxToolkit reducer
  };

  const handleChangePageBankAccounts = async (event, newPage) => {
    dispatch(fetchBankAccounts({ cPage: newPage, id: id }));
    dispatch(setPageBankAccounts(newPage));
  };

  const handleChangeRowsPerBankAccounts = async () => {
    dispatch(fetchBankAccounts({ cPage: 0, id: id }));
  };

  //Manejando la Creación de Nuevas Cuentas Bancarias del Persona

  //------------------------Create-------------------------------------------------------------------------
  const initialValuesNewBankAccount = {
    bank: 0,
    number: 1111111111,
    accountType: "SELECCIONA EL TIPO DE CUENTA",
    admin: true,
  };

  //Manejando creación de nuevas cuentas bancarias:

  function validationSchema() {
    return {
      bank: Yup.number()
        .required(
          t("createNewBankAccountUserModal.validations.Banco requerido")
        )
        .positive(
          t("createNewBankAccountUserModal.validations.Banco requerido")
        ),
      number: Yup.number()
        .required(
          t(
            "createNewBankAccountUserModal.validations.Número de cuenta bancaria requerida"
          )
        )
        .typeError(
          t(
            "createNewBankAccountUserModal.validations.Este campo debe ser numérico"
          )
        ),
      accountType: Yup.string()
        .required(
          t(
            "createNewBankAccountUserModal.validations.Tipo de cuenta bancaria requerida"
          )
        )
        .oneOf(["checking", "savings"]),
    };
  }

  const formikNewBankAccount = useFormik({
    initialValues: initialValuesNewBankAccount,
    enableReinitialize: true,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) => {
      createNewBankAccount(data);
    },
  });

  const createNewBankAccount = async (data) => {
    data.user_id = id;
    dispatch(fetchCreateNewBankAccount(data)); //ReduxTookit Post//Creación de Nueva cuenta bancaria
    setNewBankAccountModal(false);
    dispatch(fetchBankAccounts({ cPage: 0, id: id })); //ReduxTookit Get//Lista de Cuentas bancarias actuales
  };

  //----------------------------Destroy--------------------------------------------------------------------

  const deleteBankAccount = async (bankAccount, index) => {
    setByeBankAccount(true);
    if (bankAccount._id) {
      dispatch(fetchDeleteBankAccount(bankAccount)); //ReduxTookit Destroy
      dispatch(fetchBankAccounts({ cPage: 0, id: id })); //ReduxTookit Get//Lista de Cuentas bancarias actuales del usuario
    } else {
    }
  };

  return (
    <React.Fragment>
      <Card mb={6}>
        <CardContent>
          <Grid justifyContent="space-between" container spacing={10}>
            <Grid item>
              <Typography variant="h6" gutterBottom>
                {t("Cuentas")}
              </Typography>
              <Typography variant="h6" gutterBottom>
                {t("Bancarias")}
              </Typography>
            </Grid>
            <Grid item>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => setNewBankAccountModal(true)}
                >
                  <AddIcon />
                  {t("Nueva")}
                </Button>
              </div>
            </Grid>
          </Grid>
          <Spacer mb={4} />
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("tables.tableHeadCells.Banco")}</TableCell>
                  <TableCell>{t("tables.tableHeadCells.Cuenta")}</TableCell>
                  <TableCell>{t("tables.tableHeadCells.Tipo")}</TableCell>
                  <TableCell>{t("tables.tableHeadCells.Acción")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bankAccounts.map((row, index) => {
                  return (
                    <TableRow hover key={index}>
                      <TableCell>{BankList[row.bank]}</TableCell>
                      <TableCell>{row.number}</TableCell>

                      {row.accountType === "checking" && (
                        <TableCell>
                          {t("createNewBankAccountUserModal.CUENTA CORRIENTE")}
                        </TableCell>
                      )}

                      {row.accountType === "savings" && (
                        <TableCell>
                          {t("createNewBankAccountUserModal.CUENTA DE AHORROS")}
                        </TableCell>
                      )}

                      <TableCell align="left">
                        <IconButton
                          aria-label="delete"
                          size="large"
                          onClick={(e) => deleteBankAccount(row, index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[20]}
              component="div"
              count={totalRowsBanksAccounts}
              rowsPerPage={20}
              page={pageBankAccounts}
              onPageChange={handleChangePageBankAccounts}
              onRowsPerPageChange={handleChangeRowsPerBankAccounts}
            />
          </TableWrapper>
          {bankAccounts.length === 0 && (
            <Box display="flex" justifyContent="center">
              <Typography style={{ fontSize: "16px", color: "gray" }}>
                {t("No se han creado cuentas bancarias para este usuario")}
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>

      {/* -----------------------MODAL CREACIÓN DE CUENTA BANCARIA--------------------- */}

      <Dialog
        open={newBankAccountModal}
        onClose={() => setNewBankAccountModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={formikNewBankAccount.handleSubmit}>
          <DialogTitle id="form-dialog-title">
            {t("createNewBankAccountUserModal.Creación de cuenta bancaria")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t(
                "createNewBankAccountUserModal.Se le asignará al usuario actual"
              )}
            </DialogContentText>
            <Spacer mb={4} />
            <Grid item md={12} my={2}>
              <Select
                name="bank"
                label="Banco"
                fullWidth
                variant="outlined"
                my={10}
                value={formikNewBankAccount.values.bank}
                onChange={formikNewBankAccount.handleChange}
              >
                <MenuItem value={0} key={0} disabled={true}>
                  {t("createNewBankAccountUserModal.SELECCIONA EL BANCO")}
                </MenuItem>

                {BankListArray &&
                  BankListArray.map((bank) => (
                    <MenuItem key={bank.id} value={bank.id}>
                      {bank.bank}
                    </MenuItem>
                  ))}
              </Select>
              {formikNewBankAccount.errors.bank && (
                <Alert mt={2} mb={3} severity="error">
                  {formikNewBankAccount.errors.bank}
                </Alert>
              )}
            </Grid>
            <Spacer mb={4} />
            <Grid item md={12} my={2}>
              <Select
                name="accountType"
                label="Tipo de cuenta"
                fullWidth
                variant="outlined"
                my={10}
                value={formikNewBankAccount.values.accountType}
                onChange={formikNewBankAccount.handleChange}
              >
                <MenuItem
                  key={0}
                  value={"SELECCIONA EL TIPO DE CUENTA"}
                  disabled={true}
                >
                  {t(
                    "createNewBankAccountUserModal.SELECCIONA EL TIPO DE CUENTA"
                  )}
                </MenuItem>

                {AccountsTypes &&
                  AccountsTypes.map((type) => (
                    <MenuItem key={type.id} value={type.accountType}>
                      {t(
                        `createNewBankAccountUserModal.${type.accountTypeName}`
                      )}
                    </MenuItem>
                  ))}
              </Select>
              {formikNewBankAccount.errors.accountType && (
                <Alert mt={2} mb={3} severity="error">
                  {t(
                    "createNewBankAccountUserModal.validations.Tipo de cuenta requerido"
                  )}{" "}
                </Alert>
              )}
            </Grid>
            <Spacer mb={4} />
            <TextField
              name="number"
              label={t("createNewBankAccountUserModal.Número de cuenta")}
              type="decimal"
              variant="outlined"
              value={formikNewBankAccount.values.number}
              onChange={formikNewBankAccount.handleChange}
              fullWidth
              my={2}
            />
            {formikNewBankAccount.errors.number && (
              <Alert mt={2} mb={3} severity="error">
                {formikNewBankAccount.errors.number}
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setNewBankAccountModal(false)}
              color="primary"
            >
              {t("createNewBankAccountUserModal.Cancelar")}
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {t("createNewBankAccountUserModal.Crear")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* --------------------------------------------------------------- */}

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showSnack}
        autoHideDuration={6000}
        onClose={() => {
          dispatch(setShowSnack(false));
        }}
        message={snackTxt}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                dispatch(setShowSnack(false));
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
