import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { useFormik } from "formik";

import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import { setCollectionMaterialsModalState } from "../../redux/slices/collections/collectionsMaterialsModalsStates";
import {
  reset as resetEditMaterial,
  setFormEditMaterialState,
} from "../../redux/slices/collections/editMaterialCollectionSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { fetchCollectionDetails } from "../../redux/thunks/collections/collectionDetailsThunk";
import { fetchEditMaterialCollection } from "../../redux/thunks/collections/editMaterialCollectionThunk";
import { firstLetterCapitalizeFormat } from "../../utils/formats/firstLetterCapitalizeFormat";
import {
  quantity as quantityValidation,
  price as priceValidation,
  validateYupSchema,
} from "../../utils/forms/validationSchema";
import CustomButton from "../custom/buttons/default/CustomButton";
import CustomInput from "../custom/forms/default/CustomInput";
import CustomText from "../custom/texts/CustomText";
import CustomAlert from "../custom/alerts/CustomAlert";

export default function EditMaterialCollectionForm() {
  const { id } = useParams();
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { selectedMaterialToEdit } = useAppSelector(
    (state) => state.editMaterialCollection
  );

  const { collectionDetails } = useAppSelector(
    (state) => state.collectionDetails
  );

  const [totalToPay, setTotalToPay] = useState(0);
  const [disabledPriceInput, setDisabledPriceInput] = useState(false);
  const [disabledPrice, setDisabledPrice] = useState(
    selectedMaterialToEdit.priceType === "country" ? false : true
  );

  const resetForm = useCallback(async () => {
    dispatch(resetEditMaterial());
    dispatch(
      setCollectionMaterialsModalState({ id: "editQuantity", value: false })
    );
  }, [dispatch]);

  const handleClickCloseModal = () => {
    resetForm();
  };

  const handleChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    formikEditMaterialCollection.setFieldValue("quantity", value);

    if (!disabledPrice) {
      // Evitar cambiar el precio si disabledPrice es true
      dispatch(
        setFormEditMaterialState({ id: "quantity", value: event.target.value })
      );
    }
  };

  const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabledPrice) {
      // Evitar cambiar el precio si disabledPrice es true
      const { value } = event.target;
      formikEditMaterialCollection.setFieldValue("price", value);
      dispatch(
        setFormEditMaterialState({ id: "price", value: event.target.value })
      );
    }
  };

  const initialFormikValues = {
    quantity: selectedMaterialToEdit.quantity,
    price: selectedMaterialToEdit.price,
  };

  const validationSchema = validateYupSchema({
    quantity: quantityValidation,
    price: priceValidation,
  });

  const handleSubmit = async (data: { quantity: number; price: number }) => {
    const payload = {
      quantity: Number(data.quantity),
      price: data.price,
    };

    dispatch(
      fetchEditMaterialCollection({
        data: payload,
        materialId: selectedMaterialToEdit.id,
      })
    );
    if (id !== undefined) dispatch(fetchCollectionDetails({ id: id }));
    handleClickCloseModal();
  };

  const formikEditMaterialCollection = useFormik({
    initialValues: initialFormikValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: false,
  });

  useEffect(() => {
    const newTotalToPay =
      formikEditMaterialCollection.values.price *
      Number(formikEditMaterialCollection.values.quantity);
    setTotalToPay(newTotalToPay);
  }, [
    formikEditMaterialCollection.values.price,
    formikEditMaterialCollection.values.quantity,
  ]);

  useEffect(() => {
    if (collectionDetails.payment_method === "bank_transfer") {
      setDisabledPriceInput(true);
    } else if (collectionDetails.payment_method === "cash") {
      if (selectedMaterialToEdit.priceType !== "country") {
        setDisabledPriceInput(true);
      } else {
        setDisabledPriceInput(false);
      }
    } else if (collectionDetails.payment_method === "mixed") {
      if (selectedMaterialToEdit.priceType !== "country") {
        setDisabledPriceInput(true);
      } else {
        if (selectedMaterialToEdit.materialId <= 5) {
          setDisabledPriceInput(false);
        } else {
          setDisabledPriceInput(true);
        }
      }
    }
  }, []);

  return (
    <form noValidate onSubmit={formikEditMaterialCollection.handleSubmit}>
      <CustomInput
        id="category"
        label={t("collectionInformation.customEditMaterialModal.editMaterialCollectionForm.Categoría")}
        placeholder="00"
        value={t(`collectionInformation.customSelectCategoryModal.categories.${selectedMaterialToEdit.material.category}`)}
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        inputHeight={PP(40)}
        inputBorderRadius={PP(8)}
        backgroundColor="#F6F6F6"
        extraStyle={{ marginBottom: PP(16), marginTop: PP(24) }}
        disabled
      />
      <CustomInput
        id="materialId"
        label={t("collectionInformation.customEditMaterialModal.editMaterialCollectionForm.Material")}
        placeholder="00"
        value={firstLetterCapitalizeFormat(
          selectedMaterialToEdit.material.name
        )}
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        inputHeight={PP(40)}
        inputBorderRadius={PP(8)}
        backgroundColor="#F6F6F6"
        extraStyle={{ marginBottom: PP(16) }}
        disabled
      />
      {disabledPrice && (
        <CustomAlert alertText="Este material tiene un precio unitario personalizado" />
      )}
      <CustomInput
        id="quantity"
        label={t("collectionInformation.customEditMaterialModal.editMaterialCollectionForm.Cantidad")}
        placeholder="00"
        formik={formikEditMaterialCollection}
        value={formikEditMaterialCollection.values.quantity}
        onChange={handleChangeQuantity}
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        extraStyle={{ marginBottom: PP(16) }}
        inputHeight={PP(40)}
        inputBorderRadius={PP(8)}
        endAdornmentValue={
          <CustomText noSpacing color="#969696">
            {selectedMaterialToEdit.material.measure}
          </CustomText>
        }
      />
      {/* Alerta informativa Para ACU */}
      {selectedMaterialToEdit.material.id === 1 && (
        <CustomAlert alertText={t("collectionInformation.customEditMaterialModal.editMaterialCollectionForm.Ingresar peso NETO del material")} />
      )}

      <CustomInput
        id="price"
        label={t("collectionInformation.customEditMaterialModal.editMaterialCollectionForm.Precio unitario")}
        placeholder="00"
        disabled={disabledPriceInput}
        formik={formikEditMaterialCollection}
        value={formikEditMaterialCollection.values.price}
        onChange={handleChangePrice}
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        extraStyle={{ marginBottom: PP(16) }}
        inputHeight={PP(24)}
        inputBorderRadius={PP(8)}
        startAdornmentValue={
          <CustomText noSpacing color="#969696">
            $
          </CustomText>
        }
      />
      <Box display="flex" flexDirection="row">
        <CustomText noSpacing color="#4D4D4D">
          {t("collectionInformation.customEditMaterialModal.editMaterialCollectionForm.Total a pagar")}:
        </CustomText>
        <CustomText noSpacing mediumWeight color="#4D4D4D">
          &nbsp;&nbsp;$ {totalToPay.toLocaleString("es-MX")}
        </CustomText>
      </Box>
      <CustomButton
        width={432}
        height={44}
        noSpacing
        style={{ padding: "0px", marginTop: PP(24) }}
        type="submit"
        label={t("collectionInformation.customEditMaterialModal.editMaterialCollectionForm.Guardar")}
        formik={formikEditMaterialCollection}
      />
      <CustomButton
        noSpacing
        width={432}
        height={44}
        cancelButton
        onClick={handleClickCloseModal}
        formik={formikEditMaterialCollection}
      />
    </form>
  );
}
