import { createSlice } from "@reduxjs/toolkit";

import { fetchDeleteMaterialTransfer } from "../../../thunks/inventory/transfer/deleteMaterialTransferThunk";

const initialState = {
    selectedToDeleteMaterialName: "",
    selectedToDeleteTransferMaterialId: -1,
    loading: false,
    status: -1,
    statusText: "",
};

export const deleteMaterialTransferSlice = createSlice({
    name: "deleteMaterialTransferReducer",
    initialState,
    reducers: {
        resetStatus: (state) => {
            state.status = -1;
            state.statusText = "";
        },
        setSelectedToDeleteTransferMaterialId: (state, action) => {
            state.selectedToDeleteTransferMaterialId = action.payload;
        },
        setSelectedToDeleteMaterialName: (state, action) => {
            state.selectedToDeleteMaterialName = action.payload;
        },

        resetDeleteMaterialTransfer: () => initialState,
    },
    extraReducers: (builder) => {
        // Eliminar material seleccionado de transferencia
        builder.addCase(fetchDeleteMaterialTransfer.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            fetchDeleteMaterialTransfer.fulfilled,
            (state, action) => {
                state.status = action.payload.status;
                state.statusText = action.payload.statusText;
                state.loading = false;
            }
        );
        builder.addCase(fetchDeleteMaterialTransfer.rejected, (state, action) => {
            state.loading = false;
        });
    },
});

export const {
    resetStatus,
    resetDeleteMaterialTransfer,
    setSelectedToDeleteTransferMaterialId,
    setSelectedToDeleteMaterialName,
} = deleteMaterialTransferSlice.actions;

export default deleteMaterialTransferSlice.reducer;
