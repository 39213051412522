import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  destroyRedux,
  getRedux,
  patchRedux,
  postRedux,
} from "../../../utils/old/api";

const initialState = {
  business: {},
  addresses: [], //Con paginación
  totalAddressesList: [], //Sin paginación
  certificatesAddresses: [],
  certificates: [],
  prices: [],
  allPrices: [],
  collections: [],
  recurrentCollections: [],
  totalRowsCollections: 0,
  totalRowsRecurrentCollections: 0,
  totalRowsAddresses: 0,
  totalRowsBanksAccounts: 0,
  businesses: [],
  totalRows: 0,
  bankAccounts: [],
  totalBankAccounts: [],
  rows: [], //Para retiros
  pageWithdrawals: 0,
  totalRowsWithdrawals: 0,
  successWithdrawal: false,
  pageCollections: 0,
  pageRecurrentCollections: 0,
  pageCustomPrices: 0,
  pageAddresses: 0,
  pageBankAccounts: 0,
  showLoading: false,
  snackTxt: "",
  showSnack: false,
  error: "",
};

//--------------------------------------------businessProfile----------------------------------------------------

//Para BasicBusinessDataCard
export const fetchBusiness = createAsyncThunk(
  //get
  "business/show",
  (id) => {
    return getRedux(`/business/${id}`).then((response) => {
      return response.data;
    });
  }
);

export const fetchUpdateBusiness = createAsyncThunk(
  //patch
  "updateBusiness/patch",
  ({ data, id }) => {
    return patchRedux(`/business/${id}`, data).then((response) => {
      return response.data;
    });
  }
);

export const fetchCreateNotification = createAsyncThunk(
  //post
  "createNotification/post",
  (data) => {
    return postRedux("/user/notify", data).then((response) => {
      return response.data;
    });
  }
);

//Para AddressCard
export const fetchBusinessAddress = createAsyncThunk(
  //get
  "businessAddress/show",
  ({ cPage, id }) => {
    return getRedux(
      `/address?ownerId=${id}&admin=true&ownerType=business&$limit=20&$skip=${
        cPage * 20
      }&$sort[createdAt]=-1`
    ).then((response) => {
      return response.data;
    });
  }
);

export const fetchCreateAddress = createAsyncThunk(
  //post
  "createAddress/post",
  (data) => {
    return postRedux("/address", data).then((response) => {
      return response.data;
    });
  }
);

export const fetchUpdateAddress = createAsyncThunk(
  //patch
  "updateAddress/patch",
  ({ data, selectedAddressId }) => {
    return patchRedux(`/address/${selectedAddressId}`, data).then(
      (response) => {
        return response.data;
      }
    );
  }
);

export const fetchDeleteAddress = createAsyncThunk(
  //Destroy
  "deleteAddress/delete",
  (address) => {
    return destroyRedux(`/address/${address.id}?admin=true`).then(
      (response) => {
        return response.data;
      }
    );
  }
);

//Para CertificatesCard

export const fetchBusinessCertificatesAddresses = createAsyncThunk(
  //get
  "businessCertificatesAddresses/show",
  (id) => {
    return getRedux(
      `/certificate?ownerType=business&ownerId=${id}&certificate=true`
    ).then((response) => {
      return response.data;
    });
  }
);

export const fetchBusinessCertificates = createAsyncThunk(
  //get
  "businessCertificates/show",
  ({ id, address }) => {
    return getRedux(
      `/certificate?ownerType=business&ownerId=${id}&type=monthly_env_certificate&address=${address}`
    ).then((response) => {
      return response.data;
    });
  }
);

//Para CustomPricesCard

export const fetchBusinessPrices = createAsyncThunk(
  //Get
  "businessPrices/show",
  (id) => {
    return getRedux(`/material-price?business_id=${id}&only_custom=true`).then(
      (response) => {
        return response.data;
      }
    );
  }
);

export const fetchBusinessAllPrices = createAsyncThunk(
  //Get
  "businessAllPrices/show",
  () => {
    return getRedux("/material-price?admin=true").then((response) => {
      return [...response.data].sort((a, b) =>
        a.material.name
          .toLowerCase()
          .localeCompare(b.material.name.toLowerCase())
      );
    });
  }
);

export const fetchCreateNewMaterialPrice = createAsyncThunk(
  //Post
  "createNewMaterialPrice/post",
  (data) => {
    return postRedux("/material-price", data).then((response) => {
      return response.data;
    });
  }
);

export const fetchDeleteMaterial = createAsyncThunk(
  //Destroy
  "deleteMaterial/delete",
  (material) => {
    return destroyRedux(`/material-price/${material.id}`).then((response) => {
      return response.data;
    });
  }
);

//Para BanksAccountsCard

export const fetchBankAccounts = createAsyncThunk(
  //Get
  "bankAccounts /show",
  ({ cPage, id }) => {
    return getRedux(
      `/bank-account?business_id=${id}&$limit=20&$skip=${
        cPage * 20
      }&$sort[createdAt]=-1`
    ).then((response) => {
      return response.data;
    });
  }
);

export const fetchCreateNewBankAccount = createAsyncThunk(
  //Post
  "createNewBankAccounte/post",
  (data) => {
    return postRedux("/bank-account", data).then((response) => {
      return response.data;
    });
  }
);

export const fetchDeleteBankAccount = createAsyncThunk(
  //Destroy
  "deleteBankAccount/delete",
  (bankAccount) => {
    return destroyRedux(`/bank-account/${bankAccount._id}`).then((response) => {
      return response.data;
    });
  }
);

//Para Collection Card
export const fetchBusinessCollections = createAsyncThunk(
  //Get
  "businessColections/show",
  ({ cPage, id }) => {
    return getRedux(
      `/collection?ownerId=${id}&ownerType=business&admin=true&$limit=20&$skip=${
        cPage * 20
      }&$sort[date]=-1`
    ).then((response) => {
      return response.data;
    });
  }
);

export const fetchCreateCollection = createAsyncThunk(
  //Post
  "createCollection/post",
  (data) => {
    return postRedux("/collection", data).then((response) => {
      return response.data;
    });
  }
);

//Direcciones sin Paginación
export const fetchBusinessAddressList = createAsyncThunk(
  //get
  "businessAddressesList/show",
  (id) => {
    return getRedux(
      `/address?ownerType=business&ownerId=${id}&admin=true&paginate=false&$sort[createdAt]=-1`
    ).then((response) => {
      return response.data;
    });
  }
);

//Para Recurrent Collection Card

export const fetchBusinessRecurrentCollections = createAsyncThunk(
  //Get
  "businessRecurrentColections/show",
  ({ cPage, id }) => {
    return getRedux(
      `/recurrent-collection?business_id=${id}&admin=true&$limit=20&$skip=${
        cPage * 20
      }&$sort[createdAt]=-1`
    ).then((response) => {
      return response.data;
    });
  }
);

export const fetchCreateRecurrentCollection = createAsyncThunk(
  //Post
  "createRecurrentCollection/post",
  (data) => {
    return postRedux("/recurrent-collection", data).then((response) => {
      return response.data;
    });
  }
);

export const fetchUpdateRecurrentCollection = createAsyncThunk(
  //patch
  "updateRecurrentCollection/patch",
  ({ data, selectedRecurrentCollectionId }) => {
    return patchRedux(
      `/recurrent-collection/${selectedRecurrentCollectionId}`,
      data
    ).then((response) => {
      return response.data;
    });
  }
);

export const fetchDeleteRecurrentCollection = createAsyncThunk(
  //Destroy
  "deleteRecurrentCollection/delete",
  (recurrentCollection) => {
    return destroyRedux(
      `/recurrent-collection/${recurrentCollection._id}`
    ).then((response) => {
      return response.data;
    });
  }
);

//Para WithdrawalsCard

export const fetchBankAccountsList = createAsyncThunk(
  //Get
  "bankAccountsList /show",
  (id) => {
    return getRedux(
      `/bank-account?business_id=${id}&admin=true&paginate=false&$sort[createdAt]=-1`
    ).then((response) => {
      return response.data;
    });
  }
);

export const fetchWithdrawals = createAsyncThunk(
  //Get
  "withdrawals /show",
  ({ cPage, id }) => {
    return getRedux(
      `/withdrawal?ownerId=${id}&ownerType=business&admin=true&$limit=20&$skip=${
        cPage * 20
      }&$sort[createdAt]=-1&business_id=${id}`
    ).then((response) => {
      return response.data;
    });
  }
);

export const fetchUpdateWithdrawal = createAsyncThunk(
  //patch
  "updateWithdrawal/patch",
  ({ state, selectedWithdrawal }) => {
    return patchRedux(`/withdrawal/${selectedWithdrawal._id}`, state).then(
      (response) => {
        return response.data;
      }
    );
  }
);

export const fetchCreateWithdrawal = createAsyncThunk(
  //Post
  "createWithdrawal/post",
  (data) => {
    return postRedux("/withdrawal", data).then((response) => {
      return response.data;
    });
  }
);

//--------------------------------------------businessList----------------------------------------------------------------

//Para businessList.js
export const fetchBusinessList = createAsyncThunk(
  //Get
  "businesses/show",
  (cPage) => {
    return getRedux(`/business?$limit=20&$skip=${cPage * 20}`).then(
      (response) => {
        return response.data;
      }
    );
  }
);

export const fetchCreateBusiness = createAsyncThunk(
  //Post
  "createBusiness/post",
  (params) => {
    return postRedux("/user", params)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
);

//ParaUsersTable

export const fetchSearchBusiness = createAsyncThunk(
  //Post
  "searchBusinesses/post",
  (query) => {
    return postRedux("/business/search", query)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
);

export const businessSlice = createSlice({
  name: "businessReducer",
  initialState,
  reducers: {
    setShowSnack: (state, action) => {
      state.showSnack = action.payload;
    },

    setPageCertificates: (state, action) => {
      state.pageCertificates = action.payload;
    },

    setPageCollections: (state, action) => {
      state.pageCollections = action.payload;
    },

    setPageRecurrentCollections: (state, action) => {
      state.pageRecurrentCollections = action.payload;
    },

    setPageAddresses: (state, action) => {
      state.pageAddresses = action.payload;
    },

    setPageWithdrawals: (state, action) => {
      state.pageWithdrawals = action.payload;
    },

    setPageBankAccounts: (state, action) => {
      state.pageBankAccounts = action.payload;
    },
  },
  extraReducers: (builder) => {
    //1
    builder.addCase(fetchBusiness.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchBusiness.fulfilled, (state, action) => {
      state.business = action.payload;
      state.showLoading = false;
    });
    //2
    builder.addCase(fetchBusinessAddress.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchBusinessAddress.fulfilled, (state, action) => {
      state.addresses = action.payload.data;
      state.totalRowsAddresses = action.payload.total;
      state.showLoading = false;
    });
    //3
    builder.addCase(fetchBusinessPrices.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchBusinessPrices.fulfilled, (state, action) => {
      state.prices = action.payload;
      state.totalRowsCustomPrices = action.payload.total;
      state.showLoading = false;
    });
    //4
    builder.addCase(fetchBusinessAllPrices.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchBusinessAllPrices.fulfilled, (state, action) => {
      state.allPrices = action.payload;
      state.showLoading = false;
    });
    //5
    builder.addCase(fetchBusinessCollections.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchBusinessCollections.fulfilled, (state, action) => {
      state.collections = action.payload.data;
      state.totalRowsCollections = action.payload.total;
      state.showLoading = false;
    });
    //6
    builder.addCase(fetchBusinessList.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchBusinessList.fulfilled, (state, action) => {
      state.businesses = action.payload.data;
      state.totalRows = action.payload.total;
      state.showLoading = false;
    });
    //7
    builder.addCase(fetchSearchBusiness.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchSearchBusiness.fulfilled, (state, action) => {
      state.businesses = action.payload;
      state.showLoading = false;
    });
    //8
    builder.addCase(fetchUpdateBusiness.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchUpdateBusiness.fulfilled, (state, action) => {
      state.showLoading = false;
    });
    //9
    builder.addCase(fetchCreateNotification.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchCreateNotification.fulfilled, (state, action) => {
      state.showLoading = false;
    });
    //10
    builder.addCase(fetchCreateAddress.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchCreateAddress.fulfilled, (state, action) => {
      state.showLoading = false;
    });
    //11
    builder.addCase(fetchCreateNewMaterialPrice.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchCreateNewMaterialPrice.fulfilled, (state, action) => {
      state.showLoading = false;
    });
    builder.addCase(fetchCreateNewMaterialPrice.rejected, (state, action) => {
      state.showLoading = false;
    });
    //12
    builder.addCase(fetchDeleteMaterial.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchDeleteMaterial.fulfilled, (state, action) => {
      state.showLoading = false;
    });
    builder.addCase(fetchDeleteMaterial.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error");
    });
    //13
    builder.addCase(fetchCreateCollection.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchCreateCollection.fulfilled, (state, action) => {
      state.showLoading = false;
    });
    builder.addCase(fetchCreateCollection.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error");
    });
    //14 fetchCreateBusiness
    builder.addCase(fetchCreateBusiness.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchCreateBusiness.fulfilled, (state, action) => {
      state.showLoading = false;
      state.snackTxt = "Negocio creado";
      state.showSnack = true;
    });
    builder.addCase(fetchCreateBusiness.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error");
    });
    //15-fetchBankAccounts
    builder.addCase(fetchBankAccounts.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchBankAccounts.fulfilled, (state, action) => {
      state.showLoading = false;
      state.bankAccounts = action.payload.data;
      state.totalRowsBanksAccounts = action.payload.total;
    });
    builder.addCase(fetchBankAccounts.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error");
    });
    //16-fetchWithdrawals
    builder.addCase(fetchWithdrawals.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchWithdrawals.fulfilled, (state, action) => {
      state.showLoading = false;
      state.rows = action.payload.data;
      state.totalRowsWithdrawals = action.payload.total;
    });
    builder.addCase(fetchWithdrawals.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error");
    });
    //17-fetchUpdateWithdrawal
    builder.addCase(fetchUpdateWithdrawal.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchUpdateWithdrawal.fulfilled, (state, action) => {
      state.showLoading = false;
      state.successWithdrawal = true;
      // state.snackTxt = "Retiro actualizado";
    });
    builder.addCase(fetchUpdateWithdrawal.rejected, (state, action) => {
      state.showLoading = false;
      state.successWithdrawal = false;
      alert("Error");
    });
    //18-fetchCreateWithdrawal
    builder.addCase(fetchCreateWithdrawal.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchCreateWithdrawal.fulfilled, (state, action) => {
      state.showLoading = false;
    });
    builder.addCase(fetchCreateWithdrawal.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error");
    });

    //19-fetchCreateNewBankAccount
    builder.addCase(fetchCreateNewBankAccount.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchCreateNewBankAccount.fulfilled, (state, action) => {
      state.showLoading = false;
    });
    builder.addCase(fetchCreateNewBankAccount.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error");
    });

    //20-fetchDeleteBankAccount
    builder.addCase(fetchDeleteBankAccount.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchDeleteBankAccount.fulfilled, (state, action) => {
      state.showLoading = false;
    });
    builder.addCase(fetchDeleteBankAccount.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error");
    });

    //21-fetchBusinessCertificates
    builder.addCase(fetchBusinessCertificates.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchBusinessCertificates.fulfilled, (state, action) => {
      state.showLoading = false;
      state.certificates = action.payload.data;
      state.totalRowsCertificates = action.payload.total;
    });
    builder.addCase(fetchBusinessCertificates.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error");
    });

    //21.1-fetchBusinessCertificatesAddresess
    builder.addCase(fetchBusinessCertificatesAddresses.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(
      fetchBusinessCertificatesAddresses.fulfilled,
      (state, action) => {
        state.showLoading = false;
        state.certificatesAddresses = action.payload;
      }
    );
    builder.addCase(
      fetchBusinessCertificatesAddresses.rejected,
      (state, action) => {
        state.showLoading = false;
        alert("Error");
      }
    );

    //22-fetchBusinessRecurrentCollections
    builder.addCase(fetchBusinessRecurrentCollections.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(
      fetchBusinessRecurrentCollections.fulfilled,
      (state, action) => {
        state.recurrentCollections = action.payload.data;
        state.totalRowsRecurrentCollections = action.payload.total;
        state.showLoading = false;
      }
    );
    builder.addCase(
      fetchBusinessRecurrentCollections.rejected,
      (state, action) => {
        state.showLoading = false;
        alert("Error");
      }
    );

    //23-fetchCreateRecurrentCollection
    builder.addCase(fetchCreateRecurrentCollection.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(
      fetchCreateRecurrentCollection.fulfilled,
      (state, action) => {
        state.showLoading = false;
      }
    );
    builder.addCase(
      fetchCreateRecurrentCollection.rejected,
      (state, action) => {
        state.showLoading = false;
        alert("Error");
      }
    );

    //24-fetchUpdateRecurrentCollection
    builder.addCase(fetchUpdateRecurrentCollection.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(
      fetchUpdateRecurrentCollection.fulfilled,
      (state, action) => {
        state.showLoading = false;
      }
    );
    builder.addCase(
      fetchUpdateRecurrentCollection.rejected,
      (state, action) => {
        state.showLoading = false;
        alert("Error");
      }
    );

    //25-fetchDeleteRecurrentCollection
    builder.addCase(fetchDeleteRecurrentCollection.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(
      fetchDeleteRecurrentCollection.fulfilled,
      (state, action) => {
        state.showLoading = false;
      }
    );
    builder.addCase(
      fetchDeleteRecurrentCollection.rejected,
      (state, action) => {
        state.showLoading = false;
        alert("Error");
      }
    );
    //26-fetchBusinessAddressList
    builder.addCase(fetchBusinessAddressList.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchBusinessAddressList.fulfilled, (state, action) => {
      state.totalAddressesList = action.payload;
      state.showLoading = false;
    });
    builder.addCase(fetchBusinessAddressList.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error");
    });
    //27-fetchBankAccountsList
    builder.addCase(fetchBankAccountsList.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchBankAccountsList.fulfilled, (state, action) => {
      state.totalBankAccounts = action.payload;
      state.showLoading = false;
    });
    builder.addCase(fetchBankAccountsList.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error");
    });
  },
});

export const {
  setShowSnack,
  setPageCollections,
  setPageRecurrentCollections,
  setPageAddresses,
  setPageWithdrawals,
  setPageCertificates,
  setPageBankAccounts,
} = businessSlice.actions;
export default businessSlice.reducer;
