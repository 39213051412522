import React from "react";
import {
  Box,
  FormControl,
  InputAdornment,
  InputBase,
  ListItemIcon,
  MenuItem,
  Select as MuiSelect,
} from "@mui/material";
import { ExpandMoreOutlined } from "@mui/icons-material";

import CustomLabel from "./CustomLabel";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import { CustomSelectType } from "../../../../interfaces/data/CustomSelectInterface";
import CustomText from "../../texts/CustomText";
import { useTranslation } from "react-i18next";

const CustomSelect: React.FC<CustomSelectType> = ({
  id,
  label,
  value,
  onChange,
  variant,
  dimension,
  IconComponent,
  items,
  fullWidth,
  optional,
  required,
  defaultOptionValue,
  defaultOptionLabel,
  defaultOption,
  startIcon,
  startSelectedIcon,
  placeholder,
  disabled,
  extraStyle,
  formik,
}) => {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();

  const renderListItemIcon = () => {
    if (startIcon) {
      return (
        <ListItemIcon>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {startSelectedIcon}
          </Box>
        </ListItemIcon>
      );
    }
    return null;
  };

  return (
    <FormControl
      fullWidth={fullWidth}
      sx={{
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "#E8E8E8",
          },
        },
        "& .MuiOutlinedInput-root:hover": {
          "& > fieldset": {
            borderColor: "#E8E8E8",
          },
        },
        ...(formik &&
          formik.touched[id] &&
          formik.errors[id] && {
            fieldset: {
              border: `${PP(1)} solid #E8E8E8`,
            },
          }),
        ...extraStyle,
      }}
    >
      {label && (
        <CustomLabel
          className="medium-size bold-weight color-gray-light"
          sx={{
            marginBottom: PP(8),
            color: "#969696",
            fontSize: PP(14),
            fontWeight: 700,
            fontFamily: "'Inter', sans-serif",
          }}
        >
          {label}
          {optional && (
            <span style={{ color: "#969696" }}>{" (Opcional)"}</span>
          )}
          {required && <span style={{ color: "#E73B85" }}>{" *"}</span>}
        </CustomLabel>
      )}
      <MuiSelect
        id={id}
        value={value}
        onChange={onChange}
        name={id}
        input={
          <InputBase
            startAdornment={
              startIcon ? (
                <InputAdornment position="end">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {startIcon}
                  </Box>
                </InputAdornment>
              ) : null
            }
            className={`${variant ? variant : "default"}${
              dimension ? ` ${dimension}` : ""
            }`}
            sx={{
              marginLeft: "0px",
              fontFamily: "'Inter', sans-serif",
              borderRadius: PP(4),
              height: PP(40),
              border:
                formik && formik.errors[id]
                  ? `${PP(1)} solid #E73B85`
                  : disabled
                  ? `none`
                  : `${PP(1)} solid #E8E8E8`,
              paddingLeft: PP(24),
              paddingRight: PP(24),
            }}
            disabled={disabled}
          />
        }
        IconComponent={
          !disabled
            ? IconComponent
              ? IconComponent
              : (props) => (
                  <ExpandMoreOutlined
                    style={{
                      ...{ width: PP(20), height: PP(20), color: "#C4C4C4" },
                    }}
                    {...props}
                  />
                )
            : () => <></>
        }
      >
        {placeholder && (
          <MenuItem value="" disabled>
            <CustomText noSpacing>{placeholder}</CustomText>
          </MenuItem>
        )}
        {defaultOption && (
          <CustomText noSpacing>
            <MenuItem
              value={defaultOptionValue}
              key={-1}
              style={{ display: "none" }}
            >
              {defaultOptionLabel}
            </MenuItem>
          </CustomText>
        )}
        {items &&
          Array.isArray(items) &&
          items.map((item, key) => (
            <MenuItem
              key={key}
              value={item.value}
              sx={{
                backgroundColor:
                  value === item.value
                    ? "rgb(236, 241, 255)" // Color de fondo rojo para la opción seleccionada
                    : "transparent", // Color de fondo por defecto
              }}
            >
              {renderListItemIcon()}
              <CustomText noSpacing>{item.label}</CustomText>
            </MenuItem>
          ))}
      </MuiSelect>
      {formik && formik.touched[id] && (
        <span
          style={{
            color: "#E73B85",
            fontSize: PP(12),
          }}
        >
          {t(formik.errors[id])}
        </span>
      )}
    </FormControl>
  );
};

export default CustomSelect;
