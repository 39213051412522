import "moment/locale/es";
import moment from "moment-timezone";

export const collectionPageHistoryDateFormat = (
  data: Date | string
): string => {
  return moment(data)
    .locale("es")
    .tz("America/Bogota")
    .format("MMM D, YYYY. h:mm a")
    .replace(/^\w/, (c) => c.toUpperCase());
};
