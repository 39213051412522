import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import {
  Box,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
  LinearProgress as MuiLinearProgress,
  TextField,
} from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import EnhancedTableHead from "../../../generalOld/EnhancedTableHead";
import {
  fetchBusinessList,
  fetchSearchBusiness,
} from "../../../../../redux/old/slices/businessSlicer";

const Paper = styled(MuiPaper)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

export default function UsersTable({ headCells }) {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [currentDelay, setCurrentDelay] = useState(null);
  const [businessSearch, setBusinessSearch] = useState(false);
  const [text, setText] = useState("");
  const { t } = useTranslation();

  //ReduxTookit
  //const loading = useSelector((state) => state.business.showLoading);
  const businesses = useSelector((state) => state.business.businesses);
  const totalRows = useSelector((state) => state.business.totalRows);
  const dispatch = useDispatch();

  useEffect(() => {
    getBusinesses();
  }, []);

  useEffect(() => {
    if (businessSearch) {
      getBusinessesSearch();
    }
  }, []);

  //Lista normal de todos los negocios

  const getBusinesses = async (cPage = 0) => {
    dispatch(fetchBusinessList(cPage)); //ReduxTookit
    setPage(cPage);
  };

  const handleChangePage = async (event, newPage) => {
    getBusinesses(newPage);
  };

  const handleChangeRowsPerPage = async () => {
    getBusinesses(0);
  };

  //Lista por búsqueda de negocios
  const getBusinessesSearch = async (text) => {
    setText(text);
    await dispatch(
      fetchSearchBusiness({
        query: text !== text.toLowerCase() ? text.toLowerCase() : text,
      })
    ); //ReduxToolkit
  };

  const searchBusiness = async (text) => {
    clearTimeout(currentDelay);
    setCurrentDelay(
      setTimeout(async () => {
        if (text.length <= 2) {
          getBusinesses(0);
          setBusinessSearch(false);
        } else {
          getBusinessesSearch(text);
          setBusinessSearch(true);
        }
      }, 1000)
    );
  };

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Paper>
            {loading && <LinearProgress my={2} />}
            <Toolbar>
              <ToolbarTitle>
                <Typography variant="h6" id="tableTitle">
                  {t("Negocios")}
                </Typography>
              </ToolbarTitle>
              <Spacer />
              <div>
                <TextField
                  name="wallet"
                  label={t("Búsqueda")}
                  fullWidth
                  variant="outlined"
                  my={2}
                  onChange={(e) => searchBusiness(e.target.value)}
                />
              </div>
            </Toolbar>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead headCells={headCells} />
                <TableBody>
                  {businesses.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={`${row.id}-${index}`}
                      >
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                        <TableCell align="left">
                          {row.document_number}
                        </TableCell>
                        <TableCell padding="none" align="right">
                          <Box mr={2}>
                            <IconButton
                              aria-label="details"
                              component={NavLink}
                              to={`/users/businesses/${row.id}`}
                              size="large"
                            >
                              <EditIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            {!businessSearch && (
              <TablePagination
                rowsPerPageOptions={[20]}
                component="div"
                count={totalRows}
                rowsPerPage={20}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
