import { createSlice } from "@reduxjs/toolkit";

import { ClientsListSliceType } from "../../../interfaces/redux/clientsListSliceInterface";
import { fetchClientsList } from "../../thunks/transactions/clientsListThunk";

const initialState: ClientsListSliceType = {
  status: "idle",
  clientsList: [],
};

export const clientsListSlice = createSlice({
  name: "clientsDetails",
  initialState,
  reducers: {
    resetClientsList: () => initialState,
  },
  extraReducers: (builder) => {
    //Get lista de clientes
    builder.addCase(fetchClientsList.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchClientsList.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.clientsList = action.payload;
    });
    builder.addCase(fetchClientsList.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export const { resetClientsList } = clientsListSlice.actions;

export default clientsListSlice.reducer;
