import { useState } from "react";

import {
    styled,
    TableCell,
    TableRow,
} from "@mui/material";

import { CustomTableRowType } from "../../../../interfaces/styles/customTableRowInterface";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import { PageHistoryType } from "../../../../interfaces/data/PageHistoryInterface";
import { collectionPageHistoryDateFormat } from "../../../../utils/formats/collectionPageHistoryDateFormat";
import { collectionPageHistoryEventsFormat } from "../../../../utils/formats/collectionPageHistoryEventsFormat";

interface PageHistoryTableRowProps {
    element: PageHistoryType;
    myKey: number;
}

const CustomTableRow = styled(TableRow)<CustomTableRowType>(({ }) => ({}));

export default function CustomCollectionPageHistoryTableRow(
    props: PageHistoryTableRowProps
) {
    const { PP } = usePixelPerfect();
    const { element, myKey } = props;
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <CustomTableRow
            key={myKey}
            variant="default"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ cursor: "default" }}
        >
            {element && (
                <>
                    <TableCell style={{ color: "#212121", cursor: "default" }}>
                        {collectionPageHistoryDateFormat(element.send_at)}
                    </TableCell>
                    <TableCell style={{ cursor: "default" }}>
                        {element.person_email}
                    </TableCell>

                    <TableCell style={{ cursor: "default" }}>
                        {collectionPageHistoryEventsFormat(element.event_name)}
                    </TableCell>

                </>
            )}
        </CustomTableRow>
    );
}
