import React from "react";
import { useTranslation } from "react-i18next";

const useBusinessConstants = () => {
  const { t } = useTranslation();
  const regimenBusinessOptions = [
    {
      label: t("createNewBusinessModal.regimenBusinessOptions.Común"),
      value: "comun",
    },
    {
      label: t("createNewBusinessModal.regimenBusinessOptions.Simplificado"),
      value: "simplified",
    },
    { label: t("Especial"), value: "special" },
  ];

  const documentTypeOptions = [
    { value: "nif", label: "NIF" },
    { value: "vat", label: "VAT" },
    { value: "tin", label: "TIN" },
    { value: "ein", label: "EIN" },
    { value: "rfc", label: "RFC" },
    { value: "crn", label: "CRN" },
    { value: "nit", label: "NIT" },
    { value: "pin", label: "PIN" },
    { value: "nin", label: "NIN" },
  ];

  const paymentMethodOption = [
    { label: t("createNewBusinessModal.Efectivo"), value: "cash" },
    {
      label: t("createNewBusinessModal.Transferencia"),
      value: "bank_transfer",
    },
  ];

  const typeOfOperator = [
    { label: t("createNewBusinessModal.Interno"), value: "muta" },
    { label: t("createNewBusinessModal.Externo"), value: "external" },
  ];

  const entityBusinessTypes = [
    {
      label: t("createNewBusinessModal.entityBusinessTypes.Restaurante"),
      value: "restaurant",
    },
    {
      label: t("createNewBusinessModal.entityBusinessTypes.Minorista"),
      value: "retail",
    },
    {
      label: t("createNewBusinessModal.entityBusinessTypes.Fábricas"),
      value: "industry",
    },
    {
      label: t(
        "createNewBusinessModal.entityBusinessTypes.Institución educativa"
      ),
      value: "educational",
    },
    {
      label: t(
        "createNewBusinessModal.entityBusinessTypes.Propiedad de horizontal"
      ),
      value: "building",
    },
    {
      label: t("createNewBusinessModal.entityBusinessTypes.Hoteles"),
      value: "hotel",
    },
    {
      label: t(
        "createNewBusinessModal.entityBusinessTypes.Fabricación de alimentos y bebidas"
      ),
      value: "food",
    },
    {
      label: t(
        "createNewBusinessModal.entityBusinessTypes.Proovedores de entretenimiento"
      ),
      value: "entertainment",
    },
    {
      label: t(
        "createNewBusinessModal.entityBusinessTypes.Prestación de servicios de salud"
      ),
      value: "health",
    },
    {
      label: t("createNewBusinessModal.entityBusinessTypes.Constructoras"),
      value: "construction",
    },
    {
      label: t(
        "createNewBusinessModal.entityBusinessTypes.Administración Gobierno"
      ),
      value: "government",
    },
    {
      label: t(
        "createNewBusinessModal.entityBusinessTypes.Servicios de mantenimiento"
      ),
      value: "workshop",
    },
    {
      label: t(
        "createNewBusinessModal.entityBusinessTypes.Servicios de bienestar y fitness"
      ),
      value: "wellness",
    },
    {
      label: t(
        "createNewBusinessModal.entityBusinessTypes.Servicios medioambientales"
      ),
      value: "enviromental",
    },
    {
      label: t(
        "createNewBusinessModal.entityBusinessTypes.Transporte, logística, cadena de suministro y almacenamiento"
      ),
      value: "logistic",
    },
    {
      label: t(
        "createNewBusinessModal.entityBusinessTypes.Caja de compensación"
      ),
      value: "compensation",
    },
    {
      label: t("createNewBusinessModal.entityBusinessTypes.Mayorista"),
      value: "factory",
    },
    {
      label: t(
        "createNewBusinessModal.entityBusinessTypes.Centros comerciales"
      ),
      value: "mall",
    },
    {
      label: t(
        "createNewBusinessModal.entityBusinessTypes.Servicios financieros y legales"
      ),
      value: "law",
    },
    {
      label: t(
        "createNewBusinessModal.entityBusinessTypes.Servicios funerarios"
      ),
      value: "funeral",
    },
    {
      label: t("createNewBusinessModal.entityBusinessTypes.Sector tecnológico"),
      value: "technology",
    },
    {
      label: t(
        "createNewBusinessModal.entityBusinessTypes.Entidades sin ánimo de lucro"
      ),
      value: "special",
    },
    {
      label: t("createNewBusinessModal.entityBusinessTypes.Oficinas"),
      value: "office",
    },
    {
      label: t("createNewBusinessModal.entityBusinessTypes.Otros"),
      value: "other",
    },
  ];
  return {
    regimenBusinessOptions,
    entityBusinessTypes,
    typeOfOperator,
    paymentMethodOption,
    documentTypeOptions,
  };
};

export default useBusinessConstants;
