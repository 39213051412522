import { createRef, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Box, Modal } from "@mui/material";

import CollectionInformation from "../../components/collections/CollectionInformation";
import CollectionRequestInformation from "../../components/collections/CollectionRequestInformation";
import CollectionDetailsLayout from "../../layouts/collections/CollectionDetailsLayout";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { fetchCollectionDetails } from "../../redux/thunks/collections/collectionDetailsThunk";
import CustomAddMaterialModal from "../../components/custom/modals/collections/CustomAddMaterialModal";
import CustomSelectMaterialCollectionModal from "../../components/custom/modals/collections/CustomSelectMaterialCollectionModal";
import CustomDeleteMaterialModal from "../../components/custom/modals/collections/CustomDeleteMaterialModal";
import CustomEditMaterialModal from "../../components/custom/modals/collections/CustomEditMaterialModal";
import {
  setMessage,
  setOpenSnackbar,
  setType,
} from "../../redux/slices/globalSnackbar/globalSnackbarSlice";
import CollectionAssignmentDetail from "../../components/collections/CollectionAssignmentDetail";
import CustomEditCollectionStateModal from "../../components/custom/modals/collections/CustomEditCollectionStateModal";
import CustomSelectCategory from "../../components/custom/modals/collections/CustomSelectCategory";
import { fetchCollectionMaterialPrices } from "../../redux/thunks/collections/collectionMaterialPricesThunk";
import CollectionAttachedFiles from "../../components/collections/CollectionAttachedFiles";
import CustomCollectionSelectTypeFileModal from "../../components/custom/modals/collections/CustomCollectionSelectTypeFileModal";
import CustomAttachNewFileModal from "../../components/custom/modals/collections/CustomAttachNewFileModal";
import CustomDeleteAttachFileModal from "../../components/custom/modals/collections/CustomDeleteAttachFileModal";
import CustomCollectionWithMaterialsModal from "../../components/custom/modals/collections/CustomCollectionWithMaterialsModal";
import CustomSelectCollectionFailedReasonModal from "../../components/custom/modals/collections/CustomSelectCollectionFailedReasonModal";
import CustomEditAssignmentDetailModal from "../../components/custom/modals/collections/CustomEditAssignmentDetailModal";
import CustomSelectCollectorModal from "../../components/custom/modals/collections/CustomSelectCollectorModal";

export default function CollectionDetails() {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const ref = createRef();

  const { collectionDetails } = useAppSelector(
    (state) => state.collectionDetails
  );

  const {
    selectCategory,
    addMaterial,
    selectMaterial,
    deleteMaterial,
    editQuantity,
  } = useAppSelector((state) => state.collectionMaterialsModalsState);

  const {
    editCollectionState,
    collectionWithMaterials,
    failedReasons,
    editAssignmentDetail,
    selectCollector,
  } = useAppSelector((state) => state.collectionDetailsInfoModalStates);

  const editMaterialStatus = useAppSelector(
    (state) => state.editMaterialCollection.status
  );
  const addMaterialStatus = useAppSelector(
    (state) => state.addMaterialCollection.status
  );
  const deleteMaterialStatus = useAppSelector(
    (state) => state.deleteMaterialCollection.status
  );

  const deleteFileStatus = useAppSelector(
    (state) => state.deleteCollectionAttachFile.status
  );

  const attachNewFileStatus = useAppSelector(
    (state) => state.collectionAttachNewFile.status
  );

  const updateCollectionStateStatus = useAppSelector(
    (state) => state.updateCollectionState.status
  );

  const { selectTypeFile, attachFile, deleteAttachFile } = useAppSelector(
    (state) => state.collectionAttachedFilesModalStates
  );

  const getCollectionDetails = useCallback(async () => {
    if (id !== undefined) await dispatch(fetchCollectionDetails({ id: id }));
  }, [collectionDetails]);

  useEffect(() => {
    getCollectionDetails();
  }, [dispatch, id]);

  const getCollectionMaterialsPrices = async () => {
    if (collectionDetails && collectionDetails.id) {
      await dispatch(
        fetchCollectionMaterialPrices({
          ownerType: collectionDetails.ownerType,
          ownerId: collectionDetails.ownerId,
        })
      );
    }
  };

  useEffect(() => {
    getCollectionMaterialsPrices();
  }, [collectionDetails]);

  useEffect(() => {
    if (
      editMaterialStatus === 201 ||
      addMaterialStatus === 201 ||
      deleteMaterialStatus === 200 ||
      deleteFileStatus === 200 ||
      attachNewFileStatus === 201 ||
      updateCollectionStateStatus === 200
    ) {
      dispatch(setOpenSnackbar(true));
      dispatch(setMessage(t("snackbars.Cambios guardados exitosamente")));
      dispatch(setType("sucessSnackbar"));
      getCollectionDetails();
    }
  }, [
    editMaterialStatus,
    addMaterialStatus,
    deleteMaterialStatus,
    deleteFileStatus,
    attachNewFileStatus,
    updateCollectionStateStatus,
  ]);

  return (
    <CollectionDetailsLayout>
      <Box display="flex" flexDirection="row" width="100%">
        <CollectionRequestInformation />
        <CollectionAssignmentDetail />
      </Box>
      {collectionDetails.items !== undefined &&
        collectionDetails.state !== "failed" &&
        collectionDetails.state !== "canceled" && <CollectionInformation />}
      {collectionDetails.state !== "canceled" &&
        collectionDetails.state !== "collected" &&
        collectionDetails.files &&
        collectionDetails.files.length !== 0 && <CollectionAttachedFiles />}
      {(collectionDetails.state === "pending" ||
        collectionDetails.state === "assigned" ||
        collectionDetails.state === "collected") && <CollectionAttachedFiles />}
      {/* Modales */}
      <Modal
        open={editCollectionState}
        children={<CustomEditCollectionStateModal ref={ref} />}
      />
      <Modal
        open={selectCategory}
        children={<CustomSelectCategory ref={ref} />}
      />
      <Modal
        open={addMaterial}
        children={<CustomAddMaterialModal ref={ref} />}
      />
      <Modal
        open={selectMaterial}
        children={<CustomSelectMaterialCollectionModal ref={ref} />}
      />
      <Modal
        open={deleteMaterial}
        children={<CustomDeleteMaterialModal ref={ref} />}
      />
      <Modal
        open={editQuantity}
        children={<CustomEditMaterialModal ref={ref} />}
      />
      <Modal
        open={selectTypeFile}
        children={<CustomCollectionSelectTypeFileModal ref={ref} />}
      />
      <Modal
        open={attachFile}
        children={<CustomAttachNewFileModal ref={ref} />}
      />
      <Modal
        open={deleteAttachFile}
        children={<CustomDeleteAttachFileModal ref={ref} />}
      />
      <Modal
        open={collectionWithMaterials}
        children={<CustomCollectionWithMaterialsModal ref={ref} />}
      />
      <Modal
        open={failedReasons}
        children={<CustomSelectCollectionFailedReasonModal ref={ref} />}
      />
      <Modal
        open={editAssignmentDetail}
        children={<CustomEditAssignmentDetailModal ref={ref} />}
      />
      <Modal
        open={selectCollector}
        children={<CustomSelectCollectorModal ref={ref} />}
      />
    </CollectionDetailsLayout>
  );
}
