import { forwardRef } from "react";

import { Divider } from "@mui/material";

import DefaultModalLayout from "../../../../layouts/collections/modals/DefaultModalLayout";
import CustomText from "../../texts/CustomText";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import { useTranslation } from "react-i18next";
import CreateClientForm from "../../clients/CreateClientForm";
import { ClientType } from "../../../../interfaces/data/clientInterface";
import EditClientForm from "../../clients/EditClientForm";

interface CustomEditClientModalProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getClients: () => Promise<void>;
  selectedClientToEdit: ClientType;
}

export default forwardRef(function CustomEditClientModal(
  props: CustomEditClientModalProps
) {
  const { setOpen, getClients, selectedClientToEdit } = props;
  const { PP2 } = usePixelPerfect();
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DefaultModalLayout handleClose={handleClose}>
      <CustomText
        noSpacing
        bigSize
        premediumWeight
        color="#1C1C1C"
        style={{ marginBottom: PP2(24) }}
        // onClick={resetForm}
      >
        {t("Editar cliente")}
      </CustomText>
      <Divider sx={{ margin: "0px" }} />
      <EditClientForm
        setOpen={setOpen}
        getClientList={getClients}
        selectedClientToEdit={selectedClientToEdit}
      />
    </DefaultModalLayout>
  );
});
