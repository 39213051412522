export const transformationDateFormat = (isoDateString) => {
  // Crear un objeto Date usando la cadena ISO 8601
  const date = new Date(isoDateString);

  // Convertir la fecha UTC a la zona horaria de Bogotá (UTC-5)
  const bogotaOffset = -5 * 60; // Bogotá está en UTC-5
  const adjustedDate = new Date(
    date.getTime() + (bogotaOffset + date.getTimezoneOffset()) * 60000
  );

  // Extraer los componentes de la fecha ajustada
  const year = adjustedDate.getFullYear();
  const month = String(adjustedDate.getMonth() + 1).padStart(2, "0"); // Sumamos 1 para obtener el mes correcto
  const day = String(adjustedDate.getDate()).padStart(2, "0");

  // Formatear la fecha en el formato "YYYY-MM-DD"
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};
