export function firstLetterCapitalizeFormat( // la primera letra de cada palabra de una cadena de texto
  sentence: string | undefined
): string {
  if (sentence !== undefined) {
    const words = sentence?.split(" ");
    const capitalizedWords = words?.map((word) => {
      if (word.length > 0) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
      return "";
    });
    return capitalizedWords?.join(" ");
  } else {
    return "";
  }
}
