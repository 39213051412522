import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TransferMaterialsModalKeys = any;

interface SetTransferModalStatePayload {
  id: TransferMaterialsModalKeys;
  value: boolean;
}

interface TransferMaterialsModalSliceType {
  selectCategory: boolean;
  addMaterial: boolean;
  selectMaterial: boolean;
  deleteMaterial: boolean;
  editQuantity: boolean;
}

const initialState: TransferMaterialsModalSliceType = {
  selectCategory: false,
  addMaterial: false,
  selectMaterial: false,
  deleteMaterial: false,
  editQuantity: false,
};

export const transferMaterialsModalsStatesSlice = createSlice({
  name: "transferMaterialsModalsStatesReducer",
  initialState,
  reducers: {
    setTransferMaterialsModalState: (
      state: any,
      action: PayloadAction<SetTransferModalStatePayload>
    ) => {
      // @ts-ignore
      state[action.payload.id] = action.payload.value;
    },
    reset: () => initialState,
  },
});

export const { setTransferMaterialsModalState, reset } =
  transferMaterialsModalsStatesSlice.actions;

export default transferMaterialsModalsStatesSlice.reducer;
