import { createSlice } from "@reduxjs/toolkit";

import { BusinessListSliceType } from "../../../../interfaces/redux/businessListSliceInterface";
import { fetchBusinessList } from "../../../thunks/users/business/businessListThunk";

const initialState: BusinessListSliceType = {
  status: "idle",
  businessList: [],
  totalRows: 0,
};

export const businessListSlice = createSlice({
  name: "businessList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Get lista Negocios en general con o sin filtros
    builder.addCase(fetchBusinessList.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchBusinessList.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.businessList = action.payload.data;
      state.totalRows = action.payload.total;
    });
    builder.addCase(fetchBusinessList.rejected, (state) => {
      state.status = "failed";
    });
  },
});

export const {} = businessListSlice.actions;

export default businessListSlice.reducer;
