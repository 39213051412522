import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Chip, IconButton, styled, TableCell, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CustomTableRowType } from "../../../../../interfaces/styles/customTableRowInterface";
import { ReactComponent as ActionIcon } from "../../../../../assets/icons/tables/next.svg";
import usePixelPerfect from "../../../../../hooks/old/usePixelPerfect";
import { TransferType } from "../../../../../interfaces/data/transferInterface";
import { fetchWarehouseDetails } from "../../../../../redux/thunks/warehouse/warehouseDetailsThunk";
import { useAppDispatch } from "../../../../../redux/store";

interface CustomTransferListTableRowProps {
    element: TransferType;
    myKey: number;
}

const CustomTableRow = styled(TableRow)<CustomTableRowType>(({ }) => ({}));

export default function CustomTransferListTableRow(
    props: CustomTransferListTableRowProps
) {
    const { PP } = usePixelPerfect();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { element, myKey } = props;

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);


    return (
        <CustomTableRow
            key={myKey}
            variant="default"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ cursor: "default" }}
        >
            {element && (
                <>
                    <TableCell style={{ color: "#212121", cursor: "default" }}>
                        {element.startDate}
                    </TableCell>
                    <TableCell style={{ color: "#212121", cursor: "default" }}>
                        {element.nameOriginWarehouse}
                    </TableCell>
                    <TableCell style={{ cursor: "default" }}>
                        {element.nameDestinationWarehouse}
                    </TableCell>
                    <TableCell style={{ cursor: "default" }}>
                        <Chip
                            style={{
                                padding: `${PP(4)} ${PP(8)}`,
                                height: PP(28),
                                width: "auto",
                            }}
                            // @ts-ignore
                            variant={element.status}
                            label={t(`chips.transferStates.${element.status}`)}
                        />
                    </TableCell>
                    <TableCell align="right" style={{ cursor: "default" }}>
                        {isHovered && (
                            <IconButton
                                aria-label="transactionDetails"
                                component={NavLink}
                                to={`/inventory/transfers/${element.id}`}
                                size="large"
                                style={{ borderRadius: PP(8) }}
                            >
                                <ActionIcon style={{ width: PP(24), height: PP(24) }} />
                            </IconButton>
                        )}
                    </TableCell>
                </>
            )}
        </CustomTableRow>
    );
}
