import * as yup from "yup";

export const customPricesCardValidation = yup.object().shape({
  price: yup.string().trim().required("Price is required"),
  materialId: yup
    .string()
    .trim()
    .required("Material id is required")
    .notOneOf(["", "null"], "Material id is required"),
});
