
import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Box, Divider, LinearProgress, Toolbar } from "@mui/material";

import CustomInput from "../../../components/custom/forms/default/CustomInput";
import usePixelPerfect from "../../../hooks/old/usePixelPerfect";
import CustomHeaderTitle from "../../../components/custom/headers/default/CustomHeaderTitle";
import CustomButton from "../../../components/custom/buttons/default/CustomButton";
import CustomText from "../../../components/custom/texts/CustomText";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { setMessage, setOpenSnackbar, setType } from "../../../redux/slices/globalSnackbar/globalSnackbarSlice";
import { fetchCurrentPasswordValidation } from "../../../redux/thunks/settings/security/currentPasswordValidationThunk";
import { setOldPassword } from "../../../redux/slices/settings/security/currentPasswordValidationSlice";

export default function CurrentPasswordValidation() {
    const { PP } = usePixelPerfect();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { oldPassword, status, loading } = useAppSelector(
        (state) => state.currentPasswordValidation
    );

    const initialValues = {
        oldPassword: oldPassword,
    };

    const handleSubmit = async (values: { oldPassword: string }) => {
        dispatch(setOldPassword(values.oldPassword))
        await dispatch(fetchCurrentPasswordValidation({ oldPassword: values.oldPassword }))
    };


    const formik = useFormik({
        initialValues,
        onSubmit: handleSubmit,
    });

    useEffect(() => {
        if (!loading && oldPassword !== "") {
            if (status === 200) {
                navigate("/settings/changePasswordStep2");
            } else {
                dispatch(setOpenSnackbar(true));
                dispatch(setMessage(t("Contraseña incorrecta. Inténtalo de nuevo")));
                dispatch(setType("failedSnackbar"));
            }
        }
    }, [status, loading, navigate]);

    return (
        <Box display="flex" flexDirection="column">
            <Toolbar
                style={{
                    padding: "0px",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <CustomHeaderTitle
                    title={t("Cambiar contraseña")}
                    style={{ fontSize: PP(36), fontWeight: 500 }}
                />
            </Toolbar>
            <Divider />
            <CustomText>
                {t("Debes verificar que eres tú para poder continuar")}
            </CustomText>
            <form onSubmit={formik.handleSubmit}>
                {loading && <LinearProgress />}
                <Box
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                    }}
                >
                    <CustomInput
                        id="oldPassword"
                        label={t("Contraseña actual")}
                        placeholder={t("Introduce la contraseña actual")}
                        value={formik.values.oldPassword}
                        onChange={formik.handleChange}
                        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
                        inputHeight={PP(40)}
                        inputBorderRadius={PP(8)}
                        extraStyle={{ marginTop: PP(24) }}
                        widthProp={500}
                        type="password"
                    />
                    <CustomButton
                        width={500}
                        height={44}
                        noSpacing
                        style={{ padding: "0px", marginTop: PP(50) }}
                        type="submit"
                        label={t("Siguiente")}
                    />
                </Box>
            </form>
        </Box>
    );
}
