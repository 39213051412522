import { useState } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { Box, IconButton } from "@mui/material";

import "./collectionFileFullScreen.css";
import CustomText from "../custom/texts/CustomText";
import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import { ReactComponent as ImageIcon } from "../../assets/icons/modals/image.svg";
import { ReactComponent as ZoomInIcon } from "../../assets/icons/modals/zoom-in.svg";
import { ReactComponent as ZoomOutIcon } from "../../assets/icons/modals/zoom-out.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/modals/download.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/modals/x.svg";

interface CollectionImageFullScreenProps {
  fileURL: string;
  fileName: string;
  setFullSreen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CollectionImageFullScreen(
  props: CollectionImageFullScreenProps
) {
  const { PP } = usePixelPerfect();
  const [zoomLevel, setZoomLevel] = useState<number>(100);

  const handleDownloadImage = () => {
    // Crear un enlace temporal
    const link = document.createElement("a");
    link.href = props.fileURL; // Establecer la URL de la imagen como el enlace de descarga
    link.download = props.fileName; // Establecer el nombre de archivo para la descarga

    // Adjuntar el enlace al cuerpo del documento (necesario para algunos navegadores)
    document.body.appendChild(link);

    // Simular un clic en el enlace para iniciar la descarga
    link.click();

    // Limpia el enlace temporal del DOM después de un breve período
    setTimeout(() => {
      document.body.removeChild(link);
    }, 100);
  };

  return (
    <TransformWrapper
      initialScale={1}
      initialPositionX={0}
      initialPositionY={0}
      minScale={0.3}
    >
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <div className="image-preview-modal">
          <div className="image-preview-header">
            <Box display="flex" flexDirection="row" alignItems="center">
              <ImageIcon style={{ width: PP(24), height: PP(24) }} />
              <CustomText> {props.fileName}</CustomText>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <IconButton
                onClick={() => {
                  zoomOut();
                  setZoomLevel((prevZoom) => Math.max(prevZoom - 10, 70)); // Disminuye el zoom en 10%
                }}
              >
                <ZoomOutIcon
                  style={{
                    width: PP(24),
                    height: PP(24),
                    marginRight: PP(24),
                  }}
                />
              </IconButton>
              <IconButton
                onClick={() => {
                  zoomIn();
                  setZoomLevel((prevZoom) => prevZoom + 10);
                }}
              >
                <ZoomInIcon
                  style={{
                    width: PP(24),
                    height: PP(24),
                    marginRight: PP(24),
                  }}
                />
              </IconButton>
              <CustomText>{zoomLevel}%</CustomText>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <IconButton onClick={handleDownloadImage}>
                <DownloadIcon
                  style={{
                    width: PP(24),
                    height: PP(24),
                    marginRight: PP(16),
                  }}
                />
              </IconButton>
              <IconButton onClick={() => props.setFullSreen(false)}>
                <CloseIcon style={{ width: PP(24), height: PP(24) }} />
              </IconButton>
            </Box>
          </div>
          <div className="image-preview-content">
            <TransformComponent>
              <img src={props.fileURL} alt="Imagen en vista previa" />
            </TransformComponent>
          </div>
        </div>
      )}
    </TransformWrapper>
  );
}