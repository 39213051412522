import { createSlice } from "@reduxjs/toolkit";

import { userAddressesListSliceType } from "../../../interfaces/redux/userAddressesListSliceInterface";
import { fetchUserAddressesList } from "../../thunks/users/userAddressesList";

const initialState: userAddressesListSliceType = {
  loading: false,
  status: {
    addresses: -1,
    createAddress: -1,
  },
  statusText: "",
  description: "",
  finalDescription: "",
  city: 1,
  coords: [null, null],
  list: [],
  page: 0,
  totalList: 0,
  selected: null,
};

export const addressesSlice = createSlice({
  name: "addressesReducer",
  initialState,
  reducers: {
    setCity: (state, action) => {
      state.city = action.payload;
    },
    setCoords: (state, action) => {
      state.coords = [action.payload.lng, action.payload.lat];
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setFinalDescription: (state, action) => {
      state.finalDescription = action.payload;
    },
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Get Addresses List
    builder.addCase(fetchUserAddressesList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUserAddressesList.fulfilled, (state, action) => {
      if (action.payload.data.data.length > 0 && state.selected === null) {
        state.selected = action.payload.data.data[0];
      }
      if (action.payload.data.data && action.payload.data.data.length > 0) {
        state.list = state.list.concat(action.payload.data.data);
      }
      state.totalList = action.payload.data.total;
      state.status.addresses = action.payload.status;
      state.statusText = action.payload.statusText;
      state.loading = false;
      state.page = state.page + 1;
    });
    builder.addCase(fetchUserAddressesList.rejected, (state, action) => {
      //   state.status.addresses = action.payload.status;
      //   state.statusText = action.payload.data;
      //   state.loading = false;
    });
  },
});

export const {
  setCity,
  setCoords,
  setDescription,
  setFinalDescription,
  setSelected,
  reset,
} = addressesSlice.actions;

export default addressesSlice.reducer;
