import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button,
  Card as MuiCard,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { useFormik } from "formik";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import {
  fetchBusiness,
  fetchBusinessAllPrices,
  fetchBusinessPrices,
  fetchCreateNewMaterialPrice,
  fetchDeleteMaterial,
} from "../../../../../redux/old/slices/businessSlicer";
import CurrencyInput from "../../../customized/old/CurrencyInput";
import "../../../../../utils/old/styles/currencyInput.css";
import { customPricesCardValidation } from "../../../../../validations/business";
import { ErrorText } from "../../../../errors";

const Spacer = styled.div(spacing);

const Card = styled(MuiCard)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

export default function CustomPricesCard() {
  const { id } = useParams();
  const { t } = useTranslation();

  const [newMaterialPriceModal, setNewMaterialPriceModal] = useState(false);
  const [snackTxt, setSnackTxt] = useState("hola");
  const [showSnack, setShowSnack] = useState(false);

  //ReduxTookit
  const loading = useSelector((state) => state.business.showLoading);
  const prices = useSelector((state) => state.business.prices);
  const allPrices = useSelector((state) => state.business.allPrices);

  const dispatch = useDispatch();

  useEffect(() => {
    getBusiness();
  }, [newMaterialPriceModal, showSnack]);

  const getBusiness = async () => {
    dispatch(fetchBusiness(id)); //ReduxTookit Get
    dispatch(fetchBusinessPrices(id)); //ReduxTookit Get//Precios personalizados
    dispatch(fetchBusinessAllPrices()); //ReduxTookit Get //Todos los precios...Para tomar los materiales para el Select del form para personalizar precios
  };

  //Manejando la edición de precios personalizados para materiales

  //------------------------Create-------------------------------------------------------------------------
  const initialValuesNewMaterialPrice = {
    price: "",
    materialId: "null",
  };

  const formikNewMaterialPrice = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: initialValuesNewMaterialPrice,
    validationSchema: customPricesCardValidation,
    onSubmit: async (data) => {
      createNewMaterialPrice(data);
    },
  });

  const createNewMaterialPrice = async (data) => {
    data.business_id = id;
    data.countryId = 1;
    data.price =
      typeof data.price !== "number"
        ? Number(data.price.replace(/[$,]/g, ""))
        : data.price;
    dispatch(fetchCreateNewMaterialPrice(data)); //ReduxTookit Post//Precio personalizado
    setNewMaterialPriceModal(false);
    setSnackTxt(t("Precio personalizado añadido"));
    setShowSnack(true);
    dispatch(fetchBusinessPrices(id)); //ReduxTookit Get//Precios personalizados

    formikNewMaterialPrice.resetForm();
  };

  //----------------------------Destroy--------------------------------------------------------------------

  const deleteMaterial = async (material, index) => {
    if (material.id) {
      dispatch(fetchDeleteMaterial(material)); //ReduxTookit Destroy
      setSnackTxt(t("Precio Personalizado Eliminado"));
      setShowSnack(true);
      dispatch(fetchBusinessPrices(id)); //ReduxTookit Get//Precios personalizado
    } else {
    }
  };

  const handleChangeInput = (name, value) => {
    formikNewMaterialPrice.setFieldValue(name, value);

    if (formikNewMaterialPrice.errors[name]) {
      formikNewMaterialPrice.setFieldError(name, "");
    }
  };

  return (
    <React.Fragment>
      <Card mb={6}>
        <CardContent>
          <Grid justifyContent="space-between" container spacing={10}>
            <Grid item>
              <Typography variant="h6" gutterBottom>
                {t("createNewCustomPriceModal.Precios")}
              </Typography>
              <Typography variant="h6" gutterBottom>
                {t("createNewCustomPriceModal.Personalizados")}
              </Typography>
            </Grid>
            <Grid item>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => setNewMaterialPriceModal(true)}
                >
                  <AddIcon />
                  {t("createNewCustomPriceModal.Nuevo")}
                </Button>
              </div>
            </Grid>
          </Grid>
          <Spacer mb={4} />
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("tables.tableHeadCells.Material")}</TableCell>
                  <TableCell>{t("tables.tableHeadCells.Precio")}</TableCell>
                  <TableCell>{t("tables.tableHeadCells.Acción")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {prices.map((row, index) => {
                  return (
                    <TableRow hover key={row.id}>
                      <TableCell>{row.material.name}</TableCell>
                      <TableCell>
                        ${row.price.toLocaleString("es-MX")}
                      </TableCell>
                      <TableCell align="left">
                        <IconButton
                          aria-label="delete"
                          size="large"
                          onClick={(e) => deleteMaterial(row, index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableWrapper>
          {prices.length === 0 && (
            <Box display="flex" justifyContent="center">
              <Typography style={{ fontSize: "16px", color: "gray" }}>
                {t("No posee precios personalizados")}
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>

      {/* -----------------------MODAL PRECIO PERSONALIZADO--------------------- */}

      <Dialog
        open={newMaterialPriceModal}
        onClose={() => setNewMaterialPriceModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <form noValidate onSubmit={formikNewMaterialPrice.handleSubmit}>
          <DialogTitle id="form-dialog-title">
            {t("createNewCustomPriceModal.Personalizar un precio")}
          </DialogTitle>
          <DialogContent>
            <Box style={{ color: "grey" }}>
              {t(
                "createNewCustomPriceModal.Este será el nuevo precio personalizado del"
              )}
            </Box>
            <Box style={{ color: "grey" }}>
              {t(
                "createNewCustomPriceModal.negocio para el material seleccionado"
              )}
            </Box>

            <br />
            <Grid item md={6} my={2}>
              <Typography style={{ fontSize: "11px" }} color="grey">
                {t("createNewCustomPriceModal.Material")}
              </Typography>
              <Select
                name="materialId"
                label={t("createNewCustomPriceModal.Material")}
                style={{ width: "20em" }}
                variant="outlined"
                my={2}
                value={formikNewMaterialPrice.values.materialId}
                onChange={(e) =>
                  handleChangeInput("materialId", e.target.value)
                }
                placeholder="Material"
              >
                <MenuItem value={"null"}>
                  {t("createNewCustomPriceModal.selectMaterial")}
                </MenuItem>

                {allPrices.map((element, index) => (
                  <MenuItem
                    key={element.material.id}
                    value={element.material.id}
                  >
                    {element.material.name}
                  </MenuItem>
                ))}
              </Select>
              {formikNewMaterialPrice.errors.materialId && (
                <ErrorText error={t("createNewCustomPriceModal.errorSelect")} />
              )}
            </Grid>
            <br />
            <Typography style={{ fontSize: "11px" }} color="grey">
              {t("createNewCustomPriceModal.Nuevo precio")}
            </Typography>
            <CurrencyInput
              name="price"
              label={t("createNewCustomPriceModal.Precio")}
              type="decimal"
              placeholder=" $0.00"
              variant="outlined"
              value={formikNewMaterialPrice.values.price}
              onChange={(e) => handleChangeInput("price", e.target.value)}
              className="currencyInputStyle"
              collectionDetail
              my={2}
            />
            {formikNewMaterialPrice.errors.price && (
              <ErrorText error={t("createNewCustomPriceModal.errorPrice")} />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setNewMaterialPriceModal(false)}
              color="primary"
            >
              {t("createNewCustomPriceModal.Cancelar")}
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {t("createNewCustomPriceModal.Actualizar")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* --------------------------------------------------------------- */}

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showSnack}
        autoHideDuration={6000}
        onClose={() => {
          setShowSnack(false);
        }}
        message={t(snackTxt)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                setShowSnack(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
