import { useState } from "react";
import { NavLink } from "react-router-dom";

import { Chip, IconButton, styled, TableCell, TableRow } from "@mui/material";

import { CustomTableRowType } from "../../../../interfaces/styles/customTableRowInterface";
import { ReactComponent as ActionIcon } from "../../../../assets/icons/tables/next.svg";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import { useTranslation } from "react-i18next";
import { TransactionType } from "../../../../interfaces/data/transactionInterface";

interface CustomTransactionsListTableRowProps {
  element: TransactionType;
  myKey: number;
}

const CustomTableRow = styled(TableRow)<CustomTableRowType>(({}) => ({}));

export default function CustomTransactionsListTableRow(
  props: CustomTransactionsListTableRowProps
) {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const { element, myKey } = props;

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <CustomTableRow
      key={myKey}
      variant="default"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ cursor: "default" }}
    >
      {element && (
        <>
          <TableCell style={{ color: "#212121", cursor: "default" }}>
            {element.buyerInfo.name}
          </TableCell>
          <TableCell style={{ color: "#212121", cursor: "default" }}>
            {element.extra?.loadDate}
          </TableCell>
          <TableCell style={{ cursor: "default" }}>
            #{element.consecutive}
          </TableCell>
          <TableCell style={{ cursor: "default" }}>
            {element.contract}
          </TableCell>
          <TableCell style={{ cursor: "default" }}>
            <Chip
              style={{
                padding: `${PP(4)} ${PP(8)}`,
                height: PP(28),
                width: "auto",
              }}
              // @ts-ignore
              variant={element.state}
              label={t(`chips.exportStates.${element.state}`)}
            />
          </TableCell>
          <TableCell align="right" style={{ cursor: "default" }}>
            {isHovered && (
              <IconButton
                aria-label="transactionDetails"
                component={NavLink}
                to={`/transactions/sales/detail/${element._id}`}
                size="large"
                style={{ borderRadius: PP(8) }}
              >
                <ActionIcon style={{ width: PP(24), height: PP(24) }} />
              </IconButton>
            )}
          </TableCell>
        </>
      )}
    </CustomTableRow>
  );
}
