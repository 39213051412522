import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TransferStatusModalKeys = any;

interface SetTransferModalStatusPayload {
  id: TransferStatusModalKeys;
  value: boolean;
}
const initialState = {
  editTransferStatus: false,
};

export const transferDetailsModalStatusSlice = createSlice({
  name: "transferEditStatusModalReducer",
  initialState,
  reducers: {
    setTransferModalStatus: (
      state: any,
      action: PayloadAction<SetTransferModalStatusPayload>
    ) => {
      // @ts-ignore
      state[action.payload.id] = action.payload.value;
    },
    reset: () => initialState,
  },
});

export const { setTransferModalStatus, reset } =
  transferDetailsModalStatusSlice.actions;

export default transferDetailsModalStatusSlice.reducer;
