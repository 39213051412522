import { useParams } from "react-router";

import { Box, Divider, IconButton } from "@mui/material";

import { ReactComponent as ArrowLeft } from "../../assets/icons/layouts/arrow-left.svg";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import CustomText from "../../components/custom/texts/CustomText";
import { useCallback, useEffect } from "react";
import { fetchCollectionDetails } from "../../redux/thunks/collections/collectionDetailsThunk";
import { useTranslation } from "react-i18next";

interface PageHistoryLayoutProps {
    children?: React.ReactNode;
}
export default function PageHistoryLayout(
    props: PageHistoryLayoutProps
) {

    const { id } = useParams();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { collectionDetails } = useAppSelector(
        (state) => state.collectionDetails
    );

    const { PP } = usePixelPerfect();

    const goBack = () => {
        window.history.back();
    };

    const getCollectionDetails = useCallback(async () => {
        if (id !== undefined) await dispatch(fetchCollectionDetails({ id: id }));
    }, [collectionDetails]);


    useEffect(() => {
        getCollectionDetails();
    }, [dispatch, id]);

    return (

        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: PP(8),
                }}
            >
                <IconButton
                    aria-label="comeBackToBusinessList"
                    onClick={goBack}
                    style={{ padding: "0px" }}
                >
                    <ArrowLeft style={{ width: PP(22), height: PP(22) }} />
                </IconButton>
                <CustomText color="#969696">&nbsp; {t("pageHistoryLayout.Volver a Recolección #{consecutivo}", { consecutive: collectionDetails.consecutive })}</CustomText>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: PP(14),
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <CustomText
                        premediumWeight
                        noSpacing
                        bigSize
                        color="#1C1C1C"
                        style={{
                            lineHeight: PP(32),
                            marginRight: PP(8.23),
                        }}
                    >
                        {t("pageHistoryLayout.Historial de la página")}
                    </CustomText>
                </Box>
            </Box>
            <Divider style={{ marginBottom: PP(24) }} />
            <>{props.children}</>
        </Box>
    );
}
