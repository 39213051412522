import { createSlice } from "@reduxjs/toolkit";

import { fetchDeleteCollectionAttachFile } from "../../thunks/collections/deleteCollectionAttachFileThunk";

const initialState = {
  fileSelectedToDelete: {
    id: -1,
    name: "",
    ownerType: "",
    ownerId: -1,
    type: "",
    description: "",
    updatedAt: "",
    createdAt: "",
    url: "",
  },
  loading: false,
  status: -1,
  statusText: "",
};

export const deleteCollectionAttachFileSlice = createSlice({
  name: "deleteCollectionAttachFileReducer",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = -1;
      state.statusText = "";
    },
    setFileSelectedToDelete: (state, action) => {
      state.fileSelectedToDelete = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Eliminar archivo adjunto seleccionado, de la recolección
    builder.addCase(fetchDeleteCollectionAttachFile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchDeleteCollectionAttachFile.fulfilled,
      (state, action) => {
        state.status = action.payload.status;
        state.statusText = action.payload.statusText;
        state.loading = false;
      }
    );
    builder.addCase(
      fetchDeleteCollectionAttachFile.rejected,
      (state, action) => {
        state.loading = false;
      }
    );
  },
});

export const { resetStatus, setFileSelectedToDelete, reset } =
  deleteCollectionAttachFileSlice.actions;

export default deleteCollectionAttachFileSlice.reducer;
