import { Box } from "@mui/material";

import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import { useAppSelector } from "../../redux/store";
import CustomText from "../custom/texts/CustomText";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../../utils/formats/capitalizeFirstLetter";

export default function CollectionAproWithoutSeparating() {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();

  const { collectionDetails } = useAppSelector(
    (state) => state.collectionDetails
  );

  return collectionDetails.state !== "failed" &&
    collectionDetails.state !== "canceled" ? (
    <></>
  ) : // <Box
  //   style={{
  //     border: "1px solid #E8E8E8",
  //     padding: `${PP(24)} ${PP(24)}`,
  //     width: "100%",
  //     marginBottom: PP(24),
  //     borderRadius: PP(8),
  //     background: "rgba(150, 150, 150, 0.06)",
  //   }}
  // >
  //   <Box width="100%">
  //     <CustomText
  //       superSmallSize
  //       color="#969696"
  //       noSpacing
  //       style={{ marginBottom: PP(8) }}
  //     >
  //       Propiedad
  //     </CustomText>
  //   </Box>
  //   <Box
  //     width="100%"
  //     display="flex"
  //     flexDirection="row"
  //     justifyContent="space-between"
  //   >
  //     <CustomText color="#4D4D4D" noSpacing>
  //       Aprovechables sin separar
  //     </CustomText>
  //     <CustomText color="#808080" noSpacing>
  //       200.30 kg
  //     </CustomText>
  //   </Box>
  // </Box>
  collectionDetails.state === "failed" ? (
    <Box
      style={{
        border: "1px solid #E8E8E8",
        padding: `${PP(24)} ${PP(24)}`,
        width: "100%",
        marginBottom: PP(24),
        borderRadius: PP(8),
        background: "rgba(212, 2, 44, 0.06)",
      }}
    >
      <Box width="100%">
        <CustomText
          superSmallSize
          color="#969696"
          noSpacing
          style={{ marginBottom: PP(8) }}
        >
          {t(
            "collectionRequestInformation.collectionAproWithoutSeparating.Motivo de fallida"
          )}
        </CustomText>
      </Box>
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <CustomText color="#4D4D4D" noSpacing>
          {collectionDetails.collectorObservation !== null
            ? t(
                `collectionDetailsLayout.customSelectCollectionFailedReasonModal.failedReasonsOptions.${capitalizeFirstLetter(
                  collectionDetails.collectorObservation
                )}`
              )
            : t(
                "collectionRequestInformation.collectionAproWithoutSeparating.No definido"
              )}
        </CustomText>
      </Box>
    </Box>
  ) : (
    <></>
  );
}
