import { createAsyncThunk } from "@reduxjs/toolkit";

import { destroyRedux } from "../../../utils/old/api";

export const fetchDeleteMaterialCollection = createAsyncThunk(
  "deleteMaterialCollection/fetchDelete",
  async (payload: { materialId: number }, thunkAPI) => {
    try {
      const response = await destroyRedux(
        `/collection-material/${payload.materialId}`
      );
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    } catch (error) {
      const errors = thunkAPI.rejectWithValue(error);
      console.log(error);
      return errors;
    }
  }
);
