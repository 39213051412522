import { useState } from "react";
import { NavLink } from "react-router-dom";

import {
  Box,
  Chip,
  IconButton,
  styled,
  TableCell,
  TableRow,
} from "@mui/material";

import { CollectionType } from "../../../../interfaces/data/collectionInterface";
import { CustomTableRowType } from "../../../../interfaces/styles/customTableRowInterface";
import { collectionsStates } from "../../../../utils/constants/collections/collectionsStates";
import { dateFormat } from "../../../../utils/formats/dateFormat";
import { ReactComponent as ActionIcon } from "../../../../assets/icons/tables/next.svg";
import { firstLetterCapitalizeFormat } from "../../../../utils/formats/firstLetterCapitalizeFormat";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import CustomText from "../../texts/CustomText";
import { useTranslation } from "react-i18next";

interface CollectionsTableRowProps {
  element: CollectionType;
  myKey: number;
  businessProfile?: boolean;
}

const CustomTableRow = styled(TableRow)<CustomTableRowType>(({ }) => ({}));

export default function CustomCollectionsTableRow(
  props: CollectionsTableRowProps
) {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const { element, myKey, businessProfile } = props;
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <CustomTableRow
      key={myKey}
      variant="default"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ cursor: "default" }}
    >
      {element && (
        <>
          <TableCell style={{ color: "#212121", cursor: "default" }}>
            #{element.consecutive}
          </TableCell>
          <TableCell style={{ cursor: "default" }}>
            {dateFormat(element.date, t)}
          </TableCell>
          {!businessProfile && (
            <TableCell style={{ cursor: "default" }}>
              {`${firstLetterCapitalizeFormat(element.owner.name)}`}
              {element.owner.last_name
                ? ` ${firstLetterCapitalizeFormat(element.owner.last_name)}`
                : ""}
            </TableCell>
          )}
          <TableCell style={{ cursor: "default" }}>
            {firstLetterCapitalizeFormat(element.city.name)}
          </TableCell>
          <TableCell style={{ cursor: "default" }}>
            <Box>
              <Box>
                {firstLetterCapitalizeFormat(element.addressDescription)}
              </Box>
              {isHovered && (
                <Box>
                  <CustomText color="#969696">
                    {firstLetterCapitalizeFormat(element.addressName)}
                  </CustomText>
                </Box>
              )}
            </Box>
          </TableCell>
          <TableCell style={{ cursor: "default" }}>
            <Chip
              style={{
                padding: `${PP(4)} ${PP(8)}`,
                height: PP(28),
                width: "auto",
              }}
              // @ts-ignore
              variant={element.state}
              label={t(`chips.states.${collectionsStates[element.state]}`)}
            />
          </TableCell>
          {businessProfile && (
            <TableCell style={{ cursor: "default" }}></TableCell>
          )}
          <TableCell align="right" style={{ cursor: "default" }}>
            {isHovered && (
              <IconButton
                aria-label="collectionDetails"
                component={NavLink}
                to={`/collections/detail/${element.id}`}
                size="large"
                style={{ borderRadius: PP(8) }}
              >
                <ActionIcon style={{ width: PP(24), height: PP(24) }} />
              </IconButton>
            )}
          </TableCell>
        </>
      )}
    </CustomTableRow>
  );
}
