import { createSlice } from "@reduxjs/toolkit";

import { collectionsListWarehouseFilterSliceType } from "../../../interfaces/redux/collectionsListWarehouseFilterSliceInterface";

const initialState: collectionsListWarehouseFilterSliceType = {
  citySelected: null,
  cityFilter: "",
  warehouseSelected: null,
  warehouseFilter: "",
};

export const collectionsListWarehouseFilterSlice = createSlice({
  name: "collectionsListWarehouseFilterReducer",
  initialState,
  reducers: {
    setCitySelected: (state, action) => {
      state.citySelected = action.payload;
    },
    setCityFilter: (state, action) => {
      state.cityFilter = action.payload;
    },
    setWarehouseSelected: (state, action) => {
      state.warehouseSelected = action.payload;
    },
    setWarehouseFilter: (state, action) => {
      state.warehouseFilter = action.payload;
    },
    setResetWarehouseSelected: (state) => {
      state.warehouseSelected = null;
    },
    resetWarehouseFilter: () => initialState,
  },
});

export const {
  setCitySelected,
  setCityFilter,
  setWarehouseSelected,
  setWarehouseFilter,
  resetWarehouseFilter,
  setResetWarehouseSelected,
} = collectionsListWarehouseFilterSlice.actions;

export default collectionsListWarehouseFilterSlice.reducer;
