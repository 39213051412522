import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import CustomButton from "../custom/buttons/default/CustomButton";
import CustomInput from "../custom/forms/default/CustomInput";
import CollectionSelectedAttachedFile from "./CollectionSelectedAttachedFile";
import { setCollectionAttachedFilesModalState } from "../../redux/slices/collections/collectionAttachedFilesModalStatesSlice";
import { fetchCollectionAttachNewFile } from "../../redux/thunks/collections/collectionAttachNewFileModalThunk";
import { setFormAttachNewFileState } from "../../redux/slices/collections/collectionAttachNewFileModalSlice";
import {
  setMessage,
  setOpenSnackbar,
  setType,
} from "../../redux/slices/globalSnackbar/globalSnackbarSlice";


export default function CollectionAttachNewFileForm() {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { fileType } = useAppSelector(
    (state) => state.collectionAttachNewFile.form
  );

  const { status } = useAppSelector((state) => state.collectionAttachNewFile);

  const { collectionDetails } = useAppSelector(
    (state) => state.collectionDetails
  );

  const [selectedFile, setSelectedFile] = useState<File | null>(null); // Maneja selectedFile localmente

  const resetForm = useCallback(async () => {
    dispatch(
      setCollectionAttachedFilesModalState({
        id: "attachFile",
        value: false,
      })
    );
  }, [dispatch]);

  const handleClickCloseModal = () => {
    resetForm();
  };

  const initialFormikValues = {
    Imagen1: null, // Inicialmente, no hay archivo seleccionado
    ownerType: "collection",
    ownerId: collectionDetails.id,
    type: fileType.type,
    description: "",
  };

  const handleSubmit = async () => {
    if (selectedFile) {
      dispatch(
        fetchCollectionAttachNewFile({
          data: selectedFile,
          ownerId: collectionDetails.id,
          type: fileType.type,
        })
      );
      handleClickCloseModal();
    }
  };

  const formikCollectionAttachNewFile = useFormik({
    initialValues: initialFormikValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validateOnChange: false,
  });

  // Actualiza selectedFile cuando el usuario selecciona un archivo
  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      setSelectedFile(selectedFile);
      dispatch(
        setFormAttachNewFileState({
          id: "selectedDocument",
          value: selectedFile.name,
        })
      );
    }
  };

  useEffect(() => {
    if (status === 201) {
      dispatch(setOpenSnackbar(true));
      dispatch(setMessage(t("snackbars.Cambios guardados exitosamente")));
      dispatch(setType("sucessSnackbar"));
    }
  }, [status]);

  return (
    <form noValidate onSubmit={formikCollectionAttachNewFile.handleSubmit}>
      <CustomInput
        id="type"
        label={t("collectionAttachedFiles.customAttachNewFileModal.collectionAttachNewFileForm.Tipo de documento")}
        placeholder={t("collectionAttachedFiles.customAttachNewFileModal.collectionAttachNewFileForm.Tipo de documento")}
        value={t(`collectionAttachedFiles.filesTypes.${fileType.type}`)}
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        inputHeight={PP(40)}
        inputBorderRadius={PP(8)}
        backgroundColor="#F6F6F6"
        extraStyle={{ marginTop: PP(24) }}
        disabled
      />

      <CollectionSelectedAttachedFile
        selectedFile={selectedFile}
        handleSelectFile={(e) => handleSelectFile(e)}
      />

      <CustomButton
        width={432}
        height={44}
        noSpacing
        style={{ padding: "0px", marginTop: PP(24) }}
        type="submit"
        label={t("collectionAttachedFiles.customAttachNewFileModal.collectionAttachNewFileForm.Guardar")}
        formik={formikCollectionAttachNewFile}
        disabled={selectedFile ? false : true} // Habilita el botón si hay un archivo seleccionado
      />
      <CustomButton
        noSpacing
        width={432}
        height={44}
        cancelButton
        onClick={handleClickCloseModal}
        formik={formikCollectionAttachNewFile}
      />
    </form>
  );
}
