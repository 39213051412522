import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { green, red, yellow } from "@mui/material/colors";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import {
  ArrowBackIosNewOutlined,
  FilterList as FilterListIcon,
} from "@mui/icons-material";

import CustomCircularProgress from "../../components/controlview/CustomCircularProgress";
import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import {
  fetchAssignedCollections,
  fetchCollectedCollections,
  fetchCollectorsList,
  fetchFailedCollections,
  fetchWarehouses,
  setWarehouseidFilter,
} from "../../redux/old/slices/collectionSlicer";
import { updateSelectedCollectorsId } from "../../redux/old/slices/googleMapsSlicer";
import { useNavigate } from "react-router-dom";

export default function ControlView() {
  const navigate = useNavigate();
  const {
    assignedCollections = 0,
    collectedCollections = 0,
    failedCollections = 0,
    collectorsList = [],
    warehouseIdFilter = 20,
    warehouses = [],
  } = useSelector((state) => state.collection);

  const dispatch = useDispatch();
  const { PP2 } = usePixelPerfect();

  const collections = [
    assignedCollections,
    collectedCollections,
    failedCollections,
  ];

  const total =
    collections.reduce((a, b) => a + b, 0) !== 0
      ? collections.reduce((a, b) => a + b, 0)
      : 0;

  const divider = total === 0 ? 1 : total;

  const data = [
    {
      label: "Asignadas",
      value: assignedCollections,
      percentage: Math.round((assignedCollections / divider) * 100),
      color: yellow[500],
    },
    {
      label: "Recolectadas",
      value: collectedCollections,
      percentage: Math.round((collectedCollections / divider) * 100),
      color: green[400],
    },
    {
      label: "Fallidas",
      value: failedCollections,
      percentage: Math.round((failedCollections / divider) * 100),
      color: red[400],
    },
  ];

  const today = new Date();
  const currentMinutes = today.getMinutes();
  const roundedMinutes = Math.floor(currentMinutes / 5) * 5;
  today.setMinutes(roundedMinutes);
  const dateFilter = today.toISOString().split("T")[0];

  const [lastUpdateTime, setLastUpdateTime] = useState(new Date());
  const [selectWarehouseModal, setSelectWarehouseModal] = useState(false);
  const [currentHour, setCurrentHour] = useState(
    lastUpdateTime.toLocaleTimeString("es-CO", {
      timeZone: "America/Bogota",
      hour: "numeric",
      minute: "numeric",
    })
  );

  const fetchData = async () => {
    await dispatch(
      fetchAssignedCollections({
        dateFilter: dateFilter,
        warehouseId: warehouseIdFilter,
      })
    );
    await dispatch(
      fetchCollectedCollections({
        dateFilter: dateFilter,
        warehouseId: warehouseIdFilter,
      })
    );
    await dispatch(
      fetchFailedCollections({
        dateFilter: dateFilter,
        warehouseId: warehouseIdFilter,
      })
    );
    setLastUpdateTime(new Date());
  };

  const getCollectorsList = useCallback(async () => {
    if (collectorsList.length !== 0) {
      const allCollectorsIdArray = collectorsList.map(
        (collectorInfo) => collectorInfo.id
      );

      dispatch(updateSelectedCollectorsId(allCollectorsIdArray));
    }
  }, [dispatch, collectorsList]);

  useEffect(() => {
    dispatch(fetchCollectorsList());
  }, [dispatch]);

  useEffect(() => {
    getCollectorsList();
  }, [getCollectorsList]);

  useEffect(() => {
    fetchData();
  }, [warehouseIdFilter]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
      const currentMinutes = new Date().getMinutes();
      const roundedMinutes = Math.floor(currentMinutes / 5) * 5;
      const currentHour = new Date();
      currentHour.setMinutes(roundedMinutes);
      setCurrentHour(
        currentHour.toLocaleTimeString("es-CO", {
          timeZone: "America/Bogota",
          hour: "numeric",
          minute: "numeric",
        })
      );
    }, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, [lastUpdateTime]);

  const getWarehouses = async () => {
    dispatch(fetchWarehouses());
  };

  useEffect(() => {
    getWarehouses();
  }, []);

  const updateWareHouseIdFilter = (data) => {
    dispatch(setWarehouseidFilter(data.warehouseId));
    setSelectWarehouseModal(false);
  };

  const initialValue = {
    warehouseId: warehouseIdFilter,
  };

  const formikWarehouseId = useFormik({
    initialValues: initialValue,
    enableReinitialize: true,
    onSubmit: (warehouseId) => {
      updateWareHouseIdFilter(warehouseId);
    },
  });

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Button
        size="small"
        style={{ backgroundColor: "#233043", marginBottom: 20 }}
        onClick={() => {
          navigate("/");
        }}
      >
        <ArrowBackIosNewOutlined color="#898F99" />
      </Button>
      <Typography
        style={{ fontWeight: 700, fontSize: PP2(20), textAlign: "center" }}
      >
        CONTROL DE RECOLECCIONES
      </Typography>

      <Grid container spacing={3} style={{ marginTop: PP2(10), width: "100%" }}>
        <Grid item xs={12}>
          <Card elevation={3}>
            <CardContent>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography style={{ fontWeight: 500, fontSize: PP2(20) }}>
                  Fecha: {dateFilter}
                </Typography>
                <Typography style={{ fontWeight: 500, fontSize: PP2(20) }}>
                  Última actualización: {currentHour}
                </Typography>
                <IconButton
                  aria-label="Filter list"
                  size="large"
                  onClick={() => setSelectWarehouseModal(true)}
                >
                  <FilterListIcon style={{ width: PP2(30), height: PP2(30) }} />
                </IconButton>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        {data.map((collectionInfo, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card elevation={3}>
              <CardHeader
                title={collectionInfo.label}
                style={{
                  textAlign: "center",
                  color: collectionInfo.color,
                  fontWeight: 700,
                }}
              />
              <CardContent>
                <CustomCircularProgress
                  title={collectionInfo.label}
                  percentage={collectionInfo.percentage}
                  quantity={collectionInfo.value}
                  lastUpdateTime={lastUpdateTime}
                  total={total}
                />
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Dialog
        open={selectWarehouseModal}
        onClose={() => setSelectWarehouseModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={formikWarehouseId.handleSubmit}>
          <DialogTitle id="form-dialog-warehouse">
            Seleccionar bodega
          </DialogTitle>
          <DialogContent>
            <Box style={{ color: "grey" }}>
              Mutante, al filtrar, las métricas que se mostrarán en pantalla
              serán únicamente respecto a la bodega seleccionada
            </Box>
            <FormControl style={{ marginTop: 16, width: "100%" }}>
              <InputLabel id="warehouse">Bodega</InputLabel>
              <Select
                labelId="select-warehouse"
                name="warehouseId"
                value={formikWarehouseId.values.warehouseId}
                onChange={formikWarehouseId.handleChange}
                label="Bodega"
                required
              >
                <MenuItem value={-1} disabled>
                  <em>Seleccionar bodega</em>
                </MenuItem>
                {warehouses.map((warehouse, index) => (
                  <MenuItem key={index} value={warehouse._id}>
                    {warehouse._source.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSelectWarehouseModal(false)}>
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              Filtrar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}
