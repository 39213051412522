import { forwardRef } from "react";

import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import usePixelPerfect from "../../../../../hooks/old/usePixelPerfect";
import DefaultModalLayout from "../../../../../layouts/collections/modals/DefaultModalLayout";
import CustomText from "../../../texts/CustomText";
import CreateTransactionForm from "../../../../transactions/CreateTransactionForm";
import CreateTransferForm from "../../../../inventory/transfers/CreateTransferForm";


interface CustomCreateTransferModalProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    getTransferList: () => Promise<void>;
}

export default forwardRef(function CustomCreateTransferModal(
    props: CustomCreateTransferModalProps
) {
    const { setOpen, getTransferList } = props;
    const { PP2 } = usePixelPerfect();
    const { t } = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <DefaultModalLayout handleClose={handleClose}>
            <CustomText
                noSpacing
                bigSize
                premediumWeight
                color="#1C1C1C"
                style={{ marginBottom: PP2(24) }}
            // onClick={resetForm}
            >
                {t("Crear transferencia")}
            </CustomText>
            <Divider sx={{ margin: "0px" }} />
            <CreateTransferForm
                setOpen={setOpen}
                getTransferList={getTransferList}
            />
        </DefaultModalLayout>
    );
});
