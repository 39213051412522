import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { Box, Divider, Paper } from "@mui/material";

import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import CustomText from "../custom/texts/CustomText";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { transactionDetailsTableHeadCells } from "../../utils/constants/transactions/transactionDetailsTableHeadCells";
import CustomCompleteTableNoPagination from "../custom/tables/default/CustomCompleteTableNoPagination";
import { setTransactionMaterialsModalState } from "../../redux/slices/transactions/transactionMaterialsModalsStatesSlice";
import CustomTransactionMaterialsTableRow from "../custom/tables/sales/CustomTransactionMaterialsTableRow";

export default function TransactionMaterialsInfo() {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { transactionDetails, status } = useAppSelector(
    (state) => state.transactionDetails
  );

  useEffect(() => { }, [transactionDetails, dispatch]);

  return (
    <Box marginTop={PP(24)}>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CustomText
          noSpacing
          premediumWeight
          color="#1C1C1C"
          style={{ fontSize: PP(20) }}
        >
          {t("Materiales")}
        </CustomText>
      </Box>

      <Divider style={{ marginTop: PP(16), marginBottom: PP(16) }} />
      <Box display="flex">
        <Box flex={1}>
          <CustomCompleteTableNoPagination
            status={status}
            tableHeadCells={transactionDetailsTableHeadCells} //Encabezados de la tabla
            children={transactionDetails.materials.map((element, key) => {
              return (
                <CustomTransactionMaterialsTableRow
                  element={element}
                  key={key}
                  myKey={key}
                />
              );
            })}
          />
          {transactionDetails.state === "open" && (
            <Box>
              <Paper
                style={{
                  border: `${PP(3)} dashed #557CE6`,
                  padding: `${PP(8)} ${PP(16)}`,
                  width: "auto",
                  height: "auto",
                  marginBottom: PP(16),
                  borderRadius: PP(8),
                  justifyContent: "center",
                  background: "rgba(85, 124, 230,0.1)",
                }}
              >
                <CustomText
                  noSpacing
                  bluePlusButton
                  mediumWeight
                  color="#557CE6"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    dispatch(
                      setTransactionMaterialsModalState({
                        id: "selectCategory",
                        value: true,
                      })
                    )
                  }
                >
                  {t("Añadir material")}
                </CustomText>
              </Paper>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
