import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.bubble.css";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";

import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Link,
  Typography as MuiTypography,
} from "@mui/material";

import { spacing } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import BasicPersonDataCard from "../../../../../components/old/users/old/individuals/BasicPersonDataCard";
import AddressCard from "../../../../../components/old/users/old/individuals/AddressCard";
import CollectionsCard from "../../../../../components/old/users/old/individuals/CollectionsCard";
import CustomPricesCard from "../../../../../components/old/users/old/individuals/CustomPricesCard";
import WithdrawalsCard from "../../../../../components/old/users/old/individuals/WithdrawalsCard";
import { fetchPerson } from "../../../../../redux/old/slices/individualsSlicer";
import BanksAccountsCard from "../../../../../components/old/users/old/individuals/BanksAccountsCard";
import CertificatesCard from "../../../../../components/old/users/old/individuals/CertificatesCard";
import RecurrentCollectionCard from "../../../../../components/old/users/old/individuals/RecurrentCollectionCard";
import UcoCertificatesCard from "../../../../../components/old/users/old/individuals/UcoCertificatesCard";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Typography = styled(MuiTypography)(spacing);

export default function Profile() {
  const loading = useSelector((state) => state.person.showLoading);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchPerson(id));
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Persona" />
      <Typography variant="h3" gutterBottom display="inline">
        {t("Perfil")}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/users/individuals">
          {t("Personas")}
        </Link>
        <Typography>{t("Perfil")}</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {loading && <LinearProgress my={2} />}

      <Grid container spacing={6}>
        <Grid item xs={12} lg={4} xl={3}>
          <BasicPersonDataCard />{" "}
          {/* Manejando info de la persona- Card con datos */}
          <CustomPricesCard />
          {/* Manejo de precios Personalizados Perfil de la persona */}
          <BanksAccountsCard />
          {/* Manejo de cuentas bancarias Perfil de la persona */}
        </Grid>
        <Grid item xs={12} lg={8} xl={9}>
          <AddressCard /> {/* Manejando direcciones de la persona- Card */}
          <CertificatesCard /> {/* Manejo de direcciones de la persona */}
          <UcoCertificatesCard />
          {/* Manejo de certificados de UCO de la persona */}
          <CollectionsCard />
          {/* Manejando recolecciones de la persona- Card */}
          <RecurrentCollectionCard />
          {/* Manejo de recolecciones recurrentes Perfil del Negocio */}
          <WithdrawalsCard /> {/* Manejo de retiros Perfil de la persona */}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
