import { createSlice } from "@reduxjs/toolkit";

import { CollectorsListType } from "../../../interfaces/redux/collectorsListInterface";
import { fetchCollectorsList } from "../../thunks/collectors/collectorsListThunk";

const initialState: CollectorsListType = {
  status: "idle",
  collectorsList: [],
};

export const collectorsListSlice = createSlice({
  name: "collectionDetails",
  initialState,
  reducers: {
    resetCollectorsList: () => initialState,
  },
  extraReducers: (builder) => {
    //Get lista de recolectores
    builder.addCase(fetchCollectorsList.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchCollectorsList.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.collectorsList = action.payload;
    });
    builder.addCase(fetchCollectorsList.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export const { resetCollectorsList } = collectorsListSlice.actions;

export default collectorsListSlice.reducer;
