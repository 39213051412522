import { createSlice } from "@reduxjs/toolkit";
import { fetchDeleteMaterialCollection } from "../../thunks/collections/deleteMaterialCollection";

const initialState = {
  selectedToDeleteMaterialName: "",
  selectedToDeleteMaterialId: -1,
  loading: false,
  status: -1,
  statusText: "",
};

export const deleteMaterialCollectionSlice = createSlice({
  name: "deleteMaterialCollectionReducer",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = -1;
      state.statusText = "";
    },
    setSelectedToDeleteMaterialId: (state, action) => {
      state.selectedToDeleteMaterialId = action.payload;
    },
    setSelectedToDeleteMaterialName: (state, action) => {
      state.selectedToDeleteMaterialName = action.payload;
    },

    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Eliminar material seleccionado de recolección
    builder.addCase(fetchDeleteMaterialCollection.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchDeleteMaterialCollection.fulfilled,
      (state, action) => {
        state.status = action.payload.status;
        state.statusText = action.payload.statusText;
        state.loading = false;
      }
    );
    builder.addCase(fetchDeleteMaterialCollection.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const {
  resetStatus,
  reset,
  setSelectedToDeleteMaterialId,
  setSelectedToDeleteMaterialName,
} = deleteMaterialCollectionSlice.actions;

export default deleteMaterialCollectionSlice.reducer;
