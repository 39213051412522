import { tableHeadCellType } from "../../../interfaces/data/tableHeadCellInterface";

export const verificationTableHeadCells: tableHeadCellType[] = [
  {
    id: "name",
    alignment: "left",
    label: "Recolector",
    disablePadding: false,
  },
  {
    id: "state",
    alignment: "left",
    label: "Estado",
    disablePadding: false,
  },
  {
    id: "action",
    alignment: "right",
    label: "Acciones",
    disablePadding: false,
  },
];

export const verificationCollectionTableHeadCells: tableHeadCellType[] = [
  {
    id: "name",
    alignment: "left",
    label: "Generador",
    disablePadding: false,
  },
  {
    id: "city",
    alignment: "left",
    label: "Ciudad",
    disablePadding: false,
  },
  {
    id: "addressDescription",
    alignment: "left",
    label: "Punto de recoleccion",
    disablePadding: false,
  },
  {
    id: "action",
    alignment: "right",
    label: "Acciones",
    disablePadding: false,
  },
];

export const verificationMaterialsTableHeadCells: tableHeadCellType[] = [
  {
    id: "material",
    alignment: "left",
    label: "Material",
    disablePadding: false,
  },
  {
    id: "quantity",
    alignment: "left",
    label: "Cantidad registrada",
    disablePadding: false,
  },
  {
    id: "addressDescription",
    alignment: "left",
    label: "Cantidad verificada",
    disablePadding: false,
  },
];
