import { customMiuDivider } from "./customStylesMuiComponents/customMuiDivider";
import { customMiuPaper } from "./customStylesMuiComponents/customMuiPaper";
import { customMiuTableCell } from "./customStylesMuiComponents/customMuiTableCell";
import { customMuiTablePagination } from "./customStylesMuiComponents/customMuiTablePagination";
import { customMuiButton } from "./customStylesMuiComponents/customMuiButton";
import { customMuiButtonBase } from "./customStylesMuiComponents/customMuiButtonBase";
import { customMuiCard } from "./customStylesMuiComponents/customMuiCard";
import { customMuiCardHeader } from "./customStylesMuiComponents/customMuiCardHeader";
import { customMuiChip } from "./customStylesMuiComponents/customMuiChip";
import { customMuiPickerDTHeader } from "./customStylesMuiComponents/customMuiPickerDTHeader";
import { customMuiPickersClock } from "./customStylesMuiComponents/customMuiPickersClock";
import { customMuiPickersClockNumber } from "./customStylesMuiComponents/customMuiPickersClockNumber";
import { customMuiPickersTimePicker } from "./customStylesMuiComponents/customMuiPickersTimePicker";
import { customMuiPickersToolbar } from "./customStylesMuiComponents/customMuiPickersToolbar";
import { customMuiTable } from "./customStylesMuiComponents/customMuiTable";
import { customMuiTableRow } from "./customStylesMuiComponents/customMuiTableRow";
import { customMuiOutlinedInput } from "./customStylesMuiComponents/customMuiOutlinedInput";
// import { customMuiCalendarPicker } from "./customStylesMuiComponents/customMuiCalendarPicker";
// import { customMuiPickersYear } from "./customStylesMuiComponents/customMuiPickersYear";
// import { customMuiPickersDay } from "./customStylesMuiComponents/customMuiPickersDay";
// import { customMuiPickersCalendar } from "./customStylesMuiComponents/customMuiPickersCalendar";
// import { customMuiPickersCalendarHeader } from "./customStylesMuiComponents/customMuiPickersCalendarHeader";

const allStylesComponents: {} = {
  MuiButtonBase: customMuiButtonBase,
  MuiButton: customMuiButton,
  MuiCardHeader: customMuiCardHeader,
  MuiCard: customMuiCard,
  MuiChip: customMuiChip,
  MuiDivider: customMiuDivider,
  MuiLink: customMuiCardHeader,
  MuiOutlinedInput: customMuiOutlinedInput,
  MuiPaper: customMiuPaper,
  // MuiPickersDay: customMuiPickersDay,
  // MuiPickersYear: customMuiPickersYear,
  // MuiPickersCalendar: customMuiPickersCalendar,
  // MuiPickersCalendarHeader: customMuiPickersCalendarHeader,
  // MuiCalendarPicker: customMuiCalendarPicker,
  MuiPickersClock: customMuiPickersClock,
  MuiPickersClockNumber: customMuiPickersClockNumber,
  MuiPickerDTHeader: customMuiPickerDTHeader,
  MuiPickersTimePicker: customMuiPickersTimePicker,
  MuiPickersToolbar: customMuiPickersToolbar,
  MuiTable: customMuiTable,
  MuiTableRow: customMuiTableRow,
  MuiTableCell: customMiuTableCell,
  MuiTablePagination: customMuiTablePagination,
};

export default allStylesComponents;
