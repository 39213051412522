import { useState, useEffect } from "react";
import { Box, IconButton } from "@mui/material";
import { ReactComponent as DownloadIcon } from "../../assets/icons/modals/download.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/modals/x.svg";
import * as XLSX from 'xlsx';
import CustomText from "../custom/texts/CustomText";
import './excelFullScreen.css'; // Asegúrate de importar tu archivo CSS

interface ExcelFullScreenProps {
    fileURL: string;
    fileName: string;
    setFullSreen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ExcelFullScreen(props: ExcelFullScreenProps) {
    const [tableData, setTableData] = useState<any[][]>([]);
    const [error, setError] = useState<string | null>(null);

    // Fetch and process the Excel file
    const fetchAndProcessExcelFile = async () => {
        try {
            const response = await fetch(props.fileURL);
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const arrayBuffer = await response.arrayBuffer();
            const workbook = XLSX.read(arrayBuffer, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            //@ts-ignore
            const tableData: any[][] = jsonData;
            setTableData(tableData);

        } catch (error) {
            setError("Error processing Excel file");
            console.error("Error processing Excel file:", error);
        }
    };

    useEffect(() => {
        fetchAndProcessExcelFile();
    }, [props.fileURL]);

    const handleDownloadExcel = () => {
        const link = document.createElement("a");
        link.href = props.fileURL;
        link.download = props.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="image-preview-modal">
            <div className="image-preview-header">
                <Box display="flex" flexDirection="row" alignItems="center">
                    <CustomText>{props.fileName}</CustomText>
                </Box>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <IconButton onClick={handleDownloadExcel}>
                        <DownloadIcon
                            style={{ width: 24, height: 24, marginRight: 16 }}
                        />
                    </IconButton>
                    <IconButton onClick={() => props.setFullSreen(false)}>
                        <CloseIcon style={{ width: 24, height: 24 }} />
                    </IconButton>
                </Box>
            </div>
            <div className="excel-preview-content">
                {error ? (
                    <div className="error-message">{error}</div>
                ) : (
                    <table className="excel-table">
                        <thead>
                            <tr>
                                {tableData[0]?.map((header, index) => (
                                    <th key={index}>{header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.slice(1).map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => (
                                        <td key={cellIndex}>{cell}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
}
