import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";

import { getRedux } from "../../../utils/old/api";

const formatDate = (date: Date) => {
  return moment(date.toString()).format("YYYY-MM-DD");
};

export const fetchTransactionsList = createAsyncThunk(
  "transactionsList/fetchGet",
  async (payload: { page?: number; filter?: string }, thunkAPI) => {
    const page = payload.page ? payload.page : 0;
    try {
      const response = await getRedux(
        `/transaction?admin=true&type=seller&$limit=50&$skip=${
          page * 50
        }&$sort[extra.loadDate]=-1&${payload.filter ? payload.filter : ``}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
