import { createAsyncThunk } from "@reduxjs/toolkit";

import { postRedux } from "../../../../utils/old/api";

export const fetchCreateTransformation = createAsyncThunk(
  "createTransformation/fetchPost",
  async (
    payload: {
      warehouseId: number;
    },
    thunkAPI
  ) => {
    try {
      const response = await postRedux("/transform", payload);
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
        transformId: response.data.id,
      };
    } catch (error) {
      const errors = thunkAPI.rejectWithValue(error);
      return errors;
    }
  }
);
