import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TransactionMaterialsModalSliceType } from "../../../interfaces/redux/transactionMaterialsModalSliceInterface";

type TransactionMaterialsModalKeys = any;

interface SetTransactionModalStatePayload {
  id: TransactionMaterialsModalKeys;
  value: boolean;
}
const initialState: TransactionMaterialsModalSliceType = {
  selectCategory: false,
  addMaterial: false,
  selectMaterial: false,
  deleteMaterial: false,
  editQuantity: false,
};

export const transactionMaterialsModalsStatesSlice = createSlice({
  name: "transactionMaterialsModalsStatesReducer",
  initialState,
  reducers: {
    setTransactionMaterialsModalState: (
      state: any,
      action: PayloadAction<SetTransactionModalStatePayload>
    ) => {
      // @ts-ignore
      state[action.payload.id] = action.payload.value;
    },
    reset: () => initialState,
  },
});

export const { setTransactionMaterialsModalState, reset } =
  transactionMaterialsModalsStatesSlice.actions;

export default transactionMaterialsModalsStatesSlice.reducer;
