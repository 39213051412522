import { Box } from "@mui/material";

import CustomText from "../../texts/CustomText";
import { ReactComponent as GreyXIcon } from "../../../../assets/icons/chips/greyX.svg";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { setCollectionsFilter } from "../../../../redux/slices/collections/collectionListSlice";
import { resetCollectionsListOtherFiltersBy } from "../../../../redux/slices/collections/collectionsListOtherFiltersBySlice";
import { useTranslation } from "react-i18next";

export default function CustomDeleteAllAppliedFiltersChip() {
  const { PP } = usePixelPerfect();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { warehouseFilter } = useAppSelector(
    (state) => state.collectionsListWarehouseFilter
  );

  const removeFilters = () => {
    dispatch(setCollectionsFilter(warehouseFilter));
    dispatch(resetCollectionsListOtherFiltersBy());
  };
  return (
    <Box
      display="flex"
      flexDirection="row"
      height={PP(28)}
      padding={`${PP(8)} ${PP(16)}`}
      marginRight={PP(8)}
      marginBottom={PP(16)}
      alignItems="center"
    >
      <Box style={{ cursor: "pointer" }} onClick={() => removeFilters()}>
        <GreyXIcon
          style={{ width: PP(24), height: PP(24), marginRight: PP(8) }}
        />
      </Box>
      <Box>
        <CustomText noSpacing color="#969696" mediumWeight>
          {t("filterControl.customDeleteAllAppliedFiltersChip.Quitar filtros")}
        </CustomText>
      </Box>
    </Box>
  );
}
