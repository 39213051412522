import { createSlice } from "@reduxjs/toolkit";
import { fetchDeleteUserCustomPrice } from "../../thunks/users/deleteUserCustomPriceThunk";

const initialState = {
  selectedToDeleteMaterialCustomPriceName: "",
  selectedToDeleteMaterialCustomPriceId: -1,
  loading: false,
  status: -1,
  statusText: "",
};

export const deleteUserCustomPriceSlice = createSlice({
  name: "deleteUserCustomPriceReducer",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = -1;
      state.statusText = "";
    },
    setSelectedToDeleteMaterialCustomPriceId: (state, action) => {
      state.selectedToDeleteMaterialCustomPriceId = action.payload;
    },
    setSelectedToDeleteMaterialCustomPriceName: (state, action) => {
      state.selectedToDeleteMaterialCustomPriceName = action.payload;
    },

    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Eliminar precio personalizado de material seleccionado de usuario específico
    builder.addCase(fetchDeleteUserCustomPrice.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDeleteUserCustomPrice.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.statusText = action.payload.statusText;
      state.loading = false;
    });
    builder.addCase(fetchDeleteUserCustomPrice.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const {
  resetStatus,
  reset,
  setSelectedToDeleteMaterialCustomPriceId,
  setSelectedToDeleteMaterialCustomPriceName,
} = deleteUserCustomPriceSlice.actions;

export default deleteUserCustomPriceSlice.reducer;
