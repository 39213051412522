import { createAsyncThunk } from "@reduxjs/toolkit";

import { postRedux } from "../../../../utils/old/api";

export const fetchChangePassword = createAsyncThunk(
  "changePassword/fetchPost",
  async (payload: { oldPassword: string; newPassword: string }, thunkAPI) => {
    try {
      const response = await postRedux("/user/updatePassword", payload);
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    } catch (error) {
      const errors = thunkAPI.rejectWithValue(error);
      return errors;
    }
  }
);
