import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";

import "moment/locale/es";
import moment from "moment-timezone";

import styled from "@emotion/styled";
import {
  Avatar as MuiAvatar,
  Box,
  Button,
  // Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  // TableSortLabel,
  // Toolbar,
  // Tooltip,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  TablePagination,
} from "@mui/material";
import { green, orange, red } from "@mui/material/colors";
import {
  // Archive as ArchiveIcon,
  // FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import "moment/locale/es";

import { BankList } from "../../../../utils/old/bankList";
import {
  fetchUpdateWithdrawal,
  fetchWithdrawalsList,
  setOpen,
  setPageWithdrawals,
} from "../../../../redux/old/slices/withdrawalSlicer";
import { dateFormat } from "../../../../utils/formats/dateFormat";
import { useTranslation } from "react-i18next";
import { documentTypeOptionsObject } from "../../../../utils/old/constants";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};
  background: ${(props) => props.pending && orange[700]};
  background: ${(props) => props.approved && green[500]};
  background: ${(props) => props.rejected && red[400]};
  color: ${(props) => props.pending && props.theme.palette.common.white};
`;

// const Spacer = styled.div`
//   flex: 1 1 100%;
// `;

// const ToolbarTitle = styled.div`
//   min-width: 150px;
// `;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

// function createData(
//   customer,
//   customerEmail,
//   customerAvatar,
//   status,
//   amount,
//   id,
//   date
// ) {
//   return { customer, customerEmail, customerAvatar, status, amount, id, date };
// }

const headCells = [
  { id: "customer", alignment: "left", label: "Cliente" },
  { id: "bank", alignment: "left", label: "Banco" },
  { id: "account", alignment: "left", label: "Cuenta" },
  { id: "accountType", alignment: "center", label: "Tipo" },
  { id: "amount", alignment: "right", label: "Monto" },
  { id: "date", alignment: "right", label: "Fecha de Solicitud" },
  { id: "state", alignment: "center", label: "Estado" },
  { id: "actions", alignment: "right", label: "Acciones" },
];

const EnhancedTableHead = (props) => {
  const { t } = useTranslation();
  const {
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            > */}
            {t(`tables.tableHeadCells.${headCell.label}`)}
            {/* </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <>
      {/* <Toolbar> */}
      {/* <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Retiros
          </Typography>
        )}
      </ToolbarTitle> */}
      {/* <Spacer /> */}
      {/* <div>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete" size="large">
              <ArchiveIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list" size="large">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div> */}
      {/* </Toolbar> */}
    </>
  );
};

function EnhancedTable(props) {
  const { t } = useTranslation();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("date");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [modalEdit, setModalEdit] = React.useState(false);
  const [selectedWithdrawal, setSelectedWithdrawal] = React.useState({
    owner: { name: "" },
  });

  const isSelected = (id) => selected.indexOf(id) !== -1;

  //ReduxToolkit
  const rows = useSelector((state) => state.withdrawals.rows);
  const open = useSelector((state) => state.withdrawals.open);
  const snackTxt = useSelector((state) => state.withdrawals.snackTxt);
  const pageWithdrawals = useSelector(
    (state) => state.withdrawals.pageWithdrawals
  );
  const totalRowsWithdrawals = useSelector(
    (state) => state.withdrawals.totalRowsWithdrawals
  );

  const dispatch = useDispatch();

  const rowsManageWithdrawal = [
    {
      label: t("manageWithdrawalListModal.Tipo de cuenta"),
      value:
        selectedWithdrawal.accountType === "savings"
          ? t("CUENTA DE AHORROS")
          : t("CUENTA CORRIENTE"),
    },
    {
      label: t("manageWithdrawalListModal.Nombre comercial"),
      value: selectedWithdrawal.owner.name,
    },
    {
      label: t("manageWithdrawalListModal.Tipo de documento"),
      value: documentTypeOptionsObject[selectedWithdrawal.owner.document_type],
    },
    {
      label: t("manageWithdrawalListModal.Número de documento"),
      value: selectedWithdrawal.owner.document_number,
    },
    {
      label: t("manageWithdrawalListModal.Correo"),
      value: selectedWithdrawal.owner.email,
    },
    {
      label: t("manageWithdrawalListModal.Celular"),
      value: selectedWithdrawal.owner.phone,
    },
    {
      label: t("manageWithdrawalListModal.Banco"),
      value: BankList[selectedWithdrawal.bank],
    },
    {
      label: t("manageWithdrawalListModal.Monto"),
      value: selectedWithdrawal.amount,
    },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setOpen(false));
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  useEffect(() => {
    getWithdrawals();
  }, [open]);

  const getWithdrawals = async (cPage = 0) => {
    dispatch(fetchWithdrawalsList(cPage)); //Redux Get
    dispatch(setPageWithdrawals(cPage)); //ReduxToolkit reducer
  };

  const handleChangePageWithdrawals = async (event, newPage) => {
    dispatch(fetchWithdrawalsList(newPage));
    dispatch(setPageWithdrawals(newPage));
  };

  const handleChangeRowsPerWithdrawalsPage = async (cPage = 0) => {
    dispatch(fetchWithdrawalsList(cPage));
  };

  const updateWithdrawal = async (state) => {
    dispatch(
      fetchUpdateWithdrawal({
        state: state,
        selectedWithdrawal: selectedWithdrawal,
      })
    );
    setModalEdit(false);
    getWithdrawals();
  };

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={`${row.id}-${index}`}
                    selected={isItemSelected}
                  >
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                        onClick={(event) => handleClick(event, row.id)}
                      />
                    </TableCell> */}
                    <TableCell id={labelId}>
                      <Customer>
                        <Avatar>{row.owner.name[0].toUpperCase()}</Avatar>
                        {row.ownerType === "business" && (
                          <Box ml={3}>{row.owner.name}</Box>
                        )}
                        {row.ownerType === "user" && (
                          <Box
                            ml={3}
                          >{`${row.owner.name} ${row.owner.last_name}`}</Box>
                        )}
                      </Customer>
                    </TableCell>
                    <TableCell align="left">{BankList[row.bank]}</TableCell>
                    <TableCell align="left">{row.number}</TableCell>
                    {row.accountType === "checking" && (
                      <TableCell>{t("CUENTA CORRIENTE")}</TableCell>
                    )}

                    {row.accountType === "savings" && (
                      <TableCell>{t("CUENTA DE AHORROS")}</TableCell>
                    )}
                    <TableCell align="right">${row.amount}</TableCell>
                    <TableCell align="right">
                      {dateFormat(row.createdAt, t)}
                    </TableCell>
                    <TableCell align="right">
                      {row.state === "pending" && (
                        <Chip
                          size="small"
                          mr={1}
                          mb={1}
                          label={t("Pendiente")}
                          pending={+true}
                        />
                      )}
                      {row.state === "approved" && (
                        <Chip
                          size="small"
                          mr={1}
                          mb={1}
                          label={t("Aprobado")}
                          approved={+true}
                        />
                      )}
                      {row.state === "rejected" && (
                        <Chip
                          size="small"
                          mr={1}
                          mb={1}
                          label={t("Rechazado")}
                          rejected={+true}
                        />
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        aria-label="details"
                        size="large"
                        onClick={(e) => {
                          setSelectedWithdrawal(row);
                          setModalEdit(true);
                        }}
                      >
                        <RemoveRedEyeIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[20]}
            component="div"
            count={totalRowsWithdrawals}
            rowsPerPage={20}
            page={pageWithdrawals}
            onPageChange={handleChangePageWithdrawals}
            onRowsPerPageChange={handleChangeRowsPerWithdrawalsPage}
          />
        </TableContainer>
      </Paper>
      {/*----------------------------- MODAL ADMINISTRACIÓN DE RETIRO-------------------------------------- */}
      {/*----------------------------- MODAL ADMINISTRACIÓN DE RETIRO-------------------------------------- */}
      <Dialog
        open={modalEdit}
        onClose={() => setModalEdit(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t("manageWithdrawalListModal.Administrar retiro")}
        </DialogTitle>
        <DialogContent>
          <Table>
            {rowsManageWithdrawal.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.label}</TableCell>
                <TableCell>{row.value}</TableCell>
              </TableRow>
            ))}
          </Table>
          <DialogContentText>
            {t(
              "manageWithdrawalListModal.Mutante, recuerda que si apruebas el retiro, la billetera de {negocio} cambiará automáticamente.",
              {
                ownerName: selectedWithdrawal.owner.name,
              }
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => updateWithdrawal({ state: "rejected" })}
            color="primary"
          >
            {t("manageWithdrawalListModal.Rechazar")}
          </Button>
          <Button
            onClick={() => updateWithdrawal({ state: "approved" })}
            color="primary"
          >
            {t("manageWithdrawalListModal.Aprobar")}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={t(snackTxt)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}

function InvoiceList() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Helmet title="Retiros" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {t("Retiros")}
          </Typography>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default InvoiceList;
