import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CollectionsListOtherFiltersBySliceType } from "../../../interfaces/redux/CollectionsListOtherFiltersBySliceInterface";

type CollectionsListOtherFiltersByStatesKeys = any;

interface SetCollectionsFilterByModalStatesPayload {
  state: CollectionsListOtherFiltersByStatesKeys;
  value: boolean;
}

const initialState: CollectionsListOtherFiltersBySliceType = {
  //filtro por states
  pending: false,
  finished: false,
  assigned: false,
  collected: false,
  canceled: false,
  failed: false,
  statesCollectionsListFilterQuery: "",
  selectedStatesFilterArray: [],
  selectedStatesFilterArrayChip: [],
  //Filtro por recolector:
  selectedCollectorFilter: null,
  collectorCollectionsListFilterQuery: "",
  selectedCollectorFilterChip: null,
  //filtro por fecha
  rangeDateCollectionsListFilter: { value: "", label: "" },
  rangeDateCollectionsListFilterQuery: "",
  startDate: null,
  endDate: null,
  startDateValue: "",
  endDateValue: "",
  rangeDateCollectionsListFilterChip: { value: "", label: "" },
  //Filtro por ciudad:
  selectedCityFilter: null,
  cityCollectionsListFilterQuery: "",
  selectedCityFilterChip: null,
  //Filtro por tipo de material de recolección:
  typeOfMaterialCollectionsListFilter: { value: "", label: "" },
  typeOfMaterialCollectionsListFilterQuery: "",
  typeOfMaterialCollectionsListFilterChip: { value: "", label: "" },
  //Filtro por método de pago
  paymentMethodCollectionsListFilter: { value: "", label: "" },
  paymentMethodCollectionsListFilterQuery: "",
  paymentMethodCollectionsListFilterChip: { value: "", label: "" },
  //Control submit
  submit: false,
  //Control filtro fecha
  isSwitchOnSlice: false,
};

export const collectionsListOtherFiltersBySlice = createSlice({
  name: "collectionsListOtherFiltersByReducer",
  initialState,
  reducers: {
    //filtro por states
    setCollectionsStatesFilter: (
      state: any,
      action: PayloadAction<SetCollectionsFilterByModalStatesPayload>
    ) => {
      // @ts-ignore
      state[action.payload.state] = action.payload.value;
    },
    setStatesCollectionsFilterQuery: (state, action) => {
      state.statesCollectionsListFilterQuery = action.payload;
    },
    setSelectedStatesFilterArray: (state, action) => {
      state.selectedStatesFilterArray = action.payload;
    },
    setStatesCollectionsFilterChip: (state, action) => {
      state.selectedStatesFilterArrayChip = action.payload;
    },
    //Filtro por recolector:
    setCollectorCollectionsListFilterQuery: (state, action) => {
      state.collectorCollectionsListFilterQuery = action.payload;
    },
    setSelectedCollectorFilter: (state, action) => {
      state.selectedCollectorFilter = action.payload;
    },
    setSelectedCollectorFilterChip: (state, action) => {
      state.selectedCollectorFilterChip = action.payload;
    },
    //filtro por fecha
    setRangeDateCollectionsListFilter: (state, action) => {
      state.rangeDateCollectionsListFilter = action.payload;
    },
    setRangeDateCollectionsListFilterQuery: (state, action) => {
      state.rangeDateCollectionsListFilterQuery = action.payload;
    },
    setStartDateValue: (state, action) => {
      state.startDateValue = action.payload;
    },
    setEndDateValue: (state, action) => {
      state.endDateValue = action.payload;
    },
    setStartChipDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndChipDate: (state, action) => {
      state.endDate = action.payload;
    },
    setRangeDateCollectionsListFilterChip: (state, action) => {
      state.rangeDateCollectionsListFilterChip = action.payload;
    },
    //Filtro por ciudad:
    setCityCollectionsListFilterQuery: (state, action) => {
      state.cityCollectionsListFilterQuery = action.payload;
    },
    setSelectedCityFilter: (state, action) => {
      state.selectedCityFilter = action.payload;
    },
    setSelectedCityFilterChip: (state, action) => {
      state.selectedCityFilterChip = action.payload;
    },
    //Filtro por tipo de material de recolección:
    setTypeOfMaterialCollectionsListFilterQuery: (state, action) => {
      state.typeOfMaterialCollectionsListFilterQuery = action.payload;
    },
    setTypeOfMaterialCollectionsListFilter: (state, action) => {
      state.typeOfMaterialCollectionsListFilter = action.payload;
    },
    setTypeOfMaterialCollectionsListFilterChip: (state, action) => {
      state.typeOfMaterialCollectionsListFilterChip = action.payload;
    },
    //Filtro por método de pago
    setPaymentMethodCollectionsListFilterQuery: (state, action) => {
      state.paymentMethodCollectionsListFilterQuery = action.payload;
    },
    setPaymentMethodCollectionsListFilter: (state, action) => {
      state.paymentMethodCollectionsListFilter = action.payload;
    },
    setPaymentMethodCollectionsListFilterChip: (state, action) => {
      state.paymentMethodCollectionsListFilterChip = action.payload;
    },
    //submit
    setSubmit: (state, action) => {
      state.submit = action.payload;
    },
    //Renderizado filtro fecha
    setIsSwitchOnSlice: (state, action) => {
      state.isSwitchOnSlice = action.payload;
    },
    // resets
    resetCollectionsListOtherFiltersBy: () => initialState,
  },
});

export const {
  setCollectionsStatesFilter,
  setStatesCollectionsFilterQuery,
  setSelectedStatesFilterArray,
  setStatesCollectionsFilterChip,
  setRangeDateCollectionsListFilter,
  setRangeDateCollectionsListFilterQuery,
  setStartChipDate,
  setEndChipDate,
  setRangeDateCollectionsListFilterChip,
  setCityCollectionsListFilterQuery,
  setSelectedCityFilter,
  setSelectedCityFilterChip,
  setCollectorCollectionsListFilterQuery,
  setSelectedCollectorFilter,
  setSelectedCollectorFilterChip,
  setTypeOfMaterialCollectionsListFilter,
  setTypeOfMaterialCollectionsListFilterQuery,
  setTypeOfMaterialCollectionsListFilterChip,
  setPaymentMethodCollectionsListFilterQuery,
  setPaymentMethodCollectionsListFilter,
  setPaymentMethodCollectionsListFilterChip,
  setSubmit,
  setIsSwitchOnSlice,
  resetCollectionsListOtherFiltersBy,
  setStartDateValue,
  setEndDateValue,
} = collectionsListOtherFiltersBySlice.actions;

export default collectionsListOtherFiltersBySlice.reducer;
