export const customMuiButton = {
  styleOverrides: {
    root: {
      background: "#557CE6",
      borderRadius: 8,
      padding: "8px 16px",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: 16,
      lineHeight: "28px",
      textAlign: "center",
      color: "#FFFFFF",
      "&:hover": {
        background: "#4565b5",
      },
    },
  },
};
