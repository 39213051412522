import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { collectionsWarehouseFilterModalStatesSliceType } from "../../../interfaces/redux/collectionsWarehouseFilterModalStatesSliceInterface";

type CollectionsWarehouseFilterModalKeys = any;

interface SetCollectionsWarehouseFilterModalStatePayload {
  id: CollectionsWarehouseFilterModalKeys;
  value: boolean;
}
const initialState: collectionsWarehouseFilterModalStatesSliceType = {
  openWarehouseFilterModal: false,
  selectCity: false,
  selectWarehouse: false,
};

export const collectionWarehouseFilterModalStatesSlice = createSlice({
  name: "collectionWarehouseFilterModalStatesReducer",
  initialState,
  reducers: {
    setCollectionsWarehouseFilterModalState: (
      state: any,
      action: PayloadAction<SetCollectionsWarehouseFilterModalStatePayload>
    ) => {
      // @ts-ignore
      state[action.payload.id] = action.payload.value;
    },
    reset: () => initialState,
  },
});

export const { setCollectionsWarehouseFilterModalState, reset } =
  collectionWarehouseFilterModalStatesSlice.actions;

export default collectionWarehouseFilterModalStatesSlice.reducer;
