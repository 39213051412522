import { Typography } from "@mui/material";

interface ErrorTextProps {
  error: string;
}

export const ErrorText = ({ error }: ErrorTextProps) => {
  return (
    <Typography style={{ fontSize: "12px" }} color="red">
      {error}
    </Typography>
  );
};
