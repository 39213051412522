import CollectionsFilterByInput from "../forms/collections/filters/CollectionsFilterByInput";
import CustomWarehousePickerInput from "../forms/collections/filters/CustomWarehousePickerInput";

interface CustomFiltersProps {
  about?: string;
}

export default function CustomFilters(props: CustomFiltersProps) {
  const { about } = props;
  return (
    <>
      {about === "allCollections" && (
        <>
          <CustomWarehousePickerInput />
          <CollectionsFilterByInput />
        </>
      )}
    </>
  );
}
