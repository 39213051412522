import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

// import { citiesLatLng } from "../../utils/constants";

function AddressMap(props) {
  const {
    latSelectedAddres,
    lngSelectedAddres,
    // idCitySelected,
    onChangeMarker,
  } = props;

  // let cityLatLng = citiesLatLng.find((city) => city.id === idCitySelected);
  const [position, setPosition] = useState({
    lat: 0,
    lng: 0,
  });

  useEffect(() => {
    setPosition({
      lat: latSelectedAddres,
      lng: lngSelectedAddres,
    });
  }, [latSelectedAddres, lngSelectedAddres]);

  const mapContainerStyle = {
    height: "250px",
    width: "600px",
  };

  const center = {
    //Colombia
    lat: 4.570868,
    lng: -74.297333,
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAGrJxvfXC9U9b2LL7pFSzQ5kRVy6vtZoM",
  });

  const onLoad = (marker) => {
    // console.log("marker: ", marker);
  };

  return isLoaded ? (
    <GoogleMap
      id="marker"
      mapContainerStyle={mapContainerStyle}
      zoom={12}
      center={center}
      onClick={(e) => {
        var temp = {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        };
        onChangeMarker(temp);
        setPosition(temp);
      }}
    >
      {position && position.lat != 0 && position.lng != 0 && (
        <Marker onLoad={onLoad} position={position} />
      )}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(AddressMap);
