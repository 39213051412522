import { forwardRef, Fragment, useCallback } from "react";

import { Divider } from "@mui/material";

import { useAppSelector } from "../../../../redux/store";
import CustomText from "../../texts/CustomText";
import useAppDispatch from "../../../../hooks/old/useAppDispatch";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import CollectionsFilterByModalLayout from "../../../../layouts/collections/modals/CollectionsFilterByModalLayout";
import SelectOthersCollectionsFiltersForm from "../../../collections/SelectOthersCollectionsFiltersForm";
import { setCollectionsFilterByModalState } from "../../../../redux/slices/collections/collectionsFilterByModalStatesSlice";
import { useTranslation } from "react-i18next";

export default forwardRef(function CollectionsFilterByModal() {
  const { status } = useAppSelector((state) => state.newCollectionUser);
  const dispatch = useAppDispatch();
  const { PP2 } = usePixelPerfect();
  const { t } = useTranslation();

  const resetForm = useCallback(async () => {
    dispatch(
      setCollectionsFilterByModalState({
        id: "openFilterByModal",
        value: false,
      })
    );
  }, [dispatch]);

  const handleClickCloseModal = (event: React.MouseEvent<SVGSVGElement>) => {
    resetForm();
  };

  return (
    <CollectionsFilterByModalLayout handleClose={handleClickCloseModal}>
      {status === -1 && (
        <Fragment>
          <CustomText
            noSpacing
            bigSize
            premediumWeight
            color="#1C1C1C"
            style={{ marginBottom: PP2(24) }}
            onClick={resetForm}
          >
            {t("collectionsFilterByModal.Filtrar recolecciones")}{" "}
          </CustomText>
          <Divider sx={{ margin: "0px" }} />
          <SelectOthersCollectionsFiltersForm />
        </Fragment>
      )}
    </CollectionsFilterByModalLayout>
  );
});
