import React from 'react'
import { Helmet } from 'react-helmet-async'

import {  Grid, Typography } from '@mui/material'

export default function PageHeader({title, children}) {
  return (
    <React.Fragment>
      <Helmet title={title} />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {title}
          </Typography>
        </Grid>
        {children}
      </Grid>
      
    </React.Fragment>
  )
}
