import { useCallback } from "react";

import { useFormik } from "formik";

import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  warehouseId as warehouseIdValidation,
  validateYupSchema,
} from "../../utils/forms/validationSchema";
import CustomButton from "../custom/buttons/default/CustomButton";
import { setCollectionsWarehouseFilterModalState } from "../../redux/slices/collections/collectionWarehouseFilterModalStatesSlice";
import CustomSelectWarehouseInput from "../custom/forms/collections/filters/CustomSelectWarehouseInput";
import { setCollectionsFilter } from "../../redux/slices/collections/collectionListSlice";
import { useTranslation } from "react-i18next";

export default function SelectWarehouseFilterForm() {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { warehouseSelected, cityFilter, warehouseFilter } = useAppSelector(
    (state) => state.collectionsListWarehouseFilter
  );

  const {
    statesCollectionsListFilterQuery,
    rangeDateCollectionsListFilterQuery,
    collectorCollectionsListFilterQuery,
    typeOfMaterialCollectionsListFilterQuery,
    paymentMethodCollectionsListFilterQuery,
  } = useAppSelector((state) => state.collectionsListOtherFilters);

  const resetForm = useCallback(() => {
    dispatch(
      setCollectionsWarehouseFilterModalState({
        id: "openWarehouseFilterModal",
        value: false,
      })
    );
  }, [dispatch]);

  const handleClickCloseModal = () => {
    resetForm();
  };

  const initialFormikValues = {
    warehouseId: warehouseSelected?.id,
  };

  const validationSchema = validateYupSchema({
    warehouseId: warehouseIdValidation,
  });

  const handleSubmit = useCallback(() => {
    dispatch(
      setCollectionsFilter(
        cityFilter +
        warehouseFilter +
        statesCollectionsListFilterQuery +
        rangeDateCollectionsListFilterQuery +
        collectorCollectionsListFilterQuery +
        typeOfMaterialCollectionsListFilterQuery +
        paymentMethodCollectionsListFilterQuery
      )
    );
    handleClickCloseModal();
  }, [
    dispatch,
    cityFilter,
    warehouseFilter,
    statesCollectionsListFilterQuery,
    rangeDateCollectionsListFilterQuery,
    collectorCollectionsListFilterQuery,
    typeOfMaterialCollectionsListFilterQuery,
    paymentMethodCollectionsListFilterQuery,
  ]);

  const formikWarehouseFilter = useFormik({
    initialValues: initialFormikValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: false,
  });

  return (
    <form noValidate onSubmit={formikWarehouseFilter.handleSubmit}>
      <CustomSelectWarehouseInput
        label={t("collectionWarehouseFilterModal.SelectWarehouseFilterForm.Bodega")}
        required
        id="warehouseId"
        error={t(formikWarehouseFilter.errors.warehouseId)}
        width={432}
      />

      <CustomButton
        width={432}
        height={44}
        noSpacing
        style={{ padding: "0px", marginTop: PP(24) }}
        type="submit"
        label={t("collectionWarehouseFilterModal.SelectWarehouseFilterForm.Aplicar")}
        formik={formikWarehouseFilter}
      />
      <CustomButton
        noSpacing
        width={432}
        height={44}
        cancelButton
        onClick={handleClickCloseModal}
        formik={formikWarehouseFilter}
      />
    </form>
  );
}
