import { createSlice } from "@reduxjs/toolkit";
import { AddMaterialTransactionSliceType } from "../../../interfaces/redux/addMaterialTransactionSliceInterface";

const initialState: AddMaterialTransactionSliceType = {
  loading: false,
  status: -1,
  statusText: "",
  form: {
    category: "",
    materialId: -1,
    quantity: 0,
    price: 0,
    discount: 0,
  },
};

export const addMaterialTransactionSlice = createSlice({
  name: "addMaterialTransactionReducer",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = -1;
      state.statusText = "";
    },
    setFormAddTransactionMaterialState: (state, action) => {
      const { id, value } = action.payload;
      state.form = {
        ...state.form,
        [id]: value,
      };
    },

    reset: () => initialState,
  },
  // extraReducers: (builder) => {
  //   // Añadir nuevo material a Transactionación
  //   builder.addCase(fetchAddMaterialTransaction.pending, (state) => {
  //     state.loading = true;
  //   });
  //   builder.addCase(fetchAddMaterialTransaction.fulfilled, (state, action) => {
  //     state.status = action.payload.status;
  //     state.statusText = action.payload.statusText;
  //     state.loading = false;
  //   });
  //   builder.addCase(fetchAddMaterialTransaction.rejected, (state, action) => {
  //     state.loading = false;
  //   });
  // },
});

export const { resetStatus, reset, setFormAddTransactionMaterialState } =
  addMaterialTransactionSlice.actions;

export default addMaterialTransactionSlice.reducer;
