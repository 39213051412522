import PPD from "../../utils/modals/ppd";

const customTablePagination = {
  mainContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: PPD(20),
  },
  paginationText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: PPD(16),
    lineHeight: PPD(24),
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    "&:hover": {
      background: "none",
    },
  },
  previousButton: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: PPD(40),
    padding: `${PPD(8)} ${PPD(12)}`,
    border: `${PPD(1)} solid #E8E8E8`,
    boxShadow: `${PPD(0)} ${PPD(1)} ${PPD(1)} rgba(0, 0, 0, 0.06)`,
    borderRadius: PPD(4),
    marginRight: PPD(8),
    marginLeft: PPD(12),
    cursor: "pointer",
  },
  nextButton: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: PPD(40),
    padding: `${PPD(8)} ${PPD(12)}`,
    border: `${PPD(1)} solid #E8E8E8`,
    boxShadow: `${PPD(0)} ${PPD(1)} ${PPD(1)} rgba(0, 0, 0, 0.06)`,
    borderRadius: PPD(4),
    cursor: "pointer",
  },
  iconButton: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: PPD(16),
    lineHeight: PPD(24),
  },
};

export default customTablePagination;
