import { createSlice } from "@reduxjs/toolkit";
import { UserCustomPricesListSliceType } from "../../../interfaces/redux/userCustomPricesListSliceInterface";
import { fetchBusinessCustomPricesList } from "../../thunks/users/userCustomPricesListThunk";

const initialState: UserCustomPricesListSliceType = {
  status: "idle",
  userCustomPricesList: [],
  totalRowsUserCustomPricesList: 0,
};

export const userCustomPricesListSlice = createSlice({
  name: "userCustomPricesList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Get lista PRECIOS personalizados del usuario
    builder.addCase(fetchBusinessCustomPricesList.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(
      fetchBusinessCustomPricesList.fulfilled,
      (state, action) => {
        state.status = "succeeded";
        state.userCustomPricesList = action.payload;
        state.totalRowsUserCustomPricesList = action.payload.total; //FALTA PAGINACION EN PRECIOS PERSONALIZADOS
      }
    );
    builder.addCase(fetchBusinessCustomPricesList.rejected, (state) => {
      state.status = "failed";
    });
  },
});

export const {} = userCustomPricesListSlice.actions;

export default userCustomPricesListSlice.reducer;
