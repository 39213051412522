import { forwardRef, Fragment, useCallback } from "react";

import { Divider } from "@mui/material";

import CustomText from "../../texts/CustomText";
import useAppDispatch from "../../../../hooks/old/useAppDispatch";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import DetailsCollectionModalLayout from "../../../../layouts/collections/modals/DetailsCollectionModalLayout";
import CollectionEditAssignmentDetailsForm from "../../../collections/CollectionEditAssignmentDetailsForm";
import { setCollectionEditStateModalState } from "../../../../redux/slices/collections/collectionDetailsInfoModalStatesSlice";
import { useTranslation } from "react-i18next";

export default forwardRef(function CustomEditAssignmentDetailModal() {
  const dispatch = useAppDispatch();
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();

  const resetForm = useCallback(async () => {
    dispatch(
      setCollectionEditStateModalState({
        id: "editAssignmentDetail",
        value: false,
      })
    );
  }, [dispatch]);

  const handleClickCloseModal = (event: React.MouseEvent<SVGSVGElement>) => {
    resetForm();
  };

  return (
    <DetailsCollectionModalLayout handleClose={handleClickCloseModal}>
      <Fragment>
        <CustomText
          noSpacing
          bigSize
          premediumWeight
          color="#1C1C1C"
          style={{ marginBottom: PP(24) }}
        >
          {t(
            "collectionAssignmentDetail.customEditAssignmentDetailModal.Editar asignación"
          )}
        </CustomText>
        <Divider sx={{ margin: "0px" }} />
        <CollectionEditAssignmentDetailsForm />
      </Fragment>
    </DetailsCollectionModalLayout>
  );
});
