import { createSlice } from "@reduxjs/toolkit";
import { fetchEditTransactionInfo } from "../../thunks/transactions/editTransactionInfoThunk";

const initialState = {
  selectedTransactionToEdit: {
    incoterm: "",
    currency: "",
    consecutive: 0,
    contract: "",
    buyerType: "",
    buyerId: "",
    observation: "",
  },
  loading: false,
  status: -1,
  statusText: "",
  form: {
    state: "",
    incoterm: "",
    currency: "",
    consecutive: 0,
    contract: "",
    buyerType: "",
    buyerId: "",
    observation: "",
    extra: { loadDate: "", totalPayment: 0 },
    materials: [
      {
        materialId: -1,
        quantity: 0,
        price: 0,
        discount: 0,
      },
    ],
  },
};

export const editTransactionSlice = createSlice({
  name: "editTransactionReducer",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = -1;
      state.statusText = "";
    },
    setFormEditTransactionState: (state, action) => {
      const { id, value } = action.payload;
      state.form = {
        ...state.form,
        [id]: value,
      };
    },
    setLoadDate: (state, action) => {
      state.form.extra.loadDate = action.payload;
    },
    setSelectedTransactionToEdit: (state, action) => {
      state.selectedTransactionToEdit = action.payload;
    },

    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Editar una transacción
    builder.addCase(fetchEditTransactionInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchEditTransactionInfo.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.statusText = action.payload.statusText;
      state.loading = false;
    });
    builder.addCase(fetchEditTransactionInfo.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const {
  resetStatus,
  reset,
  setFormEditTransactionState,
  setSelectedTransactionToEdit,
  setLoadDate,
} = editTransactionSlice.actions;

export default editTransactionSlice.reducer;
