// import styled from "@emotion/styled";
// import { IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
// import {
//   Archive as ArchiveIcon,
//   FilterList as FilterListIcon,
// } from "@mui/icons-material";

// const ToolbarTitle = styled.div`
//   min-width: 150px;
// `;

// const Spacer = styled.div`
//   flex: 1 1 100%;
// `;

const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  // const { numSelected } = props;s

  return (
    <></>
    // <Toolbar>
    //   <ToolbarTitle>
    //     {numSelected > 0 ? (
    //       <Typography color="inherit" variant="subtitle1">
    //         {numSelected} selected
    //       </Typography>
    //     ) : (
    //       <Typography variant="h6" id="tableTitle">
    //         Precios
    //       </Typography>
    //     )}
    //   </ToolbarTitle>
    //   <Spacer />
    //    <div>
    //     {numSelected > 0 ? (
    //       <Tooltip title="Delete">
    //         <IconButton aria-label="Delete" size="large">
    //           <ArchiveIcon />
    //         </IconButton>
    //       </Tooltip>
    //     ) : (
    //       <Tooltip title="Filter list">
    //         <IconButton aria-label="Filter list" size="large">
    //           <FilterListIcon />
    //         </IconButton>
    //       </Tooltip>
    //     )}
    //   </div>
    // </Toolbar>
  );
};

export default EnhancedTableToolbar;
