import { TableCell, TableHead, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";

const EnhancedTableHead = ({ headCells }) => {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label !== "NIT"
              ? t(`table.tableHeadCells.${headCell.label}`)
              : "NIT"}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
