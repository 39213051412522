import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRedux } from "../../../utils/old/api";

const initialState = {
  allCities: [],
  totalNumberCities: 0,
  pageCities: 0,
  cityDetail: null,
  showLoading: false,
  errorMessage: null,
};

export const fetchCities = createAsyncThunk(
  //Get
  "allCities/show",
  (page) => {
    return getRedux(`/city?$skip=${0 * 300}&$limit=300`).then((response) => {
      return response.data;
    });
  }
);

export const fetchCityDetail = createAsyncThunk(
  //Get
  "cityDetail/show",
  (id) => {
    return getRedux(`/city/${id}`).then((response) => {
      return response.data;
    });
  }
);

export const citiesSlice = createSlice({
  name: "citiesReducer",
  initialState,
  reducers: {
    setPageCities: (state, action) => {
      state.pageCities = action.payload;
    },
  },
  extraReducers: (builder) => {
    //1-fetchCities
    builder.addCase(fetchCities.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchCities.fulfilled, (state, action) => {
      if (action.payload.data && action.payload.data.length > 0) {
        state.allCities = state.allCities.concat(action.payload.data);
      }
      // console.log(state.allCities);

      state.totalNumberCities = action.payload.total;
      state.allCities = action.payload.data;
      state.showLoading = false;
      state.pageCities = state.pageCities + 1;
    });
    builder.addCase(fetchCities.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error obteniendo ciudades");
    });
    //2-fetchCityDetail
    builder.addCase(fetchCityDetail.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchCityDetail.fulfilled, (state, action) => {
      state.showLoading = false;
      state.cityDetail = action.payload;
    });
    builder.addCase(fetchCityDetail.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error obteniendo detalle de ciudad");
    });
  },
});

export const { setPageCities } = citiesSlice.actions;

export default citiesSlice.reducer;
