import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TransferAttachedFilesModalKeys = any;

interface transferAttachedFilesModalsSliceType {
  selectTypeFile: boolean;
  attachFile: boolean;
  deleteAttachFile: boolean;
  fullScreen: boolean;
}

interface SetTransferModalStatePayload {
  id: TransferAttachedFilesModalKeys;
  value: boolean;
}
const initialState: transferAttachedFilesModalsSliceType = {
  selectTypeFile: false,
  attachFile: false,
  deleteAttachFile: false,
  fullScreen: false,
};

export const transferAttachedFilesModalStatesSlice = createSlice({
  name: "transferAttachedFilesModalStatesReducer",
  initialState,
  reducers: {
    setTransferAttachedFilesModalState: (
      state: any,
      action: PayloadAction<SetTransferModalStatePayload>
    ) => {
      // @ts-ignore
      state[action.payload.id] = action.payload.value;
    },
    reset: () => initialState,
  },
});

export const { setTransferAttachedFilesModalState, reset } =
  transferAttachedFilesModalStatesSlice.actions;

export default transferAttachedFilesModalStatesSlice.reducer;
