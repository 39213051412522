import { createAsyncThunk } from "@reduxjs/toolkit";

import { getRedux } from "../../../utils/old/api";

export const fetchCitiesList = createAsyncThunk(
  "citiesList/fetchGet",
  async (payload: { page: number }, thunkAPI) => {
    try {
      const response = await getRedux(
        `/city?$skip=${payload.page * 10}&$limit=10`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
