import { forwardRef, Fragment, useCallback } from "react";

import { Divider } from "@mui/material";

import { useAppSelector } from "../../../../redux/store";
import CustomText from "../../texts/CustomText";
import useAppDispatch from "../../../../hooks/old/useAppDispatch";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
// import { useTranslation } from "react-i18next";
import MaterialsModalLayout from "../../../../layouts/MaterialsModalLayout";
import { reset as resetEditMaterialExport } from "../../../../redux/slices/transactions/editMaterialTransactionSlice";
import { setTransactionMaterialsModalState } from "../../../../redux/slices/transactions/transactionMaterialsModalsStatesSlice";
import EditMaterialTransactionForm from "../../../transactions/EditMaterialTransactionForm";
import { useTranslation } from "react-i18next";

export default forwardRef(function CustomEditTransactionMaterialModal() {
  const { status } = useAppSelector((state) => state.editMaterialTransaction);
  const dispatch = useAppDispatch();
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();

  const resetForm = useCallback(async () => {
    dispatch(resetEditMaterialExport());
    dispatch(
      setTransactionMaterialsModalState({ id: "editQuantity", value: false })
    );
  }, [dispatch]);

  const handleClickCloseModal = (event: React.MouseEvent<SVGSVGElement>) => {
    resetForm();
  };

  return (
    <MaterialsModalLayout handleClose={handleClickCloseModal}>
      <Fragment>
        <CustomText
          showBackArrowButton
          noSpacing
          bigSize
          premediumWeight
          color="#1C1C1C"
          style={{ marginBottom: PP(24) }}
          onClick={resetForm}
        >
          {t("Editar información material")}
        </CustomText>
        <Divider sx={{ margin: "0px" }} />
        <EditMaterialTransactionForm />
      </Fragment>
    </MaterialsModalLayout>
  );
});
