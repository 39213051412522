import { createAsyncThunk } from "@reduxjs/toolkit";

import { patchRedux } from "../../../utils/old/api";
import { fetchCollectionDetails } from "./collectionDetailsThunk";

export const fetchUpdateCollectionInfo = createAsyncThunk(
  "updateCollectionInfo/fetchPatch",
  async (payload: { data: {}; collectionId: string }, thunkAPI) => {
    try {
      const response = await patchRedux(
        `/collection/${payload.collectionId}`,
        payload.data
      );

      await thunkAPI.dispatch(
        fetchCollectionDetails({ id: payload.collectionId })
      );

      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    } catch (error) {
      const errors = thunkAPI.rejectWithValue(error);
      // console.log(errors);
      return errors;
    }
  }
);
