import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { configureStore } from "@reduxjs/toolkit";

import counterReducer from "./old/slices/counter";
import collectionSlicer from "./old/slices/collectionSlicer";
import businessSlicer from "./old/slices/businessSlicer";
import pricesSlicer from "./old/slices/pricesSlicer";
import individualsSlicer from "./old/slices/individualsSlicer";
import withdrawalSlicer from "./old/slices/withdrawalSlicer";
import citiesSlicer from "./old/slices/citiesSlicer";
import googleMapsSlicer from "./old/slices/googleMapsSlicer";
import googleMapsModalSlicer from "./old/slices/googleMapsModalSlicer";
import collectionListSlice from "./slices/collections/collectionListSlice";
import businessListSlice from "./slices/users/business/businessListSlice";
import businessSelectedSlice from "./slices/users/business/businessSelectedSlice";
import activeTabMainNavBarSlice from "./slices/users/activeTabMainNavBarSlice";
import businessCollectionsSlice from "./slices/users/business/businessCollectionsSlice";
import newCollectionUserSlice from "./slices/users/newCollectionUserSlice";
import userAdressesListSlice from "./slices/users/userAdressesListSlice";
import collectionUserModalStateSlice from "./slices/users/collectionUserModalStateSlice";
import collectionDetailsSlice from "./slices/collections/collectionDetailsSlice";
import collectorsListSlice from "./slices/collectors/collectorsListSlice";
import warehouseDetailsSlice from "./slices/warehouse/warehouseDetailsSlice";
import collectionMaterialsModalsStateSlice from "./slices/collections/collectionsMaterialsModalsStates";
import addMaterialCollectionSlice from "./slices/collections/addMaterialCollectionSlice";
import materialsListSlice from "./slices/collections/materialsListSlice";
import deleteMaterialCollectionSlice from "./slices/collections/deleteMaterialCollectionSlice";
import editMaterialCollectionSlice from "./slices/collections/editMaterialCollectionSlice";
import globalSnackbarSlice from "./slices/globalSnackbar/globalSnackbarSlice";
import userInformationSlice from "./slices/users/userInformationSlice";
import updateUserInformationSlice from "./slices/users/updateUserInformationSlice";
import userCustomPricesListSlice from "./slices/users/userCustomPricesListSlice";
import userCustomPricesModalStatesSlice from "./slices/users/userCustomPricesModalStates";
import editUserCustomPriceSlice from "./slices/users/editUserCustomPriceSlice";
import deleteUserCustomPriceSlice from "./slices/users/deleteUserCustomPriceSlice";
import createUserCustomPriceSlice from "./slices/users/createUserCustomPriceSlice";
import collectionWarehouseFilterModalStatesSlice from "./slices/collections/collectionWarehouseFilterModalStatesSlice";
import commercialsListSlice from "./slices/commercials/commercialsListSlice";
import citiesListSlice from "./slices/cities/citiesListSlice";
import inventoryVerificationSlice from "./slices/inventory/inventoryVerificationListSlice";
import collectionsListWarehouseFilterSlice from "./slices/collections/collectionsListWarehouseFilterSlice";
import warehouseListSlice from "./slices/warehouse/warehouseListSlice";
import collectionsFilterByModalStatesSlice from "./slices/collections/collectionsFilterByModalStatesSlice";
import collectionsListOtherFiltersBySlice from "./slices/collections/collectionsListOtherFiltersBySlice";
import collectionDetailsInfoModalStatesSlice from "./slices/collections/collectionDetailsInfoModalStatesSlice";
import updateCollectionStateSlice from "./slices/collections/updateCollectionStateSlice";
import collectionMaterialsPricesListSlice from "./slices/collections/collectionMaterialsPricesListSlice";
import collectionAttachedFilesModalStatesSlice from "./slices/collections/collectionAttachedFilesModalStatesSlice";
import collectionAttachNewFileModalSlice from "./slices/collections/collectionAttachNewFileModalSlice";
import updateCollectionCollectorSlice from "./slices/collections/updateCollectionCollectorSlice";
import deleteCollectionAttachFileSlice from "./slices/collections/deleteCollectionAttachFileSlice";
import collectionHistoryPageSlice from "./slices/collections/collectionHistoryPageSlice";
import transactionMaterialsModalsStatesSlice from "./slices/transactions/transactionMaterialsModalsStatesSlice";
import transactionsListSlice from "./slices/transactions/transactionsListSlice";
import clientsListSlice from "./slices/transactions/clientsListSlice";
import createTransactionSlice from "./slices/transactions/createTransactionSlice";
import transactionDetailsSlice from "./slices/transactions/transactionDetailsSlice";
import editTransactionSlice from "./slices/transactions/editTransactionSlice";
import transactionAttachedFilesModalStatesSlice from "./slices/transactions/transactionAttachedFilesModalStatesSlice";
import deleteMaterialTransactionSlice from "./slices/transactions/deleteMaterialTransactionSlice";
import addMaterialTransactionSlice from "./slices/transactions/addMaterialTransactionSlice";
import transactionAttachNewFileModalSlice from "./slices/transactions/transactionAttachNewFileModalSlice";
import deleteTransactionAttachFileSlice from "./slices/transactions/deleteTransactionAttachFileSlice";
import editMaterialTransactionSlice from "./slices/transactions/editMaterialTransactionSlice";
import POOListSlice from "./slices/transactions/POOListSlice";
import generalClientsListSlice from "./slices/clients/generalClientsListSlice";
import createClientSlice from "./slices/clients/creatClientSlice";
import editClientSlice from "./slices/clients/editClientSlice";
import currentPasswordValidationSlice from "./slices/settings/security/currentPasswordValidationSlice";
import changePasswordSlice from "./slices/settings/security/changePasswordSlice";
import transferListSlice from "./slices/inventory/transfer/transferListSlice";
import createTransferSlice from "./slices/inventory/transfer/createTransferSlice";
import transferDetailsSlice from "./slices/inventory/transfer/transferDetailsSlice";
import transferDetailsModalStatusSlice from "./slices/inventory/transfer/transferDetailsModalStatusSlice";
import updateTransferInfoSlice from "./slices/inventory/transfer/updateTransferInfoSlice";
import transferMaterialsModalsStatesSlice from "./slices/inventory/transfer/transferMaterialsModalsStatesSlice";
import addMaterialTransferSlice from "./slices/inventory/transfer/addMaterialTransferSlice";
import deleteMaterialTransferSlice from "./slices/inventory/transfer/deleteMaterialTransferSlice";
import editMaterialTransferSlice from "./slices/inventory/transfer/editMaterialTransferSlice";
import transferAttachNewFileModalSlice from "./slices/inventory/transfer/transferAttachNewFileModalSlice";
import transferAttachedFilesModalStatesSlice from "./slices/inventory/transfer/transferAttachedFilesModalStatesSlice";
import deleteTransferAttachFileSlice from "./slices/inventory/transfer/deleteTransferAttachFileSlice";
import transformationListSlice from "./slices/inventory/transformation/transformationListSlice";
import transformationMaterialsModalsStatesSlice from "./slices/inventory/transformation/transformationMaterialsModalsStatesSlice";
import addMaterialTransformationSlice from "./slices/inventory/transformation/addMaterialTransformationSlice";
import editMaterialTransformationSlice from "./slices/inventory/transformation/editMaterialTransformationSlice";
import deleteMaterialTransformationSlice from "./slices/inventory/transformation/deleteMaterialTransformationSlice";
import createTransformationSlice from "./slices/inventory/transformation/createTransformationSlice";
import oneTransformationSlice from "./slices/inventory/transformation/oneTransformationSlice";
import deleteTransformationSlice from "./slices/inventory/transformation/deleteTransformationSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    collection: collectionSlicer,
    prices: pricesSlicer,
    business: businessSlicer,
    person: individualsSlicer,
    withdrawals: withdrawalSlicer,
    cities: citiesSlicer,
    googleMaps: googleMapsSlicer,
    googleMapsModal: googleMapsModalSlicer,

    //NUEVAS SLICES CON TS:
    //1.Collections
    collectionList: collectionListSlice,
    collectionDetails: collectionDetailsSlice,
    collectionMaterialsModalsState: collectionMaterialsModalsStateSlice,
    addMaterialCollection: addMaterialCollectionSlice,
    materialsList: materialsListSlice,
    deleteMaterialCollection: deleteMaterialCollectionSlice,
    editMaterialCollection: editMaterialCollectionSlice,
    collectionsWarehouseFilterModalStates:
      collectionWarehouseFilterModalStatesSlice,
    collectionsListWarehouseFilter: collectionsListWarehouseFilterSlice,
    collectionsListFilterByModalStates: collectionsFilterByModalStatesSlice,
    collectionsListOtherFilters: collectionsListOtherFiltersBySlice,
    collectionDetailsInfoModalStates: collectionDetailsInfoModalStatesSlice,
    updateCollectionState: updateCollectionStateSlice,
    collectionMaterialsPricesList: collectionMaterialsPricesListSlice,
    collectionAttachedFilesModalStates: collectionAttachedFilesModalStatesSlice,
    collectionAttachNewFile: collectionAttachNewFileModalSlice,
    updateCollectionCollector: updateCollectionCollectorSlice,
    deleteCollectionAttachFile: deleteCollectionAttachFileSlice,
    collectionHistoryPage: collectionHistoryPageSlice,

    //2.Collectors
    collectorsList: collectorsListSlice,

    //3.Users
    newCollectionUser: newCollectionUserSlice,
    activeTabMainNavBar: activeTabMainNavBarSlice,
    addressesListUser: userAdressesListSlice,
    collectionUserModalState: collectionUserModalStateSlice,
    userInformation: userInformationSlice,
    updateUserInformation: updateUserInformationSlice,
    userCustomPrices: userCustomPricesListSlice,
    userCustomPricesModalsStates: userCustomPricesModalStatesSlice,
    userEditCustomPrice: editUserCustomPriceSlice,
    userDeleteCustomPrice: deleteUserCustomPriceSlice,
    createUserCustomPrice: createUserCustomPriceSlice,

    //3.1.Business
    businessList: businessListSlice,
    businessSelected: businessSelectedSlice,
    businessCollections: businessCollectionsSlice,

    //4.Warehouses
    warehouseDetails: warehouseDetailsSlice,
    warehouseList: warehouseListSlice,

    //5.SnackBar
    globalSnackBar: globalSnackbarSlice,

    //6.Commercials
    commercialsList: commercialsListSlice,

    //7.Cities
    citiesList: citiesListSlice,


    //8. Inventario
    // quantityList: inventorySlice,
    // transferList: inventoryTransferSlice,
    verificationList: inventoryVerificationSlice,
    transferList: transferListSlice,
    createTransfer: createTransferSlice,

    //9.Transactions
    transactionsList: transactionsListSlice,
    createTransaction: createTransactionSlice,
    clientsList: clientsListSlice,
    transactionDetails: transactionDetailsSlice,
    editTransaction: editTransactionSlice,
    transactionAttachedFilesModalStates:
      transactionAttachedFilesModalStatesSlice,
    transactionAttachNewFileModal: transactionAttachNewFileModalSlice,
    deleteTransactionAttachFile: deleteTransactionAttachFileSlice,
    addMaterialTransaction: addMaterialTransactionSlice,
    deleteMaterialTransaction: deleteMaterialTransactionSlice,
    transactionMaterialsModals: transactionMaterialsModalsStatesSlice,
    editMaterialTransaction: editMaterialTransactionSlice,
    POOList: POOListSlice,

    //10.Clientes
    generalClientsList: generalClientsListSlice,
    createClient: createClientSlice,
    editClient: editClientSlice,

    //11. Configuraciones
    //11.1 Seguridad - Cambio de contraseña
    currentPasswordValidation: currentPasswordValidationSlice,
    changePassword: changePasswordSlice,

    //12. Transferencias
    transferDetails: transferDetailsSlice,
    transferDetailsModalStatus: transferDetailsModalStatusSlice,
    updateTransferInfo: updateTransferInfoSlice,
    transferMaterialsModalsStates: transferMaterialsModalsStatesSlice,
    addMaterialTransfer: addMaterialTransferSlice,
    deleteMaterialTransfer: deleteMaterialTransferSlice,
    editMaterialTransfer: editMaterialTransferSlice,
    transferAttachNewFile: transferAttachNewFileModalSlice,
    transferAttachedFilesModalStates: transferAttachedFilesModalStatesSlice,
    deleteTransferAttachFile: deleteTransferAttachFileSlice,

    //13. Transformaciones
    transformationList: transformationListSlice,
    transformationMaterialsModalsStates: transformationMaterialsModalsStatesSlice,
    addMaterialTransformation: addMaterialTransformationSlice,
    editMaterialTransformation: editMaterialTransformationSlice,
    deleteMaterialTransformation: deleteMaterialTransformationSlice,
    createTransformation: createTransformationSlice,
    oneTransformation: oneTransformationSlice,
    deleteTransformation: deleteTransformationSlice
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
