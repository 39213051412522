import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import {
  Card,
  CardMedia,
  Drawer as MuiDrawer,
  ListItemButton,
} from "@mui/material";

import MenuLogo from "../../../../vendor/old/menuLogo.png";
import Footer from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const Sidebar = ({ items, showFooter = true, ...rest }) => {
  const { PP } = usePixelPerfect();
  return (
    <Drawer variant="permanent" {...rest}>
      <Brand component={NavLink} to="/">
        <Card
          sx={{
            display: "flex",
            justifyContent: "center",
            boxShadow: "none",
            backgroundColor: "#233044",
            marginTop: PP(30),
            height: PP(70),
          }}
        >
          <CardMedia
            component="img"
            sx={{ width: PP(160), objectFit: "contain" }}
            image={MenuLogo}
            alt="MenuLogo"
          />
        </Card>
      </Brand>
      <SidebarNav items={items} />
      {!!showFooter && <Footer />}
    </Drawer>
  );
};

export default Sidebar;
