import { styled, TableCell, TableHead, TableRow } from "@mui/material";
import { tableHeadCellType } from "../../../../interfaces/data/tableHeadCellInterface";

import { CustomTableRowType } from "../../../../interfaces/styles/customTableRowInterface";
import { useTranslation } from "react-i18next";

interface TableHeadProps {
  tableHeadCells: tableHeadCellType[];
}

const CustomTableRow = styled(TableRow)<CustomTableRowType>(({ }) => ({}));

export default function CustomTableHead(props: TableHeadProps) {
  const { tableHeadCells } = props;
  const { t } = useTranslation();

  return (
    <TableHead style={{ width: "100%" }}>
      <CustomTableRow
        variant="default"
        style={{ backgroundColor: "transparent" }}
      >
        {tableHeadCells.map((tableHeadCells) => (
          <TableCell
            key={tableHeadCells.id}
            align={tableHeadCells.alignment}
            padding={tableHeadCells.disablePadding ? "none" : "normal"}
            style={{ cursor: "default" }}
          >
            {t(`tables.tableHeadCells.${tableHeadCells.label}`)}
          </TableCell>
        ))}
      </CustomTableRow>
    </TableHead>
  );
}
