export const customMuiCard = {
  styleOverrides: {
    root: {
      borderRadius: "6px",
      boxShadow:
        "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px",
      backgroundImage: "red",
    },
  },
};
