import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";


import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import { useAppSelector } from "../../redux/store";
import { firstLetterCapitalizeFormat } from "../../utils/formats/firstLetterCapitalizeFormat";
import CustomAppliedFilterChip, {
  FilterType,
} from "../custom/chip/collections/CustomAppliedFilterChip";
import CustomDeleteAllAppliedFiltersChip from "../custom/chip/collections/CustomDeleteAllAppliedFiltersChip";

export default function FilterControl() {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();

  const {
    statesCollectionsListFilterQuery,
    selectedStatesFilterArrayChip,
    rangeDateCollectionsListFilterQuery,
    rangeDateCollectionsListFilterChip,
    startDate,
    endDate,
    collectorCollectionsListFilterQuery,
    selectedCollectorFilterChip,
    typeOfMaterialCollectionsListFilterQuery,
    typeOfMaterialCollectionsListFilterChip,
    paymentMethodCollectionsListFilterQuery,
    paymentMethodCollectionsListFilterChip,
    cityCollectionsListFilterQuery,
    selectedCityFilterChip,
  } = useAppSelector((state) => state.collectionsListOtherFilters);

  const { filter } = useAppSelector((state) => state.collectionList);

  const statesFilterTranslation = selectedStatesFilterArrayChip && selectedStatesFilterArrayChip.map((state) => {
    return t(`chips.states.${state}`);
  });

  const filtersArray: {
    filterName: FilterType;
    value: string;
    chipLabel: string;
  }[] = [
      {
        filterName: "state",
        value: statesCollectionsListFilterQuery,
        chipLabel: statesFilterTranslation.join(", ").replace(/chips\.states\./g, ''),
      },
      {
        filterName: "date",
        value: rangeDateCollectionsListFilterQuery,
        chipLabel: rangeDateCollectionsListFilterQuery.includes("$lte")
          ?
          startDate && endDate ?
            `${startDate} - ${endDate}` : ""
          : t(`collectionsFilterByModal.selectOthersCollectionsFiltersForm.customSelectRangeDateFilter.dateFilterOptions.${rangeDateCollectionsListFilterChip.label}`),
      },
      {
        filterName: "cityId",
        value: cityCollectionsListFilterQuery,
        chipLabel: selectedCityFilterChip
          ? firstLetterCapitalizeFormat(selectedCityFilterChip.name)
          : "",
      },
      {
        filterName: "collectorId",
        value: collectorCollectionsListFilterQuery,
        chipLabel: selectedCollectorFilterChip
          ? firstLetterCapitalizeFormat(selectedCollectorFilterChip.name)
          : "",
      },
      {
        filterName: "have",
        value: typeOfMaterialCollectionsListFilterQuery,
        chipLabel: typeOfMaterialCollectionsListFilterChip.label,
      },
      {
        filterName: "payment_method",
        value: paymentMethodCollectionsListFilterQuery,
        chipLabel: paymentMethodCollectionsListFilterChip.label,
      },
    ];

  // Lista de palabras a verificar en filter
  const keyFiltersWords = [
    "state",
    "date",
    "cityId",
    "collectorId",
    "have",
    "payment_method",
  ];

  // Función que verifica si alguna de las palabras clave está presente en filter
  function someKeyWordOnFilter(filter: string) {
    return keyFiltersWords.some((word) => filter.includes(word));
  }

  // Verifico si hay al menos un filtro aplicado
  const hasFilters = filtersArray.some((filter) => filter.value !== "");

  return (
    <>
      {filter !== "" && someKeyWordOnFilter(filter) && (
        <Box
          display="flex"
          marginTop={PP(24)}
          marginBottom={PP(16)}
          flexWrap="wrap"
        >
          {hasFilters && <CustomDeleteAllAppliedFiltersChip />}
          {filtersArray.map((filterOption, key) => {
            if (
              filterOption.value !== "" &&
              filter.includes(filterOption.filterName) && filterOption.chipLabel !== ""
            ) {
              return (
                <CustomAppliedFilterChip
                  key={key}
                  chipLabel={filterOption.chipLabel}
                  filterName={filterOption.filterName}
                />
              );
            }
            return null;
          })}
        </Box>
      )}
    </>
  );
}
