import { forwardRef, Fragment, useCallback } from "react";

import { Divider } from "@mui/material";

import { useAppSelector } from "../../../../redux/store";
import CustomText from "../../texts/CustomText";
import useAppDispatch from "../../../../hooks/old/useAppDispatch";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import { setCollectionMaterialsModalState } from "../../../../redux/slices/collections/collectionsMaterialsModalsStates";
import EditMaterialCollectionForm from "../../../collections/EditMaterialCollectionForm";
import { reset as resetEditMaterial } from "../../../../redux/slices/collections/editMaterialCollectionSlice";
import { useTranslation } from "react-i18next";
import MaterialsModalLayout from "../../../../layouts/MaterialsModalLayout";

export default forwardRef(function CustomEditMaterialModal() {
  const { status } = useAppSelector((state) => state.editMaterialCollection);
  const dispatch = useAppDispatch();
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();

  const resetForm = useCallback(async () => {
    dispatch(resetEditMaterial());
    dispatch(
      setCollectionMaterialsModalState({ id: "editQuantity", value: false })
    );
  }, [dispatch]);

  const handleClickCloseModal = (event: React.MouseEvent<SVGSVGElement>) => {
    resetForm();
  };

  return (
    <MaterialsModalLayout handleClose={handleClickCloseModal}>
      <Fragment>
        <CustomText
          showBackArrowButton
          noSpacing
          bigSize
          premediumWeight
          color="#1C1C1C"
          style={{ marginBottom: PP(24) }}
          onClick={resetForm}
        >
          {t("collectionInformation.customEditMaterialModal.Editar cantidad")}{" "}
        </CustomText>
        <Divider sx={{ margin: "0px" }} />
        <EditMaterialCollectionForm />
      </Fragment>
    </MaterialsModalLayout>
  );
});
