import { useCallback, useEffect } from "react";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { SelectChangeEvent } from "@mui/material";

import usePixelPerfect from "../../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { fetchMaterialsList } from "../../../redux/thunks/collections/materialsListThunk";
import { reset as resetAddMaterial, setArrayTransformationsMaterials, setFormAddTransformationMaterial } from "../../../redux/slices/inventory/transformation/addMaterialTransformationSlice";
import { reset } from "../../../redux/slices/collections/materialsListSlice";
import CustomInput from "../../custom/forms/default/CustomInput";
import CustomAlert from "../../custom/alerts/CustomAlert";
import CustomButton from "../../custom/buttons/default/CustomButton";
import { materialId as materialIdYup, quantity as quantityYup, validateYupSchema } from "../../../utils/forms/validationSchema";
import { setMessage, setOpenSnackbar, setType } from "../../../redux/slices/globalSnackbar/globalSnackbarSlice";
import CustomMaterialPickerInput from "../../custom/forms/inventory/transformation/CustomMaterialPickerInput";
import { setTransformationMaterialsModalStates } from "../../../redux/slices/inventory/transformation/transformationMaterialsModalsStatesSlice";


export default function AddMaterialTransformationForm() {
    const { PP } = usePixelPerfect();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { materials } =
        useAppSelector((state) => state.addMaterialTransformation);

    const { category, materialId, quantityMaterial, type, name, materialMeasure, materialNameTranslations } =
        useAppSelector((state) => state.addMaterialTransformation.form);

    const { page, selected, totalMaterialsList, materialsList } = useAppSelector(
        (state) => state.materialsList
    );

    const getMaterialsList = async () => {
        if (totalMaterialsList > materialsList.length) {
            await dispatch(fetchMaterialsList({ page: page, category: category }));
        }
    };

    useEffect(() => {
        getMaterialsList();
    }, [category, dispatch]);

    const resetForm = useCallback(async () => {
        dispatch(resetAddMaterial());
        dispatch(reset());
        dispatch(
            setTransformationMaterialsModalStates({ id: "addMaterial", value: false })
        );
    }, [dispatch]);

    const handleClickCloseModal = () => {
        resetForm();
    };

    const initialFormikValues = {
        materialId: materialId,
        quantityMaterial: quantityMaterial,
    };

    const validationSchema = validateYupSchema({
        materialId: materialIdYup,
        quantityMaterial: quantityYup,
    });

    const handleSubmit = async (data: {
        materialId: number,
        quantityMaterial: number,
    }) => {
        const payload = {
            category: category,
            materialId: Number(data.materialId),
            type: type,
            quantityMaterial: Number(data.quantityMaterial),
            name: name,
            materialNameTranslations: materialNameTranslations,
            materialMeasure: materialMeasure,
        };

        let repeatProduct = false;

        /* materials.map((material) => {
            if (material.materialId === formikAddMaterialTransformation.values.materialId)
                repeatProduct = true;
        }); */

        if (repeatProduct) {
            dispatch(setOpenSnackbar(true));
            dispatch(setMessage(t("Se ha producido un error: material repetido")));
            dispatch(setType("failedSnackbar"));
        } else {
            dispatch(setArrayTransformationsMaterials(payload));
            dispatch(setMessage(t("Material añadido")));
            dispatch(setType("sucessSnackbar"));
            dispatch(setOpenSnackbar(true));
        }
        handleClickCloseModal();
    };

    const formikAddMaterialTransformation = useFormik({
        initialValues: initialFormikValues,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
        validateOnChange: false,
    });

    const formikAddMaterialTransformationChange = useCallback(
        (
            event:
                | React.ChangeEvent<HTMLInputElement>
                | SelectChangeEvent<number | string>
        ) => {
            formikAddMaterialTransformation.setFieldValue(
                event.target.name,
                event.target.value
            );
            dispatch(
                setFormAddTransformationMaterial({
                    id: event.target.name,
                    value: event.target.value,
                })
            );
        },
        [formikAddMaterialTransformation]
    );


    return (
        <form noValidate onSubmit={formikAddMaterialTransformation.handleSubmit}>
            <CustomInput
                id="category"
                label={t("Categoría")}
                placeholder="Categoría de material"
                value={t(category)}
                labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
                inputHeight={PP(40)}
                inputBorderRadius={PP(8)}
                backgroundColor="#F6F6F6"
                extraStyle={{ marginTop: PP(24) }}
                disabled
            />
            <CustomMaterialPickerInput
                width={432}
                label={t("Material")}
                required
                id="materialId"
                error={t(formikAddMaterialTransformation.errors.materialId)}
            />
            <CustomInput
                id="quantityMaterial"
                label={t("Cantidad")}
                placeholder="00"
                formik={formikAddMaterialTransformation}
                value={formikAddMaterialTransformation.values.quantityMaterial}
                onChange={formikAddMaterialTransformationChange}
                labelStyles={{
                    fontSize: PP(14),
                    fontWeight: 700,
                }}
                inputHeight={PP(40)}
                inputBorderRadius={PP(4)}
                endAdornmentValue={selected?.measure}
                extraStyle={{ marginBottom: PP(16) }}
            />
            {/* Alerta informativa Para UCO */}
            {formikAddMaterialTransformation.values.materialId === 1 && (
                <CustomAlert
                    alertText={t("Ingresar peso NETO del material")}
                />
            )}
            <CustomButton
                width={432}
                height={44}
                noSpacing
                style={{ padding: "0px", marginTop: PP(24) }}
                type="submit"
                label={t("Agregar")}
                formik={formikAddMaterialTransformation}
            />
            <CustomButton
                noSpacing
                width={432}
                height={44}
                cancelButton
                onClick={handleClickCloseModal}
                formik={formikAddMaterialTransformation}
            />
        </form>
    );
}