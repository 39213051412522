import { createSlice } from "@reduxjs/toolkit";
import { fetchDeleteTransformation } from "../../../thunks/inventory/transformations/deleteTransformationThunk";

const initialState = {
  loading: false,
  status: -1,
  statusText: "",
};

export const deleteTransformationSlice = createSlice({
  name: "deleteMaterialTransferReducer",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = -1;
      state.statusText = "";
    },

    resetDeleteTransformation: () => initialState,
  },
  extraReducers: (builder) => {
    // Eliminar transformación
    builder.addCase(fetchDeleteTransformation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDeleteTransformation.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.statusText = action.payload.statusText;
      state.loading = false;
    });
    builder.addCase(fetchDeleteTransformation.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { resetStatus, resetDeleteTransformation } =
  deleteTransformationSlice.actions;

export default deleteTransformationSlice.reducer;
