import customTableCell from "../../styles/tables/customTableCell";
import PPD from "../../utils/modals/ppd";

const { tableCellStyle } = customTableCell;

export const customMiuTableCell = {
  styleOverrides: {
    root: {
      backgroundColor: "transparent",
      fontFamily: tableCellStyle.fontFamily,
      fontStyle: tableCellStyle.fontStyle,
      fontWeight: tableCellStyle.fontWeight,
      fontSize: tableCellStyle.fontSize,
      lineHeight: tableCellStyle.lineHeight,
      textTransform: "none",
    },
    head: {
      color: "#7D7D7D",
      border: "none",
    },
    body: {
      borderTop: `${PPD(1)} solid #E8E8E8`,
      borderBottom: `${PPD(1)} solid #E8E8E8`,
      color: "#585757",
      "&:first-of-type": {
        borderTop: `${PPD(1)} solid #E8E8E8`,
        borderBottom: `${PPD(1)} solid #E8E8E8`,
        borderLeft: `${PPD(1)} solid #E8E8E8`,
        borderRadius: `${PPD(8)} 0px 0px ${PPD(8)}`,
      },
      "&:last-of-type": {
        borderTop: `${PPD(1)} solid #E8E8E8`,
        borderBottom: `${PPD(1)} solid #E8E8E8`,
        borderRight: `${PPD(1)} solid #E8E8E8`,
        borderRadius: `0px ${PPD(8)} ${PPD(8)} 0px`,
      },
    },
  },
};
