import { createRef, useCallback, useEffect } from "react";

import { Box, Modal, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import CustomText from "../../../components/custom/texts/CustomText";
import usePixelPerfect from "../../../hooks/old/usePixelPerfect";
import CustomSelect from "../../../components/custom/forms/default/CustomSelect";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { fetchWarehousesList } from "../../../redux/thunks/warehouse/warehousesListThunk";
import { reset as resetWarehouseslist } from "../../../redux/slices/warehouse/warehouseListSlice";
import { validateYupSchema } from "../../../utils/forms/validationSchema";
import CustomInput from "../../../components/custom/forms/default/CustomInput";
import TransformationInputMaterial from "../../../components/inventory/transformations/TransformationInputMaterial";
import CustomAddMaterialModal from "../../../components/custom/modals/inventory/transformations/CustomAddMaterialModal";
import CustomSelectCategory from "../../../components/custom/modals/inventory/transformations/CustomSelectCategory";
import TransformationOutputMaterial from "../../../components/inventory/transformations/TransformationOutputMaterial";
import CustomSelectMaterialTransformationModal from "../../../components/custom/modals/inventory/transformations/CustomSelectMaterialTransformationModal";
import CustomEditTransformationMaterialModal from "../../../components/custom/modals/inventory/transformations/CustomEditTransformationMaterialModal";
import CustomDeleteTransformationMaterialModal from "../../../components/custom/modals/inventory/transformations/CustomDeleteTransformationMaterialModal";
import CustomButton from "../../../components/custom/buttons/default/CustomButton";
import { fetchCreateTransformation } from "../../../redux/thunks/inventory/transformations/createTransformationThunk";
import { setMessage, setOpenSnackbar, setType } from "../../../redux/slices/globalSnackbar/globalSnackbarSlice";
import { fetchAddMaterialTransformation } from "../../../redux/thunks/inventory/transformations/addMaterialTransformationThunk";
import { resetAllTransformMaterials } from "../../../redux/slices/inventory/transformation/addMaterialTransformationSlice";
import { resetCreateTransformation } from "../../../redux/slices/inventory/transformation/createTransformationSlice";
import { transformationDateFormat } from "../../../utils/old/transformationDateFormat";

interface InitialFormikValues {
    date: string,
    warehouse: number,
}

export default function CreateTransformation() {
    const { PP } = usePixelPerfect();
    const dispatch = useAppDispatch();
    const ref = createRef();
    const { t } = useTranslation();

    const navigate = useNavigate();


    const { materials } =
        useAppSelector((state) => state.addMaterialTransformation);

    const { warehousesList } = useAppSelector(
        (state) => state.warehouseList
    );

    const {
        selectCategory, addMaterial,
        selectMaterial, editQuantity,
        deleteMaterial
    } = useAppSelector((state) => state.transformationMaterialsModalsStates);

    const getWarehousesList = useCallback(() => {
        dispatch(
            fetchWarehousesList({
                page: 0,
            })
        );
    }, []);

    useEffect(() => {
        dispatch(resetWarehouseslist());
        getWarehousesList();
    }, [getWarehousesList]);

    const initialFormikValues: InitialFormikValues = {
        date: transformationDateFormat(new Date()),
        warehouse: -1,
    };

    const handleSubmit = async (data: InitialFormikValues) => {
        if (Number(data.warehouse) !== -1 && materials.length > 1) {
            try {
                const response = await dispatch(fetchCreateTransformation({ warehouseId: Number(data.warehouse) })).unwrap();
                // console.log('API Response:', response);
                const newTransformId = response.transformId;
                const promises = materials.map(material =>
                    dispatch(fetchAddMaterialTransformation({
                        transformId: newTransformId,
                        materialId: material.materialId,
                        quantityMaterial: material.quantityMaterial,
                        type: material.type
                    })).unwrap(),

                );
                // console.log(promises)
                await Promise.all(promises);
                dispatch(setMessage(t("Material transformado con éxito")));
                dispatch(setType("sucessSnackbar"));
                dispatch(setOpenSnackbar(true));
                navigate("/inventory/transformations")
                dispatch(resetAllTransformMaterials());
                dispatch(resetCreateTransformation());

            } catch (error) {
                // console.error('Error fetching data:', error);
                dispatch(setMessage(t("Error transformando material")));
                dispatch(setType("failedSnackbar"));
                dispatch(setOpenSnackbar(true));
            }
        } else {
            dispatch(setMessage(t("Completa los datos")));
            dispatch(setType("failedSnackbar"));
            dispatch(setOpenSnackbar(true));
        }
    };


    const validationSchema = validateYupSchema({
    });

    const formikEditTransformation = useFormik<InitialFormikValues>({
        initialValues: initialFormikValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
        validateOnChange: false,
    });


    const formikEditTransformationChange = useCallback(
        (
            event:
                | React.ChangeEvent<HTMLInputElement>
                | SelectChangeEvent<number | string>
        ) => {
            formikEditTransformation.setFieldValue(
                event.target.name,
                event.target.value
            );
        },
        [formikEditTransformation]
    );

    useEffect(() => {
    }, [materials])


    return (
        <>
            <form noValidate onSubmit={formikEditTransformation.handleSubmit}>
                <Box
                    sx={{
                        marginTop: PP(24),
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginBottom: PP(14),
                        }}
                    >

                        <CustomText
                            premediumWeight
                            noSpacing
                            bigSize
                            color="#1C1C1C"
                            style={{
                                lineHeight: PP(32),
                                marginRight: PP(8.23),
                            }}
                        >
                            {t("Crear transformación")}
                        </CustomText>
                        <CustomButton
                            width={250}
                            height={44}
                            noSpacing
                            style={{ padding: "0px" }}
                            type="submit"
                            label={t("Transformar material")}
                            formik={formikEditTransformation}
                            startIcon={<ChangeCircleOutlinedIcon />}
                        />
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <CustomInput
                            id="date"
                            labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
                            label={t("Fecha")}
                            value={formikEditTransformation.values.date}
                            onChange={formikEditTransformationChange}
                            formik={formikEditTransformation}
                            extraStyle={{ marginTop: PP(24) }}
                            disabled
                        />
                        <CustomSelect
                            id="warehouse"
                            value={formikEditTransformation.values.warehouse}
                            onChange={formikEditTransformationChange}
                            label={t("Bodega")}
                            items={warehousesList.map((warehouse) => {
                                return { label: warehouse.name, value: warehouse.id };
                            })}
                            extraStyle={{ marginTop: PP(24), width: "30%" }}
                            placeholder={t("Seleccionar bodega")}
                        />
                    </Box>
                    <TransformationInputMaterial />
                    {materials.length !== 0 && <TransformationOutputMaterial />}

                </Box>
            </form>
            {/* Materiales */}
            <Modal
                open={selectCategory}
                children={<CustomSelectCategory ref={ref} />}
            />
            <Modal
                open={addMaterial}
                children={<CustomAddMaterialModal ref={ref} />}
            />
            <Modal
                open={selectMaterial}
                children={<CustomSelectMaterialTransformationModal ref={ref} />}
            />
            <Modal
                open={editQuantity}
                children={<CustomEditTransformationMaterialModal ref={ref} />}
            />
            <Modal
                open={deleteMaterial}
                children={<CustomDeleteTransformationMaterialModal ref={ref} />}
            />
        </>
    )
}
