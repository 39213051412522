import posthog from "posthog-js";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup"; // Validaciones de Yup
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  LinearProgress,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField as MuiTextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { Edit as EditIcon, Close as CloseIcon } from "@mui/icons-material";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import EnhancedTableToolbar from "./EnhacedTableToolbar";
import EnhancedTableHead from "./EnhancedTableHead";
import {
  fetchPricesList,
  fetchUpdatePrice,
} from "../../../../redux/old/slices/pricesSlicer";
import { AuthContext } from "../../../../contexts/old/JWTContext";

const TextField = styled(MuiTextField)(({ spacing }) => ({ ...spacing }));

// Validación de Formik con Yup
const validationSchema = Yup.object({
  price: Yup.number()
    .typeError("Debe ser un número válido")
    .required("El precio es obligatorio"),
});

function EnhancedTable({ headCells }) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("customer");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState({ price: 0 });

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);

  // Redux Toolkit
  const loading = useSelector((state) => state.prices.showLoading);
  const prices = useSelector((state) => state.prices.prices);
  const dispatch = useDispatch();

  const selectedLanguage = localStorage.getItem("language");

  // Obtener precios
  useEffect(() => {
    getPrices();
  }, [modalEdit]);

  const getPrices = async () => {
    dispatch(fetchPricesList()); // Redux Toolkit Get
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = prices.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const editPrice = (price) => {
    setSelectedPrice(price);
    setModalEdit(true);
  };

  const initialValues = {
    price: selectedPrice.price,
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (data) => {
      updatePrice(data);
    },
  });

  const updatePrice = async (data) => {
    let selectedPriceId = selectedPrice.id;
    await dispatch(
      fetchUpdatePrice({ data: data, selectedPriceId: selectedPriceId })
    ); // Redux Toolkit Patch

    posthog.capture("update_standard_price", {
      data: {
        userId: user.id,
        id: selectedPrice.id,
        newPrice: data.price,
        currentPrice: selectedPrice.price,
        materialPriceId: selectedPrice.materialId,
      },
    });

    setModalEdit(false);
    setOpen(true);
    getPrices(); // Redux Toolkit Get
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <Paper>
        {loading && <LinearProgress my={2} />}
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table aria-labelledby="tableTitle" aria-label="enhanced table">
            <EnhancedTableHead
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={prices.length}
            />
            <TableBody>
              {prices.map((row, index) => (
                <TableRow hover tabIndex={-1} key={row.id}>
                  <TableCell align="left">
                    {row.material.nameTranslations
                      ? row.material.nameTranslations.translations[
                          selectedLanguage
                        ]
                      : row.material.name}
                  </TableCell>
                  <TableCell align="left">${row.price}</TableCell>
                  <TableCell padding="none" align="right">
                    <Box mr={2}>
                      <IconButton
                        aria-label="edit"
                        size="large"
                        onClick={() => editPrice(row)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Dialog
        open={modalEdit}
        onClose={() => setModalEdit(false)}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle id="form-dialog-title">
            {t("editPriceModal.Editar un precio")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t(
                "editPriceModal.Este será el nuevo precio por defecto para todos los negocios y hogares"
              )}
            </DialogContentText>
            <div style={{ marginTop: 15 }}>
              <TextField
                name="price"
                label={t("editPriceModal.precio")}
                variant="outlined"
                value={formik.values.price}
                onChange={formik.handleChange}
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                }
                fullWidth
                my={2}
                error={formik.touched.price && Boolean(formik.errors.price)}
                helperText={formik.touched.price && formik.errors.price}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModalEdit(false)} color="primary">
              {t("editPriceModal.cancelar")}
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {t("editPriceModal.actualizar")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={t("editPriceModal.Precio actualizado")}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}

export default EnhancedTable;
