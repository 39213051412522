import { tableHeadCellType } from "../../../interfaces/data/tableHeadCellInterface";

export const transformationsListTableHeadCells: tableHeadCellType[] = [
  {
    id: "date",
    alignment: "left",
    label: "Fecha",
    disablePadding: false,
  },
  {
    id: "warehouse",
    alignment: "left",
    label: "Bodega",
    disablePadding: false,
  },
  {
    id: "state",
    alignment: "left",
    label: "Material transformado",
    disablePadding: false,
  },
];
