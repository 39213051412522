import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type setTransformationMaterialsModalState = any;

interface SetTransformationModalStatePayload {
  id: setTransformationMaterialsModalState;
  value: boolean;
}
const initialState: {
  selectCategory: boolean;
  addMaterial: boolean;
  selectMaterial: boolean;
  deleteMaterial: boolean;
  editQuantity: boolean;
} = {
  selectCategory: false,
  addMaterial: false,
  selectMaterial: false,
  deleteMaterial: false,
  editQuantity: false,
};

export const transformationMaterialsModalsStatesSlice = createSlice({
  name: "transformationMaterialsModalsStatesReducer",
  initialState,
  reducers: {
    setTransformationMaterialsModalStates: (
      state: any,
      action: PayloadAction<SetTransformationModalStatePayload>
    ) => {
      // @ts-ignore
      state[action.payload.id] = action.payload.value;
    },
    reset: () => initialState,
  },
});

export const { setTransformationMaterialsModalStates, reset } =
  transformationMaterialsModalsStatesSlice.actions;

export default transformationMaterialsModalsStatesSlice.reducer;
