import { MouseEventHandler } from "react";

import { Box } from "@mui/material";

import usePixelPerfect from "../../../hooks/old/usePixelPerfect";
import { ReactComponent as CloseModalIcon } from "../../../assets/icons/modals/closeModal.svg";

interface CollectionsWarehouseFilterModalLayoutProps {
  noCloseButton?: boolean;
  handleClose?: MouseEventHandler<SVGSVGElement>;
  width?: number;
  children: React.ReactNode;
}

const CollectionsFilterByModalLayout = (
  props: CollectionsWarehouseFilterModalLayoutProps
) => {
  const { PP2 } = usePixelPerfect();

  const modalStyle = {
    backgroundColor: "#FFFFFF",
    borderRadius: PP2(8),
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: props.width ? PP2(props.width) : PP2(490),
    padding: PP2(24),
    height: "auto",
  };

  return (
    <Box sx={modalStyle}>
      {!props.noCloseButton && (
        <CloseModalIcon
          onClick={props.handleClose}
          style={{
            width: PP2(24),
            height: PP2(24),
            position: "absolute",
            top: PP2(20),
            right: PP2(28),
            cursor: "pointer",
          }}
        />
      )}
      <div
        style={{ maxHeight: `calc(100vh - ${PP2(100)}px)`, overflowY: "auto" }}
      >
        {props.children}
      </div>
    </Box>
  );
};

export default CollectionsFilterByModalLayout;
