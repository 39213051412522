import { Button, ButtonProps } from "@mui/material";
import { FormikProps } from "formik";

import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import { useTranslation } from "react-i18next";

type CustomButtonProps = ButtonProps & {
  label?: React.ReactNode;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  noSpacing?: Boolean;
  backgroundColor?: string;
  cancelButton?: Boolean;
  deleteButton?: Boolean;
  width?: number | string;
  height?: number | string;
  style?: React.CSSProperties;
  formik?: FormikProps<any>; //reemplazar luego el any acorde a cada form
  disabled?: boolean;
};

export default function CustomButton(props: CustomButtonProps) {
  const { PP, PP2 } = usePixelPerfect();
  const { t } = useTranslation();

  return (
    <Button
      onClick={props.onClick}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      type={props.type}
      variant="contained"
      disabled={props.disabled}
      sx={{
        fontSize: PP(16),
        borderRadius: PP(8),
        ...(!props.noSpacing && { margin: `${PP(12)} ${PP(0)}` }),
        backgroundColor: props.disabled ? "#213059" : props.backgroundColor,
        color: props.disabled ? "#969696" : props.color,
        height: props.height ? PP(props.height) : PP2(44),
        width: props.width ? PP(props.width) : PP2(425),
        ...(props.style && props.style),
        ...(props.cancelButton && {
          color: "#7D7D7D",
          fontWeight: "700",
          backgroundColor: "transparent",
          "&:hover": {
            backgroundColor: "transparent",
          },
          "&:active": {
            backgroundColor: "transparent",
          },
        }),
        ...(props.deleteButton && {
          color: "#EBEBEB",
          fontWeight: "700",
          backgroundColor: "#E51A42",
          border: `${PP(1)} solid #E51A42`,
          "&:hover": {
            backgroundColor: "rgba(229, 26, 66, 0.3)",
            color: "#E51A42",
          },
          "&:active": {
            backgroundColor: "rgba(229, 26, 66, 0.3)",
            color: "#E51A42",
          },
        }),
      }}
      style={props.style && props.style}
      {...(props.formik && {
        disabled: props.formik.isSubmitting || props.disabled,
      })}
    >
      {props.cancelButton ? t("modals.cancelar") : props.label}
    </Button>
  );
}
