import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { exportAttachedFilesModalsSliceType } from "../../../interfaces/redux/exportAttachedFilesModalsSliceInterface";

type ExportAttachedFilesModalKeys = any;

interface SetExportModalStatePayload {
  id: ExportAttachedFilesModalKeys;
  value: boolean;
}
const initialState: exportAttachedFilesModalsSliceType = {
  selectTypeFile: false,
  attachFile: false,
  deleteAttachFile: false,
  fullScreen: false,
};

export const transactionAttachedFilesModalStatesSlice = createSlice({
  name: "transactionAttachedFilesModalStatesReducer",
  initialState,
  reducers: {
    setTransactionAttachedFilesModalState: (
      state: any,
      action: PayloadAction<SetExportModalStatePayload>
    ) => {
      // @ts-ignore
      state[action.payload.id] = action.payload.value;
    },
    reset: () => initialState,
  },
});

export const { setTransactionAttachedFilesModalState, reset } =
  transactionAttachedFilesModalStatesSlice.actions;

export default transactionAttachedFilesModalStatesSlice.reducer;
