import "moment/locale/es";
import moment from "moment-timezone";

export const formatCollectionHour = (initialTime, finalTime) => {
  const formatedHour = `${moment(initialTime, "HH:mm:ss").format(
    "h:mm"
  )} - ${moment(finalTime, "HH:mm:ss").format("h:mm a")}`;
  return formatedHour;
};

export const formatHour = (time) => {
  return moment(time, "HH:mm:ss").format("h:mm a");
};
