import { createAsyncThunk } from "@reduxjs/toolkit";

import { destroyRedux } from "../../../utils/old/api";

export const fetchDeleteUserCustomPrice = createAsyncThunk(
  "deleteUserCustomPrice/fetchDelete",
  async (payload: { id: number }, thunkAPI) => {
    try {
      const response = await destroyRedux(`/material-price/${payload.id}`);
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    } catch (error) {
      const errors = thunkAPI.rejectWithValue(error);
      return errors;
    }
  }
);
