// Layouts
import { DashboardLayout } from "./layouts/DashboardLayout";
import { AuthLayout } from "./layouts/AuthLayout";

// Guards
import AuthGuard from "./components/old/guards/old/AuthGuard";

// Auth components
import SignIn from "./pages/old/auth/old/SignIn";
import SignUp from "./pages/old/auth/old/SignUp";
import ResetPassword from "./pages/old/auth/old/ResetPassword";
import Page404 from "./pages/old/auth/old/Page404";
import Page500 from "./pages/old/auth/old/Page500";

// Page components
import AllCollections from "./pages/collections/AllCollections";
import CollectionDetails from "./pages/collections/CollectionDetails";

import PriceList from "./pages/old/prices/old/PriceList";

import UserList from "./pages/old/users/old/individuals/UserList";
import UserProfile from "./pages/old/users/old/individuals/UserProfile";

import WithdrawalList from "./pages/old/withdrawals/old/WithdrawalList";
import VerificationList from "./pages/inventory/verification/VerificationList";
import VerificationNew from "./pages/inventory/verification/VerificationNew";
import AdjustmentList from "./pages/inventory/adjustment/VerificationList";
import AdjustmentNew from "./pages/inventory/adjustment/VerificationNew";
import CollectionsRoutes from "./pages/old/routes/old/CollectionsRoutes";
import BusinessList from "./pages/old/users/old/businesses/BusinessList";
import Profile from "./pages/old/users/old/businesses/BusinessProfile";
import Control from "./layouts/Control";
import ControlView from "./pages/control/ControlView";
import CollectionPageHistory from "./pages/collections/CollectionPageHistory";
import Transactions from "./pages/transactions/Transactions";
import TransactionsDetails from "./pages/transactions/TransactionsDetails";
import Clients from "./pages/clients/Clients";
import ChangePassword from "./pages/settings/security/ChangePassword";
import CurrentPasswordValidation from "./pages/settings/security/CurrentPasswordValidation";
import TransferList from "./pages/inventory/transfer/TransferList";
import TransferDetails from "./pages/inventory/transfer/TransferDetails";
import TransformationList from "./pages/inventory/transformation/TransformationList";
import TransformationDetails from "./pages/inventory/transformation/TransformationDetails";
import CreateTransformation from "./pages/inventory/transformation/CreateTransformation";

const routes = [
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <SignIn />,
      },
    ],
  },
  {
    path: "collections",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <AllCollections />,
      },
      {
        path: "detail/:id",
        element: <CollectionDetails />,
      },
      {
        path: "detail/:id/page-history",
        element: <CollectionPageHistory />,
      },
    ],
  },
  {
    path: "prices",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <PriceList />,
      },
    ],
  },
  {
    path: "users",
    element: <DashboardLayout />,
    children: [
      {
        path: "businesses",
        element: <BusinessList />,
      },
      {
        path: "businesses/:id",
        element: <Profile />,
      },
      {
        path: "individuals",
        element: <UserList />,
      },
      {
        path: "individuals/:id",
        element: <UserProfile />,
      },
    ],
  },
  {
    path: "users",
    element: <DashboardLayout />,
    children: [
      {
        path: "businesses",
        element: <BusinessList />,
      },
      {
        path: "businesses/:id",
        element: <Profile />,
      },
      {
        path: "individuals",
        element: <UserList />,
      },
      {
        path: "individuals/:id",
        element: <UserProfile />,
      },
    ],
  },
  {
    path: "withdrawals",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <WithdrawalList />,
      },
    ],
  },
  {
    path: "inventory",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "transfers",
        element: <TransferList />,
      },
      {
        path: "transfers/:id",
        element: <TransferDetails />,
      },
      {
        path: "transformations",
        element: <TransformationList />,
      },
      {
        path: "transformations/create",
        element: <CreateTransformation />,
      },
      {
        path: "transformations/detail/:id",
        element: <TransformationDetails />,
      },
    ],
  },

  {
    path: "verification",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <VerificationList />,
      },
    ],
  },
  {
    path: "verification",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <VerificationList />,
      },
      {
        path: "new",
        element: <VerificationNew />,
      },
    ],
  },

  {
    path: "verification",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <VerificationList />,
      },
    ],
  },
  {
    path: "adjustment",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <AdjustmentList />,
      },
      {
        path: "new",
        element: <AdjustmentNew />,
      },
    ],
  },

  {
    path: "transactions",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "sales",
        element: <Transactions />,
      },
      {
        path: "sales/detail/:id",
        element: <TransactionsDetails />,
      },
      {
        path: "clients",
        element: <Clients />,
      },
    ],
  },

  // {
  //   path: "transfers",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <TransferList />,
  //     }
  //   ],
  // },
  // {
  //   path: "compact_pet",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <CompactPet />,
  //     }
  //   ],
  // },
  {
    path: "routes",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <CollectionsRoutes />,
      },
    ],
  },
  {
    path: "control",
    element: <Control />,
    children: [
      {
        path: "",
        element: <ControlView />,
      },
    ],
  },
  {
    path: "settings",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "changePasswordStep1",
        element: <CurrentPasswordValidation />,
      },
      {
        path: "changePasswordStep2",
        element: <ChangePassword />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
