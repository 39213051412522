// @ts-ignore

import { useEffect } from "react";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Chip } from "@mui/material";

import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import CustomText from "../custom/texts/CustomText";
import { ReactComponent as AcuIcon } from "../../assets/icons/chips/droplet.svg";
import { ReactComponent as AproIcon } from "../../assets/icons/chips/aproIcon.svg";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { CollectionMaterialsType } from "../../interfaces/data/collectionMaterialsInterface";

export default function CollectionMaterialWeightControl() {
  const { id } = useParams();
  const { PP } = usePixelPerfect();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { collectionDetails } = useAppSelector(
    (state) => state.collectionDetails
  );

  // console.log(collectionDetails);

  const includeACU: CollectionMaterialsType[] =
    collectionDetails && collectionDetails.items
      ? collectionDetails.items.filter((item) => item.materialId === 1)
      : [];

  // console.log(includeACU);

  const includeAPRO: CollectionMaterialsType[] =
    collectionDetails && collectionDetails.items
      ? collectionDetails.items.filter((item) => item.materialId !== 1)
      : [];

  const calculateKgApro = (
    includeAPROArray: CollectionMaterialsType[]
  ): number => {
    const kgAproTotal = includeAPROArray.reduce(
      (acumulador, item) =>
        item.material.measure === "kg"
          ? acumulador + item.quantity
          : acumulador + 0,
      0
    );

    return kgAproTotal;
  };

  const kgApro = includeAPRO.length !== 0 ? calculateKgApro(includeAPRO) : 0;

  useEffect(() => { }, [collectionDetails.items, dispatch]);

  return (
    <Box width="50%">
      <Box height="auto">
        <Box
          height="100%"
          width="auto"
          sx={{
            border: `${PP(1)} solid #E8E8E8`,
            borderRadius: PP(8),
            padding: PP(24),
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              marginBottom: PP(8),
            }}
          >
            <CustomText noSpacing color="#969696" mediumSize premediumWeight>
              {t("collectionRequestInformation.collectionMaterialWeightControl.Total pesado")}
            </CustomText>
            <CustomText noSpacing color="#969696" mediumSize premediumWeight>
              {t("collectionRequestInformation.collectionMaterialWeightControl.Tipo de recolección")}
            </CustomText>
          </Box>

          <>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <CustomText color="#1C1C1C">
                {includeACU.length === 0
                  ? "0 kg" + t("collectionRequestInformation.collectionMaterialWeightControl.(Neto)")
                  : `${includeACU[0].quantity.toFixed(2)} kg ${t("collectionRequestInformation.collectionMaterialWeightControl.(Neto)")}`}
              </CustomText>

              <Chip
                style={{
                  borderRadius: PP(100),
                  borderWidth: PP(1),
                  width: "auto",
                  height: "auto",
                }}
                // @ts-ignore
                variant={"canceled"}
                icon={
                  <AcuIcon
                    style={{
                      width: PP(18),
                      height: PP(18),
                    }}
                  />
                }
                label={
                  <CustomText
                    noSpacing
                    mediumWeight
                    style={{ fontSize: PP(14), margin: `${PP(5)} 0px` }}
                  >
                    {t("collectionRequestInformation.collectionMaterialWeightControl.ACU")}
                  </CustomText>
                }
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <CustomText color="#1C1C1C"> {kgApro.toFixed(2)} kg</CustomText>
              <Chip
                style={{
                  borderRadius: PP(100),
                  borderWidth: PP(1),
                  width: "auto",
                  height: "auto",
                }}
                // @ts-ignore
                variant={"canceled"}
                icon={<AproIcon style={{ width: PP(18), height: PP(18) }} />}
                label={
                  <CustomText
                    noSpacing
                    mediumWeight
                    style={{ fontSize: PP(14), margin: `${PP(5)} 0px` }}
                  >
                    {t("collectionRequestInformation.collectionMaterialWeightControl.Aprovechable")}
                  </CustomText>
                }
              />
            </Box>
          </>
        </Box>
      </Box>
    </Box>
  );
}
