import { forwardRef, Fragment, useCallback } from "react";

import { Divider } from "@mui/material";

import useAppDispatch from "../../../../hooks/old/useAppDispatch";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import CollectionAttachFileModalLayout from "../../../../layouts/collections/modals/CollectionAttachFileModalLayout";
import { setCollectionAttachedFilesModalState } from "../../../../redux/slices/collections/collectionAttachedFilesModalStatesSlice";
import CustomText from "../../texts/CustomText";
import CollectionAttachNewFileForm from "../../../collections/CollectionAttachNewFileForm";
import { useTranslation } from "react-i18next";

export default forwardRef(function CustomAttachNewFileModal() {
  const dispatch = useAppDispatch();
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();

  const resetForm = useCallback(async () => {
    dispatch(
      setCollectionAttachedFilesModalState({
        id: "attachFile",
        value: false,
      })
    );
  }, [dispatch]);

  const handleClickCloseModal = (event: React.MouseEvent<SVGSVGElement>) => {
    resetForm();
  };

  const handleBackClick = useCallback(async () => {
    dispatch(
      setCollectionAttachedFilesModalState({
        id: "attachFile",
        value: false,
      })
    );
    dispatch(
      setCollectionAttachedFilesModalState({
        id: "selectTypeFile",
        value: true,
      })
    );
  }, [dispatch]);

  return (
    <CollectionAttachFileModalLayout handleClose={handleClickCloseModal}>
      <Fragment>
        <CustomText
          showBackArrowButton
          noSpacing
          bigSize
          premediumWeight
          color="#1C1C1C"
          style={{ marginBottom: PP(24) }}
          onClick={handleBackClick}
        >
          {t(
            "collectionAttachedFiles.customAttachNewFileModal.Adjuntar documento"
          )}
        </CustomText>
        <Divider sx={{ margin: "0px" }} />
        <CollectionAttachNewFileForm />
      </Fragment>
    </CollectionAttachFileModalLayout>
  );
});
