// collectionPageHistoryTableHeadCells.ts

import { useTranslation } from "react-i18next";
import { tableHeadCellType } from "../../../interfaces/data/tableHeadCellInterface";

export const collectionPageHistoryTableHeadCells: tableHeadCellType[] = [
  {
    id: "send_at",
    alignment: "left",
    label: "Fecha",
    disablePadding: false,
  },
  {
    id: "person_email",
    alignment: "left",
    label: "Editado por",
    disablePadding: false,
  },
  {
    id: "event_name",
    alignment: "left",
    label: "Detalle",
    disablePadding: false,
  },
];
