import {
  // Checkbox,
  TableCell,
  TableHead,
  TableRow,
  // TableSortLabel,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const EnhancedTableHead = (props) => {
  const {
    headCells,
    // onSelectAllClick,
    // order,
    // orderBy,
    // // numSelected,
    // // rowCount,
    // onRequestSort,
  } = props;
  // const createSortHandler = (property) => (event) => {
  //   onRequestSort(event, property);
  // };
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            // sortDirection={orderBy === headCell.id ? order : false}
          >
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            > */}
            {t(`tables.tableHeadCells.${headCell.label}`)}
            {/* </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
