export const license =
  process.env.REACT_APP_SERVER_LICENSE ||
  "6bd103f2-e6d2-47ee-bbed-673bfb935460";

export const collectionBadStates = [
  { finished: "Finalizada" },
  { failed: "Fallida" },
  { canceled: "Cancelada" },
];

export const materialsPricesTypes = {
  user: "Precio Personalizado",
  business: "Precio Personalizado",
  country: "Precio Estándar",
};

export const citiesLatLng = [
  { id: 0, lat: 4.570868, lng: -74.297333 }, // 0 cuando el usuario No ha seleccionado Ciudad- el 0 se tomará cómo Colombia en general-país
  { id: 1, lat: 10.963889, lng: -74.796387 }, // 1: Barranquilla
  { id: 2, lat: 10.983, lng: -74.95 }, // 2: Puerto Colombia
  { id: 3, lat: 4.624335, lng: -74.063644 }, // 3: Bogotá
];

export const AccountsTypes = [
  { id: 1, accountType: "checking", accountTypeName: "CUENTA CORRIENTE" },
  { id: 2, accountType: "savings", accountTypeName: "CUENTA DE AHORROS" },
];

export const collectionHours = [
  {
    value: "08:00:00",
    label: "8:00 am",
  },
  {
    value: "09:00:00",
    label: "9:00 am",
  },
  {
    value: "10:00:00",
    label: "10:00 am",
  },
  {
    value: "11:00:00",
    label: "11:00 am",
  },
  {
    value: "12:00:00",
    label: "12:00 pm",
  },
  {
    value: "13:00:00",
    label: "1:00 pm",
  },
  {
    value: "14:00:00",
    label: "2:00 pm",
  },
  {
    value: "15:00:00",
    label: "3:00 pm",
  },
  {
    value: "16:00:00",
    label: "4:00 pm",
  },
  {
    value: "17:00:00",
    label: "5:00 pm",
  },
  {
    value: "18:00:00",
    label: "6:00 pm",
  },
];

export const collectionHoursIntialTime = [
  {
    value: "08:00:00",
    label: "8:00 am",
  },
  {
    value: "09:00:00",
    label: "9:00 am",
  },
  {
    value: "10:00:00",
    label: "10:00 am",
  },
  {
    value: "11:00:00",
    label: "11:00 am",
  },
  {
    value: "12:00:00",
    label: "12:00 pm",
  },
  {
    value: "13:00:00",
    label: "1:00 pm",
  },
  {
    value: "14:00:00",
    label: "2:00 pm",
  },
  {
    value: "15:00:00",
    label: "3:00 pm",
  },
  {
    value: "16:00:00",
    label: "4:00 pm",
  },
  {
    value: "17:00:00",
    label: "5:00 pm",
  },
];

export const daysForCollect = [
  { label: "Lunes", value: "monday" },
  { label: "Martes", value: "tuesday" },
  { label: "Miércoles", value: "wednesday" },
  { label: "Jueves", value: "thursday" },
  { label: "Viernes", value: "friday" },
  { label: "Sábado", value: "saturday" },
];

export const recurrentDaysForCollect = {
  monday: "Lunes",
  tuesday: "Martes",
  wednesday: "Miércoles",
  thursday: "Jueves",
  friday: "Viernes",
  saturday: "Sábado",
};

export const regimenBusinessOptions = [
  { label: "Común", value: "comun" },
  { label: "Simplificado", value: "simplified" },
  { label: "Especial", value: "special" },
];

export const mapStyles = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#242f3e",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#746855",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#242f3e",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#d59563",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#d59563",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#263c3f",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#6b9a76",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#38414e",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#212a37",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9ca5b3",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#746855",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#1f2835",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#f3d19c",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        color: "#2f3948",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#d59563",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#17263c",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#515c6d",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#17263c",
      },
    ],
  },
];

export const failedOptions = [
  "Camión se averió",
  "El camión se llenó",
  "Envió fuera de ventana horaria",
  "Falta de tiempo",
  "Fue cancelado por el comercial",
  "Los datos están errados",
  "Botaron el reciclaje",
  "El punto está cerrado definitivamente",
  "El punto está cerrado temporalmente",
  "Ya no reciclan con Muta",
  "No me atendieron (en la franja horaria)",
  "No tiene residuos",
  "No tienen big bag",
  "No tienen pimpinas",
  "Otra empresa les recogió",
  "Residuos contaminados",
  "Reasignado a otro recolector",
];

export const paymentMethodOptions = [
  { label: "Efectivo", value: "cash" },
  { label: "Transferencia", value: "bank_transfer" },
  { label: "Mixto", value: "mixed" },
];

export const documentTypeOptions = [
  { label: "Cédula", value: "cc" },
  { label: "Nit", value: "nit" },
];

export const documentTypeOptionsObject = { cc: "Cédula", nit: "Nit" };

export const entityBusinessTypes = [
  {
    label: "SELECCIONA EL TIPO DE NEGOCIO",
    value: "SELECCIONA EL TIPO DE NEGOCIO",
  },
  { label: "Restaurante", value: "restaurant" },
  { label: "Minorista", value: "retail" },
  { label: "Fábricas", value: "industry" },
  { label: "Institución educativa", value: "educational" },
  { label: "Propiedad de horizontal", value: "building" },
  { label: "Hoteles", value: "hotel" },
  { label: "Fabricación de alimentos y bebidas", value: "food" },
  { label: "Proovedores de entrenimiento", value: "entertainment" },
  { label: "Prestación de servicios de salud", value: "health" },
  { label: "Constructoras", value: "construction" },
  { label: "Administración Gobierno", value: "government" },
  { label: "Servicios de mantenimiento", value: "workshop" },
  { label: "Servicios de bienestar y fitness", value: "wellness" },
  { label: "Servicios medioambientales", value: "enviromental" },
  {
    label: "Transporte, logística, cadena de suministro y almacenamiento",
    value: "logistic",
  },
  { label: "Caja de compensación", value: "compensation" },
  { label: "Mayorista", value: "factory" },
  { label: "Centros comerciales", value: "mall" },
  { label: "Servicios financieros y legales", value: "law" },
  { label: "Servicios funerarios", value: "funeral" },
  { label: "Sector tecnológico", value: "technology" },
  { label: "Entidades sin ánimo de lucro", value: "special" },
  { label: "Oficinas", value: "office" },
  { label: "Otros", value: "other" },
];
