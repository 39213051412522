import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
} from "react";

import moment from "moment";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "dayjs/locale/en";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box, TextField, ThemeProvider } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { ReactComponent as DatePickerIcon } from "../../../../../assets/icons/calendar/datePicker.svg";
import { ReactComponent as SwitchArrowDatePickerIcon } from "../../../../../assets/icons/calendar/switchArrowDatePicker.svg";
import { ReactComponent as LeftArrowDatePickerIcon } from "../../../../../assets/icons/calendar/leftArrowDatePicker.svg";
import { ReactComponent as RightArrowDatePickerIcon } from "../../../../../assets/icons/calendar/rightArrowDatePicker.svg";
import usePixelPerfect from "../../../../../hooks/old/usePixelPerfect";
import theme from "../../../../../theme/customThemesOwnComponents/DatePickerInput";
import CustomText from "../../../texts/CustomText";
import { daysOfWeekSpanish } from "../../../../../utils/constants/calendar/daysWeekCalendar";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
// import { fetchTransferDetails } from "../../../../../redux/thunks/inventory/transfer/transferDetailsThunk";



interface endDateProps {
    width?: string | number;
    setEndDate: Dispatch<SetStateAction<string>>;
    endDate: string | Date;
    marginTop?: string | number;
}

const InputFormat = "DD[/]MM[/]YYYY";

const formatDate = (date: Date | string) => {
    return moment(date.toString()).format("YYYY-MM-DD");
};

// Establece el idioma español para dayjs
dayjs.locale("es");

// Función para deshabilitar domingos
const shouldDisableSunday = (date: Date | string) => {
    const dayjsDate = dayjs(date); // Convierte a dayjs
    return dayjsDate.day() === 0; // Devuelve true para los domingos, false para los demás días
};

export default function endDate(props: endDateProps) {
    const { PP2, PP } = usePixelPerfect();
    const dispatch = useAppDispatch();
    const { id } = useParams();

    const DateInputStyle = {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            borderRadius: PP2(8),
            "& fieldset": {
                borderColor: "transparent",
                border: "none",
            },
            "&:hover fieldset": {
                borderColor: "transparent",
            },
            "&.Mui-focused fieldset": {
                borderColor: "transparent",
            },
        },
    };
    const { t } = useTranslation();

    const { transferDetails } = useAppSelector(
        (state) => state.transferDetails
    );


    const language = localStorage.getItem("language");

    const handleDateChange = (newValue: Date | null | string) => {
        if (newValue) {
            props.setEndDate(formatDate(newValue));
        }
    };

    // const getTransferDetails = useCallback(async () => {
    //     if (id !== undefined) await dispatch(fetchTransferDetails({ id: id }));
    // }, [transferDetails]);

    useEffect(() => {
        const fetchData = async () => {
            // await getTransferDetails();
            if (transferDetails.endDate) {
                props.setEndDate(transferDetails.endDate);
            }
        };

        fetchData();
    }, [transferDetails.endDate]);

    return (
        <Box
            sx={[
                {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    backgroundColor: "#FFFFFF",
                    width: props.width ? props.width : PP2(428),
                },
            ]}
        >
            <Box width={props.width ? props.width : PP2(428)}>
                <Box
                    width={props.width ? props.width : PP2(428)}
                    marginBottom={PP(6)}
                    marginTop={props.marginTop ? props.marginTop : 0}
                >
                    <ThemeProvider theme={theme}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            dayOfWeekStartsOn={0}
                            adapterLocale={language || undefined}
                        >
                            <Box width={props.width ? props.width : PP2(428)}>
                                <Box display="flex" flexDirection="row" alignItems="center" >
                                    <CustomText
                                        mediumSize
                                        mediumWeight
                                        noSpacing
                                        color="#969696"
                                    >
                                        {t("Fecha recibido")}
                                    </CustomText>
                                </Box>

                                <DesktopDatePicker
                                    inputFormat={InputFormat}
                                    disableMaskedInput
                                    disabled
                                    value={props.endDate}
                                    shouldDisableDate={shouldDisableSunday}
                                    dayOfWeekFormatter={(day) => daysOfWeekSpanish[day]}
                                    onChange={handleDateChange}
                                    renderInput={(params) => (
                                        <TextField
                                            disabled
                                            {...params}
                                            sx={{
                                                ...DateInputStyle,
                                                "& input": {
                                                    width: props.width ? props.width : PP2(428),
                                                },
                                            }}
                                            inputProps={{
                                                ...params.inputProps,
                                                readOnly: true,
                                            }}
                                        />
                                    )}
                                    components={{
                                        OpenPickerIcon: DatePickerIcon,
                                        SwitchViewIcon: SwitchArrowDatePickerIcon,
                                        LeftArrowIcon: LeftArrowDatePickerIcon,
                                        RightArrowIcon: RightArrowDatePickerIcon,
                                    }}
                                    PopperProps={{
                                        placement: "top-end",
                                    }}
                                />
                            </Box>
                        </LocalizationProvider>
                    </ThemeProvider>
                </Box>
            </Box>
        </Box>
    );
}
