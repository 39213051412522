import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

import {
  Button,
  Card as MuiCard,
  CardContent,
  Typography,
  Avatar as MuiAvatar,
  Dialog,
  DialogTitle,
  DialogContent,
  Alert,
  Select,
  MenuItem,
  DialogActions,
  FormControl,
  Paper,
  FormControlLabel,
  Checkbox,
  Snackbar,
  FormLabel,
  RadioGroup,
  Radio,
  FormGroup,
  IconButton,
  TextField as MuiTextField,
  Grid as MuiGrid,
} from "@mui/material";
import { Edit as EditIcon, Close as CloseIcon } from "@mui/icons-material";
import MailIcon from "@mui/icons-material/Mail";
import styled from "@emotion/styled";
import { Box, spacing } from "@mui/system";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import {
  fetchBusiness,
  fetchCreateNotification,
  fetchUpdateBusiness,
} from "../../../../../redux/old/slices/businessSlicer";
import { documentTypeOptions } from "../../../../../utils/old/constants";

const TextField = styled(MuiTextField)(spacing);

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const Centered = styled.div`
  text-align: center;
`;

const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;

const BubbleWrapper = styled.div`
  .ql-editor {
    padding: 0;
    &.ql-blank:before {
      left: 0;
    }
  }
  .ql-tooltip {
    z-index: 9999;
  }
`;

const Grid = styled(MuiGrid)(spacing);

export default function BasicBusinessDataCard() {
  const { id } = useParams();

  const [editModal, setEditModal] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  const [snackTxt, setSnackTxt] = useState("hola");
  const [showSnack, setShowSnack] = useState(false);
  const [value, setValue] = useState(""); //Para manejar msj predeterminados - contactar usuario seleccionado
  const [state, setState] = useState({
    email: false,
    sms: false,
    app: true,
  });
  const { t } = useTranslation();

  //Redux Toolkit
  const loading = useSelector((state) => state.business.showLoading);
  const business = useSelector((state) => state.business.business);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBusiness(id)); //Redux Toolkit Get
  }, [editModal]);

  //--------------------Actualización de Datos Básicos del Negocio-------------------//

  const initialValuesBusiness = {
    email: business.email,
    name: business.name,
    legal_name: business.legal_name,
    document_type: business.document_type,
    document_number: business.document_number,
    phone: business.phone,
    countryId: 1,
    payment_method: business.payment_method,
    operator: business.operator,
    internal_code:
      business.internal_code === null ? "" : business.internal_code,
  };

  function validationSchema() {
    return {
      email: Yup.string()
        .email(t("editInfoModal.validations.Correo no válido"))
        .required(t("editInfoModal.validations.Correo requerido")),
      name: Yup.string()
        .min(3, t("editInfoModal.validations.Mín 3 caracteres"))
        .required(t("editInfoModal.validations.Nombre requerido")),
      legal_name: Yup.string()
        .min(3, t("editInfoModal.validations.Mín 3 caracteres"))
        .required(t("editInfoModal.validations.Nombre legal requerido")),
      document_type: Yup.string()
        .required(t("editInfoModal.validations.Tipo de documento requerido"))
        .oneOf(["cc", "nit"]),
      document_number: Yup.string()
        .required(t("editInfoModal.validations.Número de documento requerido"))
        .matches(
          /^[0-9]+$/,
          t(
            "editInfoModal.validations.El número de documento debe contener justamente sólo números, sin espacios ni guion"
          )
        )
        .matches(
          /^[^\s-]+$/,
          t(
            "editInfoModal.validations.El número de documento no puede contener espacios ni guiones"
          )
        ),
      phone: Yup.number().required(
        t("editInfoModal.validations.Teléfono requerido")
      ),
      internal_code: Yup.string().matches(
        /^[a-zA-Z0-9]+$/,
        t(
          "editInfoModal.validations.Ingresa una combinación válida de números y letras"
        )
      ),
    };
  }

  const formikBusiness = useFormik({
    initialValues: initialValuesBusiness,
    enableReinitialize: true,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) => {
      updateBusiness(data);
    },
  });

  const updateBusiness = async (data) => {
    dispatch(fetchUpdateBusiness({ data: data, id: id })); //Redux Toolkit Patch
    dispatch(fetchBusiness(id)); //Redux Toolkit Get
    setEditModal(false);
    setSnackTxt(t("Negocio actualizado"));
    setShowSnack(true);
  };

  //--------------Manjenado el envío de mensajes, correos, notificaciones personalizadas hacia el negocio:

  const handleChangeContactMessage = (event) => {
    setValue(event.target.value);
  };
  const handleChangeCheckBoxContactModal = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const { email, sms, app } = state;
  const initialValuesNotification = {
    email: email,
    sms: sms,
    app: app,
  };
  const formikNotification = useFormik({
    initialValues: initialValuesNotification,
    enableReinitialize: true,
    onSubmit: async (data) => {
      createNotification(data);
    },
  });
  const createNotification = async (data) => {
    data.business_id = id;
    dispatch(fetchCreateNotification(data));
    setContactModal(false);
    setSnackTxt(t("Notificación enviada al negocio"));
    setShowSnack(true);
  };

  return (
    <React.Fragment>
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {t("Empresa")}
          </Typography>

          <Spacer mb={4} />

          <Centered>
            {business.name && <Avatar>{business.name[0].toUpperCase()}</Avatar>}
            <Typography variant="body2" component="div" gutterBottom>
              <Box fontWeight="fontWeightMedium">{business.name}</Box>
              <Box fontWeight="fontWeightRegular" fontStyle="italic">
                {business.operator}
              </Box>
              <Box fontWeight="fontWeightRegular">{business.email}</Box>
              <Box fontWeight="fontWeightRegular">{business.phone}</Box>
              <Box fontWeight="fontWeightRegular">${business.wallet}</Box>
            </Typography>
            <Box mb={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => setEditModal(true)}
              >
                <EditIcon />
                {t("Editar")}
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => setContactModal(true)}
              >
                <MailIcon />
                {t("Contactar")}
              </Button>
            </Box>
          </Centered>
        </CardContent>
      </Card>

      {/* -----------------------------------------MODALES ------------------------------------------------------------- */}

      {/* 1) Modal Editar Negocio */}

      <Dialog
        open={editModal}
        onClose={() => setEditModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={formikBusiness.handleSubmit}>
          <DialogTitle id="form-dialog-title">
            {t("editInfoModal.Actualizar negocio")}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={6}>
              <Grid item md={6} my={2}>
                <Select
                  name="document_type"
                  label="document_type"
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formikBusiness.values.document_type}
                  onChange={formikBusiness.handleChange}
                >
                  <MenuItem
                    key={"label"}
                    value={t("editInfoModal.SELECCIONA EL TIPO DE DOCUMENTO")}
                    disabled={true}
                  >
                    {t("editInfoModal.SELECCIONA EL TIPO DE DOCUMENTO")}
                  </MenuItem>

                  {documentTypeOptions.map((documentType, index) => (
                    <MenuItem key={index} value={documentType.value}>
                      {t(
                        `editInfoModal.documentTypeOptions.${documentType.label}`
                      )}
                    </MenuItem>
                  ))}
                </Select>
                {formikBusiness.errors.document_type && (
                  <Alert mt={2} mb={3} severity="error">
                    {t(
                      `editInfoModal.documentTypeOptions.${documentType.label}`
                    )}
                  </Alert>
                )}
              </Grid>
              <Grid item md={6}>
                <TextField
                  name="document_number"
                  label={t("editInfoModal.Número de documento")}
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formikBusiness.values.document_number}
                  onChange={formikBusiness.handleChange}
                />
                {formikBusiness.errors.document_number && (
                  <Alert mt={2} mb={3} severity="error">
                    {formikBusiness.errors.document_number}
                  </Alert>
                )}
                {formikBusiness.values.document_type === "nit" && (
                  <Alert mt={2} mb={3} severity="warning">
                    {t(
                      "editInfoModal.Mutante, recuerda ingresar el Nit SIN código de verificación"
                    )}
                  </Alert>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item md={6}>
                <TextField
                  name="name"
                  label={t("editInfoModal.Nombre comercial")}
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formikBusiness.values.name}
                  onChange={formikBusiness.handleChange}
                />
                {formikBusiness.errors.name && (
                  <Alert mt={2} mb={3} severity="error">
                    {formikBusiness.errors.name}
                  </Alert>
                )}
              </Grid>
              <Grid item md={6}>
                <TextField
                  name="legal_name"
                  label={t("editInfoModal.Nombre legal")}
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formikBusiness.values.legal_name}
                  onChange={formikBusiness.handleChange}
                />
                {formikBusiness.errors.legal_name && (
                  <Alert mt={2} mb={3} severity="error">
                    {formikBusiness.errors.legal_name}
                  </Alert>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item md={6}>
                <TextField
                  name="email"
                  label={t("editInfoModal.Correo")}
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formikBusiness.values.email}
                  onChange={formikBusiness.handleChange}
                />
                {formikBusiness.errors.email && (
                  <Alert mt={2} mb={3} severity="error">
                    {formikBusiness.errors.email}
                  </Alert>
                )}
              </Grid>
              <Grid item md={6}>
                <TextField
                  name="phone"
                  label={t("editInfoModal.Teléfono")}
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formikBusiness.values.phone}
                  onChange={formikBusiness.handleChange}
                />
                {formikBusiness.errors.phone && (
                  <Alert mt={2} mb={3} severity="error">
                    {formikBusiness.errors.phone}
                  </Alert>
                )}
              </Grid>
            </Grid>{" "}
            <Grid container spacing={6}>
              <Grid item md={6} my={2}>
                <Select
                  name="operator"
                  label={t("editInfoModal.Operador")}
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formikBusiness.values.operator}
                  onChange={formikBusiness.handleChange}
                >
                  <MenuItem
                    key={"label"}
                    value={t("editInfoModal.SELECCIONA EL TIPO DE OPERADOR")}
                    disabled={true}
                  >
                    {t("editInfoModal.SELECCIONA EL TIPO DE OPERADOR")}
                  </MenuItem>
                  <MenuItem key={"team foods"} value={"tf"}>
                    Team Foods, " tf "
                  </MenuItem>
                  <MenuItem key={"external"} value={"external"}>
                    External
                  </MenuItem>
                  <MenuItem key={"muta"} value={"muta"}>
                    Muta
                  </MenuItem>
                </Select>
              </Grid>
              <Grid item md={6} my={2}>
                <Select
                  name="payment_method"
                  label="Medio de pago"
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formikBusiness.values.payment_method}
                  onChange={formikBusiness.handleChange}
                >
                  <MenuItem
                    key={"label"}
                    value={t("editInfoModal.SELECCIONA MÉTODO DE PAGO")}
                    disabled={true}
                  >
                    {t("editInfoModal.SELECCIONA MÉTODO DE PAGO")}
                  </MenuItem>
                  <MenuItem key={"cash"} value={"cash"}>
                    {t("editInfoModal.Efectivo")}
                  </MenuItem>
                  <MenuItem key={"bank_transfer"} value={"bank_transfer"}>
                    {t("editInfoModal.Transferencia")}
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item md={6}>
                <TextField
                  name="internal_code"
                  label={t("editInfoModal.Código interno")}
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formikBusiness.values.internal_code}
                  onChange={formikBusiness.handleChange}
                />
                {formikBusiness.errors.internal_code && (
                  <Alert mt={2} mb={3} severity="error">
                    {formikBusiness.errors.internal_code}
                  </Alert>
                )}
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setEditModal(false)} color="primary">
              {t("editInfoModal.Cancelar")}
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {t("editInfoModal.Actualizar")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* 2) Modal Contactar Negocio*/}
      <Dialog
        open={contactModal}
        onClose={() => setContactModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={formikNotification.handleSubmit}>
          {" "}
          {/*Formulario para Envío de Notificaciones-MODAL*/}
          <DialogTitle id="form-dialog-title">
            {t("contactModal.Contactar Negocio")}
          </DialogTitle>
          <DialogContent>
            <Typography variant="body2" gutterBottom>
              {t(
                "contactModal.Escribe el mensaje que deseas enviar al Negocio"
              )}
              :
            </Typography>
            <Box mt={3} mb={6} border={1} borderColor="#EFEBEB" padding="1em">
              <BubbleWrapper>
                <ReactQuill
                  theme="bubble"
                  value={value}
                  onChange={setValue}
                  placeholder={t("contactModal.Escribe aquí")}
                />
              </BubbleWrapper>
            </Box>

            <Box mt={3} mb={6}>
              <Paper mt={3}>
                <FormControl component="fieldset" variant="standard">
                  <FormLabel component="legend">
                    {t("contactModal.Mensajes predeterminados")}:
                  </FormLabel>
                  <RadioGroup
                    aria-label="default-messages"
                    name="default-messages"
                    value={value}
                    onChange={handleChangeContactMessage}
                  >
                    <FormControlLabel
                      value="Hola Mutante! es un gusto saludarte..."
                      control={<Radio />}
                      label={t(
                        "contactModal.Hola Mutante! es un gusto saludarte"
                      )}
                    />

                    <FormControlLabel
                      value="Buenas tardes Mutante, te informamos que..."
                      control={<Radio />}
                      label={t(
                        "contactModal.Buenas tardes Mutante, te informamos que"
                      )}
                    />
                    <FormControlLabel
                      value="Buenos días Mutante, te informamos que..."
                      control={<Radio />}
                      label={t(
                        "contactModal.Buenos días Mutante, te informamos que"
                      )}
                    />
                  </RadioGroup>
                </FormControl>
              </Paper>
            </Box>
            <Box mt={3} mb={6}>
              <Paper mt={6}>
                <FormControl component="fieldset" variant="standard">
                  <FormLabel component="legend">
                    {t("contactModal.Enviar mensaje mediante:")}
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sms}
                          onChange={handleChangeCheckBoxContactModal}
                          name="sms"
                        />
                      }
                      label={t("contactModal.Mensaje de texto")}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={email}
                          onChange={handleChangeCheckBoxContactModal}
                          name="email"
                        />
                      }
                      label={t("contactModal.Correo electrónico")}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={app}
                          onChange={handleChangeCheckBoxContactModal}
                          name="app"
                        />
                      }
                      label={t("contactModal.Notificación de App")}
                    />
                  </FormGroup>
                </FormControl>
              </Paper>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setContactModal(false)} color="primary">
              {t("contactModal.Cancelar")}
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {t("contactModal.Enviar")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* --------------------------------------------------------------- */}

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showSnack}
        autoHideDuration={6000}
        onClose={() => {
          setShowSnack(false);
        }}
        message={snackTxt}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                setShowSnack(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
