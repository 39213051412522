import { useEffect, useState } from "react";

import { Box, Divider, LinearProgress, Toolbar } from "@mui/material";
import { useFormik } from "formik";

import CustomHeaderTitle from "../../../components/custom/headers/default/CustomHeaderTitle";
import usePixelPerfect from "../../../hooks/old/usePixelPerfect";
import CustomInput from "../../../components/custom/forms/default/CustomInput";
import CustomButton from "../../../components/custom/buttons/default/CustomButton";
import CustomText from "../../../components/custom/texts/CustomText";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { setMessage, setOpenSnackbar, setType } from "../../../redux/slices/globalSnackbar/globalSnackbarSlice";
import { fetchChangePassword } from "../../../redux/thunks/settings/security/changePasswordThunk";
import Page500 from "../../old/auth/old/Page500";


interface PasswordRequirement {
    text: string;
    met: boolean;
}

interface FormErrors {
    oldPassword?: string;
    newPassword?: string;
    confirmPassword?: string;
    [key: string]: string | undefined;
}

export default function ChangePassword() {
    const { PP } = usePixelPerfect();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { oldPassword, status } = useAppSelector(
        (state) => state.currentPasswordValidation
    );

    const { statusChangePassword, loading } = useAppSelector(
        (state) => state.changePassword
    );

    const initialFormikValues = {
        oldPassword: oldPassword,
        newPassword: "",
        confirmPassword: "",
    };


    const [passwordRequirements, setPasswordRequirements] = useState<PasswordRequirement[]>([
        { text: t("Mínimo 12 caracteres"), met: false },
        { text: t("1 carácter en mayúscula"), met: false },
        { text: t("1 carácter en minúscula"), met: false },
        { text: t("1 carácter especial"), met: false },
        { text: t("1 número"), met: false },
    ]);

    const validatePassword = (password: string) => {
        const updatedRequirements = passwordRequirements.map((requirement) => {
            switch (requirement.text) {
                case t("Mínimo 12 caracteres"):
                    return { ...requirement, met: password.length >= 12 };
                case t("1 carácter en mayúscula"):
                    return { ...requirement, met: /[A-Z]/.test(password) };
                case t("1 carácter en minúscula"):
                    return { ...requirement, met: /[a-z]/.test(password) };
                case t("1 carácter especial"):
                    return { ...requirement, met: /[^\w\s]/.test(password) };
                case t("1 número"):
                    return { ...requirement, met: /[0-9]/.test(password) };
                default:
                    return requirement;
            }
        });
        return updatedRequirements;
    };

    const handleSubmit = async (values: {
        oldPassword: string;
        newPassword: string;
        confirmPassword: string;
    }) => {
        // Validando requisitos de la contraseña
        const isPasswordValid = passwordRequirements.every((req) => req.met);
        if (!isPasswordValid) {
            dispatch(setOpenSnackbar(true));
            dispatch(setMessage(t("Error. Intenta nuevamente")));
            dispatch(setType("failedSnackbar"));
            return;
        }
        // Petición
        await dispatch(fetchChangePassword({
            oldPassword: values.oldPassword,
            newPassword: values.newPassword
        }));
    };


    const formikChangePassword = useFormik({
        initialValues: initialFormikValues,
        enableReinitialize: true,
        onSubmit: handleSubmit,
        validate: (values) => {
            const errors: FormErrors = {};
            const updatedRequirements = validatePassword(values.newPassword);
            passwordRequirements.forEach((req, index) => {
                if (!req.met) {
                    errors[`passwordRequirement${index + 1}`] = req.text;
                }
            });
            if (values.newPassword !== values.confirmPassword) {
                errors.confirmPassword = t("Las contraseñas no coinciden");
            }
            return errors;
        },
    });

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        formikChangePassword.handleChange(event);
        const updatedRequirements = validatePassword(event.target.value);

        // Actualizar el estado de passwordRequirements
        setPasswordRequirements(updatedRequirements);
    };

    useEffect(() => {
        if (!loading && statusChangePassword !== -1) {
            if (statusChangePassword === 201) {
                dispatch(setMessage(t("Contraseña cambiada con éxito")));
                dispatch(setType("sucessSnackbar"));
                dispatch(setOpenSnackbar(true));
            } else if (statusChangePassword === 400) {
                dispatch(setMessage(t("Error. Intenta nuevamente")));
                dispatch(setType("failedSnackbar"));
                dispatch(setOpenSnackbar(true));
            }
        }
    }, [statusChangePassword, loading, dispatch]);

    return status === 200 ? (
        <Box display="flex" flexDirection="column">
            <Toolbar
                style={{
                    padding: "0px",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <CustomHeaderTitle
                    title={t("Cambiar contraseña")}
                    style={{ fontSize: PP(36), fontWeight: 500 }}
                />
            </Toolbar>
            <Divider />
            <form onSubmit={formikChangePassword.handleSubmit}>
                {loading && <LinearProgress />}
                <Box
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                    }}
                >

                    <CustomInput
                        id="newPassword"
                        label={t("Nueva contraseña")}
                        placeholder={t("Introduce la nueva contraseña")}
                        value={formikChangePassword.values.newPassword}
                        onChange={handlePasswordChange}
                        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
                        inputHeight={PP(40)}
                        inputBorderRadius={PP(8)}
                        extraStyle={{ marginTop: PP(16) }}
                        widthProp={500}
                        type="password"
                        formik={formikChangePassword}
                    />
                    <Box style={{ display: "flex", flexDirection: "column", marginTop: PP(8) }}>
                        {passwordRequirements.map((requirement, index) => (
                            <CustomText
                                key={`passwordRequirement${index + 1}`}
                                noSpacing
                                superSmallSize
                                style={{
                                    color: requirement.met ? "#00B080" : "rgb(231, 59, 133)",
                                }}
                            >
                                {requirement.text}
                            </CustomText>
                        ))}
                    </Box>
                    {formikChangePassword.errors.newPassword && (
                        <CustomText noSpacing style={{ marginTop: PP(8) }}>
                            {formikChangePassword.errors.newPassword}
                        </CustomText>
                    )}
                </Box>

                <Box
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                    }}
                >
                    <CustomInput
                        id="confirmPassword"
                        label={t("Confirma la nueva contraseña")}
                        placeholder={t("Introduce la nueva contraseña")}
                        value={formikChangePassword.values.confirmPassword}
                        onChange={formikChangePassword.handleChange}
                        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
                        inputHeight={PP(40)}
                        inputBorderRadius={PP(8)}
                        extraStyle={{ marginTop: PP(16) }}
                        widthProp={500}
                        type="password"
                        formik={formikChangePassword}
                    />


                    <Box style={{ display: "flex", justifyContent: "center", marginTop: PP(50) }}>
                        <CustomButton
                            width={500}
                            height={44}
                            noSpacing
                            style={{ padding: "0px" }}
                            type="submit"
                            label={t("Cambiar contraseña")}
                            formik={formikChangePassword}
                            disabled={!formikChangePassword.isValid}
                        />
                    </Box>

                </Box>
            </ form>

        </Box>
    ) : (<Page500 />)
}
