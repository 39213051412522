import { Edit as EditIcon } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import {
  Chip,
  Divider, IconButton, MenuItem, Select, TableCell,
  TableRow,
  Toolbar,
  styled
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { useEffect, useState } from "react";
import CustomHeaderTitle from "../../../components/custom/headers/default/CustomHeaderTitle";
import CustomCompleteTable from "../../../components/custom/tables/default/CustomCompleteTable";
import usePixelPerfect from "../../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { fetchVerificationList } from "../../../redux/thunks/inventory/verificationThunks";
import { fetchWarehousesList } from "../../../redux/thunks/warehouse/warehousesListThunk";
import { verificationTableHeadCells } from "../../../utils/constants/inventory/inventoryVerificationTableHeadCells";

const CustomTableRow = styled(TableRow)<any>(({ }) => ({}));

function VerificationList() {

  const { verificationList, status } =
    useAppSelector((state) => state.verificationList);
  const [selectedWarehouse, setSelectedWarehouse] = useState<any>(0);
  const [selectedDate, setSelectedDate] = useState<any>(new Date().toISOString().split('T')[0]);
  const dispatch = useAppDispatch();
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const { warehousesList } = useAppSelector((state) => state.warehouseList);

  useEffect(() => {
    getWarehouseList();
  }, [])

  useEffect(() => {
    if (selectedWarehouse !== 0 && selectedDate) {
      dispatch(fetchVerificationList({ warehouseId: selectedWarehouse, date: selectedDate }));
    }
  }, [selectedWarehouse, selectedDate])

  const getWarehouseList = async () => {
    if (warehousesList.length === 0) {
      dispatch(fetchWarehousesList({ page: 0 }));
    }
  }

  return (
    <>
      <Toolbar
        style={{
          padding: "0px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <CustomHeaderTitle
          title={t("Verificacion de pesos")}
          style={{ fontSize: PP(36), fontWeight: 500 }}
        />
        <div>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedWarehouse}
            label="Age"
            onChange={(event) => {
              setSelectedWarehouse(event.target.value);
            }}
          >
            <MenuItem key={0} value={0} disabled>{t("Bodega")}</MenuItem>
            {
              warehousesList.map((element: any, key) => {
                return (
                  <MenuItem key={key} value={element.id}>{element.name}</MenuItem>
                )
              })
            }
          </Select>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedDate}
            onChange={(event) => {
              setSelectedDate(event.target.value);
            }}
          >
            <MenuItem key={0} value={0} disabled>{t("Fecha")}</MenuItem>
            {
              [0, 1, 2, 3, 4, 5].map((element: any, key) => {

                function restarDias(dias: any) {
                  var fechaHoy = new Date();
                  var nuevaFecha = new Date(fechaHoy);
                  nuevaFecha.setDate(fechaHoy.getDate() - dias);
                  var formattedDate = nuevaFecha.toISOString().split('T')[0];
                  return formattedDate;
                }

                return (
                  <MenuItem key={key} value={restarDias(element)}>{restarDias(element)}</MenuItem>
                )
              })
            }
          </Select>
        </div>
      </Toolbar>
      <Divider />

      <CustomCompleteTable
        status={status}
        tableHeadCells={verificationTableHeadCells} //Encabezados de la tabla
        totalRows={50} // total de filas de la tabla = total de datos respuesta backend
        rowsPerPage={50} //Filas por pagina de tabla
        page={0} //página actual
        handleChangePage={() => { }} // Manejando cambio de página para consumo Api
        setPage={() => { }} //Cambio de página actual
        children={verificationList?.map((element: any, key) => {
          return (
            <CustomTableRow
              key={key}
              variant="default"
              style={{ cursor: "default" }}
            >
              {element && (
                <>
                  <TableCell style={{ color: "#212121", cursor: "default" }}>
                    {element.name}
                  </TableCell>
                  <TableCell style={{ cursor: "default" }}>
                    {/* {element.state} */}
                    <Chip
                      style={{
                        padding: `${PP(4)} ${PP(8)}`,
                        height: PP(28),
                        width: "auto",
                      }}
                      // @ts-ignore
                      variant="pending"
                      label={t("chips.states.Pendiente")}
                    />
                  </TableCell>
                  <TableCell align="right" style={{ cursor: "default" }}>
                    <IconButton
                      aria-label="details"
                      component={NavLink}
                      to={`/verification/new?date=${selectedDate}&warehouseId=${selectedWarehouse}&collectorId=${element.collectorId}&collector=${element.name}`}
                      size="large"
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </>
              )}
            </CustomTableRow>
          );
        })}
      />

    </>
  )
}

export default VerificationList