import { createSlice } from "@reduxjs/toolkit";

import { userInformationSliceType } from "../../../interfaces/redux/userInformationSliceInterface";
import { fetchUserInformation } from "../../thunks/users/userInformationThunk";

const initialState: userInformationSliceType = {
  loading: false,
  status: {
    informationStatus: "idle",
    updateInformationStatus: -1,
  },
  statusText: "",
  userInformation: {
    id: -1,
    name: "",
    legal_name: "",
    internal_code: "",
    payment_method: "",
    wallet: -1,
    document_type: "",
    document_number: "",
    email: "",
    phone: "",
    countryId: -1,
    ownerId: -1,
    tags: "",
    regime: "",
    segment: "",
    operator: "",
    commercial: [{ name: "", last_name: "", email: "" }],
    commercialId: -1,
  },
};

export const userInformationSlice = createSlice({
  name: "userInformationReducer",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Get user information details
    builder.addCase(fetchUserInformation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUserInformation.fulfilled, (state, action) => {
      const { data, status, statusText } = action.payload;
      state.userInformation = {
        id: data.id,
        name: data.name,
        legal_name: data.legal_name,
        internal_code: data.internal_code,
        payment_method: data.payment_method,
        wallet: data.wallet,
        document_type: data.document_type,
        document_number: data.document_number,
        email: data.email,
        phone: data.phone,
        countryId: data.countryId,
        ownerId: data.ownerId,
        tags: data.tags,
        regime: data.regime,
        segment: data.segment,
        operator: data.operator,
        commercial: data.commercial,
        commercialId: data.commercialId,
      };
      state.status.informationStatus = "succeeded";
      state.statusText = statusText;
    });
    builder.addCase(fetchUserInformation.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { reset } = userInformationSlice.actions;

export default userInformationSlice.reducer;
