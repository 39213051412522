import { Fragment, useCallback, useEffect } from "react";

import { Box, IconButton } from "@mui/material";

import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { ReactComponent as ChevronRight } from "../../../../assets/icons/modals/chevronRight.svg";
import CustomText from "../../texts/CustomText";
import { setCollectionMaterialsModalState } from "../../../../redux/slices/collections/collectionsMaterialsModalsStates";
import { setFormAddMaterialState } from "../../../../redux/slices/collections/addMaterialCollectionSlice";
import { fetchMaterialsList } from "../../../../redux/thunks/collections/materialsListThunk";
import { useTranslation } from "react-i18next";

interface CustomMaterialPickerInputProps {
  label: string;
  id: string;
  required: boolean;
  error: string | undefined;
  width?: number;
  disabledPrice?: boolean;
}

export default function CustomMaterialPickerInput(
  props: CustomMaterialPickerInputProps
) {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();

  const { selected, page } = useAppSelector((state) => state.materialsList);
  const { category } = useAppSelector(
    (state) => state.addMaterialCollection.form
  );

  const dispatch = useAppDispatch();

  const selectedLanguage = localStorage.getItem("language")

  const getMaterialsList = useCallback(() => {
    if (selected !== null) {
      dispatch(
        setFormAddMaterialState({ id: "materialId", value: selected.id })
      );
    }
    if (selected === null) {
      dispatch(fetchMaterialsList({ page: page, category: category }));
    }
  }, [selected, dispatch]);

  useEffect(() => {
    getMaterialsList();
  }, [getMaterialsList]);

  const handleOpenModal = () => {
    dispatch(
      setCollectionMaterialsModalState({ id: "addMaterial", value: false })
    );
    dispatch(
      setCollectionMaterialsModalState({ id: "selectMaterial", value: true })
    );
  };

  return (
    <Box
      sx={{
        marginBottom: props.disabledPrice ? PP(8) : PP(16),
        width: PP(props.width),
      }}
    >
      <CustomText
        smallSize
        noSpacing
        color="#969696"
        mediumWeight
        style={{ marginBottom: PP(8), marginTop: PP(16), fontSize: PP(14) }}
      >
        {props.label}
      </CustomText>

      <Box
        sx={[
          {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: PP(4),
            padding: `${PP(8)} ${PP(12)}`,
            paddingRight: "0px",
            backgroundColor: "#FFFFFF",
            width: props.width ? PP(props.width) : PP(367),
            height: PP(40),
            border: props.error
              ? `${PP(1)} solid #E73B85`
              : `${PP(1)} solid #E8E8E8`,
          },
        ]}
      >
        <Box>
          {selected !== null ? (
            <Box>
              <CustomText smallSize noSpacing color="#969696">
                {
                  selected.nameTranslations !== null ?
                    //@ts-ignore
                    selected.nameTranslations.translations[selectedLanguage] : selected.name
                }
              </CustomText>
            </Box>
          ) : (
            <Fragment>
              <CustomText smallSize noSpacing color="#C4C4C4">
                {t("collectionInformation.customAddMaterialModal.AddMaterialCollectionForm.Selecciona el material")}
              </CustomText>
            </Fragment>
          )}
        </Box>

        <IconButton
          onClick={handleOpenModal}
          sx={{
            textTransform: "none",
            borderRadius: PP(8),
            height: PP(32),
            width: PP(32),
            display: "flex",
            justifyContent: "flex-end",
            backgroundColor: "transparent",
            //border: "none",
            "&:hover": {
              backgroundColor: "transparent",
            },
            "&:focus": {
              backgroundColor: "transparent",
            },
          }}
        >
          <ChevronRight style={{ height: PP(16), width: PP(16) }} />
        </IconButton>
      </Box>
      {props.error && (
        <CustomText superSmallSize noSpacing color="#E73B85">
          {props.error}
        </CustomText>
      )}
    </Box>
  );
}
