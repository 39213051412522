import { createSlice } from "@reduxjs/toolkit";

import { fetchTransferAttachNewFile } from "../../../thunks/inventory/transfer/transferAttachNewFileModalThunk";
import { TransferAttachNewFileSliceType } from "../../../../interfaces/redux/transferAttachNewFileSliceInterface";

const initialState: TransferAttachNewFileSliceType = {
  loading: false,
  status: -1,
  form: {
    fileType: { type: "", label: "" },
    selectedDocument: "",
  },
};

export const transferAttachNewFileModalSlice = createSlice({
  name: "transferAttachNewFileModalReducer",
  initialState,
  reducers: {
    setFormAttachNewTransferFileState: (state, action) => {
      const { id, value } = action.payload;
      state.form = {
        ...state.form,
        [id]: value,
      };
    },

    reset: () => initialState,
  },
  extraReducers: (builder) => {
    //Añadir nuevo archivo adjunto de transferencia de inventario
    builder.addCase(fetchTransferAttachNewFile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchTransferAttachNewFile.fulfilled, (state, action) => {
      state.loading = false;
      state.status = action.payload.status;
    });
    builder.addCase(fetchTransferAttachNewFile.rejected, (state, action) => {
      state.loading = false;
      state.status = 403;
    });
  },
});

export const { reset, setFormAttachNewTransferFileState } =
  transferAttachNewFileModalSlice.actions;

export default transferAttachNewFileModalSlice.reducer;
