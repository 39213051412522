import { createSlice } from "@reduxjs/toolkit";

import { collectionMaterialsPriceListSliceType } from "../../../interfaces/redux/collectionMaterialsPriceListSliceInteface";
import { fetchCollectionMaterialPrices } from "../../thunks/collections/collectionMaterialPricesThunk";

const initialState: collectionMaterialsPriceListSliceType = {
  status: "idle",
  materialsPricesList: [],
};

export const collectionMaterialsPricesListSlice = createSlice({
  name: "collectionMaterialsPricesList",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    //Get lista de precios de todos los materiales asociados al negocio de la recolección creada
    builder.addCase(fetchCollectionMaterialPrices.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(
      fetchCollectionMaterialPrices.fulfilled,
      (state, action) => {
        state.status = "succeeded";
        state.materialsPricesList = action.payload.data;
      }
    );
    builder.addCase(fetchCollectionMaterialPrices.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export const { reset } = collectionMaterialsPricesListSlice.actions;

export default collectionMaterialsPricesListSlice.reducer;
