import { createSlice } from "@reduxjs/toolkit";

import { TransformationType } from "../../../../interfaces/data/transformationInterface";
import { fetchOneTransformationInfo } from "../../../thunks/inventory/transformations/oneTransformationInfo";

const initialState: {
  status: "idle" | "loading" | "succeeded" | "failed";
  transformationInfo: TransformationType;
} = {
  status: "idle",
  transformationInfo: {
    id: -1,
    date: "",
    warehouseId: -1,
    createdAt: "",
    updatedAt: "",
    warehouse: {
      id: -1,
      name: "",
    },
    transformMaterial: [],
  },
};

export const oneTransformationSlice = createSlice({
  name: "oneTransformationInfo",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    //Info de una transformación
    builder.addCase(fetchOneTransformationInfo.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchOneTransformationInfo.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.transformationInfo = action.payload;
    });
    builder.addCase(fetchOneTransformationInfo.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export const { reset } = oneTransformationSlice.actions;

export default oneTransformationSlice.reducer;
