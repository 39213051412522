import CustomFilters from "../../filters/CustomFilters";
import CustomTextField from "../../forms/default/CustomTextField";

type CustomSearchFiltersProps = {
  placeholder?: string;
  about?: string;
  aboutCustomFilters?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  width?: number;
  height?: number;
  bradius?: string;
  widthsearchicon?: number;
  heightsearchicon?: number;
};

export default function CustomSearchFilters(props: CustomSearchFiltersProps) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {props.about === "onlySearch" ? (
        <></>
      ) : (
        <CustomFilters about={props.aboutCustomFilters} />
      )}
      {/* <CustomTextField
        placeholder={props.placeholder}
        onChange={props.onChange}
        about="search"
        width={props.width}
        height={props.height}
        bradius={props.bradius}
        widthsearchicon={props.widthsearchicon}
        heightsearchicon={props.heightsearchicon}
      /> */}
    </div>
  );
}
