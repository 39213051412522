import { useCallback, useEffect, useState } from "react";

import { useFormik } from "formik";

import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../redux/store";

import CustomButton from "../custom/buttons/default/CustomButton";
import CustomInput from "../custom/forms/default/CustomInput";
import {
  setMessage,
  setOpenSnackbar,
  setType,
} from "../../redux/slices/globalSnackbar/globalSnackbarSlice";
import CustomSelect from "../custom/forms/default/CustomSelect";
import { Box, SelectChangeEvent } from "@mui/material";
import CustomText from "../custom/texts/CustomText";
import { ReactComponent as InfoIcon } from "../../assets/icons/modals/infoIcon.svg";
import { reset as resetFormCreateTransaction, setFormCreateTransaction } from "../../redux/slices/transactions/createTransactionSlice";
import { ReactComponent as BlueCheckIcon } from "../../assets/icons/modals/blueCheck.svg";
import { fetchClientsList } from "../../redux/thunks/transactions/clientsListThunk";
import { fetchCreateTransaction } from "../../redux/thunks/transactions/createTransactionThunk";
import {
  buyerIdYup,
  validateYupSchema,
  warehouseIdYup,
} from "../../utils/forms/validationSchema";
import { ClientType } from "../../interfaces/data/clientInterface";
import { useTranslation } from "react-i18next";
import EditPortBulkDate from "../custom/forms/sales/EditPortBulkDate";
import { fetchWarehousesList } from "../../redux/thunks/warehouse/warehousesListThunk";
// import SelectTransactionUploadDate from "../custom/forms/sales/SelectTransactionUploadDate";

interface CreateTransactionFormProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getTransactions: () => Promise<void>;
}

export default function CreateTransactionForm(
  props: CreateTransactionFormProps
) {
  const { PP } = usePixelPerfect();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { setOpen, getTransactions } = props;

  const {
    incoterm,
    currency,
    consecutive,
    contract,
    buyerType,
    buyerId,
    observation,
    warehouseId,
  } = useAppSelector((state) => state.createTransaction.form);
  const { warehousesList } = useAppSelector((state) => state.warehouseList);

  const { clientsList } = useAppSelector((state) => state.clientsList);

  const { transactionDetails } = useAppSelector(
    (state) => state.transactionDetails
  );

  const [loadDate, setLoadDate] = useState(
    transactionDetails.extra?.loadDate || ""
  );

  useEffect(() => {
    return () => {
      dispatch(resetFormCreateTransaction());
    }
  }, []);


  const getClientList = async () => {
    dispatch(fetchClientsList());
  };

  const getWarehouseList = async () => {
    if (warehousesList.length === 0) {
      dispatch(fetchWarehousesList({ page: 0 }));
    }
  }

  useEffect(() => {
    getClientList();
    getWarehouseList();
  }, [dispatch]);

  const handleClickCloseModal = () => {
    setOpen(false);
  };

  const initialFormikValues = {
    incoterm: incoterm,
    currency: currency,
    consecutive: consecutive,
    contract: contract,
    buyerType: buyerType,
    buyerId: buyerId,
    observation: observation,
    warehouseId: warehouseId,
  };

  const handleSubmit = async (data: {
    incoterm: string;
    warehouseId: string;
    currency: string;
    consecutive: string;
    contract: string;
    buyerType: string;
    buyerId: string;
    observation: string;
    extra?: { loadDate: string };
  }) => {
    let buyerTypeData = "";
    clientsList.forEach((client: ClientType) => {
      if (client.id.toString() === data.buyerId.toString()) {
        buyerTypeData = client.type;
      }
    });
    data.buyerType = buyerTypeData;

    type PayloadType = {
      [key: string]: string | number | { [key: string]: string };
    };

    const payload: PayloadType = {};

    Object.entries(data).forEach(([key, value]) => {
      if (key === "consecutive" || key === "observation" || key === "contract") {
        payload[key] = value;
        return;
      }
      if (
        (value !== undefined && value !== "") ||
        key === "buyerType" ||
        (key === "extra" &&
          value &&
          typeof value === "object" &&
          "loadDate" in value)
      ) {
        if (key === "consecutive") {
          payload[key] = Number(value);
        } else {
          payload[key] = value;
        }
      }
    });

    payload.extra = { loadDate: loadDate };
    dispatch(fetchCreateTransaction(payload));
    dispatch(setOpenSnackbar(true));
    dispatch(setMessage(t("Venta creada")));
    dispatch(setType("sucessSnackbar"));
    getTransactions();
    handleClickCloseModal();
  };

  const validationSchema = validateYupSchema({
    // incoterm: incotermYup,
    // currency: currencyYup,
    // consecutive: consecutiveYup,
    // contract: contractYup,
    buyerId: buyerIdYup,
    warehouseId: warehouseIdYup
  });

  const formikCreateTransaction = useFormik({
    initialValues: initialFormikValues,
    /* enableReinitialize: true, */
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: false,
  });

  const formikCreateTransactionChange = useCallback(
    (
      event:
        | React.ChangeEvent<HTMLInputElement>
        | SelectChangeEvent<number | string>
    ) => {
      formikCreateTransaction.setFieldValue(
        event.target.name,
        event.target.value
      );
      dispatch(
        setFormCreateTransaction({
          id: event.target.name,
          value: event.target.value,
        })
      );

      /* clear error input */
      formikCreateTransaction.setFieldError(event.target.name, undefined);
    },
    [formikCreateTransaction]
  );

  return (
    <form noValidate onSubmit={formikCreateTransaction.handleSubmit}>
      <CustomSelect
        id="buyerId"
        value={formikCreateTransaction.values.buyerId}
        onChange={(e) => {
          formikCreateTransactionChange(e);
        }}
        label={t("Cliente")}
        fullWidth
        items={clientsList.map((client) => {
          return { label: client.name, value: client.id };
        })}
        extraStyle={{ marginTop: PP(24) }}
        placeholder={t("Seleccionar cliente")}
        startSelectedIcon={<BlueCheckIcon />}
        formik={formikCreateTransaction}
        required
      />

      <CustomSelect
        id="warehouseId"
        value={formikCreateTransaction.values.warehouseId}
        onChange={(e) => {
          formikCreateTransactionChange(e);
        }}
        label={t("Bodega de origen")}
        fullWidth
        items={warehousesList.map((warehouse) => {
          return { label: warehouse.name, value: warehouse.id };
        })}
        extraStyle={{ marginTop: PP(24) }}
        placeholder={t("Seleccionar bodega")}
        startSelectedIcon={<BlueCheckIcon />}
        formik={formikCreateTransaction}
        required
      />

      <CustomInput
        id="consecutive"
        label={t("Consecutivo")}
        placeholder=""
        value={formikCreateTransaction.values.consecutive}
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        inputHeight={PP(40)}
        extraStyle={{ marginTop: PP(24) }}
        onChange={formikCreateTransactionChange}
        formik={formikCreateTransaction}
      // required
      />
      <CustomInput
        id="contract"
        label={t("Número de contrato")}
        placeholder=""
        value={formikCreateTransaction.values.contract}
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        inputHeight={PP(40)}
        extraStyle={{ marginTop: PP(24) }}
        onChange={formikCreateTransactionChange}
        formik={formikCreateTransaction}
      />
      <CustomSelect
        id="currency"
        value={formikCreateTransaction.values.currency}
        onChange={formikCreateTransactionChange}
        label={t("Moneda")}
        fullWidth
        items={[
          { value: "cop", label: "COP" },
          { value: "usd", label: "USD" },
        ]}
        extraStyle={{ marginTop: PP(24) }}
        placeholder={t("Seleccionar moneda")}
      // required
      />
      {/* <SelectTransactionUploadDate width="100%" /> */}
      <CustomSelect
        id="incoterm"
        value={formikCreateTransaction.values.incoterm}
        onChange={formikCreateTransactionChange}
        label={t("Incoterm")}
        fullWidth
        items={[
          { value: "N/A", label: "N/A" },
          { value: "FCA", label: "FCA" },
          { value: "CPT", label: "CPT" },
          { value: "CIP", label: "CIP" },
          { value: "DAP", label: "DAP" },
          { value: "DPU", label: "DPU" },
          { value: "DDP", label: "DDP" },
          { value: "CFR", label: "CFR" },
          { value: "FOB", label: "FOB" },
          { value: "FAS", label: "FAS" },
          { value: "CIF", label: "CIF" },
          { value: "EFW", label: "EFW" },
        ]}
        extraStyle={{ marginTop: PP(24) }}
        placeholder={t("Seleccionar incoterm")}
        formik={formikCreateTransaction}
      // required
      />
      <EditPortBulkDate
        width="100%"
        setLoadDate={setLoadDate}
        loadDate={loadDate}
      />
      <CustomInput
        id="observation"
        type="textarea"
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        label={t("Observaciones (opcional)")}
        placeholder={t("Tus observaciones...")}
        value={formikCreateTransaction.values.observation}
        onChange={formikCreateTransactionChange}
        multiline={true}
        rows={2}
        formik={formikCreateTransaction}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          margin: "0px",
        }}
      >
        <InfoIcon
          style={{ margin: `${PP(8)} 0px 0px` }}
          width={PP(13)}
          height={PP(13)}
        />
        <CustomText
          superSmallSize
          color="#585757"
          style={{ margin: `${PP(8)} 0px 0px` }}
        >
          &nbsp; {t("Este campo tiene un límite de 100 caracteres")}
        </CustomText>
      </Box>
      <CustomButton
        width={432}
        height={44}
        noSpacing
        style={{ padding: "0px", marginTop: PP(24) }}
        type="submit"
        label={t("Crear")}
        formik={formikCreateTransaction}
      />
      <CustomButton
        noSpacing
        width={432}
        height={44}
        cancelButton
        onClick={handleClickCloseModal}
        formik={formikCreateTransaction}
      />
    </form>
  );
}
