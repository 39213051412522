import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import styled from "@emotion/styled";

import {
  Button,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField as MuiTextField,
  Snackbar,
} from "@mui/material";
import { Add as AddIcon, Close as CloseIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";

import PageHeader from "../../../../../components/old/generalOld/PageHeader";
import UsersTable from "../../../../../components/old/users/old/business/UsersTable";
import {
  fetchBusinessList,
  fetchCreateBusiness,
  setShowSnack,
} from "../../../../../redux/old/slices/businessSlicer";
import useAuth from "../../../../../hooks/old/useAuth";
import AppFormikProvider from "../../../../../components/AppFormikProvider/AppFormikProvider";

import createBusinessSchema from "./schema/createBusinessSchema";

import FormCreateBusinesses from "./FormCreateBusinesses";

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

const headCells = [
  { id: "name", alignment: "left", label: "Nombre" },
  { id: "email", alignment: "left", label: "Email" },
  { id: "Nit", alignment: "left", label: "NIT" },
  { id: "actions", alignment: "right", label: "Acciones" },
];

export default function BusinessList() {
  const [createModal, setCreateModal] = useState(false);

  const [page, setPage] = useState(0);
  const { t } = useTranslation();
  const { user } = useAuth();

  const newBusinessSchema = createBusinessSchema(t);

  //ReduxToolkit
  const loading = useSelector((state) => state.business.showLoading);
  const snackTxt = useSelector((state) => state.business.snackTxt);
  const showSnack = useSelector((state) => state.business.showSnack);

  const dispatch = useDispatch();

  const getBusinesses = async (cPage) => {
    dispatch(fetchBusinessList(cPage)); //ReduxTookit Get
    setPage(cPage);
  };

  const createBusiness = async (data) => {
    //ReduxTookit Post
    let r = (Math.random() + 1).toString(36).substring(2);
    const params = {
      admin: true,
      email: data.email,
      password: r,
      name: data.name,
      last_name: data.legal_name,
      document_type: "person_id",
      document_number: data.document_number,
      phone: data.phone,
      business: {
        commercial_name: data.name,
        name: data.name,
        legal_name: data.legal_name,
        document_type: data.document_type,
        document_number: data.document_number,
        email: data.email,
        phone: data.phone,
        countryId: 1,
        payment_method: data.payment_method,
        segment: data.segment,
        regime: data.regime,
        operator: data.operator,
        internal_code: data.internal_code,
      },
      licenseId: user.licenseId,
    };
    dispatch(fetchCreateBusiness(params));
    setCreateModal(false);
    getBusinesses(0);
  };

  return (
    <React.Fragment>
      <PageHeader title={t("Negocios")}>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => setCreateModal(true)}
            >
              <AddIcon />
              {t("createNewBusinessModal.Nuevo")}
            </Button>
          </div>
        </Grid>
      </PageHeader>

      <Divider my={6} />

      <UsersTable headCells={headCells} />

      <Dialog
        open={createModal}
        onClose={() => setCreateModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <AppFormikProvider
          onSubmit={(data) => {
            createBusiness(data);
          }}
          validationSchema={newBusinessSchema}
          initialValues={{
            name: "",
            legal_name: "",
            email: "",
            phone: "",
            document_type: "",
            document_number: "",
            regime: "",
            segment: "",
            operator: "",
            payment_method: "",
            internal_code: "",
          }}
        >
          <DialogTitle id="form-dialog-title">
            {t("createNewBusinessModal.Crear un negocio")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t(
                "createNewBusinessModal.Cada negocio creado tendrá asignado una cuenta con la que podrá ingresar y cambiar su contraseña"
              )}
            </DialogContentText>

            <FormCreateBusinesses />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCreateModal(false)} color="primary">
              {t("Cancelar")}
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {t("Guardar")}
            </Button>
          </DialogActions>
        </AppFormikProvider>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showSnack}
        autoHideDuration={6000}
        onClose={() => {
          dispatch(setShowSnack(false));
        }}
        message={t(snackTxt)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                dispatch(setShowSnack(false));
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
