import { forwardRef, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { Box, Divider } from "@mui/material";

import { ReactComponent as BlueCheckIcon } from "../../../../../assets/icons/modals/blueCheck.svg";
import usePixelPerfect from "../../../../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { setTransferModalStatus } from "../../../../../redux/slices/inventory/transfer/transferDetailsModalStatusSlice";
import { fetchTransferDetails } from "../../../../../redux/thunks/inventory/transfer/transferDetailsThunk";
import { fetchUpdateTransferInfo } from "../../../../../redux/thunks/inventory/transfer/updateTransferInfo";
import DefaultModalLayout from "../../../../../layouts/collections/modals/DefaultModalLayout";
import CustomButton from "../../../buttons/default/CustomButton";
import CustomText from "../../../texts/CustomText";
import { allowedTransitions, transferStatesArray } from "../../../../../utils/constants/inventory/transferStates";
import { setMessage, setOpenSnackbar, setType } from "../../../../../redux/slices/globalSnackbar/globalSnackbarSlice";
import { resetUpdateTransferInfoSlice } from "../../../../../redux/slices/inventory/transfer/updateTransferInfoSlice";


export default forwardRef(function CustomEditTransferStatusModal() {
    const { id } = useParams();
    const { PP } = usePixelPerfect();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);

    const { transferDetails } = useAppSelector(
        (state) => state.transferDetails
    );

    const { statusText } = useAppSelector(
        (state) => state.updateTransferInfo
    );

    const [selectedTransferStatus, setSelectedTransferStatus] = useState(
        transferDetails.status
    );

    const [loadMore, setLoadMore] = useState(false);

    const handleLoadMore = () => {
        setLoadMore(true);
    };

    const dispatch = useAppDispatch();

    const handleCloseClickModal = (event: React.MouseEvent<SVGSVGElement>) => {
        dispatch(
            setTransferModalStatus({
                id: "editTransferStatus",
                value: false,
            })
        );
    };

    const handleBackModal = () => {
        dispatch(
            setTransferModalStatus({
                id: "editTransferStatus",
                value: false,
            })
        );
    };

    const updateNewTransferStatus = async () => {
        if (id !== undefined) {
            await dispatch(
                fetchUpdateTransferInfo({
                    data: { status: selectedTransferStatus },
                    transferId: id,
                })
            );
            await dispatch(fetchTransferDetails({ id }));
        }
        handleBackModal();
    };

    const handleSaveNewStatus = async () => {
        setIsLoading(true);
        if (transferDetails.materials.length !== 0) {
            await updateNewTransferStatus();
        } else {
            dispatch(setOpenSnackbar(true));
            dispatch(setMessage("Debes ingresar materiales"));
            dispatch(setType("failedSnackbar"));
            handleBackModal();
        }
        setIsLoading(false);
    };

    const handleSetSelected = (selectedState: string) => {
        //@ts-ignore
        const allowedStates = allowedTransitions[transferDetails.status];

        if (!allowedStates.includes(selectedState)) {
            dispatch(setType("failedSnackbar"));
            dispatch(setMessage(t("Cambio de estado no permitido")));
            dispatch(setOpenSnackbar(true));
            handleBackModal();
        } else {
            setSelectedTransferStatus(selectedState);
        }
    };


    useEffect(() => {
        if (statusText === "failed") {
            dispatch(setType("failedSnackbar"));
            dispatch(setMessage(t("Debes completar la información")));
            dispatch(setOpenSnackbar(true));
            dispatch(resetUpdateTransferInfoSlice());
        } else if (statusText === "success") {
            dispatch(setType("sucessSnackbar"));
            dispatch(setMessage(t("Estado actualizado")));
            dispatch(setOpenSnackbar(true));
            dispatch(resetUpdateTransferInfoSlice());
        }
    }, [statusText, dispatch]);

    return (
        <DefaultModalLayout handleClose={handleCloseClickModal}>
            <CustomText
                showBackArrowButton
                bigSize
                premediumWeight
                color="#1C1C1C"
                onClick={handleBackModal}
                style={{ marginBottom: PP(24) }}
            >
                {t("Editar estado")}
            </CustomText>
            <Divider style={{ marginBottom: PP(24) }} />
            <Box
                sx={[
                    {
                        borderRadius: PP(8),
                        minWidth: PP(432),
                        height: PP(140),
                        marginBottom: PP(24),
                        marginTop: PP(8),
                    },
                ]}
            >
                <Box>
                    <InfiniteScroll
                        height={PP(240)}
                        dataLength={transferStatesArray.length}
                        next={handleLoadMore}
                        hasMore={transferStatesArray.length >= 5}
                        loader={<CustomText color="#969696">cargando</CustomText>}
                    >
                        {transferStatesArray.map((state, key) => (
                            <Box
                                onClick={() =>
                                    handleSetSelected(state.state)
                                }
                                key={key}
                                sx={[
                                    {
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        flexDirection: "row",
                                        padding: PP(16),
                                        color: "white",
                                        height: PP(40),
                                        "&:hover": {
                                            backgroundColor: "#ECF1FF",
                                            cursor: "pointer",
                                            borderRadius: PP(4),
                                        },
                                    },
                                    selectedTransferStatus !== "" &&
                                    selectedTransferStatus === state.state && {
                                        backgroundColor: "#ECF1FF",
                                        borderRadius: PP(4),
                                    },
                                ]}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        width: "100%",
                                    }}
                                >
                                    <Box>
                                        <CustomText
                                            noSpacing
                                            color={
                                                selectedTransferStatus !== "" &&
                                                    selectedTransferStatus === state.state
                                                    ? "#4463B8"
                                                    : "#1C1C1C"
                                            }
                                        >
                                            {t(state.label)}
                                        </CustomText>
                                    </Box>
                                    {selectedTransferStatus !== "" &&
                                        selectedTransferStatus === state.state && (
                                            <span>
                                                <BlueCheckIcon />
                                            </span>
                                        )}
                                </Box>
                            </Box>
                        ))}
                    </InfiniteScroll>
                </Box>
            </Box>

            <CustomButton
                width={432}
                height={44}
                label={t("Aplicar")}
                onClick={handleSaveNewStatus}
                disabled={isLoading}
            />
            <CustomButton
                noSpacing
                width={432}
                height={44}
                cancelButton
                onClick={handleBackModal}
            />
        </DefaultModalLayout>
    );
});
