import { createAsyncThunk } from "@reduxjs/toolkit";

import { getRedux } from "../../../../utils/old/api";

export const fetchBusinessCollections = createAsyncThunk(
  "businessCollections/fetchGet",
  async (payload: { id: string; page: number; filter?: string }, thunkAPI) => {
    try {
      const response = await getRedux(
        `/collection?ownerId=${
          payload.id
        }&ownerType=business&admin=true&$limit=20&$skip=${
          payload.page * 20
        }&$sort[date]=-1&${payload.filter ? payload.filter : ""}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
