import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedMaterialToEdit: {
    materialId: 0,
    quantity: 0,
    price: 0,
    discount: 0,
    total: 0,
    poo: {
      pooId: [],
      pooType: "",
    },
    material: {
      id: 0,
      name: "",
      measure: "",
      category: "",
      description: "",
      createdAt: "",
      updatedAt: "",
      _include: [],
      nameTranslations: {
        translations: {
          es: "",
          en: "",
          it: "",
        },
        __v: 0,
      },
    },
  },
  loading: false,
  status: -1,
  statusText: "",
  form: {
    quantity: 0,
  },
};

export const editMaterialTransactionSlice = createSlice({
  name: "editMaterialTransactionReducer",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = -1;
      state.statusText = "";
    },
    setFormEditMaterialState: (state, action) => {
      const { id, value } = action.payload;
      state.form = {
        ...state.form,
        [id]: value,
      };
    },
    setSelectedMaterialToEdit: (state, action) => {
      state.selectedMaterialToEdit = action.payload;
    },

    reset: () => initialState,
  },
  //   extraReducers: (builder) => {
  //     // Editar un material de la exportación
  //     builder.addCase(fetchEditMaterialExport.pending, (state) => {
  //       state.loading = true;
  //     });
  //     builder.addCase(fetchEditMaterialExport.fulfilled, (state, action) => {
  //       state.status = action.payload.status;
  //       state.statusText = action.payload.statusText;
  //       state.loading = false;
  //     });
  //     builder.addCase(fetchEditMaterialExport.rejected, (state, action) => {
  //       state.loading = false;
  //     });
  //   },
});

export const {
  resetStatus,
  reset,
  setFormEditMaterialState,
  setSelectedMaterialToEdit,
} = editMaterialTransactionSlice.actions;

export default editMaterialTransactionSlice.reducer;
