import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
  Box,
} from "@mui/material";

import CustomText from "../../../custom/texts/CustomText";
import { ReactComponent as BlueCheckIcon } from "../../../../assets/icons/modals/blueCheck.svg";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 24px;
    height: 24px;
  }
`;

const Flag = styled.img`
  border-radius: 50%;
  width: 24px;
  height: 24px;
`;

const languageOptions = {
  es: {
    icon: "/static/img/flags/es.png",
    name: "Español - ES",
    code: "ES",
  },
  en: {
    icon: "/static/img/flags/us.png",
    name: "English - EN",
    code: "EN",
  },
};

function NavbarLanguagesDropdown() {
  const { i18n } = useTranslation();
  const [anchorMenu, setAnchorMenu] = React.useState(null);

  const [selected, setSelected] = useState(i18n.language);
  const selectedLanguage = languageOptions[selected];

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    closeMenu();
    setSelected(language);
  };

  useEffect(() => {}, [selected]);

  return selected ? (
    <React.Fragment>
      <Tooltip title="Idiomas" style={{ height: "100%" }}>
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
          style={{
            display: "flex",
            gap: "8px",
            border: "1px solid #DEDEDE",
            boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.06)",
            padding: "8px 12px",
            borderRadius: "4px",
            height: "100%",
          }}
        >
          <Flag
            src={selectedLanguage && selectedLanguage.icon}
            alt={selectedLanguage.name && selectedLanguage.name}
          />
          <CustomText bigSize color="#000">
            {selectedLanguage.code}
          </CustomText>
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <Box
          sx={{
            borderRadius: "4px",
            padding: "8px 0px",
            boxShadow:
              "0px 3px 5px 0px rgba(0, 0, 0, 0.03), 0px 1px 18px 0px rgba(0, 0, 0, 0.03), 0px 3px 3px 0px rgba(0, 0, 0, 0.03)",
          }}
        >
          {Object.keys(languageOptions).map((language) => (
            <MenuItem
              key={language}
              onClick={() => handleLanguageChange(language)}
              width="100%"
              style={{
                ...(selected === language && {
                  backgroundColor: "#ECF1FF",
                }),
              }}
            >
              <CustomText noSpacing width="168px">
                {languageOptions[language].name}
              </CustomText>
              {selected === language && (
                <span>
                  <BlueCheckIcon />
                </span>
              )}
            </MenuItem>
          ))}
        </Box>
      </Menu>
    </React.Fragment>
  ) : (
    <></>
  );
}

export default NavbarLanguagesDropdown;
