import { createSlice } from "@reduxjs/toolkit";

import { CollectionListSliceType } from "../../../../interfaces/redux/collectionListSliceInterface";
import { fetchBusinessCollectionsSearch } from "../../../thunks/users/business/businessCollectionsSearchThunk";
import { fetchBusinessCollections } from "../../../thunks/users/business/businessCollectionsThunk";

const initialState: CollectionListSliceType = {
  status: "idle",
  collectionList: [],
  totalRows: 0,
  filter: "",
};

export const businessCollectionsSlice = createSlice({
  name: "businesscollectionList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Get lista Recolecciones del negocio en general con o sin filtros
    builder.addCase(fetchBusinessCollections.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchBusinessCollections.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.collectionList = action.payload.data;
      state.totalRows = action.payload.total;
    });
    builder.addCase(fetchBusinessCollections.rejected, (state, action) => {
      state.status = "failed";
    });
    //Get para obtener nueva lista Recolecciones acorde a la búsqueda específica
    builder.addCase(fetchBusinessCollectionsSearch.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(
      fetchBusinessCollectionsSearch.fulfilled,
      (state, action) => {
        state.status = "succeeded";
        state.collectionList = action.payload.data;
        state.totalRows = action.payload.total;
      }
    );
    builder.addCase(fetchBusinessCollectionsSearch.rejected, (state) => {
      state.status = "failed";
    });
  },
});

export const {} = businessCollectionsSlice.actions;

export default businessCollectionsSlice.reducer;
