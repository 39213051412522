import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  destroyRedux,
  getRedux,
  patchRedux,
  postRedux,
} from "../../../utils/old/api";

const initialState = {
  person: {},
  permissions: [],
  checkPermissions: {},
  isHouse: false,
  addresses: [], //Con paginación
  totalAddressesList: [], //Sin paginación
  certificates: [],
  pageCertificates: 0,
  totalRowsCertificates: 0,
  prices: [],
  allPrices: [],
  collections: [],
  recurrentCollections: [],
  totalRowsCollections: 0,
  totalRowsRecurrentCollections: 0,
  totalRowsAddresses: 0,
  totalRowsBanksAccounts: 0,
  users: [],
  totalRows: 0,
  bankAccounts: [],
  totalBankAccounts: [],
  rows: [], //Para retiros
  pageWithdrawals: 0,
  totalRowsWithdrawals: 0,
  snackTxt: "",
  showSnack: false,
  userErrorExistsSnack: false,
  pageCollections: 0,
  pageRecurrentCollections: 0,
  pageAddresses: 0,
  pageBankAccounts: 0,
  showLoading: false,
  errorMessage: null,
};

//Para BasicPersonDataCard----
export const fetchPerson = createAsyncThunk(
  //Get
  "person/show",
  (id) => {
    return getRedux(`/user/${id}`).then((response) => {
      return response.data;
    });
  }
);

export const fetchUpdatePerson = createAsyncThunk(
  //Patch
  "updatePerson/patch",
  ({ data, id }) => {
    return patchRedux(`/user/${id}`, data).then((response) => {
      return response.data;
    });
  }
);

export const fetchCreateNotification = createAsyncThunk(
  //post
  "createNotification/post",
  (data) => {
    return postRedux("/user/notify", data).then((response) => {
      return response.data;
    });
  }
);

//Para AddressCard
export const fetchPersonAddress = createAsyncThunk(
  //Get
  "personAddress/show",
  ({ cPage, id }) => {
    return getRedux(
      `/address?ownerId=${id}&admin=true&ownerType=user&$limit=20&$skip=${
        cPage * 20
      }&$sort[createdAt]=-1`
    ).then((response) => {
      return response.data;
    });
  }
);

export const fetchCreatePersonAddress = createAsyncThunk(
  //post
  "createPersonAddress/post",
  (data) => {
    return postRedux("/address", data).then((response) => {
      return response.data;
    });
  }
);

export const fetchUpdateAddress = createAsyncThunk(
  //patch
  "updateAddress/patch",
  ({ data, selectedAddressId }) => {
    return patchRedux(`/address/${selectedAddressId}`, data).then(
      (response) => {
        return response.data;
      }
    );
  }
);

export const fetchDeleteAddress = createAsyncThunk(
  //Destroy
  "deleteAddress/delete",
  (address) => {
    return destroyRedux(`/address/${address.id}?admin=true`).then(
      (response) => {
        return response.data;
      }
    );
  }
);

//Para CertificatesCard
export const fetchPersonCertificates = createAsyncThunk(
  //get
  "personCertificates/show",
  (id) => {
    return getRedux(
      `/certificate?ownerType=user&ownerId=${id}&certificate=true`
    ).then((response) => {
      return response.data;
    });
  }
);

//Para CustomPricesCard
export const fetchPersonPrices = createAsyncThunk(
  //Get
  "personPrices/show",
  (id) => {
    return getRedux(`/material-price?user_id=${id}&only_custom=true`).then(
      (response) => {
        return response.data;
      }
    );
  }
);

export const fetchPersonAllPrices = createAsyncThunk(
  //Get
  "personAllPrices/show",
  () => {
    return getRedux("/material-price?admin=true&$sort[name]=1").then(
      (response) => {
        return response.data;
      }
    );
  }
);

export const fetchCreatePersonNewMaterialPrice = createAsyncThunk(
  //Post
  "createPersonNewMaterialPrice/post",
  (data) => {
    return postRedux("/material-price", data).then((response) => {
      return response.data;
    });
  }
);

export const fetchDeletePersonMaterial = createAsyncThunk(
  //Destroy
  "deletePersonMaterial/delete",
  (material) => {
    return destroyRedux(`/material-price/${material.id}`).then((response) => {
      return response.data;
    });
  }
);

//Para BanksAccountsCard

export const fetchBankAccounts = createAsyncThunk(
  //Get
  "bankAccounts /show",
  ({ cPage, id }) => {
    return getRedux(
      `/bank-account?user_id=${id}&$limit=20&$skip=${
        cPage * 20
      }&$sort[createdAt]=-1`
    ).then((response) => {
      return response.data;
    });
  }
);

export const fetchCreateNewBankAccount = createAsyncThunk(
  //Post
  "createNewBankAccounte/post",
  (data) => {
    return postRedux("/bank-account", data).then((response) => {
      return response.data;
    });
  }
);

export const fetchDeleteBankAccount = createAsyncThunk(
  //Destroy
  "deleteBankAccount/delete",
  (bankAccount) => {
    return destroyRedux(`/bank-account/${bankAccount._id}`).then((response) => {
      return response.data;
    });
  }
);

//Para CollectionCard
export const fetchPersonCollections = createAsyncThunk(
  //Get
  "personColections/show",
  ({ cPage, id }) => {
    return getRedux(
      `/collection?ownerId=${id}&ownerType=user&admin=true&$limit=20&$skip=${
        cPage * 20
      }&$sort[date]=-1`
    ).then((response) => {
      return response.data;
    });
  }
);

export const fetchCreatePersonCollection = createAsyncThunk(
  //Post
  "createPersonCollection/post",
  (data) => {
    return postRedux("/collection", data).then((response) => {
      return response.data;
    });
  }
);

//-->Direcciones sin Paginación
export const fetchPersonAddressList = createAsyncThunk(
  //get
  "personAddressesList/show",
  (id) => {
    return getRedux(
      `/address?ownerType=user&ownerId=${id}&admin=true&paginate=false&$sort[createdAt]=-1`
    ).then((response) => {
      return response.data;
    });
  }
);

//Para Recurrent Collection Card
export const fetchUserRecurrentCollections = createAsyncThunk(
  //Get
  "userRecurrentCollections/show",
  ({ cPage, id }) => {
    return getRedux(
      `/recurrent-collection?user_id=${id}&admin=true&$limit=20&$skip=${
        cPage * 20
      }&$sort[createdAt]=-1`
    ).then((response) => {
      return response.data;
    });
  }
);

export const fetchCreateRecurrentCollection = createAsyncThunk(
  //Post
  "createUserRecurrentCollection/post",
  (data) => {
    return postRedux("/recurrent-collection", data).then((response) => {
      return response.data;
    });
  }
);

export const fetchUpdateRecurrentCollection = createAsyncThunk(
  //patch
  "updateUserRecurrentCollection/patch",
  ({ data, selectedRecurrentCollectionId }) => {
    return patchRedux(
      `/recurrent-collection/${selectedRecurrentCollectionId}`,
      data
    ).then((response) => {
      return response.data;
    });
  }
);

export const fetchDeleteRecurrentCollection = createAsyncThunk(
  //Destroy
  "deleteUserRecurrentCollection/delete",
  (recurrentCollection) => {
    return destroyRedux(
      `/recurrent-collection/${recurrentCollection._id}`
    ).then((response) => {
      return response.data;
    });
  }
);

//Para WithdrawalsCard

export const fetchBankAccountsList = createAsyncThunk(
  //Get
  "bankAccountsPersonList /show",
  (id) => {
    return getRedux(
      `/bank-account?user_id=${id}&admin=true&paginate=false&$sort[createdAt]=-1`
    ).then((response) => {
      return response.data;
    });
  }
);

export const fetchWithdrawals = createAsyncThunk(
  //Get
  "withdrawals /show",
  ({ cPage, id }) => {
    return getRedux(
      `/withdrawal?ownerId=${id}&ownerType=user&admin=true&$limit=20&$skip=${
        cPage * 20
      }&$sort[createdAt]=-1`
    ).then((response) => {
      return response.data;
    });
  }
);

export const fetchUpdateWithdrawal = createAsyncThunk(
  //patch
  "updateWithdrawal/patch",
  ({ state, selectedWithdrawal }) => {
    return patchRedux(`/withdrawal/${selectedWithdrawal._id}`, state).then(
      (response) => {
        return response.data;
      }
    );
  }
);

export const fetchCreateWithdrawal = createAsyncThunk(
  //Post
  "createWithdrawal/post",
  (data) => {
    return postRedux("/withdrawal", data).then((response) => {
      return response.data;
    });
  }
);

//--------------------------------------------userList-Person----------------------------------------------------------------

//Para userList.js
export const fetchPersonList = createAsyncThunk(
  //Get
  "personList/show",
  (cPage) => {
    return getRedux(`/user?$limit=20&$skip=${cPage * 20}`).then((response) => {
      return response.data;
    });
  }
);

export const fetchCreatePerson = createAsyncThunk(
  //Post
  "createPerson/post",
  (data) => {
    return postRedux("/user", data).then((response) => {
      return response.data;
    });
  }
);

//Para UsersTable-Person

export const fetchSearchPerson = createAsyncThunk(
  //Post
  "searchUser/post",
  (query) => {
    return postRedux("/user/search", query)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
);

export const personSlice = createSlice({
  name: "personReducer",
  initialState,
  reducers: {
    pushPersonPermission: (state, action) => {
      state.permissions.push(action.payload);
    },

    setCheckPermissions: (state, action) => {
      const { key, value } = action.payload;
      state.checkPermissions[key] = value;
      if (value && !state.person.permissions.includes(key))
        state.person.permissions.push(key);
      else if (!value && state.person.permissions.includes(key)) {
        var index = state.person.permissions.indexOf(key);
        state.person.permissions.splice(index, 1);
      }
    },

    setShowSnack: (state, action) => {
      state.showSnack = action.payload;
    },

    setUserErrorExistsSnack: (state, action) => {
      state.userErrorExistsSnack = action.payload;
    },

    setPageCertificates: (state, action) => {
      state.pageCertificates = action.payload;
    },

    setPageWithdrawals: (state, action) => {
      state.pageWithdrawals = action.payload;
    },

    setPageCollections: (state, action) => {
      state.pageCollections = action.payload;
    },

    setPageRecurrentCollections: (state, action) => {
      state.pageRecurrentCollections = action.payload;
    },

    setPageAddresses: (state, action) => {
      state.pageAddresses = action.payload;
    },

    setPageBankAccounts: (state, action) => {
      state.pageBankAccounts = action.payload;
    },
  },
  extraReducers: (builder) => {
    //1
    builder.addCase(fetchPerson.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchPerson.fulfilled, (state, action) => {
      state.person = action.payload;
      state.permissions = action.payload.permissions;
      state.checkPermissions = {
        collector_fog: action.payload.permissions.includes("collector_fog"),
        collector_ssr: action.payload.permissions.includes("collector_ssr"),
        commercial: action.payload.permissions.includes("commercial"),
      };
      state.isHouse = action.payload.permissions.includes("house");
      state.showLoading = false;
    });
    //2
    builder.addCase(fetchPersonAddress.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchPersonAddress.fulfilled, (state, action) => {
      state.addresses = action.payload.data;
      state.totalRowsAddresses = action.payload.total;
      state.showLoading = false;
    });
    //3
    builder.addCase(fetchPersonPrices.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchPersonPrices.fulfilled, (state, action) => {
      state.prices = action.payload;
      state.showLoading = false;
    });
    //4
    builder.addCase(fetchPersonAllPrices.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchPersonAllPrices.fulfilled, (state, action) => {
      state.allPrices = action.payload;
      state.showLoading = false;
    });
    //5
    builder.addCase(fetchPersonCollections.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchPersonCollections.fulfilled, (state, action) => {
      state.collections = action.payload.data;
      state.totalRowsCollections = action.payload.total;
      state.showLoading = false;
    });
    //6
    builder.addCase(fetchPersonList.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchPersonList.fulfilled, (state, action) => {
      state.users = action.payload.data;
      state.totalRows = action.payload.total;
      state.showLoading = false;
    });
    //7
    builder.addCase(fetchSearchPerson.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchSearchPerson.fulfilled, (state, action) => {
      state.users = action.payload;
      state.showLoading = false;
    });
    //8
    builder.addCase(fetchUpdatePerson.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchUpdatePerson.fulfilled, (state, action) => {
      state.showLoading = false;
      // state.snackTxt = "Usuario actualizado";
      // state.showSnack = true;
    });
    builder.addCase(fetchUpdatePerson.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error actualizando persona");
    });
    //9
    builder.addCase(fetchCreatePersonAddress.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchCreatePersonAddress.fulfilled, (state, action) => {
      state.showLoading = false;
    });
    builder.addCase(fetchCreatePersonAddress.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error creando dirección");
    });
    //10 fetchCreatePersonNewMaterialPrice
    builder.addCase(fetchCreatePersonNewMaterialPrice.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(
      fetchCreatePersonNewMaterialPrice.fulfilled,
      (state, action) => {
        state.showLoading = false;
        state.snackTxt = "Precio personalizado añadido";
        state.showSnack = true;
      }
    );
    builder.addCase(
      fetchCreatePersonNewMaterialPrice.rejected,
      (state, action) => {
        state.showLoading = false;
        alert("Error editando precio personalizado");
      }
    );
    //11 fetchDeletePersonMaterial
    builder.addCase(fetchDeletePersonMaterial.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchDeletePersonMaterial.fulfilled, (state, action) => {
      state.showLoading = false;
      state.snackTxt = "Precio Personalizado Eliminado";
      state.showSnack = true;
    });
    builder.addCase(fetchDeletePersonMaterial.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error eliminando material personalizado");
    });
    //12 fetchCreatePersonCollection
    builder.addCase(fetchCreatePersonCollection.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchCreatePersonCollection.fulfilled, (state, action) => {
      state.showLoading = false;
      state.snackTxt = "Recolección creada";
      state.showSnack = true;
    });
    builder.addCase(fetchCreatePersonCollection.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error creando recolección");
    });
    //13 fetchCreatePerson
    builder.addCase(fetchCreatePerson.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchCreatePerson.fulfilled, (state, action) => {
      state.showLoading = false;
      state.snackTxt = "Usuario Creado";
      state.showSnack = true;
    });
    builder.addCase(fetchCreatePerson.rejected, (state, { error }) => {
      state.errorMessage = error.message;
      //console.log(typeof(state.errorMessage))

      if (state.errorMessage === "Invalid user.") {
        state.snackTxt = "Error: La persona que intenta crear ya existe";
        state.userErrorExistsSnack = true;
      } else {
        alert("Error creando usuario");
      }
    });
    //14-fetchBankAccounts
    builder.addCase(fetchBankAccounts.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchBankAccounts.fulfilled, (state, action) => {
      state.showLoading = false;
      state.bankAccounts = action.payload.data;
      state.totalRowsBanksAccounts = action.payload.total;
    });
    builder.addCase(fetchBankAccounts.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error obteniendo cuentas bancarias");
    });
    //15-fetchWithdrawals
    builder.addCase(fetchWithdrawals.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchWithdrawals.fulfilled, (state, action) => {
      state.showLoading = false;
      state.rows = action.payload.data;
      state.totalRowsWithdrawals = action.payload.total;
    });
    builder.addCase(fetchWithdrawals.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error obteniendo historial de retiros");
    });
    //16-fetchUpdateWithdrawal
    builder.addCase(fetchUpdateWithdrawal.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchUpdateWithdrawal.fulfilled, (state, action) => {
      state.showLoading = false;
      state.snackTxt = "Retiro actualizado";
      state.showSnack = true;
    });
    builder.addCase(fetchUpdateWithdrawal.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error actualizando retiro");
    });
    //17-fetchCreateWithdrawal
    builder.addCase(fetchCreateWithdrawal.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchCreateWithdrawal.fulfilled, (state, action) => {
      state.showLoading = false;
      state.snackTxt = "Solicitud de retiro creada";
      state.showSnack = true;
    });
    builder.addCase(fetchCreateWithdrawal.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error creando solicitud de retiro");
    });
    //18-fetchCreateNotification
    builder.addCase(fetchCreateNotification.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchCreateNotification.fulfilled, (state, action) => {
      state.showLoading = false;
      state.snackTxt = "Notificación enviada a la persona";
      state.showSnack = true;
    });
    builder.addCase(fetchCreateNotification.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error enviando notificación a la persona");
    });
    //19-fetchCreateNewBankAccount
    builder.addCase(fetchCreateNewBankAccount.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchCreateNewBankAccount.fulfilled, (state, action) => {
      state.showLoading = false;
      state.snackTxt = "Cuenta bancaria creada";
      state.showSnack = true;
    });
    builder.addCase(fetchCreateNewBankAccount.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error creando cuenta bancaria");
    });

    //20-fetchDeleteBankAccount
    builder.addCase(fetchDeleteBankAccount.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchDeleteBankAccount.fulfilled, (state, action) => {
      state.showLoading = false;
      state.snackTxt = "Cuenta bancaria Eliminada";
      state.showSnack = true;
    });
    builder.addCase(fetchDeleteBankAccount.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error eliminando cuenta bancaria");
    });

    //21-fetchPersonCertificates
    builder.addCase(fetchPersonCertificates.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchPersonCertificates.fulfilled, (state, action) => {
      state.showLoading = false;
      state.certificates = action.payload;
      state.totalRowsCertificates = action.payload.length;
    });
    builder.addCase(fetchPersonCertificates.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error obteniendo certificados");
    });
    //22-fetchPersonAddressList
    builder.addCase(fetchPersonAddressList.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchPersonAddressList.fulfilled, (state, action) => {
      state.totalAddressesList = action.payload;
      state.showLoading = false;
    });
    builder.addCase(fetchPersonAddressList.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error obteniendo direcciones de la persona");
    });
    //23-fetchBankAccountsList
    builder.addCase(fetchBankAccountsList.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchBankAccountsList.fulfilled, (state, action) => {
      state.totalBankAccounts = action.payload;
      state.showLoading = false;
    });
    builder.addCase(fetchBankAccountsList.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error obteniendo cuentas bancarias de la persona");
    });
    //24-fetchUserRecurrentCollections
    builder.addCase(fetchUserRecurrentCollections.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(
      fetchUserRecurrentCollections.fulfilled,
      (state, action) => {
        state.recurrentCollections = action.payload.data;
        state.totalRowsRecurrentCollections = action.payload.total;
        state.showLoading = false;
      }
    );
    builder.addCase(fetchUserRecurrentCollections.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error obteniendo recolecciones recurrentes");
    });
    //25-fetchCreateRecurrentCollection
    builder.addCase(fetchCreateRecurrentCollection.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(
      fetchCreateRecurrentCollection.fulfilled,
      (state, action) => {
        state.showLoading = false;
        state.snackTxt = "Recolección recurrente creada";
        state.showSnack = true;
      }
    );
    builder.addCase(
      fetchCreateRecurrentCollection.rejected,
      (state, action) => {
        state.showLoading = false;
        alert("Error creando recolección recurrente");
      }
    );

    //26-fetchUpdateRecurrentCollection
    builder.addCase(fetchUpdateRecurrentCollection.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(
      fetchUpdateRecurrentCollection.fulfilled,
      (state, action) => {
        state.showLoading = false;
        state.snackTxt = "Recolección recurrente actualizada";
        state.showSnack = true;
      }
    );
    builder.addCase(
      fetchUpdateRecurrentCollection.rejected,
      (state, action) => {
        state.showLoading = false;
        alert("Error actualizando recolección recurrente");
      }
    );

    //28-fetchDeleteRecurrentCollection
    builder.addCase(fetchDeleteRecurrentCollection.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(
      fetchDeleteRecurrentCollection.fulfilled,
      (state, action) => {
        state.showLoading = false;
        state.snackTxt = "Recolección recurrente eliminada";
        state.showSnack = true;
      }
    );
    builder.addCase(
      fetchDeleteRecurrentCollection.rejected,
      (state, action) => {
        state.showLoading = false;
        alert("Error eliminando recolección recurrente");
      }
    );
  },
});

export const {
  pushPersonPermission,
  setPersonPermission,
  setShowSnack,
  setUserErrorExistsSnack,
  setPageCertificates,
  setPageWithdrawals,
  setPageCollections,
  setPageRecurrentCollections,
  setPageAddresses,
  setCheckPermissions,
  setPageBankAccounts,
} = personSlice.actions;

export default personSlice.reducer;
