import { useEffect, useState } from "react";

import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ReactComponent as BlueCheckIcon } from "../../../assets/icons/modals/blueCheck.svg";
import usePixelPerfect from "../../../hooks/old/usePixelPerfect";
import { useAppDispatch } from "../../../redux/store";
import CustomSelect from "../../custom/forms/default/CustomSelect";
import DateFilter from "../../custom/forms/sales/DateFilter";


interface TransfersFiltersProps {
    setFilter: (filter: string) => void;
}


export default function TransfersFilters(props: TransfersFiltersProps) {
    const { PP } = usePixelPerfect();
    const { t } = useTranslation();
    const [transferState, setTransferState] = useState<string | number>("");

    const [date, setDate] = useState<string | Date>("");

    useEffect(() => {
        let filter = "";

        if (date !== "") {
            filter += `&startDate=${date}`;
        }

        if (transferState !== "") {
            filter += `&status[$in][]=${transferState}`;
        }

        if (filter !== "") {
            props.setFilter(filter)
        }
    }, [transferState, date]);

    return (
        <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            height={PP(40)}
        >
            <Box>
                <CustomSelect
                    id="transferState"
                    value={transferState}
                    onChange={(event) => {
                        setTransferState(event.target.value);
                    }}
                    items={[
                        { value: "pending", label: t("chips.transferStates.pending") },
                        { value: "transit", label: t("chips.transferStates.transit") },
                        { value: "received", label: t("chips.transferStates.received") },
                        { value: "finished", label: t("chips.transferStates.finished") },
                    ]}
                    extraStyle={{
                        height: PP(40),
                    }}
                    placeholder={t("Selecciona un estado")}
                    startSelectedIcon={<BlueCheckIcon />}
                />
            </Box>
            <Box height={PP(42)}>
                <DateFilter date={date} setDate={setDate} />
            </Box>
        </Box>
    );
}