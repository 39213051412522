import { useEffect } from "react";

import { Box, Divider, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";

import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import CustomText from "../custom/texts/CustomText";
import { ReactComponent as FileIcon } from "../../assets/icons/cards/file-text.svg";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import CustomCompleteTableNoPagination from "../custom/tables/default/CustomCompleteTableNoPagination";
import { collectionDetailsTableHeadCells } from "../../utils/constants/collections/collectionDetailsTableHeadCells";
import { setCollectionMaterialsModalState } from "../../redux/slices/collections/collectionsMaterialsModalsStates";
import CustomCollectionMaterialsTableRow from "../custom/tables/collections/CustomCollectionMaterialsTableRow";
import CollectionUnregisteredInformation from "./CollectionUnregisteredInformation";

export default function CollectionInformation() {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { collectionDetails, status } = useAppSelector(
    (state) => state.collectionDetails
  );

  const idsCashMaterials = [1, 2, 3, 4, 5]; // IDs de los materiales que en caso de ser pago mixto, se pagan en efectivo
  // Filtrar los elementos que cumplan los IDs deseados

  let cashTotal = 0;
  let bankTransferTotal = 0;

  if (collectionDetails.payment_method === "mixed") {
    const cashCollectionMaterials = collectionDetails.items.filter((item) =>
      idsCashMaterials.includes(item.materialId)
    );

    const bankTransferCollectionMaterials = collectionDetails.items.filter(
      (item) => !idsCashMaterials.includes(item.materialId)
    );

    cashTotal = cashCollectionMaterials.reduce(
      (acumulador, item) => acumulador + item.total,
      0
    );
    bankTransferTotal = bankTransferCollectionMaterials.reduce(
      (acumulador, item) => acumulador + item.total,
      0
    );
  }

  useEffect(() => { }, [collectionDetails.items, dispatch]);

  return (
    <Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CustomText
          noSpacing
          premediumWeight
          color="#1C1C1C"
          style={{ fontSize: PP(20) }}
        >
          {t("collectionInformation.Materiales recolectados")}
        </CustomText>
      </Box>
      {collectionDetails.state !== "pending" &&
        collectionDetails.state !== "assigned" ? (
        <>
          <Divider style={{ marginTop: PP(16), marginBottom: PP(16) }} />
          <Box display="flex">
            <Box flex={1}>
              <CustomCompleteTableNoPagination
                status={status}
                tableHeadCells={collectionDetailsTableHeadCells} //Encabezados de la tabla
                children={collectionDetails.items.map((element, key) => {
                  return (
                    <CustomCollectionMaterialsTableRow
                      element={element}
                      key={key}
                      myKey={key}
                    />
                  );
                })}
              />
              {collectionDetails.state === "collected" && (
                <Box>
                  <Paper
                    style={{
                      border: `${PP(3)} dashed #557CE6`,
                      padding: `${PP(8)} ${PP(16)}`,
                      width: "auto",
                      height: "auto",
                      marginBottom: PP(16),
                      borderRadius: PP(8),
                      justifyContent: "center",
                      background: "rgba(85, 124, 230,0.1)",
                    }}
                  >
                    <CustomText
                      noSpacing
                      bluePlusButton
                      mediumWeight
                      color="#557CE6"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        dispatch(
                          setCollectionMaterialsModalState({
                            id: "selectCategory",
                            value: true,
                          })
                        )
                      }
                    >
                      {t("collectionInformation.Agregar material")}
                    </CustomText>
                  </Paper>
                </Box>
              )}
            </Box>
          </Box>
        </>
      ) : (
        <Box width="100%" marginTop={PP(16)}>
          <CollectionUnregisteredInformation
            informativeIcon={<FileIcon width={PP(62)} height={PP(62)} />}
            text={t("collectionInformation.Todavía no se registran materiales en esta recolección")}
          />
          <Divider style={{ marginTop: PP(24) }} />
        </Box>
      )}
    </Box>
  );
}
