import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import styled from "@emotion/styled";
import { CssBaseline } from "@mui/material";
import posthog from "posthog-js";

import GlobalStyle from "../components/old/generalOld/GlobalStyle";
import useAuth from "../hooks/old/useAuth";

const Root = styled.div`
  max-width: 100%;
  margin: 10px 10px;
  display: flex;
  max-height: 100%;
  flex-direction: column;
`;

const Control = ({ children }) => {
  const { user } = useAuth();
  const location = useLocation();

  const userId = user && user.id;
  const userEmail = user && user.email;

  useEffect(() => {
    if (userId !== null) {
      // Llamando al evento "identify" con el ID del usuario logueado y el email
      posthog.capture("$identify", {
        distinct_id: userId,
        properties: { email: userEmail },
      });
      // Capturar el evento de "pageview" con información de la página visitada
      posthog.capture("$pageview", {
        url: location.pathname,
        title: document.title,
        distinct_id: userId,
      });
    }
  }, [userId, userEmail, location]);

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
      <Outlet />
    </Root>
  );
};

export default Control;
