import PPD from "../../utils/modals/ppd";

const customTableCell = {
  tableCellStyle: {
    fontFamily: "'Inter', sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: PPD(16),
    lineHeight: PPD(24),
  },
};

export default customTableCell;
