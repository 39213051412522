import {
  Chip,
  IconButton,
  styled as styledMui,
  TableCell,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { CustomTableRowType } from "../../../../interfaces/styles/customTableRowInterface";
import { CollectionMaterialsType } from "../../../../interfaces/data/collectionMaterialsInterface";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/buttons/trash.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/layouts/edit.svg";
import { setCollectionMaterialsModalState } from "../../../../redux/slices/collections/collectionsMaterialsModalsStates";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  setSelectedToDeleteMaterialId,
  setSelectedToDeleteMaterialName,
} from "../../../../redux/slices/collections/deleteMaterialCollectionSlice";
import { setSelectedMaterialToEdit } from "../../../../redux/slices/collections/editMaterialCollectionSlice";
import { collectionMaterialsPricesTypes } from "../../../../utils/constants/collections/collectionMaterialsPricesTypes";
import { firstLetterCapitalizeFormat } from "../../../../utils/formats/firstLetterCapitalizeFormat";

interface CollectionsTableRowProps {
  element: CollectionMaterialsType;
  myKey: number;
}

const CustomTableRow = styledMui(TableRow)<CustomTableRowType>(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#E8E8E8",
  },
}));

export default function CustomCollectionMaterialsTableRow(
  props: CollectionsTableRowProps
) {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { element, myKey } = props;

  const { collectionDetails } = useAppSelector(
    (state) => state.collectionDetails
  );

  const selectedLanguage = localStorage.getItem("language");

  const handleDeleteMaterial = () => {
    dispatch(setSelectedToDeleteMaterialId(element.id));
    dispatch(setSelectedToDeleteMaterialName(element.material.name));
    dispatch(
      setCollectionMaterialsModalState({
        id: "deleteMaterial",
        value: true,
      })
    );
  };

  const handleEditQuantityMaterial = () => {
    dispatch(setSelectedMaterialToEdit(element));
    dispatch(
      setCollectionMaterialsModalState({
        id: "editQuantity",
        value: true,
      })
    );
  };
  return (
    <>
      <CustomTableRow key={myKey} variant="default">
        {element && (
          <>
            <TableCell sx={{ color: "#1C1C1C", cursor: "default" }}>
              {
                element.material.nameTranslations !== null ?
                  //@ts-ignore
                  element.material.nameTranslations.translations[selectedLanguage] : element.material.name
              }
            </TableCell>
            <TableCell sx={{ color: "#1C1C1C", cursor: "default" }}>
              {element.material.name === "uco"
                ? `${element.quantity.toFixed(2)} ${element.material.measure
                } (Neto)`
                : `${element.quantity.toFixed(2)} ${element.material.measure}`}
            </TableCell>
            <TableCell sx={{ color: "#1C1C1C", cursor: "default" }}>
              ${element.price.toLocaleString("es-MX")} x{" "}
              {element.material.measure}{" "}
            </TableCell>
            <TableCell sx={{ color: "#1C1C1C", cursor: "default" }}>
              {element.priceType === "country" && (
                <Chip
                  label={
                    // @ts-ignore
                    t(`collectionInformation.customCollectionMaterialsTableRow.priceType.${collectionMaterialsPricesTypes[element.priceType]}`)
                  }
                  key={element.materialId}
                  // @ts-ignore
                  variant={"canceled"}
                />
              )}
              {element.priceType !== "country" && (
                <Chip
                  label={
                    // @ts-ignore
                    t(`collectionInformation.customCollectionMaterialsTableRow.priceType.${collectionMaterialsPricesTypes[element.priceType]}`)
                  }
                  key={element.materialId}
                  // @ts-ignore
                  variant={"pending"}
                />
              )}
            </TableCell>
            <TableCell
              sx={{
                color: "#1C1C1C",
                ...{ width: PP(130) },
                cursor: "default",
              }}
            >
              ${element.total.toLocaleString("es-MX")}
            </TableCell>

            <TableCell
              sx={{ padding: PP(16), cursor: "default" }}
              align="right"
            >
              {collectionDetails.state === "collected" && (
                <>
                  <IconButton onClick={handleEditQuantityMaterial}>
                    <EditIcon
                      style={{
                        width: PP(22),
                        height: PP(22),
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                  <IconButton onClick={handleDeleteMaterial}>
                    <TrashIcon
                      style={{
                        width: PP(22),
                        height: PP(22),
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                </>
              )}
            </TableCell>
          </>
        )}
      </CustomTableRow>
    </>
  );
}
