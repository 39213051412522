import { useState } from "react";

import { NavLink } from "react-router-dom";
import { IconButton, styled, TableCell, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ReactComponent as ActionIcon } from "../../../../../../assets/icons/tables/next.svg";
import { CustomTableRowType } from "../../../../../../interfaces/styles/customTableRowInterface";
import usePixelPerfect from "../../../../../../hooks/old/usePixelPerfect";
import { TransformationType } from "../../../../../../interfaces/data/transformationInterface";
import { useAppSelector } from "../../../../../../redux/store";


interface CustomTransformationListTableRowProps {
    element: TransformationType;
    myKey: number;
}

const CustomTableRow = styled(TableRow)<CustomTableRowType>(({ }) => ({}));

export default function CustomTransformationListTableRow(
    props: CustomTransformationListTableRowProps
) {
    const { PP } = usePixelPerfect();
    const { t } = useTranslation();
    const { element, myKey } = props;

    const inputMaterial = Array.isArray(element.transformMaterial)
        ? element.transformMaterial.find(item => item.type === 'input')?.material.name || 'Desconocido'
        : 'Desconocido';

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <CustomTableRow
            key={myKey}
            variant="default"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ cursor: "default" }}
        >
            {element && (
                <>
                    <TableCell style={{ color: "#212121", cursor: "default" }}>
                        {element.date}
                    </TableCell>
                    <TableCell style={{ color: "#212121", cursor: "default" }}>
                        {element.warehouse?.name}
                    </TableCell>
                    <TableCell style={{ cursor: "default" }}>
                        {inputMaterial}
                    </TableCell>
                    <TableCell align="right" style={{ cursor: "default" }}>
                        {isHovered && (
                            <IconButton
                                aria-label="transformationsDetails"
                                component={NavLink}
                                to={`/inventory/transformations/detail/${element.id}`}
                                size="large"
                                style={{ borderRadius: PP(8) }}
                            >
                                <ActionIcon style={{ width: PP(24), height: PP(24) }} />
                            </IconButton>
                        )}
                    </TableCell>
                </>
            )}
        </CustomTableRow>
    );
}
