import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import moment from "moment";
import "moment/locale/es";

// Importando los archivos de traducción
import esAllCollections from "./i18n/collections/allCollections/es";
import enAllCollections from "./i18n/collections/allCollections/en";

import esCollectionDetails from "./i18n/collections/collectionDetails/es";
import enCollectionDetails from "./i18n/collections/collectionDetails/en";

import esCollectionPageHistory from "./i18n/collections/collectionPageHistory/es";
import enCollectionPageHistory from "./i18n/collections/collectionPageHistory/en";

import esComponents from "./i18n/components/es.json";
import enComponents from "./i18n/components/en.json";

import esSidebarMenu from "./i18n/sidebarMenu/es.json";
import enSidebarMenu from "./i18n/sidebarMenu/en.json";

import esPrices from "./i18n/prices/es.json";
import enPrices from "./i18n/prices/en.json";

import esWithdrawals from "./i18n/withdrawals/es.json";
import enWithdrawals from "./i18n/withdrawals/en.json";

import esVerificationNew from "./i18n/verifications/verificationNew/es.json";
import enVerificationNew from "./i18n/verifications/verificationNew/en.json";

import esVerificationList from "./i18n/verifications/verificationList/es.json";
import enVerificationList from "./i18n/verifications/verificationList/en.json";

import esBusinessesList from "./i18n/users/business/businessList/es.json";
import enBusinessesList from "./i18n/users/business/businessList/en.json";

import esBusinessesAddressCard from "./i18n/users/business/businessProfile/addressCard/es.json";
import enBusinessesAddressCard from "./i18n/users/business/businessProfile/addressCard/en.json";

import esIndividualsAddressCard from "./i18n/users/individuals/individualProfile/addressCard/es.json";
import enIndividualsAddressCard from "./i18n/users/individuals/individualProfile/addressCard/en.json";

import esBusinessesBanksAccountsCard from "./i18n/users/business/businessProfile/banksAccountsCard/es.json";
import enBusinessesBanksAccountsCard from "./i18n/users/business/businessProfile/banksAccountsCard/en.json";

import esIndividualsBanksAccountsCard from "./i18n/users/individuals/individualProfile/banksAccountsCard/es.json";
import enIndividualsBanksAccountsCard from "./i18n/users/individuals/individualProfile/banksAccountsCard/en.json";

import esBasicBusinessDataCard from "./i18n/users/business/businessProfile/basicBusinessDataCard/es.json";
import enBasicBusinessDataCard from "./i18n/users/business/businessProfile/basicBusinessDataCard/en.json";

import esBasicIndividualsDataCard from "./i18n/users/individuals/individualProfile/basicIndividualDataCard/es.json";
import enBasicIndividualsDataCard from "./i18n/users/individuals/individualProfile/basicIndividualDataCard/en.json";

import esIndividualsCertificatesCard from "./i18n/users/individuals/individualProfile/certificatesCard/es.json";
import enIndividualsCertificatesCard from "./i18n/users/individuals/individualProfile/certificatesCard/en.json";

import esIndividualUcoCertificatesCard from "./i18n/users/individuals/individualProfile/ucoCertificatesCard/es.json";
import enIndividualUcoCertificatesCard from "./i18n/users/individuals/individualProfile/ucoCertificatesCard/en.json";

import esBusinessCertificatesCard from "./i18n/users/business/businessProfile/certificatesCard/es.json";
import enBusinessCertificatesCard from "./i18n/users/business/businessProfile/certificatesCard/en.json";

import esBusinessUcoCertificatesCard from "./i18n/users/business/businessProfile/ucoCertificatesCard/es.json";
import enBusinessUcoCertificatesCard from "./i18n/users/business/businessProfile/ucoCertificatesCard/en.json";

import esBusinesscollectionCard from "./i18n/users/business/businessProfile/collectionCard/es.json";
import enBusinesscollectionCard from "./i18n/users/business/businessProfile/collectionCard/en.json";

import esIndividualsCollectionCard from "./i18n/users/individuals/individualProfile/collectionCard/es.json";
import enIndividualsCollectionCard from "./i18n/users/individuals/individualProfile/collectionCard/en.json";

import esBusinessCustomPricesCard from "./i18n/users/business/businessProfile/customPricesCard/es.json";
import enBusinessCustomPricesCard from "./i18n/users/business/businessProfile/customPricesCard/en.json";

import esIndividualsCustomPricesCard from "./i18n/users/individuals/individualProfile/customPricesCard/es.json";
import enIndividualsCustomPricesCard from "./i18n/users/individuals/individualProfile/customPricesCard/en.json";

import esBusinessRecurrentCollectionCard from "./i18n/users/business/businessProfile/recurrentCollectionCard/es.json";
import enBusinessRecurrentCollectionCard from "./i18n/users/business/businessProfile/recurrentCollectionCard/en.json";

import esIndividualsRecurrentCollectionCard from "./i18n/users/business/businessProfile/recurrentCollectionCard/es.json";
import enIndividualsRecurrentCollectionCard from "./i18n/users/business/businessProfile/recurrentCollectionCard/en.json";

import esBusinessWithdrawalsCard from "./i18n/users/business/businessProfile/withdrawalsCard/es.json";
import enBusinessWithdrawalsCard from "./i18n/users/business/businessProfile/withdrawalsCard/en.json";

import esIndividualsWithdrawalsCard from "./i18n/users/individuals/individualProfile/withdrawalsCard/es.json";
import enIndividualsWithdrawalsCard from "./i18n/users/individuals/individualProfile/withdrawalsCard/en.json";

import esIndividualsList from "./i18n/users/individuals/individualsList/es.json";
import enIndividualsList from "./i18n/users/individuals/individualsList/en.json";

import esValidationSchema from "./i18n/utils/validationSchema/es.json";
import enValidationSchema from "./i18n/utils/validationSchema/en.json";

import esFormats from "./i18n/utils/formats/es.json";
import enFormats from "./i18n/utils/formats/en.json";

import esSignIn from "./i18n/signIn/es.json";
import enSignIn from "./i18n/signIn/en.json";

import esSales from "./i18n/sales/es.json";
import enSales from "./i18n/sales/en.json";

import esClients from "./i18n/clients/es.json";
import enClients from "./i18n/clients/en.json";

import esSecurity from "./i18n/settings/security/es.json";
import enSecurity from "./i18n/settings/security/en.json";

import esTransfers from "./i18n/inventory/transfers/es.json";
import enTransfers from "./i18n/inventory/transfers/en.json";

import esTransformations from "./i18n/inventory/transformations/es.json";
import enTransformations from "./i18n/inventory/transformations/en.json";

i18n.on("languageChanged", (lng) => {
  moment.locale(lng);
  localStorage.setItem("language", lng);
});

const combinedESTranslation = {
  ...esCollectionDetails,
  ...esAllCollections,
  ...esCollectionPageHistory,
  ...esComponents,
  ...esSidebarMenu,
  ...esPrices,
  ...esBusinessesList,
  ...esIndividualsList,
  ...esBusinessesAddressCard,
  ...esBusinessesBanksAccountsCard,
  ...esBasicBusinessDataCard,
  ...esBusinessCertificatesCard,
  ...esBusinessUcoCertificatesCard,
  ...esBusinessCustomPricesCard,
  ...esBusinesscollectionCard,
  ...esBusinessRecurrentCollectionCard,
  ...esBusinessWithdrawalsCard,
  ...esWithdrawals,
  ...esVerificationList,
  ...esVerificationNew,
  ...esIndividualsAddressCard,
  ...esBasicIndividualsDataCard,
  ...esIndividualsCertificatesCard,
  ...esIndividualUcoCertificatesCard,
  ...esIndividualsCollectionCard,
  ...esIndividualsCustomPricesCard,
  ...esIndividualsBanksAccountsCard,
  ...esIndividualsRecurrentCollectionCard,
  ...esIndividualsWithdrawalsCard,
  ...esValidationSchema,
  ...esFormats,
  ...esSignIn,
  ...esSales,
  ...esClients,
  ...esSecurity,
  ...esTransfers,
  ...esTransformations,
};

const combinedENTranslation = {
  ...enAllCollections,
  ...enCollectionDetails,
  ...enCollectionPageHistory,
  ...enComponents,
  ...enSidebarMenu,
  ...enPrices,
  ...enBusinessesList,
  ...enIndividualsList,
  ...enBusinessesAddressCard,
  ...enBusinessesBanksAccountsCard,
  ...enBasicBusinessDataCard,
  ...enBusinessCertificatesCard,
  ...enBusinessUcoCertificatesCard,
  ...enBusinessCustomPricesCard,
  ...enBusinesscollectionCard,
  ...enIndividualUcoCertificatesCard,
  ...enBusinessRecurrentCollectionCard,
  ...enBusinessWithdrawalsCard,
  ...enWithdrawals,
  ...enVerificationList,
  ...enVerificationNew,
  ...enIndividualsAddressCard,
  ...enBasicIndividualsDataCard,
  ...enIndividualsCertificatesCard,
  ...enIndividualsCollectionCard,
  ...enIndividualsCustomPricesCard,
  ...enIndividualsBanksAccountsCard,
  ...enIndividualsRecurrentCollectionCard,
  ...enIndividualsWithdrawalsCard,
  ...enValidationSchema,
  ...enFormats,
  ...enSignIn,
  ...enSales,
  ...enClients,
  ...enSecurity,
  ...enTransfers,
  ...enTransformations,
};

const resources = {
  es: { translation: combinedESTranslation },
  en: { translation: combinedENTranslation },
};

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem("language"),
    fallbackLng: "es",
    ns: ["translation"], // Ajustar esto según tus namespaces
    defaultNS: "translation",
    // debug: true,
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
      useSuspense: true,
    },
  });

export default i18n;
