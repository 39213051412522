import { Fragment, useCallback, useEffect } from "react";

import { Box, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";


import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { ReactComponent as ChevronRight } from "../../../../assets/icons/modals/chevronRight.svg";
import CustomText from "../../texts/CustomText";
import { setCollectionEditStateModalState } from "../../../../redux/slices/collections/collectionDetailsInfoModalStatesSlice";
import { firstLetterCapitalizeFormat } from "../../../../utils/formats/firstLetterCapitalizeFormat";
import { fetchCollectorsList } from "../../../../redux/thunks/collectors/collectorsListThunk";

interface CustomCollectorPickerInputProps {
  label?: string;
  id: string;
  required: boolean;
  error?: string | undefined;
  width?: number;
  disabledPrice?: boolean;
}

export default function CustomCollectorPickerInput(
  props: CustomCollectorPickerInputProps
) {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();

  const { collectionDetails } = useAppSelector(
    (state) => state.collectionDetails
  );

  const dispatch = useAppDispatch();

  const getCollectorsList = useCallback(() => {
    if (collectionDetails && collectionDetails.collector && collectionDetails.collector.id === -1 && collectionDetails.collector.name && collectionDetails.collector.phone) {
      dispatch(fetchCollectorsList());
    }
  }, [dispatch, collectionDetails.collector]);



  useEffect(() => {
    getCollectorsList();
  }, [getCollectorsList]);



  const handleOpenModal = () => {
    dispatch(
      setCollectionEditStateModalState({
        id: "editAssignmentDetail",
        value: false,
      })
    );
    dispatch(
      setCollectionEditStateModalState({
        id: "selectCollector",
        value: true,
      })
    );
  };

  return (
    <Box
      sx={{
        marginBottom: props.disabledPrice ? PP(8) : PP(16),
        width: props.width ? PP(props.width) : "100%",
      }}
    >
      <CustomText
        smallSize
        noSpacing
        color="#969696"
        mediumWeight
        style={{ marginBottom: PP(8), marginTop: PP(16), fontSize: PP(14) }}
      >
        {props.label}
      </CustomText>

      <Box
        sx={[
          {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: PP(4),
            padding: (collectionDetails.state === "pending" || collectionDetails.state === "assigned") ? `${PP(8)} ${PP(12)}` : "0",
            paddingRight: "0px",
            backgroundColor: "#FFFFFF",
            width: props.width ? PP(props.width) : "auto",
            height: "auto",
            border: (collectionDetails.state === "pending" || collectionDetails.state === "assigned") ? props.error
              ? `${PP(1)} solid #E73B85`
              : `${PP(1)} solid #E8E8E8` : "transparent"
          },
        ]}
      >
        <Box>
          {collectionDetails && collectionDetails.collector ? (
            <Box>
              <CustomText smallSize noSpacing color="#4D4D4D">
                {`${firstLetterCapitalizeFormat(collectionDetails.collector.name)} - ${collectionDetails.collector.phone}`}
              </CustomText>
            </Box>
          ) : (
            <Fragment>
              <CustomText smallSize noSpacing color="#C4C4C4">
                {t("collectionAssignmentDetail.customCollectorPickerInput.Selecciona el recolector")}
              </CustomText>
            </Fragment>
          )}
        </Box>


        {(collectionDetails.state === "pending" || collectionDetails.state === "assigned") ?
          <IconButton
            onClick={handleOpenModal}
            sx={{
              textTransform: "none",
              borderRadius: PP(8),
              height: PP(32),
              width: PP(32),
              display: "flex",
              justifyContent: "flex-end",
              backgroundColor: "transparent",
              //border: "none",
              "&:hover": {
                backgroundColor: "transparent",
              },
              "&:focus": {
                backgroundColor: "transparent",
              },
            }}
          >
            <ChevronRight style={{ height: PP(16), width: PP(16) }} />
          </IconButton> : <></>
        }
      </Box>
      {
        props.error && (
          <CustomText superSmallSize noSpacing color="#E73B85">
            {props.error}
          </CustomText>
        )
      }
    </Box >
  );
}
