import { ChangeEvent, useEffect, useState } from "react";

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

import CustomText from "../../../texts/CustomText";
import {
  collectionsStates as collectionsStatesNames,
  collectionsStatesArray,
  collectionsStatesArrayReset,
} from "../../../../../utils/constants/collections/collectionsStates";
import { useAppSelector, useAppDispatch } from "../../../../../redux/store";
import {
  setCollectionsStatesFilter,
  setSelectedStatesFilterArray,
  setStatesCollectionsFilterQuery,
} from "../../../../../redux/slices/collections/collectionsListOtherFiltersBySlice";
import usePixelPerfect from "../../../../../hooks/old/usePixelPerfect";
import { useTranslation } from "react-i18next";

interface CustomCheckCollectionsStatesFilterProps {
  width?: number;
}


export default function CustomCheckCollectionsStatesFilter(
  props: CustomCheckCollectionsStatesFilterProps
) {
  const { PP2 } = usePixelPerfect();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const collectionsStates: any = useAppSelector(
    (state) => state.collectionsListOtherFilters
  );

  const { statesCollectionsListFilterQuery } = useAppSelector(
    (state) => state.collectionsListOtherFilters
  );

  const [selectedStates, setSelectedStates] = useState(() => {
    // Inicializamos el estado local con las claves en español de collectionsStatesNames
    const initialSelectedStates = collectionsStatesArray
      .filter((state) => collectionsStates[state.state])
      .map((state) => collectionsStatesNames[state.state]);

    return initialSelectedStates;
  });

  function updateSelectedStates(
    currentStates: string[],
    stateName: string,
    isChecked: boolean
  ): string[] {

    if (isChecked) {
      // Agrego el estado si está marcado, pero primero verifico si ya existe en el array
      if (!currentStates.includes(collectionsStatesNames[stateName])) {
        return [...currentStates, t(`chips.states.${collectionsStatesNames[stateName]}`)];
      }
    } else {
      // Quito el estado si no está marcado
      return currentStates.filter(
        (state) => state !== t(`chips.states.${collectionsStatesNames[stateName]}`)
      );
    }

    return currentStates; // Si no se hace ningún cambio, devuelvo el array sin modificar
  }


  // Utilizo un efecto para actualizar el query cuando cambien las selecciones
  useEffect(() => {
    const filterKeys = {
      pending: false,
      assigned: false,
      canceled: false,
      collected: false,
      failed: false,
      finished: false,
    };

    // Consulta dinámica basada en las claves del objeto
    let query = "";
    Object.keys(filterKeys).forEach((key) => {
      // @ts-ignore
      if (collectionsStates[key]) {
        // Agrego al query si la casilla está marcada
        query += `state[$in][]=${key}&`;
      }
    });
    // Actualizo la query del filtro por estado de recolección
    dispatch(setStatesCollectionsFilterQuery(query));
  }, [collectionsStates, dispatch]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const checked = event.target.checked;
    // Creo un objeto con las propiedades state y value
    const updatedState = {
      state: name,
      value: checked,
    };

    // Despacho la acción con el objeto actualizado
    // @ts-ignore
    dispatch(setCollectionsStatesFilter(updatedState));

    // Actualizo el array de estados seleccionados utilizando la función updateSelectedStates
    setSelectedStates((prevSelectedStates) => {
      return updateSelectedStates(
        prevSelectedStates,
        updatedState.state,
        updatedState.value
      );
    });
  };

  useEffect(() => {
    dispatch(setSelectedStatesFilterArray(selectedStates));
    // console.log(selectedStates);
  }, [selectedStates]);

  useEffect(() => {
    if (statesCollectionsListFilterQuery === "") {
      collectionsStatesArrayReset.map((collectionState) => {
        dispatch(
          setCollectionsStatesFilter({
            // @ts-ignore
            state: Object.keys(collectionState)[0],
            value: false,
          })
        );
      });
    }
  }, [statesCollectionsListFilterQuery]);

  return (
    <Box sx={{ marginBottom: PP2(24), width: PP2(props.width) }}>
      <CustomText
        smallSize
        noSpacing
        color="#969696"
        mediumWeight
        style={{ marginBottom: PP2(8), marginTop: PP2(24), fontSize: "14px" }}
      >
        {t("collectionsFilterByModal.selectOthersCollectionsFiltersForm.customCheckCollectionsStatesFilter.Estado de recolección")}
      </CustomText>

      <Box
        sx={[
          {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: PP2(4),
            padding: `${PP2(4)} ${PP2(4)}`,
            paddingRight: "0px",
            backgroundColor: "#FFFFFF",
            width: props.width ? PP2(props.width) : PP2(367),
          },
        ]}
      >
        <FormControl component="fieldset" variant="standard">
          <FormGroup>
            {collectionsStatesArray.map((state, key) => {
              return (
                <FormControlLabel
                  key={key}
                  control={
                    <Checkbox
                      name={state.state}
                      checked={collectionsStates[state.state]}
                      onChange={handleChange}
                    />
                  }
                  label={<CustomText noSpacing>{t(`chips.states.${state.label}`)}</CustomText>}
                />
              );
            })}
          </FormGroup>
        </FormControl>
      </Box>
    </Box>
  );
}
