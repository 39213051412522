import { Box } from "@mui/material";

import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import CustomText from "../custom/texts/CustomText";

interface CollectionUnregisteredInformationProps {
  informativeIcon: React.ReactNode;
  text: string;
}

export default function CollectionUnregisteredInformation(
  props: CollectionUnregisteredInformationProps
) {
  const { informativeIcon, text } = props;
  const { PP } = usePixelPerfect();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      border={`${PP(1)} solid #E8E8E8`}
      borderRadius={PP(8)}
      width="100%"
      padding={PP(20)}
    >
      <Box>{informativeIcon}</Box>
      <CustomText color="#969696">{text}</CustomText>
    </Box>
  );
}
