import { IconButton, Typography } from "@mui/material";
import { ArrowBackRounded } from "@mui/icons-material";
import { ReactComponent as BluePlusIcon } from "../../../assets/icons/buttons/bluePlus.svg";

import usePixelPerfect from "../../../hooks/old/usePixelPerfect";

interface CustomTextProps {
  justifyCenter?: boolean;
  center?: boolean;
  right?: boolean;
  left?: boolean;
  premediumWeight?: boolean;
  mediumWeight?: boolean;
  superBigSize?: boolean;
  bigSize?: boolean;
  mediumSize?: boolean;
  preSmallSize?: boolean;
  smallSize?: boolean;
  superSmallSize?: boolean;
  width?: string;
  color?: string;
  padding?: string;
  noSpacing?: boolean;
  style?: React.CSSProperties;
  onClick?: () => void;
  showBackArrowButton?: boolean;
  bluePlusButton?: boolean;
  children: React.ReactNode;
}

const CustomText = (props: CustomTextProps) => {
  const { PP } = usePixelPerfect();

  const textStyles = {
    fontFamily: "'Inter', sans-serif",
    fontWeight: "400",
    fontSize: PP(16),
    ...(props.justifyCenter && { display: "flex", justifyContent: "center" }),
    ...(props.center && { textAlign: "center" }),
    ...(props.right && { textAlign: "end" }),
    ...(props.left && { textAlign: "start" }),
    ...(props.mediumWeight && { fontWeight: "700" }),
    ...(props.premediumWeight && { fontWeight: "500" }),
    ...(props.superBigSize && { fontSize: PP(28) }),
    ...(props.bigSize && { fontSize: PP(24) }),
    ...(props.mediumSize && { fontSize: PP(14) }),
    ...(props.smallSize && { fontSize: PP(16) }),
    ...(props.preSmallSize && { fontSize: PP(13) }),
    ...(props.superSmallSize && { fontSize: PP(12) }),
    ...(props.width && { width: props.width }),
    ...(props.color && { color: props.color }),
    ...(props.padding && { padding: props.padding }),
    ...(!props.noSpacing && { margin: `${PP(12)} ${PP(0)}` }),

    ...(props.style && props.style),
  };

  return (
    <Typography sx={textStyles} onClick={props.onClick}>
      <span>
        {props.showBackArrowButton && (
          <IconButton onClick={props.onClick}>
            <ArrowBackRounded
              style={{
                width: PP(24),
                height: PP(24),
              }}
            />
          </IconButton>
        )}
        {props.bluePlusButton && (
          <IconButton onClick={props.onClick}>
            <BluePlusIcon
              style={{
                width: PP(24),
                height: PP(24),
              }}
            />
          </IconButton>
        )}
        {props.children}
      </span>
    </Typography>
  );
};

export default CustomText;
