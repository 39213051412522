import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../redux/store";

import CustomButton from "../custom/buttons/default/CustomButton";
import CustomInput from "../custom/forms/default/CustomInput";
import {
  setMessage,
  setOpenSnackbar,
  setType,
} from "../../redux/slices/globalSnackbar/globalSnackbarSlice";
import CustomSelect from "../custom/forms/default/CustomSelect";
import { Box, SelectChangeEvent } from "@mui/material";
import CustomText from "../custom/texts/CustomText";
import { ReactComponent as InfoIcon } from "../../assets/icons/modals/infoIcon.svg";
import { ReactComponent as BlueCheckIcon } from "../../assets/icons/modals/blueCheck.svg";
import EditPortBulkDate from "../custom/forms/sales/EditPortBulkDate";
import { fetchClientsList } from "../../redux/thunks/transactions/clientsListThunk";
import { fetchEditTransactionInfo } from "../../redux/thunks/transactions/editTransactionInfoThunk";
import { setFormEditTransactionState } from "../../redux/slices/transactions/editTransactionSlice";
import { fetchTransactionDetails } from "../../redux/thunks/transactions/transactionsDetailsThunk";
import { fetchWarehousesList } from "../../redux/thunks/warehouse/warehousesListThunk";
import { buyerIdYup, validateYupSchema, warehouseId as warehouseIdYup } from "../../utils/forms/validationSchema";

interface InitialFormikValues {
  incoterm: string;
  currency: string;
  consecutive: string;
  contract: string;
  buyerType: string;
  buyerId: string;
  observation: string;
  warehouseId: string;
  extra?: { loadDate: string };
}

export default function EditTransactionForm() {
  const { PP } = usePixelPerfect();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { id } = useParams();

  const { transactionDetails } = useAppSelector(
    (state) => state.transactionDetails
  );

  const [loadDate, setLoadDate] = useState(
    transactionDetails.extra?.loadDate || ""
  );

  const { clientsList } = useAppSelector((state) => state.clientsList);
  const { warehousesList } = useAppSelector((state) => state.warehouseList);

  const getClientList = async () => {
    dispatch(fetchClientsList());
  };

  const getWarehouseList = async () => {
    if (warehousesList.length === 0) {
      dispatch(fetchWarehousesList({ page: 0 }));
    }
  }

  useEffect(() => {
    getClientList();
    getWarehouseList();
  }, [dispatch]);

  const getTransactionDetails = useCallback(async () => {
    if (id !== undefined) await dispatch(fetchTransactionDetails({ id: id }));
    if (
      transactionDetails?.extra &&
      transactionDetails?.extra?.loadDate !== ""
    ) {
      setLoadDate(transactionDetails?.extra?.loadDate);
    }
  }, [transactionDetails]);

  const initialFormikValues = {
    incoterm: transactionDetails.incoterm,
    currency: transactionDetails.currency,
    consecutive: transactionDetails.consecutive,
    contract: transactionDetails.contract,
    buyerType: transactionDetails.buyerType,
    buyerId: transactionDetails.buyerId,
    observation: transactionDetails.observation,
    warehouseId: transactionDetails.warehouseId,
    /* extra: { loadDate: loadDate }, */
  };

  const handleSubmit = async (data: InitialFormikValues) => {
    type PayloadType = {
      [key: string]: string | number | { [key: string]: string };
    };

    const payload: PayloadType = {};

    Object.entries(data).forEach(([key, value]) => {
      if (key === "consecutive" || key === "observation" || key === "contract") {
        payload[key] = value;
        return;
      }
      if (
        (value !== undefined && value !== "") ||
        key === "buyerType" ||
        (key === "extra" &&
          value &&
          typeof value === "object" &&
          "loadDate" in value)
      ) {
        if (key === "consecutive") {
          payload[key] = Number(value);
        } else {
          payload[key] = value;
        }
      }
    });

    payload.extra = { loadDate: loadDate };

    await dispatch(
      fetchEditTransactionInfo({ data: payload, transactionId: id })
    );
    await getTransactionDetails();
    dispatch(setOpenSnackbar(true));
    dispatch(setMessage("Sale saved"));
    dispatch(setType("sucessSnackbar"));
  };

  const validationSchema = validateYupSchema({
    // incoterm: incotermYup,
    // currency: currencyYup,
    // consecutive: consecutiveYup,
    // contract: contractYup,
    buyerId: buyerIdYup,
    warehouseId: warehouseIdYup
  });

  const formikEditTransaction = useFormik<InitialFormikValues>({
    initialValues: initialFormikValues,
    onSubmit: handleSubmit,
    validateOnChange: false,
    validationSchema: validationSchema,
  });

  const formikEditTransactionChange = useCallback(
    (
      event:
        | React.ChangeEvent<HTMLInputElement>
        | SelectChangeEvent<number | string>
    ) => {
      formikEditTransaction.setFieldValue(
        event.target.name,
        event.target.value
      );
      dispatch(
        setFormEditTransactionState({
          id: event.target.name,
          value: event.target.value,
        })
      );

      /* clear error input */
      formikEditTransaction.setFieldError(event.target.name, undefined);
    },
    [formikEditTransaction]
  );

  useEffect(() => {
    getTransactionDetails();
  }, [dispatch, id]);

  useEffect(() => {
    if (transactionDetails._id !== "") {
      formikEditTransaction.setValues({
        incoterm: transactionDetails.incoterm,
        currency: transactionDetails.currency,
        consecutive: transactionDetails.consecutive,
        contract: transactionDetails.contract,
        buyerType: transactionDetails.buyerType,
        buyerId: transactionDetails.buyerId,
        observation: transactionDetails.observation,
        warehouseId: transactionDetails.warehouseId,
        /* extra: { loadDate: loadDate }, */
      });
    }
  }, [transactionDetails]);

  return (
    <form noValidate onSubmit={formikEditTransaction.handleSubmit}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="77vw"
        >
          <Box width="30%">
            <CustomSelect
              id="buyerId"
              value={formikEditTransaction.values.buyerId}
              onChange={(e) => {
                formikEditTransactionChange(e);
                clientsList.forEach((client) => {
                  if (client.id === e.target.value) {
                    formikEditTransaction.setFieldValue(
                      "buyerType",
                      client.type
                    );
                  }
                });
              }}
              label={t("Cliente")}
              fullWidth
              items={clientsList.map((client) => ({
                label: client.name,
                value: client.id,
              }))}
              extraStyle={{ marginTop: PP(24) }}
              placeholder={t("Seleccionar cliente")}
              startSelectedIcon={<BlueCheckIcon />}
              disabled={transactionDetails.state === "close"}
              formik={formikEditTransaction}
            />
            <CustomSelect
              id="warehouseId"
              value={formikEditTransaction.values.warehouseId}
              onChange={(e) => {
                formikEditTransactionChange(e);
              }}
              label={t("Bodega de origen")}
              fullWidth
              items={warehousesList.map((warehouse) => {
                return { label: warehouse.name, value: warehouse.id };
              })}
              extraStyle={{ marginTop: PP(24) }}
              placeholder={t("Seleccionar bodega")}
              startSelectedIcon={<BlueCheckIcon />}
              disabled={transactionDetails.state === "close"}
              formik={formikEditTransaction}
            />
            <CustomInput
              id="consecutive"
              label={t("Consecutivo")}
              placeholder=""
              value={formikEditTransaction.values.consecutive}
              labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
              inputHeight={PP(40)}
              extraStyle={{ marginTop: PP(24) }}
              onChange={formikEditTransactionChange}
              disabled={transactionDetails.state === "close"}
            />

            <CustomSelect
              id="currency"
              value={formikEditTransaction.values.currency}
              onChange={formikEditTransactionChange}
              label={t("Moneda")}
              fullWidth
              items={[
                { value: "cop", label: "COP" },
                { value: "usd", label: "USD" },
              ]}
              extraStyle={{ marginTop: PP(24) }}
              placeholder={t("Seleccionar moneda")}
              disabled={transactionDetails.state === "close"}
            />
          </Box>
          <Box width="30%">
            <CustomSelect
              id="incoterm"
              value={formikEditTransaction.values.incoterm}
              onChange={formikEditTransactionChange}
              label={t("Incoterm")}
              fullWidth
              items={[
                { value: "N/A", label: "N/A" },
                { value: "FCA", label: "FCA" },
                { value: "CPT", label: "CPT" },
                { value: "CIP", label: "CIP" },
                { value: "DAP", label: "DAP" },
                { value: "DPU", label: "DPU" },
                { value: "DDP", label: "DDP" },
                { value: "CFR", label: "CFR" },
                { value: "FOB", label: "FOB" },
                { value: "FAS", label: "FAS" },
                { value: "CIF", label: "CIF" },
                { value: "EFW", label: "EFW" },
              ]}
              extraStyle={{ marginTop: PP(24) }}
              placeholder={t("Seleccionar incoterm")}
              disabled={transactionDetails.state === "close"}
            />
            <EditPortBulkDate
              width="100%"
              setLoadDate={setLoadDate}
              loadDate={loadDate}
            />

            <CustomInput
              id="contract"
              label={t("Número de contrato")}
              placeholder=""
              value={formikEditTransaction.values.contract}
              labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
              inputHeight={PP(40)}
              /* extraStyle={{ marginTop: PP(24) }} */
              onChange={formikEditTransactionChange}
              disabled={transactionDetails.state === "close"}
            />

            {/* {!props.finishExport && (
              <CustomButton
                width={300}
                height={44}
                noSpacing
                deleteButton
                label={t("Restar inventario")}
                onClick={handleSubtractInventory}
                formik={formikEditTransaction}
              />
            )} */}
          </Box>
          <Box width="30%">
            <Box>
              <CustomInput
                id="observation"
                type="textarea"
                labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
                extraStyle={{ marginTop: PP(24) }}
                label={t("Observaciones (opcional)")}
                placeholder={t("Tus observaciones...")}
                value={formikEditTransaction.values.observation}
                onChange={formikEditTransactionChange}
                multiline={true}
                rows={2}
                formik={formikEditTransaction}
                disabled={transactionDetails.state === "close"}
              />
              {transactionDetails.state !== "close" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    margin: "0px",
                  }}
                >
                  <InfoIcon
                    style={{ margin: `${PP(8)} 0px 0px` }}
                    width={PP(13)}
                    height={PP(13)}
                  />
                  <CustomText
                    superSmallSize
                    color="#585757"
                    style={{ margin: `${PP(8)} 0px 0px` }}
                  >
                    &nbsp;{t("Este campo tiene un límite de 100 caracteres")}
                  </CustomText>
                </Box>
              )}
            </Box>

            {transactionDetails.state === "open" && (
              <Box
                style={{
                  border: "1px solid #E8E8E8",
                  padding: `${PP(24)} ${PP(24)}`,
                  width: "100%",
                  marginTop: PP(24),
                  marginBottom: PP(24),
                  borderRadius: PP(8),
                  background: "rgba(85, 124, 230, 0.15)",
                }}
              >
                <Box width="100%">
                  <CustomText
                    superSmallSize
                    color="#557CE6"
                    noSpacing
                    style={{ marginBottom: PP(8) }}
                  >
                    {t("(Cantidades vendidas x Precios) - Dctos")}
                  </CustomText>
                </Box>

                <Box
                  width="100%"
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  {transactionDetails.extra?.totalPayment !== undefined ? (
                    <CustomText color="#557CE6" mediumWeight noSpacing>
                      {`${transactionDetails.extra.totalPayment.toLocaleString(
                        "es-MX"
                      )} ${transactionDetails.currency}`}
                    </CustomText>
                  ) : (
                    <CustomText color="#557CE6" mediumWeight noSpacing>
                      0 {transactionDetails.currency}
                    </CustomText>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        {transactionDetails.state === "open" && (
          <CustomButton
            width={600}
            height={44}
            noSpacing
            style={{ padding: "0px", marginTop: PP(48) }}
            type="submit"
            label={t("Guardar cambios")}
            formik={formikEditTransaction}
          />
        )}
      </Box>
    </form>
  );
}
