import { FormEvent, useCallback } from "react";

import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import usePixelPerfect from "../../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  setMessage,
  setOpenSnackbar,
  setType,
} from "../../../redux/slices/globalSnackbar/globalSnackbarSlice";
import { resetDeleteMaterialTransformation } from "../../../redux/slices/inventory/transformation/deleteMaterialTransformationSlice";
import { setTransformationMaterialsModalStates } from "../../../redux/slices/inventory/transformation/transformationMaterialsModalsStatesSlice";
import {
  setArrayTransformationsMaterials,
  setEditArrayTransformationsMaterials,
} from "../../../redux/slices/inventory/transformation/addMaterialTransformationSlice";
import { firstLetterCapitalizeFormat } from "../../../utils/formats/firstLetterCapitalizeFormat";
import CustomButton from "../../custom/buttons/default/CustomButton";
import CustomText from "../../custom/texts/CustomText";

export default function DeleteMaterialTransformationForm() {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    selectedToDeleteMaterial,
    selectedToDeleteMaterialName,
    selectedToDeleteTransformationMaterialId,
  } = useAppSelector((state) => state.deleteMaterialTransformation);

  const { materials } = useAppSelector(
    (state) => state.addMaterialTransformation
  );

  const resetForm = useCallback(async () => {
    dispatch(resetDeleteMaterialTransformation());
    dispatch(
      setTransformationMaterialsModalStates({
        id: "deleteMaterial",
        value: false,
      })
    );
  }, [dispatch]);

  const handleClickCloseModal = () => {
    resetForm();
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Elimina el material con materialId igual a selectedToDeleteTransformationMaterialId
    const updatedMaterials = materials.filter(
      (material) =>
        !(
          material.materialId === selectedToDeleteMaterial?.materialId &&
          material.type === selectedToDeleteMaterial?.type
        )
    );

    // Despacha el nuevo array de materiales
    dispatch(setEditArrayTransformationsMaterials(updatedMaterials));
    // Cierra el modal y muestra notificaciones
    handleClickCloseModal();
    dispatch(setType("sucessSnackbar"));
    dispatch(setMessage(t("snackbars.Material eliminado con éxito")));
    dispatch(setOpenSnackbar(true));
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Box
        sx={[
          {
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            backgroundColor: "#FFFFFF",
            width: PP(432),
            height: PP(40),
          },
        ]}
      >
        <CustomText noSpacing color="#585757" width="100%">
          ¿{t("Estás seguro que deseas eliminar")}
          <span style={{ color: "#FF3364" }}>
            {" "}
            {firstLetterCapitalizeFormat(selectedToDeleteMaterialName)}
          </span>
          ?
        </CustomText>
      </Box>

      <CustomButton
        width={432}
        height={44}
        noSpacing
        style={{ padding: "0px", marginTop: PP(24) }}
        deleteButton
        type="submit"
        label={t("Sí, deseo eliminarlo")}
      />
      <CustomButton
        noSpacing
        width={432}
        height={44}
        cancelButton
        onClick={handleClickCloseModal}
      />
    </form>
  );
}
