import React from 'react';
import { useFormikContext } from 'formik';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

interface FormValues {
    [key: string]: any;
}

interface Option {
    label: string;
    value: string;
}

interface BasicSelectProps {
    name: string;
    label: string;
    options: Option[];
}

const BasicSelect: React.FC<BasicSelectProps> = ({ name, label, options }) => {
    const { values, errors, touched, handleChange } = useFormikContext<FormValues>();

    return (
        <FormControl fullWidth variant="outlined" error={touched[name] && Boolean(errors[name])}>
            <Select
                displayEmpty
                name={name}
                value={values[name] || ''}
                onChange={handleChange}
                renderValue={(selected) =>
                    selected ? options.find(option => option.value === selected)?.label : label
                }
            >
                <MenuItem value="" disabled>
                    {label}
                </MenuItem>
                {options.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
            {touched[name] && errors[name] && (
                <FormHelperText>{errors[name]}</FormHelperText>
            )}
        </FormControl>
    );
};

export default BasicSelect;
