import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { Box, Divider, Paper } from "@mui/material";

import usePixelPerfect from "../../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import CustomText from "../../custom/texts/CustomText";
import CustomCompleteTableNoPagination from "../../custom/tables/default/CustomCompleteTableNoPagination";
import { setTransformationMaterialsModalStates } from "../../../redux/slices/inventory/transformation/transformationMaterialsModalsStatesSlice";
import CustomTransformationsMaterialsTableRow from "../../custom/tables/inventary/transfer/transformations/CustomTransformationsMaterialsTableRow";
import { setFormAddTransformationMaterial } from "../../../redux/slices/inventory/transformation/addMaterialTransformationSlice";
import { transformationsMaterialsTableHeadCells } from "../../../utils/constants/inventory/transformationsMaterialsTableHeadCells";

export default function TransformationOutputMaterial() {
    const { PP } = usePixelPerfect();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { materials } =
        useAppSelector((state) => state.addMaterialTransformation);

    useEffect(() => {

    }, [materials])


    return (
        <Box marginTop={PP(24)}>
            <Box
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <CustomText
                    noSpacing
                    premediumWeight
                    color="#1C1C1C"
                    style={{ fontSize: PP(20) }}
                >
                    {t("Materiales de salida")}
                </CustomText>
            </Box>

            <Divider style={{ marginTop: PP(16), marginBottom: PP(16) }} />
            <Box display="flex">
                <Box flex={1}>
                    <CustomCompleteTableNoPagination
                        status={""}
                        tableHeadCells={transformationsMaterialsTableHeadCells} //Encabezados de la tabla
                        children={materials.slice(1).map((element, key) => {
                            return (
                                <CustomTransformationsMaterialsTableRow
                                    element={element}
                                    key={key}
                                    myKey={key}
                                />
                            );
                        })}
                    />
                    <Box>
                        <Paper
                            style={{
                                border: `${PP(3)} dashed #557CE6`,
                                padding: `${PP(8)} ${PP(16)}`,
                                width: "auto",
                                height: "auto",
                                marginBottom: PP(16),
                                borderRadius: PP(8),
                                justifyContent: "center",
                                background: "rgba(85, 124, 230,0.1)",
                            }}
                        >
                            <CustomText
                                noSpacing
                                bluePlusButton
                                mediumWeight
                                color="#557CE6"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    dispatch(setTransformationMaterialsModalStates({
                                        id: "selectCategory",
                                        value: true,
                                    }));
                                    dispatch(setFormAddTransformationMaterial({
                                        id: "type",
                                        value: "output"
                                    }));
                                }}
                            >
                                {t("Añadir material")}
                            </CustomText>
                        </Paper>
                    </Box>
                </Box>
            </Box>
        </Box >
    );
}