import ExcelFullScreen from "../transactions/ExcelFullScreen";
import CollectionImageFullScreen from "./CollectionImageFullScreen";
import CollectionPdfFullScreen from "./CollectionPdfFullScreen";

interface CollectionFileFullScreenProps {
  fileURL: string;
  fileName: string;
  fileType: string;
  setFullSreen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CollectionFileFullScreen(
  props: CollectionFileFullScreenProps
) {
  if (props.fileType === "pdf") {
    return (
      <CollectionPdfFullScreen
        fileURL={props.fileURL}
        fileName={props.fileName}
        setFullSreen={props.setFullSreen}
      />
    );
  } else if (
    props.fileType === "png" ||
    props.fileType === "jpg" ||
    props.fileType === "jpeg"
  ) {
    return (
      <CollectionImageFullScreen
        fileURL={props.fileURL}
        fileName={props.fileName}
        setFullSreen={props.setFullSreen}
      />
    );
  } else if (props.fileType === "xlsx" || props.fileType === "csv") {
    return (
      <ExcelFullScreen
        fileURL={props.fileURL}
        fileName={props.fileName}
        setFullSreen={props.setFullSreen}
      />
    );
  }
  return <div>Unsupported file type</div>;
}
