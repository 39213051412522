import { Box, Chip, Divider, IconButton } from "@mui/material";

import { ReactComponent as ArrowLeft } from "../../assets/icons/layouts/arrow-left.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/layouts/edit.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/layouts/check.svg";
import { useAppSelector } from "../../redux/store";
import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import CustomText from "../../components/custom/texts/CustomText";
import useAppDispatch from "../../hooks/old/useAppDispatch";
import { reset as resetCollectionsList } from "../../redux/slices/collections/collectionListSlice";
import { createCollectionDateFormat } from "../../utils/formats/createCollectionDateFormat";
import { useTranslation } from "react-i18next";
import { transferStates } from "../../utils/constants/inventory/transferStates";
import { setTransferModalStatus } from "../../redux/slices/inventory/transfer/transferDetailsModalStatusSlice";

interface TransfersDetailsLayoutProps {
    children?: React.ReactNode;
}
export default function TransferDetailsLayout(
    props: TransfersDetailsLayoutProps
) {
    const { transferDetails } = useAppSelector(
        (status) => status.transferDetails
    );
    const { PP } = usePixelPerfect();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const goBack = () => {
        window.history.back();
        dispatch(resetCollectionsList());
    };

    const openEditTransferStatusModal = () => {
        dispatch(
            setTransferModalStatus({
                id: "editTransferStatus",
                value: true,
            })
        );
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: PP(8),
                }}
            >
                <IconButton
                    aria-label="comeBackToBusinessList"
                    onClick={goBack}
                    style={{ padding: "0px" }}
                >
                    <ArrowLeft style={{ width: PP(22), height: PP(22) }} />
                </IconButton>
                <CustomText color="#969696">&nbsp; {t("Volver")}</CustomText>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: PP(14),
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <CustomText
                        premediumWeight
                        noSpacing
                        bigSize
                        color="#1C1C1C"
                        style={{
                            lineHeight: PP(32),
                            marginRight: PP(8.23),
                        }}
                    >
                        {t("Transferencia")} {transferDetails.consecutive}
                    </CustomText>
                    <Box
                        style={{
                            borderLeft: `${PP(3)} solid #E8E8E8`,
                            height: PP(28),
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            paddingLeft: PP(16),
                        }}
                    >
                        {" "}
                        <Chip
                            style={{
                                borderRadius: PP(100),
                                borderWidth: PP(1),
                                width: "auto",
                                height: "auto",
                            }}
                            // @ts-ignore
                            variant={transferDetails.status}
                            label={
                                <CustomText
                                    noSpacing
                                    mediumWeight
                                    style={{ fontSize: PP(14), margin: `${PP(5)} 0px` }}
                                >
                                    {t(transferStates[transferDetails.status])}
                                </CustomText>
                            }
                        />
                        {transferDetails.status !== "finished" && (
                            <EditIcon
                                style={{
                                    marginLeft: PP(17),
                                    width: PP(22),
                                    height: PP(22),
                                    cursor: "pointer",
                                }}
                                onClick={openEditTransferStatusModal}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: PP(24),
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <Box
                        style={{
                            height: PP(28),
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            paddingRight: PP(24),
                        }}
                    >
                        <CustomText noSpacing superSmallSize color="#909090">
                            {t("Solicitud creada")} {createCollectionDateFormat(transferDetails.createdAt, t)}
                        </CustomText>
                    </Box>
                </Box>

                <Box
                    style={{
                        height: PP(28),
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        paddingLeft: PP(16),
                    }}
                >
                    <CheckIcon
                        style={{ marginRight: PP(8), width: PP(22), height: PP(22) }}
                    />
                    <CustomText noSpacing superSmallSize color="#909090">
                        {t("Última actualización")}:{" "}
                        {createCollectionDateFormat(transferDetails.updatedAt, t)}
                    </CustomText>
                </Box>
            </Box>
            <Divider style={{ marginBottom: PP(24) }} />
            <>{props.children}</>
        </Box >
    );
}