import { Box, Divider, Grid, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";

import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import CustomText from "../custom/texts/CustomText";
import { ReactComponent as ExternalLinkIcon } from "../../assets/icons/buttons/externalLink.svg";
import { useAppSelector } from "../../redux/store";
import { firstLetterCapitalizeFormat } from "../../utils/formats/firstLetterCapitalizeFormat";
import { scheduledCollectionDateFormat } from "../../utils/formats/scheduledCollectionDateFormat";
import { collectionTimeFormat } from "../../utils/formats/collectionTimeFormat";
import CollectionMoneyInformation from "./CollectionMoneyInformation";
import CollectionMaterialWeightControl from "./CollectionMaterialWeightControl";
import CollectionAproWithoutSeparating from "./CollectionAproWithoutSeparating";
import ObservationCollectorField from "./ObservationCollectorField";

export default function CollectionRequestInformation() {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const { collectionDetails } = useAppSelector(
    (state) => state.collectionDetails
  );

  return (
    <Box width="70%" marginTop={PP(24)}>
      {collectionDetails.operator && (
        <CustomText color="#7D7D7D" noSpacing>
          {t("collectionRequestInformation.Operador")}:{" "}
          <span style={{ fontWeight: 700, overflow: "hidden" }}>
            {collectionDetails.operator === "tf"
              ? firstLetterCapitalizeFormat("team foods")
              : firstLetterCapitalizeFormat(collectionDetails.operator)}
          </span>
        </CustomText>
      )}
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          height: PP(44),
          marginBottom: PP(16),
          overflow: "hidden",
        }}
      >
        <CustomText color="#1C1C1C" superBigSize premediumWeight noSpacing>
          {firstLetterCapitalizeFormat(collectionDetails.owner?.name)}
        </CustomText>
        <a
          href={`/users/${collectionDetails?.ownerType === "business"
            ? "businesses"
            : "individuals"
            }/${collectionDetails?.ownerId}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              padding: `${PP(8)} ${PP(16)}`,
            }}
          >
            <ExternalLinkIcon
              style={{
                width: PP(24),
                height: PP(24),
                cursor: "pointer",
                marginRight: PP(8),
              }}
            />
            <CustomText
              premediumWeight
              color="#969696"
              noSpacing
              style={{
                display: "flex",
                alignItems: "center",
                height: PP(24),
                cursor: "pointer",
              }}
            >
              {t("collectionRequestInformation.Ver perfil")}
            </CustomText>
          </Box>
        </a>
      </Box>
      <Paper
        style={{
          border: "1px solid #E8E8E8",
          padding: `${PP(24)} ${PP(24)}`,
          width: "100%",
          marginBottom: PP(16),
          borderRadius: PP(8),
        }}
      >
        <Grid container spacing={2} height={PP(84)} style={{ height: "auto" }}>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            style={{ marginBottom: PP(16) }}
          >
            <CustomText
              mediumSize
              premediumWeight
              color="#7D7D7D"
              noSpacing
              style={{ marginBottom: PP(8) }}
            >
              {t("collectionRequestInformation.Fecha de recolección")}
            </CustomText>
            <CustomText
              color="#4D4D4D"
              noSpacing
              style={{ marginBottom: PP(10) }}
            >
              {scheduledCollectionDateFormat(collectionDetails.date)}
            </CustomText>
            <CustomText noSpacing color="#7D7D7D">
              {collectionTimeFormat(collectionDetails.initialTime)} -{" "}
              {collectionTimeFormat(collectionDetails.finalTime)}
            </CustomText>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            style={{ marginBottom: PP(16) }}
          >
            <CustomText
              mediumSize
              premediumWeight
              color="#7D7D7D"
              noSpacing
              style={{ marginBottom: PP(8) }}
            >
              {t("collectionRequestInformation.Dirección")}
            </CustomText>
            <CustomText
              color="#4D4D4D"
              noSpacing
              style={{ marginBottom: PP(8) }}
            >
              {collectionDetails.addressName &&
                collectionDetails.addressName !== ""
                ? collectionDetails.addressDescription +
                "-" +
                collectionDetails.addressName
                : collectionDetails.addressDescription}
            </CustomText>
            <CustomText color="#7D7D7D" noSpacing>
              {firstLetterCapitalizeFormat(collectionDetails.city?.name)}
            </CustomText>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            style={{ marginBottom: PP(16) }}
          >
            <CustomText
              mediumSize
              premediumWeight
              color="#7D7D7D"
              noSpacing
              style={{ marginBottom: PP(8) }}
            >
              {t("collectionRequestInformation.Detalle")}
            </CustomText>
            <CustomText color="#1C1C1C" noSpacing>
              {collectionDetails.observation === null || ""
                ? "-"
                : firstLetterCapitalizeFormat(
                  collectionDetails.observation
                )}{" "}
            </CustomText>
          </Grid>
        </Grid>
      </Paper>

      <Divider style={{ width: "100%" }} />

      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        marginBottom={PP(16)}
      >
        <CollectionMaterialWeightControl />
        {collectionDetails.items !== undefined && (
          <CollectionMoneyInformation />
        )}
      </Box>
      {collectionDetails.observationCollector !== null && (
        <ObservationCollectorField />
      )}
      <CollectionAproWithoutSeparating />
    </Box>
  );
}
