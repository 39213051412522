import { useEffect } from "react";

import { IconButton, Snackbar } from "@mui/material";

import { ReactComponent as CloseIcon } from "../../../assets/icons/snackbar/x.svg";
import usePixelPerfect from "../../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { setOpenSnackbar } from "../../../redux/slices/globalSnackbar/globalSnackbarSlice";

export default function CustomSnackbar() {
  const { PP } = usePixelPerfect();
  const dispatch = useAppDispatch();

  const { openSnackBar, message, type } = useAppSelector(
    (state) => state.globalSnackBar
  );

  const handleClose = () => {
    dispatch(setOpenSnackbar(false));
  };

  useEffect(() => {}, [openSnackBar]);

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackBar}
        autoHideDuration={10000}
        onClose={handleClose}
        ContentProps={{
          sx: {
            background: type === "sucessSnackbar" ? "#0162AC" : "red",
            fontSize: PP(12),
            fontWeight: 600,
            color: "#EBEBEB",
            width: PP(325),
            height: PP(52),
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            borderRadius: PP(8),
            padding: PP(16),
          },
        }}
        message={message}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon style={{ height: PP(16), width: PP(16) }} />
            </IconButton>
          </>
        }
      />
    </>
  );
}
