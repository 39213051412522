import { MouseEventHandler } from "react";

import { Box } from "@mui/material";

import usePixelPerfect from "../../../hooks/old/usePixelPerfect";
import { ReactComponent as CloseModalIcon } from "../../../assets/icons/modals/closeModal.svg";

interface DetailsCollectionModalLayoutProps {
  modalWidth?: number;
  noCloseButton?: boolean;
  handleClose?: MouseEventHandler<SVGSVGElement>;
  children: React.ReactNode;
}

const DetailsCollectionModalLayout = (
  props: DetailsCollectionModalLayoutProps
) => {
  const { PP } = usePixelPerfect();

  return (
    <Box
      sx={[
        {
          backgroundColor: "#FFFFFF",
          borderRadius: PP(8),
          maxHeight: "90vh",
          overflow: "auto",
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: props.modalWidth ? PP(props.modalWidth) : PP(480),
          padding: PP(24),
        },
      ]}
    >
      {!props.noCloseButton && (
        <CloseModalIcon
          onClick={props.handleClose}
          style={{
            ...{
              width: PP(24),
              height: PP(24),
              position: "absolute",
              top: PP(20),
              right: PP(28),
              cursor: "pointer",
            },
          }}
        />
      )}
      {props.children}
    </Box>
  );
};

export default DetailsCollectionModalLayout;
