import React from "react";

import { Grid, Divider as MuiDivider } from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";

import PageHeader from "../../../../components/old/generalOld/PageHeader";
import EnhancedTable from "../../../../components/old/prices/old/EnhancedTable";

const Divider = styled(MuiDivider)(spacing);

const headCells = [
  { id: "id", alignment: "left", label: "Material", disablePadding: false },
  { id: "product", alignment: "left", label: "Precio", disablePadding: false },
  {
    id: "actions",
    alignment: "right",
    label: "Acciones",
    disablePadding: true,
  },
];

function OrderList() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <PageHeader title={t("precios")} />
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable headCells={headCells} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default OrderList;
