export const transactionFilesTypes: { type: string; label: string }[] = [
  { type: "remission", label: "Remisión" },
  { type: "billing_landing", label: "Conocimiento de embarque" },
  { type: "certificado_origen", label: "Certificado de origen" },
  { type: "analisis_calidades", label: "Análisis de calidad" },
  { type: "factura_electronica", label: "Factura electrónica" },
  { type: "factura_proforma", label: "Factura proforma" },
  { type: "delivery_note", label: "Nota de entrega" },
  { type: "contrato", label: "Contrato" },
  { type: "annex_vii", label: "Anexo VII" },
  { type: "certificado_cze", label: "Certificado CZE" },
  { type: "certificado_cis", label: "Certificado CIS" },
  { type: "planilla_cargue", label: "Planilla de carga" },
  { type: "charge_account", label: "Cuenta de cargo" },
  { type: "acu_certificate", label: "Certificado ACU" },
  { type: "EXD", label: "Declaracion de exportacion" },
  { type: "poo_excel", label: "Puntos de origen" },
];

export const typesTransactionFilesObject: { [key: string]: string } = {
  acu_certificate: "Certificado ACU",
  charge_account: "Cuenta de cargo",
  evidence: "Evidencia",
  remission: "Remisión",
  billing_landing: "Conocimiento de embarque",
  certificado_origen: "Certificado de origen",
  analisis_calidades: "Análisis de calidad",
  factura_electronica: "Factura electrónica",
  factura_proforma: "Factura proforma",
  delivery_note: "Nota de entrega",
  contrato: "Contrato",
  annex_vii: "Anexo VII",
  certificado_cze: "Certificado CZE",
  certificado_cis: "Certificado CIS",
  planilla_cargue: "Planilla de carga",
  EXD: "Declaracion de exportacion",
  poo_excel: "Puntos de origen",
};
