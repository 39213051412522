import { createSlice } from "@reduxjs/toolkit";

import { ClientsListSliceType } from "../../../interfaces/redux/clientsListSliceInterface";
import { fetchGeneralClientsList } from "../../thunks/clients/clientsListThunk";

const initialState: ClientsListSliceType = {
  status: "idle",
  clientsList: [],
  totalRows: -1,
};

export const generalClientsListSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    resetClientsList: () => initialState,
  },
  extraReducers: (builder) => {
    //Get lista de clientes
    builder.addCase(fetchGeneralClientsList.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchGeneralClientsList.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.totalRows = action.payload.total;
      state.clientsList = action.payload.data;
    });
    builder.addCase(fetchGeneralClientsList.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export const { resetClientsList } = generalClientsListSlice.actions;

export default generalClientsListSlice.reducer;
