import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { collectionAttachedFilesModalsSliceType } from "../../../interfaces/redux/collectionAttachedFilesModalsSliceInterface";

type CollectionAttachedFilesModalKeys = any;

interface SetCollectionsModalStatePayload {
  id: CollectionAttachedFilesModalKeys;
  value: boolean;
}
const initialState: collectionAttachedFilesModalsSliceType = {
  selectTypeFile: false,
  attachFile: false,
  deleteAttachFile: false,
  fullScreen: false,
};

export const collectionAttachedFilesModalStatesSlice = createSlice({
  name: "collectionAttachedFilesModalStatesReducer",
  initialState,
  reducers: {
    setCollectionAttachedFilesModalState: (
      state: any,
      action: PayloadAction<SetCollectionsModalStatePayload>
    ) => {
      // @ts-ignore
      state[action.payload.id] = action.payload.value;
    },
    reset: () => initialState,
  },
});

export const { setCollectionAttachedFilesModalState, reset } =
  collectionAttachedFilesModalStatesSlice.actions;

export default collectionAttachedFilesModalStatesSlice.reducer;
