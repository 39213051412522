import { createAsyncThunk } from "@reduxjs/toolkit";

import { postRedux } from "../../../utils/old/api";

export const fetchAddMaterialTransaction = createAsyncThunk(
  "addMaterialTransaction/fetchPost",
  async (
    payload: {
      materials: {}[];
    },
    thunkAPI
  ) => {
    try {
      const response = await postRedux("transaction/material", payload);
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    } catch (error) {
      const errors = thunkAPI.rejectWithValue(error);
      return errors;
    }
  }
);
