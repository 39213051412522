import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";

import { getRedux } from "../../../utils/old/api";

const formatDate = (date: Date) => {
  return moment(date.toString()).format("YYYY-MM-DD");
};

export const fetchCollectionsList = createAsyncThunk(
  "collectionList/fetchGet",
  async (payload: { page: number; filter?: string }, thunkAPI) => {
    try {
      const response = await getRedux(
        `/collection?admin=true&$limit=50&$skip=${
          payload.page * 50
        }&$sort[date]=-1&${
          payload.filter
            ? payload.filter
            : `date[$gte]=${formatDate(new Date())}&date[$lte]=${formatDate(
                new Date()
              )}&`
        }`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
