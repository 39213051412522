import { LinearProgress, Paper, Table, TableContainer } from "@mui/material";

import { tableHeadCellType } from "../../../../interfaces/data/tableHeadCellInterface";
import CustomTableBody from "./CustomTableBody";
import CustomTableHead from "./CustomTableHead";

type CompleteTableProps = {
  status: "idle" | "loading" | "succeeded" | "failed" | string;
  tableHeadCells: tableHeadCellType[];
  children: React.ReactNode;
};

export default function CustomCompleteTableNoPagination(
  props: CompleteTableProps
) {
  const { status, tableHeadCells, children } = props;

  return (
    <Paper style={{ width: "100%" }}>
      {status === "loading" && <LinearProgress />}
      <TableContainer style={{ width: "100%" }}>
        <Table
          style={{ width: "100%" }}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          <CustomTableHead tableHeadCells={tableHeadCells} />
          <CustomTableBody tableRow={children} />
        </Table>
      </TableContainer>
    </Paper>
  );
}
