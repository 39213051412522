import "moment/locale/es";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";

export const scheduledCollectionDateFormat = (data: Date | string): string => {
  const { t } = useTranslation();

  const formattedDate = moment(data)
    .locale("es")
    .format("MMMM D, YYYY")
    .replace(/^\w/, (c) => c.toUpperCase());
  /* .tz("America/Bogota") */

  const translatedMonth = t(
    `months.${formattedDate.split(" ")[0].toLowerCase()}`
  );

  return `${translatedMonth} ${formattedDate.substring(
    formattedDate.indexOf(" ") + 1
  )}`;
};
