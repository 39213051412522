import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { useFormik } from "formik";

import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { firstLetterCapitalizeFormat } from "../../utils/formats/firstLetterCapitalizeFormat";
import {
  quantity as quantityValidation,
  price as priceValidation,
  validateYupSchema,
  materialId,
} from "../../utils/forms/validationSchema";
import CustomButton from "../custom/buttons/default/CustomButton";
import CustomInput from "../custom/forms/default/CustomInput";
import CustomText from "../custom/texts/CustomText";
import CustomAlert from "../custom/alerts/CustomAlert";
import {
  reset as resetEditTransactionMaterial,
  setFormEditMaterialState,
} from "../../redux/slices/transactions/editMaterialTransactionSlice";
import { fetchEditMaterialTransaction } from "../../redux/thunks/transactions/editMaterialTransactionThunk";
import { fetchTransactionDetails } from "../../redux/thunks/transactions/transactionsDetailsThunk";
import { setTransactionMaterialsModalState } from "../../redux/slices/transactions/transactionMaterialsModalsStatesSlice";

export default function EditMaterialTransactionForm() {
  const { id } = useParams();
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { selectedMaterialToEdit } = useAppSelector(
    (state) => state.editMaterialTransaction
  );

  const { transactionDetails } = useAppSelector(
    (state) => state.transactionDetails
  );

  const [totalToPay, setTotalToPay] = useState(0);
  const [disabledPriceInput, setDisabledPriceInput] = useState(false);

  const resetForm = useCallback(async () => {
    dispatch(resetEditTransactionMaterial());
    dispatch(
      setTransactionMaterialsModalState({ id: "editQuantity", value: false })
    );
  }, [dispatch]);

  const handleClickCloseModal = () => {
    resetForm();
  };

  const handleChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    formikEditMaterialTransaction.setFieldValue("quantity", value);

    dispatch(
      setFormEditMaterialState({ id: "quantity", value: event.target.value })
    );
  };

  const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    formikEditMaterialTransaction.setFieldValue("price", value);
    dispatch(
      setFormEditMaterialState({ id: "price", value: event.target.value })
    );
  };

  const handleChangeDiscount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    formikEditMaterialTransaction.setFieldValue("discount", value);
    dispatch(setFormEditMaterialState({ id: "discount", value: value }));
  };

  const initialFormikValues = {
    quantity: selectedMaterialToEdit.quantity,
    price: selectedMaterialToEdit.price,
    discount: selectedMaterialToEdit.discount,
    materialId: selectedMaterialToEdit.materialId,
  };

  const validationSchema = validateYupSchema({
    quantity: quantityValidation,
    price: priceValidation,
  });

  const handleSubmit = async (data: {
    quantity: number;
    price: number;
    discount: number;
    materialId: number;
  }) => {
    const payload = {
      materials: [
        {
          quantity: Number(data.quantity),
          price: Number(data.price),
          discount: Number(data.discount),
          materialId: selectedMaterialToEdit.materialId,
        },
      ],
    };

    dispatch(
      fetchEditMaterialTransaction({
        data: payload,
        transactionId: id,
      })
    );
    if (id !== undefined) dispatch(fetchTransactionDetails({ id: id }));
    handleClickCloseModal();
  };

  const formikEditMaterialTransaction = useFormik({
    initialValues: initialFormikValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: false,
  });

  useEffect(() => {
    const newTotalToPay =
      Number(formikEditMaterialTransaction.values.price) *
        Number(formikEditMaterialTransaction.values.quantity) -
      Number(formikEditMaterialTransaction.values.discount);
    setTotalToPay(newTotalToPay);
  }, [
    formikEditMaterialTransaction.values.price,
    formikEditMaterialTransaction.values.quantity,
    formikEditMaterialTransaction.values.discount,
    formikEditMaterialTransaction.isSubmitting,
  ]);

  return (
    <form noValidate onSubmit={formikEditMaterialTransaction.handleSubmit}>
      <CustomInput
        id="category"
        label={t("Categoría")}
        placeholder="00"
        value={t(selectedMaterialToEdit.material.category)}
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        inputHeight={PP(40)}
        inputBorderRadius={PP(8)}
        backgroundColor="#F6F6F6"
        extraStyle={{ marginBottom: PP(16), marginTop: PP(24) }}
        disabled
      />
      <CustomInput
        id="materialId"
        label={t("Material")}
        placeholder="00"
        value={firstLetterCapitalizeFormat(
          selectedMaterialToEdit.material.name
        )}
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        inputHeight={PP(40)}
        inputBorderRadius={PP(8)}
        backgroundColor="#F6F6F6"
        extraStyle={{ marginBottom: PP(16) }}
        disabled
      />
      <CustomInput
        id="quantity"
        label={t("Cantidad")}
        placeholder="00"
        formik={formikEditMaterialTransaction}
        value={formikEditMaterialTransaction.values.quantity}
        onChange={handleChangeQuantity}
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        extraStyle={{ marginBottom: PP(16) }}
        inputHeight={PP(40)}
        inputBorderRadius={PP(8)}
        endAdornmentValue={
          <CustomText noSpacing color="#969696">
            {selectedMaterialToEdit.material.measure}
          </CustomText>
        }
      />
      {/* Alerta informativa Para ACU */}
      {selectedMaterialToEdit.material.id === 1 && (
        <CustomAlert alertText={t("Ingresar peso NETO del material")} />
      )}

      <CustomInput
        id="price"
        label={t("Precio unitario")}
        placeholder="00"
        disabled={disabledPriceInput}
        formik={formikEditMaterialTransaction}
        value={formikEditMaterialTransaction.values.price}
        onChange={handleChangePrice}
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        extraStyle={{ marginBottom: PP(16) }}
        inputHeight={PP(24)}
        inputBorderRadius={PP(8)}
        startAdornmentValue={
          <CustomText noSpacing color="#969696">
            $
          </CustomText>
        }
      />

      <CustomInput
        id="discount"
        label={t("Descuento")}
        placeholder="0.00"
        formik={formikEditMaterialTransaction}
        value={formikEditMaterialTransaction.values.discount}
        disabled={disabledPriceInput}
        onChange={handleChangeDiscount}
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        extraStyle={{ marginBottom: PP(16) }}
        inputHeight={PP(24)}
        inputBorderRadius={PP(8)}
        endAdornmentValue={
          <CustomText noSpacing color="#969696">
            {transactionDetails.currency}
          </CustomText>
        }
      />
      <Box display="flex" flexDirection="row">
        <CustomText noSpacing color="#4D4D4D">
          {t("Total a pagar")}:
        </CustomText>
        <CustomText noSpacing mediumWeight color="#4D4D4D">
          &nbsp;&nbsp;$ {totalToPay.toLocaleString("es-MX")}
        </CustomText>
      </Box>
      <CustomButton
        width={432}
        height={44}
        noSpacing
        style={{ padding: "0px", marginTop: PP(24) }}
        type="submit"
        label={t("Guardar")}
        formik={formikEditMaterialTransaction}
      />
      <CustomButton
        noSpacing
        width={432}
        height={44}
        cancelButton
        onClick={handleClickCloseModal}
        formik={formikEditMaterialTransaction}
      />
    </form>
  );
}
