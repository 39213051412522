import { createAsyncThunk } from "@reduxjs/toolkit";

import { getRedux } from "../../../../utils/old/api";

export const fetchBusinessCollectionsSearch = createAsyncThunk(
  "businessCollectionsSearch/fetchGet",
  async (payload: { text: string; id: string }, thunkAPI) => {
    try {
      const response = await getRedux(
        `/collection?$or[0][consecutive]=${payload.text}&$limit=100&ownerId=${payload.id}&ownerType=business`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
