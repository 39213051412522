export const collectionsFailedReasonsOptions: string[] = [
  "Camión se averió",
  "El camión se llenó",
  "Envió fuera de ventana horaria",
  "Falta de tiempo",
  "Fue cancelado por el comercial",
  "Los datos están errados",
  "Botaron el reciclaje",
  "El punto está cerrado definitivamente",
  "El punto está cerrado temporalmente",
  "Ya no reciclan con muta",
  "No me atendieron (en la franja horaria)",
  "No tiene residuos",
  "No tienen big bag",
  "No tienen pimpinas",
  "Otra empresa les recogió",
  "Residuos contaminados",
  "Reasignado a otro recolector",
];
