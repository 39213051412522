import { useState } from "react";

import moment from "moment";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "dayjs/locale/en";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box, TextField, ThemeProvider } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import usePixelPerfect from "../../../../../hooks/old/usePixelPerfect";
import CustomText from "../../../texts/CustomText";
import CustomSwitch from "../../../switch/CustomSwitch";
import theme from "../../../../../theme/customThemesOwnComponents/DatePickerInput";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import {
  setEndChipDate,
  setEndDateValue,
  setIsSwitchOnSlice,
  setRangeDateCollectionsListFilterQuery,
  setStartChipDate,
  setStartDateValue,
} from "../../../../../redux/slices/collections/collectionsListOtherFiltersBySlice";
import { daysOfWeekSpanish } from "../../../../../utils/constants/calendar/daysWeekCalendar";
import { ReactComponent as DatePickerIcon } from "../../../../../assets/icons/calendar/datePicker.svg";
import { ReactComponent as SwitchArrowDatePickerIcon } from "../../../../../assets/icons/calendar/switchArrowDatePicker.svg";
import { ReactComponent as LeftArrowDatePickerIcon } from "../../../../../assets/icons/calendar/leftArrowDatePicker.svg";
import { ReactComponent as RightArrowDatePickerIcon } from "../../../../../assets/icons/calendar/rightArrowDatePicker.svg";
import { useTranslation } from "react-i18next";

interface SelectCustomDateRangeFilterProps {
  width?: number;
}

const InputFormat = "DD[/]MM[/]YYYY";

const formatDate = (date: Date | string) => {
  return moment(date.toString()).format("YYYY-MM-DD");
};

const formatChipDate = (date: Date | string) => {
  return moment(date.toString()).format("DD[/]MM[/]YYYY");
};

// Establece el idioma español para dayjs
dayjs.locale("es");

// Función para deshabilitar domingos
const shouldDisableSunday = (date: Date | string) => {
  const dayjsDate = dayjs(date); // Convierte a dayjs
  return dayjsDate.day() === 0; // Devuelve true para los domingos, false para los demás días
};

export default function SelectCustomDateRangeFilter(
  props: SelectCustomDateRangeFilterProps
) {
  const { PP2 } = usePixelPerfect();
  const dispatch = useAppDispatch();
  const DateInputStyle = {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: PP2(8),
      "& fieldset": {
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
      },
    },
  };

  const { isSwitchOnSlice, startDateValue, endDateValue } = useAppSelector(
    (state) => state.collectionsListOtherFilters
  );
  const { t } = useTranslation();
  const [isSwitchOn, setIsSwitchOn] = useState(isSwitchOnSlice);

  const language = localStorage.getItem("language");

  const handleSwitchChange = (newState: boolean) => {
    setIsSwitchOn(newState);
    dispatch(setIsSwitchOnSlice(newState));
  };

  //Manejo de filtros

  const [startDate, setStartDate] = useState<string | Date>("");
  const [endDate, setEndDate] = useState<string | Date>("");

  const handleStartDateChange = (newValue: Date | null | string) => {
    if (newValue) {
      setStartDate(newValue);
      dispatch(setStartDateValue(newValue));
      dispatch(setStartChipDate(formatChipDate(newValue)));
      dispatch(
        setRangeDateCollectionsListFilterQuery(
          `date[$gte]=${formatDate(newValue)}&date[$lte]=${formatDate(
            endDate
          )}&`
        )
      );
    }
  };
  const handleEndDateChange = (newValue: Date | null | string) => {
    if (newValue) {
      setEndDate(newValue);
      dispatch(setEndChipDate(formatChipDate(newValue)));
      dispatch(setEndDateValue(newValue));
      dispatch(
        setRangeDateCollectionsListFilterQuery(
          `date[$gte]=${formatDate(startDate)}&date[$lte]=${formatDate(
            newValue
          )}&`
        )
      );
    }
  };

  return (
    <Box
      sx={[
        {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "column",
          backgroundColor: "#FFFFFF",
          width: PP2(props.width),
          padding: `${PP2(8)} ${PP2(12)}`,
        },
      ]}
    >
      <Box width={PP2(408)}>
        <Box
          width={PP2(418)}
          height={PP2(27)}
          display="flex"
          flexDirection={"row"}
          justifyContent="space-between"
          alignItems="center"
          style={{ padding: PP2(4) }}
        >
          <CustomText noSpacing color="#4D4D4D">
            {t(
              `collectionsFilterByModal.selectOthersCollectionsFiltersForm.customSelectRangeDateFilter.SelectCustomDateRangeFilter.Personalizar`
            )}
          </CustomText>
          <CustomSwitch checked={isSwitchOn} onChange={handleSwitchChange} />
        </Box>
        {isSwitchOn && (
          <>
            {" "}
            <Box width={PP2(408)} height={PP2(162)}>
              <ThemeProvider theme={theme}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  dayOfWeekStartsOn={0}
                  adapterLocale={language || undefined}
                >
                  <Box>
                    <CustomText
                      mediumSize
                      mediumWeight
                      noSpacing
                      color="#969696"
                      style={{ marginTop: PP2(16), marginBottom: PP2(8) }}
                    >
                      {t(
                        `collectionsFilterByModal.selectOthersCollectionsFiltersForm.customSelectRangeDateFilter.SelectCustomDateRangeFilter.Desde`
                      )}
                    </CustomText>

                    <DesktopDatePicker
                      inputFormat={InputFormat}
                      disableMaskedInput
                      value={startDateValue}
                      shouldDisableDate={shouldDisableSunday}
                      dayOfWeekFormatter={(day) => daysOfWeekSpanish[day]}
                      onChange={handleStartDateChange}
                      renderInput={(params) => (
                        <TextField
                          disabled
                          {...params}
                          sx={DateInputStyle}
                          inputProps={{
                            ...params.inputProps,
                            readOnly: true,
                          }}
                        />
                      )}
                      components={{
                        OpenPickerIcon: DatePickerIcon,
                        SwitchViewIcon: SwitchArrowDatePickerIcon,
                        LeftArrowIcon: LeftArrowDatePickerIcon,
                        RightArrowIcon: RightArrowDatePickerIcon,
                      }}
                      PopperProps={{
                        placement: "top-end",
                      }}
                    />
                  </Box>
                  <Box>
                    <CustomText
                      mediumSize
                      mediumWeight
                      noSpacing
                      color="#969696"
                      style={{ marginTop: PP2(16), marginBottom: PP2(8) }}
                    >
                      {t(
                        `collectionsFilterByModal.selectOthersCollectionsFiltersForm.customSelectRangeDateFilter.SelectCustomDateRangeFilter.Hasta`
                      )}
                    </CustomText>
                    <DesktopDatePicker
                      minDate={startDate}
                      inputFormat={InputFormat}
                      disableMaskedInput
                      value={endDateValue}
                      shouldDisableDate={shouldDisableSunday}
                      dayOfWeekFormatter={(day) => daysOfWeekSpanish[day]}
                      onChange={handleEndDateChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={DateInputStyle}
                          inputProps={{
                            ...params.inputProps,
                            readOnly: true,
                          }}
                        />
                      )}
                      components={{
                        OpenPickerIcon: DatePickerIcon,
                        SwitchViewIcon: SwitchArrowDatePickerIcon,
                        LeftArrowIcon: LeftArrowDatePickerIcon,
                        RightArrowIcon: RightArrowDatePickerIcon,
                      }}
                      PopperProps={{
                        placement: "top-end",
                      }}
                    />
                  </Box>
                </LocalizationProvider>
              </ThemeProvider>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}
