import { Box } from "@mui/material";

import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import { useAppSelector } from "../../redux/store";
import CustomText from "../custom/texts/CustomText";
import { useTranslation } from "react-i18next";

export default function ObservationCollectorField() {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();

  const { collectionDetails } = useAppSelector(
    (state) => state.collectionDetails
  );

  return collectionDetails.state === "collected" ||
    collectionDetails.state === "finished" ||
    collectionDetails.state === "canceled" ? (
    <Box
      style={{
        border: "1px solid #E8E8E8",
        padding: `${PP(24)} ${PP(24)}`,
        width: "100%",
        marginBottom: PP(24),
        borderRadius: PP(8),
      }}
    >
      <Box width="100%">
        <CustomText
          noSpacing
          color="#969696"
          mediumSize
          premediumWeight
          style={{ marginBottom: PP(8) }}
        >
          Observaciones
        </CustomText>
      </Box>
      <Box width="100%" display="flex" flexDirection="row">
        <CustomText color="#242323" noSpacing>
          {collectionDetails.observationCollector}
        </CustomText>
      </Box>
    </Box>
  ) : (
    <></>
  );
}
