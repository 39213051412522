import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";

import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import { setCollectionMaterialsModalState } from "../../redux/slices/collections/collectionsMaterialsModalsStates";
import { reset as resetDeleteMaterial } from "../../redux/slices/collections/deleteMaterialCollectionSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import CustomButton from "../custom/buttons/default/CustomButton";
import CustomText from "../custom/texts/CustomText";
import { fetchDeleteMaterialCollection } from "../../redux/thunks/collections/deleteMaterialCollection";
import { fetchCollectionDetails } from "../../redux/thunks/collections/collectionDetailsThunk";
import { firstLetterCapitalizeFormat } from "../../utils/formats/firstLetterCapitalizeFormat";

export default function DeleteMaterialCollectionForm() {
  const { id } = useParams();
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { selectedToDeleteMaterialName, selectedToDeleteMaterialId } =
    useAppSelector((state) => state.deleteMaterialCollection);

  const resetForm = useCallback(async () => {
    dispatch(resetDeleteMaterial());
    dispatch(
      setCollectionMaterialsModalState({ id: "deleteMaterial", value: false })
    );
  }, [dispatch]);

  const handleClickCloseModal = () => {
    resetForm();
  };

  const getCollectionDetails = async () => {
    if (id !== undefined) await dispatch(fetchCollectionDetails({ id: id }));
  };

  const handleSubmit = async () => {
    dispatch(
      fetchDeleteMaterialCollection({ materialId: selectedToDeleteMaterialId })
    );
    getCollectionDetails();
    handleClickCloseModal();
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Box
        sx={[
          {
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            backgroundColor: "#FFFFFF",
            width: PP(432),
            height: PP(40),
          },
        ]}
      >
        <CustomText noSpacing color="#585757" width="100%">
          {t(
            "collectionInformation.customDeleteMaterialModal.DeleteMaterialCollectionForm.¿Estás seguro que deseas eliminar"
          )}{" "}
          <span style={{ color: "#FF3364" }}>
            {firstLetterCapitalizeFormat(selectedToDeleteMaterialName)}
          </span>
          ?
        </CustomText>
      </Box>

      <CustomButton
        width={432}
        height={44}
        noSpacing
        style={{ padding: "0px", marginTop: PP(24) }}
        deleteButton
        type="submit"
        label={t(
          "collectionInformation.customDeleteMaterialModal.DeleteMaterialCollectionForm.Sí, deseo eliminarlo"
        )}
        onClick={handleSubmit}
      />
      <CustomButton
        noSpacing
        width={432}
        height={44}
        cancelButton
        onClick={handleClickCloseModal}
      />
    </form>
  );
}
