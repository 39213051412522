import { tableHeadCellType } from "../../../interfaces/data/tableHeadCellInterface";

export const clientsListTableHeadCells: tableHeadCellType[] = [
  {
    id: "name",
    alignment: "left",
    label: "Cliente",
    disablePadding: false,
  },
  {
    id: "legalName",
    alignment: "left",
    label: "Razón social",
    disablePadding: false,
  },
  {
    id: "documentNumber",
    alignment: "left",
    label: "Nit",
    disablePadding: false,
  },
  {
    id: "address",
    alignment: "left",
    label: "Dirección",
    disablePadding: false,
  },
  {
    id: "contactName",
    alignment: "left",
    label: "Contacto",
    disablePadding: false,
  },
  {
    id: "contactEmail",
    alignment: "left",
    label: "Correo",
    disablePadding: false,
  },
  {
    id: "contactPhone",
    alignment: "left",
    label: "Teléfono",
    disablePadding: false,
  },
];
