import { createAsyncThunk } from "@reduxjs/toolkit";

import { getRedux } from "../../../utils/old/api";

export const fetchGeneralClientsList = createAsyncThunk(
  "generalClientsList/fetchGet",
  async (payload: { page: number }, thunkAPI) => {
    try {
      const response = await getRedux(
        `/client?$limit=10&$skip=${
          payload.page * 10
        }&$sort[createdAt]=-1&$sort[updatedAt]=-1`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
