import PPD from "../../utils/modals/ppd";

const customChip = {
  chipStyle: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: PPD(14),
  },
  chip: {
    "bg-color": {
      pending: {
        default: "rgba(255, 229, 0, 0.15)",
      },
      assigned: {
        default: "rgba(214, 105, 0, 0.10)",
      },
      collected: {
        default: "rgba(0, 186, 52, 0.10)",
      },
      finished: {
        default: "rgba(4, 144, 250, 0.10)",
      },
      canceled: {
        default: "rgba(221, 228, 233, 0.50)",
      },
      failed: {
        default: "rgba(250, 220, 217, 0.50)",
      },
    },

    "text-color": {
      pending: {
        default: "#7A6E00",
      },
      assigned: {
        default: "#B34F00",
      },
      collected: {
        default: "#00701F",
      },
      finished: {
        default: "#014B90",
      },
      canceled: {
        default: "#4D4D4D",
      },
      failed: {
        default: "#A00221",
      },
    },

    "bd-color": {
      pending: {
        default: "rgba(122, 110, 0, 0.30)",
      },
      assigned: {
        default: "rgba(179, 79, 0, 0.30)",
      },
      collected: {
        default: "rgba(0, 112, 31, 0.30)",
      },
      finished: {
        default: "rgba(1, 75, 144, 0.30)",
      },
      canceled: {
        default: "rgba(77, 77, 77, 0.25)",
      },
      failed: {
        default: "rgba(140, 22, 31, 0.40)",
      },
    },
  },
};

export default customChip;
