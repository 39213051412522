import { tableHeadCellType } from "../../../interfaces/data/tableHeadCellInterface";

export const transactionDetailsTableHeadCells: tableHeadCellType[] = [
  {
    id: "materialName",
    alignment: "left",
    label: "Material",
    disablePadding: false,
  },
  {
    id: "quantity",
    alignment: "left",
    label: "Cantidad",
    disablePadding: false,
  },
  {
    id: "price",
    alignment: "left",
    label: "Precio unitario",
    disablePadding: false,
  },
  {
    id: "dcto",
    alignment: "left",
    label: "Descuento",
    disablePadding: false,
  },
  {
    id: "total",
    alignment: "left",
    label: "Subtotal",
    disablePadding: false,
  },
];
