import { createSlice } from "@reduxjs/toolkit";

import { ActiveTabSliceType } from "../../../interfaces/redux/activeTabUserProfileInterface";

const initialState: ActiveTabSliceType = {
  activeTabMainNavBar: "Precios",
};

export const activeTabMainNavBarSlice = createSlice({
  name: "activeTabMainNavBar",
  initialState,
  reducers: {
    setActiveTabMainNavBar: (state, action) => {
      state.activeTabMainNavBar = action.payload;
    },
  },
});

export const { setActiveTabMainNavBar } = activeTabMainNavBarSlice.actions;

export default activeTabMainNavBarSlice.reducer;
