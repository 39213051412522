import { IconButton } from "@mui/material";

import customTablePaginationStyles from "../../../../styles/tables/customTablePagination";
import { ReactComponent as PreviousArrow } from "../../../../assets/icons/tables/previousArrow.svg";
import { ReactComponent as NextArrow } from "../../../../assets/icons/tables/nextArrow.svg";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import CustomText from "../../texts/CustomText";
import { useTranslation } from "react-i18next";

interface CustomTablePaginationProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  setPage: (page: number) => void;
}

function CustomTablePagination(props: CustomTablePaginationProps) {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const { count, page, rowsPerPage, onPageChange, setPage } = props;

  const {
    mainContainer,
    paginationText,
    buttonsContainer,
    previousButton,
    nextButton,
    iconButton,
  } = customTablePaginationStyles;

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setPage(page - 1);
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setPage(page + 1);
    onPageChange(event, page + 1);
  };

  return (
    <div style={mainContainer}>
      <CustomText color="#585757" style={paginationText}>
        {t("tables.customTablePagination.Página")} {page + 1} {t("tables.customTablePagination.de")} {Math.ceil(count / rowsPerPage)}
      </CustomText>
      <div style={buttonsContainer}>
        <div style={previousButton}>
          <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="Página anterior"
            style={iconButton}
            sx={{
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <PreviousArrow style={{ marginRight: PP(14) }} /> {t("tables.customTablePagination.Anterior")}
          </IconButton>
        </div>
        <div style={nextButton}>
          <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="Página siguiente"
            style={iconButton}
            sx={{
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            {t("tables.customTablePagination.Siguiente")} <NextArrow style={{ marginLeft: PP(14) }} />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default CustomTablePagination;
