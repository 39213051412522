//Certificados-Personas
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  Card as MuiCard,
  CardContent,
  Grid as MuiGrid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";

import {
  fetchPerson,
  fetchPersonCertificates,
} from "../../../../../redux/old/slices/individualsSlicer";
import { useTranslation } from "react-i18next";

const Grid = styled(MuiGrid)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const Card = styled(MuiCard)(spacing);

const headCells = [
  { id: "month", alignment: "center", label: "Mes", disablePadding: true },
  { id: "year", alignment: "center", label: "Año", disablePadding: true },
  { id: "action", alignment: "center", label: "Acción", disablePadding: true },
];

//CertificatesCard

export default function CertificatesCard() {
  const { id } = useParams();
  const { t } = useTranslation();
  const baseUrl = "https://env-certificate.s3.amazonaws.com/";

  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const certificates = useSelector((state) => state.person.certificates);

  const dispatch = useDispatch();

  // Agrupar certificados por dirección y filtrar por type
  const groupedCertificates = certificates
    .filter((certificate) => certificate.type === "monthly_env_certificate")
    .reduce((acc, certificate) => {
      const key = certificate.extra.address;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(certificate);
      return acc;
    }, {});

  useEffect(() => {
    getPerson();
  }, []);

  const getPerson = async () => {
    dispatch(fetchPerson(id));
    dispatch(fetchPersonCertificates(id));
  };

  return (
    <React.Fragment>
      <Card mb={6}>
        <CardContent>
          <Grid justifyContent="space-between" container spacing={10}>
            <Grid item>
              <Typography variant="h6" gutterBottom>
                {t("Certificados ambientales")}
              </Typography>
            </Grid>
          </Grid>
          <br />
          <br />
          {Object.keys(groupedCertificates).map((description, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleAccordionChange(`panel${index}`)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontSize: "16px", color: "#585857" }}>
                  {description}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Table>
                  <TableHead>
                    <TableRow>
                      {headCells.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.alignment}
                          padding={headCell.disablePadding ? "none" : "normal"}
                        >
                          {t(`tables.tableHeadCells.${headCell.label}`)}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {groupedCertificates[description].map((row, rowIndex) => (
                      <TableRow key={rowIndex}>
                        <TableCell align="center">{row.extra.month}</TableCell>
                        <TableCell align="center">{row.extra.year}</TableCell>
                        <TableCell align="center">
                          <IconButton
                            aria-label="delete"
                            size="large"
                            component="a"
                            href={row.url}
                            target="_blank"
                          >
                            <DownloadIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
          ))}
          {groupedCertificates.length === 0 && (
            <Box display="flex" justifyContent="center">
              <Typography style={{ fontSize: "16px", color: "gray" }}>
                {t("No tiene certificados")}
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
