import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fileSelectedToDelete: {
    id: -1,
    name: "",
    ownerType: "",
    ownerId: -1,
    type: "",
    description: "",
    updatedAt: "",
    createdAt: "",
    url: "",
  },
  loading: false,
  status: -1,
  statusText: "",
};

export const deleteTransactionAttachFileSlice = createSlice({
  name: "deleteTransactionAttachFileReducer",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = -1;
      state.statusText = "";
    },
    setTransactionFileSelectedToDelete: (state, action) => {
      state.fileSelectedToDelete = action.payload;
    },
    reset: () => initialState,
  },
  //   extraReducers: (builder) => {
  //     // Eliminar archivo adjunto seleccionado, de la exportación
  //     builder.addCase(fetchDeleteExportAttachFile.pending, (state) => {
  //       state.loading = true;
  //     });
  //     builder.addCase(fetchDeleteExportAttachFile.fulfilled, (state, action) => {
  //       state.status = action.payload.status;
  //       state.statusText = action.payload.statusText;
  //       state.loading = false;
  //     });
  //     builder.addCase(fetchDeleteExportAttachFile.rejected, (state, action) => {
  //       state.loading = false;
  //     });
  //   },
});

export const { resetStatus, setTransactionFileSelectedToDelete, reset } =
  deleteTransactionAttachFileSlice.actions;

export default deleteTransactionAttachFileSlice.reducer;
