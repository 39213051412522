import { createRef, useEffect, useState } from "react";

import { Add as AddIcon } from "@mui/icons-material";
import { Button, Divider, Modal, Toolbar } from "@mui/material";
import { useTranslation } from "react-i18next";

import CustomHeaderTitle from "../../components/custom/headers/default/CustomHeaderTitle";
import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { ClientType } from "../../interfaces/data/clientInterface";
import CustomClientsListTableRow from "../../components/custom/tables/clients/CustomClientsListTableRow";
import { fetchGeneralClientsList } from "../../redux/thunks/clients/clientsListThunk";
import CustomCompleteTable from "../../components/custom/tables/default/CustomCompleteTable";
import { clientsListTableHeadCells } from "../../utils/constants/clientes/clientsListTableHeadCells";
import CustomCreateClientModal from "../../components/custom/modals/clients/CustomCreateClientModal";
import CustomEditClientModal from "../../components/custom/modals/clients/CustomEditClientModal";

export default function Clients() {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const ref = createRef();
  const [page, setPage] = useState<number>(0);
  const [createClientModal, setCreateClientModal] = useState(false);
  const [selectedClientToEdit, setSelectedClientToEdit] = useState<ClientType>({
    id: -1,
    name: "",
    legalName: "",
    documentNumber: "",
    address: "",
    contactName: "",
    contactEmail: "",
    contactPhone: -1,
    type: "",
  });
  const [editClientModal, setEditClientModal] = useState(false);

  const { clientsList, status, totalRows } = useAppSelector(
    (state) => state.generalClientsList
  );

  const handleChangePage = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (event !== null) {
      setPage(newPage);
    }
  };

  const getClientList = async () => {
    await dispatch(fetchGeneralClientsList({ page: page }));
  };

  useEffect(() => {
    getClientList();
  }, [page, dispatch]);

  return (
    <>
      <Toolbar
        style={{
          padding: "0px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <CustomHeaderTitle
          title={t("Clientes")}
          style={{ fontSize: PP(36), fontWeight: 500 }}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={(e) => setCreateClientModal(true)}
        >
          <AddIcon />
          {t("Crear cliente")}
        </Button>
      </Toolbar>
      <Divider />

      <CustomCompleteTable
        status={status}
        totalRows={totalRows || 0} // total de filas de la tabla = total de datos respuesta backend
        rowsPerPage={10} //Filas por pagina de tabla
        page={page} //página actual
        handleChangePage={handleChangePage} // Manejando cambio de página para consumo Api
        setPage={setPage} //Cambio de página actual
        tableHeadCells={clientsListTableHeadCells} //Encabezados de la tabla
        children={clientsList?.map((element: ClientType, key) => {
          return (
            <CustomClientsListTableRow
              element={element}
              key={key}
              myKey={key}
              setEditClientModal={setEditClientModal}
              setSelectedClientToEdit={setSelectedClientToEdit}
            />
          );
        })}
      />
      <Modal
        open={createClientModal}
        children={
          <CustomCreateClientModal
            ref={ref}
            setOpen={setCreateClientModal}
            getClients={getClientList}
          />
        }
      />
      <Modal
        open={editClientModal}
        children={
          <CustomEditClientModal
            ref={ref}
            setOpen={setEditClientModal}
            getClients={getClientList}
            selectedClientToEdit={selectedClientToEdit}
          />
        }
      />
    </>
  );
}
