import "moment/locale/es";
import moment from "moment-timezone";

export const dateFormat = (data) => {
  let firstPart = moment(data)
    .locale("es")
    .format("dddd DD [de]"); /* tz('America/Bogota'). */

  let secondPart = moment(data)
    .locale("es")
    .format("MMMM"); /* tz('America/Bogota'). */

  let UpperCaseMonth = secondPart[0].toUpperCase() + secondPart.substring(1);

  return firstPart + " " + UpperCaseMonth;
};
