import { createSlice } from "@reduxjs/toolkit";

import { createTransactionSliceType } from "../../../interfaces/redux/createTransactionSliceInterface";
import { fetchCreateTransaction } from "../../thunks/transactions/createTransactionThunk";

const initialState: createTransactionSliceType = {
  loading: false,
  status: -1,
  form: {
    incoterm: "",
    currency: "",
    consecutive: "",
    contract: "",
    buyerType: "client",
    buyerId: "",
    observation: "",
    warehouseId: "",
  },
};

export const createTransactionSlice = createSlice({
  name: "createTransactionReducer",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = -1;
    },
    setFormCreateTransaction: (state, action) => {
      const { id, value } = action.payload;
      state.form = {
        ...state.form,
        [id]: value,
      };
    },

    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Crear nueva venta
    builder.addCase(fetchCreateTransaction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCreateTransaction.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.loading = false;
    });
    builder.addCase(fetchCreateTransaction.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { resetStatus, reset, setFormCreateTransaction } =
  createTransactionSlice.actions;

export default createTransactionSlice.reducer;
