import { createAsyncThunk } from "@reduxjs/toolkit";

import { getRedux } from "../../../utils/old/api";

export const fetchCommercialsList = createAsyncThunk(
  "commercialsList/fetchGet",
  async (payload, thunkAPI) => {
    try {
      const response = await getRedux(`/user/commercial`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
