export const customMuiPickersTimePicker = {
  styleOverrides: {
    hourMinuteLabel: {
      "& h2": {
        fontSize: "3.75rem",
        fontWeight: 300,
      },
    },
  },
};
