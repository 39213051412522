import { tableHeadCellType } from "../../../interfaces/data/tableHeadCellInterface";

export const collectionDetailsTableHeadCells: tableHeadCellType[] = [
  {
    id: "materialName",
    alignment: "left",
    label: "Material",
    disablePadding: false,
  },
  {
    id: "quantity",
    alignment: "left",
    label: "Cantidad",
    disablePadding: false,
  },
  {
    id: "price",
    alignment: "left",
    label: "Precio unitario",
    disablePadding: false,
  },
  {
    id: "priceType",
    alignment: "left",
    label: "Tipo de precio",
    disablePadding: false,
  },
  {
    id: "total",
    alignment: "left",
    label: "Subtotal",
    disablePadding: false,
  },
];
