import PPD from "../../utils/modals/ppd";

export const customMuiTablePagination = {
  styleOverrides: {
    root: {
      display: "flex",
      justifyContent: "flex-end",
    },
    toolbar: {
      flex: "0 0 auto",
    },
    select: {
      marginRight: PPD(24),
    },
    selectIcon: {
      top: "50%",
      marginTop: PPD(-12),
    },
    actions: {
      marginLeft: PPD(24),
    },
  },
};
