import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Outlet, useLocation } from "react-router-dom";
import { Menu as EditIcon } from "@mui/icons-material";

import { Box, CssBaseline, Paper as MuiPaper, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";
import posthog from "posthog-js";

import GlobalStyle from "../components/old/generalOld/GlobalStyle";
import Navbar from "../components/old/navbar/old/Navbar";
import dashboardItems from "../components/old/sidebar/old/dashboardItems";
import Sidebar from "../components/old/sidebar/old/Sidebar";
import useAuth from "../hooks/old/useAuth";
import usePixelPerfect from "../hooks/old/usePixelPerfect";

interface DashboardProps {
  theme: any;
  children: React.ReactNode;
}

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${({ theme }: DashboardProps) => theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${({ theme }: DashboardProps) =>
    theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

export const DashboardLayout = (props: DashboardProps) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { PP2 } = usePixelPerfect();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  const { user } = useAuth();
  const location = useLocation();

  // @ts-ignore
  const userId = user && user.id;
  // @ts-ignore
  const userEmail = user && user.email;

  useEffect(() => {
    if (userId !== null) {
      // Llamando al evento "identify" con el ID del usuario logueado y el email
      posthog.capture("$identify", {
        distinct_id: userId,
        properties: { email: userEmail },
      });
      // Capturar el evento de "pageview" con información de la página visitada
      posthog.capture("$pageview", {
        url: location.pathname,
        title: document.title,
        distinct_id: userId,
      });
    }
  }, [userId, userEmail, location]);

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer
        // @ts-ignore
        theme={theme}
      >
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
          <IconButton
            aria-label="details"
            size="large"
            onClick={handleDrawerToggle}
          >
            <EditIcon />
          </IconButton>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={dashboardItems}
          />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={dashboardItems}
          />
        </Box>
      </Drawer>
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent theme={props.theme} p={isLgUp ? 12 : 5} style={{ padding: `${PP2(12)} ${PP2(24)}` }}>
          {props.children}
          <Outlet />
        </MainContent>
        {/* <Footer /> */}
      </AppContent>
    </Root>
  );
};
