interface TransferStates {
  pending: string;
  transit: string;
  received: string;
  finished: string;
  [key: string]: string; // firma de índice adicional que permite cualquier valor de cadena como índice válido
}

export const transferStates: TransferStates = {
  pending: "Pendiente",
  transit: "En tránsito",
  received: "Recibida",
  finished: "Terminada",
};

export const transferStatesArray: { state: string; label: string }[] = [
  { state: "pending", label: "Pendiente" },
  { state: "transit", label: "En tránsito" },
  { state: "received", label: "Recibida" },
  { state: "finished", label: "Terminada" },
];

export const allowedTransitions = {
  pending: ["transit"],
  transit: ["received"],
  received: ["finished"],
  finished: [""], // En este caso, no permitimos ninguna transición desde "finished"
};
