import { createAsyncThunk } from "@reduxjs/toolkit";

import { patchRedux } from "../../../utils/old/api";

export const fetchEditMaterialCollection = createAsyncThunk(
  "editMaterialCollection/fetchPatch",
  async (
    payload: { data: { quantity: number; price: number }; materialId: number },
    thunkAPI
  ) => {
    try {
      const response = await patchRedux(
        `/collection-material/${payload.materialId}`,
        payload.data
      );
      console.log(response.data);
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    } catch (error) {
      const errors = thunkAPI.rejectWithValue(error);
      console.log(errors);
      return errors;
    }
  }
);
