import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRedux } from "../../../utils/old/api";
import { routesDateFormat } from "../../../utils/old/routesDateFormat";

//Haciendo slice para obtener "raw" puntos para recolectar para el modal de "ver lista de puntos":

const initialState = {
  havePointsOnRoute: 0,
  pointsCollectorModal: [],
  collectorsWithPoints: [],
  routesDate: routesDateFormat(new Date()),
  showLoading: false,
  error: "",
};

// Para CollectionsRoutes
export const fetchPointsCollectionsListModal = createAsyncThunk(
  //Get
  "PointsModal/show",
  ({ collectorId, routesDate }) => {
    return getRedux(
      `/collector-route?collectorId=${collectorId}&date=${routesDate}&&admin=true`
    ).then((response) => {
      return response.data;
    });
  }
);

export const fetchCollectorsWithPoints = createAsyncThunk(
  //Get
  "collectorsPoints/show",
  (routesDate) => {
    return getRedux(
      `/collector-route?date=${routesDate}&$limit=100&admin=true`
    ).then((response) => {
      return response.data;
    });
  }
);

export const googleMapsModalSlice = createSlice({
  name: "googleMapsModalReducer",
  initialState,
  reducers: {
    setRoutesDate: (state, action) => {
      state.routesDate = action.payload;
    },
  },
  extraReducers: (builder) => {
    //fetchPointsCollectionsListModal
    builder.addCase(fetchPointsCollectionsListModal.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(
      fetchPointsCollectionsListModal.fulfilled,
      (state, action) => {
        state.havePointsOnRoute = action.payload.total;
        if (action.payload.total !== 0) {
          state.pointsCollectorModal = action.payload.data[0].raw;
        }

        state.showLoading = false;
      }
    );
    builder.addCase(
      fetchPointsCollectionsListModal.rejected,
      (state, action) => {
        state.showLoading = false;
        alert("Error obteniendo puntos de recolección");
      }
    );
    //fetchCollectorsWithPoints
    builder.addCase(fetchCollectorsWithPoints.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchCollectorsWithPoints.fulfilled, (state, action) => {
      state.collectorsWithPoints = action.payload.data;
      state.showLoading = false;
    });
    builder.addCase(fetchCollectorsWithPoints.rejected, (state, action) => {
      state.showLoading = false;
      alert(
        "Error obteniendo lista de recolectores con puntos establecidos de recolección"
      );
    });
  },
});

export const { setRoutesDate } = googleMapsModalSlice.actions;

export default googleMapsModalSlice.reducer;
