import { createSlice } from "@reduxjs/toolkit";

import { CollectionDetailsSliceType } from "../../../interfaces/redux/collectionDetailsSliceInterface";
import { fetchCollectionDetails } from "../../thunks/collections/collectionDetailsThunk";

const initialState: CollectionDetailsSliceType = {
  status: "idle",
  collectionDetails: {
    id: 0,
    consecutive: 0,
    ownerId: 0,
    ownerType: "",
    addressName: "",
    addressDescription: "",
    observation: "",
    date: "",
    state: "",
    source: "",
    total: 0,
    warehouseId: 0,
    collectorId: 0,
    collectorObservation: "",
    observationCollector: "",
    initialTime: "",
    finalTime: "",
    commercialId: 0,
    payment_method: "",
    operator: "",
    verificationStep: false,
    createdAt: "",
    updatedAt: "",
    owner: {
      id: 0,
      name: "",
      legal_name: "",
      internal_code: "",
      payment_method: "",
      document_type: "",
      document_number: "",
      email: "",
      phone: "",
      countryId: 0,
      ownerId: 0,
    },
    city: {
      name: "",
    },
    items: [],
    files: [],
    collector: { id: -1, name: "", last_name: "", phone: -1 },
  },
};

export const collectionDetailsSlice = createSlice({
  name: "collectionDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Get detalles de una recolección
    builder.addCase(fetchCollectionDetails.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchCollectionDetails.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.collectionDetails = action.payload;
    });
    builder.addCase(fetchCollectionDetails.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export const {} = collectionDetailsSlice.actions;

export default collectionDetailsSlice.reducer;
