import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRedux, patchRedux } from "../../../utils/old/api";

const initialState = {
  rows: [],
  pageWithdrawals: 0,
  totalRowsWithdrawals: 0,
  open: false,
  snackTxt: " ",
};

//Para WithdrawalList
export const fetchWithdrawalsList = createAsyncThunk(
  //Get
  "withdrawalsList/show",
  (cPage) => {
    return getRedux(
      `/withdrawal?state=pending&admin=true&$limit=20&$skip=${
        cPage * 20
      }&$sort[createdAt]=-1`
    ).then((response) => {
      return response.data;
    });
  }
);

export const fetchUpdateWithdrawal = createAsyncThunk(
  //Patch
  "updateWithdrawal/patch",
  ({ state, selectedWithdrawal }) => {
    return patchRedux(`/withdrawal/${selectedWithdrawal._id}`, state).then(
      (response) => {
        return response.data;
      }
    );
  }
);

export const withdrawalSlice = createSlice({
  name: "withdrawalReducer",
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload;
    },

    setPageWithdrawals: (state, action) => {
      state.pageWithdrawals = action.payload;
    },
  },
  extraReducers: (builder) => {
    //1
    builder.addCase(fetchWithdrawalsList.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchWithdrawalsList.fulfilled, (state, action) => {
      state.rows = action.payload.data;
      state.totalRowsWithdrawals = action.payload.total;
      state.showLoading = false;
    });
    //2
    builder.addCase(fetchUpdateWithdrawal.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchUpdateWithdrawal.fulfilled, (state) => {
      state.showLoading = false;
      state.snackTxt = "Retiro actualizado";
      state.open = true;
    });
    builder.addCase(fetchUpdateWithdrawal.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error actualizando retiro");
    });
  },
});

export const { setOpen, setPageWithdrawals } = withdrawalSlice.actions;
export default withdrawalSlice.reducer;
