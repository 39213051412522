import { useEffect, useState } from "react";

import { Visibility as EditIcon } from "@mui/icons-material";
import { NavLink, useSearchParams } from "react-router-dom";
import {
  Divider,
  IconButton,
  TableCell,
  TableRow,
  Toolbar,
  styled,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import CustomHeaderTitle from "../../../components/custom/headers/default/CustomHeaderTitle";
import CustomCompleteTable from "../../../components/custom/tables/default/CustomCompleteTable";
import usePixelPerfect from "../../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  createInventoryVerification,
  createInventoryVerificationItem,
  fetchVerificationDetails,
} from "../../../redux/thunks/inventory/verificationThunks";
import { reset } from "../../../redux/slices/inventory/inventoryVerificationListSlice";
import useAuth from "../../../hooks/old/useAuth";
import {
  verificationCollectionTableHeadCells,
  verificationMaterialsTableHeadCells,
} from "../../../utils/constants/inventory/inventoryVerificationTableHeadCells";

const CustomTableRow = styled(TableRow)<any>(({}) => ({}));

function VerificationNew() {
  const { verificationList, status, collections, materials, auxId } =
    useAppSelector((state) => state.verificationList);

  const { user } = useAuth();

  const dispatch = useAppDispatch();
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [verifiedAmount, setVerifiedAmount] = useState<VerifiedAmountState>({});

  const Containers = [
    {
      id: 2,
      name: "Pimpina de Pasta",
      weight: 1,
    },
    {
      id: 3,
      name: "Pimpina de PET",
      weight: 0.5,
    },
    {
      id: 144,
      name: "Pimpina de 14lt",
      weight: 0.75,
    },
    {
      id: 145,
      name: "Pimpina de 30lt",
      weight: 2,
    },
    {
      id: 146,
      name: "Caneca grande",
      weight: 10,
    },
    {
      id: 147,
      name: "Caneca pequeña",
      weight: 6,
    },
    {
      id: 84,
      name: "IBC",
      weight: 60,
    },
  ];

  useEffect(() => {
    getVerificationDetails();
  }, []);

  useEffect(() => {
    setVerifiedAmount(() => {
      const newState: any = {};
      materials.forEach((element: any) => {
        newState[element.id] = 0;
      });
      return newState;
    });
  }, [materials]);

  const getVerificationDetails = () => {
    dispatch(
      fetchVerificationDetails({
        date: searchParams.get("date"),
        collectorId: searchParams.get("collectorId"),
      })
    );
  };

  const handleVerify = async () => {
    let data = {
      collectorId: searchParams.get("collectorId") || "",
      date: searchParams.get("date") || "",
      userVerifier: user ?? {
        id: 0,
        email: "",
      },
    };
    dispatch(createInventoryVerification(data));
  };

  useEffect(() => {
    if (auxId) {
      createMaterials();
    }
  }, [auxId]);

  interface VerifiedAmountState {
    [key: string]: any;
  }

  const createMaterials = async () => {
    materials.forEach(async (element: any) => {
      let amount = parseFloat(verifiedAmount[element.id]);
      if (amount) {
        if (element.id === 1) {
          Containers.forEach((container) => {
            const containerQ = verifiedAmount[container.id];
            if (containerQ) {
              amount = amount - parseFloat(containerQ) * container.weight;
            }
          });
        }
        await dispatch(
          createInventoryVerificationItem({
            materialId: element.id,
            quantity: amount,
            verificationId: auxId,
          })
        );
      }
    });
    await dispatch(reset());
  };

  return (
    <>
      <Toolbar
        style={{
          padding: "0px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <CustomHeaderTitle
          title={t("Verificar recolecciones de {recolector}", {
            collector: searchParams.get("collector"),
          })}
          style={{ fontSize: PP(36), fontWeight: 500 }}
        />
      </Toolbar>
      <Divider />

      <CustomHeaderTitle
        title={t("Materiales recolectados")}
        style={{ fontSize: PP(22), fontWeight: 500 }}
      />
      <CustomCompleteTable
        status={status}
        tableHeadCells={verificationMaterialsTableHeadCells} //Encabezados de la tabla
        totalRows={50} // total de filas de la tabla = total de datos respuesta backend
        rowsPerPage={50} //Filas por pagina de tabla
        page={0} //página actual
        handleChangePage={() => {}} // Manejando cambio de página para consumo Api
        setPage={() => {}} //Cambio de página actual
        children={materials?.map((element: any, key) => {
          return (
            <CustomTableRow
              key={key}
              variant="default"
              style={{ cursor: "default" }}
            >
              {element && (
                <>
                  <TableCell style={{ color: "#212121", cursor: "default" }}>
                    {element.name}
                  </TableCell>
                  <TableCell style={{ color: "#212121", cursor: "default" }}>
                    {`${element.sum} ${element.measure}`}
                  </TableCell>
                  <TableCell style={{ color: "#212121", cursor: "default" }}>
                    <input
                      type="number"
                      min={0}
                      onChange={(t) => {
                        const name = element.id;
                        const nuevoValor: VerifiedAmountState = {
                          [name]: t.target.value,
                        };
                        setVerifiedAmount((prevState) => ({
                          ...prevState,
                          ...nuevoValor,
                        }));
                      }}
                      value={verifiedAmount[element.id]}
                    />{" "}
                    {element.measure}
                    {element.id === 1 && (
                      <>
                        <br />
                        {t("Ingresar peso bruto")}
                      </>
                    )}
                  </TableCell>
                </>
              )}
            </CustomTableRow>
          );
        })}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={(e) => {
          handleVerify();
        }}
        disabled={status == "loading"}
      >
        {t("Verificar")}
      </Button>

      <Divider />
      <CustomHeaderTitle
        title={t("Recolecciones")}
        style={{ fontSize: PP(22), fontWeight: 500 }}
      />

      <CustomCompleteTable
        status={status}
        tableHeadCells={verificationCollectionTableHeadCells} //Encabezados de la tabla
        totalRows={50} // total de filas de la tabla = total de datos respuesta backend
        rowsPerPage={50} //Filas por pagina de tabla
        page={0} //página actual
        handleChangePage={() => {}} // Manejando cambio de página para consumo Api
        setPage={() => {}} //Cambio de página actual
        children={collections?.map((element: any, key) => {
          return (
            <CustomTableRow
              key={key}
              variant="default"
              style={{ cursor: "default" }}
            >
              {element && (
                <>
                  <TableCell style={{ color: "#212121", cursor: "default" }}>
                    {element.name}
                  </TableCell>
                  <TableCell style={{ color: "#212121", cursor: "default" }}>
                    {element.city}
                  </TableCell>
                  <TableCell style={{ color: "#212121", cursor: "default" }}>
                    {element.addressDescription}
                  </TableCell>
                  <TableCell align="right" style={{ cursor: "default" }}>
                    <IconButton
                      aria-label="details"
                      component={NavLink}
                      to={`/collections/detail/${element.id}`}
                      size="large"
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </>
              )}
            </CustomTableRow>
          );
        })}
      />
    </>
  );
}

export default VerificationNew;
