import { createSlice } from "@reduxjs/toolkit";

import { citiesListSliceType } from "../../../interfaces/redux/citiesListSliceInterface";
import { fetchCitiesList } from "../../thunks/cities/citiesListThunk";

const initialState: citiesListSliceType = {
  status: "idle",
  citiesList: [],
  totalRows: 0,
  page: 0,
};

export const citiesListSlice = createSlice({
  name: "citiesList",
  initialState,
  reducers: {
    resetPage: (state) => {
      state.page = 0;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    //Get lista Ciudades
    builder.addCase(fetchCitiesList.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchCitiesList.fulfilled, (state, action) => {
      state.status = "succeeded";
      if (action.payload.data && action.payload.data.length > 0) {
        state.citiesList = state.citiesList.concat(action.payload.data);
      }
      state.totalRows = action.payload.total;
      state.page = state.page + 1;
    });
    builder.addCase(fetchCitiesList.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export const { resetPage, reset } = citiesListSlice.actions;

export default citiesListSlice.reducer;
