import { forwardRef } from "react";

import { Divider } from "@mui/material";

import DefaultModalLayout from "../../../../layouts/collections/modals/DefaultModalLayout";
import CustomText from "../../texts/CustomText";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import { useTranslation } from "react-i18next";
import CreateClientForm from "../../clients/CreateClientForm";

interface CustomCreateClientModalProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getClients: () => Promise<void>;
}

export default forwardRef(function CustomCreateClientModal(
  props: CustomCreateClientModalProps
) {
  const { setOpen, getClients } = props;
  const { PP2 } = usePixelPerfect();
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DefaultModalLayout handleClose={handleClose}>
      <CustomText
        noSpacing
        bigSize
        premediumWeight
        color="#1C1C1C"
        style={{ marginBottom: PP2(24) }}
      // onClick={resetForm}
      >
        {t("Crear cliente")}
      </CustomText>
      <Divider sx={{ margin: "0px" }} />
      <CreateClientForm setOpen={setOpen} getClientList={getClients} />
    </DefaultModalLayout>
  );
});
