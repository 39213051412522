import { useCallback } from "react";

import { useFormik } from "formik";
import usePixelPerfect from "../../../hooks/old/usePixelPerfect";
import { useAppDispatch } from "../../../redux/store";
import { useTranslation } from "react-i18next";
import {
  setMessage,
  setOpenSnackbar,
  setType,
} from "../../../redux/slices/globalSnackbar/globalSnackbarSlice";
import {
  addressYup,
  contactEmailYup,
  contactNameYup,
  contactPhoneYup,
  documentNumberYup,
  legalNameYup,
  nameYup,
  validateYupSchema,
} from "../../../utils/forms/validationSchema";
import { SelectChangeEvent } from "@mui/material";
import { setFormCreateTransaction } from "../../../redux/slices/transactions/createTransactionSlice";
import CustomInput from "../forms/default/CustomInput";
import CustomButton from "../buttons/default/CustomButton";
import { fetchCreateClient } from "../../../redux/thunks/clients/createClientThunk";
import { ClientType } from "../../../interfaces/data/clientInterface";
import { fetchEditClient } from "../../../redux/thunks/clients/editClientThunk";

interface EditClientFormProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getClientList: () => Promise<void>;
  selectedClientToEdit: ClientType;
}

export default function EditClientForm(props: EditClientFormProps) {
  const { PP } = usePixelPerfect();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { setOpen, getClientList, selectedClientToEdit } = props;

  const handleClickCloseModal = () => {
    setOpen(false);
  };

  const initialFormikValues = {
    name: selectedClientToEdit.name,
    legalName: selectedClientToEdit.legalName,
    documentNumber: selectedClientToEdit.documentNumber,
    address: selectedClientToEdit.address,
    contactName: selectedClientToEdit.contactName,
    contactEmail: selectedClientToEdit.contactEmail,
    contactPhone: selectedClientToEdit.contactPhone,
  };

  const handleSubmit = async (data: {
    name: string;
    legalName: string;
    documentNumber: string;
    address: string;
    contactName: string;
    contactEmail: string;
    contactPhone: number;
  }) => {
    await dispatch(
      fetchEditClient({ data: data, clientId: selectedClientToEdit.id })
    );
    dispatch(setOpenSnackbar(true));
    dispatch(setMessage(t("Actualizados datos del cliente")));
    dispatch(setType("sucessSnackbar"));
    await getClientList();
    handleClickCloseModal();
  };

  const validationSchema = validateYupSchema({
    name: nameYup,
    legalName: legalNameYup,
    documentNumber: documentNumberYup,
    address: addressYup,
    contactName: contactNameYup,
    contactEmail: contactEmailYup,
    contactPhone: contactPhoneYup,
  });

  const formikEditClient = useFormik({
    initialValues: initialFormikValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: false,
  });

  const formikEditClientChange = useCallback(
    (
      event:
        | React.ChangeEvent<HTMLInputElement>
        | SelectChangeEvent<number | string>
    ) => {
      formikEditClient.setFieldValue(event.target.name, event.target.value);
      dispatch(
        setFormCreateTransaction({
          id: event.target.name,
          value: event.target.value,
        })
      );
    },
    [formikEditClient]
  );

  return (
    <form noValidate onSubmit={formikEditClient.handleSubmit}>
      <CustomInput
        id="name"
        label={t("Nombre del cliente")}
        placeholder=""
        value={formikEditClient.values.name}
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        inputHeight={PP(40)}
        extraStyle={{ marginTop: PP(24) }}
        onChange={formikEditClientChange}
        formik={formikEditClient}
        type="string"
        required
      />
      <CustomInput
        id="legalName"
        label={t("Razón social")}
        placeholder=""
        value={formikEditClient.values.legalName}
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        inputHeight={PP(40)}
        extraStyle={{ marginTop: PP(24) }}
        onChange={formikEditClientChange}
        formik={formikEditClient}
        required
      />

      <CustomInput
        id="documentNumber"
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        extraStyle={{ marginTop: PP(24) }}
        label={t("Nit")}
        placeholder=""
        value={formikEditClient.values.documentNumber}
        onChange={formikEditClientChange}
        formik={formikEditClient}
        required
      />
      <CustomInput
        id="address"
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        extraStyle={{ marginTop: PP(24) }}
        label={t("Dirección")}
        placeholder=""
        value={formikEditClient.values.address}
        onChange={formikEditClientChange}
        formik={formikEditClient}
        required
      />
      <CustomInput
        id="contactName"
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        extraStyle={{ marginTop: PP(24) }}
        label={t("Nombre contacto")}
        placeholder=""
        value={formikEditClient.values.contactName}
        onChange={formikEditClientChange}
        formik={formikEditClient}
        required
      />
      <CustomInput
        id="contactEmail"
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        extraStyle={{ marginTop: PP(24) }}
        label={t("Correo")}
        placeholder=""
        value={formikEditClient.values.contactEmail}
        onChange={formikEditClientChange}
        formik={formikEditClient}
        required
      />
      <CustomInput
        id="contactPhone"
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        extraStyle={{ marginTop: PP(24) }}
        label={t("Teléfono")}
        placeholder=""
        value={formikEditClient.values.contactPhone}
        onChange={formikEditClientChange}
        formik={formikEditClient}
        required
      />
      <CustomButton
        width={432}
        height={44}
        noSpacing
        style={{ padding: "0px", marginTop: PP(24) }}
        type="submit"
        label={t("Editar")}
        formik={formikEditClient}
      />
      <CustomButton
        noSpacing
        width={432}
        height={44}
        cancelButton
        onClick={handleClickCloseModal}
        formik={formikEditClient}
      />
    </form>
  );
}
