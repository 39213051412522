import { forwardRef, Fragment, useCallback } from "react";

import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";

import useAppDispatch from "../../../../hooks/old/useAppDispatch";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import CustomText from "../../texts/CustomText";
import DefaultModalLayout from "../../../../layouts/collections/modals/DefaultModalLayout";
import { setTransactionAttachedFilesModalState } from "../../../../redux/slices/transactions/transactionAttachedFilesModalStatesSlice";
import TransactionAttachNewFileForm from "../../../transactions/TransactionAttachNewFileForm";

export default forwardRef(function CustomAttachNewTransactionFileModal() {
  const dispatch = useAppDispatch();
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();

  const resetForm = useCallback(async () => {
    dispatch(
      setTransactionAttachedFilesModalState({
        id: "attachFile",
        value: false,
      })
    );
  }, [dispatch]);

  const handleClickCloseModal = (event: React.MouseEvent<SVGSVGElement>) => {
    resetForm();
  };

  const handleBackClick = useCallback(async () => {
    dispatch(
      setTransactionAttachedFilesModalState({
        id: "attachFile",
        value: false,
      })
    );
    dispatch(
      setTransactionAttachedFilesModalState({
        id: "selectTypeFile",
        value: true,
      })
    );
  }, [dispatch]);

  return (
    <DefaultModalLayout handleClose={handleClickCloseModal}>
      <Fragment>
        <CustomText
          showBackArrowButton
          noSpacing
          bigSize
          premediumWeight
          color="#1C1C1C"
          style={{ marginBottom: PP(24) }}
          onClick={handleBackClick}
        >
          {t(
            "transactionAttachedFiles.customAttachNewTransactionFileModal.Adjuntar documento"
          )}
        </CustomText>
        <Divider sx={{ margin: "0px" }} />
        <TransactionAttachNewFileForm />
      </Fragment>
    </DefaultModalLayout>
  );
});
