import { createAsyncThunk } from "@reduxjs/toolkit";

import { patchRedux } from "../../../utils/old/api";

export const fetchUpdateUserInformation = createAsyncThunk(
  "updateUserInformation/fetchPatch",
  async (
    payload: {
      data: {
        legal_name: string;
        name: string;
        document_number: string;
        phone: string;
        email: string;
        payment_method: string;
        commercialId: number;
      };
      id: string | undefined;
      ownerType: string;
    },
    thunkAPI
  ) => {
    try {
      const response = await patchRedux(
        `/${payload.ownerType}/${payload.id}`,
        payload.data
      );
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    } catch (error) {
      const errors = thunkAPI.rejectWithValue(error);
      return errors;
    }
  }
);
