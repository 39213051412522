import { forwardRef, useState } from "react";

import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import { Box, Divider } from "@mui/material";

import { ReactComponent as BlueCheckIcon } from "../../../../../assets/icons/modals/blueCheck.svg";
import usePixelPerfect from "../../../../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { reset as resetTransformationsModals, setTransformationMaterialsModalStates } from "../../../../../redux/slices/inventory/transformation/transformationMaterialsModalsStatesSlice";
import MaterialsModalLayout from "../../../../../layouts/MaterialsModalLayout";
import CustomText from "../../../texts/CustomText";
import CustomSearchFilters from "../../../headers/default/CustomSearchFilters";
import { collectionsMaterialsCategories } from "../../../../../utils/constants/collections/collectionsMaterialsCategories";
import CustomButton from "../../../buttons/default/CustomButton";
import { setFormAddTransformationMaterial } from "../../../../../redux/slices/inventory/transformation/addMaterialTransformationSlice";


export default forwardRef(function CustomSelectCategory() {
    const { PP } = usePixelPerfect();
    const { t } = useTranslation();
    const { category } = useAppSelector(
        (state) => state.addMaterialCollection.form
    );

    const [selectedCategory, setSelectedCategory] = useState(category);

    const [loadMore, setLoadMore] = useState(false);

    const handleLoadMore = () => {
        setLoadMore(true);
    };

    const dispatch = useAppDispatch();

    const handleCloseClickModal = (event: React.MouseEvent<SVGSVGElement>) => {
        dispatch(resetTransformationsModals());
    };

    const handleBackModal = () => {
        dispatch(resetTransformationsModals());
    };

    const handleSaveCategory = () => {
        if (selectedCategory !== "") {
            dispatch(
                setFormAddTransformationMaterial({ id: "category", value: selectedCategory })
            );
            dispatch(
                setTransformationMaterialsModalStates({ id: "selectCategory", value: false })
            );
            dispatch(
                setTransformationMaterialsModalStates({ id: "addMaterial", value: true })
            );
        }
    };

    const handleSetSelected = (selected: string) => {
        setSelectedCategory(selected);
    };

    return (
        <MaterialsModalLayout handleClose={handleCloseClickModal}>
            <CustomText
                showBackArrowButton
                bigSize
                premediumWeight
                color="#1C1C1C"
                onClick={handleBackModal}
                style={{ marginBottom: PP(24) }}
            >
                {t("Seleccionar categoría")}
            </CustomText>
            <Divider style={{ marginBottom: PP(24) }} />
            <CustomSearchFilters
                about="onlySearch"
                placeholder={t("Buscar por nombre")}
                width={432}
                height={40}
                bradius={PP(4)}
                widthsearchicon={16}
                heightsearchicon={16}
            />
            <Box
                sx={[
                    {
                        borderRadius: PP(8),
                        minWidth: PP(432),
                        height: PP(160),
                        marginBottom: PP(24),
                        marginTop: PP(8),
                    },
                ]}
            >
                <Box>
                    <InfiniteScroll
                        height={PP(160)}
                        dataLength={collectionsMaterialsCategories.length}
                        next={handleLoadMore}
                        hasMore={collectionsMaterialsCategories.length >= 10}
                        loader={
                            <CustomText color="#969696">
                                {t("cargando...")}
                            </CustomText>
                        }
                    >
                        {collectionsMaterialsCategories.map((category, key) => (
                            <Box
                                onClick={() => handleSetSelected(category)}
                                key={key}
                                sx={[
                                    {
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        flexDirection: "row",
                                        padding: PP(16),
                                        color: "white",
                                        height: PP(40),
                                        "&:hover": {
                                            backgroundColor: "#ECF1FF",
                                            cursor: "pointer",
                                            borderRadius: PP(4),
                                        },
                                    },
                                    selectedCategory !== "" &&
                                    selectedCategory === category && {
                                        backgroundColor: "#ECF1FF",
                                        borderRadius: PP(4),
                                    },
                                ]}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        width: "100%",
                                    }}
                                >
                                    <Box>
                                        <CustomText
                                            noSpacing
                                            color={
                                                selectedCategory !== "" && selectedCategory === category
                                                    ? "#4463B8"
                                                    : "#1C1C1C"
                                            }
                                        >
                                            {t(`collectionInformation.customSelectCategoryModal.categories.${category}`)}
                                        </CustomText>
                                    </Box>
                                    {selectedCategory !== "" && selectedCategory === category && (
                                        <span>
                                            <BlueCheckIcon />
                                        </span>
                                    )}
                                </Box>
                            </Box>
                        ))}
                    </InfiniteScroll>
                </Box>
            </Box>

            <CustomButton
                width={432}
                height={44}
                label={t("Seleccionar")}
                onClick={handleSaveCategory}
            />
            <CustomButton
                noSpacing
                width={432}
                height={44}
                cancelButton
                onClick={handleBackModal}
            />
        </MaterialsModalLayout>
    );
});
