import { Fragment, useEffect } from "react";

import { Box, IconButton } from "@mui/material";
import usePixelPerfect from "../../../../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { ReactComponent as ChevronRight } from "../../../../../assets/icons/modals/chevronRight.svg";
import CustomText from "../../../texts/CustomText";
import { firstLetterCapitalizeFormat } from "../../../../../utils/formats/firstLetterCapitalizeFormat";
import { setCollectionsFilterByModalState } from "../../../../../redux/slices/collections/collectionsFilterByModalStatesSlice";
import { setSelectedCollectorFilter } from "../../../../../redux/slices/collections/collectionsListOtherFiltersBySlice";
import { useTranslation } from "react-i18next";

interface CustomSelectCategoryInputProps {
  label: string;
  width?: number;
}

export default function CustomSelectCollectorInput(
  props: CustomSelectCategoryInputProps
) {
  const { PP2 } = usePixelPerfect();
  const { t } = useTranslation();
  const { selectedCollectorFilter, collectorCollectionsListFilterQuery } =
    useAppSelector((state) => state.collectionsListOtherFilters);

  const dispatch = useAppDispatch();

  const handleOpenModal = () => {
    dispatch(
      setCollectionsFilterByModalState({
        id: "selectCollectorFilterModal",
        value: true,
      })
    );
  };

  useEffect(() => {
    if (collectorCollectionsListFilterQuery === "") {
      dispatch(setSelectedCollectorFilter(null));
    }
  }, [collectorCollectionsListFilterQuery]);

  return (
    <Box sx={{ marginBottom: PP2(24), width: PP2(props.width) }}>
      <CustomText
        smallSize
        noSpacing
        color="#969696"
        mediumWeight
        style={{ marginBottom: PP2(8), marginTop: PP2(24), fontSize: "14px" }}
      >
        {props.label}
      </CustomText>

      <Box
        sx={[
          {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: PP2(4),
            padding: `${PP2(8)} ${PP2(12)}`,
            paddingRight: "0px",
            backgroundColor: "#FFFFFF",
            width: props.width ? PP2(props.width) : PP2(367),
            height: "auto",
            border: `${PP2(1)} solid #E8E8E8`,
          },
        ]}
      >
        <Box>
          {selectedCollectorFilter !== null ? (
            <Box>
              <CustomText smallSize noSpacing color="#557CE6">
                {firstLetterCapitalizeFormat(selectedCollectorFilter.name)}
              </CustomText>
            </Box>
          ) : (
            <Fragment>
              <CustomText smallSize noSpacing color="#C4C4C4">
                {t("collectionsFilterByModal.selectOthersCollectionsFiltersForm.customSelectCollectorInput.Selecciona un recolector")}
              </CustomText>
            </Fragment>
          )}
        </Box>

        <IconButton
          onClick={handleOpenModal}
          sx={{
            textTransform: "none",
            borderRadius: PP2(8),
            height: PP2(32),
            width: PP2(32),
            display: "flex",
            justifyContent: "flex-end",
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent",
            },
            "&:focus": {
              backgroundColor: "transparent",
            },
          }}
        >
          <ChevronRight style={{ height: PP2(16), width: PP2(16) }} />
        </IconButton>
      </Box>
    </Box>
  );
}
