import { createSlice } from "@reduxjs/toolkit";

import { TransactionAttachNewExportFileSliceType } from "../../../interfaces/redux/transactionAttachNewExportFileSliceInterface";
import { fetchTransactionAttachNewFile } from "../../thunks/transactions/transactionAttachNewFileModalThunk";

const initialState: TransactionAttachNewExportFileSliceType = {
  loading: false,
  status: -1,
  form: {
    fileType: { type: "", label: "" },
    selectedDocument: "",
  },
};

export const transactionAttachNewFileModalSlice = createSlice({
  name: "transactionAttachNewFileModalReducer",
  initialState,
  reducers: {
    setFormAttachNewTransactionFileState: (state, action) => {
      const { id, value } = action.payload;
      state.form = {
        ...state.form,
        [id]: value,
      };
    },

    reset: () => initialState,
  },
  extraReducers: (builder) => {
    //Añadir nuevo archivo adjunto de transacción
    builder.addCase(fetchTransactionAttachNewFile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchTransactionAttachNewFile.fulfilled,
      (state, action) => {
        state.loading = false;
        state.status = action.payload.status;
      }
    );
    builder.addCase(fetchTransactionAttachNewFile.rejected, (state, action) => {
      state.loading = false;
      state.status = 403;
    });
  },
});

export const { reset, setFormAttachNewTransactionFileState } =
  transactionAttachNewFileModalSlice.actions;

export default transactionAttachNewFileModalSlice.reducer;
