import { forwardRef, useEffect, useState } from "react";

import { Box, Divider } from "@mui/material";
import { useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import CustomButton from "../../buttons/default/CustomButton";
import CustomText from "../../texts/CustomText";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import CustomSearchFilters from "../../headers/default/CustomSearchFilters";
import { ReactComponent as BlueCheckIcon } from "../../../../assets/icons/modals/blueCheck.svg";
import { setCollectionEditStateModalState } from "../../../../redux/slices/collections/collectionDetailsInfoModalStatesSlice";
import { CollectorType } from "../../../../interfaces/data/collectorInterface";
import { firstLetterCapitalizeFormat } from "../../../../utils/formats/firstLetterCapitalizeFormat";
import { setCollectionCollector } from "../../../../redux/slices/collections/updateCollectionCollectorSlice";
import { useTranslation } from "react-i18next";
import { fetchUpdateCollectionInfo } from "../../../../redux/thunks/collections/updateCollectionInfoThunk";
import MaterialsModalLayout from "../../../../layouts/MaterialsModalLayout";

export default forwardRef(function CustomSelectCollectorModal() {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const { id } = useParams();
  const { collectorsList } = useAppSelector((state) => state.collectorsList);
  const { selectedCollector } = useAppSelector(
    (state) => state.updateCollectionCollector
  );

  const [loadMore, setLoadMore] = useState(false);
  const [selectedCollectorState, setSelectedCollectorState] =
    useState(selectedCollector);

  useEffect(() => {
    setSelectedCollectorState(selectedCollector);
  }, [selectedCollector]);

  const handleLoadMore = () => {
    setLoadMore(true);
  };

  const dispatch = useAppDispatch();

  const handleCloseClickModal = (event: React.MouseEvent<SVGSVGElement>) => {
    dispatch(
      setCollectionEditStateModalState({
        id: "selectCollector",
        value: false,
      })
    );
  };

  const handleBackModal = () => {
    dispatch(
      setCollectionEditStateModalState({
        id: "selectCollector",
        value: false,
      })
    );
  };

  const handleSaveCollector = async () => {
    dispatch(setCollectionCollector(selectedCollectorState));
    if (id !== undefined) {
      await dispatch(
        fetchUpdateCollectionInfo({
          data: { state: "assigned", collectorId: selectedCollectorState.id },
          collectionId: id,
        })
      );
    }
    handleBackModal();
  };

  const handleSetSelected = (selectedCollector: CollectorType) => {
    setSelectedCollectorState(selectedCollector);
  };

  return (
    <MaterialsModalLayout handleClose={handleCloseClickModal}>
      <CustomText
        showBackArrowButton
        bigSize
        premediumWeight
        color="#1C1C1C"
        onClick={handleBackModal}
        style={{ marginBottom: PP(24) }}
      >
        {t(
          "collectionAssignmentDetail.customSelectCollectorModal.Selecciona el recolector"
        )}
      </CustomText>
      <Divider style={{ marginBottom: PP(24) }} />
      <CustomSearchFilters
        about="onlySearch"
        placeholder={t(
          "collectionAssignmentDetail.customSelectCollectorModal.Buscar por nombre o ID"
        )}
        width={432}
        height={40}
        bradius={PP(4)}
        widthsearchicon={16}
        heightsearchicon={16}
      />
      <Box
        sx={[
          {
            borderRadius: PP(8),
            minWidth: PP(432),
            height: PP(160),
            marginBottom: PP(24),
            marginTop: PP(8),
          },
        ]}
      >
        <Box>
          <InfiniteScroll
            height={PP(160)}
            dataLength={collectorsList.length}
            next={handleLoadMore}
            hasMore={collectorsList.length >= 10}
            loader={
              <CustomText color="#969696">
                {t(
                  "CollectionAssignmentDetail.customSelectCollectorModal.cargando"
                )}
              </CustomText>
            }
          >
            {collectorsList.map((collector, key) => (
              <Box
                onClick={() => handleSetSelected(collector)}
                key={key}
                sx={[
                  {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    padding: PP(16),
                    color: "white",
                    height: PP(40),
                    "&:hover": {
                      backgroundColor: "#ECF1FF",
                      cursor: "pointer",
                      borderRadius: PP(4),
                    },
                  },
                  selectedCollectorState.id !== -1 &&
                    selectedCollectorState.id === collector.id && {
                      backgroundColor: "#ECF1FF",
                      borderRadius: PP(4),
                    },
                ]}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box>
                    <CustomText
                      noSpacing
                      color={
                        selectedCollectorState.id !== -1 &&
                        selectedCollectorState.id === collector.id
                          ? "#4463B8"
                          : "#1C1C1C"
                      }
                    >
                      {`${firstLetterCapitalizeFormat(collector.name)} - ${
                        collector.id
                      }`}
                    </CustomText>
                  </Box>
                  {selectedCollectorState.id !== -1 &&
                    selectedCollectorState.id === collector.id && (
                      <span>
                        <BlueCheckIcon />
                      </span>
                    )}
                </Box>
              </Box>
            ))}
          </InfiniteScroll>
        </Box>
      </Box>

      <CustomButton
        width={432}
        height={44}
        label={t(
          "collectionAssignmentDetail.customSelectCollectorModal.Seleccionar"
        )}
        onClick={handleSaveCollector}
      />
      <CustomButton
        noSpacing
        width={432}
        height={44}
        cancelButton
        onClick={handleBackModal}
      />
    </MaterialsModalLayout>
  );
});
