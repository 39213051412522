import "react-app-polyfill/stable";

import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";

import reportWebVitals from "./utils/old/reportWebVitals";
import App from "./App";

import "./mocks/old";

import { ThemeProvider } from "./contexts/old/ThemeContext";

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <BrowserRouter>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </I18nextProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
