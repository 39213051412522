import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { UserCustomPricesModalStatesSliceType } from "../../../interfaces/redux/userCustomPricesModalStatesSliceInterface";

type UserCustomPricesModalKeys = any;

interface SetUserCustomPricesModalStatePayload {
  id: UserCustomPricesModalKeys;
  value: boolean;
}
const initialState: UserCustomPricesModalStatesSliceType = {
  addCustomPrice: false,
  selectMaterialCategory: false,
  selectMaterial: false,
  editCustomPrice: false,
  deleteCustomPrice: false,
};

export const userCustomPricesModalStatesSlice = createSlice({
  name: "userCustomPricesModalStatesReducer",
  initialState,
  reducers: {
    setUserCustomPricesModalState: (
      state: any,
      action: PayloadAction<SetUserCustomPricesModalStatePayload>
    ) => {
      // @ts-ignore
      state[action.payload.id] = action.payload.value;
    },
    reset: () => initialState,
  },
});

export const { setUserCustomPricesModalState, reset } =
  userCustomPricesModalStatesSlice.actions;

export default userCustomPricesModalStatesSlice.reducer;
