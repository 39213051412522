import { forwardRef, useState } from "react";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { Box, Divider } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import CustomButton from "../../buttons/default/CustomButton";
import CustomText from "../../texts/CustomText";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import { ReactComponent as BlueCheckIcon } from "../../../../assets/icons/modals/blueCheck.svg";
import DetailsCollectionModalLayout from "../../../../layouts/collections/modals/DetailsCollectionModalLayout";
import { collectionsStatesArray } from "../../../../utils/constants/collections/collectionsStates";
import { setCollectionState } from "../../../../redux/slices/collections/updateCollectionStateSlice";
import { setCollectionEditStateModalState } from "../../../../redux/slices/collections/collectionDetailsInfoModalStatesSlice";
import { fetchUpdateCollectionInfo } from "../../../../redux/thunks/collections/updateCollectionInfoThunk";
import { fetchCollectionDetails } from "../../../../redux/thunks/collections/collectionDetailsThunk";
import {
  setMessage,
  setOpenSnackbar,
  setType,
} from "../../../../redux/slices/globalSnackbar/globalSnackbarSlice";

export default forwardRef(function CustomEditCollectionStateModal() {
  const { id } = useParams();
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const { collectionDetails } = useAppSelector(
    (state) => state.collectionDetails
  );

  let hasItemWithIdOne =
    collectionDetails.items.find((item) => item.materialId === 1) !== undefined; //Verificando si en los materiales de la recolección está el UCO
  let ucoVerifiedNegative = false;

  const [selectedCollectionState, setSelectedCollectionState] = useState(
    collectionDetails.state
  );

  const [loadMore, setLoadMore] = useState(false);

  const handleLoadMore = () => {
    setLoadMore(true);
  };

  const dispatch = useAppDispatch();

  const handleCloseClickModal = (event: React.MouseEvent<SVGSVGElement>) => {
    dispatch(
      setCollectionEditStateModalState({
        id: "editCollectionState",
        value: false,
      })
    );
  };

  const handleBackModal = () => {
    dispatch(
      setCollectionEditStateModalState({
        id: "editCollectionState",
        value: false,
      })
    );
  };

  const updateNewCollectionState = async () => {
    if (id !== undefined) {
      await dispatch(
        fetchUpdateCollectionInfo({
          data: { state: selectedCollectionState },
          collectionId: id,
        })
      );
    }
    if (id !== undefined) await dispatch(fetchCollectionDetails({ id: id }));
    handleBackModal();

    //IF (ESTADO DE ACTUALIZACIÓN ESTA OK...) ELSE...(ERROR)
    // dispatch(setOpenSnackbar(true));
    // dispatch(setMessage("Cambios guardados exitosamente"));
    // dispatch(setType("sucessSnackbar"));
  };

  const handleSaveNewState = async () => {
    setIsLoading(true);
    dispatch(setCollectionState(selectedCollectionState));

    if (
      selectedCollectionState !== "failed" &&
      selectedCollectionState !== "canceled" &&
      selectedCollectionState !== "finished"
    ) {
      updateNewCollectionState();
    } else {
      if (
        selectedCollectionState === "failed" ||
        selectedCollectionState === "canceled"
      ) {
        if (collectionDetails.items.length !== 0) {
          setIsLoading(false);
          dispatch(
            setCollectionEditStateModalState({
              id: "editCollectionState",
              value: false,
            })
          );
          dispatch(
            setCollectionEditStateModalState({
              id: "collectionWithMaterials",
              value: true,
            })
          );
        } else {
          if (selectedCollectionState === "failed") {
            setIsLoading(false);
            dispatch(
              setCollectionEditStateModalState({
                id: "editCollectionState",
                value: false,
              })
            );
            dispatch(
              setCollectionEditStateModalState({
                id: "failedReasons",
                value: true,
              })
            );
          } else if (selectedCollectionState === "canceled") {
            updateNewCollectionState();
          }
        }
      } else if (selectedCollectionState === "finished") {
        if (collectionDetails.items.length !== 0) {
          // Permitir marcar como "terminada" si hay materiales
          if (
            hasItemWithIdOne &&
            collectionDetails.verificationStep === false
          ) {
            dispatch(setOpenSnackbar(true));
            dispatch(
              setMessage(
                t(
                  "collectionDetailsLayout.customEditCollectionStateModal.Debes verificar ACU en Retool"
                )
              )
            );
            dispatch(setType("failedSnackbar"));
            ucoVerifiedNegative = true;
          } else {
            updateNewCollectionState();
          }
        } else {
          dispatch(setOpenSnackbar(true));
          dispatch(
            setMessage(
              t(
                "collectionDetailsLayout.customEditCollectionStateModal.Recolección sin materiales"
              )
            )
          );
          dispatch(setType("failedSnackbar"));
        }
        setIsLoading(false);
      }
    }
  };

  const handleSetSelected = (selectedState: string) => {
    setSelectedCollectionState(selectedState);
  };

  return (
    <DetailsCollectionModalLayout handleClose={handleCloseClickModal}>
      <CustomText
        showBackArrowButton
        bigSize
        premediumWeight
        color="#1C1C1C"
        onClick={handleBackModal}
        style={{ marginBottom: PP(24) }}
      >
        {t(
          "collectionDetailsLayout.customEditCollectionStateModal.Editar estado"
        )}
      </CustomText>
      <Divider style={{ marginBottom: PP(24) }} />
      <Box
        sx={[
          {
            borderRadius: PP(8),
            minWidth: PP(432),
            height: PP(240),
            marginBottom: PP(24),
            marginTop: PP(8),
          },
        ]}
      >
        <Box>
          <InfiniteScroll
            height={PP(240)}
            dataLength={collectionsStatesArray.length}
            next={handleLoadMore}
            hasMore={collectionsStatesArray.length >= 10}
            loader={<CustomText color="#969696">cargando</CustomText>}
          >
            {collectionsStatesArray.map((state, key) => (
              <Box
                onClick={() =>
                  collectionDetails.state === "pending"
                    ? (state.state === "failed" ||
                        state.state === "canceled") &&
                      handleSetSelected(state.state)
                    : handleSetSelected(state.state)
                }
                key={key}
                sx={[
                  {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    padding: PP(16),
                    color: "white",
                    height: PP(40),
                    "&:hover": {
                      backgroundColor: "#ECF1FF",
                      cursor: "pointer",
                      borderRadius: PP(4),
                    },
                  },
                  selectedCollectionState !== "" &&
                    selectedCollectionState === state.state && {
                      backgroundColor: "#ECF1FF",
                      borderRadius: PP(4),
                    },
                ]}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box>
                    <CustomText
                      noSpacing
                      color={
                        selectedCollectionState !== "" &&
                        selectedCollectionState === state.state
                          ? "#4463B8"
                          : "#1C1C1C"
                      }
                    >
                      {t(`collectionDetailsLayout.states.${state.label}`)}
                    </CustomText>
                  </Box>
                  {selectedCollectionState !== "" &&
                    selectedCollectionState === state.state && (
                      <span>
                        <BlueCheckIcon />
                      </span>
                    )}
                </Box>
              </Box>
            ))}
          </InfiniteScroll>
        </Box>
      </Box>

      <CustomButton
        width={432}
        height={44}
        label={t(
          "collectionDetailsLayout.customEditCollectionStateModal.Aplicar"
        )}
        onClick={handleSaveNewState}
        disabled={isLoading}
      />
      <CustomButton
        noSpacing
        width={432}
        height={44}
        cancelButton
        onClick={handleBackModal}
      />
    </DetailsCollectionModalLayout>
  );
});
