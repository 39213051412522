import { createSlice } from "@reduxjs/toolkit";

import { fetchEditMaterialCollection } from "../../thunks/collections/editMaterialCollectionThunk";

const initialState = {
  selectedMaterialToEdit: {
    id: -1,
    price: -1,
    priceType: "",
    total: -1,
    materialId: -1,
    material: { name: "", measure: "", category: "", id: -1 },
    quantity: -1,
  },
  loading: false,
  status: -1,
  statusText: "",
  form: {
    quantity: 0,
  },
};

export const editMaterialCollectionSlice = createSlice({
  name: "editMaterialCollectionReducer",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = -1;
      state.statusText = "";
    },
    setFormEditMaterialState: (state, action) => {
      const { id, value } = action.payload;
      state.form = {
        ...state.form,
        [id]: value,
      };
    },
    setSelectedMaterialToEdit: (state, action) => {
      state.selectedMaterialToEdit = action.payload;
    },

    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Editar un material de la recolección
    builder.addCase(fetchEditMaterialCollection.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchEditMaterialCollection.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.statusText = action.payload.statusText;
      state.loading = false;
    });
    builder.addCase(fetchEditMaterialCollection.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const {
  resetStatus,
  reset,
  setFormEditMaterialState,
  setSelectedMaterialToEdit,
} = editMaterialCollectionSlice.actions;

export default editMaterialCollectionSlice.reducer;
