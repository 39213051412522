import { useState } from "react";

import { Box, Icon } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as XLSX from 'xlsx';  // Importar xlsx para procesar Excel y CSV
import ExcelIcon from '@mui/icons-material/InsertDriveFile'

import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import CustomText from "../custom/texts/CustomText";
import { ReactComponent as PaperClipIcon } from "../../assets/icons/modals/paperclip.svg";
import { useAppSelector } from "../../redux/store";
import { display } from "@mui/system";

interface TransactionSelectedAttachedFileProps {
  selectedFile: File | null;
  handleSelectFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function TransactionSelectedAttachedFile(
  props: TransactionSelectedAttachedFileProps
) {
  const { selectedFile, handleSelectFile } = props;
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();

  const { selectedDocument } = useAppSelector(
    (state) => state.transactionAttachNewFileModal.form
  );

  const [fileSrc, setFileSrc] = useState<string | null>(null);
  const [fileContent, setFileContent] = useState<string | null>(null);

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];

      const reader = new FileReader();
      reader.onload = (e) => {
        const dataUrl = e.target?.result;

        if (dataUrl) {
          // Verificar el tipo de archivo
          if (selectedFile.type === "application/pdf") {
            const blob = new Blob([dataUrl], { type: "application/pdf" });
            const blobUrl = URL.createObjectURL(blob);
            setFileSrc(blobUrl);
          } else if (
            selectedFile.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            selectedFile.type === "application/vnd.ms-excel" ||
            selectedFile.type === "text/csv"
          ) {
            const binaryStr = e.target?.result;
            const workbook = XLSX.read(binaryStr, { type: "binary" });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const csvData = XLSX.utils.sheet_to_csv(worksheet);
            setFileContent(csvData); // Guardar el contenido del archivo Excel o CSV
          } else if (typeof dataUrl === 'string') {
            setFileSrc(dataUrl); // Usamos el dataUrl solo si es un string
          }
        }
      };

      if (
        selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        selectedFile.type === "application/vnd.ms-excel" ||
        selectedFile.type === "text/csv"
      ) {
        reader.readAsBinaryString(selectedFile);
      } else if (selectedFile.type !== "application/pdf") {
        reader.readAsDataURL(selectedFile);
      } else {
        reader.readAsArrayBuffer(selectedFile);
      }

      handleSelectFile(e);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CustomText
        smallSize
        noSpacing
        color="#969696"
        premediumWeight
        style={{
          fontSize: PP(14),
          fontWeight: 700,
          marginTop: PP(16),
          marginBottom: PP(8),
        }}
      >
        {t("Documento")}
      </CustomText>

      {selectedFile === null ? (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: "100%",
            height: PP(112),
            backgroundColor: "#ffffff",
            border: `${PP(1)} dashed #969696`,
            borderRadius: PP(8),
            padding: PP(20),
            cursor: "pointer",
          }}
          onClick={(e) => document.getElementById("fileInput")?.click()}
        >
          <PaperClipIcon
            style={{ width: PP(24), height: PP(24), marginRight: PP(8) }}
          />
          <CustomText noSpacing mediumWeight color="#969696">
            {t(
              "transactionAttachedFiles.customAttachNewTransactionFileModal.Adjuntar documento"
            )}
          </CustomText>
          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            onChange={handleFileInputChange}
            accept=".pdf,.xlsx,.xls,.csv,image/*"
          />
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            backgroundColor: "#FAFAFA",
            border: `${PP(1)} solid #DEDEDE`,
            borderRadius: PP(8),
            position: "relative",
          }}
        >
          {selectedFile.type === "application/pdf" ? (
            <iframe
              title="PDF Viewer"
              width="100%"
              height={PP(264)}
              src={fileSrc || ""}
            ></iframe>
          ) : selectedFile.type.startsWith("image/") ? (
            <img
              id="selectedImage"
              alt="Imagen"
              src={fileSrc ? fileSrc : ""}
              style={{
                width: "100%",
                height: PP(264),
                objectFit: "cover",
                border: "none",
                cursor: "pointer",
                borderRadius: PP(8),
              }}
            />
          ) : (
            <Icon
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ExcelIcon
                style={{ fontSize: "5rem", color: "#969696" }}
              />
            </Icon>
          )}
          <Box
            borderRadius={`${PP(0)} ${PP(0)} ${PP(8)} ${PP(8)}`}
            border={`${PP(1)} solid #E8E8E8`}
            padding={` ${PP(8)} ${PP(12)}`}
            style={{ backgroundColor: "#FFF", display: "flex" }}
            flexDirection={"row"} flexWrap={"wrap"}
            width={"100%"}
          >
            <CustomText noSpacing color="#4D4D4D">
              {selectedDocument}
            </CustomText>
          </Box>
        </Box >
      )}
    </Box>
  );
}
