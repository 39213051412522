import { forwardRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

// import { useTranslation } from "react-i18next";
import { Box, Divider } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import CustomButton from "../../buttons/default/CustomButton";
import CustomText from "../../texts/CustomText";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import CustomSearchFilters from "../../headers/default/CustomSearchFilters";
import { ReactComponent as BlueCheckIcon } from "../../../../assets/icons/modals/blueCheck.svg";
import DefaultModalLayout from "../../../../layouts/collections/modals/DefaultModalLayout";
import { transactionFilesTypes } from "../../../../utils/constants/transactions/typesTransactionFiles";
import { setTransactionAttachedFilesModalState } from "../../../../redux/slices/transactions/transactionAttachedFilesModalStatesSlice";
import { setFormAttachNewTransactionFileState } from "../../../../redux/slices/transactions/transactionAttachNewFileModalSlice";
import { useTranslation } from "react-i18next";

export default forwardRef(function CustomTransactionSelectTypeFileModal() {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const { fileType } = useAppSelector(
    (state) => state.collectionAttachNewFile.form
  );

  const [selectedFileType, setSelectedFileType] = useState(fileType);

  const [loadMore, setLoadMore] = useState(false);

  const handleLoadMore = () => {
    setLoadMore(true);
  };

  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(
      setTransactionAttachedFilesModalState({
        id: "selectTypeFile",
        value: false,
      })
    );
  };

  const handleCloseClickModal = (event: React.MouseEvent<SVGSVGElement>) => {
    handleCloseModal();
  };

  const handleBackModal = () => {
    handleCloseModal();
  };

  const handleFileType = () => {
    if (selectedFileType.type !== "") {
      dispatch(
        setFormAttachNewTransactionFileState({
          id: "fileType",
          value: selectedFileType,
        })
      );
      dispatch(
        setTransactionAttachedFilesModalState({
          id: "selectTypeFile",
          value: false,
        })
      );
      dispatch(
        setTransactionAttachedFilesModalState({
          id: "attachFile",
          value: true,
        })
      );
    }
  };

  const handleSetSelected = (selected: { type: string; label: string }) => {
    setSelectedFileType(selected);
  };

  return (
    <DefaultModalLayout handleClose={handleCloseClickModal}>
      <CustomText
        showBackArrowButton
        bigSize
        premediumWeight
        color="#1C1C1C"
        onClick={handleBackModal}
        style={{ marginBottom: PP(24) }}
      >
        {t(
          "transactionAttachedFiles.CustomTransactionSelectTypeFileModal.Tipo de documento"
        )}
      </CustomText>
      <Divider style={{ marginBottom: PP(24) }} />
      <CustomSearchFilters
        about="onlySearch"
        placeholder={"Buscar"}
        width={432}
        height={40}
        bradius={PP(4)}
        widthsearchicon={16}
        heightsearchicon={16}
      />
      <Box
        sx={[
          {
            borderRadius: PP(8),
            minWidth: PP(432),
            height: PP(160),
            marginBottom: PP(24),
            marginTop: PP(8),
          },
        ]}
      >
        <Box>
          <InfiniteScroll
            height={PP(160)}
            dataLength={transactionFilesTypes.length}
            next={handleLoadMore}
            hasMore={transactionFilesTypes.length >= 20}
            loader={<CustomText color="#969696">cargando</CustomText>}
          >
            {transactionFilesTypes.map((fileType, key) =>
              fileType.type !== "remission" ? (
                <Box
                  onClick={() => handleSetSelected(fileType)}
                  key={key}
                  sx={[
                    {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      padding: PP(16),
                      color: "white",
                      height: PP(40),
                      "&:hover": {
                        backgroundColor: "#ECF1FF",
                        cursor: "pointer",
                        borderRadius: PP(4),
                      },
                    },
                    selectedFileType.type !== "" &&
                      selectedFileType.type === fileType.type && {
                        backgroundColor: "#ECF1FF",
                        borderRadius: PP(4),
                      },
                  ]}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Box>
                      <CustomText
                        noSpacing
                        color={
                          selectedFileType.type !== "" &&
                          selectedFileType.type === fileType.type
                            ? "#4463B8"
                            : "#1C1C1C"
                        }
                      >
                        {t(
                          `transactionAttachedFiles.filesTypes.${fileType.label}`
                        )}
                      </CustomText>
                    </Box>
                    {selectedFileType.type !== "" &&
                      selectedFileType.type === fileType.type && (
                        <span>
                          <BlueCheckIcon />
                        </span>
                      )}
                  </Box>
                </Box>
              ) : (
                <></>
              )
            )}
          </InfiniteScroll>
        </Box>
      </Box>

      <CustomButton
        width={432}
        height={44}
        label={t("Seleccionar")}
        onClick={handleFileType}
      />
      <CustomButton
        noSpacing
        width={432}
        height={44}
        cancelButton
        onClick={handleBackModal}
      />
    </DefaultModalLayout>
  );
});
