import React, { useState } from "react";
import "react-quill/dist/quill.bubble.css";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Link,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";

import BasicBusinessDataCard from "../../../../../components/old/users/old/business/BasicBusinessDataCard";
import CustomPricesCard from "../../../../../components/old/users/old/business/CustomPricesCard";
import AddressCard from "../../../../../components/old/users/old/business/AddressCard";
import CollectionsCard from "../../../../../components/old/users/old/business/CollectionsCard";
import WithdrawalsCard from "../../../../../components/old/users/old/business/WithdrawalsCard";
import BanksAccountsCard from "../../../../../components/old/users/old/business/BanksAccountsCard";
import CertificatesCard from "../../../../../components/old/users/old/business/CertificatesCard";
import RecurrentCollectionCard from "../../../../../components/old/users/old/business/RecurrentCollectionCard";
import UcoCertificatesCard from "../../../../../components/old/users/old/business/UcoCertificatesCard";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Grid = styled(MuiGrid)(spacing);

export default function Profile() {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Helmet title="Negocio" />
      <Typography variant="h3" gutterBottom display="inline">
        {t("Perfil")}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/users/businesses">
          {t("Negocios")}
        </Link>
        <Typography>{t("Perfil")}</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {loading && <LinearProgress my={2} />}

      <main>
        <Grid container spacing={6}>
          <Grid item xs={12} lg={4} xl={3}>
            <BasicBusinessDataCard /> {/* Información del Negocio */}
            <CustomPricesCard />{" "}
            {/* Manejo de precios Personalizados Perfil del Negocio */}
            <BanksAccountsCard />{" "}
            {/* Manejo de cuentas bancarias Perfil del Negocio */}
          </Grid>

          <Grid item xs={12} lg={8} xl={9}>
            <AddressCard /> {/* Manejo de direcciones Perfil del Negocio */}
            <CertificatesCard />{" "}
            {/* Manejo de certificados medioambientales del Negocio */}
            <UcoCertificatesCard />{" "}
            {/* Manejo de certificados de UCO del Negocio */}
            <CollectionsCard />
            {/* Manejo de recolecciones recurrentes Perfil del Negocio */}
            <RecurrentCollectionCard />
            <WithdrawalsCard /> {/* Manejo de retiros Perfil del Negocio */}
          </Grid>
        </Grid>
      </main>
    </React.Fragment>
  );
}
