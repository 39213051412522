import CustomText from "../../texts/CustomText";

interface HeaderTitleProps {
  title: string;
  style?: React.CSSProperties;
}

export default function CustomHeaderTitle(props: HeaderTitleProps) {
  const { title, style } = props;

  return <CustomText style={style}>{title}</CustomText>;
}
