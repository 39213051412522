import { createAsyncThunk } from "@reduxjs/toolkit";

import { postRedux } from "../../../utils/old/api";

export const fetchSearchAllCollections = createAsyncThunk(
  "searchCollectionList/fetchPost",
  async (payload: { query: string }, thunkAPI) => {
    try {
      const response = await postRedux("/collection/search", payload.query);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
