import { useCallback } from "react";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SelectChangeEvent } from "@mui/material";
import { useFormik } from "formik";

import usePixelPerfect from "../../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { validateYupSchema } from "../../../utils/forms/validationSchema";
import CustomInput from "../../custom/forms/default/CustomInput";
import { firstLetterCapitalizeFormat } from "../../../utils/formats/firstLetterCapitalizeFormat";
import CustomText from "../../custom/texts/CustomText";
import CustomButton from "../../custom/buttons/default/CustomButton";
import CustomAlert from "../../custom/alerts/CustomAlert";
import {
  setMessage,
  setOpenSnackbar,
  setType,
} from "../../../redux/slices/globalSnackbar/globalSnackbarSlice";
import { resetEditTransformationMaterial } from "../../../redux/slices/inventory/transformation/editMaterialTransformationSlice";
import { setTransformationMaterialsModalStates } from "../../../redux/slices/inventory/transformation/transformationMaterialsModalsStatesSlice";
import { setEditArrayTransformationsMaterials } from "../../../redux/slices/inventory/transformation/addMaterialTransformationSlice";

export default function EditMaterialTransformationForm() {
  const { id } = useParams();
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { selectedMaterialToEdit } = useAppSelector(
    (state) => state.editMaterialTransformation
  );

  const { materials } = useAppSelector(
    (state) => state.addMaterialTransformation
  );

  const resetForm = useCallback(async () => {
    dispatch(resetEditTransformationMaterial());
    dispatch(
      setTransformationMaterialsModalStates({
        id: "editQuantity",
        value: false,
      })
    );
  }, [dispatch]);

  const handleClickCloseModal = () => {
    resetForm();
  };

  const initialFormikValues = {
    materialId: selectedMaterialToEdit.materialId,
    quantityMaterial: selectedMaterialToEdit.quantityMaterial,
  };

  const validationSchema = validateYupSchema({});

  const handleSubmit = async (data: {
    materialId: number;
    quantityMaterial: number;
  }) => {
    const newQuantity = Number(data.quantityMaterial);
    // Encuentra el índice del material a actualizar
    const materialIndex = materials.findIndex(
      (material) =>
        material.materialId === data.materialId &&
        material.type === selectedMaterialToEdit?.type
    );

    if (materialIndex === -1) {
      // Si el material no está en el array, cerramos el modal y no hacemos nada
      handleClickCloseModal();
      return;
    }

    const currentMaterial = materials[materialIndex];

    // Verifica si la cantidad realmente cambió
    if (currentMaterial.quantityMaterial === newQuantity) {
      handleClickCloseModal();
      return; // No hace falta actualizar si no hay cambio
    }

    // Actualiza el array de materiales
    const updatedMaterials = materials.map(
      (material) =>
        material.materialId === data.materialId &&
        material.type === selectedMaterialToEdit?.type
          ? { ...material, quantityMaterial: newQuantity } // Actualiza el material con la nueva cantidad
          : material // Mantiene los materiales sin cambios
    );

    // Despacha el nuevo array de materiales
    dispatch(setEditArrayTransformationsMaterials(updatedMaterials));

    // Cierra el modal y muestra notificaciones
    handleClickCloseModal();
    dispatch(setType("sucessSnackbar"));
    dispatch(setMessage(t("snackbars.Material editado con éxito")));
    dispatch(setOpenSnackbar(true));
  };

  const formikEditMaterialTransformation = useFormik({
    initialValues: initialFormikValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: false,
  });

  const formikEditMaterialTransformationChange = useCallback(
    (
      event:
        | React.ChangeEvent<HTMLInputElement>
        | SelectChangeEvent<number | string>
    ) => {
      formikEditMaterialTransformation.setFieldValue(
        event.target.name,
        event.target.value
      );
    },
    [formikEditMaterialTransformation]
  );

  return (
    <form noValidate onSubmit={formikEditMaterialTransformation.handleSubmit}>
      <CustomInput
        id="category"
        label={t("Categoría")}
        placeholder="00"
        value={t(selectedMaterialToEdit.category)}
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        inputHeight={PP(40)}
        inputBorderRadius={PP(8)}
        backgroundColor="#F6F6F6"
        extraStyle={{ marginBottom: PP(16), marginTop: PP(24) }}
        disabled
      />
      <CustomInput
        id="materialId"
        label={t("Material")}
        placeholder="00"
        value={firstLetterCapitalizeFormat(selectedMaterialToEdit.name)}
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        inputHeight={PP(40)}
        inputBorderRadius={PP(8)}
        backgroundColor="#F6F6F6"
        extraStyle={{ marginBottom: PP(16) }}
        disabled
      />
      <CustomInput
        id="quantityMaterial"
        label={t("Cantidad")}
        placeholder="00"
        formik={formikEditMaterialTransformation}
        value={formikEditMaterialTransformation.values.quantityMaterial}
        onChange={formikEditMaterialTransformationChange}
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        extraStyle={{ marginBottom: PP(16) }}
        inputHeight={PP(40)}
        inputBorderRadius={PP(8)}
        endAdornmentValue={
          <CustomText noSpacing color="#969696">
            {selectedMaterialToEdit?.materialMeasure}
          </CustomText>
        }
      />

      {/* Alerta informativa Para ACU */}
      {selectedMaterialToEdit.materialId === 1 && (
        <CustomAlert alertText={t("Ingresar peso NETO del material")} />
      )}

      <CustomButton
        width={432}
        height={44}
        noSpacing
        style={{ padding: "0px", marginTop: PP(24) }}
        type="submit"
        label={t("Guardar")}
        formik={formikEditMaterialTransformation}
      />
      <CustomButton
        noSpacing
        width={432}
        height={44}
        cancelButton
        onClick={handleClickCloseModal}
        formik={formikEditMaterialTransformation}
      />
    </form>
  );
}
