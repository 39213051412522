import React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";

import {
  Grid,
  AppBar as MuiAppBar,
  // IconButton as MuiIconButton,
  Toolbar,
} from "@mui/material";
// import { Menu as MenuIcon } from "@mui/icons-material";

import NavbarUserDropdown from "./NavbarUserDropdown";
import NavbarLanguagesDropdown from "./NavbarLanguagesDropdown";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

// const IconButton = styled(MuiIconButton)`
//   svg {
//     width: 22px;
//     height: 22px;
//   }
// `;

const Navbar = ({ onDrawerToggle }) => {
  const { PP2 } = usePixelPerfect();
  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar style={{ marginRight: PP2(4) }}>
          <Grid
            container
            alignItems="center"
            direction="row"
            justifyContent="space-between"
          >
            {/* <Grid item sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid> */}
            {/* <Grid item>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <Input placeholder={t("Search")} />
              </Search>
            </Grid> */}
            <Grid item xs />
            <Grid item container height="40px" justifyContent="flex-end">
              {/* <NavbarMessagesDropdown />
              <NavbarNotificationsDropdown />*/}
              <NavbarUserDropdown />
              <NavbarLanguagesDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
