import { Box } from "@mui/material";

import { ReactComponent as InfoIcon } from "../../../assets/icons/alerts/infoIcon.svg";
import usePixelPerfect from "../../../hooks/old/usePixelPerfect";
import CustomText from "../texts/CustomText";

interface CustomAlertProps {
  alertText: string;
}

export default function CustomAlert(props: CustomAlertProps) {
  const { PP } = usePixelPerfect();

  return (
    <Box width="100%" display="flex" marginBottom={PP(16)}>
      <InfoIcon style={{ width: PP(16), height: PP(16), marginRight: PP(4) }} />
      <CustomText noSpacing superSmallSize color="#585757">
        {props.alertText}
      </CustomText>
    </Box>
  );
}
