import "dayjs/locale/es";
import "dayjs/locale/en";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import * as Yup from "yup";
import { useFormik } from "formik";

import { spacing } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { green, grey, orange, red, teal, yellow } from "@mui/material/colors";

import {
  Alert,
  Button,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import {
  Add as AddIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import {
  fetchBusinessAddressList,
  fetchBusinessCollections,
  fetchCreateCollection,
  setPageCollections,
} from "../../../../../redux/old/slices/businessSlicer";
import { fetchCities } from "../../../../../redux/old/slices/citiesSlicer";
import { collectionDateFormat } from "../../../../../utils/old/collectionDateFormat";
import {
  collectionHours,
  collectionHoursIntialTime,
} from "../../../../../utils/old/constants";
import { formatHour } from "../../../../../utils/old/collectionHoursFormat";

const Card = styled(MuiCard)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const Chip = styled(MuiChip)`
  ${spacing};
  background: ${(props) => props.pending && orange[700]};
  background: ${(props) => props.assigned && yellow[500]};
  background: ${(props) => props.collected && green[400]};
  background: ${(props) => props.finished && teal[400]};
  background: ${(props) => props.canceled && grey[400]};
  background: ${(props) => props.failed && red[400]};
  color: ${(props) => props.pending && props.theme.palette.common.white};
`;

export default function CollectionsCard() {
  const { id } = useParams();

  const [collectionModal, setCollectionModal] = useState(false);
  const [date, setDate] = useState(collectionDateFormat(new Date()));
  const [collectionHoursFinalTime, setCollectionHoursFinalTime] = useState([]);
  const [snackTxt, setSnackTxt] = useState("hola");
  const [showSnack, setShowSnack] = useState(false);
  const { t } = useTranslation();

  //ReduxTookit
  const loading = useSelector((state) => state.business.showLoading);
  const collections = useSelector((state) => state.business.collections);

  const totalRowsCollections = useSelector(
    (state) => state.business.totalRowsCollections
  );
  const pageCollections = useSelector(
    (state) => state.business.pageCollections
  );
  const totalAddressesList = useSelector(
    (state) => state.business.totalAddressesList
  );

  const language = localStorage.getItem("language");

  const dispatch = useDispatch();

  useEffect(() => {
    getBusiness();
    getCities();
  }, [collectionModal]);

  const getBusiness = async (cPage = 0) => {
    dispatch(fetchBusinessCollections({ cPage: cPage, id: id })); //ReduxTookit
    dispatch(fetchBusinessAddressList(id));
    dispatch(setPageCollections(cPage)); //ReduxToolkit reducer
  };

  const handleChangePageCollections = async (event, newPage) => {
    dispatch(fetchBusinessCollections({ cPage: newPage, id: id }));
    dispatch(setPageCollections(newPage));
  };

  const handleChangeRowsPerCollectionPage = async () => {
    dispatch(fetchBusinessCollections({ cPage: 0, id: id }));
  };

  const getCities = async () => {
    dispatch(fetchCities()); //ReduxTookit
  };

  const newFinalTimeArray = (initialTime) => {
    let indexOfInitialTime = -1;
    if (initialTime !== "18:00:00") {
      collectionHours.map((hour, index) =>
        hour.value === initialTime
          ? (indexOfInitialTime = index)
          : indexOfInitialTime
      );
      setCollectionHoursFinalTime(
        collectionHours.slice(indexOfInitialTime + 1)
      );
    } else {
      setCollectionHoursFinalTime([
        {
          value: "18:00:00",
          label: "6:00 pm",
        },
      ]);
    }
  };

  //Manejando creación de nuevas recolecciones:

  const initialValuesCollection = {
    address_id: "",
    observation: "",
    initialTime: "",
    finalTime: "",
  };

  function validationSchema() {
    return {
      address_id: Yup.number().required(
        t("createNewCollectionModal.validations.Dirección requerida")
      ),
      initialTime: Yup.string().required(
        t("createNewCollectionModal.validations.Hora inicial requerida")
      ),
      finalTime: Yup.string().required(
        t("createNewCollectionModal.validations.Hora final requerida")
      ),
    };
  }

  const formikCollection = useFormik({
    initialValues: initialValuesCollection,
    enableReinitialize: true,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) => {
      createCollection(data);
    },
  });

  const createCollection = async (data) => {
    data.date = collectionDateFormat(date);
    data.ownerId = id;
    data.ownerType = "business";
    data.admin = true;
    data.source = "management-web";
    console.log(data.date);
    dispatch(fetchCreateCollection(data));
    setCollectionModal(false);
    setSnackTxt(t("Recolección creada"));
    // console.log(data.date);
    setShowSnack(true);
    dispatch(fetchBusinessCollections({ cPage: 0, id: id }));
  };

  return (
    <React.Fragment>
      <Card mb={6}>
        <CardContent>
          <Grid justifyContent="space-between" container spacing={10}>
            <Grid item>
              <Typography variant="h6" gutterBottom>
                {t("Recolecciones Únicas")}
              </Typography>
            </Grid>
            <Grid item>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => setCollectionModal(true)}
                >
                  <AddIcon />
                  {t("createNewCollectionModal.Nueva")}
                </Button>
              </div>
            </Grid>
          </Grid>

          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("tables.tableHeadCells.Dirección")}</TableCell>
                  <TableCell>
                    {t("tables.tableHeadCells.Complemento")}
                  </TableCell>
                  <TableCell>{t("tables.tableHeadCells.Fecha")}</TableCell>
                  <TableCell>{t("tables.tableHeadCells.Estado")}</TableCell>
                  <TableCell>{t("tables.tableHeadCells.Acción")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {collections.map((row, index) => {
                  return (
                    <TableRow hover key={row.id}>
                      <TableCell>{row.addressDescription}</TableCell>
                      <TableCell>{row.addressName}</TableCell>
                      <TableCell>{row.date}</TableCell>
                      <TableCell>
                        {row.state === "pending" && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label={t("chips.states.Pendiente")}
                            pending={+true}
                          />
                        )}
                        {row.state === "assigned" && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label={t("chips.states.Asignada")}
                            assigned={+true}
                          />
                        )}
                        {row.state === "collected" && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label={t("chips.states.Recolectada")}
                            collected={+true}
                          />
                        )}
                        {row.state === "failed" && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label={t("chips.states.Fallida")}
                            failed={+true}
                          />
                        )}
                        {row.state === "finished" && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label={t("chips.states.Terminada")}
                            finished={+true}
                          />
                        )}
                        {row.state === "canceled" && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label={t("chips.states.Cancelada")}
                            canceled={+true}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="details"
                          component={NavLink}
                          to={`/collections/detail/${row.id}`}
                          size="large"
                        >
                          <RemoveRedEyeIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[20]}
              component="div"
              count={totalRowsCollections}
              rowsPerPage={20}
              page={pageCollections}
              onPageChange={handleChangePageCollections}
              onRowsPerPageChange={handleChangeRowsPerCollectionPage}
            />
          </TableWrapper>
          {collections.length === 0 && (
            <Box display="flex" justifyContent="center">
              <Typography style={{ fontSize: "16px", color: "gray" }}>
                {t("No tiene recolecciones")}
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>

      {/* --------------------------------Modal Crear Recolección---------------------------------------------- */}

      <Dialog
        open={collectionModal}
        onClose={() => setCollectionModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={formikCollection.handleSubmit}>
          <DialogTitle id="form-dialog-title">
            {t("createNewCollectionModal.Crear una recolección")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("createNewCollectionModal.Se le asignará al negocio actual")}
            </DialogContentText>
            <Grid container spacing={6}>
              <Grid item md={12}>
                <br />
                <Typography style={{ fontSize: "11px" }} color="grey">
                  {t("createNewCollectionModal.Dirección")}
                </Typography>
                <Select
                  name="address_id"
                  label={t("createNewCollectionModal.Dirección")}
                  fullWidth
                  variant="outlined"
                  value={formikCollection.values.address_id}
                  onChange={formikCollection.handleChange}
                >
                  {totalAddressesList.map((element, index) => (
                    <MenuItem
                      key={element.id}
                      value={element.id}
                    >{`${element.description} ${element.city.name}`}</MenuItem>
                  ))}
                </Select>
                {formikCollection.errors.address_id && (
                  <Alert mt={2} mb={3} severity="error">
                    {formikCollection.errors.address_id}
                  </Alert>
                )}
              </Grid>
              <Grid item md={12}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={language || undefined}
                >
                  <DatePicker
                    name="date"
                    label={t("createNewCollectionModal.Fecha")}
                    value={date}
                    onChange={(newValue) => {
                      setDate(
                        collectionDateFormat(
                          format(new Date(newValue), "yyyy-MM-dd")
                        )
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} style={{ width: "42.5em" }} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item md={6}>
                <Typography style={{ fontSize: "11px" }} color="grey">
                  {t("createNewCollectionModal.Hora de inicio")}
                </Typography>
                {formikCollection.values.initialTime === "" && (
                  <Select
                    name="initialTime"
                    label={t("createNewCollectionModal.Hora inicio")}
                    fullWidth
                    variant="outlined"
                    value={formikCollection.values.initialTime}
                    onChange={(e) => {
                      newFinalTimeArray(e.target.value);
                      formikCollection.setFieldValue(
                        "initialTime",
                        e.target.value
                      );
                    }}
                  >
                    {collectionHoursIntialTime.map((element, index) => (
                      <MenuItem key={index} value={element.value}>
                        {element.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                {formikCollection.values.initialTime !== "" && (
                  <Typography>
                    {formatHour(formikCollection.values.initialTime)}
                  </Typography>
                )}
                {formikCollection.errors.initialTime && (
                  <Alert mt={2} mb={3} severity="error">
                    {formikCollection.errors.initialTime}
                  </Alert>
                )}
              </Grid>
              <Grid item md={6}>
                <Typography style={{ fontSize: "11px" }} color="grey">
                  {t("createNewCollectionModal.Hora de fin")}
                </Typography>
                <Select
                  name="finalTime"
                  label={t("createNewCollectionModal.Hora fin")}
                  fullWidth
                  variant="outlined"
                  value={formikCollection.values.finalTime}
                  onChange={formikCollection.handleChange}
                >
                  {collectionHoursFinalTime.map((element, index) => (
                    <MenuItem key={index} value={element.value}>
                      {element.label}
                    </MenuItem>
                  ))}
                </Select>
                {formikCollection.errors.finalTime && (
                  <Alert mt={2} mb={3} severity="error">
                    {formikCollection.errors.finalTime}
                  </Alert>
                )}
              </Grid>
              <Grid item md={12}>
                <TextField
                  name="observation"
                  label={t("createNewCollectionModal.Observaciones")}
                  id="observation"
                  multiline={true}
                  maxRows={6}
                  variant="outlined"
                  my={2}
                  style={{ width: "42.5em" }}
                  value={formikCollection.values.observation}
                  onChange={formikCollection.handleChange}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCollectionModal(false)} color="primary">
              {t("Cancelar")}
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {t("Crear")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/*--------------- Snackbar de Confirmación --------------*/}

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showSnack}
        autoHideDuration={6000}
        onClose={() => {
          setShowSnack(false);
        }}
        message={snackTxt}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                setShowSnack(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
