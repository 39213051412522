import { createAsyncThunk } from "@reduxjs/toolkit";

import { getRedux } from "../../../utils/old/api";

export const fetchMaterialsList = createAsyncThunk(
  "materialsList/fetch",
  async (payload: { page: number; category?: string }, thunkAPI) => {
    const url: string = payload.category
      ? `/material?$skip=${payload.page * 100}&$limit=100&category=${
          payload.category
        }&$sort[name]=1`
      : `/material?$skip=${payload.page * 100}&$limit=100&$sort[name]=1`;

    try {
      const response = await getRedux(url);
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
