import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { collectionDetailsInfoModalStatesSliceType } from "../../../interfaces/redux/collectionDetailsInfoModalStatesSliceInterface";

type CollectionStatesModalKeys = any;

interface SetCollectionsModalStatePayload {
  id: CollectionStatesModalKeys;
  value: boolean;
}
const initialState: collectionDetailsInfoModalStatesSliceType = {
  editCollectionState: false,
  collectionWithMaterials: false,
  failedReasons: false,
  editAssignmentDetail: false,
  selectCollector: false,
};

export const collectionDetailsInfoModalStatesSlice = createSlice({
  name: "collectionEditStateModalsStateReducer",
  initialState,
  reducers: {
    setCollectionEditStateModalState: (
      state: any,
      action: PayloadAction<SetCollectionsModalStatePayload>
    ) => {
      // @ts-ignore
      state[action.payload.id] = action.payload.value;
    },
    reset: () => initialState,
  },
});

export const { setCollectionEditStateModalState, reset } =
  collectionDetailsInfoModalStatesSlice.actions;

export default collectionDetailsInfoModalStatesSlice.reducer;
