import { create } from "react-native-pixel-perfect";

export default function PPD(size: number) {
  const designResolutionDesktop = {
    width: 1920,
    height: 1080,
  }; //Acorde a diseño

  // const perfectSizeDesktop = create(designResolutionDesktop);

  return `${size}px`;
}
