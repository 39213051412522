// @ts-ignore
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { CacheProvider } from "@emotion/react";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PostHogProvider } from "posthog-js/react";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import { store } from "./redux/store";
import createEmotionCache from "./utils/old/createEmotionCache";

import { AuthProvider } from "./contexts/old/JWTContext";
import CustomSnackbar from "./components/custom/snackbars/CustomSnackbar";
import { Suspense } from "react";

const clientSideEmotionCache = createEmotionCache();

export default function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes);
  const { theme } = useTheme();

  const options = {
    host: "https://app.posthog.com",
    autocapture: false,
    capture_pageview: false,
    capture_pageleave: false,
  };

  return (
    <PostHogProvider
      apiKey={process.env.REACT_APP_POSTHOG_API_KEY}
      options={options}
    >
      <Suspense fallback={<div>Loading...</div>}>
        <CacheProvider value={clientSideEmotionCache}>
          <HelmetProvider>
            <Helmet
              titleTemplate="%s | Muta"
              defaultTitle="Muta - Admin Dashboard"
            />
            <Provider store={store}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MuiThemeProvider theme={createTheme(theme)}>
                  <CustomSnackbar />
                  <AuthProvider>{content}</AuthProvider>
                </MuiThemeProvider>
              </LocalizationProvider>
            </Provider>
          </HelmetProvider>
        </CacheProvider>
      </Suspense>
    </PostHogProvider>
  );
}
