import { tableHeadCellType } from "../../../interfaces/data/tableHeadCellInterface";

export const transactionsListTableHeadCells: tableHeadCellType[] = [
  {
    id: "buyer",
    alignment: "left",
    label: "Cliente",
    disablePadding: false,
  },
  {
    id: "extra.date",
    alignment: "left",
    label: "Fecha",
    disablePadding: false,
  },
  {
    id: "contract",
    alignment: "left",
    label: "Consecutivo",
    disablePadding: false,
  },
  {
    id: "currency",
    alignment: "left",
    label: "Contrato",
    disablePadding: false,
  },
  {
    id: "state",
    alignment: "left",
    label: "Estado",
    disablePadding: false,
  },
];
