import { tableHeadCellType } from "../../../interfaces/data/tableHeadCellInterface";

export const transformationsMaterialsTableHeadCells: tableHeadCellType[] = [
  {
    id: "materialName",
    alignment: "left",
    label: "Material",
    disablePadding: false,
  },
  {
    id: "quantity",
    alignment: "left",
    label: "Cantidad",
    disablePadding: false,
  },
];
