import { useCallback, useEffect } from "react";

import { Box, Divider } from "@mui/material";
import { CSVLink } from "react-csv";
import { useParams } from "react-router-dom";

import { pointsOfOriginTableHeadCells } from "../../utils/constants/transactions/pointsOfOriginTableHeadCells";
import CustomPointsOfOriginTableRow from "../custom/tables/sales/CustomPointsOfOriginTableRow";
import CustomText from "../custom/texts/CustomText";
import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import CustomButton from "../custom/buttons/default/CustomButton";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { fetchPOOList } from "../../redux/thunks/transactions/POOListThunk";
import { POOType } from "../../interfaces/data/POOInterface";
import CustomCompleteTableNoPagination from "../custom/tables/default/CustomCompleteTableNoPagination";
import { useTranslation } from "react-i18next";

export default function PointsOfOrigin() {
  const { PP } = usePixelPerfect();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { POOList } = useAppSelector((state) => state.POOList);

  const getPOO = useCallback(async () => {
    if (id !== undefined) await dispatch(fetchPOOList({ transactionId: id }));
  }, [POOList]);

  useEffect(() => {
    getPOO();
  }, [dispatch, id]);

  return (
    <Box marginTop={PP(48)} width="100%">
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        alignContent="center"
      >
        <CustomText
          noSpacing
          premediumWeight
          color="#1C1C1C"
          style={{ fontSize: PP(20) }}
        >
          {t("Puntos de origen")}
        </CustomText>
        <CustomButton
          width={150}
          height={44}
          noSpacing
          style={{ padding: "0px" }}
          label={
            <CSVLink
              data={POOList}
              headers={pointsOfOriginTableHeadCells.map((cell) => ({
                label: cell.label,
                key: cell.id,
              }))}
              filename={"points_of_origin.csv"}
              style={{ textDecoration: "none", color: "#ffffff" }}
            >
              {t("Descargar CSV")}
            </CSVLink>
          }
        />
      </Box>

      <Divider style={{ margin: `${PP(16)} 0px` }} />
      <CustomCompleteTableNoPagination
        status={"succeeded"}
        tableHeadCells={pointsOfOriginTableHeadCells} //Encabezados de la tabla
        children={POOList?.map((element: POOType, key) => {
          return (
            <CustomPointsOfOriginTableRow
              element={element}
              key={key}
              myKey={key}
            />
          );
        })}
      />
    </Box>
  );
}
