import { createSlice } from "@reduxjs/toolkit";

import { fetchTransactionDetails } from "../../thunks/transactions/transactionsDetailsThunk";
import { TransactionDetailSliceType } from "../../../interfaces/redux/transactionDetailsSliceInterface";

const initialState: TransactionDetailSliceType = {
  status: "idle",
  transactionDetails: {
    _id: "",
    state: "",
    incoterm: "",
    observation: "",
    currency: "",
    consecutive: "",
    contract: "",
    sellerType: "",
    sellerId: "",
    buyerType: "",
    buyerId: "",
    extra: { loadDate: "", totalPayment: 0 },
    files: [],
    materials: [],
    createdAt: "",
    updatedAt: "",
    __v: 0,
    creationLicense: "",
    warehouseId: "",
  },
};

export const transactionDetailsSlice = createSlice({
  name: "transactionDetails",
  initialState,
  reducers: {
    resetTransactionDetails: (state) => {
      state.transactionDetails = initialState.transactionDetails;
    },
  },
  extraReducers: (builder) => {
    //Get detalles de una transacción
    builder.addCase(fetchTransactionDetails.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchTransactionDetails.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.transactionDetails = action.payload;
    });
    builder.addCase(fetchTransactionDetails.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export const { resetTransactionDetails } = transactionDetailsSlice.actions;

export default transactionDetailsSlice.reducer;
