import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import styled from "@emotion/styled";
import { Box, Card, CardMedia, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import LoginLogo from "../../../../vendor/old/loginLogo.png";
import SignInComponent from "../../../../components/old/auth/old/SignIn";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function SignIn() {
  const navigate = useNavigate();
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();

  useEffect(() => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (accessToken) navigate("/collections");
  }, []);

  return (
    <React.Fragment>
      <Wrapper>
        <Helmet title="Sign In" />
        <Card
          sx={{
            display: "flex",
            justifyContent: "center",
            boxShadow: "none",
          }}
        >
          <CardMedia
            component="img"
            sx={{ width: PP(210) }}
            image={LoginLogo}
            alt="LoginLogo"
          />
        </Card>

        <Box sx={{ marginBottom: PP(16) }}>
          <Typography
            align="center"
            sx={{ marginTop: PP(16), fontSize: PP(20), fontWeight: 500 }}
            gutterBottom
          >
            {t("¡Bienvenido(a) Mutante!")}
          </Typography>
          <Typography
            align="center"
            sx={{ marginTop: PP(16), fontSize: PP(16), fontWeight: 500 }}
          >
            {t("Ingresa tu cuenta para continuar")}
          </Typography>
        </Box>
        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
