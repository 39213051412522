import { createAsyncThunk } from "@reduxjs/toolkit";

import { getRedux } from "../../../../utils/old/api";

export const fetchSelectedBusiness = createAsyncThunk(
  "selectedBusiness/fetchGet",
  async (payload: { id: string }, thunkAPI) => {
    try {
      const response = await getRedux(`/business/${payload.id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
