import moment from "moment-timezone";
import { useTranslation } from "react-i18next";

export const dateFormat = (data: Date | string, t: Function): string => {
  // Establecer la configuración regional de moment a español
  moment.locale("es");

  const formattedDate = moment(data); /* .tz("America/Bogota"); */

  // Obtener el nombre del mes en español
  const spanishMonth = formattedDate.format("MMMM").toLowerCase();

  // Traducir el nombre del mes al idioma actual
  const translatedMonth = t(`months.${spanishMonth}`);

  const monthAbbreviation = translatedMonth.slice(0, 3);

  // Restaurar la configuración regional original de moment
  moment.locale();

  return `${monthAbbreviation} ${formattedDate.format("D, YYYY")}`;
};

// Llamada desde un componente
// const { t } = useTranslation();
// const formattedDate = dateFormat(date, t);
