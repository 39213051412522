interface CollectionsStates {
  pending: string;
  finished: string;
  assigned: string;
  collected: string;
  canceled: string;
  failed: string;
  [key: string]: string; // firma de índice adicional que permite cualquier valor de cadena como índice válido
}

export const collectionsStates: CollectionsStates = {
  pending: "Pendiente",
  assigned: "Asignada",
  collected: "Recolectada",
  finished: "Terminada",
  canceled: "Cancelada",
  failed: "Fallida",
};

export const collectionsStatesArray: { state: string; label: string }[] = [
  { state: "pending", label: "Pendiente" },
  { state: "assigned", label: "Asignada" },
  { state: "collected", label: "Recolectada" },
  { state: "finished", label: "Terminada" },
  { state: "canceled", label: "Cancelada" },
  { state: "failed", label: "Fallida" },
];

type CollectionStates = Record<string, boolean>;

export const collectionsStatesArrayReset: CollectionStates[] = [
  { pending: false },
  { finished: false },
  { assigned: false },
  { collected: false },
  { canceled: false },
  { failed: false },
];
