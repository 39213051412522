import { Box } from "@mui/material";

import { ReactComponent as FilterIcon } from "../../../../../assets/icons/textfields/filter.svg";
import usePixelPerfect from "../../../../../hooks/old/usePixelPerfect";
import CustomText from "../../../texts/CustomText";
import { useAppDispatch } from "../../../../../redux/store";
import { setCollectionsFilterByModalState } from "../../../../../redux/slices/collections/collectionsFilterByModalStatesSlice";
import { useTranslation } from "react-i18next";

export default function CollectionsFilterByInput() {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleOpenModal = () => {
    dispatch(
      setCollectionsFilterByModalState({
        id: "openFilterByModal",
        value: true,
      })
    );
  };

  return (
    <Box onClick={handleOpenModal}>
      <Box
        sx={[
          {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: PP(8),
            padding: `${PP(8)} ${PP(12)}`,
            paddingRight: "0px",
            width: PP(122),
            height: PP(40),
            border: `${PP(1)} solid #E8E8E8`,
            cursor: "pointer",
          },
        ]}
      >
        <Box
          style={{ display: "flex", alignItems: "center" }}
          height={PP(40)}
          width={PP(231)}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: PP(8),
            }}
          >
            <FilterIcon style={{ height: PP(16), width: PP(16) }} />
          </Box>
          <Box width={PP(151)}>
            <CustomText smallSize noSpacing color="#4D4D4D">
              {t("customSearchFilters.customFilters.collectionsFilterByInput.Filtrar por")}
            </CustomText>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
