import { forwardRef, useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { Box, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import CustomButton from "../../buttons/default/CustomButton";
import CustomText from "../../texts/CustomText";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import CustomSearchFilters from "../../headers/default/CustomSearchFilters";
import {
  reset as resetMaterials,
  setSelected,
} from "../../../../redux/slices/collections/materialsListSlice";
import { fetchMaterialsList } from "../../../../redux/thunks/collections/materialsListThunk";
import { reset as resetAddMaterialCollection } from "../../../../redux/slices/collections/addMaterialCollectionSlice";
import {
  reset as resetCollectionMaterialsModalStates,
  setCollectionMaterialsModalState,
} from "../../../../redux/slices/collections/collectionsMaterialsModalsStates";
import { materialInfoType } from "../../../../interfaces/redux/materialInfoInterface";
import MaterialsModalLayout from "../../../../layouts/MaterialsModalLayout";
import { ReactComponent as BlueCheckIcon } from "../../../../assets/icons/modals/blueCheck.svg";

export default forwardRef(function CustomSelectMaterialCollectionModal() {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const { page, materialsList, totalMaterialsList, selected } = useAppSelector(
    (state) => state.materialsList
  );

  const selectedLanguage = localStorage.getItem("language");

  // console.log(materialsList);

  const { category } = useAppSelector(
    (state) => state.addMaterialCollection.form
  );

  const [selectedMaterial, setSelectedMaterial] = useState(selected);

  const [loadMore, setLoadMore] = useState(false);

  const handleLoadMore = () => {
    setLoadMore(true);
  };

  const dispatch = useAppDispatch();

  // useEffect(() => {
  //   dispatch(resetMaterials());
  //   setLoadMore(true);
  // }, [dispatch]);

  const getMaterialsList = async () => {
    if (totalMaterialsList > materialsList.length) {
      dispatch(fetchMaterialsList({ page: page, category: category }));
    }
  };

  useEffect(() => {
    getMaterialsList();
  }, [category, dispatch]);

  const handleCloseClickModal = (event: React.MouseEvent<SVGSVGElement>) => {
    dispatch(resetAddMaterialCollection());
    dispatch(resetMaterials());
    dispatch(resetCollectionMaterialsModalStates());
  };

  const handleBackModal = () => {
    dispatch(
      setCollectionMaterialsModalState({ id: "selectMaterial", value: false })
    );
    dispatch(
      setCollectionMaterialsModalState({ id: "addMaterial", value: true })
    );
  };

  const handleSaveMaterial = () => {
    dispatch(setSelected(selectedMaterial));
    dispatch(
      setCollectionMaterialsModalState({ id: "selectMaterial", value: false })
    );
    dispatch(
      setCollectionMaterialsModalState({ id: "addMaterial", value: true })
    );
  };

  const handleSetSelected = (selectedMaterial: materialInfoType) => {
    setSelectedMaterial(selectedMaterial);
  };

  return (
    <MaterialsModalLayout handleClose={handleCloseClickModal}>
      <CustomText
        showBackArrowButton
        bigSize
        premediumWeight
        color="#1C1C1C"
        onClick={handleBackModal}
        style={{ marginBottom: PP(24) }}
      >
        {t(
          "collectionInformation.customAddMaterialModal.AddMaterialCollectionForm.Seleccionar materiales"
        )}
      </CustomText>
      <Divider style={{ marginBottom: PP(24) }} />
      <CustomSearchFilters
        about="onlySearch"
        placeholder={"Buscar por nombre"}
        width={432}
        height={40}
        bradius={PP(4)}
        widthsearchicon={16}
        heightsearchicon={16}
      />
      <Box
        sx={[
          {
            borderRadius: PP(8),
            minWidth: PP(432),
            height: PP(160),
            marginBottom: PP(24),
            marginTop: PP(8),
          },
        ]}
      >
        <Box>
          <InfiniteScroll
            height={PP(160)}
            dataLength={materialsList.length}
            next={handleLoadMore}
            hasMore={totalMaterialsList < materialsList.length}
            loader={<CustomText color="#969696">cargando</CustomText>}
          >
            {materialsList.map((material: materialInfoType, key) => (
              <Box
                onClick={() => handleSetSelected(material)}
                key={key}
                sx={[
                  {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    padding: PP(16),
                    color: "white",
                    height: PP(40),
                    "&:hover": {
                      backgroundColor: "#ECF1FF",
                      cursor: "pointer",
                      borderRadius: PP(4),
                    },
                  },
                  selectedMaterial !== null &&
                    selectedMaterial.id === material.id && {
                      backgroundColor: "#ECF1FF",
                      borderRadius: PP(4),
                    },
                ]}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box>
                    <CustomText
                      noSpacing
                      color={
                        selectedMaterial !== null &&
                        selectedMaterial.id === material.id
                          ? "#4463B8"
                          : "#1C1C1C"
                      }
                    >
                      {selectedLanguage !== null &&
                      material.nameTranslations !== null &&
                      // @ts-ignore
                      material.nameTranslations.translations[selectedLanguage]
                      // @ts-ignore
                        ? material.nameTranslations.translations[
                            selectedLanguage
                          ]
                        : material.name}
                    </CustomText>
                  </Box>
                  {selectedMaterial !== null &&
                    selectedMaterial.id === material.id && (
                      <span>
                        <BlueCheckIcon />
                      </span>
                    )}
                </Box>
              </Box>
            ))}
          </InfiniteScroll>
        </Box>
      </Box>

      <CustomButton
        width={432}
        height={44}
        label={t(
          "collectionInformation.customAddMaterialModal.AddMaterialCollectionForm.Seleccionar"
        )}
        onClick={handleSaveMaterial}
      />
      <CustomButton
        noSpacing
        width={432}
        height={44}
        cancelButton
        onClick={handleBackModal}
      />
    </MaterialsModalLayout>
  );
});
