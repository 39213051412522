import customChip from "../../styles/chips/customChip";
import PPD from "../../utils/modals/ppd";

const { chipStyle, chip } = customChip;

export const customMuiChip = {
  styleOverrides: {
    root: {
      borderRadius: PPD(100),
      borderWidth: PPD(1),
      borderStyle: "solid",

      fontFamily: chipStyle.fontFamily,
      fontStyle: chipStyle.fontStyle,
      fontWeight: chipStyle.fontWeight,
      fontSize: chipStyle.fontSize,
    },
  },
  variants: [
    {
      props: { variant: "pending" },
      style: {
        borderColor: chip["bd-color"].pending,
        backgroundColor: chip["bg-color"].pending.default,
        color: chip["text-color"].pending.default,
      },
    },
    {
      props: { variant: "finished" },
      style: {
        borderColor: chip["bd-color"].finished,
        backgroundColor: chip["bg-color"].finished.default,
        color: chip["text-color"].finished.default,
      },
    },
    {
      props: { variant: "assigned" },
      style: {
        borderColor: chip["bd-color"].assigned,
        backgroundColor: chip["bg-color"].assigned.default,
        color: chip["text-color"].assigned.default,
      },
    },
    {
      props: { variant: "failed" },
      style: {
        borderColor: chip["bd-color"].failed,
        backgroundColor: chip["bg-color"].failed.default,
        color: chip["text-color"].failed.default,
      },
    },
    {
      props: { variant: "collected" },
      style: {
        borderColor: chip["bd-color"].collected,
        backgroundColor: chip["bg-color"].collected.default,
        color: chip["text-color"].collected.default,
      },
    },
    {
      props: { variant: "canceled" },
      style: {
        borderColor: chip["bd-color"].canceled,
        backgroundColor: chip["bg-color"].canceled.default,
        color: chip["text-color"].canceled.default,
      },
    },
    {
      props: { variant: "close" },
      style: {
        borderColor: chip["bd-color"].canceled,
        backgroundColor: chip["bg-color"].canceled.default,
        color: chip["text-color"].canceled.default,
      },
    },
    {
      props: { variant: "open" },
      style: {
        borderColor: chip["bd-color"].pending,
        backgroundColor: chip["bg-color"].pending.default,
        color: chip["text-color"].pending.default,
      },
    },
    {
      props: { variant: "transit" },
      style: {
        borderColor: chip["bd-color"].assigned,
        backgroundColor: chip["bg-color"].assigned.default,
        color: chip["text-color"].assigned.default,
      },
    },
    {
      props: { variant: "received" },
      style: {
        borderColor: chip["bd-color"].collected,
        backgroundColor: chip["bg-color"].collected.default,
        color: chip["text-color"].collected.default,
      },
    },
  ],
};
