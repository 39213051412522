import { createSlice } from "@reduxjs/toolkit";

import { fetchCreateTransfer } from "../../../thunks/inventory/transfer/createTransferThunk";

const initialState: { loading: boolean; status: number } = {
  loading: false,
  status: -1,
};

export const createTransferSlice = createSlice({
  name: "createTransactionReducer",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = -1;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Crear nueva transferencia de inventario
    builder.addCase(fetchCreateTransfer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCreateTransfer.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.loading = false;
      // console.log("bien");
    });
    builder.addCase(fetchCreateTransfer.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { resetStatus, reset } = createTransferSlice.actions;

export default createTransferSlice.reducer;
