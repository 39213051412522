import { useEffect } from "react";

import { Box, IconButton } from "@mui/material";

import { ReactComponent as ChevronDown } from "../../../../../assets/icons/textfields/chevron-down.svg";
import usePixelPerfect from "../../../../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import CustomText from "../../../texts/CustomText";
import { setCollectionsWarehouseFilterModalState } from "../../../../../redux/slices/collections/collectionWarehouseFilterModalStatesSlice";
import { firstLetterCapitalizeFormat } from "../../../../../utils/formats/firstLetterCapitalizeFormat";
import { useTranslation } from "react-i18next";

interface CustomCommercialPickerInputProps {
  label?: string;
  id?: string;
  required?: boolean;
  error?: string | undefined;
  disabled?: boolean;
}

export default function CustomWarehousePickerInput(
  props: CustomCommercialPickerInputProps
) {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();

  const { warehouseSelected, warehouseFilter } = useAppSelector(
    (state) => state.collectionsListWarehouseFilter
  );

  const dispatch = useAppDispatch();

  const handleOpenModal = () => {
    dispatch(
      setCollectionsWarehouseFilterModalState({
        id: "openWarehouseFilterModal",
        value: true,
      })
    );
  };

  useEffect(() => { }, [warehouseFilter]);

  return (
    <Box sx={{ marginRight: PP(8) }}>
      <Box
        sx={[
          {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: PP(8),
            padding: `${PP(4)} ${PP(16)}`,
            paddingRight: "0px",
            backgroundColor: props.disabled ? "#F6F6F6" : "#FFFFFF",
            width: PP(207),
            height: PP(40),
            border: props.error
              ? `${PP(1)} solid #E73B85`
              : `${PP(1)} solid #E8E8E8`,
          },
        ]}
      >
        <Box
          style={{ display: "flex", alignItems: "center" }}
          height={PP(40)}
          width={PP(207)}
        >
          {warehouseSelected !== null ? (
            <Box
              width={PP(151)}
              overflow="hidden"
              lineHeight={PP(24)}
              display="flex"
              flexDirection="row"
              height={PP(24)}
            >
              <CustomText smallSize noSpacing color="#557CE6">
                {firstLetterCapitalizeFormat(warehouseSelected?.name)}
              </CustomText>
            </Box>
          ) : (
            <Box width={PP(151)}>
              <CustomText smallSize noSpacing color="#4D4D4D">
                {t("customSearchFilters.customFilters.customWarehousePickerInput.Seleccionar bodega")}
              </CustomText>
            </Box>
          )}
        </Box>

        <IconButton
          onClick={handleOpenModal}
          sx={{
            textTransform: "none",
            borderRadius: PP(8),
            height: PP(32),
            width: PP(32),
            display: "flex",
            justifyContent: "flex-end",
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent",
            },
            "&:focus": {
              backgroundColor: "transparent",
            },
          }}
        >
          <ChevronDown style={{ height: PP(16), width: PP(16) }} />
        </IconButton>
      </Box>
      {props.error && (
        <CustomText superSmallSize noSpacing color="#E73B85">
          {props.error}
        </CustomText>
      )}
      {/* 
       SELECT
      <Select
        id="state"
        name="state"
        //value={formik.values.state}
      >
        <MenuItem
          value={"SELECCIONAR ESTADO"}
          style={{ justifyContent: "center" }}
          disabled
        >
          Seleccionar bodega
        </MenuItem>
        <MenuItem value={"pending"}>Pendiente</MenuItem>
        <MenuItem value={"assigned"}>Asignada</MenuItem>
        <MenuItem value={"collected"}>Recolectada</MenuItem>
        <MenuItem value={"finished"}>Terminada</MenuItem>
        <MenuItem value={"failed"}>Fallida</MenuItem>
        <MenuItem value={"canceled"}>Cancelada</MenuItem>
      </Select> */}
    </Box>
  );
}
