import { createAsyncThunk } from "@reduxjs/toolkit";

import { getRedux } from "../../../utils/old/api";

export const fetchBusinessCustomPricesList = createAsyncThunk(
  "businessCustomPricesList/fetchGet",
  async (payload: { id: string; userType: string }, thunkAPI) => {
    try {
      const response = await getRedux(
        `/material-price?${payload.userType}_id=${payload.id}&only_custom=true`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
