const customTableRow = {
  tableRow: {
    bold: {
      "bg-color": {
        collections: {
          default: "#FFFFFF",
          hover: "#E8E8E8",
        },
      },
      "text-color": {
        collections: {
          default: "#585757",
          hover: "#585757",
        },
      },
    },
  },
};

export default customTableRow;
