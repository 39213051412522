import { createAsyncThunk } from "@reduxjs/toolkit";

import { getRedux } from "../../../utils/old/api";

export const fetchWarehouseDetails = createAsyncThunk(
  "warehouseDetails/fetchGet",
  async (payload: { id: number }, thunkAPI) => {
    try {
      const response = await getRedux(`/warehouse/${payload.id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
