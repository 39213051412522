import * as Yup from "yup";

type SchemaType<T> = any;

type ValidationSchema<T> = any;

export const required = Yup.string().required("Field required");

export const date = Yup.string().required("Field required");

export const address = Yup.number()
  .min(0, "Field required")
  .required("Field required");

export const materialId = Yup.number()
  .min(0, "Field required")
  .required("Field required");

export const category = Yup.string().required("Field required");

export const quantity = Yup.number()
  .positive("Field required")
  .required("Field required");

export const observations = Yup.string().max(100, "Field required");

export const names = Yup.string()
  .max(100, "Field required")
  .required("Field required");

export const document_number = Yup.string()
  .max(100, "Field required")
  .required("Field required");

export const phone = Yup.string()
  .max(100, "Field required")
  .required("Field required");

export const email = Yup.string()
  .email("Field required")
  .required("Field required");

export const price = Yup.number()
  .min(0, "Field required")
  .required("Field required");

export const cityId = Yup.number().required("Field required");
export const warehouseId = Yup.number().required("Field required");

export const incotermYup = Yup.string();

export const currencyYup = Yup.string();

export const contractYup = Yup.number();

export const buyerIdYup = Yup.string().required("Field required");

export const consecutiveYup = Yup.number();

export const nameYup = Yup.string().required("Field required");

export const legalNameYup = Yup.string().required("Field required");

export const documentNumberYup = Yup.string().required("Field required");

export const addressYup = Yup.string().required("Field required");

export const contactNameYup = Yup.string().required("Field required");

export const contactEmailYup = Yup.string().email().required("Field required");

export const contactPhoneYup = Yup.string().required("Field required");

export const startDateYup = Yup.string().required("Field required");

export const originWarehouseYup = Yup.number().required("Field required");

export const destinationWarehouseYup = Yup.number().required("Field required");
export const warehouseIdYup = Yup.string().required("Field required");

export const freightValueYup = Yup.number().required("Field required");

export const validateYupSchema = <T>(validationSchema: any) =>
  Yup.object().shape(validationSchema);
