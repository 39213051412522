import { createSlice } from "@reduxjs/toolkit";

import { fetchPOOList } from "../../thunks/transactions/POOListThunk";
import { POOListSliceType } from "../../../interfaces/redux/POOListSliceInterface";

const initialState: POOListSliceType = {
  status: "idle",
  POOList: [],
};

export const POOListSlice = createSlice({
  name: "POOList",
  initialState,
  reducers: {
    resetPOOList: () => initialState,
  },
  extraReducers: (builder) => {
    //Get lista de POO
    builder.addCase(fetchPOOList.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchPOOList.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.POOList = action.payload;
    });
    builder.addCase(fetchPOOList.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export const { resetPOOList } = POOListSlice.actions;

export default POOListSlice.reducer;
