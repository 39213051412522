import { createSlice } from "@reduxjs/toolkit";
import { AddMaterialCollectionSliceType } from "../../../interfaces/redux/addMaterialCollectionSliceInterface";
import { fetchAddMaterialCollection } from "../../thunks/collections/addMaterialCollectionThunk";

const initialState: AddMaterialCollectionSliceType = {
  loading: false,
  status: -1,
  statusText: "",
  form: {
    category: "",
    materialId: -1,
    quantity: 0,
    price: 0,
    collectionId: -1,
  },
};

export const addMaterialCollectionSlice = createSlice({
  name: "addMaterialCollectionReducer",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = -1;
      state.statusText = "";
    },
    setFormAddMaterialState: (state, action) => {
      const { id, value } = action.payload;
      state.form = {
        ...state.form,
        [id]: value,
      };
    },

    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Añadir nuevo material a recolección
    builder.addCase(fetchAddMaterialCollection.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAddMaterialCollection.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.statusText = action.payload.statusText;
      state.loading = false;
    });
    builder.addCase(fetchAddMaterialCollection.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { resetStatus, reset, setFormAddMaterialState } =
  addMaterialCollectionSlice.actions;

export default addMaterialCollectionSlice.reducer;
