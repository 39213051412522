export const BankList = {
  1: "Banco De Bogotá",
  2: "Banco Popular",
  6: "Itaú CorpBanca Colombia S.A.",
  7: "Bancolombia S.A.",
  9: "Citibank Colombia",
  12: "GNB Sudameris S.A.",
  13: "BBVA Colombia",
  19: "Colpatria",
  23: "Banco De Occidente",
  32: "Banco Caja Social - BCSC S.A.",
  40: "Banco Agrario De Colombia S.A.",
  51: "Banco Davivienda S.A.",
  52: "Banco Av Villas",
  53: "Banco W S.A.",
  58: "Banco Credifinanciera S.A.C.F",
  59: "Bancamia",
  60: "Banco Pichincha S.A.",
  61: "Bancoomeva",
  62: "CMR Falabella S.A.",
  63: "Banco Finandina S.A.",
  66: "Banco Cooperativo Coopcentral",
  67: "Banco Compartir S.A",
  69: "Banco Serfinanza S.A",
  70: "Nequi",
  71: "Daviplata",
  72: "Banco Mundo Mujer",
};

export const BankListArray = [
  { id: 1, bank: "Banco De Bogotá" },
  { id: 2, bank: "Banco Popular" },
  { id: 6, bank: "Itaú CorpBanca Colombia S.A." },
  { id: 7, bank: "Bancolombia S.A." },
  { id: 9, bank: "Citibank Colombia" },
  { id: 12, bank: "GNB Sudameris S.A." },
  { id: 13, bank: "BBVA Colombia" },
  { id: 19, bank: "Colpatria" },
  { id: 23, bank: "Banco De Occidente" },
  { id: 32, bank: "Banco Caja Social - BCSC S.A." },
  { id: 40, bank: "Banco Agrario De Colombia S.A." },
  { id: 51, bank: "Banco Davivienda S.A." },
  { id: 52, bank: "Banco Av Villas" },
  { id: 53, bank: "Banco W S.A." },
  { id: 58, bank: "Banco Credifinanciera S.A.C.F" },
  { id: 59, bank: "Bancamia" },
  { id: 60, bank: "Banco Pichincha S.A." },
  { id: 61, bank: "Bancoomeva" },
  { id: 62, bank: "CMR Falabella S.A." },
  { id: 63, bank: "Banco Finandina S.A." },
  { id: 66, bank: "Banco Cooperativo Coopcentral" },
  { id: 67, bank: "Banco Compartir S.A" },
  { id: 69, bank: "Banco Serfinanza S.A" },
  { id: 70, bank: "Nequi" },
  { id: 71, bank: "Daviplata" },
  { id: 72, bank: "Banco Mundo Mujer" },
];
