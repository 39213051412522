import React from 'react';
import { useFormikContext } from 'formik';
import TextField from '@mui/material/TextField';

interface FormValues {
    [key: string]: any;
}

interface BasicInputProps {
    name: string;
    type?: string;
    label: string;
}

const BasicInput: React.FC<BasicInputProps> = ({ name, type = 'text', label }) => {
    const { values, errors, touched, handleChange } = useFormikContext<FormValues>();

    return (
        <TextField
            fullWidth
            id={name}
            name={name}
            label={label}
            type={type}
            value={values[name] || ''}
            onChange={handleChange}
            error={touched[name] && Boolean(errors[name])}
            helperText={touched[name] && errors[name] ? errors[name] : ''}
            variant="outlined"
        />
    );
};

export default BasicInput;
