import { createAsyncThunk } from "@reduxjs/toolkit";

import { getRedux } from "../../../utils/old/api";

export const fetchClientsList = createAsyncThunk(
  "clientsList/fetchGet",
  async (payload, thunkAPI) => {
    try {
      const response = await getRedux(`/client?transaction=true`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
