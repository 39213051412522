import { useCallback, useEffect } from "react";

import { Box, IconButton, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";

import { ReactComponent as ArrowLeft } from "../../../assets/icons/layouts/arrow-left.svg";
import { ReactComponent as CheckIcon } from "../../../assets/icons/layouts/check.svg";
import CustomText from "../../../components/custom/texts/CustomText";
import usePixelPerfect from "../../../hooks/old/usePixelPerfect";
import CustomSelect from "../../../components/custom/forms/default/CustomSelect";
import { validateYupSchema } from "../../../utils/forms/validationSchema";
import CustomInput from "../../../components/custom/forms/default/CustomInput";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { fetchOneTransformationInfo } from "../../../redux/thunks/inventory/transformations/oneTransformationInfo";
import { fetchWarehousesList } from "../../../redux/thunks/warehouse/warehousesListThunk";
import TransformationDetailsInputMaterial from "../../../components/inventory/transformations/TransformationDetailsInputMaterial";
import TransformationsDetailsOutputMaterial from "../../../components/inventory/transformations/TransformationsDetailsOutputMaterial";
import { reset } from "../../../redux/slices/inventory/transformation/transformationListSlice";
import { createCollectionDateFormat } from "../../../utils/formats/createCollectionDateFormat";

interface InitialFormikValues {
    date: string,
    warehouse: number,
}

export default function TransformationDetails() {
    const { PP } = usePixelPerfect();
    const { t } = useTranslation();
    const { id } = useParams();
    const dispatch = useAppDispatch();

    const { transformationInfo } = useAppSelector(
        (state) => state.oneTransformation
    );

    const { warehousesList } = useAppSelector(
        (state) => state.warehouseList
    );

    const getOneTransformationInfo = useCallback(() => {
        if (id) {
            dispatch(
                fetchOneTransformationInfo({ transformationId: id })
            );
        }

    }, [id, dispatch]);

    useEffect(() => {
        getOneTransformationInfo();
    }, [getOneTransformationInfo]);

    const getWarehousesList = useCallback(() => {
        dispatch(
            fetchWarehousesList({
                page: 0,
            })
        );
    }, []);

    useEffect(() => {
        getWarehousesList();
    }, [getWarehousesList]);

    const goBack = () => {
        window.history.back();
        dispatch(reset());
    };

    // Filtra los elementos por tipo
    const inputMaterials = Array.isArray(transformationInfo.transformMaterial)
        ? transformationInfo.transformMaterial.filter(item => item.type === "input")
        : [];

    const outputMaterials = Array.isArray(transformationInfo.transformMaterial)
        ? transformationInfo.transformMaterial.filter(item => item.type === "output")
        : [];


    const initialFormikValues: InitialFormikValues = {
        date: transformationInfo.date || '',
        warehouse: transformationInfo.warehouseId,
    };

    const handleSubmit = async (data: InitialFormikValues) => {
    };


    const validationSchema = validateYupSchema({
    });

    const formikEditTransformation = useFormik<InitialFormikValues>({
        initialValues: initialFormikValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
        validateOnChange: false,
    });


    const formikEditTransformationChange = useCallback(
        (
            event:
                | React.ChangeEvent<HTMLInputElement>
                | SelectChangeEvent<number | string>
        ) => {
            formikEditTransformation.setFieldValue(
                event.target.name,
                event.target.value
            );
        },
        [formikEditTransformation]
    );

    return (
        <>
            <form noValidate onSubmit={formikEditTransformation.handleSubmit}>
                <Box
                    sx={{
                        marginTop: PP(24),
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: PP(8),
                        }}
                    >
                        <IconButton
                            aria-label="comeBackToBusinessList"
                            onClick={goBack}
                            style={{ padding: "0px" }}
                        >
                            <ArrowLeft style={{ width: PP(22), height: PP(22) }} />
                        </IconButton>
                        <CustomText color="#969696">&nbsp; {t("Volver")}</CustomText>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            marginBottom: PP(14),
                        }}
                    >
                        <CustomText
                            premediumWeight
                            noSpacing
                            bigSize
                            color="#1C1C1C"
                            style={{
                                lineHeight: PP(32),
                                marginRight: PP(8.23),
                            }}
                        >
                            {t("Transformación ")}{id}
                        </CustomText>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginBottom: PP(24),
                        }}
                    >
                        <Box
                            style={{
                                height: PP(28),
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <CheckIcon
                                style={{ marginRight: PP(8), width: PP(22), height: PP(22) }}
                            />
                            <CustomText noSpacing superSmallSize color="#909090">
                                {t("Última actualización")}:{" "}
                                {createCollectionDateFormat(transformationInfo.updatedAt, t)}
                            </CustomText>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <CustomInput
                            id="date"
                            labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
                            label={t("Fecha")}
                            value={transformationInfo.date}
                            onChange={formikEditTransformationChange}
                            extraStyle={{ marginTop: PP(24) }}
                            disabled
                        />
                        <CustomSelect
                            id="warehouse"
                            value={transformationInfo.warehouseId}
                            onChange={formikEditTransformationChange}
                            label={t("Bodega")}
                            items={warehousesList.map((warehouse) => {
                                return { label: warehouse.name, value: warehouse.id };
                            })}
                            extraStyle={{ marginTop: PP(24), width: "30%" }}
                            disabled
                        />
                    </Box>
                    <TransformationDetailsInputMaterial transformMaterials={inputMaterials} />
                    <TransformationsDetailsOutputMaterial transformMaterials={outputMaterials} />

                </Box>
            </form>
        </>
    )
}
