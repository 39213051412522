import { createSlice } from "@reduxjs/toolkit";

import { fetchUpdateCollectionInfo } from "../../thunks/collections/updateCollectionInfoThunk";

const initialState = {
  status: -1,
  statusText: "",
  loading: false,
  selectedCollector: { id: -1, name: "", phone: -1 },
};

export const updateCollectionCollectorSlice = createSlice({
  name: "updateCollectionCollectorSliceReducer",
  initialState,
  reducers: {
    setCollectionCollector: (state, action) => {
      state.selectedCollector = action.payload;
    },

    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Actualizar estado de recolección
    builder.addCase(fetchUpdateCollectionInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUpdateCollectionInfo.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.statusText = action.payload.statusText;
      state.loading = false;
    });
    builder.addCase(fetchUpdateCollectionInfo.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { setCollectionCollector, reset } =
  updateCollectionCollectorSlice.actions;

export default updateCollectionCollectorSlice.reducer;
