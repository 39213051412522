import { useState } from "react";

import { Box, Chip, Divider, IconButton } from "@mui/material";

import { ReactComponent as ArrowLeft } from "../../assets/icons/layouts/arrow-left.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/layouts/edit.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/layouts/check.svg";
import { ReactComponent as HistoricalIcon } from "../../assets/icons/layouts/moreHorizontal.svg";
import { useAppSelector } from "../../redux/store";
import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import { collectionsStates } from "../../utils/constants/collections/collectionsStates";
import CustomText from "../../components/custom/texts/CustomText";
import useAppDispatch from "../../hooks/old/useAppDispatch";
import { reset as resetCollectionsList } from "../../redux/slices/collections/collectionListSlice";
import { createCollectionDateFormat } from "../../utils/formats/createCollectionDateFormat";
import { setCollectionEditStateModalState } from "../../redux/slices/collections/collectionDetailsInfoModalStatesSlice";
import CustomSeeHistoryCard from "../../components/collections/CustomSeeHistoryCard";
import { useTranslation } from "react-i18next";

interface CollectionDetailsLayoutProps {
  children?: React.ReactNode;
}
export default function CollectionDetailsLayout(
  props: CollectionDetailsLayoutProps
) {
  const { collectionDetails } = useAppSelector(
    (state) => state.collectionDetails
  );
  const { PP } = usePixelPerfect();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [showMoreInfo, setShowMoreInfo] = useState(false)

  const goBack = () => {
    window.history.back();
    dispatch(resetCollectionsList());
  };

  const openEditCollectionStateModal = () => {
    dispatch(
      setCollectionEditStateModalState({
        id: "editCollectionState",
        value: true,
      })
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: PP(8),
        }}
      >
        <IconButton
          aria-label="comeBackToBusinessList"
          onClick={goBack}
          style={{ padding: "0px" }}
        >
          <ArrowLeft style={{ width: PP(22), height: PP(22) }} />
        </IconButton>
        <CustomText color="#969696">&nbsp; {t("collectionDetailsLayout.Volver")}</CustomText>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: PP(14),
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <CustomText
            premediumWeight
            noSpacing
            bigSize
            color="#1C1C1C"
            style={{
              lineHeight: PP(32),
              marginRight: PP(8.23),
            }}
          >
            {t("collectionDetailsLayout.Recolección {consecutivo}", { consecutive: collectionDetails.consecutive })}
          </CustomText>
          <Box
            style={{
              borderLeft: `${PP(3)} solid #E8E8E8`,
              height: PP(28),
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingLeft: PP(16),
            }}
          >
            {" "}
            <Chip
              style={{
                borderRadius: PP(100),
                borderWidth: PP(1),
                width: "auto",
                height: "auto",
              }}
              // @ts-ignore
              variant={collectionDetails.state}
              label={
                <CustomText
                  noSpacing
                  mediumWeight
                  style={{ fontSize: PP(14), margin: `${PP(5)} 0px` }}
                >
                  {t(`collectionDetailsLayout.states.${collectionsStates[collectionDetails.state]}`)}
                </CustomText>
              }
            />
            {collectionDetails.state !== "finished" &&
              collectionDetails.state !== "failed" &&
              collectionDetails.state !== "canceled" && (
                <EditIcon
                  style={{
                    marginLeft: PP(17),
                    width: PP(22),
                    height: PP(22),
                    cursor: "pointer",
                  }}
                  onClick={openEditCollectionStateModal}
                />
              )}
          </Box>
        </Box>
        <Box style={{ position: "relative" }}>
          <Box onClick={() => setShowMoreInfo(prevState => !prevState)} style={{ cursor: "pointer" }}>
            <HistoricalIcon style={{ width: PP(24), height: PP(24) }} />
          </Box>
          {showMoreInfo && (
            <CustomSeeHistoryCard />
          )
          }

        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: PP(24),
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              height: PP(28),
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingRight: PP(24),
            }}
          >
            <CustomText noSpacing superSmallSize color="#909090">
              {t("collectionDetailsLayout.Solicitud creada {fecha}", { createdAt: createCollectionDateFormat(collectionDetails.createdAt, t) })}
              {" "}{t("collectionDetailsLayout.como")}{" "}
              <span style={{ color: "#7D7D7D", fontWeight: 500 }}>
                {collectionDetails.source === "automatic"
                  ? t("collectionDetailsLayout.Recurrente")
                  : t("collectionDetailsLayout.Única")}
              </span>
            </CustomText>
          </Box>
        </Box>

        <Box
          style={{
            height: PP(28),
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: PP(16),
          }}
        >
          <CheckIcon
            style={{ marginRight: PP(8), width: PP(22), height: PP(22) }}
          />
          <CustomText noSpacing superSmallSize color="#909090">
            {t("collectionDetailsLayout.Última actualización")}:{" "}
            {createCollectionDateFormat(collectionDetails.updatedAt, t)}
            {/* por{" "}
            <span style={{ color: "#212121", fontWeight: 500 }}>
              {" "}
              Pedro Jiménez
            </span> */}
          </CustomText>
        </Box>
      </Box>
      <Divider style={{ marginBottom: PP(24) }} />
      <>{props.children}</>
    </Box >
  );
}
