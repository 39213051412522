import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { SelectChangeEvent } from "@mui/material";

import usePixelPerfect from "../../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { fetchMaterialsList } from "../../../redux/thunks/collections/materialsListThunk";
import { reset as resetAddMaterial, setFormAddTransferMaterial } from "../../../redux/slices/inventory/transfer/addMaterialTransferSlice";
import { reset } from "../../../redux/slices/collections/materialsListSlice";
import { setTransferMaterialsModalState } from "../../../redux/slices/inventory/transfer/transferMaterialsModalsStatesSlice";
import CustomInput from "../../custom/forms/default/CustomInput";
import CustomAlert from "../../custom/alerts/CustomAlert";
import CustomButton from "../../custom/buttons/default/CustomButton";
import { materialId as materialIdYup, quantity as quantityYup, validateYupSchema } from "../../../utils/forms/validationSchema";
import { setMessage, setOpenSnackbar, setType } from "../../../redux/slices/globalSnackbar/globalSnackbarSlice";
import { fetchTransferDetails } from "../../../redux/thunks/inventory/transfer/transferDetailsThunk";
import { fetchAddMaterialTransfer } from "../../../redux/thunks/inventory/transfer/addMaterialTransferThunk";
import CustomMaterialPickerInput from "../../custom/forms/inventory/transfer/CustomMaterialPickerInput";


export default function AddMaterialTransferForm() {
    const { id } = useParams();
    const { PP } = usePixelPerfect();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { transferDetails } = useAppSelector(
        (state) => state.transferDetails
    );

    const { category, materialId, quantityOrigin } =
        useAppSelector((state) => state.addMaterialTransfer.form);

    const { page, selected, totalMaterialsList, materialsList } = useAppSelector(
        (state) => state.materialsList
    );

    const getMaterialsList = async () => {
        if (totalMaterialsList > materialsList.length) {
            await dispatch(fetchMaterialsList({ page: page, category: category }));
        }
    };

    useEffect(() => {
        getMaterialsList();
    }, [category, dispatch]);

    const resetForm = useCallback(async () => {
        dispatch(resetAddMaterial());
        dispatch(reset());
        dispatch(
            setTransferMaterialsModalState({ id: "addMaterial", value: false })
        );
    }, [dispatch]);

    const handleClickCloseModal = () => {
        resetForm();
    };

    const initialFormikValues = {
        materialId: materialId,
        quantityOrigin: quantityOrigin,
        transferId: id,
    };

    const validationSchema = validateYupSchema({
        materialId: materialIdYup,
        quantityOrigin: quantityYup,
    });

    const handleSubmit = async (data: {
        materialId: number,
        quantityOrigin: number,
        transferId: string | undefined,
    }) => {
        const payload = {
            materialId: Number(data.materialId),
            quantityOrigin: Number(data.quantityOrigin),
            transferId: Number(data.transferId),
        };

        let repeatProduct = false;

        transferDetails.materials.map((material) => {
            if (material.materialId === formikAddMaterialTransfer.values.materialId)
                repeatProduct = true;
        });

        if (repeatProduct) {
            dispatch(setOpenSnackbar(true));
            dispatch(setMessage(t("Se ha producido un error: material repetido")));
            dispatch(setType("failedSnackbar"));
            if (id !== undefined) dispatch(fetchTransferDetails({ id: id }));
        } else {
            dispatch(fetchAddMaterialTransfer(payload));
            if (id !== undefined) dispatch(fetchTransferDetails({ id: id }));
            dispatch(setMessage(t("Material añadido")));
            dispatch(setType("sucessSnackbar"));
            dispatch(setOpenSnackbar(true));
        }
        handleClickCloseModal();
    };

    const formikAddMaterialTransfer = useFormik({
        initialValues: initialFormikValues,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
        validateOnChange: false,
    });

    const formikAddMaterialTransferChange = useCallback(
        (
            event:
                | React.ChangeEvent<HTMLInputElement>
                | SelectChangeEvent<number | string>
        ) => {
            formikAddMaterialTransfer.setFieldValue(
                event.target.name,
                event.target.value
            );
            dispatch(
                setFormAddTransferMaterial({
                    id: event.target.name,
                    value: event.target.value,
                })
            );
        },
        [formikAddMaterialTransfer]
    );

    return (
        <form noValidate onSubmit={formikAddMaterialTransfer.handleSubmit}>
            <CustomInput
                id="category"
                label={t("Categoría")}
                placeholder="Categoría de material"
                value={t(category)}
                labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
                inputHeight={PP(40)}
                inputBorderRadius={PP(8)}
                backgroundColor="#F6F6F6"
                extraStyle={{ marginTop: PP(24) }}
                disabled
            />
            <CustomMaterialPickerInput
                width={432}
                label={t("Material")}
                required
                id="materialId"
                error={t(formikAddMaterialTransfer.errors.materialId)}
            />
            <CustomInput
                id="quantityOrigin"
                label={t("Cantidad de origen")}
                placeholder="00"
                formik={formikAddMaterialTransfer}
                value={formikAddMaterialTransfer.values.quantityOrigin}
                onChange={formikAddMaterialTransferChange}
                labelStyles={{
                    fontSize: PP(14),
                    fontWeight: 700,
                }}
                inputHeight={PP(40)}
                inputBorderRadius={PP(4)}
                endAdornmentValue={selected?.measure}
                extraStyle={{ marginBottom: PP(16) }}
                disabled={transferDetails.status === "pending" ? false : true}
            />
            {/* Alerta informativa Para UCO */}
            {formikAddMaterialTransfer.values.materialId === 1 && (
                <CustomAlert
                    alertText={t("Ingresar peso NETO del material")}
                />
            )}
            <CustomButton
                width={432}
                height={44}
                noSpacing
                style={{ padding: "0px", marginTop: PP(24) }}
                type="submit"
                label={t("Agregar")}
                formik={formikAddMaterialTransfer}
            />
            <CustomButton
                noSpacing
                width={432}
                height={44}
                cancelButton
                onClick={handleClickCloseModal}
                formik={formikAddMaterialTransfer}
            />
        </form>
    );
}