export const customMiuDivider = {
  styleOverrides: {
    root: {
      width: "100%",
      height: "0px",
      backgroundColor: "#E8E8E8",
      margin: "12px 0px",
    },
  },
};
