import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import usePixelPerfect from "../../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { setTransferAttachedFilesModalState } from "../../../redux/slices/inventory/transfer/transferAttachedFilesModalStatesSlice";
import { fetchTransferAttachNewFile } from "../../../redux/thunks/inventory/transfer/transferAttachNewFileModalThunk";
import { fetchTransferDetails } from "../../../redux/thunks/inventory/transfer/transferDetailsThunk";
import { setFormAttachNewTransferFileState } from "../../../redux/slices/inventory/transfer/transferAttachNewFileModalSlice";
import { setMessage, setOpenSnackbar, setType } from "../../../redux/slices/globalSnackbar/globalSnackbarSlice";
import CustomInput from "../../custom/forms/default/CustomInput";
import CustomButton from "../../custom/buttons/default/CustomButton";
import TransferSelectedAttachedFile from "./TransferSelectedAttachedFile";


export default function TransferAttachNewFileForm() {
    const { PP } = usePixelPerfect();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { transferDetails } = useAppSelector(
        (state) => state.transferDetails
    );

    const { fileType } = useAppSelector(
        (state) => state.transferAttachNewFile.form
    );

    const { status } = useAppSelector(
        (state) => state.transferAttachNewFile
    );

    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const resetForm = useCallback(async () => {
        dispatch(
            setTransferAttachedFilesModalState({
                id: "attachFile",
                value: false,
            })
        );
    }, [dispatch]);

    const handleClickCloseModal = () => {
        resetForm();
    };

    const initialFormikValues = {
        Imagen1: null, // Inicialmente, no hay archivo seleccionado
        ownerType: "transfer",
        ownerId: transferDetails.id,
        type: fileType.type,
        description: "",
    };

    const handleSubmit = async () => {
        if (selectedFile) {
            await dispatch(
                fetchTransferAttachNewFile({
                    data: selectedFile,
                    ownerId: transferDetails.id.toString(),
                    type: fileType.type,
                })
            );
            await dispatch(fetchTransferDetails({ id: transferDetails.id.toString() }));
            handleClickCloseModal();
        }
    };

    const formiktransferAttachNewFile = useFormik({
        initialValues: initialFormikValues,
        enableReinitialize: true,
        onSubmit: handleSubmit,
        validateOnChange: false,
    });

    // Actualiza selectedFile cuando el usuario selecciona un archivo
    const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const selectedFile = files[0];
            setSelectedFile(selectedFile);
            dispatch(
                setFormAttachNewTransferFileState({
                    id: "selectedDocument",
                    value: selectedFile.name,
                })
            );
        }
    };

    useEffect(() => {
        if (status === 201) {
            dispatch(setOpenSnackbar(true));
            dispatch(setMessage(t("snackbars.Cambios guardados exitosamente")));
            dispatch(setType("sucessSnackbar"));
        }
        if (status === 403) {
            dispatch(setOpenSnackbar(true));
            dispatch(
                setMessage(t("snackbars.No tienes permiso para adjuntar archivos"))
            );
            dispatch(setType("failedSnackbar"));
        }
    }, [status]);

    return (
        <form noValidate onSubmit={formiktransferAttachNewFile.handleSubmit}>
            <CustomInput
                id="type"
                label={t("Tipo de documento")}
                placeholder={t("Tipo de documento")}
                value={t(fileType.label)}
                labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
                inputHeight={PP(40)}
                inputBorderRadius={PP(8)}
                backgroundColor="#F6F6F6"
                extraStyle={{ marginTop: PP(24) }}
                disabled
            />

            <TransferSelectedAttachedFile
                selectedFile={selectedFile}
                handleSelectFile={(e) => handleSelectFile(e)}
            />

            <CustomButton
                width={432}
                height={44}
                noSpacing
                style={{ padding: "0px", marginTop: PP(24) }}
                type="submit"
                label={t("Guardar")}
                formik={formiktransferAttachNewFile}
                disabled={selectedFile ? false : true} // Habilita el botón si hay un archivo seleccionado
            />
            <CustomButton
                noSpacing
                width={432}
                height={44}
                cancelButton
                onClick={handleClickCloseModal}
                formik={formiktransferAttachNewFile}
            />
        </form>
    );
}
