import { createRef, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import { Button, Divider, Toolbar } from "@mui/material";
import { NavLink } from "react-router-dom";

import usePixelPerfect from "../../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import CustomHeaderTitle from "../../../components/custom/headers/default/CustomHeaderTitle";
import CustomCompleteTable from "../../../components/custom/tables/default/CustomCompleteTable";
import { transformationsListTableHeadCells } from "../../../utils/constants/inventory/transformationsListTableHeadCells";
import CustomTransformationListTableRow from "../../../components/custom/tables/inventary/transfer/transformations/CustomTransformationListTableRow";
import { TransformationType } from "../../../interfaces/data/transformationInterface";
import { fetchTransformationList } from "../../../redux/thunks/inventory/transformations/transformationListThunk";
import TransformationsFilters from "../../../components/inventory/transformations/TransformationsFilters";

export default function TransformationList() {
    const { PP } = usePixelPerfect();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const ref = createRef();

    const [filter, setFilter] = useState<string>("");

    const { transformationList, status, totalRows } = useAppSelector(
        (state) => state.transformationList
    );
    const [page, setPage] = useState<number>(0);

    const handleChangePage = async (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (event !== null) {
            setPage(newPage);
        }
    };

    const getTransformations = async () => {
        await dispatch(fetchTransformationList({ page: page, filter: filter }));
    };

    useEffect(() => {
        getTransformations();
    }, [page, filter, dispatch]);

    return (
        <>
            <Toolbar
                style={{
                    padding: "0px",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <CustomHeaderTitle
                    title={t("Transformaciones")}
                    style={{ fontSize: PP(36), fontWeight: 500 }}
                />
                <TransformationsFilters setFilter={setFilter} setPage={setPage} />
                <Button
                    variant="contained"
                    color="primary"
                    component={NavLink}
                    to="/inventory/transformations/create"
                >
                    <ChangeCircleOutlinedIcon />
                    {t("Transformar material")}
                </Button>
            </Toolbar>
            <Divider />

            <CustomCompleteTable
                status={status}
                tableHeadCells={transformationsListTableHeadCells} //Encabezados de la tabla
                totalRows={totalRows} // total de filas de la tabla = total de datos respuesta backend
                rowsPerPage={50} //Filas por pagina de tabla
                page={page} //página actual
                handleChangePage={handleChangePage} // Manejando cambio de página para consumo Api
                setPage={setPage} //Cambio de página actual
                children={transformationList?.map((element: TransformationType, key) => {
                    return (
                        <CustomTransformationListTableRow
                            element={element}
                            key={key}
                            myKey={key}
                        />
                    );
                })}
            />
        </>
    );
}
