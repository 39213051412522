import { createAsyncThunk } from "@reduxjs/toolkit";

import { getRedux, patchRedux } from "../../../utils/old/api";

export const fetchWarehousesList = createAsyncThunk(
  "warehousesList/fetch",
  async (payload: { page: number; cityId?: number }, thunkAPI) => {
    const url: string = payload.cityId
      ? `/warehouse?$skip=${payload.page * 10}&$limit=10&cityId=${
          payload.cityId
        }`
      : `/warehouse?$skip=${payload.page * 10}&$limit=10`;

    try {
      const response = await getRedux(url);      
      
      return {
        data: response.data.hits,
        status: response.status,
        statusText: response.statusText,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchWarehouseQuantityList = createAsyncThunk(
  "inventoryQuantityList/fetchGet",
  async (payload: { warehouseId: number }, thunkAPI) => {
    try {
      const response = await getRedux(`/inventory-quantities${
        payload.warehouseId != 0 ? `?warehouseId=${payload.warehouseId}` : ``
      }`);      
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateWarehouseQuantity = createAsyncThunk(
  "inventoryQuantityList/update",
  async (payload: { id: number, data: {} }, thunkAPI) => {
    try {
      const response = await patchRedux(
        `/inventory-quantities/${payload.id}`,
        payload.data
      ); 
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
