import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRedux, patchRedux } from "../../../utils/old/api";

const initialState = {
  prices: [],
  showLoading: false,
};

export const fetchPricesList = createAsyncThunk("pricesList/show", () => {
  return getRedux(`material-price?admin=true`).then((response) => {
    return response.data;
  });
});

export const fetchUpdatePrice = createAsyncThunk(
  "pricesList/patch",
  ({ data, selectedPriceId }) => {
    return patchRedux(`/material-price/${selectedPriceId}`, data).then(
      (response) => {
        return response.data;
      }
    );
  }
);

export const pricesSlice = createSlice({
  name: "pricesReducer",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchPricesList.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchPricesList.fulfilled, (state, action) => {
      state.prices = action.payload;
      state.showLoading = false;
    });

    builder.addCase(fetchUpdatePrice.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchUpdatePrice.fulfilled, (state) => {
      state.showLoading = false;
    });
  },
});

export default pricesSlice.reducer;
