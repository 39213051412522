import { createSlice } from "@reduxjs/toolkit";

import { AddMaterialTransformationSliceType } from "../../../../interfaces/redux/addMaterialTransformationSliceInterface";
import { fetchAddMaterialTransformation } from "../../../thunks/inventory/transformations/addMaterialTransformationThunk";

const initialState: AddMaterialTransformationSliceType = {
  loading: false,
  status: -1,
  statusText: "",
  materials: [],
  form: {
    transformId: -1,
    materialId: -1,
    name: "",
    materialNameTranslations: "",
    materialMeasure: "",
    quantityMaterial: 0,
    type: "",
    category: "",
  },
};

export const addMaterialTransformationSlice = createSlice({
  name: "addMaterialTransformationReducer",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = -1;
      state.statusText = "";
    },
    setFormAddTransformationMaterial: (state, action) => {
      const { id, value } = action.payload;
      state.form = {
        ...state.form,
        [id]: value,
      };
    },
    setArrayTransformationsMaterials: (state, action) => {
      state.materials = state.materials.concat(action.payload);
    },
    setEditArrayTransformationsMaterials: (state, action) => {
      state.materials = action.payload;
    },

    reset: (state) => {
      const { type, ...formWithoutType } = state.form;
      return {
        ...initialState,
        form: {
          ...formWithoutType,
          type,
        },
        materials: state.materials, // Conservar el valor actual de materials
      };
    },

    resetAllTransformMaterials: () => initialState,
  },
  extraReducers: (builder) => {
    // Añadir nuevo material a transformación
    builder.addCase(fetchAddMaterialTransformation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchAddMaterialTransformation.fulfilled,
      (state, action) => {
        state.status = action.payload.status;
        state.statusText = action.payload.statusText;
        state.loading = false;
      }
    );
    builder.addCase(
      fetchAddMaterialTransformation.rejected,
      (state, action) => {
        state.loading = false;
      }
    );
  },
});

export const {
  resetStatus,
  reset,
  setFormAddTransformationMaterial,
  setArrayTransformationsMaterials,
  setEditArrayTransformationsMaterials,
  resetAllTransformMaterials,
} = addMaterialTransformationSlice.actions;

export default addMaterialTransformationSlice.reducer;
