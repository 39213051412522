import {
    IconButton,
    styled as styledMui,
    TableCell,
    TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { ReactComponent as TrashIcon } from "../../../../../assets/icons/buttons/trash.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/icons/layouts/edit.svg";
import { TransferMaterialsType } from "../../../../../interfaces/data/transferMaterialsInterface";
import { CustomTableRowType } from "../../../../../interfaces/styles/customTableRowInterface";
import usePixelPerfect from "../../../../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { setTransferMaterialsModalState } from "../../../../../redux/slices/inventory/transfer/transferMaterialsModalsStatesSlice";
import { setSelectedToDeleteTransferMaterialId, setSelectedToDeleteMaterialName } from "../../../../../redux/slices/inventory/transfer/deleteMaterialTransferSlice";
import { setSelectedMaterialToEdit } from "../../../../../redux/slices/inventory/transfer/editMaterialTransferSlice";

interface TransferTableRowProps {
    element: TransferMaterialsType;
    myKey: number;
}

const CustomTableRow = styledMui(TableRow)<CustomTableRowType>(({ theme }) => ({
    "&:hover": {
        backgroundColor: "#E8E8E8",
    },
}));

export default function CustomTransferMaterialsTableRow(
    props: TransferTableRowProps
) {
    const { PP } = usePixelPerfect();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { element, myKey } = props;
    const { transferDetails } = useAppSelector(
        (state) => state.transferDetails
    );

    const selectedLanguage = localStorage.getItem("language");

    const handleDeleteMaterial = () => {
        dispatch(setSelectedToDeleteTransferMaterialId(element.id));
        dispatch(setSelectedToDeleteMaterialName(element.material.name));
        dispatch(
            setTransferMaterialsModalState({
                id: "deleteMaterial",
                value: true,
            })
        );
    }

    const handleEditQuantityMaterial = () => {
        dispatch(setSelectedMaterialToEdit(element));
        dispatch(
            setTransferMaterialsModalState({
                id: "editQuantity",
                value: true,
            })
        );
    };
    return (
        <>
            <CustomTableRow key={myKey} variant="default">
                {element && (
                    <>
                        <TableCell sx={{ color: "#1C1C1C", cursor: "default" }}>
                            {element.material.nameTranslations !== null
                                ? //@ts-ignore
                                element.material.nameTranslations.translations[
                                selectedLanguage ? selectedLanguage : "es"
                                ]
                                : element.material.name}
                        </TableCell>
                        <TableCell sx={{ color: "#1C1C1C", cursor: "default" }}>
                            {element.material.name === "uco"
                                ? `${element.quantityOrigin.toFixed(2)} ${element.material.measure
                                } (Neto)`
                                : `${element.quantityOrigin.toFixed(2)} ${element.material.measure}`}
                        </TableCell>
                        <TableCell sx={{ color: "#1C1C1C", cursor: "default" }}>
                            {transferDetails.status !== "received" && transferDetails.status !== "finished"
                                ? t("No habilitado aún")
                                : element.material.name === "uco"
                                    ? `${element.quantityDestination?.toFixed(2)} ${element.material.measure} (Neto)`
                                    : `${element.quantityDestination?.toFixed(2)} ${element.material.measure}`}
                        </TableCell>

                        {transferDetails.status !== "finished" && transferDetails.status !== "transit" && (
                            <TableCell
                                sx={{ padding: PP(16), cursor: "default" }}
                                align="right"
                            >
                                <>
                                    <IconButton onClick={handleEditQuantityMaterial}>
                                        <EditIcon
                                            style={{
                                                width: PP(22),
                                                height: PP(22),
                                                cursor: "pointer",
                                            }}
                                        />
                                    </IconButton>
                                    {transferDetails.status === "pending" &&
                                        <IconButton onClick={handleDeleteMaterial}>
                                            <TrashIcon
                                                style={{
                                                    width: PP(22),
                                                    height: PP(22),
                                                    cursor: "pointer",
                                                }}
                                            />

                                        </IconButton>
                                    }
                                </>
                            </TableCell>
                        )}
                    </>
                )}
            </CustomTableRow>
        </>
    );
}
