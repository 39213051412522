import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { es } from "date-fns/locale";

import { useFormik } from "formik";
import {
  Box,
  Button,
  Card as MuiCard,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";

// import CollectionsRoutesMap from "../../../../../src/components/old/googlemaps/old/CollectionsRoutesMap";
import { fetchCollectorsList } from "../../../../redux/old/slices/collectionSlicer";
import PointsListSelectedCollector from "../../../../components/old/routes/old/PointsListSelectedCollector";
// import {
//   fetchPointsCollectionsList,
//   reset,
//   updateSelectedCollectorsId,
// } from "../../../../redux/old/slices/googleMapsSlicer";
// import { collectionDateFormat } from "../../../../utils/old/collectionDateFormat";
import {
  fetchCollectorsWithPoints,
  fetchPointsCollectionsListModal,
  setRoutesDate,
} from "../../../../redux/old/slices/googleMapsModalSlicer";
// import CurrentLocation from "../../../../components/old/googlemaps/old/socket.io/CurrentLocation";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import PageHeader from "../../../../components/old/generalOld/PageHeader";
import { dateFormat } from "../../../../utils/old/dateFormat";
import { routesDateFormat } from "../../../../utils/old/routesDateFormat";
import { format } from "date-fns";
// import CurrentLocation from "../../components/googlemaps/socket.io/CurrentLocation";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

export default function CollectionsRoutes() {
  const { collectorsList } = useSelector((state) => state.collection);
  const { routesDate, collectorsWithPoints, showLoading } = useSelector(
    (state) => state.googleMapsModal
  );

  const { PP } = usePixelPerfect();

  const dispatch = useDispatch();
  const [selectedCollectorModal, setSelectedCollectorModal] = useState([]);
  const [selectedCollectorRouteModal, setSelectedCollectorRouteModal] =
    useState(false);
  const [dateModal, setDateModal] = useState(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  });

  const [collectorsWithPointsView, setCollectorsWithPointsView] = useState([]);

  // Obteniendo lista de recolectores
  const getCollectorsList = async () => {
    await dispatch(fetchCollectorsList()); //ReduxToolkit Get
  };

  //Vista especifica de los puntos del recolector seleccionado
  const handleSetSelectedViewPoints = (selectedCollector) => {
    setSelectedCollectorModal(selectedCollector);
    dispatch(
      fetchPointsCollectionsListModal({
        collectorId: selectedCollector.id,
        routesDate: routesDate,
      })
    );
    setSelectedCollectorRouteModal(true);
  };

  //Controlando petición para verificación de puntos de los recolectores, al modificar filtro por fecha
  const handleCollectorsWithPoints = useCallback(
    async (date) => {
      await dispatch(fetchCollectorsWithPoints(date));
    },
    [dispatch]
  );

  //Manejando filtrado por fecha:
  const initialValuesCollectionRoutesModal = {
    date: routesDate,
  };

  const formikCollectionsRoutesModal = useFormik({
    initialValues: initialValuesCollectionRoutesModal,
    enableReinitialize: true,
    onSubmit: async (data) => {
      const newDate = format(dateModal, "yyyy-MM-dd");
      dispatch(setRoutesDate(newDate));
      await getCollectorsList();
      await handleCollectorsWithPoints(newDate);
    },
  });

  // Actualizar el useEffect para escuchar los cambios en collectorsWithPoints
  useEffect(() => {
    // Después de esperar a que se complete la operación, actualizar el estado
    const collectorsWithPointsnArray = collectorsList.filter((collector) =>
      collectorsWithPoints.some((route) => collector.id === route.collectorId)
    );
    setCollectorsWithPointsView(collectorsWithPointsnArray);
  }, [collectorsWithPoints, collectorsList, routesDate]);

  const handleChangeDatePicker = (newValue) => {
    const adjustedDate = new Date(newValue);
    adjustedDate.setHours(0, 0, 0, 0);
    setDateModal(adjustedDate);
  };

  return (
    <React.Fragment>
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "row",
        }}
      >
        <PageHeader title={"Puntos asignados"} />
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            alignSelf: "center",
            height: "100%",
          }}
        >
          <form onSubmit={formikCollectionsRoutesModal.handleSubmit}>
            <Spacer mb={4} />

            <LocalizationProvider
              adapterLocale={es}
              dateAdapter={AdapterDateFns}
            >
              <DatePicker
                name="date"
                label="Fecha"
                my={2}
                value={dateModal}
                onChange={handleChangeDatePicker}
                renderInput={(params) => (
                  <TextField {...params} style={{ width: "60%" }} />
                )}
              />
            </LocalizationProvider>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={showLoading}
              style={{ marginLeft: PP(20), width: "30%", height: "78%" }}
            >
              Filtrar
            </Button>
          </form>
        </Box>
      </Box>
      <Divider my={6} style={{ marginTop: PP(24), marginBottom: PP(24) }} />
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "90%",
        }}
      >
        <Card
          mb={6}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
          }}
        >
          <CardContent
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Box>
              <Typography style={{ fontWeight: 700, fontSize: PP(18) }}>
                {" "}
                Rutas de recolectores el día {dateFormat(routesDate)} (
                {routesDate})
              </Typography>
              <Typography>
                {" "}
                Al dar clic sobre el nombre de cada recolector podrás visualizar
                su lista de puntos de recolección designados, en la fecha
                especificada:
              </Typography>
            </Box>
            <Box style={{ height: "60vh", overflow: "auto" }}>
              <Spacer mb={4} />
              {showLoading && <Typography>Cargando...</Typography>}
              {collectorsWithPointsView.length !== 0 ? (
                <ul>
                  {collectorsWithPointsView.map((collector, key) => (
                    <Box key={key}>
                      <li style={{ color: "blue" }}>
                        <Button
                          color="info"
                          disabled={showLoading}
                          onClick={() => {
                            handleSetSelectedViewPoints(collector);
                          }}
                        >
                          {collector.name + " " + collector.last_name}
                        </Button>
                      </li>
                      <br />
                    </Box>
                  ))}
                </ul>
              ) : (
                <Typography style={{ fontStyle: "italic" }}>
                  MUTANTE, ACTUALMENTE NO HAY PUNTOS DESIGNADOS PARA NINGÚN
                  RECOLECTOR EN LA FECHA ESPECIFICADA
                </Typography>
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>

      {/* ---------------MODAL: Puntos designados para el recolector seleccionado----------- */}

      <Dialog
        open={selectedCollectorRouteModal}
        onClose={() => setSelectedCollectorRouteModal(false)}
        aria-labelledby="form-dialog-collectorRoute"
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-collectorRoute-title">
          Puntos designados para el recolector{" "}
          {selectedCollectorModal &&
            `${selectedCollectorModal.name} ${selectedCollectorModal.last_name}, el dia ${routesDate}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Los puntos se encuentran en el orden que debe seguir el recolector
            acorde a la ruta óptima calculada:
          </DialogContentText>
          <Spacer mb={4} />
          <PointsListSelectedCollector />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setSelectedCollectorRouteModal(false)}
            color="primary"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
