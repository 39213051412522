import { Fragment, useCallback, useEffect } from "react";

import { Box, IconButton } from "@mui/material";
import usePixelPerfect from "../../../../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { ReactComponent as ChevronRight } from "../../../../../assets/icons/modals/chevronRight.svg";
import CustomText from "../../../texts/CustomText";
import { setWarehouseSelected } from "../../../../../redux/slices/collections/collectionsListWarehouseFilterSlice";
import { setCollectionsWarehouseFilterModalState } from "../../../../../redux/slices/collections/collectionWarehouseFilterModalStatesSlice";
import { firstLetterCapitalizeFormat } from "../../../../../utils/formats/firstLetterCapitalizeFormat";
import { useTranslation } from "react-i18next";

interface CustomSelectWarehouseInputProps {
  label: string;
  id: string;
  required: boolean;
  error: string | undefined;
  width?: number;
}

export default function CustomSelectWarehouseInput(
  props: CustomSelectWarehouseInputProps
) {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();

  const { warehouseSelected } = useAppSelector(
    (state) => state.collectionsListWarehouseFilter
  );

  const dispatch = useAppDispatch();

  const changeWarehouseSelected = useCallback(() => {
    if (warehouseSelected !== null) {
      dispatch(setWarehouseSelected(warehouseSelected));
    }
  }, [warehouseSelected, dispatch]);

  useEffect(() => {
    changeWarehouseSelected();
  }, [changeWarehouseSelected]);

  const handleOpenModal = () => {
    dispatch(
      setCollectionsWarehouseFilterModalState({
        id: "openWarehouseFilterModal",
        value: false,
      })
    );
    dispatch(
      setCollectionsWarehouseFilterModalState({
        id: "selectWarehouse",
        value: true,
      })
    );
  };

  return (
    <Box sx={{ marginBottom: PP(24), width: PP(props.width) }}>
      <CustomText
        smallSize
        noSpacing
        color="#969696"
        mediumWeight
        style={{ marginBottom: PP(8), marginTop: PP(24), fontSize: PP(14) }}
      >
        {props.label}
      </CustomText>

      <Box
        sx={[
          {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: PP(4),
            padding: `${PP(8)} ${PP(12)}`,
            paddingRight: "0px",
            backgroundColor: "#FFFFFF",
            width: props.width ? PP(props.width) : PP(367),
            height: PP(40),
            border: props.error
              ? `${PP(1)} solid #E73B85`
              : `${PP(1)} solid #E8E8E8`,
          },
        ]}
      >
        <Box>
          {warehouseSelected !== null ? (
            <Box>
              <CustomText smallSize noSpacing color="#969696">
                {firstLetterCapitalizeFormat(warehouseSelected.name)}
              </CustomText>
            </Box>
          ) : (
            <Fragment>
              <CustomText smallSize noSpacing color="#C4C4C4">
                {t("collectionWarehouseFilterModal.SelectWarehouseFilterForm.customSelectWarehouseInput.Seleccionar una bodega")}
              </CustomText>
            </Fragment>
          )}
        </Box>

        <IconButton
          onClick={handleOpenModal}
          sx={{
            textTransform: "none",
            borderRadius: PP(8),
            height: PP(32),
            width: PP(32),
            display: "flex",
            justifyContent: "flex-end",
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent",
            },
            "&:focus": {
              backgroundColor: "transparent",
            },
          }}
        >
          <ChevronRight style={{ height: PP(16), width: PP(16) }} />
        </IconButton>
      </Box>
      {props.error && (
        <CustomText superSmallSize noSpacing color="#E73B85">
          {props.error}
        </CustomText>
      )}
    </Box>
  );
}
