import { forwardRef, Fragment, useCallback } from "react";

import { Divider } from "@mui/material";

import { useAppSelector } from "../../../../redux/store";
import CustomText from "../../texts/CustomText";
import useAppDispatch from "../../../../hooks/old/useAppDispatch";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import MaterialsModalLayout from "../../../../layouts/MaterialsModalLayout";
import { setCollectionMaterialsModalState } from "../../../../redux/slices/collections/collectionsMaterialsModalsStates";
import DeleteMaterialCollectionForm from "../../../collections/DeleteMaterialCollectionForm";
import { reset as resetDeleteMaterial } from "../../../../redux/slices/collections/deleteMaterialCollectionSlice";
import { useTranslation } from "react-i18next";

export default forwardRef(function CustomDeleteMaterialModal() {
  const { status } = useAppSelector((state) => state.newCollectionUser);
  const dispatch = useAppDispatch();
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();

  const resetForm = useCallback(async () => {
    dispatch(resetDeleteMaterial());
    dispatch(
      setCollectionMaterialsModalState({ id: "deleteMaterial", value: false })
    );
  }, [dispatch]);

  const handleClickCloseModal = (event: React.MouseEvent<SVGSVGElement>) => {
    resetForm();
  };

  return (
    <MaterialsModalLayout handleClose={handleClickCloseModal}>
      <CustomText
        noSpacing
        bigSize
        premediumWeight
        color="#1C1C1C"
        style={{ marginBottom: PP(24) }}
        onClick={resetForm}
      >
        {t("collectionInformation.customDeleteMaterialModal.Eliminar material")}
      </CustomText>
      <Divider sx={{ marginBottom: "24px" }} />
      <DeleteMaterialCollectionForm />
    </MaterialsModalLayout>
  );
});
