import { createAsyncThunk } from "@reduxjs/toolkit";

import { getRedux } from "../../../../utils/old/api";

export const fetchBusinessList = createAsyncThunk(
  "businessList/fetchGet",
  async (payload: { page: number; filter?: string }, thunkAPI) => {
    try {
      const response = await getRedux(
        `/business?$limit=20&$skip=${payload.page * 20}&${
          payload.filter ? payload.filter : ""
        }`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
