import {
  IconButton,
  styled as styledMui,
  TableCell,
  TableRow,
} from "@mui/material";
// import { useTranslation } from "react-i18next";

import { CustomTableRowType } from "../../../../../../interfaces/styles/customTableRowInterface";
import usePixelPerfect from "../../../../../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/store";
import { ReactComponent as TrashIcon } from "../../../../../../assets/icons/buttons/trash.svg";
import { ReactComponent as EditIcon } from "../../../../../../assets/icons/layouts/edit.svg";
import { setTransformationMaterialsModalStates } from "../../../../../../redux/slices/inventory/transformation/transformationMaterialsModalsStatesSlice";
import {
  setSelectedToDeleteMaterial,
  setSelectedToDeleteMaterialName,
  setSelectedToDeleteTransformationMaterialId,
} from "../../../../../../redux/slices/inventory/transformation/deleteMaterialTransformationSlice";
import { setSelectedMaterialToEdit } from "../../../../../../redux/slices/inventory/transformation/editMaterialTransformationSlice";
import { useMemo } from "react";

interface TransformationTableRowProps {
  element: {
    materialId: number;
    quantityMaterial: number;
    name: string;
    materialNameTranslations: string;
    materialMeasure: string;
    type: string;
  };
  myKey: number;
}

const CustomTableRow = styledMui(TableRow)<CustomTableRowType>(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#E8E8E8",
  },
}));

export default function CustomTransformationsMaterialsTableRow(
  props: TransformationTableRowProps
) {
  const { PP } = usePixelPerfect();
  // const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { element, myKey } = props;
  const selectedLanguage = localStorage.getItem("language");

  const { materials } = useAppSelector(
    (state) => state.addMaterialTransformation
  );

  const materialsInput = useMemo(
    () => materials.filter((item) => item.type === "input"),
    [materials]
  );
  const materialsOutput = useMemo(
    () => materials.filter((item) => item.type === "output"),
    [materials]
  );

  const handleDeleteMaterial = () => {
    dispatch(setSelectedToDeleteMaterial(element));
    dispatch(setSelectedToDeleteTransformationMaterialId(element.materialId));
    dispatch(setSelectedToDeleteMaterialName(element.name));
    dispatch(
      setTransformationMaterialsModalStates({
        id: "deleteMaterial",
        value: true,
      })
    );
  };

  const handleEditQuantityMaterial = () => {
    dispatch(setSelectedMaterialToEdit(element));
    dispatch(
      setTransformationMaterialsModalStates({
        id: "editQuantity",
        value: true,
      })
    );
  };

  const materialHaveActions = (material: typeof element) => {
    if (material.type === "input" && materialsOutput.length === 0) return true;

    if (material.type === "output" && materialsInput.length > 0) return true;

    return false;
  };

  return (
    <>
      <CustomTableRow key={myKey} variant="default">
        {element && (
          <>
            <TableCell sx={{ color: "#1C1C1C", cursor: "default" }}>
              {element.materialNameTranslations !== null
                ? //@ts-ignore
                  element.materialNameTranslations.translations[
                    selectedLanguage ? selectedLanguage : "es"
                  ]
                : element.name}
            </TableCell>
            <TableCell sx={{ color: "#1C1C1C", cursor: "default" }}>
              {element.name === "uco"
                ? `${element.quantityMaterial.toFixed(2)} ${
                    element.materialMeasure
                  } (Neto)`
                : `${element.quantityMaterial.toFixed(2)} ${
                    element.materialMeasure
                  }`}
            </TableCell>
            {materialHaveActions(element) && (
              <TableCell
                sx={{ padding: PP(16), cursor: "default" }}
                align="right"
              >
                <>
                  <IconButton onClick={handleEditQuantityMaterial}>
                    <EditIcon
                      style={{
                        width: PP(22),
                        height: PP(22),
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                  <IconButton onClick={handleDeleteMaterial}>
                    <TrashIcon
                      style={{
                        width: PP(22),
                        height: PP(22),
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                </>
              </TableCell>
            )}
          </>
        )}
      </CustomTableRow>
    </>
  );
}
