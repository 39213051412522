import {
  IconButton,
  styled as styledMui,
  TableCell,
  TableRow,
} from "@mui/material";
// import { useTranslation } from "react-i18next";

import { CustomTableRowType } from "../../../../interfaces/styles/customTableRowInterface";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/buttons/trash.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/layouts/edit.svg";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { TransactionMaterialType } from "../../../../interfaces/data/transactionMaterialInterface";
import { setTransactionMaterialsModalState } from "../../../../redux/slices/transactions/transactionMaterialsModalsStatesSlice";
import { setSelectedToDeleteMaterialId } from "../../../../redux/slices/transactions/deleteMaterialTransactionSlice";
import { setSelectedToDeleteMaterialName } from "../../../../redux/slices/transactions/deleteMaterialTransactionSlice";
import { setSelectedMaterialToEdit } from "../../../../redux/slices/transactions/editMaterialTransactionSlice";

interface TransactionTableRowProps {
  element: TransactionMaterialType;
  myKey: number;
}

const CustomTableRow = styledMui(TableRow)<CustomTableRowType>(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#E8E8E8",
  },
}));

export default function CustomTransactionMaterialsTableRow(
  props: TransactionTableRowProps
) {
  const { PP } = usePixelPerfect();
  // const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { element, myKey } = props;
  const { transactionDetails } = useAppSelector(
    (state) => state.transactionDetails
  );

  const selectedLanguage = localStorage.getItem("language");

  const handleDeleteMaterial = () => {
    dispatch(setSelectedToDeleteMaterialId(element.materialId));
    dispatch(setSelectedToDeleteMaterialName(element.material.name));
    dispatch(
      setTransactionMaterialsModalState({
        id: "deleteMaterial",
        value: true,
      })
    );
  };

  const handleEditQuantityMaterial = () => {
    dispatch(setSelectedMaterialToEdit(element));
    dispatch(
      setTransactionMaterialsModalState({
        id: "editQuantity",
        value: true,
      })
    );
  };
  return (
    <>
      <CustomTableRow key={myKey} variant="default">
        {element && (
          <>
            <TableCell sx={{ color: "#1C1C1C", cursor: "default" }}>
              {element.material.nameTranslations !== null
                ? //@ts-ignore
                  element.material.nameTranslations.translations[
                    selectedLanguage ? selectedLanguage : "es"
                  ]
                : element.material.name}
            </TableCell>
            <TableCell sx={{ color: "#1C1C1C", cursor: "default" }}>
              {element.material.name === "uco"
                ? `${element.quantity.toFixed(2)} ${
                    element.material.measure
                  } (Neto)`
                : `${element.quantity.toFixed(2)} ${element.material.measure}`}
            </TableCell>
            <TableCell sx={{ color: "#1C1C1C", cursor: "default" }}>
              {element.price.toLocaleString("es-MX")}
              {transactionDetails.currency} x {element.material.measure}
            </TableCell>
            <TableCell
              sx={{
                color: "#1C1C1C",
                ...{ width: PP(130) },
                cursor: "default",
              }}
            >
              {`${element.discount} ${transactionDetails.currency}`}
            </TableCell>
            <TableCell
              sx={{
                color: "#1C1C1C",
                ...{ width: PP(130) },
                cursor: "default",
              }}
            >
              {`${element.total?.toLocaleString("es-MX")} ${
                transactionDetails.currency
              }`}
            </TableCell>
            {transactionDetails.state === "open" && (
              <TableCell
                sx={{ padding: PP(16), cursor: "default" }}
                align="right"
              >
                <>
                  <IconButton onClick={handleEditQuantityMaterial}>
                    <EditIcon
                      style={{
                        width: PP(22),
                        height: PP(22),
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                  <IconButton onClick={handleDeleteMaterial}>
                    <TrashIcon
                      style={{
                        width: PP(22),
                        height: PP(22),
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                </>
              </TableCell>
            )}
          </>
        )}
      </CustomTableRow>
    </>
  );
}
