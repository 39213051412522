import { createSlice } from "@reduxjs/toolkit";

import { TransferDetailsSliceType } from "../../../../interfaces/redux/transferDetailsSliceInterface";
import { fetchTransferDetails } from "../../../thunks/inventory/transfer/transferDetailsThunk";

const initialState: TransferDetailsSliceType = {
  status: "idle",
  transferDetails: {
    id: 0,
    startDate: "",
    originWarehouse: 0,
    destinationWarehouse: 0,
    endDate: "",
    status: "",
    consecutive: "",
    freightValue: 0,
    observation: "",
    createdAt: "",
    updatedAt: "",
    materials: [],
    files: [],
  },
};

export const transferDetailsSlice = createSlice({
  name: "transferDetails",
  initialState,
  reducers: {
    reset: () => initialState,
    resetTransferDetails: (state) => {
      state.transferDetails = initialState.transferDetails;
    },
  },
  extraReducers: (builder) => {
    //Get detalles de una transferencia
    builder.addCase(fetchTransferDetails.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchTransferDetails.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.transferDetails = action.payload;
    });
    builder.addCase(fetchTransferDetails.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export const { reset, resetTransferDetails } = transferDetailsSlice.actions;

export default transferDetailsSlice.reducer;
