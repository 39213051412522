import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useFormik } from "formik";
import {
  Box,
  Button,
  Card as MuiCard,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";

import { get } from "../../../../../utils/old/api";
import {
  fetchCreatePersonNewMaterialPrice,
  fetchDeletePersonMaterial,
  fetchPersonAllPrices,
  fetchPersonPrices,
  setShowSnack,
} from "../../../../../redux/old/slices/individualsSlicer";
import CurrencyInput from "../../../customized/old/CurrencyInput";
import "../../../../../utils/old/styles/currencyInput.css";
import { useTranslation } from "react-i18next";

const Spacer = styled.div(spacing);

const Card = styled(MuiCard)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

export default function CustomPricesCard() {
  const { id } = useParams();
  const { t } = useTranslation();

  const [person, setPerson] = useState({});
  const [personPermissions, setPersonPermissions] = useState({});
  const [newMaterialPriceModal, setNewMaterialPriceModal] = useState(false);
  const [byeCustomPrice, setByeCustomPrice] = useState(false);
  const [loading, setLoading] = useState(false);

  //ReduxTookit
  //const loading = useSelector((state) => state.business.showLoading);
  const prices = useSelector((state) => state.person.prices);
  const allPrices = useSelector((state) => state.person.allPrices);
  const snackTxt = useSelector((state) => state.person.snackTxt);
  const showSnack = useSelector((state) => state.person.showSnack);

  const dispatch = useDispatch();

  useEffect(() => {
    getPerson();
  }, [newMaterialPriceModal, byeCustomPrice]);

  const getPerson = async () => {
    setLoading(true);
    get(
      `/user/${id}`,
      (response) => {
        setPerson(response.data);
        setPersonPermissions({
          collector_fog: response.data.permissions.includes("collector_fog"),
          collector_ssr: response.data.permissions.includes("collector_ssr"),
          commercial: response.data.permissions.includes("commercial"),
        });
        setLoading(false);
        dispatch(fetchPersonPrices(id)); //ReduxTookit //Precios personalizados
        dispatch(fetchPersonAllPrices()); //ReduxTookit //Todos los precios...Para tomar los materiales para el Select del form para personalizar precios
      },
      (error) => {
        alert("Error obteniendo usuario");
        setLoading(false);
      }
    );
  };

  //Manejando la edición de precios personalizados para materiales

  const initialValuesNewMaterialPrice = {
    price: "",
    materialId: "",
  };

  const formikNewMaterialPrice = useFormik({
    initialValues: initialValuesNewMaterialPrice,
    enableReinitialize: true,
    onSubmit: async (data) => {
      createNewMaterialPrice(data);
    },
  });

  const createNewMaterialPrice = async (data) => {
    data.user_id = id;
    data.countryId = 1;
    data.price =
      typeof data.price !== "number"
        ? Number(data.price.replace(/[$,]/g, ""))
        : data.price;
    dispatch(fetchCreatePersonNewMaterialPrice(data)); //ReduxTookit Post//Precios personalizados
    setNewMaterialPriceModal(false);
    dispatch(fetchPersonPrices(id)); //ReduxTookit Get//Precios personalizados
  };

  //----------------------------Destroy-------------------------------------------------------------------

  const deleteMaterial = async (material, index) => {
    //material.user_id = id;
    //material.countryId= 1;
    setByeCustomPrice(true);
    if (material.id) {
      dispatch(fetchDeletePersonMaterial(material)); //ReduxTookit Destroy
      dispatch(fetchPersonPrices(id)); //ReduxTookit Get//Precios personalizados
      //console.log(prices)
    } else {
    }
  };

  return (
    <React.Fragment>
      <Card mb={6}>
        <CardContent>
          <Grid justifyContent="space-between" container spacing={10}>
            <Grid item>
              <Typography variant="h6" gutterBottom>
                {t("Precios")}
              </Typography>
              <Typography variant="h6" gutterBottom>
                {t("Personalizados")}
              </Typography>
            </Grid>
            <Grid item>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => setNewMaterialPriceModal(true)}
                >
                  <AddIcon />
                  {t("Nuevo")}
                </Button>
              </div>
            </Grid>
          </Grid>
          <Spacer mb={4} />
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("tables.tableHeadCells.Material")}</TableCell>
                  <TableCell>{t("tables.tableHeadCells.Precio")}</TableCell>
                  <TableCell>{t("tables.tableHeadCells.Acción")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {prices.map((row, index) => {
                  return (
                    <TableRow hover key={row.id}>
                      <TableCell>{row.material.name}</TableCell>
                      <TableCell>
                        ${row.price.toLocaleString("es-MX")}
                      </TableCell>
                      <TableCell align="left">
                        <IconButton
                          aria-label="delete"
                          size="large"
                          onClick={(e) => deleteMaterial(row, index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableWrapper>
          {prices.length === 0 && (
            <Box display="flex" justifyContent="center">
              <Typography style={{ fontSize: "16px", color: "gray" }}>
                {t("No posee precios personalizados")}
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>

      {/* -----------------------MODAL PRECIO PERSONALIZADO--------------------- */}

      <Dialog
        open={newMaterialPriceModal}
        onClose={() => setNewMaterialPriceModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={formikNewMaterialPrice.handleSubmit}>
          <DialogTitle id="form-dialog-title">
            {t("createNewCustomPriceUserModal.Personalizar un precio")}
          </DialogTitle>
          <DialogContent>
            <Box style={{ color: "grey" }}>
              {t(
                "createNewCustomPriceUserModal.Este será el nuevo precio personalizado del"
              )}
            </Box>
            <Box style={{ color: "grey" }}>
              {t(
                "createNewCustomPriceUserModal.usuario para el material seleccionado"
              )}
            </Box>
            <br />
            <Grid item md={6} my={2}>
              <Typography style={{ fontSize: "11px" }} color="grey">
                {t("createNewCustomPriceUserModal.Material")}
              </Typography>
              <Select
                name="materialId"
                label={t("createNewCustomPriceUserModal.Material")}
                style={{ width: "20em" }}
                variant="outlined"
                my={2}
                value={formikNewMaterialPrice.values.materialId}
                onChange={formikNewMaterialPrice.handleChange}
              >
                {allPrices.map((element, index) => (
                  <MenuItem
                    key={element.material.id}
                    value={element.material.id}
                  >
                    {element.material.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <br />
            <Typography style={{ fontSize: "11px" }} color="grey">
              {t("createNewCustomPriceUserModal.Nuevo precio")}
            </Typography>
            <CurrencyInput
              name="price"
              label={t("createNewCustomPriceUserModal.Precio")}
              type="decimal"
              placeholder=" $0.00"
              variant="outlined"
              value={formikNewMaterialPrice.values.price}
              onChange={formikNewMaterialPrice.handleChange}
              className="currencyInputStyle"
              my={2}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setNewMaterialPriceModal(false)}
              color="primary"
            >
              {t("createNewCustomPriceUserModal.Cancelar")}
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {t("createNewCustomPriceUserModal.Actualizar")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* --------------------------------------------------------------- */}

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showSnack}
        autoHideDuration={6000}
        onClose={() => {
          dispatch(setShowSnack(false));
        }}
        message={t(snackTxt)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                dispatch(setShowSnack(false));
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
