import { createSlice } from "@reduxjs/toolkit";

import { fetchChangePassword } from "../../../thunks/settings/security/changePasswordThunk";

const initialState: {
  loading: boolean;
  statusChangePassword: number;
} = {
  loading: false,
  statusChangePassword: -1,
};

export const changePasswordSlice = createSlice({
  name: "changePasswordReducer",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Cambiando contraseña nueva del usuario
    builder.addCase(fetchChangePassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchChangePassword.fulfilled, (state, action) => {
      state.loading = false;
      state.statusChangePassword = action.payload.status;
    });
    builder.addCase(fetchChangePassword.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { reset } = changePasswordSlice.actions;

export default changePasswordSlice.reducer;
