import { createRef, useEffect, useState } from "react";

import { Add as AddIcon } from "@mui/icons-material";
import { Button, Divider, Modal, Toolbar } from "@mui/material";
import { useTranslation } from "react-i18next";

import usePixelPerfect from "../../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import CustomHeaderTitle from "../../../components/custom/headers/default/CustomHeaderTitle";
import CustomCompleteTable from "../../../components/custom/tables/default/CustomCompleteTable";
import CustomCreateTransferModal from "../../../components/custom/modals/inventory/transfer/CustomCreateTransferModal";
import { transferListTableHeadCells } from "../../../utils/constants/inventory/transferListTableHeadCells";
import CustomTransferListTableRow from "../../../components/custom/tables/inventary/transfer/CustomTransferListTableRow";
import { fetchTransferList } from "../../../redux/thunks/inventory/transfer/transferListThunk";
import { TransferType } from "../../../interfaces/data/transferInterface";
import TransfersFilters from "../../../components/inventory/transfers/TransfersFilters";

export default function TransferList() {
    const { PP } = usePixelPerfect();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const ref = createRef();

    const { transferList, status, totalRows } = useAppSelector(
        (state) => state.transferList
    );

    const [createTransferModal, setCreateTransferModal] = useState(false);
    const [page, setPage] = useState<number>(0);
    const [filter, setFilter] = useState<string>("");


    const handleChangePage = async (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (event !== null) {
            setPage(newPage);
        }
    };

    const getTransferList = async () => {
        await dispatch(fetchTransferList({ page: page, filter: filter }));
    };

    useEffect(() => {
        getTransferList();
    }, [page, filter, dispatch]);

    return (
        <>
            <Toolbar
                style={{
                    padding: "0px",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <CustomHeaderTitle
                    title={t("Transferencias")}
                    style={{ fontSize: PP(36), fontWeight: 500 }}
                />
                <TransfersFilters setFilter={setFilter} />

                <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => setCreateTransferModal(true)}
                >
                    <AddIcon />
                    {t("Crear transferencia")}
                </Button>
            </Toolbar>
            <Divider />

            <CustomCompleteTable
                status={status}
                tableHeadCells={transferListTableHeadCells} //Encabezados de la tabla
                totalRows={totalRows} // total de filas de la tabla = total de datos respuesta backend
                rowsPerPage={50} //Filas por pagina de tabla
                page={page} //página actual
                handleChangePage={handleChangePage} // Manejando cambio de página para consumo Api
                setPage={setPage} //Cambio de página actual
                children={transferList?.map((element: TransferType, key) => {
                    return (
                        <CustomTransferListTableRow
                            element={element}
                            key={key}
                            myKey={key}
                        />
                    );
                })}
            />
            <Modal
                open={createTransferModal}
                children={
                    <CustomCreateTransferModal
                        ref={ref}
                        setOpen={setCreateTransferModal}
                        getTransferList={getTransferList}
                    />
                }
            />
        </>
    );
}
