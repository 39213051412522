import { createSlice } from "@reduxjs/toolkit";

import { fetchEditUserCustomPrice } from "../../thunks/users/editUserCustomPriceThunk";

const initialState = {
  selectedUserCustomPriceToEdit: {
    id: -1, //identificador único de material con precio personalizado respecto al usuario específico que lo posee
    materialId: -1, //identificador del material específico de la lista de todos los materiales
    price: -1,
    material: {
      id: -1,
      name: "nombre de material",
      measure: "",
      category: "categoría de material",
      description: "",
    },
  },
  loading: false,
  status: -1,
  statusText: "",
  form: {
    price: -1,
  },
};

export const editUserCustomPriceSlice = createSlice({
  name: "editUserCustomPriceReducer",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = -1;
      state.statusText = "";
    },
    setFormEditUserCustomPriceState: (state, action) => {
      const { id, value } = action.payload;
      state.form = {
        ...state.form,
        [id]: value,
      };
    },
    setSelectedUserCustomPriceToEdit: (state, action) => {
      state.selectedUserCustomPriceToEdit = action.payload;
    },

    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Editar un precio personalizado de un material del usuario
    builder.addCase(fetchEditUserCustomPrice.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchEditUserCustomPrice.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.statusText = action.payload.statusText;
      state.loading = false;
    });
    builder.addCase(fetchEditUserCustomPrice.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const {
  resetStatus,
  reset,
  setFormEditUserCustomPriceState,
  setSelectedUserCustomPriceToEdit,
} = editUserCustomPriceSlice.actions;

export default editUserCustomPriceSlice.reducer;
