
import { useEffect } from "react";
import { useParams } from "react-router";

import PageHistoryLayout from "../../layouts/collections/PageHistoryLayout";
import CustomCollectionPageHistoryTableRow from "../../components/custom/tables/collections/CustomCollectionPageHistoryTableRow";
import { collectionPageHistoryTableHeadCells } from "../../utils/constants/collections/collectionPageHistoryTableHeadCells";
import CustomCompleteTableNoPagination from "../../components/custom/tables/default/CustomCompleteTableNoPagination";
import { fetchCollectionHistoryPage } from "../../redux/thunks/collections/collectionHistoryPageThunk";
import { useAppDispatch, useAppSelector } from "../../redux/store";


export default function CollectionPageHistory() {
    const { id } = useParams();
    const dispatch = useAppDispatch();

    const { history, statusText } = useAppSelector((state) => state.collectionHistoryPage);

    const getHistoryPage = () => {
        dispatch(fetchCollectionHistoryPage({ collectionId: Number(id) }));
    }

    useEffect(() => {
        getHistoryPage()
    }, []);


    return (
        <PageHistoryLayout>
            <CustomCompleteTableNoPagination
                status={statusText}
                tableHeadCells={collectionPageHistoryTableHeadCells} //Encabezados de la tabla
                children={history?.map((element, key) => {
                    return (
                        <CustomCollectionPageHistoryTableRow
                            element={element}
                            key={key}
                            myKey={key}
                        />
                    );
                })}
            />
        </PageHistoryLayout>
    );
}
