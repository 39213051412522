import { useEffect, useState } from "react";

import { CircularProgress, withStyles } from "@material-ui/core";
import { Box, Typography } from "@mui/material";
import { green, red, yellow } from "@mui/material/colors";

import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import PPD from "../../utils/PPD";

const styles = () => ({
  root: {
    position: "relative",
    display: "inline-block",
    width: PPD(190),
    height: PPD(190),
  },
  overlayCircle: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  labelContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  label: {
    fontWeight: "bold",
    fontSize: PPD(20),
  },
});

const CustomCircularProgress = ({
  title,
  quantity,
  percentage,
  classes,
  lastUpdateTime,
  total,
}) => {
  const { PP2 } = usePixelPerfect();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(0); // Reiniciar el progreso a 0 cada vez que se actualicen los datos o la fecha de actualización

    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        const step = Math.ceil((percentage - prevProgress) / 20);
        const newProgress = prevProgress + step;
        return newProgress > percentage ? percentage : newProgress;
      });
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, [percentage, lastUpdateTime]); // Actualizar el efecto cuando el porcentaje o la fecha de actualización cambien

  return (
    <Box style={{ marginTop: PP2(20) }}>
      <Box>
        <Typography style={{ fontWeight: 700, fontSize: PP2(18) }}>
          {title}
        </Typography>
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className={classes.root}>
          <div className={classes.labelContainer}>
            <span className={classes.label}>{quantity}</span>
          </div>
          <CircularProgress
            variant="determinate"
            value={100}
            size={PP2(180)}
            thickness={2}
            className={classes.overlayCircle}
            style={{ color: "rgba(0, 0, 0, 0.1)" }}
          />
          <CircularProgress
            variant="determinate"
            value={progress}
            size={PP2(180)}
            thickness={2}
            className={classes.overlayCircle}
            style={{
              color:
                title === "Asignadas"
                  ? yellow[500]
                  : title === "Recolectadas"
                  ? green[400]
                  : red[400],
              clip: "rect(0, 50%, 100%, 0)",
            }}
          />
        </div>
      </Box>
      <Box>
        <Typography style={{ fontWeight: 500, fontSize: PP2(16) }}>
          <span style={{ color: "#3f51b5", fontWeight: 700 }}>{quantity}</span>{" "}
          de todas las{" "}
          <span style={{ color: "#3f51b5", fontWeight: 700 }}>{total}</span>{" "}
          recolecciones por hacer el día de hoy se encuentran{" "}
          <span
            style={{
              color:
                title === "Asignadas"
                  ? yellow[500]
                  : title === "Recolectadas"
                  ? green[400]
                  : red[400],
              fontWeight: 700,
            }}
          >
            {title}
          </span>{" "}
          correspondiente a un
          <span style={{ color: "#3f51b5", fontWeight: 700 }}>
            {" "}
            {progress}%
          </span>{" "}
          respecto al total
        </Typography>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(CustomCircularProgress);
