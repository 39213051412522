import { createAsyncThunk } from "@reduxjs/toolkit";

import { postRedux } from "../../../utils/old/api";

export const fetchCreateClient = createAsyncThunk(
  "createClient/fetchPost",
  async (
    payload: {
      data: {
        name: string;
        legalName: string;
        documentNumber: string;
        address: string;
        contactName: string;
        contactEmail: string;
        contactPhone: number;
      };
    },
    thunkAPI
  ) => {
    // console.log(payload);
    try {
      const response = await postRedux("/client", payload.data);
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    } catch (error) {
      const errors = thunkAPI.rejectWithValue(error);
      // console.log(errors);
      return errors;
    }
  }
);
