import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { collectionsFilterByModalStatesSliceType } from "../../../interfaces/redux/collectionsFilterByModalStatesSliceInterface";

type CollectionsFilterByModalKeys = any;

interface SetCollectionsFilterByModalStatesPayload {
  id: CollectionsFilterByModalKeys;
  value: boolean;
}
const initialState: collectionsFilterByModalStatesSliceType = {
  openFilterByModal: false,
  selectCollectorFilterModal: false,
};

export const collectionsFilterByModalStatesSlice = createSlice({
  name: "collectionsFilterByModalStatesReducer",
  initialState,
  reducers: {
    setCollectionsFilterByModalState: (
      state: any,
      action: PayloadAction<SetCollectionsFilterByModalStatesPayload>
    ) => {
      // @ts-ignore
      state[action.payload.id] = action.payload.value;
    },
    reset: () => initialState,
  },
});

export const { setCollectionsFilterByModalState, reset } =
  collectionsFilterByModalStatesSlice.actions;

export default collectionsFilterByModalStatesSlice.reducer;
