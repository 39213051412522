import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRedux } from "../../../utils/old/api";

const initialState = {
  arrayPolylinesToDecode: [], //Array con todas las polilineas que se deben decodificar
  arrayDonePoints: [],
  arrayToDoPoints: [],
  selectedCollectorsId: [],
  arrayLocationMessage: [],
  showLoading: false,
  snackTxt: "",
  showSnack: false,
  error: "",
};

// Google Maps-Puntos
export const fetchPointsCollectionsList = createAsyncThunk(
  //Get
  "Points/show",
  ({ collectorId, routesDate }) => {
    return getRedux(
      `/collector-route?collectorId=${collectorId}&date=${routesDate}&&admin=true`
    ).then((response) => {
      return response.data;
    });
  }
);

export const googleMapsSlice = createSlice({
  name: "googleMapsReducer",
  initialState,
  reducers: {
    updateSelectedCollectorsId: (state, action) => {
      state.selectedCollectorsId = action.payload;
      // console.log(state.selectedCollectorsId);
    },

    updateArrayLocationMessage: (state, action) => {
      //  action.payload es igual a newMessage de socket.io en CurrentLocation
      let verifyExistUserId = state.arrayLocationMessage.find(
        (locationMessage) => locationMessage.userId === action.payload.userId
      );

      if (verifyExistUserId === undefined) {
        let updateCurrentarray = state.arrayLocationMessage;
        updateCurrentarray.push(action.payload);
        state.arrayLocationMessage = updateCurrentarray;
      } else {
        let arrayDeleteExistToUpdate = state.arrayLocationMessage.filter(
          (locationMessage) =>
            locationMessage.userId !== verifyExistUserId.userId
        );
        arrayDeleteExistToUpdate.push(action.payload);
        state.arrayLocationMessage = arrayDeleteExistToUpdate;
      }
    },

    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPointsCollectionsList.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchPointsCollectionsList.fulfilled, (state, action) => {
      state.arrayDonePoints.push(
        action.payload.data.length !== 0 && action.payload.data[0].done
          ? action.payload.data[0].done
          : []
      );

      state.arrayToDoPoints.push(
        action.payload.data.length !== 0 && action.payload.data[0].todo
          ? action.payload.data[0].todo
          : []
      );

      state.arrayPolylinesToDecode.push(
        action.payload.data.length !== 0 && action.payload.data[0].geometry
          ? action.payload.data[0].geometry
          : ""
      );

      state.showLoading = false;
    });
    builder.addCase(fetchPointsCollectionsList.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error obteniendo puntos de recolección");
    });
  },
});

export const { reset, updateSelectedCollectorsId, updateArrayLocationMessage } =
  googleMapsSlice.actions;

export default googleMapsSlice.reducer;
