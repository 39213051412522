import { createSlice } from "@reduxjs/toolkit";
import { fetchEditMaterialTransfer } from "../../../thunks/inventory/transfer/editMaterialTransferThunk";

const initialState = {
  selectedMaterialToEdit: {
    id: -1,
    materialId: -1,
    transferId: -1,
    quantityOrigin: -1,
    quantityDestination: -1,
    createdAt: "",
    updatedAt: "",
    material: {
      id: -1,
      name: "",
      measure: "",
      category: "",
      description: "",
      createdAt: "",
      updatedAt: "",
      nameTranslations: {
        translations: {
          es: "",
          en: "",
          it: "",
        },
      },
    },
  },
  loading: false,
  status: -1,
  statusText: "",
  form: {
    quantityDestination: 0,
    quantityOrigin: 0,
  },
};

export const editMaterialTransferSlice = createSlice({
  name: "editMaterialTransferReducer",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = -1;
      state.statusText = "";
    },
    setFormEditTransferMaterial: (state, action) => {
      const { id, value } = action.payload;
      state.form = {
        ...state.form,
        [id]: value,
      };
    },
    setSelectedMaterialToEdit: (state, action) => {
      state.selectedMaterialToEdit = action.payload;
    },

    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Editar un material de la transferencia
    builder.addCase(fetchEditMaterialTransfer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchEditMaterialTransfer.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.statusText = action.payload.statusText;
      state.loading = false;
    });
    builder.addCase(fetchEditMaterialTransfer.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const {
  resetStatus,
  reset,
  setFormEditTransferMaterial,
  setSelectedMaterialToEdit,
} = editMaterialTransferSlice.actions;

export default editMaterialTransferSlice.reducer;
