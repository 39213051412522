import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
} from "react";

import moment from "moment";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "dayjs/locale/en";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box, TextField, ThemeProvider } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { ReactComponent as DatePickerIcon } from "../../../../assets/icons/calendar/datePicker.svg";
import { ReactComponent as SwitchArrowDatePickerIcon } from "../../../../assets/icons/calendar/switchArrowDatePicker.svg";
import { ReactComponent as LeftArrowDatePickerIcon } from "../../../../assets/icons/calendar/leftArrowDatePicker.svg";
import { ReactComponent as RightArrowDatePickerIcon } from "../../../../assets/icons/calendar/rightArrowDatePicker.svg";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import CustomText from "../../texts/CustomText";
import theme from "../../../../theme/customThemesOwnComponents/DatePickerInput";
import { daysOfWeekSpanish } from "../../../../utils/constants/calendar/daysWeekCalendar";
import { useTranslation } from "react-i18next";
import { fetchTransactionDetails } from "../../../../redux/thunks/transactions/transactionsDetailsThunk";
import { useParams } from "react-router-dom";

interface EditPortBulkDateProps {
  width?: string | number;
  setLoadDate: Dispatch<SetStateAction<string>>;
  loadDate: string | Date;
}

const InputFormat = "DD[/]MM[/]YYYY";

const formatDate = (date: Date | string) => {
  return moment(date.toString()).format("YYYY-MM-DD");
};

// Establece el idioma español para dayjs
dayjs.locale("es");

// Función para deshabilitar domingos
const shouldDisableSunday = (date: Date | string) => {
  const dayjsDate = dayjs(date); // Convierte a dayjs
  return dayjsDate.day() === 0; // Devuelve true para los domingos, false para los demás días
};

export default function EditPortBulkDate(props: EditPortBulkDateProps) {
  const { PP2, PP } = usePixelPerfect();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const DateInputStyle = {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: PP2(8),
      "& fieldset": {
        borderColor: "transparent",
        border: "none",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
      },
    },
  };
  const { t } = useTranslation();

  const language = localStorage.getItem("language");

  const { transactionDetails } = useAppSelector(
    (state) => state.transactionDetails
  );

  const handleDateChange = (newValue: Date | null | string) => {
    if (newValue) {
      props.setLoadDate(formatDate(newValue));
    }
  };

  const getTransactionDetails = useCallback(async () => {
    if (id !== undefined) await dispatch(fetchTransactionDetails({ id: id }));
  }, [transactionDetails]);

  useEffect(() => {
    const fetchData = async () => {
      await getTransactionDetails();
      if (transactionDetails.extra?.loadDate) {
        props.setLoadDate(transactionDetails.extra.loadDate);
      }
    };

    fetchData();
  }, [transactionDetails.extra?.loadDate]);

  return (
    <Box
      sx={[
        {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "column",
          backgroundColor: "#FFFFFF",
          width: props.width ? props.width : PP2(428),
        },
      ]}
    >
      <Box width={props.width ? props.width : PP2(428)}>
        <Box
          width={props.width ? props.width : PP2(428)}
          marginBottom={PP(24)}
          marginTop={PP(24)}
        >
          <ThemeProvider theme={theme}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              dayOfWeekStartsOn={0}
              adapterLocale={language || undefined}
            >
              <Box width={props.width ? props.width : PP2(428)}>
                <CustomText
                  mediumSize
                  mediumWeight
                  noSpacing
                  color="#969696"
                  style={{ marginTop: PP2(24), marginBottom: PP2(8) }}
                >
                  {t("Fecha de carga")}
                </CustomText>

                <DesktopDatePicker
                  inputFormat={InputFormat}
                  disableMaskedInput
                  disabled={transactionDetails.state === "close"}
                  value={props.loadDate}
                  shouldDisableDate={shouldDisableSunday}
                  dayOfWeekFormatter={(day) => daysOfWeekSpanish[day]}
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <TextField
                      disabled
                      {...params}
                      sx={{
                        ...DateInputStyle,
                        "& input": {
                          width: props.width ? props.width : PP2(428),
                        },
                      }}
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                    />
                  )}
                  components={{
                    OpenPickerIcon: DatePickerIcon,
                    SwitchViewIcon: SwitchArrowDatePickerIcon,
                    LeftArrowIcon: LeftArrowDatePickerIcon,
                    RightArrowIcon: RightArrowDatePickerIcon,
                  }}
                  PopperProps={{
                    placement: "top-end",
                  }}
                />
              </Box>
            </LocalizationProvider>
          </ThemeProvider>
        </Box>
      </Box>
    </Box>
  );
}
