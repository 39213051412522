import { createSlice } from "@reduxjs/toolkit";

import { fetchUpdateCollectionInfo } from "../../thunks/collections/updateCollectionInfoThunk";

const initialState = {
  status: -1,
  statusText: "",
  loading: false,
  selectedState: "",
};

export const updateCollectionStateSlice = createSlice({
  name: "updateCollectionStateSliceReducer",
  initialState,
  reducers: {
    setCollectionState: (state, action) => {
      state.selectedState = action.payload;
    },

    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Actualizar estado de recolección
    builder.addCase(fetchUpdateCollectionInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUpdateCollectionInfo.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.statusText = action.payload.statusText;
      state.loading = false;
    });
    builder.addCase(fetchUpdateCollectionInfo.rejected, (state, action) => {
      console.log(action.payload);
      state.loading = false;
    });
  },
});

export const { setCollectionState, reset } = updateCollectionStateSlice.actions;

export default updateCollectionStateSlice.reducer;
