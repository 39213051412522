import { createAsyncThunk } from "@reduxjs/toolkit";

import { patchRedux } from "../../../utils/old/api";

export const fetchEditUserCustomPrice = createAsyncThunk(
  "editUserCustomPrice/fetchPatch",
  async (payload: { data: { price: number }; id: number }, thunkAPI) => {
    try {
      const response = await patchRedux(
        `/material-price/${payload.id}`,
        payload.data
      );
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    } catch (error) {
      const errors = thunkAPI.rejectWithValue(error);
      return errors;
    }
  }
);
