import { tableHeadCellType } from "../../../interfaces/data/tableHeadCellInterface";

export const transferListTableHeadCells: tableHeadCellType[] = [
  {
    id: "startDate",
    alignment: "left",
    label: "Fecha de inicio",
    disablePadding: false,
  },
  {
    id: "originWarehouse",
    alignment: "left",
    label: "Bodega origen",
    disablePadding: false,
  },
  {
    id: "destinationWarehouse",
    alignment: "left",
    label: "Bodega destino",
    disablePadding: false,
  },
  {
    id: "status",
    alignment: "left",
    label: "Estado",
    disablePadding: false,
  },
];
