import { createSlice } from "@reduxjs/toolkit";

import { CollectionListSliceType } from "../../../interfaces/redux/collectionListSliceInterface";
import { fetchSearchAllCollections } from "../../thunks/collections/collectionListSearchThunk";
import { fetchCollectionsList } from "../../thunks/collections/collectionListThunk";

const initialState: CollectionListSliceType = {
  status: "idle",
  collectionList: [],
  totalRows: 0,
  filter: "",
};

export const collectionListSlice = createSlice({
  name: "collectionList",
  initialState,
  reducers: {
    reset: () => initialState,
    setCollectionsFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    //Get lista Recolecciones en general con o sin filtros
    builder.addCase(fetchCollectionsList.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchCollectionsList.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.collectionList = action.payload.data;
      state.totalRows = action.payload.total;
    });
    builder.addCase(fetchCollectionsList.rejected, (state, action) => {
      state.status = "failed";
    });

    //Post para obtener nueva lista Recolecciones acorde a la búsqueda específica
    builder.addCase(fetchSearchAllCollections.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchSearchAllCollections.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.collectionList = action.payload.data;
    });
    builder.addCase(fetchSearchAllCollections.rejected, (state) => {
      state.status = "failed";
    });
  },
});

export const { reset, setCollectionsFilter } = collectionListSlice.actions;

export default collectionListSlice.reducer;
