import { forwardRef, useState } from "react";

import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import { Box, Divider } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import CustomButton from "../../buttons/default/CustomButton";
import CustomText from "../../texts/CustomText";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import CustomSearchFilters from "../../headers/default/CustomSearchFilters";
import { setFormAddMaterialState } from "../../../../redux/slices/collections/addMaterialCollectionSlice";
import {
  reset as resetCollectionMaterialsModalStates,
  setCollectionMaterialsModalState,
} from "../../../../redux/slices/collections/collectionsMaterialsModalsStates";
import { ReactComponent as BlueCheckIcon } from "../../../../assets/icons/modals/blueCheck.svg";
import { collectionsMaterialsCategories } from "../../../../utils/constants/collections/collectionsMaterialsCategories";
import MaterialsModalLayout from "../../../../layouts/MaterialsModalLayout";

export default forwardRef(function CustomSelectCategory() {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const { category } = useAppSelector(
    (state) => state.addMaterialCollection.form
  );

  const [selectedCategory, setSelectedCategory] = useState(category);

  const [loadMore, setLoadMore] = useState(false);

  const handleLoadMore = () => {
    setLoadMore(true);
  };

  const dispatch = useAppDispatch();

  const handleCloseClickModal = (event: React.MouseEvent<SVGSVGElement>) => {
    dispatch(resetCollectionMaterialsModalStates());
  };

  const handleBackModal = () => {
    dispatch(resetCollectionMaterialsModalStates());
  };

  const handleSaveCategory = () => {
    if (selectedCategory !== "") {
      dispatch(
        setFormAddMaterialState({ id: "category", value: selectedCategory })
      );
      dispatch(
        setCollectionMaterialsModalState({ id: "selectCategory", value: false })
      );
      dispatch(
        setCollectionMaterialsModalState({ id: "addMaterial", value: true })
      );
    }
  };

  const handleSetSelected = (selected: string) => {
    setSelectedCategory(selected);
  };

  return (
    <MaterialsModalLayout handleClose={handleCloseClickModal}>
      <CustomText
        showBackArrowButton
        bigSize
        premediumWeight
        color="#1C1C1C"
        onClick={handleBackModal}
        style={{ marginBottom: PP(24) }}
      >
        {t(
          "collectionInformation.customSelectCategoryModal.Seleccionar categoría"
        )}
      </CustomText>
      <Divider style={{ marginBottom: PP(24) }} />
      <CustomSearchFilters
        about="onlySearch"
        placeholder={t(
          "collectionInformation.customSelectCategoryModal.Buscar por nombre"
        )}
        width={432}
        height={40}
        bradius={PP(4)}
        widthsearchicon={16}
        heightsearchicon={16}
      />
      <Box
        sx={[
          {
            borderRadius: PP(8),
            minWidth: PP(432),
            height: PP(160),
            marginBottom: PP(24),
            marginTop: PP(8),
          },
        ]}
      >
        <Box>
          <InfiniteScroll
            height={PP(160)}
            dataLength={collectionsMaterialsCategories.length}
            next={handleLoadMore}
            hasMore={collectionsMaterialsCategories.length >= 10}
            loader={
              <CustomText color="#969696">
                {t("collectionInformation.customSelectCategoryModal.cargando")}
              </CustomText>
            }
          >
            {collectionsMaterialsCategories.map((category, key) => (
              <Box
                onClick={() => handleSetSelected(category)}
                key={key}
                sx={[
                  {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    padding: PP(16),
                    color: "white",
                    height: PP(40),
                    "&:hover": {
                      backgroundColor: "#ECF1FF",
                      cursor: "pointer",
                      borderRadius: PP(4),
                    },
                  },
                  selectedCategory !== "" &&
                    selectedCategory === category && {
                      backgroundColor: "#ECF1FF",
                      borderRadius: PP(4),
                    },
                ]}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box>
                    <CustomText
                      noSpacing
                      color={
                        selectedCategory !== "" && selectedCategory === category
                          ? "#4463B8"
                          : "#1C1C1C"
                      }
                    >
                      {t(
                        `collectionInformation.customSelectCategoryModal.categories.${category}`
                      )}
                    </CustomText>
                  </Box>
                  {selectedCategory !== "" && selectedCategory === category && (
                    <span>
                      <BlueCheckIcon />
                    </span>
                  )}
                </Box>
              </Box>
            ))}
          </InfiniteScroll>
        </Box>
      </Box>

      <CustomButton
        width={432}
        height={44}
        label={t("collectionInformation.customSelectCategoryModal.Seleccionar")}
        onClick={handleSaveCategory}
      />
      <CustomButton
        noSpacing
        width={432}
        height={44}
        cancelButton
        onClick={handleBackModal}
      />
    </MaterialsModalLayout>
  );
});
