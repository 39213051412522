import { createAsyncThunk } from "@reduxjs/toolkit";

import { patchRedux } from "../../../../utils/old/api";

export const fetchEditMaterialTransfer = createAsyncThunk(
  "editMaterialTransfer/fetchPatch",
  async (
    payload: {
      data: {};
      transferMaterialId: number;
    },
    thunkAPI
  ) => {
    try {
      const response = await patchRedux(
        `/transfer-material/${payload.transferMaterialId}`,
        payload.data
      );
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    } catch (error) {
      const errors = thunkAPI.rejectWithValue(error);
      // console.log(errors);
      return errors;
    }
  }
);
