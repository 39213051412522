import React from "react";
import {
  Divider as MuiDivider,
  Grid,
  TextField as MuiTextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import BasicInput from "../../../../../components/AppFormikProvider/BasicInput";
import useBusinessConstants from "./hooks/useBusinessConstants";
import BasicSelect from "../../../../../components/AppFormikProvider/BasicSelect";
import { useFormikContext } from "formik";

const FormCreateBusinesses = () => {
  const { t } = useTranslation();
  const {
    regimenBusinessOptions,
    entityBusinessTypes,
    typeOfOperator,
    paymentMethodOption,
    documentTypeOptions,
  } = useBusinessConstants();

  type DocumentType = {
    document_type: "cc" | "nit" | "international";
  };

  const { values } = useFormikContext<DocumentType>();

  console.log(values.document_type);

  const labelDocumentNumber: Record<DocumentType["document_type"], string> = {
    cc: t(`createNewBusinessModal.Número de cédula`),
    nit: t(`createNewBusinessModal.Número del Nit`),
    international: t("createNewBusinessModal.Internacional"),

  };

  return (
    <Grid container mt={2} spacing={2}>
      <Grid item xs={12} md={6}>
        <BasicInput
          label={t("createNewBusinessModal.Nombre comercial")}
          name="name"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <BasicInput
          label={t("createNewBusinessModal.Nombre legal")}
          name="legal_name"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <BasicInput label={t("createNewBusinessModal.Correo")} name="email" />
      </Grid>
      <Grid item xs={12} md={6}>
        <BasicInput label={t("createNewBusinessModal.Teléfono")} name="phone" />
      </Grid>
      <Grid item xs={12} md={6}>
        <BasicSelect
          options={documentTypeOptions}
          label={t("createNewBusinessModal.SELECCIONA EL TIPO DE DOCUMENTO")}
          name="document_type"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <BasicInput
          name="document_number"
          label={
            labelDocumentNumber[values.document_type] ||
            t(`createNewBusinessModal.Número de documento`)
          }
        />
      </Grid>
      {/* <Grid item xs={12} md={6}>
        <BasicSelect
          options={regimenBusinessOptions}
          name="regime"
          label={t("createNewBusinessModal.SELECCIONA EL RÉGIMEN TRIBUTARIO")}
        />
      </Grid> */}
      <Grid item xs={12} md={6}>
        <BasicSelect
          options={entityBusinessTypes}
          name="segment"
          label={t("createNewBusinessModal.SELECCIONA EL TIPO DE NEGOCIO")}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <BasicSelect
          options={typeOfOperator}
          name="operator"
          label={t("createNewBusinessModal.SELECCIONA EL TIPO DE OPERADOR")}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <BasicSelect
          options={paymentMethodOption}
          name="payment_method"
          label={t("createNewBusinessModal.SELECCIONA MÉTODO DE PAGO")}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <BasicInput
          name="internal_code"
          label={t("createNewBusinessModal.Código interno")}
        />
      </Grid>
    </Grid>
  );
};

export default FormCreateBusinesses;
