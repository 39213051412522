import PPD from "../../utils/modals/ppd";

export const customMuiTable = {
  styleOverrides: {
    root: {
      borderCollapse: "separate",
      borderSpacing: `0 ${PPD(4)}`,
    },
  },
};
