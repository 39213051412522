import customTableRow from "../../styles/tables/customTableRow";
import PPD from "../../utils/modals/ppd";

const { tableRow } = customTableRow;

export const customMuiTableRow = {
  styleOverrides: {
    root: {
      backgroundColor: "transparent",
      textTransform: "none",
    },
  },
  variants: [
    {
      props: { variant: "default" },
      style: {
        marginBottom: PPD(5),
        backgroundColor: tableRow.bold["bg-color"].collections.default,
        color: tableRow.bold["text-color"].collections.default,
        "&:hover": {
          backgroundColor: tableRow.bold["bg-color"].collections.hover,
          color: tableRow.bold["text-color"].collections.hover,
        },
      },
    },
  ],
};
