import { createSlice } from "@reduxjs/toolkit";

import { SelectedBusinessSliceType } from "../../../../interfaces/redux/businessSelectedSliceInterface";
import { fetchSelectedBusiness } from "../../../thunks/users/business/businessSelectedThunk";

const initialState: SelectedBusinessSliceType = {
  status: "idle",
  selectedBusiness: {
    id: -1,
    legal_name: "",
    name: "",
    email: "",
    phone: "",
  },
};

export const businessSelectedSlice = createSlice({
  name: "businessSelected",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Get Negocio seleccionado
    builder.addCase(fetchSelectedBusiness.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchSelectedBusiness.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.selectedBusiness = action.payload;
    });
    builder.addCase(fetchSelectedBusiness.rejected, (state) => {
      state.status = "failed";
    });
  },
});

export const {} = businessSelectedSlice.actions;

export default businessSelectedSlice.reducer;
