import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CollectionUserModalSliceType } from "../../../interfaces/redux/collectionUserModalStateInterface";

type CollectionModalKeys = any;

interface SetCollectionsModalStatePayload {
  id: CollectionModalKeys;
  value: boolean;
}
const initialState: CollectionUserModalSliceType = {
  collections: {
    newCollection: false,
    selectAddress: false,
    newAddress: false,
    selectDate: false,
    selectHour: false,
    cancelCollection: false,
    goodbye: false,
  },
};

export const collectionUserModalStateSlice = createSlice({
  name: "collectionUserModalStateReducer",
  initialState,
  reducers: {
    setCollectionsModalState: (
      state: any,
      action: PayloadAction<SetCollectionsModalStatePayload>
    ) => {
      // @ts-ignore
      state.collections[action.payload.id] = action.payload.value;
    },
    reset: () => initialState,
  },
});

export const { setCollectionsModalState, reset } =
  collectionUserModalStateSlice.actions;

export default collectionUserModalStateSlice.reducer;
