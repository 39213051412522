import { createAsyncThunk } from "@reduxjs/toolkit";

import { postRedux } from "../../../utils/old/api";

export const fetchCollectionAttachNewFile = createAsyncThunk(
  "collectionAttachNewFile/fetchPost",
  async (
    payload: {
      data: File;
      ownerId: number;
      type: string;
    },
    thunkAPI
  ) => {
    const formData = new FormData();
    formData.append("file", payload.data);
    formData.append("ownerType", "collection");
    formData.append("ownerId", `${payload.ownerId}`);
    formData.append("type", payload.type);
    formData.append("description", "");
    try {
      const response = await postRedux("uploaded-file", formData);
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    } catch (error) {
      const errors = thunkAPI.rejectWithValue(error);
      console.log(errors);

      return errors;
    }
  }
);
