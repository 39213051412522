import { useNavigate } from "react-router-dom";

import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";

import useAuth from "../../../../hooks/old/useAuth";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function SignIn() {
  const navigate = useNavigate();
  const { PP } = usePixelPerfect();
  const { signIn } = useAuth();
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t("validations.Debe ser un correo electrónico válido"))
          .max(255)
          .required(t("validations.El correo es requerido")),
        password: Yup.string()
          .max(255)
          .required(t("validations.Contraseña es requerida")),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await signIn(values.email.toLowerCase(), values.password);

          navigate("/collections");
        } catch (error) {
          const message = t(
            "validations.Inicio de sesión inválido. Revisa tus credenciales"
          );

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <div
            style={{ display: "flex", flexDirection: "column", width: PP(500) }}
          >
            <TextField
              type="email"
              name="email"
              label={t("Cuenta de correo")}
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              fullWidth
              my={2}
            />
            <TextField
              type="password"
              name="password"
              label={t("Contraseña")}
              value={values.password}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
              fullWidth
              my={2}
            />
          </div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            sx={{ marginTop: PP(16) }}
          >
            <Typography
              align="center"
              sx={{ fontSize: PP(16), fontWeight: 500 }}
              gutterBottom
            >
              {t("Iniciar")}
            </Typography>
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default SignIn;
