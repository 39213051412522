import { createSlice } from "@reduxjs/toolkit";
import { fetchDeleteMaterialTransaction } from "../../thunks/transactions/deleteMaterialTransactionThunk";

const initialState = {
  selectedToDeleteMaterialName: "",
  selectedToDeleteMaterialId: -1,
  loading: false,
  status: -1,
  statusText: "",
};

export const deleteMaterialTransactionSlice = createSlice({
  name: "deleteMaterialTransactionReducer",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = -1;
      state.statusText = "";
    },
    setSelectedToDeleteMaterialId: (state, action) => {
      state.selectedToDeleteMaterialId = action.payload;
    },
    setSelectedToDeleteMaterialName: (state, action) => {
      state.selectedToDeleteMaterialName = action.payload;
    },

    reset: () => initialState,
  },
  extraReducers: (builder) => {
    //Eliminar material seleccionado de la transacción
    builder.addCase(fetchDeleteMaterialTransaction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchDeleteMaterialTransaction.fulfilled,
      (state, action) => {
        state.status = action.payload.status;
        state.statusText = action.payload.statusText;
        state.loading = false;
      }
    );
    builder.addCase(
      fetchDeleteMaterialTransaction.rejected,
      (state, action) => {
        state.loading = false;
      }
    );
  },
});

export const {
  resetStatus,
  reset,
  setSelectedToDeleteMaterialId,
  setSelectedToDeleteMaterialName,
} = deleteMaterialTransactionSlice.actions;

export default deleteMaterialTransactionSlice.reducer;
