import { createSlice } from "@reduxjs/toolkit";
import { collectionHistoryPageSliceType } from "../../../interfaces/redux/collectionHistoryPageSliceInterface";
import { fetchCollectionHistoryPage } from "../../thunks/collections/collectionHistoryPageThunk";

const initialState: collectionHistoryPageSliceType = {
  loading: false,
  status: -1,
  statusText: "",
  history: [],
};

export const collectionHistoryPageSlice = createSlice({
  name: "collectionHistoryPageReducer",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = -1;
      state.statusText = "";
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Historial de cambios en una recolección
    builder.addCase(fetchCollectionHistoryPage.pending, (state) => {
      state.loading = true;
      state.statusText = "loading";
    });
    builder.addCase(fetchCollectionHistoryPage.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.statusText = "succeeded";
      state.loading = false;
      state.history = action.payload.data;
    });
    builder.addCase(fetchCollectionHistoryPage.rejected, (state, action) => {
      state.loading = false;
      state.statusText = "error";
      alert("500 (Error interno del servidor)");
    });
  },
});

export const { resetStatus, reset } = collectionHistoryPageSlice.actions;

export default collectionHistoryPageSlice.reducer;
