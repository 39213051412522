import { styled, TableCell, TableRow } from "@mui/material";

import { CustomTableRowType } from "../../../../interfaces/styles/customTableRowInterface";
import { POOType } from "../../../../interfaces/data/POOInterface";

interface CustomPointsOfOriginTableRowProps {
  element: POOType;
  myKey: number;
}

const CustomTableRow = styled(TableRow)<CustomTableRowType>(({}) => ({}));

export default function CustomPointsOfOriginTableRow(
  props: CustomPointsOfOriginTableRowProps
) {
  //   const { t } = useTranslation();
  const { element, myKey } = props;

  return (
    <CustomTableRow
      key={myKey}
      variant="default"
      style={{ cursor: "default", width: "100%" }}
    >
      <>
        <TableCell style={{ color: "#212121", cursor: "default" }}>
          {element.date}
        </TableCell>
        <TableCell style={{ cursor: "default" }} align="center">
          {element.generator}
        </TableCell>

        <TableCell style={{ cursor: "default" }} align="center">
          {element.quantity}
        </TableCell>

        <TableCell style={{ cursor: "default" }} align="center">
          {element.address}
        </TableCell>
        <TableCell style={{ cursor: "default" }} align="center">
          {element.city}
        </TableCell>
      </>
    </CustomTableRow>
  );
}
