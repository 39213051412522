import moment from "moment-timezone";
import "moment/locale/es";

export const createCollectionDateFormat = (
  data: Date | string,
  t: Function
): string => {
  try {
    const month = moment(data)
      .locale("es")
      .tz("America/Bogota")
      .format("MMMM")
      .toLowerCase();

    return (
      t(`months.${month}`) +
      moment(data)
        .locale("es")
        .tz("America/Bogota")
        .format(" D, YYYY. h:mm a")
        .replace(/^\w/, (c) => c.toUpperCase())
    );
  } catch (error) {
    return "";
  }
};
