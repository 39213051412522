import { createRef, useCallback, useEffect } from "react";

import { Box, Modal } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../redux/store";
import CustomButton from "../../components/custom/buttons/default/CustomButton";
import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import CustomSelectCategory from "../../components/custom/modals/sales/CustomSelectCategory";
import CustomAddMaterialModal from "../../components/custom/modals/sales/CustomAddMaterialModal";
import EditTransactionForm from "../../components/transactions/EditTransactionForm";
import { fetchTransactionDetails } from "../../redux/thunks/transactions/transactionsDetailsThunk";
import { useParams } from "react-router-dom";
import TransactionMaterialsInfo from "../../components/transactions/TransactionMaterialsInfo";
import CustomSelectMaterialTransactionModal from "../../components/custom/modals/sales/CustomSelectMaterialTransactionModal";
import { fetchEditTransactionInfo } from "../../redux/thunks/transactions/editTransactionInfoThunk";
import CustomTransactionSelectTypeFileModal from "../../components/custom/modals/sales/CustomTransactionSelectTypeFileModal";
import TransactionAttachments from "../../components/transactions/TransactionAttachments";
import CustomAttachNewTransactionFileModal from "../../components/custom/modals/sales/CustomAttachNewTransactionFileModal";
import CustomDeleteTransactionAttachFileModal from "../../components/custom/modals/sales/CustomDeleteTransactionAttachFileModal";
import PointsOfOrigin from "../../components/transactions/PointsOfOrigin";
import CustomDeleteTransactionMaterialModal from "../../components/custom/modals/sales/CustomDeleteTransactionMaterialModal";
import CustomEditTransactionMaterialModal from "../../components/custom/modals/sales/CustomEditTransactionMaterialModal";
import {
  setMessage,
  setOpenSnackbar,
  setType,
} from "../../redux/slices/globalSnackbar/globalSnackbarSlice";
import TransactionsDetailsLayout from "../../layouts/sales/TransactionsDetailsLayout";
import { resetTransactionDetails } from "../../redux/slices/transactions/transactionDetailsSlice";

export default function TransactionsDetails() {
  const {
    selectCategory,
    addMaterial,
    selectMaterial,
    deleteMaterial,
    editQuantity,
  } = useAppSelector((state) => state.transactionMaterialsModals);

  const { selectTypeFile, attachFile, deleteAttachFile } = useAppSelector(
    (state) => state.transactionAttachedFilesModalStates
  );

  const { id } = useParams();
  const ref = createRef();
  const dispatch = useAppDispatch();
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();

  const { transactionDetails } = useAppSelector(
    (state) => state.transactionDetails
  );

  const getTransactionDetails = useCallback(async () => {
    if (id !== undefined) await dispatch(fetchTransactionDetails({ id: id }));
  }, [transactionDetails]);

  useEffect(() => {
    getTransactionDetails();

    return () => {
      dispatch(resetTransactionDetails());
    };
  }, [dispatch, id]);

  const handleFinishTransaction = async () => {
    if (transactionDetails.materials.length !== 0) {
      await dispatch(
        fetchEditTransactionInfo({
          data: { state: "close" },
          transactionId: id,
        })
      );
      await getTransactionDetails();
      dispatch(setOpenSnackbar(true));
      dispatch(setMessage(t("snackbars.Cambios guardados exitosamente")));
      dispatch(setType("sucessSnackbar"));
    } else {
      dispatch(setOpenSnackbar(true));
      dispatch(setMessage(t("Debes ingresar los materiales de la venta")));
      dispatch(setType("failedSnackbar"));
    }
  };

  return (
    <TransactionsDetailsLayout>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box>
          <EditTransactionForm />
          <TransactionMaterialsInfo />
          <TransactionAttachments />
        </Box>
        {transactionDetails.state === "open" && (
          <CustomButton
            width={600}
            height={44}
            noSpacing
            style={{ padding: "0px", marginTop: PP(48) }}
            onClick={handleFinishTransaction}
            label={t("Finalizar venta")}
          />
        )}
        {transactionDetails.state === "close" && <PointsOfOrigin />}
      </Box>

      {/* Materiales */}

      <Modal
        open={selectCategory}
        children={<CustomSelectCategory ref={ref} />}
      />

      <Modal
        open={addMaterial}
        children={<CustomAddMaterialModal ref={ref} />}
      />

      <Modal
        open={selectMaterial}
        children={<CustomSelectMaterialTransactionModal ref={ref} />}
      />

      <Modal
        open={deleteMaterial}
        children={<CustomDeleteTransactionMaterialModal ref={ref} />}
      />

      <Modal
        open={editQuantity}
        children={<CustomEditTransactionMaterialModal ref={ref} />}
      />

      {/* Archivos adjuntos */}

      <Modal
        open={selectTypeFile}
        children={<CustomTransactionSelectTypeFileModal ref={ref} />}
      />
      <Modal
        open={attachFile}
        children={<CustomAttachNewTransactionFileModal ref={ref} />}
      />
      <Modal
        open={deleteAttachFile}
        children={<CustomDeleteTransactionAttachFileModal ref={ref} />}
      />
    </TransactionsDetailsLayout>
  );
}
