import { useState } from "react";

import { useTranslation } from "react-i18next";
import { Chip, IconButton, styled, TableCell, TableRow } from "@mui/material";

import { CustomTableRowType } from "../../../../interfaces/styles/customTableRowInterface";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import { ClientType } from "../../../../interfaces/data/clientInterface";
import { ReactComponent as EditIcon } from "../../../../assets/icons/layouts/edit.svg";

interface CustomClientsListTableRowProps {
  element: ClientType;
  myKey: number;
  setEditClientModal: (newValue: boolean) => void;
  setSelectedClientToEdit: (newValue: ClientType) => void;
}

const CustomTableRow = styled(TableRow)<CustomTableRowType>(({}) => ({}));

export default function CustomClientsListTableRow(
  props: CustomClientsListTableRowProps
) {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const { element, myKey } = props;

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleEditClient = () => {
    props.setSelectedClientToEdit(element);
    props.setEditClientModal(true);
  };

  return (
    <CustomTableRow
      key={myKey}
      variant="default"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ cursor: "default" }}
    >
      {element && (
        <>
          <TableCell style={{ color: "#212121", cursor: "default" }}>
            {element.name}
          </TableCell>
          <TableCell style={{ color: "#212121", cursor: "default" }}>
            {element.legalName}
          </TableCell>
          <TableCell style={{ cursor: "default" }}>
            {element.documentNumber}
          </TableCell>
          <TableCell style={{ cursor: "default" }}>{element.address}</TableCell>
          <TableCell style={{ cursor: "default" }}>
            {element.contactName}
          </TableCell>
          <TableCell style={{ cursor: "default" }}>
            {element.contactEmail}
          </TableCell>
          <TableCell style={{ cursor: "default" }}>
            {element.contactPhone}
          </TableCell>
          <TableCell sx={{ padding: PP(16), cursor: "default" }} align="right">
            <IconButton onClick={handleEditClient}>
              <EditIcon
                style={{
                  width: PP(22),
                  height: PP(22),
                  cursor: "pointer",
                }}
              />
            </IconButton>
          </TableCell>
        </>
      )}
    </CustomTableRow>
  );
}
