//Certificados medioambientales-Negocios
import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Card as MuiCard,
  CardContent,
  Grid as MuiGrid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";

import {
  fetchBusiness,
  fetchBusinessCertificatesAddresses,
} from "../../../../../redux/old/slices/businessSlicer";

const Grid = styled(MuiGrid)(spacing);

const Card = styled(MuiCard)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const headCells = [
  { id: "month", alignment: "center", label: "Mes", disablePadding: true },
  { id: "year", alignment: "center", label: "Año", disablePadding: true },
  { id: "action", alignment: "center", label: "Acción", disablePadding: true },
];

//CertificatesCard

export default function CertificatesCard() {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const { certificatesAddresses } = useSelector((state) => state.business);

  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // const initialValuesCertificateAddress = {
  //   certificateAddress: "SELECCIONA LA DIRECCIÓN",
  // };

  // //Manejando selección de dirección de certificados ambientales:

  // function validationSchema() {
  //   return {
  //     certificateAddress: Yup.string()
  //       .notOneOf(["SELECCIONA LA DIRECCIÓN"], "Dirección requerida")
  //       .required("Dirección requerida"),
  //   };
  // }

  // const formikCertificateAddress = useFormik({
  //   initialValues: initialValuesCertificateAddress,
  //   enableReinitialize: true,
  //   validationSchema: Yup.object(validationSchema()),
  //   onSubmit: async (data) => {
  //     dispatch(fetchBusinessCertificates({ id: id, address: data })); //Reduxtoolkit Get de todos los certificados del negocio
  //   },
  // });

  // Agrupar certificados por dirección y filtrar por type
  const groupedCertificates = certificatesAddresses
    .filter((certificate) => certificate.type === "monthly_env_certificate")
    .reduce((acc, certificate) => {
      if (certificate.extra) {
        const key = certificate?.extra?.address;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(certificate);
      }
      return acc;
    }, {});

  useEffect(() => {
    getBusiness();
  }, []);

  const getBusiness = async () => {
    dispatch(fetchBusiness(id));
    dispatch(fetchBusinessCertificatesAddresses(id));
  };

  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Grid justifyContent="space-between" container spacing={10}>
            <Grid item>
              <Typography variant="h6" gutterBottom>
                {t("Certificados ambientales")}
              </Typography>
            </Grid>
          </Grid>
          <br />
          {Object.keys(groupedCertificates).map((description, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleAccordionChange(`panel${index}`)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontSize: "16px", color: "#585857" }}>
                  {description}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableWrapper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {headCells.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            align={headCell.alignment}
                            padding={
                              headCell.disablePadding ? "none" : "normal"
                            }
                          >
                            {t(`tables.tableHeadCells.${headCell.label}`)}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {groupedCertificates[description].map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                          <TableCell align="center">
                            {row.extra.month}
                          </TableCell>
                          <TableCell align="center">{row.extra.year}</TableCell>
                          <TableCell align="center">
                            <IconButton
                              aria-label="delete"
                              size="large"
                              component="a"
                              href={row.url}
                              target="_blank"
                            >
                              <DownloadIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableWrapper>
              </AccordionDetails>
            </Accordion>
          ))}
          {Object.keys(groupedCertificates).length === 0 && (
            <Box display="flex" justifyContent="center">
              <Typography style={{ fontSize: "16px", color: "gray" }}>
                {t("No tiene certificados")}
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </>
  );
}
