import { create } from "react-native-pixel-perfect";

//this size is the size that your design is made for (screen size)

const designResolutionDesktop = {
  width: 1920,
  height: 1080,
};

const usePixelPerfect = () => {
  const PP = create(designResolutionDesktop);

  const formattedPP = (size) => {
    // return `${PP(size)}px`;
    return `${size}px`;
  };

  const formattedPP2 = (size) => {
    return `${PP(size)}px`;
  };

  return { PP: formattedPP, PP2: formattedPP2 };
};

export default usePixelPerfect;
