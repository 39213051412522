import { createAsyncThunk } from "@reduxjs/toolkit";

import { getRedux } from "../../../utils/old/api";

export const fetchCollectorsList = createAsyncThunk(
  "collectorsList/fetchGet",
  async (payload, thunkAPI) => {
    try {
      const response = await getRedux(`/collection/collector`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
