import { createSlice } from "@reduxjs/toolkit";

import { fetchCurrentPasswordValidation } from "../../../thunks/settings/security/currentPasswordValidationThunk";

const initialState: { loading: boolean; status: number; oldPassword: string } =
  {
    loading: false,
    status: -1,
    oldPassword: "",
  };

export const currentPasswordValidationSlice = createSlice({
  name: "currentPasswordValidationReducer",
  initialState,
  reducers: {
    setOldPassword: (state, action) => {
      state.oldPassword = action.payload;
    },

    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Verificando contraseña actual del usuario logueado
    builder.addCase(fetchCurrentPasswordValidation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchCurrentPasswordValidation.fulfilled,
      (state, action) => {
        state.loading = false;
        state.status = action.payload.data.status;
      }
    );
    builder.addCase(fetchCurrentPasswordValidation.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { reset, setOldPassword } = currentPasswordValidationSlice.actions;

export default currentPasswordValidationSlice.reducer;
