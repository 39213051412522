import { createSlice } from "@reduxjs/toolkit";

import { fetchTransferList } from "../../../thunks/inventory/transfer/transferListThunk";
import { TransferListSliceType } from "../../../../interfaces/redux/transferListSliceInterface";

const initialState: TransferListSliceType = {
  status: "idle",
  transferList: [],
  totalRows: 0,
  filter: "",
};

export const transferListSlice = createSlice({
  name: "transferList",
  initialState,
  reducers: {
    reset: () => initialState,
    setTransferFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    //Get lista transferencias de inventario en general con o sin filtros
    builder.addCase(fetchTransferList.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchTransferList.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.transferList = action.payload.data;
      state.totalRows = action.payload.total;
    });
    builder.addCase(fetchTransferList.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export const { reset, setTransferFilter } = transferListSlice.actions;

export default transferListSlice.reducer;
