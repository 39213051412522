import { useCallback, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Box, SelectChangeEvent } from "@mui/material";

import { ReactComponent as InfoIcon } from "../../../assets/icons/modals/infoIcon.svg";
import usePixelPerfect from "../../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { setMessage, setOpenSnackbar, setType } from "../../../redux/slices/globalSnackbar/globalSnackbarSlice";
import CustomSelect from "../../custom/forms/default/CustomSelect";
import CustomInput from "../../custom/forms/default/CustomInput";
import CustomText from "../../custom/texts/CustomText";
import CustomButton from "../../custom/buttons/default/CustomButton";
import { fetchTransferDetails } from "../../../redux/thunks/inventory/transfer/transferDetailsThunk";
import { fetchWarehousesList } from "../../../redux/thunks/warehouse/warehousesListThunk";
import { fetchUpdateTransferInfo } from "../../../redux/thunks/inventory/transfer/updateTransferInfo";
import StartDate from "../../custom/forms/inventory/transfer/StartDate";
import EndDate from "../../custom/forms/inventory/transfer/EndDate";
import { reset } from "../../../redux/slices/warehouse/warehouseListSlice";
import { freightValueYup, validateYupSchema } from "../../../utils/forms/validationSchema";
import { reset as resetTransferDetails } from "../../../redux/slices/inventory/transfer/transferDetailsSlice";

interface InitialFormikValues {
    startDate: string,
    originWarehouse: number,
    destinationWarehouse: number,
    endDate?: string | null,
    status: string,
    consecutive: string | number | undefined,
    freightValue: string | number | undefined,
    observation: string | number | undefined,
}

export default function EditTransferForm() {
    const { PP } = usePixelPerfect();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { id } = useParams();

    const { transferDetails } = useAppSelector(
        (state) => state.transferDetails
    );

    const { warehousesList } = useAppSelector((state) => state.warehouseList);

    const [startDate, setStartDate] = useState(
        transferDetails.startDate || "");

    const [endDate, setEndDate] = useState(
        transferDetails.endDate || "");

    const getWarehousesList = async (): Promise<void> => {
        dispatch(reset());
        await dispatch(fetchWarehousesList({ page: 0 }));

    }

    useEffect(() => {
        getWarehousesList();
    }, [dispatch]);

    const getTransferDetails = useCallback(async () => {
        dispatch(resetTransferDetails());
        if (id !== undefined) await dispatch(fetchTransferDetails({ id: id }));
    }, [transferDetails]);

    useEffect(() => {
        getTransferDetails();
    }, [dispatch, id]);

    const initialFormikValues: InitialFormikValues = {
        startDate: transferDetails.startDate,
        originWarehouse: transferDetails.originWarehouse,
        destinationWarehouse: transferDetails.destinationWarehouse,
        endDate: transferDetails.endDate,
        status: transferDetails.status || '',
        consecutive: transferDetails.consecutive || '',
        freightValue: transferDetails.freightValue || 0,
        observation: transferDetails.observation || '',
    };

    const handleSubmit = async (data: InitialFormikValues) => {

        const payload: InitialFormikValues = {
            ...data,
        };

        if (id !== undefined) {
            await dispatch(
                fetchUpdateTransferInfo({ data: payload, transferId: id })
            );
            await getTransferDetails();
            dispatch(setOpenSnackbar(true));
            dispatch(setMessage(t("Transferencia actualizada")));
            dispatch(setType("sucessSnackbar"));
        }
    };

    const validationSchema = validateYupSchema({
        freightValue: freightValueYup,
    });

    const formikEditTransfer = useFormik<InitialFormikValues>({
        initialValues: initialFormikValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
        validateOnChange: false,
    });


    const formikEditTransferChange = useCallback(
        (
            event:
                | React.ChangeEvent<HTMLInputElement>
                | SelectChangeEvent<number | string>
        ) => {
            formikEditTransfer.setFieldValue(
                event.target.name,
                event.target.value
            );
        },
        [formikEditTransfer]
    );


    useEffect(() => {
        if (transferDetails.id !== undefined) {
            formikEditTransfer.setValues({
                startDate: transferDetails.startDate,
                originWarehouse: transferDetails.originWarehouse,
                destinationWarehouse: transferDetails.destinationWarehouse,
                endDate: transferDetails.endDate,
                status: transferDetails.status || '',
                consecutive: transferDetails.consecutive || '',
                freightValue: transferDetails.freightValue || 0,
                observation: transferDetails.observation || '',
            });
        }
    }, [transferDetails]);

    return (
        <form noValidate onSubmit={formikEditTransfer.handleSubmit}>
            <Box display="flex" flexDirection="column" alignItems="center" >
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    width="77vw"
                >
                    <Box width="30%">
                        <CustomText color="#7D7D7D" noSpacing>{t("Detalles de asignación")}</CustomText>
                        <Box width="100%"
                            style={{
                                border: "1px solid #E8E8E8",
                                padding: `${PP(24)} ${PP(24)}`,
                                marginBottom: PP(16),
                                borderRadius: PP(8),
                            }}
                        >
                            <CustomInput
                                id="consecutive"
                                label={t("Consecutivo")}
                                placeholder=""
                                value={formikEditTransfer.values.consecutive}
                                labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
                                inputHeight={PP(40)}
                                onChange={formikEditTransferChange}
                                disabled={transferDetails.status === "finished"}
                            />
                            <CustomSelect
                                id="originWarehouse"
                                value={formikEditTransfer.values.originWarehouse}
                                onChange={formikEditTransferChange}
                                label={t("Bodega de origen")}
                                fullWidth
                                items={warehousesList.map((warehouse) => {
                                    return { label: warehouse.name, value: warehouse.id };
                                })}
                                extraStyle={{ marginTop: PP(24) }}
                                placeholder={t("Seleccionar bodega origen")}
                                disabled
                            />
                            <CustomSelect
                                id="destinationWarehouse"
                                value={formikEditTransfer.values.destinationWarehouse}
                                onChange={formikEditTransferChange}
                                label={t("Bodega destino")}
                                fullWidth
                                items={warehousesList.map((warehouse) => {
                                    return { label: warehouse.name, value: warehouse.id };
                                })}
                                extraStyle={{ marginTop: PP(24) }}
                                placeholder={t("Seleccionar bodega destino")}
                                disabled
                            />
                        </Box>
                    </Box>
                    <Box width="30%">
                        <CustomText color="#7D7D7D" noSpacing>{t("Flete y fechas")}</CustomText>
                        <Box width="100%"
                            style={{
                                border: "1px solid #E8E8E8",
                                padding: `${PP(24)} ${PP(24)}`,
                                marginBottom: PP(16),
                                borderRadius: PP(8),
                            }}
                        >
                            <CustomInput
                                id="freightValue"
                                label={t("Valor del flete")}
                                placeholder=""
                                value={formikEditTransfer.values.freightValue?.toLocaleString("es-MX")}
                                labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
                                inputHeight={PP(40)}
                                onChange={formikEditTransferChange}
                                startAdornmentValue="$"
                                disabled={transferDetails.status === "finished"}
                                extraStyle={{ marginTop: PP(2) }}
                                formik={formikEditTransfer}
                            />
                            <StartDate
                                width="100%"
                                setStartDate={setStartDate}
                                startDate={startDate}
                            />
                            <EndDate
                                width="100%"
                                setEndDate={setEndDate}
                                endDate={endDate}
                            />
                        </Box>
                    </Box>
                    <Box width="30%">
                        <CustomText color="#7D7D7D" noSpacing>{t("Observaciones")}</CustomText>
                        <Box width="100%"
                            style={{
                                border: "1px solid #E8E8E8",
                                padding: `${PP(24)} ${PP(24)}`,
                                marginBottom: PP(16),
                                borderRadius: PP(8),
                            }}
                        >
                            <CustomInput
                                id="observation"
                                type="textarea"
                                labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
                                label={t("Observaciones (opcional)")}
                                placeholder={t("Tus observaciones...")}
                                value={formikEditTransfer.values.observation}
                                onChange={formikEditTransferChange}
                                multiline={true}
                                rows={2}
                                formik={formikEditTransfer}
                                disabled={transferDetails.status === "finished"}
                            />
                            {transferDetails.status !== "finished" && (
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        margin: "0px",
                                    }}
                                >
                                    <InfoIcon
                                        style={{ margin: `${PP(8)} 0px 0px` }}
                                        width={PP(13)}
                                        height={PP(13)}
                                    />
                                    <CustomText
                                        superSmallSize
                                        color="#585757"
                                        style={{ margin: `${PP(8)} 0px 0px` }}
                                    >
                                        &nbsp;{t("Este campo tiene un límite de 100 caracteres")}
                                    </CustomText>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
                {transferDetails.status !== "finished" && (
                    <CustomButton
                        width={600}
                        height={44}
                        noSpacing
                        style={{ padding: "0px", marginTop: PP(48) }}
                        type="submit"
                        label={t("Guardar cambios")}
                        formik={formikEditTransfer}
                    />
                )}
            </Box>
        </form>
    );
}