import { createSlice } from "@reduxjs/toolkit";

import { createInventoryVerification, createInventoryVerificationItem, fetchVerificationDetails, fetchVerificationList } from "../../thunks/inventory/verificationThunks";

interface TransferListType {
    status: "idle" | "loading" | "succeeded" | "failed";
    verificationList: [];
    materials: [];
    collections: [];
    auxId: Number | null;
}

const initialState: TransferListType = {
  status: "idle",
  verificationList: [],
  materials: [],
  collections: [],
  auxId: null
};

export const inventoryVerificationSlice = createSlice({
  name: "verificationList",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    //Get lista de comerciales
    builder.addCase(fetchVerificationList.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchVerificationList.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.verificationList = action.payload.data;
    });
    builder.addCase(fetchVerificationList.rejected, (state, action) => {
      state.status = "failed";
    });
    builder.addCase(fetchVerificationDetails.fulfilled, (state, action) => {
      state.collections = action.payload.data.collections;
      state.materials = action.payload.data.materials;
    });
    builder.addCase(createInventoryVerification.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(createInventoryVerification.fulfilled, (state, action) => {
      state.auxId = action.payload.data.id;
      state.status = "succeeded";
    });
    builder.addCase(createInventoryVerificationItem.pending, (state, action) => {
      state.status = "loading";
    });
  },
});

export const { reset } =
  inventoryVerificationSlice.actions;

export default inventoryVerificationSlice.reducer;