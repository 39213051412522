import { createSlice } from "@reduxjs/toolkit";

import { collectionAttachNewFileSliceType } from "../../../interfaces/redux/collectionAttachNewFileSliceInterface";
import { fetchCollectionAttachNewFile } from "../../thunks/collections/collectionAttachNewFileModalThunk";

const initialState: collectionAttachNewFileSliceType = {
  loading: false,
  status: -1,
  form: {
    fileType: { type: "", label: "" },
    selectedDocument: "",
  },
};

export const collectionAttachNewFileModalSlice = createSlice({
  name: "collectionAttachNewFileReducer",
  initialState,
  reducers: {
    setFormAttachNewFileState: (state, action) => {
      const { id, value } = action.payload;
      state.form = {
        ...state.form,
        [id]: value,
      };
    },

    reset: () => initialState,
  },
  extraReducers: (builder) => {
    //Añadir nuevo archivo adjunto
    builder.addCase(fetchCollectionAttachNewFile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCollectionAttachNewFile.fulfilled, (state, action) => {
      state.loading = false;
      state.status = action.payload.status;
    });
    builder.addCase(fetchCollectionAttachNewFile.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { reset, setFormAttachNewFileState } =
  collectionAttachNewFileModalSlice.actions;

export default collectionAttachNewFileModalSlice.reducer;
