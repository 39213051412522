import { forwardRef, Fragment, useCallback } from "react";

import { Divider } from "@mui/material";

import CustomText from "../../texts/CustomText";
import useAppDispatch from "../../../../hooks/old/useAppDispatch";
import usePixelPerfect from "../../../../hooks/old/usePixelPerfect";
import { reset as resetMaterialsList } from "../../../../redux/slices/collections/materialsListSlice";
import { setTransactionMaterialsModalState } from "../../../../redux/slices/transactions/transactionMaterialsModalsStatesSlice";
import { reset as resetAddMaterial } from "../../../../redux/slices/transactions/addMaterialTransactionSlice";
import MaterialsModalLayout from "../../../../layouts/MaterialsModalLayout";
import AddMaterialTransactionForm from "../../../transactions/AddMaterialTransactionForm";
import { useTranslation } from "react-i18next";

export default forwardRef(function CustomAddMaterialModal() {
  const dispatch = useAppDispatch();
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();

  const resetForm = useCallback(async () => {
    dispatch(resetAddMaterial());
    dispatch(resetMaterialsList());
    dispatch(
      setTransactionMaterialsModalState({ id: "addMaterial", value: false })
    );
  }, [dispatch]);

  const handleClickCloseModal = (event: React.MouseEvent<SVGSVGElement>) => {
    resetForm();
  };

  const handleBackClick = useCallback(async () => {
    dispatch(resetAddMaterial());
    dispatch(resetMaterialsList());
    dispatch(
      setTransactionMaterialsModalState({ id: "addMaterial", value: false })
    );
    dispatch(
      setTransactionMaterialsModalState({ id: "selectCategory", value: true })
    );
  }, [dispatch]);

  return (
    <MaterialsModalLayout handleClose={handleClickCloseModal}>
      <Fragment>
        <CustomText
          showBackArrowButton
          noSpacing
          bigSize
          premediumWeight
          color="#1C1C1C"
          style={{ marginBottom: PP(24) }}
          onClick={handleBackClick}
        >
          {t("Agregar material")}
        </CustomText>
        <Divider sx={{ margin: "0px" }} />
        <AddMaterialTransactionForm />
      </Fragment>
    </MaterialsModalLayout>
  );
});
