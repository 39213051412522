export const daysOfWeek: { [key: string]: string } = {
  Mo: "LUN",
  Tu: "MA",
  We: "MIÉ",
  Th: "JU",
  Fr: "VIE",
  Sa: "SÁB",
  Su: "DOM",
};

export const daysOfWeekSpanish: { [key: string]: string } = {
  do: "DOM",
  lu: "LUN",
  ma: "MA",
  mi: "MIÉ",
  ju: "JU",
  vi: "VIE",
  sá: "SÁB",
};
