import React from 'react';
import { Formik, Form } from 'formik';

interface FormProviderProps {
    initialValues: any;
    onSubmit: (values: any) => void;
    validationSchema: any;
    children: React.ReactNode;
}

const AppFormikProvider: React.FC<FormProviderProps> = ({ initialValues, onSubmit, validationSchema, children }) => {
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            <Form>
                {children}
            </Form>
        </Formik>
    );
};

export default AppFormikProvider;
