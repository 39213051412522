import { createAsyncThunk } from "@reduxjs/toolkit";

import { patchRedux } from "../../../utils/old/api";

export const fetchEditMaterialTransaction = createAsyncThunk(
  "editMaterialTransaction/fetchPatch",
  async (
    payload: {
      data: {
        materials: {
          quantity: number;
          price: number;
          discount: number;
          materialId: number;
        }[];
      };
      transactionId: string | undefined;
    },
    thunkAPI
  ) => {
    try {
      const response = await patchRedux(
        `/transaction/material/${payload.transactionId}`,
        payload.data
      );
      console.log(response.data);
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    } catch (error) {
      const errors = thunkAPI.rejectWithValue(error);
      console.log(errors);
      return errors;
    }
  }
);
