import { useCallback, useEffect, useState } from "react";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { LinearProgress } from "@mui/material";
import moment from "moment";

import { ReactComponent as BlueCheckIcon } from "../../../assets/icons/modals/blueCheck.svg";
import usePixelPerfect from "../../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { setMessage, setOpenSnackbar, setType } from "../../../redux/slices/globalSnackbar/globalSnackbarSlice";
import { destinationWarehouseYup, originWarehouseYup, startDateYup, validateYupSchema } from "../../../utils/forms/validationSchema";
import CustomSelect from "../../custom/forms/default/CustomSelect";
import CustomButton from "../../custom/buttons/default/CustomButton";
import { fetchWarehousesList } from "../../../redux/thunks/warehouse/warehousesListThunk";
import { fetchCreateTransfer } from "../../../redux/thunks/inventory/transfer/createTransferThunk";
import StartDateCreateTransfer from "../../custom/forms/inventory/StartDateCreateTransfer";
import { reset } from "../../../redux/slices/warehouse/warehouseListSlice";

interface CreateTransferFormProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    getTransferList: () => Promise<void>;
}

const formatDate = (date: Date | string) => {
    return moment(date.toString()).format("YYYY-MM-DD");
};

export default function CreateTransferForm(
    props: CreateTransferFormProps
) {
    const { PP } = usePixelPerfect();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { setOpen, getTransferList } = props;

    const { warehousesList } = useAppSelector((state) => state.warehouseList);
    const { loading } = useAppSelector((state) => state.createTransfer);

    const [startDate, setStartDate] = useState(formatDate(new Date()));
    console.log(startDate)


    const getWarehousesList = useCallback(async () => {
        dispatch(reset());
        await dispatch(fetchWarehousesList({ page: 0 }));
    }, [dispatch]);

    useEffect(() => {
        getWarehousesList();
    }, [getWarehousesList]);

    const handleClickCloseModal = () => {
        setOpen(false);
    };

    const initialFormikValues = {
        startDate: startDate,
        originWarehouse: "",
        destinationWarehouse: "",
    };

    const handleSubmit = async (payload: {
        startDate: string;
        originWarehouse: number | string;
        destinationWarehouse: number | string;
    }) => {
        payload.startDate = startDate,
            console.log("")

        if (payload.originWarehouse === "" || payload.destinationWarehouse === "") {
            dispatch(setOpenSnackbar(true));
            dispatch(setMessage(t("Debes seleccionar las bodegas")));
            dispatch(setType("failedSnackbar"));
        } else if (payload.originWarehouse === payload.destinationWarehouse) {
            dispatch(setOpenSnackbar(true));
            dispatch(setMessage(t("Las bodegas deben ser diferentes")));
            dispatch(setType("failedSnackbar"));
        } else {
            dispatch(fetchCreateTransfer(payload));
            dispatch(setType("sucessSnackbar"));
            dispatch(setMessage(t("Transferencia creada")));
            dispatch(setOpenSnackbar(true));
            getTransferList();
            handleClickCloseModal();
        }
    };

    const validationSchema = validateYupSchema({
        startDate: startDateYup,
        originWarehouse: originWarehouseYup,
        destinationWarehouse: destinationWarehouseYup,
    });

    const formikCreateTransfer = useFormik({
        initialValues: initialFormikValues,
        // enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
        validateOnChange: false,
    });

    return (
        <form noValidate onSubmit={formikCreateTransfer.handleSubmit}>
            {loading && <LinearProgress />}
            <CustomSelect
                id="originWarehouse"
                value={formikCreateTransfer.values.originWarehouse}
                onChange={(e) => {
                    formikCreateTransfer.setFieldValue("originWarehouse", e.target.value);
                }}
                label={t("Bodega de origen")}
                fullWidth
                items={warehousesList.map((warehouse) => ({
                    label: warehouse.name,
                    value: warehouse.id,
                }))}
                extraStyle={{ marginTop: PP(24) }}
                placeholder={t("Seleccionar bodega origen")}
                startSelectedIcon={<BlueCheckIcon />}
                formik={formikCreateTransfer}
                required
            />

            <CustomSelect
                id="destinationWarehouse"
                value={formikCreateTransfer.values.destinationWarehouse}
                onChange={(e) => {
                    formikCreateTransfer.setFieldValue("destinationWarehouse", e.target.value);
                }}
                label={t("Bodega destino")}
                fullWidth
                items={warehousesList.map((warehouse) => ({
                    label: warehouse.name,
                    value: warehouse.id,
                }))}
                extraStyle={{ marginTop: PP(24) }}
                placeholder={t("Seleccionar bodega destino")}
                startSelectedIcon={<BlueCheckIcon />}
                formik={formikCreateTransfer}
                required
            />

            <StartDateCreateTransfer
                width="100%"
                setStartDate={setStartDate}
                startDate={startDate}
            />

            <CustomButton
                width={432}
                height={44}
                noSpacing
                style={{ padding: "0px", marginTop: PP(24) }}
                type="submit"
                label={t("Crear")}
                formik={formikCreateTransfer}
            />
            <CustomButton
                noSpacing
                width={432}
                height={44}
                cancelButton
                onClick={handleClickCloseModal}
                formik={formikCreateTransfer}
            />
        </form>
    );
}
