import { createSlice } from "@reduxjs/toolkit";
import { fetchUpdateTransferInfo } from "../../../thunks/inventory/transfer/updateTransferInfo";

const initialState = {
  status: -1,
  statusText: "",
  loading: false,
};

export const updateTransferInfoSlice = createSlice({
  name: "updateTransferInfoSliceReducer",
  initialState,
  reducers: {
    resetUpdateTransferInfoSlice: () => initialState,
  },
  extraReducers: (builder) => {
    // Actualizar transferencia
    builder.addCase(fetchUpdateTransferInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUpdateTransferInfo.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.statusText = "success";
      state.loading = false;
    });
    builder.addCase(fetchUpdateTransferInfo.rejected, (state, action) => {
      state.statusText = "failed";
      state.loading = false;
    });
  },
});

export const { resetUpdateTransferInfoSlice } = updateTransferInfoSlice.actions;

export default updateTransferInfoSlice.reducer;
