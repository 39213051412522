export const routesDateFormat = (isoDateString) => {
  // Crear un objeto Date usando la cadena ISO 8601
  const date = new Date(isoDateString);

  // Ajustar la fecha para tener en cuenta la zona horaria
  const adjustedDate = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  ); // Restamos el desplazamiento de la zona horaria para obtener la fecha local

  // Extraer los componentes de la fecha ajustada
  const year = adjustedDate.getFullYear();
  const month = String(adjustedDate.getMonth() + 1).padStart(2, "0"); // Sumamos 1 para obtener el mes correcto
  const day = String(adjustedDate.getDate()).padStart(2, "0");

  // Formatear la fecha en el formato "YYYY-MM-DD"
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};
