import { useState } from "react";

import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import { ReactComponent as PaperClipIcon } from "../../../assets/icons/modals/paperclip.svg"
import usePixelPerfect from "../../../hooks/old/usePixelPerfect";
import { useAppSelector } from "../../../redux/store";
import CustomText from "../../custom/texts/CustomText";



interface TransferSelectedAttachedFileProps {
    selectedFile: File | null;
    handleSelectFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function TransferSelectedAttachedFile(
    props: TransferSelectedAttachedFileProps
) {
    const { selectedFile, handleSelectFile } = props;
    const { PP } = usePixelPerfect();
    const { t } = useTranslation();

    const { selectedDocument } = useAppSelector(
        (state) => state.transactionAttachNewFileModal.form
    );

    const [fileSrc, setFileSrc] = useState<string | null>(null);

    const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const selectedFile = files[0];

            const reader = new FileReader();
            reader.onload = (e) => {
                const dataUrl = e.target?.result;

                if (dataUrl) {
                    if (selectedFile.type === "application/pdf") {
                        // Crear una Blob a partir de la URL de datos
                        const blob = new Blob([dataUrl], {
                            type: "application/pdf",
                        });

                        // Crear una URL de objeto (Blob URL)
                        const blobUrl = URL.createObjectURL(blob);

                        // Usar blobUrl para mostrar el archivo PDF
                        setFileSrc(blobUrl);
                    } else {
                        // @ts-ignore
                        setFileSrc(dataUrl);
                    }
                }
            };

            selectedFile.type !== "application/pdf"
                ? reader.readAsDataURL(selectedFile)
                : reader.readAsArrayBuffer(selectedFile);

            // Llamar a handleSelectFile si es necesario
            handleSelectFile(e);
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <CustomText
                smallSize
                noSpacing
                color="#969696"
                premediumWeight
                style={{
                    fontSize: PP(14),
                    fontWeight: 700,
                    marginTop: PP(16),
                    marginBottom: PP(8),
                }}
            >
                {t("Documento")}
            </CustomText>

            {selectedFile === null ? (
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        width: "100%",
                        height: PP(112),
                        backgroundColor: "#ffffff",
                        border: `${PP(1)} dashed #969696`,
                        borderRadius: PP(8),
                        padding: PP(20),
                        cursor: "pointer",
                    }}
                    onClick={(e) => document.getElementById("fileInput")?.click()}
                >
                    <PaperClipIcon
                        style={{ width: PP(24), height: PP(24), marginRight: PP(8) }}
                    />
                    <CustomText noSpacing mediumWeight color="#969696">
                        {t("Adjuntar documento")}
                    </CustomText>
                    <input
                        type="file"
                        id="fileInput"
                        style={{ display: "none" }}
                        onChange={handleFileInputChange}
                    />
                </Box>
            ) : (
                <Box
                    sx={{
                        width: "100%",
                        backgroundColor: "#FAFAFA",
                        border: `${PP(1)} solid #DEDEDE`,
                        borderRadius: PP(8),
                        position: "relative",
                    }}
                >
                    {selectedFile.type !== "application/pdf" ? (
                        <img
                            id="selectedImage"
                            alt="Imagen"
                            src={fileSrc ? fileSrc : ""}
                            style={{
                                width: "100%",
                                height: PP(264),
                                objectFit: "cover",
                                border: "none",
                                cursor: "pointer",
                                borderRadius: PP(8),
                            }}
                        />
                    ) : (
                        <iframe
                            title="PDF Viewer"
                            width="100%"
                            height={PP(264)}
                            src={fileSrc || ""}
                        ></iframe>
                    )}
                    <Box
                        borderRadius={`${PP(0)} ${PP(0)} ${PP(8)} ${PP(8)}`}
                        border={`${PP(1)} solid #E8E8E8`}
                        padding={` ${PP(8)} ${PP(12)}`}
                        style={{ backgroundColor: "#FFF" }}
                    >
                        <CustomText noSpacing color="#4D4D4D">
                            {selectedDocument}
                        </CustomText>
                    </Box>
                </Box>
            )}
        </Box>
    );
}
