import { Link } from "react-router-dom";

import { Box, Card } from "@mui/material";

import { ReactComponent as ClockIcon } from "../../assets/icons/cards/clock.svg";
import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import { useAppSelector } from "../../redux/store";
import CustomText from "../custom/texts/CustomText";
import { useTranslation } from "react-i18next";

export default function CustomSeeHistoryCard() {
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();

  const { collectionDetails } = useAppSelector(
    (state) => state.collectionDetails
  );

  const cardStyle = {
    width: PP(216),
    height: PP(56),
    borderRadius: PP(8),
    zIndex: 2,
    marginTop: 8,
    position: "absolute",
    top: PP(0),
    right: PP(0),
    paddingTop: PP(8),
  };

  const buttonStyle = {
    display: "flex",
    alignItems: "center",
    padding: `${PP(8)} ${PP(12)}`,
    height: PP(40),
    width: PP(216),
    cursor: "pointer",
  };

  const hoverButtonStyle = {
    backgroundColor: "#F6F6F6",
  };

  return (
    <Card sx={cardStyle}>
      <Link
        to={`/collections/detail/${collectionDetails.id}/page-history `}
        style={{ textDecoration: "none" }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            style={{
              ...buttonStyle,
            }}
            onMouseEnter={(e) =>
            (e.currentTarget.style.backgroundColor =
              hoverButtonStyle.backgroundColor)
            }
            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "")}
          >
            <ClockIcon
              style={{ marginRight: PP(8), width: PP(16), height: PP(16) }}
            />
            <CustomText color="#4D4D4D" noSpacing>
              {t("collectionDetailsLayout.customSeeHistoryCard.Ver historial")}
            </CustomText>
          </Box>

        </Box>
      </Link>
    </Card>
  );
}
